import React, { useEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { calOutput, shipCurv } from "./loadDiagramLogic";
import { ENGINE_POSITION } from "../../../../constants/trendGraph/enginesLine";
import * as PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import Offline from "../../../offlineComponent/offline.jsx";
import { NoData } from "../../../offlineComponent/noData.jsx";
import { editVesselSettings } from "../../../../helpers/vesselSetting/editVesselSettings";
import { COLORS } from "../../../../constants/colors";

const LoadDiagram = ({
  isInitialLoading,
  isNoData,
  isLoading,
  vesselId,
  engineLatestData = [],
  selectEngines = [],
  isNoEngine,
  vesselSettings,
  vesselBHP,
}) => {
  editVesselSettings(vesselSettings, selectEngines);

  const shipCurvData = shipCurv(vesselSettings);
  const calData = calOutput(vesselSettings);
  const [loadDiagramData, setLoadDiagramData] = useState([]);
  useEffect(() => {
    engineLatestData.length &&
      selectEngines.length &&
      setLoadDiagramData(() => {
        return selectEngines.map((d) => {
          const engine = engineLatestData.find((dd) => dd.position === d);
          if (engine === undefined) return null;

          // judgement power
          let powerValue = engine.meOutput;
          if (
            (engine.shaftHorsePower === undefined || engine.shaftHorsePower === null || engine.shaftHorsePower === -1)
            && (engine.meOutput === undefined ||engine.meOutput === null || engine.meOutput === -1)
          ) {
            powerValue = vesselBHP?.[d] || 0;
          } else if (!engine.meOutput || engine.meOutput === -1) {
            powerValue = engine.shaftHorsePower;
          }

          return {
            rpm: engine.meSpeed < 0 ? 0 : engine.meSpeed,
            power: powerValue,
            position: engine.position,
          };
        });
      });
  }, [vesselId, engineLatestData, selectEngines]);

  const makeLoadDiagram = () => {
    const chartId = "loadDiagramDiv";
    am5.addLicense(process.env.AMCHARTS_LICENSE);

    useEffect(() => {
      let newChartData = [];
      if (
        loadDiagramData?.length > 0 &&
        loadDiagramData[0] != undefined &&
        selectEngines?.length > 0
      ) {
        newChartData = loadDiagramData.map((v) => {
          if (v.position === ENGINE_POSITION.STARBOARD) {
            v.fillRule = {
              fill: am5.color(COLORS.starBoardPink),
              stroke: am5.color(COLORS.starBoardPink),
            };
          } else if (v.position === ENGINE_POSITION.PORT) {
            v.fillRule = {
              fill: am5.color(COLORS.portYellow),
              stroke: am5.color(COLORS.portYellow),
            };
          } else {
            v.fillRule = {
              fill: am5.color(COLORS.centerBlue),
              stroke: am5.color(COLORS.centerBlue),
            };
          }
          return v;
        });
      }

      const root = am5.Root.new(chartId, {
        useSafeResolution: false,
      });
      root.setThemes([am5themes_Animated.new(root)]);
      const bubbleChart = root.container.children.push(am5xy.XYChart.new(root, {}));
      const xAxis = bubbleChart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: vesselSettings?.meMaxRpm,
          numberFormat: "#'rpm'",
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 50,
          }),
        })
      );
      const xRenderer = xAxis.get("renderer");
      xRenderer.labels.template.setAll({
        fill: am5.color(COLORS.white),
        opacity: 0.2,
        fontSize: "14px",
        paddingTop: 14,
      });
      xRenderer.grid.template.setAll({
        stroke: am5.color(COLORS.white),
        strokeWidth: 2,
        strokeDasharray: [4, 2],
      });
      const yAxis = bubbleChart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: vesselSettings?.meMaxKw,
          numberFormat: "#'kw'",
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      const yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.setAll({
        fill: am5.color(COLORS.white),
        opacity: 0.2,
        fontSize: "14px",
        paddingRight: 14,
      });
      yRenderer.grid.template.setAll({
        stroke: am5.color(COLORS.white),
        strokeWidth: 2,
        strokeDasharray: [4, 2],
      });
      const series = bubbleChart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          xAxis,
          yAxis,
          valueXField: "rpm",
          valueYField: "power",
          maskBullets: false,
        })
      );
      const circleTemplate = am5.Template.new({});
      series.bullets.push(() => {
        const graphics = am5.Circle.new(
          root,
          {
            radius: 4,
            templateField: "fillRule",
          },
          circleTemplate
        );
        return am5.Bullet.new(root, {
          sprite: graphics,
        });
      });
      series.strokes.template.set("strokeOpacity", 0);
      series.data.setAll(newChartData);

      //reference line
      const referenceCalSeries = bubbleChart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          xAxis,
          yAxis,
          valueXField: "rpm",
          valueYField: "power",
          stroke: am5.color(COLORS.blue),
        })
      );
      const colorChangeValue = xAxis.makeDataItem({
        value: vesselSettings?.meMaxRpm * 0.85,
        endValue: vesselSettings?.meMaxRpm,
      });
      const colorChangeRange = referenceCalSeries.createAxisRange(colorChangeValue);
      colorChangeRange.strokes.template.setAll({
        stroke: am5.color(COLORS.red),
      });
      referenceCalSeries.data.setAll(calData);

      const leftVerticalReferenceLines = bubbleChart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          xAxis,
          yAxis,
          valueXField: "rpm",
          valueYField: "power",
          stroke: am5.color(COLORS.red),
          fill: am5.color(COLORS.red),
        })
      );

      const centerVerticalReferenceLines = bubbleChart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          xAxis,
          yAxis,
          valueXField: "rpm",
          valueYField: "power",
          stroke: am5.color(COLORS.red),
          fill: am5.color(COLORS.red),
        })
      );

      const rightVerticalReferenceLines = bubbleChart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          xAxis,
          yAxis,
          valueXField: "rpm",
          valueYField: "power",
          stroke: am5.color(COLORS.red),
          fill: am5.color(COLORS.red),
        })
      );

      leftVerticalReferenceLines.data.setAll([
        { rpm: vesselSettings?.meMaxRpm * 0.85, power: 0 },
        {
          rpm: vesselSettings?.meMaxRpm * 0.85,
          power: calData.find((v) => v.id === 85).power,
        },
      ]);

      centerVerticalReferenceLines.data.setAll([
        { rpm: vesselSettings?.meMaxRpm * 0.95, power: 0 },
        {
          rpm: vesselSettings?.meMaxRpm * 0.95,
          power: calData.find((v) => v.id === 95).power,
        },
      ]);

      rightVerticalReferenceLines.data.setAll([
        { rpm: vesselSettings?.meMaxRpm, power: 0 },
        {
          rpm: vesselSettings?.meMaxRpm,
          power: calData.find((v) => v.id === 100).power,
        },
      ]);

      const referenceShipSeries = bubbleChart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          xAxis,
          yAxis,
          valueXField: "rpm",
          valueYField: "power",
          stroke: am5.color(COLORS.shipCurveColor),
        })
      );
      referenceShipSeries.data.setAll(shipCurvData);

      return () => {
        root.dispose();
      };
    }, []);
    return <div id={chartId} style={{ height: "300px", width: "100%" }} />;
  };

  const Display = () => {
    switch (true) {
      case isInitialLoading:
        return <CircularProgress />;
      case isNoEngine:
        return (
          <div>
            <NoData />
          </div>
        );
      case isNoData:
        return (
          <div>
            <Offline isEngine={true} />
          </div>
        );
      case loadDiagramData?.length > 0:
        return makeLoadDiagram();
      case isLoading:
        return <CircularProgress style={{ margin: '0 auto' }} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Display />
    </>
  );
};

LoadDiagram.propTypes = {
  isLoading: PropTypes.bool,
  isNoData: PropTypes.bool,
  vesselId: PropTypes.string,
  engineLatestData: PropTypes.array,
  selectEngines: PropTypes.array,
  isNoEngine: PropTypes.bool,
  vesselSettings: PropTypes.object,
  isInitialLoading: PropTypes.bool,
  vesselBHP: PropTypes.array,
};

export default LoadDiagram;
