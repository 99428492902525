import axiosBase from "axios";
import { axios } from "./axiosSettings";
import { getAuthHeader } from "../helpers/session";

let alertCancelToken;

export const getAlertSettings = async (vesselId, channelNo = '') => {
  if (typeof alertCancelToken !== typeof undefined) {
    alertCancelToken.cancel("Operation canceled due to new request.");
  }

  alertCancelToken = axiosBase.CancelToken.source();

  try {
    const { data } = await axios.get(`/api/alert/channelconfig`, {
      params: {
        vesselId,
        channelNo,
      },
      headers: getAuthHeader(),
    });
    return { alertSettings: data };
  } catch (e) {
    throw { getAlertSettings: e };
  }
};

export const postAlertSettings = async (vesselId, alertSettings) => {
  if (typeof alertCancelToken !== typeof undefined) {
    alertCancelToken.cancel("Operation canceled due to new request.");
  }

  alertCancelToken = axiosBase.CancelToken.source();
  try {
    const { data } = await axios.post(`/api/alert/channelconfig/${vesselId}`, alertSettings, {
      params: {
        vesselId,
      },
      headers: getAuthHeader(),
    });
    return { alertSettings: data };
  } catch (e) {
    throw { getAlertSettings: e };
  }
};
