//setter
const setShowEmission = (state, action) => {
  state.showEmission.showFlag = action.payload.showFlag;
  state.showEmission.checkFlag = action.payload.checkFlag;
};
const setEmissionAddonData = (state, action) => {
  state.emissionAddonData.emissionAddonId = action.payload.emissionAddonId;
  state.emissionAddonData.emissionEnabledVesselIds = action.payload.emissionEnabledVesselIds;
};
const setShowSynthetica = (state, action) => {
  state.showSynthetica.showFlag = action.payload.showFlag;
  state.showSynthetica.checkFlag = action.payload.checkFlag;
};
const setSyntheticaAddonData = (state, action) => {
  state.syntheticaAddonData.syntheticaAddonId = action.payload.syntheticaAddonId;
  state.syntheticaAddonData.syntheticaEnabledVesselIds = action.payload.syntheticaEnabledVesselIds;
};

export default {
  setShowEmission,
  setEmissionAddonData,
  setShowSynthetica,
  setSyntheticaAddonData,
};
