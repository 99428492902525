import React from "react";

import { Modal } from "antd";
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from "../../icon/deleteIcon.jsx";

const useStyles = makeStyles({
  modal: {
    backgroundColor: "#161a1e",
    color: "#ffffff",
    paddingBottom: 0,
    top: "165px",
    width: "fit-content !important",
    "& .ant-modal-content": {
      backgroundColor: "unset",
      textAlign: "center",
      padding: "40px 70px",
    },
    "& .ant-modal-header": {
      backgroundColor: "unset",
      "& .ant-modal-title": {
        color: "#ffffff",
      },
    },
    "& .ant-modal-footer": {
      textAlign: "center",
      marginTop: "28px",
    },
  },
});

export function Confirm(props) {
  const { when, onOK, onCancel, title, okText, cancelText, message, isDelete } = props;
  const classes = useStyles();

  const backGroundColor = isDelete ? "#ff5252" : "#19b2ab";
  const color = isDelete ? "#ffffff" : "#21262f";

  return when ? (
    <Modal
      title={title}
      open={when}
      okText={okText}
      cancelText={cancelText}
      onOk={onOK}
      onCancel={onCancel}
      okButtonProps={{
        type: "primary",
        icon: isDelete ? <DeleteIcon width={16} height={16} color={"#ffffff"} /> : <></>,
        style: {
          color,
          fontSize: "12px",
          fontWeight: "500",
          backgroundColor: backGroundColor,
          borderRadius: "2px",
        },
      }}
      cancelButtonProps={{
        type: "link",
        style: {
          color: "#19b2ab",
          fontSize: "12px",
          fontWeight: "500",
        },
      }}
      closable={false}
      maskClosable={false}
      className={classes.modal}
      styles={{
        mask: {
          backgroundColor: "rgba(38, 50, 63, 0.7)",
        }
      }}
    >
      {message}
    </Modal>
  ) : null;
}
