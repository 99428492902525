export const bhpCalcDict = {
    "2f383381-125c-4f52-a6bb-d273094886c9": {
        "enginePosition": "Center",
        //"": 8.21396e1,
        "rpm": 0.005551465,
        //"": 7.77465e-1,
        //"": -3.57823e-11,
        "701": 2.23751e-1,
        "103": 2.27530e-3
    }
}