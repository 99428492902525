export const SENSORS_TAGS = [
  { tag: "O3" },
  { tag: "O4" },
  { tag: "O7" },
  { tag: "O8" },
  { tag: "O16" },
  { tag: "O14" },
  { tag: "O15" },
  { tag: "O19" },
];

export const PITCH_TAGS = [{ tag: "O7" }, { tag: "O15" }];

export const ROLL_TAGS = [{ tag: "O8" }, { tag: "O14" }];

export const VOLUME_TAGS = [{ tag: "O3" }, { tag: "O4" }];
