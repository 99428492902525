import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  currentAlarmSelector,
  currentPageSelector,
  FILTER_PAGE,
  historyAlarmSelector,
  operationAlarmSelector,
  wordSearch,
  ALARM_PAGE,
} from "../../../model/slice/alarmSlice.js";

export const useInputText = () => {
  const textRef = useRef("");
  const setCurrentText = (text) => {
    textRef.current = text;
    setSearchText();
  };
  const dispatch = useDispatch();
  const currentPage = useSelector(currentPageSelector);
  const currentFilter = useSelector(currentAlarmSelector);
  const historyFilter = useSelector(historyAlarmSelector);
  const operationFilter = useSelector(operationAlarmSelector);
  const resetSearchText = () => (textRef.current = "");

  const filterWord = () => {
    switch (currentPage) {
      case ALARM_PAGE.MONITORING:
        return currentFilter.word;
      case ALARM_PAGE.HISTORY:
        return historyFilter.word;
      case ALARM_PAGE.OPERATION:
        return operationFilter.word;
      default:
        return;
    }
  };

  useEffect(() => {
    if (filterWord() === "") return;
    textRef.current = filterWord();
  }, []);

  const currentSelectedFilter = () => {
    switch (currentPage) {
      case ALARM_PAGE.MONITORING:
        return FILTER_PAGE.MONITORING;
      case ALARM_PAGE.HISTORY:
        return FILTER_PAGE.HISTORY;
      case ALARM_PAGE.OPERATION:
        return FILTER_PAGE.OPERATION;
      default:
        return;
    }
  };

  const setSearchText = () => {
    dispatch(wordSearch({ text: textRef.current, filter: currentSelectedFilter() }));
  };

  return {
    currentText: textRef.current,
    setCurrentText,
    setSearchText,
    resetSearchText,
  };
};
