import { useEffect, useRef, useState } from "react";
import {
  fetchVesselHistoricalMainEngineData,
  fetchVesselHistoricalGpvtgData,
} from "../../../api/historicalData";

import useInterval from "../../../hooks/useInterval";
import { logger } from "../../../api/logger";
import dayjs from "util/dayjs-init.js";

export const useEngineHistoricalData = ({ vesselId }) => {
  const unmount = useRef(false);
  const [historicalEngineData, setHistoricalEngineData] = useState();
  const [historicalNavigationData, setHistoricalNavigationData] = useState();
  const [isLoadingH, setIsLoading] = useState(false);
  const [isNoEngineH, setIsNoEngine] = useState(false);

  useEffect(() => {
    setIsNoEngine(false);
  }, [vesselId]);

  const fetchData = async () => {
    setIsLoading(true);
    const startDate = dayjs.utc().subtract(7, "days").startOf('hour').format();
    const endDate = dayjs.utc().startOf('hour').format();
    const data = await Promise.all([
      fetchVesselHistoricalMainEngineData(vesselId, startDate, endDate),
      fetchVesselHistoricalGpvtgData(vesselId, startDate, endDate),
    ])
      .then((d) => {
        logger.info(`fetchVesselHistoricalData data: ${JSON.stringify(d)}`);
        setIsLoading(false);
        if (!d[0]?.historicalMainEngineData?.length) {
          setIsNoEngine(true);
        }
        return d;
      })
      .catch(() => {
        setIsLoading(false);
      });

    unmount.current || setHistoricalEngineData(data[0]?.historicalMainEngineData);
    unmount.current || setHistoricalNavigationData(data[1]?.historicalGpvtgData);
  };

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [vesselId]);

  useInterval(fetchData, 3600000, false);

  return {
    historicalNavigationData,
    historicalEngineData,
    isLoadingH,
    isNoEngineH,
  };
};
