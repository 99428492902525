export const cleanGeneratorData = (vesselId, data, count) => {
  if (count <= 0) return [];

  let dataLength = data.length;
  let sortedData = data.sort((a, b) => {
    if (a.generatorNumber > b.generatorNumber) return 1;
    else if (a.generatorNumber < b.generatorNumber) return -1;
    return 0;
  });

  if (dataLength > count) {
    let res = [];
    data.forEach((d) => {
      if (res.length < count) {
        res.push(d);
      } else {
        const min = Math.min.apply(
          null,
          res.map((r) => new Date(r.dateTime))
        );
        if (new Date(d.dateTime).getTime() > min) {
          res = res.filter((r) => new Date(r.dateTime).getTime() !== min);
          res.push(d);
        }
      }
    });
    return res;
  } else if (dataLength < count) {
    return [
      ...sortedData,
      ...Array.from(Array(count - dataLength), (e, i) => {
        return {
          vessel_id: vesselId,
          generatorNumber: dataLength + i + 1,
          isNoData: true,
        };
      }),
    ];
  } else {
    return sortedData;
  }
};
