import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"
import { VESSEL_TYPE } from "../../constants/constants";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType";
import Select from "react-select";
import { customGraphSelectorStyles } from "./graphSelector";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";


export const GraphTypeSelector = (props) => {
  const { selectedVessel, hasCustomCharts, setSelectedGraphType, selectedGraphType } = props;
  const {t} = useTranslation();
  const isSEPVessel = selectedVessel?.vesselType === VESSEL_TYPE.SEP;
  const isNoEngine = !selectedVessel?.NoME;
  const isNoGenerator = !selectedVessel?.NoDG && !selectedVessel?.NoSG;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuRef = useRef(null);
  useOnClickOutside([menuRef], () => {
    setIsMenuOpen(false);
  });

  const graphTypeOptions = useCallback(() => {
    const options = [];
    if(isSEPVessel){
      options.push({ value: GRAPH_TYPE.SEP, label: t(localizationKeys.SEP_upper)});
    }else if(!isNoEngine){
      options.push({value: GRAPH_TYPE.ENGINE, label: t(localizationKeys.Engine_upper)});
    };
    if(!isNoGenerator){
      options.push({value: GRAPH_TYPE.ELECTRIC, label: t(localizationKeys.Electric_upper)});
    };
    if(hasCustomCharts){
      options.push({value: GRAPH_TYPE.CUSTOM, label: t(localizationKeys.Custom_upper)});
    };
    return options;
  }, [selectedVessel, selectedGraphType, hasCustomCharts]);

  useEffect(() => {
    if(!selectedGraphType){
      setSelectedGraphType(graphTypeOptions()[0]?.value);
    }
  }, [selectedGraphType, hasCustomCharts])

  return (
    <div ref={menuRef}>
      <Select
        options={graphTypeOptions()}
        value={graphTypeOptions().find((d) => d.value === selectedGraphType)}
        onChange={(d) => {
          setSelectedGraphType(d?.value ?? null);
        }}
        placeholder={"GRAPH TYPE SELECT"}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        styles={customGraphSelectorStyles}
        onMenuClose={() => setIsMenuOpen(false)}
        onMenuOpen={() => setIsMenuOpen(true)}
        menuIsOpen={isMenuOpen}
      />
    </div>
  );
};

GraphTypeSelector.propTypes = {
  selectedVessel: PropTypes.object,
  hasCustomCharts: PropTypes.bool,
  selectedGraphType: PropTypes.string,
  setSelectedGraphType: PropTypes.func,
};