//dayjs configure
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc.js';
import duration from 'dayjs/plugin/duration.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import isBetween from 'dayjs/plugin/isBetween.js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import timezone from 'dayjs/plugin/timezone.js';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);


const defaultFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
dayjs.extend((option, dayjsClass, dayjsFactory) => {
  const oldFormat = dayjsClass.prototype.format

  dayjsClass.prototype.format = function (formatString) {
    return oldFormat.bind(this)(formatString ?? defaultFormat)
  }
})
export default dayjs;
