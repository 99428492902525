import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getDataDuration } from "../../../model/slice/dataDurationSlice";

//IndexDB imports

//Reducer imports

//API imports
import { fetchVesselGpvtgData } from "../../../api/navigationData";

import { calDurations } from "../../../constants/calDuration";
import { logger } from "../../../api/logger";

const INITIAL_VALUE = {
  gpvtgData: [],
};

export const useNavigationData = ({ vesselId = null, isVesselOverview = false }) => {
  const [navigationValues, setNavigationValues] = useState(INITIAL_VALUE);
  //Coming from duration selection
  const { startDate, endDate } = useSelector(getDataDuration);
  //Sets to true if component is unmounted
  const unmount = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(false);

  const NAVIGATION_CLASS = {
    navigationGpvtgData: {
      dateRangeKey: "dateRangeGpvtgData",
      fetchKey: "gpvtgData",
      fetch: async (vesselId, startDate, endDate, interval) => {
        return await fetchVesselGpvtgData(vesselId, startDate, endDate, interval);
      },
    },
  };

  //Core logic for fetching engine data values
  const getNavigationValues = async (navigationClass) => {
    let data = [];

    // interval calculation
    const interval = isVesselOverview ? 10 : calDurations({ startDate, endDate });

    logger.info("Fetch from iAPI");
    const fetchData = await navigationClass.fetch(vesselId, startDate, endDate, interval);
    data = fetchData[navigationClass.fetchKey];
    setIsNoData(!data?.length);
    return data;
  };

  //Loads data
  const dataLoad = async () => {
    await Promise.all([getNavigationValues(NAVIGATION_CLASS.navigationGpvtgData)])
      .then((values) => {
        if (unmount.current === true) {
          return;
        }
        setNavigationValues({
          gpvtgData: values[0],
        });
      })
      .catch((error) => {
        logger.error(`getNavigationValues error: ${error}`);
      });
    setIsLoading(false);
  };

  // if vesselId is changed, engine data will be reset
  useEffect(() => {
    setNavigationValues(() => INITIAL_VALUE);
  }, [vesselId]);

  // Fires when either of the following is changed:
  // vesselId => if user changes selected vessel
  // position => engine position is changed: Starboard | Port
  // startDate/endDate => if date duration is changed
  useEffect(
    () => {
      if (startDate === "" || vesselId === null) {
        setIsNoData(true);
        return;
      }
      setIsLoading(true);
      setIsNoData(false);
      dataLoad();
    },
    //IMPORTANT!!! Group position, startDate and endDate together.. The main cause of double refresh
    //is when position and duration changed at the same time
    [vesselId, startDate, endDate]
  );

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);
  return {
    navigationValues,
    isLoading,
    isNoData,
  };
};
