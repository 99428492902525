import { withStyles } from "@mui/styles";
import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { withTranslation } from "react-i18next";

import { DEFAULT_DASH_VALUE, DEFAULT_VALUE, NAVIGATION_TYPES } from "../../../../constants/constants";
import { SHIP_GROUP_TYPE, SHIP_TYPE } from "../../../../constants/vesselShipType";
import { COLORS } from "../../../../constants/colors";
import { getEta } from "../../../../util/navigation/navigation";
import localizationKeys from "../../../../i18n/localizationKeys";

const styles = () => ({
  container: {
    display: "flex",
  },
  thumbnailContainer: {
    display: "flex",
    alignItems: "center",
  },
  thumbnail: {
    minWidth: '125px',
    maxWidth: '125px',
    minHeight: '90px',
    maxHeight: '90px',
    position: 'relative'
  },
  flagHolder: {
    position: "absolute",
    height: "35px",
    width: "35px",
    borderRadius: "50px",
    top: "-10px",
    right: "-20px",
    display: "block",
    textAlign: "center",
  },
  flag: {
    width: "100%",
    height: "100%",
  },
  countryNameHolder: {
    position: "absolute",
    height: "35px",
    width: "35px",
    borderRadius: "50px",
    top: "-10px",
    right: "-20px",
    display: "block",
    background: "#2834408c",
    color: "#ffffff",
    paddingTop: "10px",
    fontSize: "12px",
    fontFamily: "Inter",
  },
  title: {
    fontSize: "32px",
  },
  text: {
    width: "100%",
    fontSize: "12px",
  },
  labelColor: {
    color: COLORS.greenishBlue,
  },
  valueColor: {
    color: COLORS.white,
  },
  center: {
    textAlign: "center",
  },
});

const aisDataInitialValue = {
  countryCode: DEFAULT_VALUE,
  shipType: DEFAULT_VALUE,
  callSign: DEFAULT_DASH_VALUE,
  mmsi: DEFAULT_DASH_VALUE,
  destination: DEFAULT_DASH_VALUE,
};

const gpggaDataInitialValue = {
  latitude: DEFAULT_DASH_VALUE,
  longitude: DEFAULT_DASH_VALUE,
};

const gpvtgDataInitialValue = {
  speedKnot: DEFAULT_DASH_VALUE,
};

const wimwvtDataInitialValue = {
  force: DEFAULT_DASH_VALUE,
  direction: DEFAULT_DASH_VALUE,
}

const Navigation = memo(({ vessel, style, navigationData, classes, t }) => {
  const [aisData, setAisData] = useState(aisDataInitialValue);
  const [gpggaData, setGpggaData] = useState(gpggaDataInitialValue);
  const [gpvtgData, setGpvtgData] = useState(gpvtgDataInitialValue);
  const [wimwvtData, setWimwvtData] = useState(wimwvtDataInitialValue);

  const ais = navigationData.find(d => d.navigationType === NAVIGATION_TYPES.AIS);
  const gpgga = navigationData.find(d => d.navigationType === NAVIGATION_TYPES.GPGGA);
  const gpvtg = navigationData.find(d => d.navigationType === NAVIGATION_TYPES.GPVTG);
  const wimwvt = navigationData.find(d => d.navigationType === NAVIGATION_TYPES.WIMWVT);

  useEffect(() => {
    if (!ais) return;

    const details = ais.data[0];
    const shipType = details.shipType;

    setAisData({
      countryCode: details.mmsi.toString().substring(0, 3),
      shipType: SHIP_GROUP_TYPE[SHIP_TYPE[shipType]],
      callSign: details.callSign ?? DEFAULT_DASH_VALUE,
      mmsi: details.mmsi ?? DEFAULT_DASH_VALUE,
      destination: details.destination,
      eta: details.etaMonth ? getEta(details.etaMonth,
        details.etaDay,
        details.etaHour,
        details.etaMinute) : DEFAULT_DASH_VALUE,
    });
  }, [ais]);

  useEffect(() => {
    if (!gpgga) return;

    const details = gpgga.data[0];

    setGpggaData({
      latitude: details.latitude.toFixed(2),
      longitude: details.longitude.toFixed(2),
    });
  }, [gpgga]);

  useEffect(() => {
    if (!gpvtg) return;

    const details = gpvtg.data[0];

    setGpvtgData({
      speedKnot: details.speedKnot,
    });
  }, [gpvtg]);

  useEffect(() => {
    if (!wimwvt) return;

    const details = wimwvt.data[0];

    setWimwvtData({
      force: details.force,
      direction: details.direction,
    });
  }, [wimwvt]);

  useEffect(() => {
    return () => {
      setAisData(aisDataInitialValue);
      setGpggaData(gpggaDataInitialValue);
      setGpvtgData(gpvtgDataInitialValue);
      setWimwvtData(wimwvtDataInitialValue);
    };
  }, [vessel.vessel_id])

  return (
    <Grid container className={ `${style.components} ${classes.container}` }>
      <Grid item xs={7}>
        <Grid container style={{ display: "flex", alignItems: "center", textAlign: "center" }} rowSpacing={2}>
          <Grid item xs={12}>
            <div className={`${classes.labelColor} ${classes.text}`}>
              {t(localizationKeys.Destination_upper)}
            </div>
            <div className={`${classes.valueColor} ${classes.text}`} style={{ fontSize: "17px", marginTop: "8px" }}>
              {aisData.destination}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <div className={`${classes.labelColor} ${classes.text} ${classes.center}`}>
                  {t(localizationKeys.VesselSpeed)}
                </div>
                <div className={`${classes.valueColor} ${classes.text} ${classes.center}`}>
                  {gpvtgData.speedKnot} knot
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={`${classes.labelColor} ${classes.text} ${classes.center}`}>
                  {t(localizationKeys.VesselPosition)}
                </div>
                <div className={`${classes.valueColor} ${classes.text} ${classes.center}`}>
                  {gpggaData.latitude}°, {gpggaData.longitude}°
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container style={{ display: "flex", alignItems: "center", textAlign: "center" }} rowSpacing={2}>
          <Grid item xs={12}>
            <div className={`${classes.labelColor} ${classes.text}`}>
              {t(localizationKeys.Eta_upper)}
            </div>
            <div className={`${classes.valueColor} ${classes.text}`} style={{ fontSize: "17px", marginTop: "8px" }}>
              {aisData.eta}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <div className={`${classes.labelColor} ${classes.text} ${classes.center}`}>
                  {t(localizationKeys.TrueWindSpeed)}
                </div>
                <div className={`${classes.valueColor} ${classes.text} ${classes.center}`}>
                  {wimwvtData.force} m/s
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={`${classes.labelColor} ${classes.text} ${classes.center}`}>
                  {t(localizationKeys.TrueWindDirection)}
                </div>
                <div className={`${classes.valueColor} ${classes.text} ${classes.center}`}>
                  {wimwvtData.direction}°
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

Navigation.propTypes = {
  vessel: PropTypes.object,
  navigationData: PropTypes.array,
  style: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func,
};

export default withStyles(styles)(withTranslation()(Navigation));