import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constants/colors.js";

import ChangeTriangle from "./changeTriangle.jsx";

const useStyles = makeStyles({
  upperIndicator: {
    position: "relative",
    display: "flex",
    color: "#ff2d00",
  },
  lowerIndicator: {
    display: "flex",
    color: "#9dcd4e",
  },
  sideString: {
    position: "relative",
    top: "5px",
    fontSize: "8px",
    marginLeft: "4px",
  },
  noTriangle: {
    color: COLORS.white,
  },
  hoverTooltip: {
    position: "absolute",
    width: "55px",
    bottom: "20px",
    fontSize: "7px",
    color: "white",
  },
});

const ChangeIndicator = (props) => {
  const { value } = props;
  const [hover, setHover] = useState(false);
  const classes = useStyles();
  const tooltipMessage = "Emissions trend for past 30 days";
  if (value > 0) {
    return (
      <div
        className={`${classes.upperIndicator} changeIndicator`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover && <div className={classes.hoverTooltip}>{tooltipMessage}</div>}
        <ChangeTriangle value={value} />
        <span className={classes.sideString}>{`${value}%`}</span>
      </div>
    );
  } else if (value < 0) {
    return (
      <div
        className={`${classes.lowerIndicator} changeIndicator`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover && <div className={classes.hoverTooltip}>{tooltipMessage}</div>}
        <ChangeTriangle value={value} />
        <span className={classes.sideString}>{`${value}%`}</span>
      </div>
    );
  } else {
    return (
      <div
        className={`${classes.noTriangle} changeIndicator`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover && <div className={classes.hoverTooltip}>{tooltipMessage}</div>}
        <span>--</span>
      </div>
    );
  }
};

export default ChangeIndicator;
