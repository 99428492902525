
import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import localizationKeys from "../../i18n/localizationKeys.js";
import { SyntheticaStyles, SynthConstants, DeltaIndicator } from "./styles";

const Constants = {
    TimeCurrent: "CURR",
    TimeProjected: "PROJ",
}

export const VesselPerformance = ({ data }) => {
    const { t } = useTranslation();
    const [selectedTime, setSelectedTime] = useState(Constants.TimeCurrent);

    const handleDropdownChange = (event) => {
        setSelectedTime(event.target.value);
    };

    const Header = () => {
        return (
            <Box sx={SyntheticaStyles.paperHeader}>
                <Box>{t(localizationKeys.VesselPerformance)}</Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Select
                    value={selectedTime}
                    onChange={handleDropdownChange}
                    sx={SyntheticaStyles.select}
                    disableUnderline
                    variant="standard"
                >
                    <MenuItem value={Constants.TimeCurrent}>YEAR-TO-DATE</MenuItem>
                    <MenuItem value={Constants.TimeProjected}>PROJECTED-EOY</MenuItem>
                </Select>
            </Box>
        )
    }
    const getContentDisplay = (title, valueField, unit, delta, positiveIsGood) => {
        return {
            title: title,
            value: data.vesselPerformance[valueField] + " " + unit,
            delta: delta,
            deltaState: SynthConstants.DELTA_MED, //FIX THIS ONCE WE HAVE DELTA DATA
        }
    }
    const contentDisplayCurrent = [
        getContentDisplay(t(localizationKeys.DistanceTraveled), "total_distance", "NM", "", true),
        getContentDisplay(t(localizationKeys.FuelConsumption), "fuel", "MT", "", false),
        getContentDisplay(t(localizationKeys.CO2), "co2", "MT", "", false),
        getContentDisplay(t(localizationKeys.AverageSpeed), "avg_speed", "KN", "", true),
    ]
    const contentDisplayProjected = [
        getContentDisplay(t(localizationKeys.DistanceTraveled), "projected_distance", "NM", "", true),
        getContentDisplay(t(localizationKeys.FuelConsumption), "projected_fuel", "MT", "", false),
        getContentDisplay(t(localizationKeys.CO2), "projected_co2", "MT", "", false),
        getContentDisplay(t(localizationKeys.AverageSpeed), "projected_speed", "KN", "", true),
    ]
    const contentDisplay = selectedTime === Constants.TimeCurrent ? contentDisplayCurrent : contentDisplayProjected;
    const Content = () => {
        return (
            <Grid container sx={{ textAlign: "left" }}>
                {contentDisplay.map((c) =>
                    <Grid xs={6} lg={3} key={c.title}>
                        {ContentReadout(c)}
                    </Grid>
                )}
            </Grid>
        )
    }
    const ContentReadout = (contentObj) => {

        return (
            <Box>
                <Box sx={{ width: "100%", color: "#85898E", fontSize: "16px", marginBottom: "2px" }}>
                    <Box sx={{ display: "inline", marginRight: "10px" }}>{contentObj.title}</Box>
                    {DeltaIndicator(contentObj.delta, contentObj.deltaState)}
                </Box>
                <Box sx={{ width: "100%", color: "white", fontSize: "1rem" }}>{contentObj.value}</Box>
            </Box>
        )
    }

    return (
        <Box>
            {Header()}
            {Content()}
        </Box>
    )

}