import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation } from "react-router-dom";

//Icon imports
import { Drawer, IconButton, List, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectSideBarState } from "../../model/slice/sideBarSettingSlice";
import MapIcon from "../../../assets/images/sideBar/nav-navigation-data-35-x-35.svg";
import ComparisonIcon from "../../../assets/images/sideBar/nav-comparison-data-2-35-x-35.svg";
import EmissionIcon from "../../../assets/images/sideBar/nav-emission-data-35-x-35.svg";
import ChannelIcon from "../../../assets/images/sideBar/nav-channel.svg";
import NewNavIcon from "../../../assets/images/navIcon/nav-navigation-menu-button-on-vessel-35-x-35-3.svg";
import EngineTrendIcon from "../../../assets/images/navIcon/Nav_EngineTrendData35x35.svg";
import MonitoringIcon from "../../../assets/images/navIcon/Nav_MonitoringData35x35.svg";
import AlarmIcon from "../../../assets/images/navIcon/Nav_Alarm35x35.svg";
import LCO2Icon from "../../../assets/images/navIcon/Nav_LCO235x35.svg";
import CIIIcon from "../../../assets/images/navIcon/Nav_CII.svg";

import {
  fleetNavigationData,
  selectedVessel,
} from "../../model/slice/fleetNavigationDataSlice";
import { NAVIGATION_BAR_HEIGHT } from "../../layout/panelLayout.jsx";

import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType";
import { withTranslation } from "react-i18next";
import IconMenu from "./menu.jsx";
import { ENGINE_CHART_TYPE } from "../../constants/trendGraph/enginesLine";
import { fetchChartsAsync } from "../../model/async/chartsAsync";
import {
  getEmissionShownStatus,
  setEmissionAddonData,
  setShowEmission,
  getSyntheticaShownStatus,
  setSyntheticaAddonData,
  setShowSynthetica,
  getEmissionEnableVesselId,
  getSyntheticaEnabledVesselIds,
} from "../../model/slice/addonSlice";
import { fetchAddon, fetchVesselApplication } from "../../api/addon";
import { CARGO_PAGES } from "../../constants/cargo/pages";
import { fetchVessel } from "../../api/vessel.js";

import UserContext from "../../context/UserContext.js";

const PAGES = {
  MAIN: "main",
  COMPARISON: "comparison",
  EMISSION: "emission",
  CII: "cii",
  NAVIGATION: "navigation",
  MONITORING: "monitoring",
  TREND: "trend",
  CARGO: "cargo",
  ALARM: "alarm",
  CHANNEL: "channel",
}

const sideBar = () => ({
  marginLeft: "16px",
  marginRight: "16px",
  background: "none !important",
  color: "white",
  width: "45px",
  overflowX: "hidden",
  flexShrink: 0,
  borderRight: "none",
  top: `${NAVIGATION_BAR_HEIGHT + 8}px`,
});

const useStyles = makeStyles((theme) => ({
  sideBar: {
    ...sideBar(),
  },
  sideBarContent: {
    ...sideBar(),
    height: `calc(100dvh - ${NAVIGATION_BAR_HEIGHT + 8}px)`,
  },
  button: {
    width: "45px !important",
    height: "45px !important",
    padding: "0 !important",
    borderRadius: "4px !important",
    margin: "8px 0 !important",
  },
  comparisonIconHolder: {
    marginLeft: "3px",
    marginTop: "10px",
    marginBottom: "20px",
  },
  kpiIconHolder: {
    marginLeft: "3px",
    marginTop: "15px",
    marginBottom: "2px",
  },
  display: {
    position: "relative",
  },
  enableColor: {
    backgroundColor: "#19b2ab !important",
    width: "100%",
    height: "50px",
    "&:hover": {
      backgroundColor: "rgb(88, 153, 164) !important",
    },
  },
  disableColor: {
    backgroundColor: "#46576d !important",
    width: "100%",
    height: "50px",
    "&:hover": {
      backgroundColor: "rgb(119, 140, 159) !important",
    },
  },
  enableColorMap: {
    backgroundColor: "rgb(25, 178, 171) !important",
    width: "100%",
    height: "50px",
    "&:hover": {
      backgroundColor: "rgb(35, 227, 219) !important",
    },
  },
  disableColorMap: {
    backgroundColor: "rgb(33, 38, 47) !important",
    width: "100%",
    height: "50px",
    "&:hover": {
      backgroundColor: "rgb(80, 91, 111) !important",
    },
  },
  listItem: {
    padding: 0,
  },
  imagePosition: {
    display: "block",
    width: "35px",
    height: "35px",
  },
  hide: {
    ...sideBar(),
    display: "none",
    width: "0px",
  },
  opacityNormal: {
    opacity: "1",
  },
  opacityMap: {
    opacity: "0.7",
  },
}));

const SideBar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const sideBarStatus = useSelector(selectSideBarState);
  const fleetList = useSelector(fleetNavigationData);
  const currentVessel = useSelector(selectedVessel);
  const showEmission = useSelector(getEmissionShownStatus);
  const showSynthetica = useSelector(getSyntheticaShownStatus);
  const emissionEnabledVesselIds = useSelector(getEmissionEnableVesselId);
  const syntheticaEnabledVesselIds = useSelector(getSyntheticaEnabledVesselIds);
  const [anchorEl, setAnchorEl] = useState(null);
  const comparisonVesselid = useMemo(() => {
    const currentPath = location.pathname.split("/");
    return currentPath?.length > 2 ? currentPath[currentPath.length - 1] : currentVessel?.vessel_id;
  }, [location.pathname, currentVessel]);
  const [vessel, setVessel] = useState(null);
  const userContext = React.useContext(UserContext);

  const sideBarSettings = () => [
    {
      icon: MapIcon,
      alt: "map page link icon",
      path: "/main",
      page: PAGES.MAIN,
      shouldShow: () => true,
    },
    {
      icon: ComparisonIcon,
      alt: "comparison page link icon",
      path: "/comparison",
      page: PAGES.COMPARISON,
      shouldShow: () => true,
    },
    {
      icon: EmissionIcon,
      alt: "emission page link icon",
      path: "/emission",
      page: PAGES.EMISSION,
      shouldShow: (vesselId) => {
        if(vesselId == null) // if no selected vessel
          return emissionEnabledVesselIds.length > 0; // show emissions if any vessel in fleet has it
        return emissionEnabledVesselIds.some((v) => v === vesselId); // else, only show emissions if the selected vessel has it
      }
    },
    {
      icon: CIIIcon,
      alt: "cii page link icon",
      path: "/cii",
      page: PAGES.CII,
      requireVesselId: true,
      shouldShow: (vesselId) => {
        if(vesselId == null)
          return false;
        return syntheticaEnabledVesselIds.some((v) => v === vesselId);
      }
    },
    {
      icon: NewNavIcon,
      alt: "navigation page link icon in navbar",
      path: "/navigation",
      page: PAGES.NAVIGATION,
      requireVesselId: true,
      shouldShow: (vesselId) => vesselId!=null,
    },
    {
      icon: MonitoringIcon,
      alt: "monitoring page link icon in navbar",
      path: "/monitoring",
      page: PAGES.MONITORING,
      requireVesselId: true,
      shouldShow: (vesselId) => {
        if(vesselId == null || vesselId === "" || vessel == null)
          return false;
        return (vessel?.NoME > 0 || vessel?.NoDG > 0 || vessel?.NoSG > 0);
      },
    },
    {
      icon: EngineTrendIcon,
      alt: "engine page link icon in navbar",
      path: `/trend/${GRAPH_TYPE.ENGINE}/${ENGINE_CHART_TYPE.ENGINE_LOAD}`,
      page: PAGES.TREND,
      requireVesselId: true,
      shouldShow: (vesselId) => vesselId!=null,
    },
    {
      icon: LCO2Icon,
      alt: "Cargo page link icon in navbar",
      path: `/cargo/${CARGO_PAGES.TRANSPORT}`,
      page: PAGES.CARGO,
      requireVesselId: true,
      shouldShow: (vesselId) => {
        if(vesselId == null || vesselId === "" || vessel == null)
          return false;
        return (vessel?.NoTank > 0);
      }
    },
    {
      icon: AlarmIcon,
      alt: "alarm page link icon in navbar",
      path: "/alarm",
      page: PAGES.ALARM,
      requireVesselId: true,
      shouldShow: (vesselId) => vesselId!=null,
    },
    {
      icon: ChannelIcon,
      alt: "channel page link icon",
      path: "/channel",
      page: PAGES.CHANNEL,
      requireVesselId: true,
      shouldShow: (vesselId) => vesselId!=null,
    },
  ];

  useEffect(() => {
    if (!showEmission?.checkFlag) {
      const fetchData = async () => {
        const emissionAddonId = (await fetchAddon("emission"))[0]?.id;
        const emissionEnabledVesselIds = (await fetchVesselApplication(userContext.user.company_id))
          .map((e) => {
            if (e.addon.includes(emissionAddonId)) return e.vessel_id;
            return undefined;
          })
          .filter((e) => e !== undefined);
        dispatch(setEmissionAddonData({ emissionAddonId, emissionEnabledVesselIds }));

        if (emissionEnabledVesselIds.length === 0) {
          dispatch(setShowEmission({ showFlag: false, checkFlag: true }));
          return;
        }
        const checkAddon = fleetList.fleetNavigationData.map((e) => {
          if (emissionEnabledVesselIds.includes(e.vessel_id)) return true;
          return false;
        });
        if (checkAddon.some((e) => e === true)) {
          dispatch(setShowEmission({ showFlag: true, checkFlag: true }));
        } else {
          dispatch(setShowEmission({ showFlag: false, checkFlag: true }));
        }
      };
      fetchData();
    }
  }, [fleetList?.fleetNavigationData]);

  useEffect(() => {
    if (!showSynthetica?.checkFlag) {
      const fetchData = async () => {
        const syntheticaAddonId = (await fetchAddon("synthetica"))[0]?.id;
        const syntheticaEnabledVesselIds = (await fetchVesselApplication(userContext.user.company_id))
          .map((e) => {
            if (e.addon.includes(syntheticaAddonId)) return e.vessel_id;
            return undefined;
          })
          .filter((e) => e !== undefined);
        dispatch(setSyntheticaAddonData({ syntheticaAddonId, syntheticaEnabledVesselIds }));

        if (syntheticaEnabledVesselIds.length === 0) {
          dispatch(setShowSynthetica({ showFlag: false, checkFlag: true }));
          return;
        }
        const checkAddon = fleetList.fleetNavigationData.map((e) => {
          if (syntheticaEnabledVesselIds.includes(e.vessel_id)) return true;
          return false;
        });
        if (checkAddon.some((e) => e === true)) {
          dispatch(setShowSynthetica({ showFlag: true, checkFlag: true }));
        } else {
          dispatch(setShowSynthetica({ showFlag: false, checkFlag: true }));
        }
      };
      fetchData();
    }
  }, [fleetList?.fleetNavigationData]);

  useEffect(async () => {
    if (!comparisonVesselid) return;
    
    dispatch(fetchChartsAsync({ vesselId: comparisonVesselid }));

    const data = await fetchVessel(comparisonVesselid);
    setVessel(data.vessel);
  }, [comparisonVesselid]);

  return (
    <div className={classes.display}>
      <Drawer
        className={classes.sideBar}
        variant={isSmUp || sideBarStatus ? "permanent" : "temporary"}
        classes={{
          paper: classes.sideBarContent,
        }}
        position="static"
      >
        <List className={classes.listItem}>
          {sideBarSettings()
            .filter(
              (nav) => nav.shouldShow(comparisonVesselid)
            )
            .map((d, i) => (
              <IconButton
                className={`
                  ${classes.button}
                  ${
                    location.pathname.includes(d.page)
                      ? location.pathname.includes("/main")
                        ? classes.enableColorMap
                        : classes.enableColor
                      : location.pathname.includes("/main")
                        ? classes.disableColorMap
                        : classes.disableColor
                  }
                  ${
                    location.pathname.includes("/main") ? classes.opacityMap : classes.opacityNormal
                  }
                `}
                key={`side-bar-select-items${i}`}
                onMouseEnter={(el) => {
                  if (d.page === "trend") {
                    setAnchorEl(el.target);
                  }
                }}
                disableRipple={true}
              >
                {location.pathname.includes(d.page) ?
                  <>
                    <img className={classes.imagePosition} src={d.icon} alt={d.alt} />
                  </>
                :
                  <Link
                    to={
                      d.requireVesselId
                        ? `${d.path}/${comparisonVesselid}`
                        : d.page === "comparison" && !!comparisonVesselid
                        ? `${d.path}/${comparisonVesselid}`
                        : `${d.path}`
                    }
                  >
                    <img className={classes.imagePosition} src={d.icon} alt={d.alt} />
                  </Link>
                }
              </IconButton>
            ))}
        </List>
      </Drawer>
      <IconMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        vessel={vessel}
        location={location}
      />
    </div>
  );
};

SideBar.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SideBar);
