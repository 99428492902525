import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import localizationKeys from "../../../i18n/localizationKeys";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import { isPassedBaseTime } from "../../../constants/util";

const label = {
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "start",
  color: "#19b2ab",
};

const value = {
  fontFamily: "Inter",
  fontSize: "14px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#ffffff",
  fontWeight: "300",
};

const useStyles = makeStyles((theme) => ({
  dataSource: {
    textAlign: "right",
  },
  table: {
    width: "100%",
    // border: "solid white"
  },
  dataValue: {
    ...value,
  },
  offlineDataValue: {
    ...value,
    color: "#c8c8c8",
  },
  label: {
    ...label,
  },
  offlineLabel: {
    ...label,
    color: "#c8c8c8",
  },
  checkBox: {
    "&.MuiCheckbox-root": {
      color: "#19B2AB",
    },
    marginLeft: "-5px",
    marginRight: "-5px",
  },
  lineHeight: {
    lineHeight: "20px",
  },

  spacer: {
    height: "85px",
    // border: "solid white",
  },
  headSpacer: {
    height: "47px",
  },
  nameLabel: {
    fontSize: "18px",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    fontHeight: "normal",
    letterSpacing: "normal",
    color: "#19b2ab",
  },
  engineName: {
    width: "95px",
    minWidth: "88px",
    alignItems: "center",
  },

  nowrap: {
    whiteSpace: "nowrap",
  },
  baseContainer: {
    [theme.breakpoints.up('lg')]: {
      marginTop: "16px",
    },
    maxHeight: "165px",
  },
  centerLabel: {
    textAlign: "center",
    marginBottom: "5px",
    marginTop: "5px",
  },
}));

const CppAnglePanel = ({ cppAngles = [], i18n, t }) => {
  const classes = useStyles();
  const theme = useTheme();
  const checkSize = useMediaQuery(theme.breakpoints.up("lg"));

  const CppAngle = useCallback(() => {
    // check latest data DateTime, then if there are old date over 10 minutes, isOffline becomes true.
    const isOffline = cppAngles.some((d) => isPassedBaseTime(d?.dateTime));
    const displayPosition = (p) => {
      const space = i18n.language === "en-US" ? " " : "";
      let position = localizationKeys.Center_abbreviation;
      switch (p) {
        case "Port":
          position = localizationKeys.Port_abbreviation;
          break;
        case "Starboard":
          position = localizationKeys.Starboard_abbreviation;
          break;
        default:
          position = localizationKeys.Center_abbreviation;
      }
      return t(position) + space;
    };

    const checkNullValue = (cppAngles) => {
      const checker = cppAngles.filter((d) => d?.angle != null);
      return checker.length > 0;
    };

    return cppAngles?.length && checkNullValue(cppAngles) ? (
      <React.Fragment>
        <div className={classes.centerLabel}>
          <p className={classes.label}>{`${t(localizationKeys.CPP_label)}`}</p>
        </div>
        <table>
          <tbody>
            {cppAngles.map((d, i) => (
              <tr key={`cpp-angle-${i}`}>
                <td className={classes.dataSource}>
                  <p className={classes.label}>{`${displayPosition(d.position)} ${t(
                    localizationKeys.CPP_lower
                  )}:`}</p>
                </td>
                <td>
                  <p
                    className={clsx(
                      isOffline ? classes.offlineDataValue : classes.dataValue,
                      classes.nowrap
                    )}
                  >
                    {d?.angle != null ? `${d?.angle} deg` : `* deg`}
                  </p>
                </td>
              </tr>
            ))}
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    ) : null;
  }, [cppAngles]);

  return (
    <Grid
      container
      direction={checkSize ? "column" : "row"}
      justifyContent="space-around"
      alignItems="flex-start"
      className={classes.baseContainer}
    >
      <Grid lg={"auto"} md={5} sm={5} style={{ width: "100%" }}>
        <CppAngle />
      </Grid>
    </Grid>
  );
};

CppAnglePanel.propTypes = {
  cppAngles: PropTypes.array,
};

export default withTranslation()(CppAnglePanel);
