export const servicePolicyJn = `
ＪＲＣＳ株式会社（以下、「甲」といいます）は、甲が運営する「ＪＲＣＳ株式会社  ｉｎｆｏｃｅａｎｕｓ  ｃｏｎｎｅｃｔ」上で提供するサービス（以下、「本サービス」といいます）の申込み希望者及び利用者（以下、「乙」といいます）が本サービスを利用することに関して以下のとおり利用規約（以下、「本利用規約」といいます）を定めます。
乙は、本利用規約及び甲が別途定めるプライバシーポリシー（https://www.jrcs.co.jp/info/privacypolicy.html 以下、「プライバシーポリシー」といいます）の条項に同意したうえで、本サービスの申込みをするものとします。

第１条（本利用規約の範囲）
  本利用規約適用の範囲には、本サービスのウェブページ（以下、「本ウェブ」といいます）のオンラインサービス利用に加え、電子メール等を介した本サービスに関連する甲乙間のやりとりも含みます。

第２条（会員登録）
１．乙は、甲が定める手段にて本サービスの会員登録申込みを行うものとします。なお、乙は会員登録にあたり、下記の事項を確認し、同意するものとします。甲は、乙が下記の事項に違反したことによる損害について責任を負いません。
（１）甲が推奨するＯＳを搭載した端末を用意すること。
（２）甲が推奨する種類・バージョンのウェブブラウザをインストールすること。
（３）甲が推奨する通信環境を用意すること。
（４）甲が提供する本サービスのうち、有料サービスについては、利用料金を本利用規約第５条に定める決済方法により支払うこと。
（５）本サービスの品質管理及びトラブル防止のため、甲が乙のログイン履歴やログイン時に使用していた端末等の記録、サービス内容等必要な情報を確認・記録することがあること。
（６）甲が乙に対し、電子メールを配信し、電子メールによる通知をすること。乙は甲より配信・通知された電子メールを受け取ることができる電子メールアドレスを準備するものとする。
（７）カスタマーサポートによる応対の品質向上等のため、甲は乙の問い合わせ内容等を記録、録音、保管できること。
（８）甲は本サービスに関する電子メールや本ウェブ・アプリケーション等による通知、広告、アンケート等を実施することができること。
２．会員登録は、乙が、甲に対して甲所定の方法により申込を行い、甲から送信されたメール内に記載されたＵＲＬへアクセスし、ログインＩＤとパスワードを設定することにより、完了するものとします。
３．乙が下記に定める事由に該当する場合、甲は、会員登録を拒否することができ、また、登録がすでに完了した場合でも、当該会員登録を取消すことができます。
（１）登録の際、乙の提供した情報（以下、「アカウント情報」といいます。）について虚偽の記載、誤記または記入漏れをした場合。
（２）過去に本利用規約に違反したことがある場合。
（３）乙が利用料金やオプション代金の支払いを怠っていた場合。
（４）甲の推奨する利用端末やブラウザ環境、回線速度等を満たしていない場合。
（５）複数の利用者が同一のアカウントを使用し本サービスを利用していることが明らかな場合または複数のアカウントを一人のユーザーが利用していることが明らかな場合。
（６）その他、本サービスの利用者として不適当であると甲が判断する場合。
４．乙は会員登録後、第６条の内容に従い、本サービスの利用を開始できるものとします。
５．乙が本サービスの登録に使用したメールアドレス、ログインＩＤ、パスワード等ログインまたは本サービスの利用に必要な情報（以下、総称して「パスワード等」といいます）は、乙が自己の責任をもって厳重に管理しなければなりません。
%甲は、ログイン時に入力されたパスワード等と登録されたパスワード等との一致をもって本サービスの利用が乙本人によるものであるとみなすことができます。
６．乙は、パスワード等を第三者に使用させてはなりません。また、第三者への譲渡・貸与等は一切禁止します。
７．乙は、パスワード等を紛失もしくは失念した場合またはパスワード等が第三者に不正に使用されている疑いがある場合、甲に対して速やかに連絡を行い、甲の指示等に従わなければなりません。なお、甲は、乙が連絡を遅滞したことにより生じる全ての損害等について賠償する義務を負いません。
８．乙はセキュリティ上の観点から、他のウェブサイトやサービスで使用しているものとは異なる独自のパスワード等を本サービスで設定、使用するものとします。
９．乙による管理不十分や第三者による使用から生じた損害については乙が責任を負うものします。

第３条(登録情報の変更)
乙は、アカウント情報に変更の必要性が生じた場合、甲が定める方法によって遅滞なく登録情報の変更手続きをするものとします。なお、甲は、乙が同変更手続きを遅滞しまたは怠ったことにより損害を被った場合でも、かかる損害に対して一切責任を負わないものとします。

第４条(利用期間)
乙は、会員登録の有効期間中、本サービスを利用できるものとします。

第５条(利用料金)
乙は、本サービス中の有料サービスについては、当該サービスの利用料金について、甲が別途定める方法により、利用開始前に支払手続きを完了するものとします。

第６条(サービス)

１．乙は、本サービス中の無料サービスについては、会員登録後、直ちに利用できるものとします。
２．乙は、本サービス中の有料サービスについては、会員登録後、第５条に従い、利用料金支払手続き完了後に利用できるものとします。
３．乙は、甲の指定する方法で、本サービス利用時までに、使用する端末、ＯＳ、通信環境等に問題がないか、本サービス利用毎に必ず確認するものとします。
４．本サービス中にダウンロードできるドキュメント等がある場合には、乙は、これをダウンロードして、本サービス利用の際に閲覧可能な状態にします。

第７条(システムの利用と利用推奨環境について)
１．本サービスは、ＨＴＭＬ５に準拠する技術を利用して提供されます。乙は、その利用に際し、下記の内容について同意しなければなりません。
（１）甲の推奨環境を整えること。

（２）推奨ブラウザのダウンロード、インストール、設定、使用等について、すべて自己の責任において行うこと。
（３）サービス開始後に発生した推奨ブラウザやＨＴＭＬ、その他アプリケーション等に起因する不具合や問題について、甲は一切の責任を負わないこと。
（４）推奨ブラウザやＨＴＭＬによって定義されたサービス・機能に関する相談、問い合わせ等について、甲が一切対応できないこと。
２．本サービスの利用にあたり、乙は、甲の指定する推奨環境に準拠するものとします。甲の定める推奨環境外で本サービスを利用した場合、いかなる場合においても補償を行なわないものとします。甲の指定する推奨環境は以下のとおりとします。

%利用推奨環境
%---------------------------------------------------------------------------
%【ＯＳ】
%下記ブラウザが推奨する環境
%
%【ブラウザ】
%Ｇｏｏｇｌｅ Ｃｈｒｏｍｅ最新版、 Ｍｉｃｒｏｓｏｆｔ Ｅｄｇｅ 最新版、Ｓａｆａｒｉ最新版、Ｍｏｚｉｌｌａ Ｆｉｒｅｆｏｘ最新版のいずれか。
%※端末に推奨ブラウザがない場合は新たにインストールが必要となります。
%
%【インターネット通信】
%ブロードバンド（光ファイバー）、ＣＡＴＶ、モバイル回線など
%※タブレットを使用してモバイル回線から利用する場合など、従量制のインターネット通信サービスをご利用の際は通信料に注意すること。
%タブレットからサービスを利用する場合はブロードバンド回線へＷｉ－Ｆｉから接続の上、利用されることをお勧めします。
%
%---------------------------------------------------------------------------
３．乙の利用する端末にインストールされている他のアプリケーションや、プラグイン等に起因する問題でサービスシステムの利用に支障が出た場合、甲は問い合わせ対応や補償等に一切対応できないものとします。

第８条(禁止行為)
１．乙は、本サービスの利用に際して、以下に定める行為を行ってはなりません。ただし、甲が、乙に対し、書面（電磁的方法を含みます。）により別途明示的に認めた場合を除きます。
（１）本サービスを利用する権利を第三者に譲渡、貸与、売買、名義変更、質権の設定、担保に供する行為。
（２）パスワード等を第三者に譲渡、貸与等する行為または第三者に使用させる行為。
（３）甲の名誉、信用、著作権、特許権、実用新案権、意匠権、商標権その他の権利、第三者のプライバシー権、肖像権その他の権利を侵害する行為。
（４）違法行為、公序良俗に反する行為。
（５）甲のサーバーに無権限・無許可でアクセスし、または不正の操作を行うなど本サービスの利用または運営に支障をきたす行為。
（６）本サービスを営業行為、営利目的またはその準備に利用する行為。
（７）本サービスの他の利用者に違法行為を勧誘または助長する行為。
（８）本サービスの他の利用者が経済的・精神的損害、その他不利益を被る行為。
（９）その他本サービスを本来の目的以外の目的で利用する行為。
（10）犯罪行為または犯罪行為に結びつく行為。
（11）甲の機密情報（第２０条にて定義します）を詮索する行為。
（12）甲のサポートセンターへのスタッフへの暴言・脅迫行為、またはサポート業務の進行を妨げる行為。
（13）一つのアカウントを複数のユーザーで利用する行為。
（14）一人のユーザーが複数のアカウントを登録する行為。
（15）その他、甲が不適当と判断する行為。
２．乙は、前項各号に違反する行為に起因して甲または第三者に損害を生じさせた場合、本サービスの退会後であっても、一切の法的責任を負うものとします。

第９条(禁止行為への対応)
１．甲は、乙が下記のいずれかに該当した場合、乙へ事前の通知をすることなしに、本サービスの提供の中断、会員登録の抹消（退会処分）等、甲が適当と判断する措置を講ずることができます。
（１）乙が第８条に定める禁止行為を行った場合。
（２）乙が本利用規約の規定のいずれかに違反した場合。
（３）乙が利用料金やオプション代金の支払を遅滞しまたは怠った場合。
（４）乙が本サービスの利用に際し、甲からの指示等に従わなかった場合。
（５）その他甲が乙による本サービスの利用を不適切と判断した場合。
２．乙が前項に基づき甲から処分を受けた場合、甲は、乙に対して、乙がすでに支払った利用料金を一切返金しないものとします。

第１０条(サービス内容変更について)
甲は、乙に通知した上で、本サービスの内容を変更することがあります。

第１１条(退会について)
１．乙は、甲の定める方法により、退会の手続きを行うものとし、その場合、甲は、乙の申出に従って、乙のアカウント情報を抹消するものとします。
２．退会手続きが完了すると、乙はログインや利用履歴の閲覧ができなくなるものとします。

第１２条(電子メールによる通知)
１．甲は、本サービスに関する情報を送信する場合、乙が登録した電子メールアドレス（以下、「指定メールアドレス」といいます）宛に通知すれば足りるものとします。
なお、本利用規約の変更に関する取り扱いは、本利用規約第１９条に定めるとおりとします。
２．甲の乙に対する通知は、指定メールアドレス宛の発信をもって完了したものとみなします。
３．乙は、必要がある場合には、指定メールアドレスに関する各種設定等を変更し、甲（ドメイン名： jrcs.co.jp）からの電子メールの受信を許可しなければなりません。
４．甲は、指定メールアドレスに不備、誤記があったことまたは乙が受信設定の変更を怠ったことに起因して、甲からの電子メールが乙に不達となった場合でも、一切責任を負わないものとします。
５．甲は、乙に対して電子メールによる広告やアンケート、サービス利用促進等を行うことができます。

第１３条(登録情報の取り扱い) 
甲は、乙のアカウントの登録情報及び利用履歴・本サイトの閲覧履歴を、本利用規
約及び甲が別途定めるプライバシーポリシープライバシーポリシー（https://www.jrcs.co.jp/info/privacypolicy.html 以下「プライバシーポリシー」という）の記載に基づき取扱うものとします。

第１４条(本サービスの中断・終了)
甲は、事前に本ウェブ上での掲示または乙に通知した上で、本サービスを中断または終了できるものとします。また、ＨＴＭＬやアプリケーションの障害、国内外の政治情勢・台風や地震、豪雨、落雷などの自然災害、火事や暴動・紛争などの人為的災害、停電などの影響や提供するサーバー等の障害、スタッフへの研修、またはその他甲の責に帰すことのできない事由により本サービスの提供が困難な場合、予告なしに本サービスを中断することができます。

第１５条(損害賠償責任)
１．甲は、乙が本利用規約に違反した場合、乙に対して、同違反行為により生じた一切の損害の賠償を請求できるものとします。
２．本サービスの不備や債務不履行に基づく乙の甲に対する損害賠償の範囲は、甲に故意または重過失がある場合を除き、乙の直接かつ現実に被った範囲の損害に限られるものとし、当該損害が発生した月に乙が甲に対し支払った本サービスの利用料金を上限とします。

第１６条(知的財産権等)
１．本サービス又は本サービス上のあらゆるコンテンツ（船舶等のデータベースを含みますが、これに限られません。以下、総称して「コンテンツ」といいます。）に関する著作権（著作権法第２７条及び第２８条に規定する権利を含みます。以下本条において同様とします。）等の知的財産権その他の権利は、甲又は甲にライセンスを許諾している者に帰属するものとします。また、本サービスに関する商標、ロゴマーク、記載等についての著作権、商標権等の知的財産権その他の権利については全て甲に帰属します。
 
２．乙は、本サービス又はコンテンツについて、乙自身が利用する目的以外に、使用、複製その他の利用をすることはできません。また、甲の指定する方法でのみ利用することができるものとします。
３．乙は、本サービス又はコンテンツの複製物、本サービスの利用権等を第三者に販売、贈与または貸与（有償・無償を問いません）することは、方法・理由の如何を問わず一切できません。
４．甲は、乙が本条各項に違反した場合、乙に対して法的措置（警告、刑事告訴、損害賠償請求、使用差止請求、名誉回復措置等を含みますがこれらに限定されません）をとることができるものとします。この場合、乙は、甲の被ったあらゆる損害を賠償し、甲の指示に従い甲の被害を回復するための措置をとるものとします。

第１７条(免責事項)
１．乙は、下記の各条項に定める事項が生じた場合について、甲がいかなる補償も行わないことに予め同意します。
（１）本サービスの利用に際し、満足な利用ができなかった場合（以下の場合を含むが、これらに限定されません）。
①  急激な本サービスの利用者数の増加、または本利用規約第１４条第２項に定める事由により、提供サービス数が不足したことに起因する場合。
②  乙が希望する特定の時間帯のサービスが予約できなかった場合。
③  本利用規約第１４条に定める事由による場合。
④  乙のメッセージやデータへの不正アクセスや不正な改変、その他第三者による行為に起因する場合。
⑤  船舶の搭載機器や通信環境に起因する場合。
（２）甲がシステムの保守を定期的に、または緊急で行う場合。
（３）乙の自己責任で受信した、または、開いたファイル等が原因となりウィルス感染などの損害が発生した場合。
（４）乙の過失によるパスワード等の紛失または使用不能により本サービスが利用できなかった場合。
（５）本ウェブで提供するすべての情報、リンク先等の不完全性、不正確性、非最新性、危険性等。
（６）本ウェブから、または本ウェブへリンクしている甲以外の第三者が運営するウェブサイトの利用に起因して本サービスの一部が利用できなかった場合、または当該ウェブサイトで本サービス上、提供されているとされたサービスが提供されていなかった場合。
（７）決済方法に伴うトラブルや不具合が生じた場合
（８）その他甲の責任によらない事由で本サービスの提供が困難となった場合。
２．甲は、乙に対し、本サービスが利用法人又は利用者の特定の目的に適合すること、正確性を有すること、その他本サービスの機能・内容について何らの表明または保証も行わないものとします。


第１８条(本サービスの日時表示)
本サービス及び本利用規約の表記上において、会員登録日、利用開始日、利用開始月、各種利用料金支払の期日、各種申請等の締切日等の日時は、全て日本時間（ＵＴＣ＋９）を基準とします。

第１９条(本利用規約の変更)
１．甲は、本利用規約を変更することができるものとします。本利用規約を変更した場合には、乙に本利用規約の変更内容を本ウェブもしくは電子メールにて通知するものとし、本該変更内容の通知後、乙が本利用規約第１１条に定める退会の手続きをせず本サービスを継続利用した場合、乙は変更後の本利用規約に同意したものとみなします。
２．強行法規の改正等により、本利用規約の一部が同法規に抵触する場合には、当該部分を同法規の定めに従い変更したものとします。

第２０条（機密保持）
  乙は、本サービスの利用を通じて知り得た甲の情報でその性質上守秘すべきと認められるもの（以下、「機密情報」といいます）について、甲の事前の承諾なく第三者に開示もしくは漏洩し、または本サービスの利用以外の目的で使用してはならないものとします。

第２１条（分離可能性）
  万一本利用規約の一部が無効と判断された場合でも、本利用規約の他の部分は有効に存続するものとします。

第２２条(準拠法及び専属的合意管轄裁判所)
１．本利用規約は、日本法を準拠法とします。また、本サービスに関連して、本利用規約に定めのない事項または疑義を生じた事項については、甲乙両者誠意をもって協議するものとします。
２．前項による協議にもかかわらず紛争となった場合、訴額に応じて東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
`;
