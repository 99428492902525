import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import ElectricChart from "./electricChart.jsx";
import { checkCondition, inlinePaper } from "./electricUtil";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { GENERATOR_TYPE } from "../../../constants/monitoring/constants.js";

const useStyles = ({ isBarChart }) =>
  makeStyles((theme) => ({
    inlinePaper: {
      ...inlinePaper(theme),
    },
    container: {
      height: "100%",
      width: "100%",
    },
    grid: {
      display: "flex",
      flexFlow: "column",
      justifyContent: isBarChart ? "start" : "center",
    },
  }));

const SgChart = ({
  engineData = [],
  sgMaxKw,
  isNoData,
  isLoading,
  isInitialLoading,
  selectedChart,
}) => {
  if (!engineData?.length) return null;

  const isBarChart = selectedChart === "bar";
  const classes = useStyles({ isBarChart })();

  return (
    <React.Fragment>
      {engineData.map((d) => (
        <Grid className={classes.grid} key={`shaft-generator-grid-${d.generatorNumber}`}>
          <ElectricChart
            key={`shaft${d.generatorNumber}`}
            title={`${d.generatorNumber} S/G`}
            data={d.sgPower}
            limit={sgMaxKw}
            type={GENERATOR_TYPE.SG}
            condition={checkCondition(d.sgacb)}
            sizeRatio={isBarChart ? 0.55 : 0.8}
            className={classes.inlinePaper}
            isLoading={isLoading}
            isNoData={isNoData || d?.isNoData}
            isInitialLoading={isInitialLoading}
          />
        </Grid>
      ))}
    </React.Fragment>
  );
};

SgChart.propTypes = {
  engineData: PropTypes.array,
  isLoading: PropTypes.bool,
  isNoData: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
  sgMaxKw: PropTypes.number,
};

export default SgChart;
