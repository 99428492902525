//Worker imports
import EngineLoadWorker from "./workers/engineLoad.worker.js";
import EngineDeviationWorker from "./workers/engineDeviation.worker.js";
import EngineWaterWorker from "./workers/engineWater.worker.js";
import EngineTempWorker from "./workers/engineTemp.worker.js";
import EnginePressWorker from "./workers/enginePress.worker";

export const engineLoadReducer = async (
  mainEngineData,
  engineData,
  gpvtgData,
  startDate,
  endDate
) => {
  const engineLoadWorker = EngineLoadWorker();
  engineLoadWorker.postMessage({
    mainEngineData,
    engineData,
    gpvtgData,
    startDate,
    endDate,
  });
  const returnPromise = new Promise(function (resolve, reject) {
    engineLoadWorker.onmessage = (event) => {
      const { mergedEngineData } = event.data;
      engineLoadWorker.terminate();
      resolve(mergedEngineData);
    };
  });

  return returnPromise;
};

export const engineDeviationReducer = async (
  mainEngineData,
  engineData,
  gpvtgData,
  startDate,
  endDate,
  keys
) => {
  const engineDeviationWorker = EngineDeviationWorker();
  engineDeviationWorker.postMessage({
    mainEngineData,
    engineData,
    startDate,
    endDate,
    keys,
  });

  const returnPromise = new Promise(function (resolve, reject) {
    engineDeviationWorker.onmessage = (event) => {
      const { mergedEngineData } = event.data;
      engineDeviationWorker.terminate();
      resolve(mergedEngineData);
    };
  });
  return returnPromise;
};

export const engineWaterReducer = async (
  mainEngineData,
  engineData,
  gpvtgData,
  startDate,
  endDate,
  keys
) => {
  const engineWaterWorker = EngineWaterWorker();
  engineWaterWorker.postMessage({
    mainEngineData,
    engineData,
    startDate,
    endDate,
    keys,
  });

  const returnPromise = new Promise(function (resolve, reject) {
    engineWaterWorker.onmessage = (event) => {
      const { mergedEngineData } = event.data;
      engineWaterWorker.terminate();
      resolve(mergedEngineData);
    };
  });

  return returnPromise;
};

export const engineTempReducer = async (
  mainEngineData,
  engineData,
  gpvtgData,
  startDate,
  endDate,
  keys
) => {
  const engineTempWorker = EngineTempWorker();
  engineTempWorker.postMessage({
    mainEngineData,
    engineData,
    startDate,
    endDate,
    keys,
  });

  const returnPromise = new Promise(function (resolve, reject) {
    engineTempWorker.onmessage = (event) => {
      const { mergedEngineData } = event.data;
      engineTempWorker.terminate();
      resolve(mergedEngineData);
    };
  });

  return returnPromise;
};

export const enginePressReducer = async (
  mainEngineData,
  engineData,
  gpvtgData,
  startDate,
  endDate,
  keys
) => {
  const enginePressWorker = EnginePressWorker();
  enginePressWorker.postMessage({
    mainEngineData,
    engineData,
    startDate,
    endDate,
    keys,
  });

  const returnPromise = new Promise(function (resolve, reject) {
    enginePressWorker.onmessage = (event) => {
      const { mergedEngineData } = event.data;
      enginePressWorker.terminate();
      resolve(mergedEngineData);
    };
  });

  return returnPromise;
};
