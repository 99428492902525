export const SHIP_TYPE = {
  0: "NotAvailable",
  20: "WingInGround",
  21: "WingInGroundHazardousCategoryA",
  22: "WingInGroundHazardousCategoryB",
  23: "WingInGroundHazardousCategoryC",
  24: "WingInGroundHazardousCategoryD",
  25: "WingInGroundReserved1",
  26: "WingInGroundReserved2",
  27: "WingInGroundReserved3",
  28: "WingInGroundReserved4",
  29: "WingInGroundReserved5",
  30: "Fishing",
  31: "Towing",
  32: "TowingLarge",
  33: "DredgingOrUnderwaterOps",
  34: "DivingOps",
  35: "MilitaryOps",
  36: "Sailing",
  37: "PleasureCraft",
  38: "Reserved1",
  39: "Reserved2",
  40: "HighSpeedCraft",
  41: "HighSpeedCraftHazardousCategoryA",
  42: "HighSpeedCraftHazardousCategoryB",
  43: "HighSpeedCraftHazardousCategoryC",
  44: "HighSpeedCraftHazardousCategoryD",
  45: "HighSpeedCraftReserved1",
  46: "HighSpeedCraftReserved2",
  47: "HighSpeedCraftReserved3",
  48: "HighSpeedCraftReserved4",
  49: "HighSpeedCraftNoAdditionalInformation",
  50: "PilotVessel",
  51: "SearchAndRescueVessel",
  52: "Tug",
  53: "PortTender",
  54: "AntiPollutionEquipment",
  55: "LawEnforcement",
  56: "SpareLocalVessel1",
  57: "SpareLocalVessel2",
  58: "MedicalTransport",
  59: "NonCombatantShip",
  60: "Passenger",
  61: "PassengerHazardousCategoryA",
  62: "PassengerHazardousCategoryB",
  63: "PassengerHazardousCategoryC",
  64: "PassengerHazardousCategoryD",
  65: "PassengerReserved1",
  66: "PassengerReserved2",
  67: "PassengerReserved3",
  68: "PassengerReserved4",
  69: "PassengerNoAdditionalInformation",
  70: "Cargo",
  71: "CargoHazardousCategoryA",
  72: "CargoHazardousCategoryB",
  73: "CargoHazardousCategoryC",
  74: "CargoHazardousCategoryD",
  75: "CargoReserved1",
  76: "CargoReserved2",
  77: "CargoReserved3",
  78: "CargoReserved4",
  79: "CargoNoAdditionalInformation",
  80: "Tanker",
  81: "TankerHazardousCategoryA",
  82: "TankerHazardousCategoryB",
  83: "TankerHazardousCategoryC",
  84: "TankerHazardousCategoryD",
  85: "TankerReserved1",
  86: "TankerReserved2",
  87: "TankerReserved3",
  88: "TankerReserved4",
  89: "TankerNoAdditionalInformation",
  90: "OtherType",
  91: "OtherTypeHazardousCategoryA",
  92: "OtherTypeHazardousCategoryB",
  93: "OtherTypeHazardousCategoryC",
  94: "OtherTypeHazardousCategoryD",
  95: "OtherTypeReserved1",
  96: "OtherTypeReserved2",
  97: "OtherTypeReserved3",
  98: "OtherTypeReserved4",
  99: "OtherTypeNoAdditionalInformation",
};

export const SHIP_GROUP_TYPE = {
  //Cargo Type
  Cargo: "Cargo",
  CargoHazardousCategoryA: "Cargo",
  CargoHazardousCategoryB: "Cargo",
  CargoHazardousCategoryC: "Cargo",
  CargoHazardousCategoryD: "Cargo",
  CargoReserved1: "Cargo",
  CargoReserved2: "Cargo",
  CargoReserved3: "Cargo",
  CargoReserved4: "Cargo",
  CargoNoAdditionalInformation: "Cargo",

  //Fishing Type
  Fishing: "Fishing",

  //High speed Type
  HighSpeedCraft: "HighSpeed",
  HighSpeedCraftHazardousCategoryA: "High Speed",
  HighSpeedCraftHazardousCategoryB: "High Speed",
  HighSpeedCraftHazardousCategoryC: "High Speed",
  HighSpeedCraftHazardousCategoryD: "High Speed",
  HighSpeedCraftReserved1: "High Speed",
  HighSpeedCraftReserved2: "High Speed",
  HighSpeedCraftReserved3: "High Speed",
  HighSpeedCraftReserved4: "High Speed",
  HighSpeedCraftNoAdditionalInformation: "High Speed",

  //Tanker Type
  Tanker: "Tanker",
  TankerHazardousCategoryA: "Tanker",
  TankerHazardousCategoryB: "Tanker",
  TankerHazardousCategoryC: "Tanker",
  TankerHazardousCategoryD: "Tanker",
  TankerReserved1: "Tanker",
  TankerReserved2: "Tanker",
  TankerReserved3: "Tanker",
  TankerReserved4: "Tanker",
  TankerNoAdditionalInformation: "Tanker",

  //TugPilot type
  Tug: "Tug Pilot",
  PilotVessel: "Tug Pilot",

  //Passenger type
  Passenger: "Passenger",

  //Yacht type
  PleasureCraft: "Yacht",
  Sailing: "Yacht",

  //Other
  NotAvailable: "",
  WingInGround: "",
  WingInGroundHazardousCategoryA: "",
  WingInGroundHazardousCategoryB: "",
  WingInGroundHazardousCategoryC: "",
  WingInGroundHazardousCategoryD: "",
  WingInGroundReserved1: "",
  WingInGroundReserved2: "",
  WingInGroundReserved3: "",
  WingInGroundReserved4: "",
  WingInGroundReserved5: "",
  Towing: "",
  TowingLarge: "",
  DredgingOrUnderwaterOps: "",
  DivingOps: "",
  MilitaryOps: "",
  Reserved1: "",
  Reserved2: "",
  SearchAndRescueVessel: "",
  PortTender: "",
  AntiPollutionEquipment: "",
  LawEnforcement: "",
  SpareLocalVessel1: "",
  SpareLocalVessel2: "",
  MedicalTransport: "",
  NonCombatantShip: "",
  PassengerHazardousCategoryA: "",
  PassengerHazardousCategoryB: "",
  PassengerHazardousCategoryC: "",
  PassengerHazardousCategoryD: "",
  PassengerReserved1: "",
  PassengerReserved2: "",
  PassengerReserved3: "",
  PassengerReserved4: "",
  PassengerNoAdditionalInformation: "",
  OtherType: "",
  OtherTypeHazardousCategoryA: "",
  OtherTypeHazardousCategoryB: "",
  OtherTypeHazardousCategoryC: "",
  OtherTypeHazardousCategoryD: "",
  OtherTypeReserved1: "",
  OtherTypeReserved2: "",
  OtherTypeReserved3: "",
  OtherTypeReserved4: "",
  OtherTypeNoAdditionalInformation: "",
};
