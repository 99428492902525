import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import dayjs from "util/dayjs-init.js";

import { CARGO_STYLES } from "../../../constants/cargo/styles";
import MaximizeIcon from "../../trendPage/icon/maximizeIcon.jsx";
import MinimiseIcon from "../../trendPage/icon/minimiseIcon.jsx";

import { BarChart } from "../../chart/barChart";
import { CARGO_CHARTS } from "../../../constants/cargo/constants";
import { DateTimePicker } from "../../dateTimePicker/index.jsx";
import { CsvDownload } from "../../trendPage/commonComponent/csvDownload.jsx";
import { DateTimeDisplay } from "../../dateTimePicker/dateTimeDisplay.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setNewShorthand } from "../../../model/slice/dateTimeShorthandSlice";
import { DATE_FORMAT } from "../../../constants/constants";
import { fetchTemperatureTrendData } from "../useTrendCargoData";
import { NoData } from "../../offlineComponent/noData";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { useTranslation } from "react-i18next";
import ResetIcon from "../../../../assets/images/alarmIcon/reset-icon.svg";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType.js";
import { formatDate } from "../../../util/timezone";
import { selectedTimezone } from "../../../model/slice/timezoneSlice";

const useStyles = makeStyles(() => ({
  container: {
    ...CARGO_STYLES.paper,
    paddingBottom: "20px",
  },
  titleContainer: {
    padding: "10px 24px",
    height: "43px",
    borderBottom: "solid 1.3px #222831",
    display: "flex",
    alignItems: "center",
  },
  title: {
    width: "60%",
  },
  picker: {
    width: "40%",
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    "& div": {
      marginLeft: "12px",
      width: "25px",
      height: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      "& img": {
        cursor: "pointer",
      },
    },
  },
  contentContainer: {
    width: "100%",
    height: "calc(100% - 43px)",
    padding: "5px 0 0 24px",
  },
  dateTimeDisplay: {
    display: 'flex',
    margin: "0 20px 0 auto",
  },
  goLatestButton: {
    display: "flex",
    padding: "5px 7px 5px 7px",
    border: 'solid 1px white',
    marginRight: "50px",
    cursor: "pointer",
    alignItems: "center",
    fontSize: "11px",
  }
}));

export const Temperature = memo((props) => {
  const { vesselId, isMaximized, setMaximize, latestData } = props;
  const { t } = useTranslation();
  const styles = useStyles();
  const dispatch = useDispatch();
  const [duration, setDuration] = useState(null);
  const [goLatest, setGoLatest] = useState(true);
  const [isLatest, setIsLatest] = useState(false);
  const [latestDateTime, setLatestDateTime] = useState();
  const timezone = useSelector(selectedTimezone);

  const tempData = fetchTemperatureTrendData({
    vesselId,
    duration,
    goLatest,
    setGoLatest,
    isLatest,
    latestData,
  });

  const [selectedDate, setSelectedDate] = useState(null);

  const formatDataForCSV = (data) => {
    const processedValue = data.reduce((previous, current) => {
      return Object.assign(previous, {[current.tag]: current.value});
    }, {});
    const returnValue = Object.assign(processedValue, {dateTime: selectedDate})
    return [returnValue];
  }
  
  const backToLatest = () => {
    if(isLatest || tempData.isLoading) return;
    setGoLatest(true);
  };

  useEffect(() => {
    setLatestDateTime(latestData[0]?.dateTime ? formatDate(latestData[0]?.dateTime, timezone, DATE_FORMAT) : null);
  }, [latestData]);

  useEffect(() => {
    if(goLatest || isLatest){
      setSelectedDate(latestDateTime ?? new Date(dayjs.utc().subtract(1, 'm')));
    }
  }, [latestDateTime, goLatest])

  useEffect(() => {
    setIsLatest(selectedDate === latestDateTime);
  }, [latestDateTime, selectedDate]);

  useEffect(() => {
    if(!selectedDate) return;
    const startDate = dayjs.utc(selectedDate).format(DATE_FORMAT);
    const endDate = dayjs.utc(selectedDate).endOf("m").format(DATE_FORMAT);

    setDuration({ startDate, endDate });
  }, [selectedDate]);

  const updateSelectedDate = (newDateTime) => {
    if (selectedDate !== newDateTime && newDateTime !== "") {
      setSelectedDate(formatDate(newDateTime, timezone, DATE_FORMAT));
    }
  }

  const submitDateTimeChangeForShorthand = (newDateTime) => {
    updateSelectedDate(newDateTime);
  };

  const submitDateTimeChange = (newDateTime, newShort, newLong) => {
    updateSelectedDate(newDateTime);
    dispatch(setNewShorthand({ short: newShort, long: newLong }));
  };

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={styles.titleContainer}>
        <div className={styles.title}>{t(localizationKeys.CargoTemperature)}</div>
        <div className={styles.picker}>
          <div>
            <DateTimePicker
              date={selectedDate}
              submitDateTimeChange={submitDateTimeChange}
              isMaximized={isMaximized}
              latestDateTime={latestDateTime}
            />
          </div>
          <div>
            <CsvDownload
              vesselId={vesselId}
              graphData={formatDataForCSV(tempData.cargoTempData)}
              graphType={GRAPH_TYPE.CARGO}
              selectedChart={"temperature"}
              isCargo={true}
            />
          </div>
          <div onClick={() => setMaximize(CARGO_CHARTS.TEMPERATURE)}>
            {isMaximized
              ? <MinimiseIcon width={20} height={20} disabled={tempData?.isLoading} />
              : <MaximizeIcon width={20} height={20} disabled={tempData?.isLoading} />
            }
          </div>
        </div>
      </Grid>
      <Grid className={styles.dateTimeDisplay}>
        <div className={styles.goLatestButton} 
          style={{opacity: isLatest || tempData.isLoading ? 0.5 : 1.0}} 
          onClick={() => {backToLatest()}}
        >
          <img src={ResetIcon} width={20} height={20} style={{marginRight: "2px"}}/>
          <span>{t(localizationKeys.BackToLatest)}</span>
        </div>
        <DateTimeDisplay
          date={selectedDate}
          submitDateTimeChange={submitDateTimeChangeForShorthand}
          latestDateTime={latestDateTime}
          timezone={timezone}
        />
      </Grid>
      <Grid container className={styles.contentContainer}>
        {
          tempData.isLoading
            ? <div style={{margin: 'auto'}}><CircularProgress /></div>
            : tempData.cargoTempData?.length > 0 ? (
              <BarChart data={tempData.cargoTempData} />
              ) : (
              <NoData />
          )
        }
      </Grid>
    </Grid>
  );
});

Temperature.propTypes = {
  vesselId: PropTypes.string,
  isMaximized: PropTypes.bool,
  setMaximize: PropTypes.func,
  latestDateTime: PropTypes.string,
  latestData: PropTypes.array,
};
