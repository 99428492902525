import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys.js";
import AlarmSearch from "../search/search.jsx";
import {
  ALARM_PAGE,
  hasFilterActive,
  setSearchButtonFlg,
  setCurrentHistoryPage,
  setCurrentOperationPage,
  searchButtonClicked,
  getSearchButtonSelector,
} from "../../../model/slice/alarmSlice.js";
import { ResetIcon } from "../resetIcon.jsx";
import Category from "./category.jsx";

const useStyles = makeStyles((theme) => ({
  base: {
    width: "100%",
    margin: "8px",
  },
  containerAlarmSearch: {
    width: "100%",
    marginBottom: "24px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gap: "2%"
    },
  },
  container: {
    display: "flex",
    width: "100%",
    alignItems: "start",
    flexBasis: "45%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
    },
  },
  filter: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "0px",
    },
  },
  control: {
    marginLeft: "10px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "end",
      marginBottom: "8px",
    },
  },
  resetButton: {
    display: "block",
    width: "124px",
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "10px",
    fontWeight: "300",
    color: "#19b2ab",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
      gap: "6px",
      marginLeft: "12px",
    },
  },
  buttonContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "end",
    },
  },
  submitButton: {
    width: "100px",
    height: "34px",
    borderRadius: "5px",
    background: "#19b2ab",
    textTransform: "none",
    fontSize: "12px",
    color: "#21262f",
    "& span": {
      marginLeft: "0px",
    },
    "&:hover": {
      background: "#19b2ab",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "10px",
    },
  },
}));

const AlarmTopNavigation = ({
  t,
  currentPage,
  setDurationFilter,
  durationFilter,
  filterWord,
  setFilterWord,
  filterStatus,
  setFilterStatus,
  vesselId,
}) => {
  const styles = useStyles();
  const resetFilter = useRef();
  const hasActiveFilter = useSelector(hasFilterActive);
  const searchButtonFlag = useSelector(getSearchButtonSelector);
  const classes = { ...styles };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchButtonFlg({ flag: false }));
    if (resetFilter.current) resetFilter.current();
  }, [currentPage, vesselId]);

  const serchButtonClick = (ButtonFlag) => (event) => {
    if (currentPage === 2) {
      //HISTORY
      dispatch(setCurrentHistoryPage(0));
    } else if (currentPage === 3) {
      //OPERATION
      dispatch(setCurrentOperationPage(0));
    }
    dispatch(setSearchButtonFlg({ flag: true }));
    dispatch(searchButtonClicked());
  };
  const restButtonClick = () => (event) => {
    resetFilter.current();
    dispatch(setSearchButtonFlg({ flag: false }));
  };

  return (
    <div className={styles.base}>
      <div className={styles.containerAlarmSearch}>
        <Grid item md={6} sm={12} className={styles.filter}>
          <AlarmSearch
            setDurationFilter={setDurationFilter}
            durationFilter={durationFilter}
            filterWord={filterWord}
            setFilterWord={setFilterWord}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            dataType={currentPage}
            vesselId={vesselId}
            resetFilter={resetFilter}
          />
        </Grid>
        <div className={classes.buttonContainer}>
          <Button className={classes.submitButton} onClick={serchButtonClick(true)}>
            {t(localizationKeys.Search_lower)}
          </Button>
        </div>
        {(hasActiveFilter || searchButtonFlag) && (
          <div className={styles.control}>
            <div className={styles.resetButton} onClick={restButtonClick()}>
              <ResetIcon color={"#19B2AB"} />
              <span>{t(localizationKeys.ResetSearchOptions_lower)}</span>
            </div>
          </div>
        )}
        {currentPage == ALARM_PAGE.MONITORING && (
          <Grid item md={8} sm={12} className={styles.container}>
            <Category vesselId={vesselId} />
          </Grid>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(AlarmTopNavigation);
