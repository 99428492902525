import React from "react";
import PropTypes from "prop-types";
import { useCargoDetailsLatestData } from "../useLatestCargoData";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Details } from "./details";
import { COLORS } from "../../../constants/colors";

const useStyles = makeStyles(() => ({
  details: {
    height: "100%",
    width: "100%",
    background: COLORS.tabSelectorColor,
  },
  overviewDetails: {
    backgroundColor: `unset !important`,
  },
}));

export const CargoStatus = (props) => {
  const { taggedLatestData, isVesselOverview } = props;
  const classes = useStyles();
  const { detailsData } = useCargoDetailsLatestData(taggedLatestData);

  return (
    <Grid container className={`${classes.details} ${isVesselOverview && classes.overviewDetails}`}>
      <Details data={detailsData} isVesselOverview={isVesselOverview} />
    </Grid>
  );
};

CargoStatus.propTypes = {
  taggedLatestData: PropTypes.array,
  isVesselOverview: PropTypes.bool,
};
