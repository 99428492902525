import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { usePopper } from "react-popper";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys.js";
import AlarmPageTab from "../../alarmPage/navigation/pageTab.jsx";
import { SearchIcon } from "./searchIcon.jsx";
import { ResetIcon } from "../../alarmPage/resetIcon.jsx";
import AlarmSearch from "../../alarmPage/search/search.jsx";
import {
  hasFilterActive,
  setSearchButtonFlg,
  getSearchButtonSelector,
  searchButtonClicked,
} from "../../../model/slice/alarmSlice.js";

import { ResetCircleIcon } from "./resetCircleIcon.jsx";
import { useLocation } from "react-router-dom";

const searchContainerBase = {
  borderRadius: "5px 0 0 5px",
  width: "30px",
  height: "40px",
  padding: "0",
  marginRight: "8px",
};

const commonFontSettings = {
  fontFamily: "Inter",
  fontWeight: "500",
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "30px",
    display: "flex",
    margin: "8px",
    position: "fixed",
    left: "8px",
    bottom: "8px",
    width: "100%",
  },
  searchFilterOpen: {
    ...searchContainerBase,
    background: "#19b2ab",
    "&:hover": {
      background: "#19b2ab",
    },
  },
  searchFilterClose: {
    ...searchContainerBase,
    background: "#2c3a48",
    "&:hover": {
      background: "#2c3a48",
    },
  },
  pageTab: {
    width: "calc(100vw - 70px)",
  },
  durationDateLabel: {
    ...commonFontSettings,
    color: "#19b2ab",
    fontSize: "14px",
  },
  durationDateLabelPosition: {
    justifyContent: "center",
    display: "flex",
    marginBottom: "16px",
  },
  controls: {
    bottom: "0",
    paddingTop: "5px",
    paddingBottom: "18px",
    position: "absolute",
    width: "calc(100% - 24px)",
    backgroundColor: "#2c3a48",
  },
  resetButton: {
    ...commonFontSettings,
    marginBottom: "19px",
    fontSize: "8px",
    fontWeight: "300",
    color: "#19b2ab",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  submitButton: {
    ...commonFontSettings,
    width: "calc(100% - 32px)",
    height: "34px",
    borderRadius: "5px",
    background: "#19b2ab",
    textTransform: "none",
    fontSize: "12px",
    color: "#21262f",
    "& span": {
      marginLeft: "0px",
    },
  },
}));

const PopperContainer = styled("div")(({ theme }) => ({
  width: "calc(100% - 32px)",
  height: "calc(100% - 119px)",
  zIndex: 5,
  boxShadow: "none",
  borderRadius: "5px",
  padding: "12px",
  textAlign: "center",
  backgroundColor: "#2c3a48",
  "&:hover": {
    backgroundColor: "#2c3a48",
  },
  "& .MuiTypography-body1": {
    color: "#19b2ab !important",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Inter",
  },
  "& .MuiTextField-root": {
    backgroundColor: "#2c3a48",
  },
  overflow: "hidden",
}));

const MobileListBottomNavigation = ({
  currentPage,
  setPage,
  setDurationFilter,
  durationFilter,
  filterWord,
  setFilterWord,
  filterStatus,
  setFilterStatus,
  vesselId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const searchButtonFlag = useSelector(getSearchButtonSelector);
  const classes = useStyles();
  const [showPopper, setShowPopper] = useState(false);
  const buttonRef = useRef();
  const popperRef = useRef();
  const resetFilter = useRef();
  const hasActiveFilter = useSelector(hasFilterActive);
  const location = useLocation();
  const currentLocation = location.pathname.split("/")[1];
  const { styles, attributes } = usePopper(buttonRef.current, popperRef.current, {
    placement: "top-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  useEffect(() => {
    dispatch(setSearchButtonFlg({ flag: false }));
    if (resetFilter.current) resetFilter.current();
  }, [currentPage, vesselId]);

  useEffect(() => {
    if (showPopper) {
      popperRef.current.removeAttribute("hidden");
    } else {
      popperRef.current.setAttribute("hidden", "hidden");
    }
  }, [showPopper]);

  const togglePopper = (openFlg) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setShowPopper(openFlg);
  };

  const searchButtonClick = (ButtonFlag) => (event) => {
    dispatch(setSearchButtonFlg({ flag: true }));
    dispatch(searchButtonClicked());
    setShowPopper(false);
  };
  const restButtonClick = () => (event) => {
    resetFilter.current();
    dispatch(setSearchButtonFlg({ flag: false }));
  };

  return <>
    <PopperContainer ref={popperRef} style={styles.popper} {...attributes.popper}>
      <div className={classes.durationDateLabelPosition}>
        <Typography variant="body1" className={classes.durationDateLabel}>
          {t(localizationKeys.Filters_lower)}
        </Typography>
      </div>
      <AlarmSearch
        setDurationFilter={setDurationFilter}
        durationFilter={durationFilter}
        filterWord={filterWord}
        setFilterWord={setFilterWord}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        dataType={currentPage}
        vesselId={vesselId}
        resetFilter={resetFilter}
      />
      <div className={classes.controls}>
        <div className={classes.resetButton} onClick={restButtonClick()}>
          <ResetCircleIcon color={"#19B2AB"} />
          <span>{t(localizationKeys.ResetSearchOptions_lower)}</span>
        </div>
        <Button className={classes.submitButton} onClick={searchButtonClick(true)}>
          {t(localizationKeys.Submit_lower)}
        </Button>
      </div>
    </PopperContainer>
    <div className={classes.container}>
      <Grid item>
        <IconButton
          ref={buttonRef}
          onClick={togglePopper(!showPopper)}
          // className={classes.searchFilterOpen}
          className={showPopper ? classes.searchFilterOpen : classes.searchFilterClose}
          size="large">
          {hasActiveFilter || searchButtonFlag ? <ResetIcon color={"#ffffff"} /> : <SearchIcon />}
        </IconButton>
      </Grid>
      {currentLocation === "alarm" && (
        <Grid item className={classes.pageTab}>
          <AlarmPageTab selectedTab={currentPage} setSelectedTab={setPage} />
        </Grid>
      )}
    </div>
  </>;
};

export { MobileListBottomNavigation };
