import { useDurationReset } from "./useDurationReset.js";
import dayjs from 'util/dayjs-init.js';
import {
  getDurationOption,
  setDataDuration,
  setElectricDataDuration,
  setSepDataDuration,
} from "../../../model/slice/dataDurationSlice.js";
import { DURATION, transformOption } from "./durationOption.js";
import { useDispatch, useSelector } from "react-redux";
import { DATE_FORMAT } from "../../../constants/constants.js";
import { useEffect } from "react";

// selected date setting function is seperated as custom hook.
// In mobile view, we will provide duration select function in drawer.
// If drawer is not displayed, initial duration select is not working.
// Hence, Loading trend graph is not started.
// it is necessary to put this custom function on each trend graph component.
export const useDurationHandle = (type, enginePosition = null) => {
  useDurationReset();
  const dispatch = useDispatch();
  const durationOption = useSelector(getDurationOption);

  const handleChangeAction = (selected) => {
    let startDate;
    //0 is for custom date inputs. Do not do anything if selected is custom
    // otherwise, subtract number of selected weeks to current date

    if (selected?.value !== 0) {
      if (selected?.value === 0.03) {
        startDate = dayjs.utc().subtract(30, "minutes").format(DATE_FORMAT);
      } else if (selected?.value === 0.1) {
        startDate = dayjs.utc().subtract(1, "h").format(DATE_FORMAT);
      } else if (selected?.value === 0.5) {
        //12 hours only
        startDate = dayjs.utc().subtract(12, "h").format(DATE_FORMAT); //Set start date that will be used by iAPI
      } else {
        //1 day and beyond
        // For 1 day and beyond, always start at 12AM of target start date.. this makes implementation simple.
        startDate = dayjs
          .utc()
          .subtract(selected?.value, "d")
          .startOf("day")
          .format(DATE_FORMAT); //Set start date that will be used by iAPI
      }

      //End date will always be time now.
      const endDate = dayjs.utc().format(DATE_FORMAT);

      //save to redux
      type === DURATION.ENGINE &&
        dispatch(
          setDataDuration({
            startDate,
            endDate,
          })
        );

      type === DURATION.ELECTRIC &&
        dispatch(
          setElectricDataDuration({
            startDate,
            endDate,
          })
        );

      type === DURATION.SEP &&
        dispatch(
          setSepDataDuration({
            startDate,
            endDate,
          })
        );
    }
  };

  useEffect(() => {
    if (!type || !enginePosition) return;
    handleChangeAction(durationOption[transformOption(type, enginePosition)]);
  }, [enginePosition, type]);
};
