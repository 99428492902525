import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TabSelector } from "../tabSelector/tabSelector";
import { CARGO_PAGES } from "../../constants/cargo/pages";
import localizationKeys from "../../i18n/localizationKeys";

export const DisplayTabSelector = (props) => {
  const { vesselId, page, pageRef, interval, selectedChart } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const commonTabs = (t) => [
    {
      name: t(localizationKeys.Transport),
      id: CARGO_PAGES.TRANSPORT,
    },
    {
      name: t(localizationKeys.Handling),
      id: CARGO_PAGES.HANDLING,
    },
  ];

  const clickTab = (id) => {
    navigate(`/cargo/${id}/${vesselId}`);
  };

  return (
    <TabSelector
      tabs={commonTabs(t)}
      selectedOne={page}
      clickHandler={clickTab}
      pageRef={pageRef}
      interval={interval}
      vesselId={vesselId}
      selectedChart={selectedChart}
    />
  );
};

DisplayTabSelector.propTypes = {
  vesselId: PropTypes.string,
  page: PropTypes.string,
  pageRef: PropTypes.any,
  interval: PropTypes.number,
  selectedChart: PropTypes.string,
};
