import { createSlice } from "@reduxjs/toolkit";
import { fetchVesselAsync, fulfilledFetchVesselAsync } from "../async/vesselAsync";

const vesselSlice = createSlice({
  name: "vesselData",
  initialState: {
    vessel: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVesselAsync.fulfilled, fulfilledFetchVesselAsync);
  },
});

export const currentVesselSelector = (state) => {
  return state?.vesselController?.vessel?.vessel;
};
export const VESSEL_TYPE = {
  SEP: "SEP",
  vessel: "vessel",
};
export const currentVesselType = (state) => {
  return state.vesselController.vessel.vessel?.vesselType;
};
export const isCurrentVesselSEP = (state) => {
  return state?.vesselController.vessel.vessel?.vesselType === VESSEL_TYPE.SEP;
};

export const currentVesselValue =
  (ItemName, position = "") =>
  (state) => {
    const currentVesselData = state.vesselController.vessel.vessel;
    if (!currentVesselData) {
      //no vessel
      return currentVesselData;
    }
    //Exitst MEngine(json)?
    const MEngineJSON = currentVesselData.MEngine;
    if (MEngineJSON != null && MEngineJSON !== "") {
      //MEngine exist
      if (position === "") {
        //no position = [0] if no item then return undefined
        return MEngineJSON[0][ItemName];
      } else {
        // if no item then return undefined
        const CurretMEngine = MEngineJSON.find(
          (d) => d?.position.toUpperCase() === position.toUpperCase()
        );
        if (!CurretMEngine) {
          //if no position then return undefined
          return CurretMEngine;
        }
        // >>> if no item then return undefined
        return CurretMEngine[ItemName];
      }
    } else {
      //no MEngine exist
      // >>> if no item then return undefined
      return currentVesselData[ItemName];
    }
  };

export default vesselSlice.reducer;
