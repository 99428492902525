import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { svgContainer } from "../../../constants/trendGraph/buttonStyles";
const useStyles = makeStyles((theme) => ({
  svgContainer: svgContainer,
}));
const EditIcon = ({ width = 25, height = 25 }) => {
  const classes = useStyles();
  return (
    <div className={classes.svgContainer}>
      <svg
        width={width}
        height={height}
        viewBox="0 -3 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m21.504 7.993-1.62 1.621a.422.422 0 0 1-.598 0l-3.903-3.902a.422.422 0 0 1 0-.598l1.621-1.62a1.691 1.691 0 0 1 2.387 0l2.113 2.112c.661.658.661 1.726 0 2.387zm-7.513-1.487-9.232 9.232-.746 4.272a.845.845 0 0 0 .978.977l4.271-.748 9.233-9.233a.422.422 0 0 0 0-.597l-3.903-3.903a.427.427 0 0 0-.601 0zm-5.629 8.441a.49.49 0 0 1 0-.696l5.415-5.414a.49.49 0 0 1 .696 0 .49.49 0 0 1 0 .696l-5.415 5.414a.49.49 0 0 1-.696 0zm-1.269 2.957h1.688v1.276l-2.268.398-1.093-1.094.397-2.267h1.276v1.687z"
          fill="#D8D8D8"
          opacity="1"
        />
      </svg>
    </div>
  );
};

EditIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default EditIcon;
