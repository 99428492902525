import React from "react";
import { useLocation} from "react-router-dom";
import ReactGA from "react-ga4";

const RouteChangeTracker = () => {
  let location = useLocation();

  React.useEffect(() => {
    // Google Analytics
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "infoceanus connect ga4" });
  }, [location]);

  return <div></div>;
};

export default RouteChangeTracker;
