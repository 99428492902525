import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys.js";
import AlarmSearch from "../search/search.jsx";
import { setSearchButtonFlg, getSearchButtonSelector } from "../../../model/slice/alarmSlice.js";
import { hasActiveFilter, setCurrentChannelPage, searchButtonClicked } from "../../../model/slice/channelSlice.js";
import { ResetIcon } from "../../alarmPage/resetIcon.jsx";

const useStyles = makeStyles((theme) => ({
  base: {
    width: "100%",
    margin: "8px",
  },
  containerAlarmSearch: {
    width: "100%",
    marginBottom: "24px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      gap: "2%",
    },
    [theme.breakpoints.down('md')]: {
      height: "82px",
      position: "fixed",
      width: "calc(100vw - 125px)",
    },
  },
  filter: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "0px",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "0px",
    },
  },
  control: {
    marginLeft: "10px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "end",
      marginBottom: "8px",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "-20px",
    },
  },
  resetButton: {
    display: "block",
    width: "124px",
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "10px",
    fontWeight: "300",
    color: "#19b2ab",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
      gap: "6px",
      marginLeft: "12px",
    },
    [theme.breakpoints.down('md')]: {
      gap: "10px",
      marginLeft: "auto",
    },
  },
  circleStatus: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    marginLeft: "6px",
    marginRight: "6px",
    display: "inline-block",
    [theme.breakpoints.only("sm")]: {
      height: "20px",
      width: "20px",
      marginLeft: "4px",
      marginRight: "4px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "10px",
      width: "10px",
      marginLeft: "2px",
      marginRight: "2px",
    },
  },
  categoryHolder: {
    minHeight: "100%",
    backgroundColor: "#2c3a48 !important",
    borderRadius: "8px !important",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      minHeight: "88px",
    },
  },
  categoryBlock: {
    textAlign: "center",
  },
  categoryTitle: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "center",
    flexDirection: "row",
    alignContent: "sretch",
    color: "white",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Inter",
    [theme.breakpoints.only("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
    },
  },
  categoryIcon: {
    height: "20px",
    [theme.breakpoints.only("sm")]: {
      height: "20px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "10px",
    },
  },
  buttonContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "end",
    },
  },
  submitButton: {
    width: "100px",
    height: "34px",
    borderRadius: "5px",
    background: "#19b2ab",
    textTransform: "none",
    fontSize: "12px",
    color: "#21262f",
    "& span": {
      marginLeft: "0px",
    },
    "&:hover": {
      background: "#19b2ab",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "10px",
    },
  },
}));

const AlarmTopNavigation = ({
  t,
  currentPage,
  setPage,
  setDurationFilter,
  durationFilter,
  filterWord,
  setFilterWord,
  filterStatus,
  setFilterStatus,
  vesselId,
}) => {
  const styles = useStyles();
  const resetFilter = useRef();
  const isFilterActive = useSelector(hasActiveFilter);
  const classes = { ...styles };

  const dispatch = useDispatch();
  const searchButtonFlag = useSelector(getSearchButtonSelector);

  useEffect(() => {
    if (resetFilter.current) resetFilter.current();
  }, [currentPage, vesselId]);

  const searchButtonClick = (ButtonFlag) => (event) => {
    dispatch(setCurrentChannelPage(0));
    dispatch(setSearchButtonFlg({ flag: isFilterActive }));
    dispatch(searchButtonClicked());
  };
  const restButtonClick = () => (event) => {
    resetFilter.current();
    const updFlag = !searchButtonFlag;
    dispatch(setSearchButtonFlg({ flag: updFlag }));
  };

  return (
    <div className={styles.base}>
      <div className={styles.containerAlarmSearch}>
        <Grid item md={6} sm={12} className={styles.filter}>
          <AlarmSearch
            setDurationFilter={setDurationFilter}
            durationFilter={durationFilter}
            filterWord={filterWord}
            setFilterWord={setFilterWord}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            dataType={currentPage}
            vesselId={vesselId}
            resetFilter={resetFilter}
          />
        </Grid>
        <div className={classes.buttonContainer}>
          <Button className={classes.submitButton} onClick={searchButtonClick(true)}>
            {t(localizationKeys.Search_lower)}
          </Button>
        </div>
        {isFilterActive && (
          <div className={styles.control}>
            <div className={styles.resetButton} onClick={restButtonClick()}>
              <ResetIcon color={"#19B2AB"} />
              <span>{t(localizationKeys.ResetSearchOptions_lower)}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(AlarmTopNavigation);
