import { withStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { COLORS } from "../../../constants/colors";

export const CustomBlueButton = withStyles({
  root: {
    background: COLORS.skyBlue,
    color: "black",
    width: "100%",
    "&:hover": {
      opacity: 0.8,
      background: COLORS.skyBlue,
    },
  },
})(Button);

export const CustomBlackButton = withStyles({
  root: {
    background: COLORS.confirmationModalColor,
    color: COLORS.white,
    width: "100%",
    "&:hover": {
      opacity: 0.8,
      background: COLORS.confirmationModalColor,
    },
  },
})(Button);

export const CustomRedButton = withStyles({
  root: {
    background: "#ff5252",
    color: COLORS.white,
    width: "100%",
    "&:hover": {
      opacity: 0.8,
      background: "#ff5252",
    },
  },
})(Button);
