import React, { memo, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

import dayjs from '../../../../util/dayjs-init';

import { COLORS } from "../../../../constants/colors";
import { SeaWaterTempIcon } from "./seaWaterTempIcon";

const useStyles = makeStyles(() => ({
  container: {
    margin: "4px",
  },
  temperature: {
    fontSize: "24px",
    fontWeight: "bold",
    color: COLORS.white,
  },
}));

const SeaWaterTemp = memo(({ hourlyData }) => {
  const classes = useStyles();

  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    if (!hourlyData) return;

    const currentHour = dayjs.utc().format('YYYY-MM-DDTHH:00');
    const dataIndex = hourlyData.time.findIndex(data => currentHour === data);
  
    setCurrentData(hourlyData.soil_temperature_0cm[dataIndex]);
  }, [hourlyData]);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        {/* <img src={SeaWaterTempIcon} width={40} height={40} /> */}
        <SeaWaterTempIcon></SeaWaterTempIcon>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.temperature}>{currentData ?? "-"}°C</div>
      </Grid>
    </Grid>
  );
});

SeaWaterTemp.propTypes = {
  hourlyData: PropTypes.object,
};

export { SeaWaterTemp };
