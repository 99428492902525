import React from "react";
import { GithubPicker } from "react-color";
import { makeStyles } from "@mui/styles";
import { IconButton, Paper } from "@mui/material";
import { Close } from "@mui/icons-material";
import localizationKeys from "../../i18n/localizationKeys";
import { useTranslation } from "react-i18next";
import { updateLines } from "../../api/charts";

const useStyles = makeStyles(() => ({
  colorPicker: {
    backgroundColor: "rgba(44, 58, 72, 1) !important",
    color: "#ffffff !important",
    size: "8px",
    width: "320px",
    height: "170px",
    padding: "20px",
  },
  colorPickerHeader: {
    width: "290px",
    height: "30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 20px 20px 4px",
    padding: "0",
  },
  colorPickerType: {
    backgroundColor: "#A7B0B0",
    color: "black",
    alignSelf: "stretch",
    flexGrow: "1",
    padding: "6px 8px 6px",
    borderRadius: "5px",
    border: "solid 0.5px rgba(0, 0, 0, 0.04)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "13px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.54",
    letterSpacing: "-0.08px",
    marginBottom: "20px",
  },
  IconButton: {
    backgroundColor: "rgba(44, 58, 72, 0.3) !important",
    color: "#ffffff !important",
    size: "8px",
    marginLeft: "40px",
  },
}));

const ColorPicker = (props) => {
  const {
    setDisplayColorPicker,
    graphType,
    dataKey,
    setOnClose,
    chartType,
    vesselId,
    isComparison,
    isCargoPressure,
    isEmission,
    vesselName,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const handleChange = (color) => {
    closeFunction(dataKey, color.hex);
  };
  const closeFunction = (datakey, color) => {
    setDisplayColorPicker({ flag: datakey, position: color });
    setOnClose(false);
  };

  return (
    <Paper variant="outlined" square className={classes.colorPicker}>
      <div className={classes.colorPickerHeader}>
        {t(localizationKeys.ChangeColor)}
        <IconButton
          onClick={() => closeFunction("", 0)}
          className={classes.IconButton}
          size="large">
          <Close />
        </IconButton>
      </div>
      <div>
        <GithubPicker
          key={`individual-color-${dataKey}`}
          onChange={handleChange}
          onChangeComplete={async (e) => {
            const color = e.hex;
            setDisplayColorPicker({ flag: dataKey, position: color });
            if (isComparison) {
              sessionStorage.setItem(`comparison-${chartType}-${vesselId}-${dataKey}`, color);
            } else if (isCargoPressure) {
              sessionStorage.setItem(`cargo-pressure-${vesselId}-${dataKey}`, color);
            } else if (isEmission) {
              sessionStorage.setItem(`emission-${vesselName}-${dataKey}`, color);
            } else {
              await updateLines(vesselId, graphType, chartType, dataKey, color);
            }
          }}
          width="277px"
          triangle="hide"
          colors={[
            "#B80000",
            "#DB3E00",
            "#FCCB00",
            "#008B02",
            "#006B76",
            "#1273DE",
            "#004DCF",
            "#5300EB",
            "#EB9694",
            "#FAD0C3",
            "#FEF3BD",
            "#C1E1C5",
            "#BEDADC",
            "#C4DEF6",
            "#BED3F3",
            "#D4C4FB",
          ]}
        />
      </div>
    </Paper>
  );
};

export { ColorPicker };
