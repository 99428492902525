import localizationKeys from "../../i18n/localizationKeys";
import { CARGO_PAGES } from "./pages";

//don't change this order
export const GAUGES_TAGS = [
  { tag: "P47" },
  { tag: "P8" },
  { tag: "P2" },
  { tag: "P46" },
  { tag: "P9" },
  { tag: "P1" },
];

export const LCO2_GAUGES = [
  {
    name: localizationKeys.No2TankPress,
    unit: "MPaG",
    tag: "P47",
    measureRangeMin: 0.0,
    measureRangeMax: 2.0,
    low1: 0.42,
    low2: 0.60,
    low3: 0.73,
    high1: 1.58,
    high2: 1.71,
    high3: 1.86,
    visibleIn: [CARGO_PAGES.TRANSPORT, CARGO_PAGES.HANDLING],
  },
  {
    name: localizationKeys.No1TankPress,
    unit: "MPaG",
    tag: "P46",
    measureRangeMin: 0.0,
    measureRangeMax: 2.0,
    low1: 0.42,
    low2: 0.60,
    low3: 0.73,
    high1: 1.58,
    high2: 1.71,
    high3: 1.86,
    visibleIn: [CARGO_PAGES.TRANSPORT, CARGO_PAGES.HANDLING],
  },
  {
    name: localizationKeys.No2CargoPPDisch,
    unit: "MPaG",
    tag: "P8",
    measureRangeMin: 0.0,
    measureRangeMax: 3.6,
    low1: 0.42,
    low2: 0.60,
    low3: 0.73,
    high1: 2.00,
    high2: 2.55,
    high3: 2.60,
    visibleIn: [CARGO_PAGES.HANDLING],
  },
  {
    name: localizationKeys.No2CargoPPSuction,
    unit: "MPaG",
    tag: "P9",
    measureRangeMin: 0.0,
    measureRangeMax: 2.0,
    low1: 0.42,
    low2: 0.60,
    low3: 0.73,
    high1: 1.58,
    high2: 1.71,
    high3: 1.86,
    visibleIn: [CARGO_PAGES.HANDLING],
  },
  {
    name: localizationKeys.PortManifoldLiquid,
    unit: "MPaG",
    tag: "P2",
    measureRangeMin: 0.0,
    measureRangeMax: 3.6,
    low1: 0.42,
    low2: 0.60,
    low3: 0.73,
    high1: 2.00,
    high2: 2.55,
    high3: 2.60,
    visibleIn: [CARGO_PAGES.HANDLING],
  },
  {
    name: localizationKeys.StarboardManifoldLiquid,
    unit: "MPaG",
    tag: "P1",
    measureRangeMin: 0.0,
    measureRangeMax: 3.6,
    low1: 0.42,
    low2: 0.60,
    low3: 0.73,
    high1: 2.00,
    high2: 2.55,
    high3: 2.60,
    visibleIn: [CARGO_PAGES.HANDLING],
  },
];
