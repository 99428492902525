import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import VesselSelector from "../common/vesselSelector.jsx";
import { GraphSelector } from "./graphSelector.jsx";
import { VESSEL_TYPE } from "../../model/slice/vesselSlice";
import { SEP_CHART_TYPE } from "../../constants/trendGraph/sepLines.js";
import { ENGINE_CHART_TYPE, ENGINE_TYPE } from "../../constants/trendGraph/enginesLine.js";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType.js";
import { GraphTypeSelector } from "./graphTypeSelector.jsx";
import { DurationPicker } from "../durationPicker/durationPicker.jsx";

const SELECTOR_WIDTH = 9;

const classes = {
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  main: {
    display: "inline-block",
    fontSize: "10px",
  },
  chartSelector: {
    marginTop: "8px",
  },
};

export const ComparisonGraphSelector = (props) => {
  const {
    currentVesselInfo,
    currentSelectedVessel,
    setCurrentSelectedVessel,
    selectedGraphType,
    setSelectedGraphType,
    selectedGraph,
    setSelectedGraph,
    enginePositions,
    setEnginePositions,
    customCharts,
    isFetchingChartInfo,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = props;

  const graphTypeInitialize = () => {
    switch (true) {
      case currentVesselInfo?.vesselType === VESSEL_TYPE.SEP:
        return SEP_CHART_TYPE;
      case [ENGINE_TYPE.STRAIGHT, ENGINE_TYPE.V].includes(currentVesselInfo?.MEEngineType):
        return ENGINE_CHART_TYPE;
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      spacing={1}
      sx={classes.main}
    >
      <Grid item xs={SELECTOR_WIDTH} marginBottom={"8px"} fontSize={"10px"}>
        <DurationPicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          isEmission={false}
          withTimePicker={true}
          singleLine={true}
        />
      </Grid>
      <Grid item xs={SELECTOR_WIDTH} sx={classes.gridContainer}>
        <VesselSelector
          currentSelectedVessel={currentSelectedVessel}
          setCurrentSelectedVessel={setCurrentSelectedVessel}
        />
      </Grid>
      <Grid item xs={SELECTOR_WIDTH} style={{ minHeight: "35px" }}>
        <GraphTypeSelector 
          selectedVessel={currentVesselInfo} 
          hasCustomCharts={customCharts?.charts?.charts?.length > 0}
          selectedGraphType={selectedGraphType}
          setSelectedGraphType={setSelectedGraphType}
        />
      </Grid>
      {currentSelectedVessel !== null &&
      currentSelectedVessel !== "null" &&
      currentSelectedVessel !== undefined &&
      selectedGraphType !== GRAPH_TYPE.ELECTRIC ? (
        <Grid item xs={SELECTOR_WIDTH} sx={classes.gridContainer}>
          <GraphSelector
            vessel={currentVesselInfo}
            selectedGraph={selectedGraph}
            setSelectedGraph={setSelectedGraph}
            selectedEngine={enginePositions}
            setSelectedEngine={setEnginePositions}
            chartType={graphTypeInitialize()}
            position={enginePositions}
            customCharts={customCharts}
            selectedGraphType={selectedGraphType}
            isFetchingChartInfo={isFetchingChartInfo}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

ComparisonGraphSelector.propTypes = {
  currentVesselInfo: PropTypes.object,
  currentSelectedVessel: PropTypes.string,
  setCurrentSelectedVessel: PropTypes.func,
  selectedGraphType: PropTypes.string,
  setSelectedGraphType: PropTypes.func,
  selectedGraph: PropTypes.string,
  setSelectedGraph: PropTypes.func,
  enginePositions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  setEnginePositions: PropTypes.func,
  customCharts: PropTypes.object,
  isFetchingChartInfo: PropTypes.bool,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
}
