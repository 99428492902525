import { DATE_FORMAT } from "../constants/constants";
import { TIMEZONE } from "../constants/timezone";
import dayjs from "./dayjs-init";

export const formatDate = (date, timezone, format = DATE_FORMAT) => {
  if (timezone === TIMEZONE.UTC) {
    return dayjs.utc(date).format(format);
  }
  return dayjs(date).format(format);
}

export const convertToTimezone = (date, timezone) => {
  if (timezone === TIMEZONE.UTC) {
    return dayjs.utc(date);
  }
  return dayjs(date);
}

export const getCurrentDateWithTimezone = (timezone) => {
  if (timezone === TIMEZONE.UTC) {
    return dayjs.utc();
  }
  return dayjs();
}