import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { svgContainer } from "../../../constants/trendGraph/buttonStyles";
const useStyles = makeStyles((theme) => ({
  svgContainer: svgContainer,
}));
const MinimiseIcon = ({ width = 25, height = 25, disabled = false }) => {
  const color = disabled ? "#FFFFFF" : "#D8D8D8";
  const opacity = disabled ? "0.5" : "1";
  const classes = useStyles();

  return (
    <div className={classes.svgContainer}>
      <svg
        width={width}
        height={height}
        viewBox="0 -3 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 8.125h-3.125V5M16.25 3.75l-4.375 4.375M5 11.875h3.125V15M3.75 16.25l4.375-4.375M11.875 15v-3.125H15M16.25 16.25l-4.375-4.375M8.125 5v3.125H5M3.75 3.75l4.375 4.375"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={opacity}
        />
      </svg>
    </div>
  );
};

MinimiseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MinimiseIcon;
