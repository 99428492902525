import React from "react";
import PropTypes from "prop-types";

const FullScreenLayout = (props) => {
  return (
    <React.Fragment>
      <props.component {...props.defaultParams} />
    </React.Fragment>
  );
};

FullScreenLayout.propTypes = {
  name: PropTypes.string,
};

export default FullScreenLayout;
