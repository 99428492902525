import React, { memo } from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../../../constants/colors";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    left: "13px",
    transform: "scale(1.0,1.5)",
  },
  load: {
    backgroundColor: "#2b3442",
    // borderRadius: "11px",
    height: "100px",
    width: "20px",
    overflow: "hidden",
  },
  fill: {
    backgroundColor: "#64d9e7",
    height: "100px",
    width: "20px",
  },
  scale: {
    height: "111px",
    position: "relative",
    top: "-5px",
    left: "1px",
    width: "fit-content",
    color: COLORS.white,
    fontSize: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export const VolumeMarker = memo((props) => {
  const { fill, max } = props;
  const classes = useStyles();
  const rate = fill >= max ? 1 : fill / max;
  return (
    <div className={classes.container}>
      <div className={classes.load}>
        <div
          className={classes.fill}
          style={{
            marginTop: `${100 - 100 * (isNaN(rate) ? 0 : rate)}px`,
          }}
        />
      </div>
      <div className={classes.scale} style={{ opacity: isNaN(rate) ? 0.5 : 1 }}>
        <div>{`- ${max}`}</div>
        <div>{`-`}</div>
        <div>{`- 0`}</div>
      </div>
    </div>
  );
});

VolumeMarker.propTypes = {
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.number,
};
