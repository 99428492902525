import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Button } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from "../../constants/colors.js";
import { DATE_TIME_FORMAT } from "../../constants/util.js";
import localizationKeys from '../../i18n/localizationKeys.js';
import { DownloadIcon } from './icons/downloadIcon.jsx';
import { SyntheticaStyles, colorFromRank } from './styles.jsx';
import { SEAJAPAN_TEST_VESSEL } from "../../constants/synthetica.js";

const MONTH_DATE_FORMAT = "MMM";

const styles = {
    noChartData: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        fontSize: 30,
        color: "white",
        display: "grid",
        alignItems: "center",
        textAlign: "center",
        opacity: 1,
        zIndex: 100,
    }
}

export const CIIChart = ({ data }) => {
    const { t } = useTranslation();

    const filterToYear = (ciiData, year) => {
        const outData = ciiData.filter((v) => {
            const date = new Date(v.date);
            return (date.getFullYear() === year);
        });
        if (outData == null || outData.length === 0)
            return [];
        else
            return outData;
    }

    const curYear = data.vesselPerformance.vessel_name.toUpperCase() === SEAJAPAN_TEST_VESSEL ? 2022 : new Date().getFullYear();

    let dataSet = filterToYear(data.ciiOverTime.cii_overtime, curYear).sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    });

    if (data.vesselPerformance.vessel_name.toUpperCase() === SEAJAPAN_TEST_VESSEL) {
      const curMonth = new Date().getMonth();
      dataSet = dataSet.filter(data => {
        const date = new Date(data.date);
        return (date.getMonth() < curMonth);
      });
    }

    useLayoutEffect(() => {
        if (data == null || !data.hasData)
            return;

        am5.addLicense(process.env.AMCHARTS_LICENSE);
        const root = am5.Root.new("chartdiv", {
          useSafeResolution: false,
        });

        const myTheme = am5.Theme.new(root);
        myTheme.rule("AxisLabel").setAll({
            fill: am5.color(0xFFFFFF),
            fontSize: "10px",
            opacity: 1,
        });
        myTheme.rule("Grid").setAll({
            opacity: 0
        })

        root.setThemes([am5themes_Animated.new(root), myTheme]);

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panY: false,
                layout: root.verticalLayout
            })
        );

        const yMin = data.ciiOverTime.limits.boundary_1 * 0.9;
        const yMax = data.ciiOverTime.limits.boundary_4 * 1.1;
        // Create Y-axis
        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
                min: yMin,
                max: yMax,
                tooltip: am5.Tooltip.new(root, {})
            })
        );

        yAxis.children.unshift(
            am5.Label.new(root, {
                rotation: -90,
                text: "CII Value",
                y: am5.p50,
                centerX: am5.p50,
                fill: am5.color(0xffffff),
                fontSize: "10px",
            })
        );

        // Create X-Axis
        const xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "day", count: 1 },
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
                min: new Date(curYear, 0, 1).getTime(),
                max: new Date(curYear, 11, 31).getTime(),
                gridIntervals: [
                    { timeUnit: "month", count: 1 }
                ],
            })
        );
        xAxis.get("dateFormats")["month"] = MONTH_DATE_FORMAT;
        xAxis.get("periodChangeDateFormats")["month"] = MONTH_DATE_FORMAT;
        xAxis.get("dateFormats")["week"] = MONTH_DATE_FORMAT;
        xAxis.get("periodChangeDateFormats")["week"] = MONTH_DATE_FORMAT;
        xAxis.get("dateFormats")["day"] = MONTH_DATE_FORMAT;
        xAxis.get("periodChangeDateFormats")["day"] = MONTH_DATE_FORMAT;
        xAxis.get("dateFormats")["hour"] = DATE_TIME_FORMAT;
        xAxis.get("periodChangeDateFormats")["hour"] = DATE_TIME_FORMAT;
        xAxis.get("dateFormats")["minute"] = DATE_TIME_FORMAT;
        xAxis.get("periodChangeDateFormats")["minute"] = DATE_TIME_FORMAT;
        xAxis.get("dateFormats")["second"] = DATE_TIME_FORMAT;
        xAxis.get("periodChangeDateFormats")["second"] = DATE_TIME_FORMAT;

        xAxis.data.setAll(data.ciiOverTime.cii_overtime);

        // Create series
        const series1 = chart.series.push(
            am5xy.SmoothedXLineSeries.new(root, {
                name: "CII",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "date",
                stroke: COLORS.white,
                fill: COLORS.white,
            })
        );

        series1.data.processor = am5.DataProcessor.new(root, {
            numericFields: ["value"],
            dateFields: ["date"],
            dateFormat: "yyyy-MM-dd hh:mm:ss"
        });
        series1.strokes.template.set("strokeWidth", 2);

        var tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}"
        });

        tooltip.get("background").setAll({
            fill: am5.color(0x000000),
            fillOpacity: 0.8
        });

        series1.set("tooltip", tooltip);
        //series1.get("tooltip").label.set("text", "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}")

        series1.data.setAll(dataSet);

        //Generate projections
        if (dataSet.length > 0) {
            const lastElement = dataSet[dataSet.length - 1];
            const projectionData = [
                {
                    date: new Date(lastElement.date).getTime(),
                    lowProjection: lastElement.value,
                    midProjection: lastElement.value,
                    highProjection: lastElement.value,
                },
                {
                    date: new Date(curYear, 11, 31).getTime(),
                    lowProjection: data.vesselStatus.ci_lower,
                    midProjection: data.vesselStatus.projected_cii,
                    highProjection: data.vesselStatus.ci_upper,
                }
            ];

            const createProjSeries = (title, field, opacity = 1) => {
                const series = chart.series.push(
                    am5xy.LineSeries.new(root, {
                        name: title,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: field,
                        valueXField: "date",
                        stroke: COLORS.white,
                        fill: COLORS.white,
                        opacity: opacity,
                        tooltip: am5.Tooltip.new(root, {})
                    })
                );
                series.strokes.template.setAll({
                    strokeDasharray: [5, 3]
                });
                series.data.setAll(projectionData);
            };

            createProjSeries("Projection - Low", "lowProjection", 0.5);
            createProjSeries("Projection - Mid", "midProjection", 1);
            createProjSeries("Projection - High", "highProjection", 0.5);
        }
        // Add cursor
        chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
        }));

        //initTooltip(root, chart);

        // Add range fills
        createRangeFill(yAxis, 0, data.ciiOverTime.limits.boundary_1, colorFromRank('a', "66"));
        createRangeFill(yAxis, data.ciiOverTime.limits.boundary_1, data.ciiOverTime.limits.boundary_2, colorFromRank('b', "66"));
        createRangeFill(yAxis, data.ciiOverTime.limits.boundary_2, data.ciiOverTime.limits.boundary_3, colorFromRank('c', "66"));
        createRangeFill(yAxis, data.ciiOverTime.limits.boundary_3, data.ciiOverTime.limits.boundary_4, colorFromRank('d', "66"));
        createRangeFill(yAxis, data.ciiOverTime.limits.boundary_4, 9999999, colorFromRank('e', "66"));

        return () => {
            root.dispose();
        };
    }, [data]);

    const createRangeFill = (axis, boundaryLow, boundaryHigh, color) => {
        var rangeDataItem = axis.makeDataItem({ value: boundaryLow, endValue: boundaryHigh });
        var range = axis.createAxisRange(rangeDataItem);
        range.get("axisFill").setAll({
            fill: color,
            fillOpacity: 1,
            visible: true
        });
    }

    const handleDownload = () => {
        const headers = ['Date', 'CII'].join(',');
        const rows = data.ciiOverTime.cii_overtime.map((v) => [new Date(v.date).toISOString(), v.value].join(','));
        const joinedRows = rows.join('\n');
        const out = headers + '\n' + joinedRows;

        const blob = new Blob([out]);

        const downloadLink = document.createElement("a");
        downloadLink.download = "CII_Over_Time" + ".csv";
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.dataset.downloadurl = ["text/csv", downloadLink.download, downloadLink.href].join(
            ":"
        );

        downloadLink.click();

        downloadLink.remove();
    };

    const getNoDataText = () => {
        if (dataSet == null || dataSet.length === 0)
            return <p style={styles.noChartData}>No data for current year</p>
        else
            return <></>
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <Box sx={{...SyntheticaStyles.paperHeader, lineHeight: "28px"}}>
                <Box>{t(localizationKeys.CIIOverTime)}</Box>
                <Box sx={{marginLeft: "10%", fontSize: "12px"}}>{new Date().getFullYear()}</Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box>
                    <Button sx={{ width: "32px", minWidth: "32px" }} onClick={handleDownload}>
                        <DownloadIcon></DownloadIcon>
                    </Button>
                </Box>
            </Box>
            <div id="chartdiv" style={{ width: "100%", flexGrow: 1, position: "relative" }}>
                {getNoDataText()}
            </div>
        </Box>
    );
}

