import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from 'util/dayjs-init.js';
import { TextField, Typography } from "@mui/material";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { Autocomplete } from '@mui/material';
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import { logger } from "../../../api/logger";

const column = {
  fontSize: "10px",
  fontFamily: "Inter",
  fontWeight: "500",
  color: "#ffffff",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
};

const placeHolder = (theme) => ({
  color: "#FFFFFF",
  fontStyle: "italic",
  opacity: 0.65,
  fontSize: "10px",
  fontWeight: 300,
  fontStretch: "normal",
  [theme.breakpoints.down('sm')]: {
    fontSize: "10px !important",
  },
});

const useStyles = makeStyles((theme) => ({
  valueSearch: {
    backgroundColor: "#252d39 !important",
    fontSize: "10px",
    fontWeight: "500",
    width: "100%",
    borderRadius: "2px",
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
      marginLeft: "10px",
      fontSize: "10px",
      [theme.breakpoints.down('sm')]: {
        fontSize: "10px !important",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      ...placeHolder(theme),
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      ...placeHolder(theme),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px !important",
      borderRadius: "2px",
    },
  },
  searchBase: {
    flexGrow: 1,
    "& .first-child": {
      marginRight: "auto",
    },
    "& .MuiAutocomplete-popper": {
      backgroundColor: "none !important",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginLeft: "0",
    },
  },
  searchIconAdjustment: {
    marginRight: "5px",
  },
  item: {
    //display: "flex",
    alignItems: "left",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
    // marginTop: "16px",
    [theme.breakpoints.down('sm')]: {
      display: "block",
    },
  },
  columnName: {
    ...column,
    [theme.breakpoints.up("sm")]: {
      //marginLeft: "auto",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px !important",
    },
  },
  titleContainer: {
    width: "100px",
    display: "flex",
    marginBottom: "8px",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: "12px",
    },
  },
}));

const SearchField = React.memo(
  ({ label, field, text, setText, autocompleteOptions, i18n, t, resetFunc }) => {
    const classes = useStyles();
    const [key, setKey] = useState("");
    const { register, getValues, setValue, watch, reset } = useForm({
      defaultValues: {
        value: "",
      },
    });

    resetFunc.current = () => {
      reset();
      setKey(dayjs.utc().toString());
    };

    useEffect(() => {
      setValue("value", text);
    }, []);

    useEffect(() => {
      setText(getValues().value);
    }, [watch("value")]);

    return (
      <div className={classes.item} key="input-base">
        <div className={classes.titleContainer}>
          <Typography className={classes.columnName} variant="body2">
            {label}
          </Typography>
        </div>
        <Autocomplete
          name="value"
          key={key}
          freeSolo
          options={autocompleteOptions(field)}
          {...register("value")}
          onInputChange={(e, v, r) => {
            setValue("value", v);
          }}
          className={classes.searchBase}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              key="input-text"
              className={classes.valueSearch}
              placeholder={t(localizationKeys.Search_lower)}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        />
      </div>
    );
  }
);

SearchField.propTypes = {
  isOperationPage: PropTypes.bool,
  text: PropTypes.string,
  setText: PropTypes.func,
  t: PropTypes.func,
  autocompleteOptions: PropTypes.func,
  i18n: PropTypes.object,
};

export default withTranslation()(SearchField);
