import { axios } from "./axiosSettings";
import { getAuthToken } from "../helpers/session.js";

export const fetchTenantByUserId = async (user_id) => {
  try {
    const { data } = await axios.get(`/tenant/user`, {
        
      params: {
        user_id,
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return { tenant: data };
  } catch (e) {
    return { error: e };
  }
};