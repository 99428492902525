import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import * as PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';

import { fetchVessel } from "../../api/vessel";
import {
  setCargoDataDuration,
} from "../../model/slice/dataDurationSlice";
import dayjs from "util/dayjs-init.js";
import useInterval from "../../hooks/useInterval";
import { channelModData } from "../../model/slice/channelSlice";
import { fetchChannelModAsync } from "../../model/async/channelAsync";
import { DisplayPage } from "./displayPage";
import { DisplayTabSelector } from "./displayTabSelector";
import { calDurations } from "../../constants/calDuration";
import { NoData } from "../offlineComponent/noData";
import { PRESS_TABS } from "../../constants/cargo/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100dvh - 140px)",
    width: "calc(100vw - 240px)",
    margin: "0 0 0 50px",
    [theme.breakpoints.down('lg')]: {
      width: "calc(100% - 16px)",
      margin: "auto",
      overflowX: "hidden",
    },
    fontFamily: "Inter",
    "user-select": "none",
    "-moz-user-select": "none",
    "-webkit-user-select": "none",
  },
  topContainer: {
    height: "350px",
    width: "calc(100% - 16px)",
    marginBottom: "16px",
    boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.3)",
  },
  middleContainer: {
    height: "350px",
    width: "calc(100% - 16px)",
    marginBottom: "16px",
    boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.3)",
  },
  bottomContainer: {
    height: "450px",
    width: "calc(100% - 16px)",
    marginBottom: "16px",
    boxShadow: "0 10px 20px 0 rgba(0, 0, 0, 0.3)",
  },
  leftContainer: {
    [theme.breakpoints.up('xl')]: {
      paddingRight: "8px",
    },
  },
  rightContainer: {
    [theme.breakpoints.up('xl')]: {
      paddingLeft: "8px",
    },
  },
  tankContainer: {
    [theme.breakpoints.down('xl')]: {
      paddingRight: "16px",
    },
  },
  maximized: {
    height: "100%",
  },
}));

export const Cargo = (props) => {
  const { vesselId, page} = useParams();
  props = {vesselId, page, ...props}; // augment props with route params to pass down to children
  const classes = useStyles();
  const dispatch = useDispatch();
  const latestData = useSelector(channelModData);
  const [currentVessel, setCurrentVessel] = useState(null);
  const [isCargoVessel, setIsCargoVessel] = useState(false);
  const [selectedChart, setSelectedChart] = useState(PRESS_TABS.LOADING);
  const [isCargoFetching, setIsCargoFetching] = useState(false);
  const pageRef = useRef(null);

  const fetchData = async () => {
    if (isCargoFetching) return;
    setIsCargoFetching(true);
    const data = (await fetchVessel(vesselId)).vessel;
    setCurrentVessel(data);
    dispatch(fetchChannelModAsync({ vesselId }));
    setIsCargoFetching(false);
  };

  useEffect(() => {
    const startDate = dayjs.utc().subtract(1, "d").format();
    const endDate = dayjs.utc().format();
    dispatch(setCargoDataDuration({startDate, endDate, interval: calDurations({startDate, endDate})}));
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [vesselId]);

  useEffect(() => {
    if (!currentVessel) return;

    setIsCargoVessel(currentVessel.NoTank > 0)
  }, [currentVessel]);

  useInterval(() => dispatch(fetchChannelModAsync({ vesselId })), 1000 * 60);

  return currentVessel && (
    <div ref={pageRef} style={{ overflowY: "scroll", height: "fit-content" }}>
      {isCargoVessel ?
        <>
          <DisplayTabSelector {...props} classes={classes} pageRef={pageRef} selectedChart={selectedChart}/>
          <DisplayPage {...props} classes={classes} latestData={latestData} selectedChart={selectedChart} setSelectedChart={setSelectedChart}/>
        </>
      : <div style={{ width: "100%", height: "500px" }}>
          <NoData />
        </div>
      }
    </div>
  );
};

Cargo.propTypes = {
  vesselId: PropTypes.string,
  page: PropTypes.string,
  layoutStyle: PropTypes.object,
};
