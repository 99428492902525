import React from "react";
import PropTypes from "prop-types";

export const DurationCalendarIcon = ({ color }) => (
  <svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.813.728h.898c.71 0 1.289.538 1.289 1.2v6.184c0 .662-.578 1.2-1.29 1.2H1.29c-.712 0-1.29-.538-1.29-1.2V1.928c0-.662.578-1.2 1.29-1.2h.897V.29c0-.16.14-.291.313-.291s.313.13.313.291v.437h4.374V.29c0-.16.14-.291.313-.291s.313.13.313.291v.437zM8.71 8.73c.367 0 .664-.277.664-.619v-4.82c0-.05-.044-.09-.098-.09H.723c-.054 0-.098.04-.098.09v4.82c0 .342.297.619.664.619h7.422z"
      fill={color}
    />
    <path fill={color} d="M5 5.322h2.857v2.661H5z" />
  </svg>
);

DurationCalendarIcon.prototype = {
  color: PropTypes.string,
};
