import * as PropTypes from "prop-types";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import ElectricChart from "./electricChart.jsx";
import { checkCondition, inlinePaper } from "./electricUtil";
import makeStyles from '@mui/styles/makeStyles';
import { GENERATOR_TYPE } from "../../../constants/monitoring/constants.js";

const useStyles = ({ isBarChart }) =>
  makeStyles((theme) => ({
    inlinePaper: {
      ...inlinePaper(theme),
    },
    grid: {
      display: "flex",
      flexFlow: "column",
      justifyContent: isBarChart ? "start" : "center",
    },
    runningTime: {
      marginTop: "10px",
    },
  }));

const GeChart = ({
  generatorData = [],
  isLoading,
  isNoData,
  isInitialLoading,
  geMaxPower,
  selectedChart,
}) => {
  if (!generatorData?.length) return null;

  const isBarChart = selectedChart === "bar";
  const classes = useStyles({ isBarChart })();

  return (
    <React.Fragment>
      {generatorData.map((d) => (
        <Grid className={classes.grid} key={`generator-grid-${d.generatorNumber}`}>
          <ElectricChart
            key={`generator${d.generatorNumber}`}
            title={`${d.generatorNumber} D/G`}
            data={d.gePower}
            limit={geMaxPower}
            type={GENERATOR_TYPE.GE}
            condition={checkCondition(d?.geacb)}
            sizeRatio={isBarChart ? 0.55 : 0.8}
            className={classes.inlinePaper}
            isLoading={isLoading}
            isNoData={isNoData || d?.isNoData}
            isInitialLoading={isInitialLoading}
          />
        </Grid>
      ))}
    </React.Fragment>
  );
};

GeChart.propTypes = {
  generatorData: PropTypes.array,
  isLoading: PropTypes.bool,
  isNoData: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
  geMaxPower: PropTypes.number,
};

export default GeChart;
