import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import PropTypes from "prop-types";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  addLegendFeatures,
  handleTooltipLock,
  handleTooltipUnlocking,
  initCursor,
  initLegend,
  initScrollbarX,
  initScrollbarY,
  initTooltip,
  initXAxis,
  initYAxis,
} from "./amcharts.jsx";
import { HANDLING_PRESS_LOAD_TAGS, HANDLING_PRESS_UNLOAD_TAGS } from "../../constants/cargo/charts";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType.js";
import { ColorPicker } from "./colorPicker.jsx";
import { PRESS_TABS } from "../../constants/cargo/constants.js";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys.js";
import { DEFAULT_LINE_COLORS } from "../../constants/trendGraph/linesDefaultColors.js";
import { useSelector } from "react-redux";
import { selectedTimezone } from "../../model/slice/timezoneSlice.js";
import { TIMEZONE } from "../../constants/timezone.js";
import dayjs from "../../util/dayjs-init.js";

let isLocked = false;



export const LineChart = (props) => {
  const { vesselId, data, currentTab, isOneSec } = props;
  const vesselName = props.vesselId;
  const { t } = useTranslation();
  const chartId = `linechart-${currentTab}`;
  const paramList =
    currentTab === PRESS_TABS.LOADING ? HANDLING_PRESS_LOAD_TAGS : HANDLING_PRESS_UNLOAD_TAGS;
  //for Color select trick
  const [displayColorPickerFlag, setDisplayColorPickerFlag] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState({
    flag: "",
    position: 0,
  });
  const [dataKeyName, setDataKeyName] = useState("");
  const [colorPickerPos, setColorPickerPos] = useState({ x: 0, y: 0 });

  const lineChartRef = useRef(null);
  const rootRef = useRef(null);
  const legendRef = useRef(null);
  const registeredHideLineList = JSON.parse(sessionStorage.getItem(`cargo-pressure-${vesselId}`));
  const [hideLineList, updateHideLineList] = useState(registeredHideLineList ?? []);
  const [operationCheck, setOperationCheck] = useState(false);
  const timezone = useSelector(selectedTimezone);
  
  useEffect(() => {
    sessionStorage.setItem(`cargo-pressure-${vesselId}`, JSON.stringify(hideLineList));
  }, [hideLineList]);

  useLayoutEffect(() => {
    am5.addLicense(process.env.AMCHARTS_LICENSE);
    if (!data?.length) return;
    const groot = am5.Root.new(chartId, {
      useSafeResolution: false,
    });
    groot.utc = timezone === TIMEZONE.UTC;
    groot.setThemes([am5themes_Animated.new(groot)]);
    const xyChart = groot.container.children.push(
      am5xy.XYChart.new(groot, {
        wheelY: "none",
        pinchZoomX: "none",
        pinchZoomY: "none",
        maxTooltipDistance: -1,
        layout: groot.verticalLayout,
        panX: false,
        panY: false,
      })
    );
    const dateAxis = initXAxis(groot, xyChart, false, isOneSec, data);
    const valueAxis = initYAxis(groot, xyChart);
    initScrollbarX(xyChart, groot, false);
    initScrollbarY(xyChart, groot, false);
    initLineSeries(groot, xyChart, paramList, data, dateAxis, valueAxis);
    const tooltip = initTooltip(groot, xyChart);
    initCursor(xyChart, groot, dateAxis, valueAxis);
    initTooltipLabel(xyChart, tooltip);
    const legend = initLegend(
      xyChart,
      groot,
      hideLineList,
      setDataKeyName,
      setColorPickerPos,
      setDisplayColorPicker,
      setDisplayColorPickerFlag,
      GRAPH_TYPE.PRESSURE,
      null,
      initTooltipLabel,
      t
    );

    legend.itemContainers._values.forEach((v) => {
      v.events.on("click", function (ev) {
        const tooltip = initTooltip(groot, xyChart);
        initTooltipLabel(xyChart, tooltip);
        legendRef.current = legend;
      });
    });

    xyChart.plotContainer.events.on("click", function (ev) {
      if (isLocked) {
        isLocked = false;
        handleTooltipUnlocking(xyChart);
      } else {
        isLocked = true;
        handleTooltipLock(xyChart);
      }
    });
    //Click stop trick : END

    //click zoom out button also
    xyChart.zoomOutButton.events.on("click", (e) => {
      if (isLocked) {
        handleTooltipUnlocking(xyChart);
      }
    });
    xyChart.appear(1000, 100);
    legendRef.current = legend;
    lineChartRef.current = xyChart;
    rootRef.current = groot;
    return () => {
      isLocked = false;
      handleTooltipUnlocking(xyChart);
      groot.dispose();
    };
  }, [currentTab, JSON.stringify(data), timezone]);

  useEffect(() => {
    if (lineChartRef.current && displayColorPicker) {
      const tooltip = initTooltip(lineChartRef.current?.root, lineChartRef?.current);
      initTooltipLabel(lineChartRef?.current, tooltip);
    }
  }, [displayColorPicker]);

  useEffect(() => {
    if (lineChartRef.current && displayColorPicker) {
      const tooltip = initTooltip(lineChartRef.current?.root, lineChartRef?.current);
      initTooltipLabel(lineChartRef?.current, tooltip);
    }
  }, [displayColorPicker]);

  useEffect(() => {
    //for User change line color trick
    if (displayColorPicker.flag == "" || displayColorPicker.position == 0) {
      // Do nothing
    } else {
      lineChartRef.current.series.each(function (item, index) {
        if (displayColorPicker.flag == item._settings.name) {
          //color change trick
          item.set("fill", am5.color(displayColorPicker.position));
          item.set("stroke", am5.color(displayColorPicker.position));
          legendRef.current.data.setAll(lineChartRef.current.series.values);
          addLegendFeatures(lineChartRef.current, rootRef.current, legendRef.current, hideLineList, null, initTooltipLabel);
        }
      });
      legendRef.current.data.setAll(lineChartRef.current.series.values);
      addLegendFeatures(lineChartRef.current, rootRef.current, legendRef.current, hideLineList, null, initTooltipLabel);
    }
  }, [displayColorPicker]);

  const initLineSeries = (root, chart, paramList, shownData, dateAxis, valueAxis) => {
    paramList.forEach((value, index) => {
      const color = sessionStorage.getItem(`cargo-pressure-${vesselId}-${value.tag}`)
          ? sessionStorage.getItem(`cargo-pressure-${vesselId}-${value.tag}`)
          : DEFAULT_LINE_COLORS[index];
      const notVisibleFlag = hideLineList.includes(value.tag);
      const valueSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: value.tag,
          valueXField: "dateTime",
          valueYField: value.tag,
          xAxis: dateAxis,
          yAxis: valueAxis,
          visible: !notVisibleFlag,
          fill: am5.color(color),
          stroke: am5.color(color),
          legendLabelText: value.tag,
        })
      );
      if (notVisibleFlag) {
        valueSeries.hide();
      }
      valueSeries.on("visible", (visible, target) => {
        const targetName = target.get("name");
        if (!operationCheck) {
          setOperationCheck(true);
        }
        if (visible) {
          updateHideLineList((prev) => prev.filter((d) => d !== targetName));
        } else {
          updateHideLineList((prev) => prev.concat([targetName]));
        }
      });
      valueSeries.data.setAll(shownData);
    });
  };

  const initTooltipLabel = (chart, tooltip) => {
    const width = 50;
    tooltip.label.adapters.add("text", (text) => {
      text = t(localizationKeys.NoData_lower);
      let hiddenLineCount = 0;
      chart.series.each(function (series, i) {
        const tooltipDataItem = series.get("tooltipDataItem");
        if (tooltipDataItem) {
          if (i === 0) {
            const dateStr = tooltipDataItem.get("valueX");
            const date = new Date(timezone === TIMEZONE.UTC ? dateStr : dayjs(dateStr).format());
            const formatDate = `${date.getUTCFullYear()}/${("00" + (date.getUTCMonth() + 1)).slice(
              -2
            )}/${("00" + date.getUTCDate()).slice(-2)} ${("00" + date.getUTCHours()).slice(-2)}:${(
              "00" + date.getUTCMinutes()
            ).slice(-2)}:${("00" + date.getUTCSeconds()).slice(-2)} (${timezone})\n`;
            text += `[bold]${formatDate}[/]`;
            text = text.replace(t(localizationKeys.NoData_lower), "");
          }
          if (!series.isHidden()) {
            const valueY = tooltipDataItem.get("valueY");
            const strokeColor = series.get("stroke").toString();
            if ((i - hiddenLineCount) % 2 === 0) {
              text += `\n`;
            }
            text += `[${strokeColor} width: ${width}px]${series.get(
              "name"
            )}:[/] [${strokeColor} width: ${width}px]${
              valueY !== null && valueY !== undefined ? valueY : "--"
            }[/]`;
          } else {
            hiddenLineCount += 1;
          }
        }
      });
      return text;
    });
  };

  return (
    <>
      <div id={chartId} style={{ width: "100%", height: "100%" }} />
      <div
        className="colorPickerContainer"
        style={{
          width: "fit-content",
          visibility: displayColorPickerFlag ? "visible" : "hidden",
          position: "relative",
          left: colorPickerPos.x,
          bottom: colorPickerPos.y,
        }}
      >
        <div style={{ left: `10px` }}>
          <ColorPicker
            vesselId={vesselId}
            setDisplayColorPicker={setDisplayColorPicker}
            dataKey={dataKeyName}
            graphType={GRAPH_TYPE.PRESSURE}
            setOnClose={setDisplayColorPickerFlag}
            vesselName={vesselName}
            isCargoPressure={true}
          />
        </div>
      </div>
    </>
  );
};

LineChart.propTypes = {
  data: PropTypes.array,
  currentTab: PropTypes.string,
  vesselId: PropTypes.string,
  isOneSec: PropTypes.bool
};
