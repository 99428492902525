import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useSelector } from "react-redux";
import { emissionChartBarList } from "../../model/slice/chartSettingsSlice";
import { ColorPicker } from "../chart/colorPicker.jsx";
import {
  handleTooltipLock,
  handleTooltipUnlocking,
  initCursor,
  initLegend,
  initScrollbarX,
  initScrollbarY,
  initTooltip,
  initYAxis,
} from "../chart/amcharts.jsx";
import { getDateName } from "./commonFunc";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType";
import { COLORS } from "../../constants/colors";
import { DEVICE_TYPE, detectDevice } from "../../constants/detectDevice.js";
let isLocked = false;

const EmissionBarChart = React.memo((props) => {
  const { vesselName, data, modeName, digits, emissionDuration, t } = props;
  const chartid = "emissionBarChart";
  const shownData = getShownData(data, modeName, digits, emissionDuration);
  //const dataKey = Object.keys(shownData[0])[1]
  const paramList = useSelector(emissionChartBarList);

  //for Color select trick
  const [displayColorPickerFlag, setDisplayColorPickerFlag] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState({
    flag: "",
    position: 0,
  });
  const [dataKeyName, setDataKeyName] = useState("");
  const [colorPickerPos, setColorPickerPos] = useState({ x: 0, y: 0 });

  const refEmissionBarChart = useRef(null);
  const legendRef = useRef(null);
  const rootRef = useRef(null);

  const [hideLineList, updateHideLineList] = useState([]);
  const [initialCheck, setInitialCheck] = useState(false);
  const [operationCheck, setOperationCheck] = useState(false);

  const isMobile = [DEVICE_TYPE.PHONE, DEVICE_TYPE.TABLET].includes(detectDevice)

  useEffect(() => {
    const currentHideLineList = sessionStorage.getItem(`emission-bar-${vesselName}`);
    if (!!currentHideLineList && currentHideLineList.length > 0) {
      updateHideLineList(JSON.parse(currentHideLineList));
    }
    setInitialCheck(true);
  }, []);

  useEffect(() => {
    if (hideLineList.length > 0 || operationCheck) {
      sessionStorage.setItem(`emission-bar-${vesselName}`, JSON.stringify(hideLineList));
    }
  }, [hideLineList]);

  //amCharts area

  useEffect(() => {
    am5.addLicense(process.env.AMCHARTS_LICENSE);
    const root = am5.Root.new(chartid, {
      useSafeResolution: false,
    });
    root.utc = true;
    root.setThemes([am5themes_Animated.new(root)]);
    const touchOptions = isMobile ? {
      pinchZoomX: true,
      pinchZoomY: true,
      panX: true,
      panY: true,
    } : {
      panX: false,
      panY: false,
    };

    const emissionBarChart = root.container.children.push(
      am5xy.XYChart.new(root, {
        wheelY: "zoomX",
        maxTooltipDistance: -1,
        layout: root.verticalLayout,
        ...touchOptions,
      })
    );
    const dateAxis = initXAxisForEmission(root, emissionBarChart, emissionDuration);
    const valueAxis = initYAxis(root, emissionBarChart);
    initBarSeriesForEmission(root, emissionBarChart, paramList, shownData, dateAxis, valueAxis);
    const tooltip = initTooltip(root, emissionBarChart);
    initTooltipLabelForEmission(emissionBarChart, tooltip);
    initCursor(emissionBarChart, root, dateAxis, valueAxis, null, isMobile);
    initScrollbarX(emissionBarChart, root);
    initScrollbarY(emissionBarChart, root);
    const legend = initLegend(
      emissionBarChart,
      root,
      hideLineList,
      setDataKeyName,
      setColorPickerPos,
      setDisplayColorPicker,
      setDisplayColorPickerFlag,
      "",
      true,
      isMobile,
      t,
    );

    legend.itemContainers._values.forEach((v) => {
      v.events.on("click", function (ev) {
        const tooltip = initTooltip(root, emissionBarChart);
        initTooltipLabelForEmission(emissionBarChart, tooltip);
      });
      legendRef.current = legend;
    });

    if (!isMobile) {
      emissionBarChart.plotContainer.events.on("click", () => {
        if (!isLocked) {
          isLocked = true;
          handleTooltipLock(emissionBarChart);
        } else {
          isLocked = false;
          handleTooltipUnlocking(emissionBarChart);
        }
      });

      emissionBarChart.zoomOutButton.events.on("click", () => {
        if (isLocked) {
          handleTooltipUnlocking(emissionBarChart);
        }
      });
    }

    refEmissionBarChart.current = emissionBarChart;
    rootRef.current = root;
    return () => {
      root.dispose();
    };
  }, [data, emissionDuration, initialCheck]);

  useEffect(() => {
    if (refEmissionBarChart.current && displayColorPicker) {
      const tooltip = initTooltip(refEmissionBarChart.current.root, refEmissionBarChart.current);
      initTooltipLabelForEmission(refEmissionBarChart.current, tooltip);
    }
  }, [displayColorPicker]);

  useEffect(() => {
    //for User change line color trick
    if (displayColorPicker.flag == "" || displayColorPicker.position == 0) {
      // Do nothing
    } else {
      refEmissionBarChart.current.series.each(function (item, index) {
        if (displayColorPicker.flag == item._settings.name) {
          //color change trick
          item.columns.template.set("fill", am5.color(displayColorPicker.position));
          item.columns.template.set("stroke", am5.color(displayColorPicker.position));
          //change legend marker color
          const legendDataItem = legendRef.current._dataItems[index];
          const legendMarker = legendDataItem.get("markerRectangle");
          legendMarker.set("fill", am5.color(displayColorPicker.position));
          legendMarker.set("stroke", am5.color(displayColorPicker.position));
        }
      });
    }
  }, [displayColorPicker]);

  const initBarSeriesForEmission = (root, chart, paramList, shownData, dateAxis, valueAxis) => {
    const shownParamList = [];
    shownData[1].forEach((index) => {
      shownParamList.push(paramList[index]);
    });
    shownParamList.forEach((value) => {
      const notVisibleFlag = hideLineList.includes(value.keyName);
      const valueSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: value.keyName,
          valueXField: "dateTime",
          valueYField: value.keyName,
          xAxis: dateAxis,
          yAxis: valueAxis,
          visible: !notVisibleFlag,
          fill: am5.color(
            sessionStorage.getItem(`emission-${vesselName}-${value.keyName}`)
              ? sessionStorage.getItem(`emission-${vesselName}-${value.keyName}`)
              : value.color
          ),
          stroke: am5.color(
            sessionStorage.getItem(`emission-${vesselName}-${value.keyName}`)
              ? sessionStorage.getItem(`emission-${vesselName}-${value.keyName}`)
              : value.color
          ),
          legendLabelText: value.keyName,
        })
      );
      if (notVisibleFlag) {
        valueSeries.hide();
      }
      valueSeries.on("visible", (visible, target) => {
        const targetName = target.get("name");
        if (!operationCheck) {
          setOperationCheck(true);
        }
        if (visible) {
          updateHideLineList((prev) => prev.filter((d) => d !== targetName));
        } else {
          updateHideLineList((prev) => prev.concat([targetName]));
        }
      });
      valueSeries.data.setAll(shownData[0]);
    });
  };

  const initTooltipLabelForEmission = (chart, tooltip) => {
    tooltip.label.adapters.add("text", (text) => {
      text = "";
      chart.series.each(function (series, i) {
        const tooltipDataItem = series.get("tooltipDataItem");
        if (tooltipDataItem) {
          if (i === 0) {
            const date = new Date(tooltipDataItem.get("valueX"));
            const formatDate = `${date.getUTCFullYear()}/${("00" + (date.getUTCMonth() + 1)).slice(
              -2
            )}/${("00" + date.getUTCDate()).slice(-2)} (UTC)\n`;
            text += "[bold]" + formatDate + "[/]";
          }
          if (!series.isHidden() && tooltipDataItem.get("valueY") !== null) {
            text += "\n";
            text +=
              "[" +
              tooltipDataItem.get("graphics").get("fill").toString() +
              " " +
              " width: 130px]" +
              series.get("name") +
              ":[/] " +
              tooltipDataItem.get("valueY");
          }
        }
      });
      return text;
    });
  };

  return (
    <>
      <div id={chartid} style={{ width: "100%", height: `100%` }} />
      <div
        className="colorPickerContainer"
        style={{
          width: "fit-content",
          visibility: displayColorPickerFlag ? "visible" : "hidden",
          position: "relative",
          left: colorPickerPos.x,
          bottom: colorPickerPos.y,
        }}
      >
        <div style={{ left: `10px` }}>
          <ColorPicker
            setDisplayColorPicker={setDisplayColorPicker}
            dataKey={dataKeyName}
            graphType={GRAPH_TYPE.EMISSION_BARS}
            setOnClose={setDisplayColorPickerFlag}
            isEmission={true}
            vesselName={vesselName}
          />
        </div>
      </div>
    </>
  );
});

const getShownData = (data, modeLookup, digits, duration) => {
  const shownData = [];
  if (data === undefined) return [shownData, []];

  let targetArray = [];
  if (duration === "daily") {
    targetArray = Object.values(data.DailyData);
  } else if (duration === "weekly") {
    targetArray = Object.values(data.WeeklyData);
  } else if (duration === "monthly") {
    targetArray = Object.values(data.MonthlyData);
  } else {
    targetArray = Object.values(data.YearlyData);
  }

  const existFlags = [false, false, false, false, false, false];

  targetArray.forEach((dataPacket) => {
    const outObj = {
      dateTime: Date.parse(dataPacket.startDate),
      name: getDateName(dataPacket.startDate),
    };
    for (let i = 0; i < 6; i++) {
      const curModeName = modeLookup[i];
      if (dataPacket.dataByMode[i].LD == 0) {
        continue;
      }

      outObj[curModeName] = Number(dataPacket.dataByMode[i].LD.toFixed(digits));
      existFlags[i] = true;
    }
    shownData.push(outObj);
  });

  //const existModes2 = existFlags.map((flag, index) => flag ? modeLookup[index] : "").filter((val) => val !== "");
  const existModes = existFlags
    .map((flag, index) => (flag ? index : -1))
    .filter((val) => val !== -1);
  // console.log(existModes);
  // const existModesData = existModes.map((modeIndex) => {return {key: modeIndex, visible:true, color: colorList[modeIndex]}})
  return [shownData, existModes];
};

const initXAxisForEmission = (root, chart, duration) => {
  const timeUnit = getUnit(duration);
  const dateAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit, count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {}),
    })
  );
  const xRenderer = dateAxis.get("renderer");
  xRenderer.labels.template.setAll({
    fill: am5.color(COLORS.white),
    opacity: 0.2,
    fontSize: "14px",
    paddingTop: 14,
  });
  xRenderer.grid.template.setAll({
    stroke: am5.color(COLORS.white),
    strokeWidth: 2,
    strokeDasharray: [4, 2],
  });
  return dateAxis;
};

const getUnit = (duration) => {
  if (duration === "daily") {
    return "day";
  } else if (duration === "weekly") {
    return "week";
  } else if (duration === "monthly") {
    return "month";
  } else {
    return "year";
  }
};

export { EmissionBarChart };
