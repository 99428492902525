import { axios_synthetica_api, axios_synthetica_api_token } from "./axiosSettings";
import { synthetica_login, synthetica_pass } from "../constants/synthetica";
import { logger } from "./logger";
import dayjs from "../util/dayjs-init";

let updateTime = dayjs();
let access_token = "";
let company = "";

export const GetSyntheticaToken = async () => {

    const loginObj = {
        username: synthetica_login,
        password: synthetica_pass
    }
    
    const result = await axios_synthetica_api_token
        .post("/login",loginObj)
        .catch((e) => logger.error(`Synthetica API GetSyntheticaToken error ${e}`));

    if(result.status !== 200 || result.data.success === 'false' ){
        logger.error(`Error occurred fetching Synthetica token: ${result.status}`);
        return;
    }

    updateTime = dayjs.utc().add(1, "d");
    access_token = result.data.payload.auth.token;
    company = result.data.payload.user.company;
    
    return access_token;
}

const accessGuard = async () => {
    if (access_token === "" || updateTime.isBefore(dayjs.utc())) {
        await GetSyntheticaToken();
    }
}
export const GetSyntheticaVessels = async () => {
    await accessGuard();

    const result = await axios_synthetica_api(access_token)
      .get(`cii/${company}/vessels`)
      .catch((e) => {
        logger.error(`Synthetica API getVessels error ${e}`);
      });

    if(result.status !== 200 || result.data.success === 'false' ){
        return;
    }

    return result.data.payload;
};

export const GetCIIOverTime = async (vesselName) => {
    await accessGuard();
    const param = {vessel: vesselName};
    const result = await axios_synthetica_api(access_token)
        .post(`cii/${company}/ciiOvertime`, param)
        .catch((e) => {
        logger.error(`Synthetica API GetCIIOverTime error ${e}`);
        });

    if(result.status !== 200 || result.data.success === 'false' ){
        return;
    }

    return result.data.payload;
}

export const GetVesselPerformance = async (vesselName) => {
    await accessGuard();
    const param = {vessel: vesselName};
    const result = await axios_synthetica_api(access_token)
        .post(`cii/${company}/vesselPerformance`, param)
        .catch((e) => {
            logger.error(`Synthetica API GetVesselPerformance error ${e}`);
        });

    if(result.status !== 200 || result.data.success === 'false' ){
        return;
    }

    return result.data.payload;
}

export const GetVoyages = async (vesselName) => {
    await accessGuard();
    const param = {vessel: vesselName};
    const result = await axios_synthetica_api(access_token)
    .post(`cii/${company}/voyages`, param)
        .catch((e) => {
            logger.error(`Synthetica API GetVoyages error ${e}`);
        });

    if(result.status !== 200 || result.data.success === 'false' ){
        return;
    }

    return result.data.payload;
}

export const GetDestinations = async (vesselName) => {
    await accessGuard();
    const param = {vessel: vesselName};
    const result = await axios_synthetica_api(access_token)
    .post(`cii/${company}/destinations`, param)
        .catch((e) => {
            logger.error(`Synthetica API GetDestinations error ${e}`);
        });

    if(result.status !== 200 || result.data.success === 'false' ){
        return;
    }

    return result.data.payload;
}

export const GetTrajectory = async (vesselName, sourceTime) => {
    await accessGuard();
    const param = {vessel: vesselName, source_datetime: sourceTime.utc().format("YYYY-MM-DD hh:mm:ss")};
    const result = await axios_synthetica_api(access_token)
    .post(`cii/${company}/trajectory`, param)
        .catch((e) => {
            logger.error(`Synthetica API GetTrajectory error ${e}`);
        });

    if(result.status !== 200 || result.data.success === 'false' ){
        return;
    }

    return result.data.payload;
}