const judgeValue = (EditMEngine, itemName) => {
  let Ret = false;

  if (EditMEngine[itemName] != null && EditMEngine[itemName] !== "") {
    Ret = true;
  }

  return Ret;
};

export const editVesselSettings = (vesselSettings, selectEngines) => {
  if (!vesselSettings) {
    //return no edit
    return vesselSettings;
  }
  //Exitst MEngine(json)?
  const MEngineJSON = vesselSettings.MEngine;
  let EditMEngine = "";
  if (MEngineJSON != null && MEngineJSON !== "") {
    const position = selectEngines.length > 0 ? selectEngines[0] : "";
    //MEngine exist
    if (position === "") {
      //no position = [0]
      EditMEngine = MEngineJSON[0];
    } else {
      // if no item then return undefined
      const CurretMEngine = MEngineJSON.find(
        (d) => d?.position.toUpperCase() === position.toUpperCase()
      );
      if (!CurretMEngine) {
        //if no position then return no edit
        return vesselSettings;
      }
      // >>> if no item then return undefined
      EditMEngine = CurretMEngine;
    }
    vesselSettings["bore"] = !judgeValue(EditMEngine, "bore")
      ? vesselSettings["bore"]
      : EditMEngine["bore"];
    vesselSettings["stroke"] = !judgeValue(EditMEngine, "stroke")
      ? vesselSettings["stroke"]
      : EditMEngine["stroke"];
    vesselSettings["cylinder"] = !judgeValue(EditMEngine, "cylinder")
      ? vesselSettings["cylinder"]
      : EditMEngine["cylinder"];
    vesselSettings["cycle"] = !judgeValue(EditMEngine, "cycle")
      ? vesselSettings["cycle"]
      : EditMEngine["cycle"];
    vesselSettings["meMaxKw"] = !judgeValue(EditMEngine, "meMaxKw")
      ? vesselSettings["meMaxKw"]
      : EditMEngine["meMaxKw"];
    vesselSettings["meMaxRpm"] = !judgeValue(EditMEngine, "meMaxRpm")
      ? vesselSettings["meMaxRpm"]
      : EditMEngine["meMaxRpm"];
    vesselSettings["meMinRpm"] = !judgeValue(EditMEngine, "meMinRpm")
      ? vesselSettings["meMinRpm"]
      : EditMEngine["meMinRpm"];
  } else {
    //no MEngine exist
    // >>> return no edit
    return vesselSettings;
  }

  return vesselSettings;
};
