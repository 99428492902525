import dayjs from "util/dayjs-init.js";

// import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys";

// const { t } = useTranslation();

export const emissionShortcut = (t) => [
  {
    dateRange: [new Date(dayjs.utc().startOf("d").subtract(6, "d")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastWeek_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().startOf("d").subtract(1, "M")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastMonth_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().startOf("d").subtract(3, "M")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.Last3Month_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().startOf("d").subtract(6, "M")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.Last6Month_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().startOf("d").subtract(1, "y")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastYear_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().startOf("y")), new Date(dayjs.utc())],
    includeTime: true,
    label: "default",
  },
];

export const alarmShortcut = (t) => [
  {
    dateRange: [new Date(dayjs.utc().subtract(1, "d")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastDay_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().subtract(7, "d")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastWeek_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().subtract(1, "M")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastMonth_lower),
  },
];

export const trendShortcut = (t) => [
  {
    dateRange: [new Date(dayjs.utc().subtract(30, "minute")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.Last30Mins_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().subtract(1, "hour")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastHour_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().subtract(1, "d")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastDay_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().subtract(7, "d")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastWeek_lower),
  },
  {
    dateRange: [new Date(dayjs.utc().subtract(30, "d")), new Date(dayjs.utc())],
    includeTime: true,
    label: t(localizationKeys.LastMonth_lower),
  },
];
