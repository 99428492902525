import React from "react";
import localizationKeys from "../../i18n/localizationKeys";
import * as PropTypes from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";
import { styled } from '@mui/system'

const RootGrid = styled('div')({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
});
const Line = styled('p')({
  display: "flex",
  width: "400px",
  height: "35px",
  marginLeft: "40px"
});
const NotSelectedTitle = styled('span')({
  fontFamily: "Inter",
  fontSize: "18px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#fff",
});
const NotSelectedMsg = styled('span')({
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#fff",
});

export const GraphNotSelected = () => {
  const { t } = useTranslation();
  return (
    <RootGrid>
      <Line>
        <NotSelectedTitle>{t(localizationKeys.GraphNotSelected_lower)}</NotSelectedTitle>
      </Line>
      <Line>
        <NotSelectedMsg>{t(localizationKeys.GraphNotSelectedMsg_lower)}</NotSelectedMsg>
      </Line>
    </RootGrid>
  );
};

GraphNotSelected.propTypes = {
  t: PropTypes.func,
};