import { ENGINE_POSITION } from "../../../constants/trendGraph/enginesLine.js";

export const DURATION = {
  ENGINE: 1,
  ELECTRIC: 2,
  ALARM_HISTORY_OCCURRENCE: 3,
  ALARM_HISTORY_RECOVERY_TIME: 4,
  SEP: 5,
  COMPARISON: 6,
  CUSTOM: 7,
};

export const DURATION_OPTION_TYPE = {
  ELECTRIC: "electric",
  PORT: "port",
  STARBOARD: "starboard",
  SEP: "sep",
  CENTER: "center",
  COMPARISON: "comparison",
  CUSTOM: "custom",
};

export const transformOption = (type, position) => {
  if (type === DURATION.ENGINE && position === ENGINE_POSITION.PORT)
    return DURATION_OPTION_TYPE.PORT;
  else if (type === DURATION.ENGINE && position === ENGINE_POSITION.STARBOARD)
    return DURATION_OPTION_TYPE.STARBOARD;
  else if (type === DURATION.ENGINE && position === ENGINE_POSITION.CENTER)
    return DURATION_OPTION_TYPE.CENTER;
  else if (type === DURATION.ELECTRIC) return DURATION_OPTION_TYPE.ELECTRIC;
  else if (type === DURATION.SEP) return DURATION_OPTION_TYPE.SEP;
  else if (type === DURATION.CUSTOM) return DURATION_OPTION_TYPE.CUSTOM;
};
