import React, { useState, useRef } from "react";
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { 
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  Box,
} from "@mui/material";
import localizationKeys from "../../i18n/localizationKeys.js";
import NextButton from "./nextButton.jsx";
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from "react-router-dom";
import { servicePolicyEn } from "../../i18n/servicePolicy/servicePolicyEn";
import { servicePolicyJn } from "../../i18n/servicePolicy/servicePolicyJn";
import { TabSelector } from "../tabSelector/tabSelector.jsx";
import Linkify from "linkify-react";
import { COOKIE_AUTH_KEY } from "../../constants/constants.js";
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  base: {
    padding: "20px",
    width: "100%",
  },
  paper: {
    backgroundColor: "#0a1538",
  },
  title: {
    color: "#ffffff",
    fontFamily: "Inter",
    fontSize: "36px",
    fontStretch: "normal",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "normal",
    paddingLeft: "24px",
  },
  body: {
    color: "#ffffff",
    "& a": {
      color: "#19b2ab",
      // textDecoration: "none",
    },
    "& a:visited": {
      color: "#d6b6ff",
    },
  },
  languageSelector: {
    // border: "solid white",import Checkbox from "./checkBox.jsx";
    backgroundColor: "rgba(2, 2, 2, 0.7)",
    height: "30px",
    width: "30px",
    lineHeight: "32px",
    borderRadius: "50px",
    textAlign: "center",
    color: "#ffffff",
    fontSize: "12px",
    fontWeight: "300",
    cursor: "pointer",
    zIndex: "200",
  },
  enSelector: {
    // position: "relative",
    // top: "8px",
    // right: "100px"
  },
  jpSelector: {
    // position: "relative",
    // top: "-23px",
    // right: "60px"
  },
  languageController: {
    // position: "absolute"
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "10px",
    marginTop: "10px",
  },
  buttonGridLeft: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  buttonGridRight: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  selectedLanguage: {
    border: "solid 2px #28b6c7",
    height: "32px",
    width: "32px",
    margin: "5px",
    textAlign: "center",
    padding: "0px",
  },
  unselectedLanguage: {
    border: "solid 2px #ffffff",
    margin: "5px",
    height: "32px",
    width: "32px",
    textAlign: "center",
    padding: "0px",
  },
  checkboxForm: {
    "& .MuiTypography-body1": {
      color: "#ffffff",
      marginLeft: "5px",
      whiteSpace: "pre-line",
    },
    "& .MuiCheckbox-root": {
      color: "#ffffff",
    },
  },
  indent: {
    // textIndent: "2em",
    paddingLeft: "24px",
    marginTop: "3px",
    marginBottom: "3px",
  },
  indentInline: {
    paddingLeft: "24px",
  },
  contentsArea: {
    backgroundColor: "#2c3a48",
    borderRadius: "12px",
  },
  contentText: {
    fontSize: "16px",
    color: "#ffffff",
    marginLeft: "50px",
    marginRight: "20px",
    textAlign: "left",
    marginBottom: "10px",
  },
  contentsTitle: {
    fontSize: "32px",
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#ffffff",
    textAlign: "center",
    marginTop: "40px",
    marginBottom: "34px",
  },
  contentTextSelected: {
    "&:hover": {backgroundColor: "#28b6c7b3"},
    backgroundColor: "#28b6c7b3",
  },
  strong: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    padding: "10px 24px",
  },
  strongSelected: {
    borderRadius: "5px",
    backgroundColor: "#28b6c74c",
  },
  normalCharacter: {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    paddingLeft: "24px",
    boxDecorationBreak: "clone",
  },
  headerSpace: {
    marginTop: "50px",
  },
}));

const strongMatch = /^(Article|第)/i;
const headerNumbers = /^([0-9]{1,2}.|[０-９]{1,2}.)/i;
const bodyNumbers = /^(\([0-9]{1,2}\)|（[０-９]{1,2}）|（[0-9]{1,2}）|①|②|③|④|⑤|\([a-z]\))/i;
const indentMark = /^%/i;

const articleTitlesEN = servicePolicyEn.split("\n").filter(d => d.match(strongMatch)).map(d => d.split(".\t")[1]);

const regex = /[\(\)（）]/g;
const articleTitlesJP = servicePolicyJn.split("\n")
  .filter(d => d.match(strongMatch))
  .map(d => d.split("条")[1].replace(regex, ""));

const commonTabs = (t) => [
  {
    name: t(localizationKeys.TermsofService_lower),
    id: "termsofService",
  },
];

// https://react.i18next.com/legacy-v9/interpolate#using-usedangerouslysetinnerhtml
const ServicePolicy = ({ t, setUserContext, changeLanguage, i18n }) => {
  const classes = useStyles();
  const location = useLocation();
  const { instance } = useMsal();

  // get userData from Authenticate component in initial login
  const userData = location?.state?.userData;
  const [checked, setChecked] = useState(false);
  const setCookies = () => {
    const cookies = new Cookies();
    cookies.set("userPolicy", "connect-true", { path: "/" });
    if (userData !== undefined && setUserContext !== undefined) {
      setUserContext(userData);
    }
  };

  const [selectedArticle, setSelectedArticle] = useState(null);
  const articleRefs = useRef(Array(articleTitlesEN?.length).fill().map(() => React.createRef()));

  const logout = () => {
    setUserContext(null);
    const cookies = new Cookies();
    cookies.remove(COOKIE_AUTH_KEY);

    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const scrollToArticle = (idx) => {
    setSelectedArticle(idx);
    if (articleRefs.current[idx] && articleRefs.current[idx].current) 
      articleRefs.current[idx].current.scrollIntoView({ behavior: "smooth" });
  };

  const contentsItems = (title, idx) => {
    return (
      <Button 
        className={`${classes.contentText} ${selectedArticle == idx ? classes.contentTextSelected : ""}`}
        onClick={() => scrollToArticle(idx)}
      >
        {title}
      </Button>
    )
  };

  const AgreeServicePolicy = () => {
    if (userData === undefined) {
      return null;
    } else {
      return (
        <Grid container spacing={3} justifyContent="center" style={{ width: "100%" }}>
          <Grid item xs={12}>
            <FormControlLabel
              value="confirm"
              control={
                <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />
              }
              label={t(localizationKeys.ConfirmServicePolicy_lower)}
              labelPlacement="end"
              className={classes.checkboxForm}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.buttonGridLeft}>
            <NextButton
              title={t(localizationKeys.ConfirmServicePolicy_lower)}
              path="/main"
              disabled={!checked}
              onClick={() => setCookies()}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.buttonGridRight}>
            <NextButton
              title={t(localizationKeys.NoConfirmServicePolicy_lower)}
              disabled={false}
              onClick={() => logout()}
            />
          </Grid>
        </Grid>
      );
    }
  };

  const LanguageController = () => (
    <div className={classes.languageController}>
      <div
        className={`${classes.languageSelector} ${classes.enSelector} ${
          i18n?.language === "en-US"
            ? `${classes.selectedLanguage}`
            : `${classes.unselectedLanguage}`
        }`}
        onClick={() => {
          changeLanguage("en-US");
        }}
      >
        EN
      </div>
      <div
        className={`${classes.languageSelector} ${classes.jpSelector} ${
          i18n?.language === "ja" ? `${classes.selectedLanguage}` : `${classes.unselectedLanguage}`
        }`}
        onClick={() => {
          changeLanguage("ja");
        }}
      >
        JP
      </div>
    </div>
  );

  let articleIdx = 0;
  const transaction = (d) => {
    if (d.match(strongMatch)) {
      return (
        <div
          className={`${classes.strong} ${selectedArticle == articleIdx ? classes.strongSelected : ""}`}
          id={`article-${articleIdx}`}
          ref={articleRefs.current[articleIdx++]}
        >
          {d}
        </div>
      );
    } else if (d.match(headerNumbers)) {
      return <div className={`${classes.indent} ${classes.normalCharacter}`}>{d}</div>;
    } else if (d.match(bodyNumbers)) {
      return <div className={`${classes.indentInline} ${classes.normalCharacter}`}>{d}</div>;
    } else if (d.match(indentMark)) {
      return (
        <div className={`${classes.indentInline} ${classes.normalCharacter}`}>
          {d.replace(indentMark, "")}
        </div>
      );
    } else {
      return (
        <>
          <span className={classes.normalCharacter}>{d}</span>
          <br />
        </>
      );
    }
  };

  return (
    <>
      {userData !== undefined ? <LanguageController /> : null}
      <Grid container spacing={2} className={classes.base}>
        {userData !== undefined 
          ? null 
          : <TabSelector tabs={commonTabs(t)} selectedOne={"termsofService"} clickHandler={() => {}} />}
        <Grid item xs={4}>
          <Box className={classes.contentsArea}
            style={userData !== undefined ? {
              overflowY: "scroll",
              height: "calc(100vh - 240px)"
            } : {
              overflowY: "scroll",
              height: "calc(100vh - 180px)"
            }}
          >
            <div className={classes.contentsTitle}>
              {t(localizationKeys.ServiceAgreementContentsTitle_lower)}
            </div>
            {i18n?.language === "ja"
              ? articleTitlesJP.map((title, idx) => (
                <div key={`content-${idx}`}>{contentsItems(title, idx)}</div>
              ))
              : articleTitlesEN.map((title, idx) => (
                <div key={`content-${idx}`}>{contentsItems(title, idx)}</div>
              ))}
          </Box>
        </Grid>
        <Grid item xs={8} style={{ paddingLeft: "20px" }}>
          <Box 
            style={userData !== undefined ? {
              overflowY: "scroll",
              height: "calc(100vh - 240px)"
            } : {
              overflowY: "scroll",
              height: "calc(100vh - 180px)"
            }}
          >
            <Grid item xs={12}>
              <p className={`${classes.title} ${userData === undefined ? classes.headerSpace : null}`}>
                {t(localizationKeys.ServicePolicyTitle_lower)}
              </p>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.body}>
                <Linkify
                  options={{
                    target: "_blank",
                  }}
                >
                  {i18n?.language === "ja"
                    ? servicePolicyJn
                        .split("\n")
                        .map((d, i) => (
                          <React.Fragment key={`sentence-${i}`}>{transaction(d)}</React.Fragment>
                        ))
                    : servicePolicyEn
                        .split("\n")
                        .map((d, i) => (
                          <React.Fragment key={`sentence-${i}`}>{transaction(d)}</React.Fragment>
                        ))}
                </Linkify>
              </div>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* display confirmation in initial login */}
      {userData !== undefined ? <AgreeServicePolicy /> : null}
      {/*</Paper>*/}
    </>
  );
};

ServicePolicy.propTypes = {
  t: PropTypes.func,
  setUserContext: PropTypes.func,
  changeLanguage: PropTypes.func,
  i18n: PropTypes.object,
};

export default withTranslation()(ServicePolicy);
