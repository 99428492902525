import React, { memo } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';

import WeatherIcon from "../../../../util/cargo/weatherIcon";
import { COLORS } from "../../../../constants/colors";

const useStyles = makeStyles(() => ({
  container: {
    margin: "4px",
  },
  temperature: {
    fontSize: "24px",
    fontWeight: "bold",
    color: COLORS.white,
  },
}));

const Weather = memo(({ weather }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} style={{ minHeight: "40px" }}>
        {weather && <img src={WeatherIcon(weather?.weathercode)} width={40} height={40} />}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.temperature}>{weather ? weather.temperature : "-"}°C</div>
      </Grid>
    </Grid>
  );
});

Weather.propTypes = {
  weather: PropTypes.object,
};

export { Weather };
