import { useEffect, useState } from "react";

export const useInnerDict = (ref) => {
  const [dict, setDict] = useState({
    width: 0,
    height: 0,
  });

  const getWidth = () => {
    return {
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight,
    };
  };

  useEffect(() => {
    const handleResize = () => {
      setDict(getWidth());
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  return {
    width: dict.width,
    height: dict.height,
  };
};
