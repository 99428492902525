import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { ENGINE_CHART_LIST } from "./engine/engineList";
import { checkSelectedChart, clickPanel } from "./engine/engineSelector.js";
import { useVesselSettings } from "../../helpers/vesselSetting/useVesselSettings";

const buttonDesign = {
  alignItems: "left",
  justifyContent: "flex-start",
  display: "flex",
  width: "fitContent",
  "&:focus": {
    outline: "none",
  },
};

const buttonTextDesign = {
  color: "#ffffff",
  fontFamily: "Inter",
  fontSize: "11px",
  fontWeight: "300",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textTransform: "none",
  opacity: "100%",
  justifyContent: "flex-start",
};

const useStyles = makeStyles(() => ({
  dataList: {
    "& p": {
      MozUserSelect: "none",
      WebkitUserSelect: "none",
      msUserSelect: "none",
    },
  },
  trueButton: {
    ...buttonDesign,
    "& span": {
      ...buttonTextDesign,
      color: "#19b2ab",
      fontWeight: "600",
    },
    borderRadius: 0,
  },
  falseButton: {
    ...buttonDesign,
    "& span": {
      ...buttonTextDesign,
      color: "#ffffff",
      "&:hover": {
        opacity: "70%",
      },
    },
  },
}));

const GraphSelector = React.memo(
  ({
    chartSettingList = ENGINE_CHART_LIST,
    chartType,
    t = null,
    vesselId,
    selectedChart = null,
    setSelectedChart,
  }) => {
    const classes = useStyles();
    const { vesselSettings } = useVesselSettings({ vesselId });

    const displayChartList = () =>
      Object.entries(chartType).map(([k, v], i) => (
        <Button
          onClick={() => clickPanel(v, vesselSettings?.MEEngineType, setSelectedChart)}
          component="button"
          style={{
            marginRight: "12px",
            minWidth: "0px",
            pointerEvents: v === selectedChart ? "none" : "auto",
          }}
          className={checkSelectedChart(v, selectedChart, classes)}
          key={`displayChartList-${i}`}
        >
          <span>{t(chartSettingList.find((d) => d.id === v)?.title ?? v)}</span>
        </Button>
      ));

    return (
      <Grid
        container
        justifyContent="flex-start"
        direction="row"
        alignItems="center"
      >
        {displayChartList()}
      </Grid>
    );
  }
);

GraphSelector.propTypes = {
  t: PropTypes.func.isRequired,
  selectedChart: PropTypes.string.isRequired,
  setSelectedChart: PropTypes.func.isRequired,
  vesselId: PropTypes.string,
};

export default withTranslation()(GraphSelector);
