export default {
  INVALID_DATE: "--:--",
  ROWS_PER_PAGE: 20,
  AMS_CHANNEL_COLUMN: "channelName",
  PAGE_BUTTON: {
    FORWARD: 1,
    BACK: 2,
  },
  HEADER_ID: {
    VALUE: "inputData",
    STATUS: "chStatus",
    CHANNEL_NUMBER: "chNo",
    CHANNEL_NAME: "chName",
    Notification: "notification",
  },
  ORDER: {
    DESC: "desc",
    ASC: "asc",
  },
};
