import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { GAUGES_TAGS, LCO2_GAUGES } from "../../../constants/cargo/gauges";
import { CARGO_PAGES } from "../../../constants/cargo/pages";

import { GaugeIcon } from "./gauge/index.jsx";
import { COLORS } from "../../../constants/colors";
import { Title } from "../title";
import { useSelector } from "react-redux";
import { isChannelModFetching } from "../../../model/slice/channelSlice.js";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys.js";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    color: COLORS.white,
    background: COLORS.tabSelectorColor,
  },
  transportContainer: {
    display: "flex",
  },
  gauge: {},
  name: {
    fontSize: "10px",
    lineHeight: "14px",
    textAlign: "center",
  },
}));

export const Gauge = memo((props) => {
  const { page, data, isVesselOverview } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const isLoading = useSelector(isChannelModFetching);
  const orderedGaugesTemplate = GAUGES_TAGS.map((tag) =>
    LCO2_GAUGES.find((gauge) => gauge.tag === tag.tag)
  );
  const gauges = useMemo(
    () => orderedGaugesTemplate.filter((gauge) => gauge.visibleIn.includes(page)),
    [page]
  );
  const isTransport = page === CARGO_PAGES.TRANSPORT;
  return (
    <Grid container className={`${!isVesselOverview && classes.container} ${classes.transportContainer}`}>
      {!isVesselOverview && <Title title={t(localizationKeys.StatusGauge)} />}
      {isLoading ? (
        <CircularProgress style={{ margin: "0 auto" }} />
      ) : (
        gauges.map((gauge) => (
          <Grid
            item
            xs={isTransport ? 6 : 4}
            className={classes.gauge}
            key={gauge.tag}
            style={{ transform: isTransport ? "scale(1.5)" : "scale(1.0)" }}
          >
            <div className={classes.name}>{t(gauge.name)}</div>
            <GaugeIcon
              gauge={gauge}
              isVesselOverview={isVesselOverview}
              value={data.find((value) => value.tag === gauge.tag)?.inputData}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
});

Gauge.propTypes = {
  page: PropTypes.string,
  data: PropTypes.array,
  isVesselOverview: PropTypes.bool,
};
