import { createSlice } from "@reduxjs/toolkit";
import Actions from "../action/chartDisplayTypeAction";
import { TOGGLE_OPTION_LIST } from "../../constants/monitoring/constants";

const chartDisplayTypeSlice = createSlice({
  name: "chartDisplayTypeController",
  initialState: {
    chartDisplayType: TOGGLE_OPTION_LIST[0],
  },
  reducers: {
    ...Actions,
  },
});

export const selectedChartDisplayType = (state) =>
  state.chartDisplayTypeController.chartDisplayType;
export const { setSelectedChartDisplayType } = chartDisplayTypeSlice.actions;

export default chartDisplayTypeSlice.reducer;
