import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from 'util/dayjs-init.js';
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from "react-redux";

import DownloadIcon from "./downloadIcon.jsx";

import { getSepDataDuration, getDurationOption } from "../../../model/slice/dataDurationSlice";
import {
  selectedVessel,
  setSelectedVessel,
} from "../../../model/slice/fleetNavigationDataSlice.js";
import {
  createCsvDownload,
  oneSecDownload,
  useCsvDownload,
} from "../../../helpers/csvDownload/createCSV";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType.js";
import { chartsData, chartsSettings } from "../../../model/slice/chartsSlice.js";
import { calDurations } from "../../../constants/calDuration.js";
import { selectedTimezone } from "../../../model/slice/timezoneSlice.js";
import { TIMEZONE } from "../../../constants/timezone.js";
import { formatDate } from "../../../util/timezone.js";
import { DATE_FORMAT } from "../../../constants/constants.js";

const useStyles = makeStyles((theme) => ({
  downloadIcon: {
    width: "25px",
    height: "25px",
    padding: "0px 0px",
    minWidth: "0px",
    marginRight: "10px",
  },
}));

export const CsvDownload = React.memo(
  ({
    vesselId,
    graphData,
    graphType,
    selectedChart,
    selectedEngine,
    isDownloading,
    setIsDownloading,
    isDurationDisabled,
    isNoData,
    is1SecFetchEnabled,
    isComparison = false,
    graphRef,
    vesselName,
    isCargo,
    chartsNameSettings,
    customChartsList,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const customChartData = useSelector(chartsData);
    const customChartSettings = useSelector(chartsSettings);
    const currentDuration = useSelector(getSepDataDuration);
    const durationOption = useSelector(getDurationOption);
    const currentSelectedVessel = useSelector(selectedVessel);
    const timezone = useSelector(selectedTimezone);
    const currentVesselName = currentSelectedVessel?.vessel_name;
    const isCustom = graphType === GRAPH_TYPE.CUSTOM;
    
    useEffect(() => {
      if (!currentSelectedVessel) {
        dispatch(setSelectedVessel(vesselId));
      }
    }, []);

    const formatGraphDataForCSV = (graphData, isCustom) => {
      if (!graphData) return [];
      if (isCustom) {
        const graphDataWithChannelName = [];
        graphData?.forEach((data) => {
          const obj = {};
          obj.dateTime = data.dateTime;
          customChartSettings?.forEach((e) => {
            obj[e.name] = data[e.key];
          });
          graphDataWithChannelName.push(obj);
        });
        return graphDataWithChannelName;
      }
      return graphData;
    };

    const formatGraphDataForCSVOnComparison = (graphData, isCustom, nameSettings) => {
      if (!graphData || Object.keys(graphData).length === 0) return [];
      if (!isCustom) {
        return graphData[Object.keys(graphData)[0]];
      }else{
        // data come after changing graphType
        if(!graphData?.length) return [];
        const graphDataWithChannelName = [];
        graphData?.forEach((data) => {
          const obj = {};
          obj.dateTime = data.dateTime;
          nameSettings?.forEach((e) => {
            obj[e.name] = data[e.key];
          });
          graphDataWithChannelName.push(obj);
        });
        return graphDataWithChannelName;
      }
    };

    const formatChartName = (selectedChart, isCustom, isComparison) => {
      if (!isCustom) return selectedChart;
      const returnVal = isComparison 
        ? customChartsList?.charts?.charts?.find((v) => v.id === selectedChart)
        : customChartData?.charts?.find((v) => v.id === selectedChart);
      return returnVal.name.replace(/\s/g, "-");
    };
    const formattedData = useCallback((isComparison
        ? formatGraphDataForCSVOnComparison(graphData, isCustom, chartsNameSettings)
        : formatGraphDataForCSV(graphData, isCustom))
          .map((data, idx) => {
            data.downloadDateTime = formatDate(data.dateTime, timezone, DATE_FORMAT);
            delete data.localDateTime;
            return data;
          }),
      [graphData, timezone]);

    const { blob } = useCsvDownload({ data: formattedData, formatter: "," });
    const downloadCsvOnClick = async () => {
      if (isDownloading) return;
      const formattedName = formatChartName(selectedChart, isCustom, isComparison);
      const formattedDate = `${(timezone === TIMEZONE.UTC ? dayjs.utc() : dayjs()).format("YYYY-MM-DD-HH-mm-ss")}(${timezone})`;
      const do1SecFetch = () => (isComparison && durationOption?.comparison?.value <= 1) || calDurations({ startDate: currentDuration.startDate, endDate: currentDuration.endDate}) <= 1
      if (
        is1SecFetchEnabled &&
        graphType === GRAPH_TYPE.SEP &&
        do1SecFetch()
      ) {
        await oneSecDownload({
          vesselId,
          startDate: currentDuration?.startDate,
          endDate: currentDuration?.endDate,
          filename: `${
            isComparison
              ? vesselName?.replace(/\s/g, "-")
              : currentVesselName?.replace(/\s/g, "-") ?? vesselId
          }_${graphType}_${formattedName}_${formattedDate}`,
          sepChartType: selectedChart,
          setIsDownloading,
          isDownloading,
          timezone,
        });
        return;
      }
      if (!blob) return;

      createCsvDownload({
        blob,
        filename:
          graphType === GRAPH_TYPE.ELECTRIC
            ? isComparison
              ? `${vesselName?.replace(/\s/g, "-") ?? vesselId}_${graphType}_${formattedDate}`
              : `${currentVesselName?.replace(/\s/g, "-") ?? vesselId}_${graphType}_${formattedDate}`
            : isComparison
            ? `${vesselName?.replace(/\s/g, "-") ?? vesselId}_${graphType}_${
                selectedEngine ? selectedEngine.split("_")[0] + "_" : ""
              }${formattedName}_${formattedDate}`
            : `${currentVesselName?.replace(/\s/g, "-") ?? vesselId}_${graphType}_${
                selectedEngine ? selectedEngine.split("_")[0] + "_" : ""
              }${formattedName}_${formattedDate}`,
      });
    };
    const shouldBeDisabled = (isDurationDisabled || blob === false || isNoData || isDownloading);
    return (
      <Button ref={graphRef} className={classes.downloadIcon} disabled={shouldBeDisabled} onClick={() => downloadCsvOnClick()}>
        <DownloadIcon
          color={shouldBeDisabled ? "#ffffff" : "#D8D8D8"}
          opacity={shouldBeDisabled ? "0.5" : "1"}
        />
      </Button>
    );
  }
);

CsvDownload.propTypes = {
  vesselId: PropTypes.string,
  graphData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  graphType: PropTypes.string,
  selectedChart: PropTypes.string,
  selectedEngine: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isDownloading: PropTypes.bool,
  setIsDownloading: PropTypes.func,
  isDurationDisabled: PropTypes.bool,
  isNoData: PropTypes.bool,
  is1SecFetchEnabled: PropTypes.bool,
  isComparison: PropTypes.bool,
  graphRef: PropTypes.any,
  vesselName: PropTypes.string,
  isCargo: PropTypes.bool,
  chartsNameSettings: PropTypes.array,
  customChartsList: PropTypes.array,
};
