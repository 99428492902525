import { createSlice } from "@reduxjs/toolkit";
import Actions from "../action/timezoneAction";

const timezoneSlice = createSlice({
  name: "timezone",
  initialState: {
    timezone: "UTC",
  },
  reducers: {
    ...Actions,
  },
});

export const selectedTimezone = (state) => state.timezoneController.timezone;

export const { setTimezone } = timezoneSlice.actions;

export default timezoneSlice.reducer;
