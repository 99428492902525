const localizationKeys = {
  FleetOverview_upper: "FleetOverview_upper",
  NavigationData_upper: "NavigationData_upper",
  NavigationData_lower: "NavigationData_lower",
  WindSpeed_upper: "WindSpeed_upper",
  WindDirection_upper: "WindDirection_upper",
  VesselOgSpeed_upper: "VesselOgSpeed_upper",
  VesselInformation_upper: "VesselInformation_upper",
  CallSign_upper: "CallSign_upper",
  VesselClass_upper: "VesselClass_upper",
  Mmsi_upper: "Mmsi_upper",
  Itinerary_upper: "Itinerary_upper",
  Eta_upper: "Eta_upper",
  Departure_upper: "Departure_upper",
  Destination_upper: "Destination_upper",
  Engine_upper: "Engine_upper",
  Engine_lower: "Engine_lower",
  BasicEngineData_upper: "BasicEngineData_upper",
  BasicSepData_upper: "BasicSepData_upper",
  SepData_lower: "SepData_lower",
  Trim_upper: "Trim_upper",
  Heel_upper: "Heel_upper",
  EngineLoad_upper: "EngineLoad_upper",
  EngineLoad_lower: "EngineLoad_lower",
  EngineRevolution_upper: "EngineRevolution_upper",
  EngineRevolution_lower: "EngineRevolution_lower",
  TotalEngineFoc_upper: "TotalEngineFoc_upper",
  TotalEngineFoc_lower: "TotalEngineFoc_lower",
  TotalDieselEngineFoc_upper: "TotalDieselEngineFoc_upper",
  TotalDieselEngineFoc_lower: "TotalDieselEngineFoc_lower",
  CurrentPosition_upper: "CurrentPosition_upper",
  EngineData_upper: "EngineData_upper",
  EngineData_lower: "EngineData_lower",
  LoadDiagram_upper: "LoadDiagram_upper",
  PowerCurve_upper: "PowerCurve_upper",
  PowerBase_upper: "PowerBase_upper",
  Foc_upper: "Foc_upper",
  Shp_upper: "Shp_upper",
  Speed_lower: "Speed_lower",
  Og_upper: "Og_upper",
  Log_upper: "Log_upper",
  EngineTrendGraph_upper: "EngineTrendGraph_upper",
  CylinderDeviation_lower: "CylinderDeviation_lower",
  CoolingFreshWaterTemp_lower: "CoolingFreshWaterTemp_lower",
  EngineTemp_lower: "EngineTemp_lower",
  EnginePress_lower: "EnginePress_lower",
  Logout_lower: "Logout_lower",
  Settings_lower: "Settings_lower",
  ProductInformation_lower: "ProductionInformation_lower",
  Generator_upper: "Generator_upper",
  Electric_upper: "Electric_upper",
  Electric_lower: "Electric_lower",
  TotalPower_upper: "TotalPower_upper",
  ElectricTrendGraph_upper: "ElectricTrendGraph_upper",
  DataDuration_lower: "DataDuration_lower",
  Hours_lower: "Hours_lower",
  Day_lower: "Day_lower",
  Week_lower: "Week_lower",
  Month_lower: "Month_lower",
  Custom_lower: "Custom_lower",
  Custom_upper: "Custom_upper",
  Last30Mins_lower: "Last30Mins_lower",
  LastHour_lower: "LastHour_lower",
  LastDay_lower: "LastDay_lower",
  LastWeek_lower: "LastWeek_lower",
  LastMonth_lower: "LastMonth_lower",
  Last3Month_lower: "Last3Month_lower",
  Last6Month_lower: "Last6Month_lower",
  LastYear_lower: "LastYear_lower",
  Monitoring_upper: "Monitoring_upper",
  Monitoring_lower: "Monitoring_lower",
  CylinderTemp_upper: "CylinderTemp_upper",
  TrendData_upper: "TrendData_upper",
  DisconnectedNodata_lower: "DisconnectedNodata_lower",
  Disconnected_upper: "Disconnected_upper",
  LastUpdate_upper: "LastUpdate_upper",
  NoData_lower: "NoData_lower",
  GraphNotSelected_lower: "GraphNotSelected_lower",
  GraphNotSelectedMsg_lower: "GraphNotSelectedMsg_lower",
  AlarmMonitoring_upper: "AlarmMonitoring_upper",
  AlarmMonitoring_lower: "AlarmMonitoring_lower",
  Status_upper: "Status_upper",
  MainEngine_upper: "MainEngine_upper",
  Fire_upper: "Fire_upper",
  LowInsulation_upper: "LowInsulation_upper",
  ActiveAlarms_upper: "ActiveAlarms_upper",
  AlarmName_lower: "AlarmName_lower",
  Category_lower: "Category_lower",
  DateTime_lower: "DateTime_lower",
  Status_lower: "Status_lower",
  Data_lower: "Data_lower",
  Notification_upper: "NOTIFICATION",
  Notification_Lower: "Notification",
  NoActiveAlarms_lower: "NoActiveAlarms_lower",
  NoActiveAlarms_upper: "NoActiveAlarms_upper",
  NoOperations_upper: "NoOperations_upper",
  NoAlarmHistory_upper: "NoAlarmHistory_upper",
  NoChannel_upper: "NoChannel_upper",
  SelectDate_lower: "SelectDate_lower",
  Submit_lower: "Submit_lower",
  TermsofService_lower: "TermsofService_lower",
  ServiceAgreementContentsTitle_lower: "ServiceAgreementContentsTitle_lower",
  ServicePolicyTitle_lower: "ServicePolicyTitle_lower",
  ServicePolicyText_lower: "ServicePolicyText_lower",
  ServicePolicyTitle_upper: "ServicePolicyTitle_upper",
  ConfirmServicePolicy_lower: "ConfirmServicePolicy_lower",
  NoConfirmServicePolicy_lower: "NoConfirmServicePolicy_lower",
  ServicePolicyLink_lower: "ServicePolicyLink_lower",
  SelectedLanguage_lower: "SelectedLanguage_lower",
  BackMenu_lower: "BackMenu_lower",
  Occurrence_lower: "Occurrence_lower",
  RecoveryTime_lower: "RecoveryTime_lower",
  Search_lower: "Search_lower",
  AlarmHistory_upper: "AlarmHistory_upper",
  AlarmHistory_lower: "AlarmHistory_lower",
  AlarmOperations_upper: "AlarmOperations_upper",
  AlarmOperations_lower: "AlarmOperations_lower",
  AlarmOperationItem_lower: "AlarmOperationItem_lower",
  AlarmOperationItemAbbr_lower: "AlarmOperationItemAbbr_lower",
  AlarmOperationType_lower: "AlarmOperationType_lower",
  AlarmOperationTypeAbbr_lower: "AlarmOperationTypeAbbr_lower",
  Action_lower: "Action_lower",
  Alarm_upper: "Alarm_upper",
  OutOf_lower: "OutOf_lower",
  Value_lower: "Value_lower",
  ResetSearch_lower: "ResetSearch_lower",
  ElectricData_upper: "ElectricData_upper",
  ElectricData_lower: "ElectricData_lower",
  Minutes_lower: "Minutes_lower",
  EngineSelection_lower: "EngineSelection_lower",
  Last_lower: "Last_lower",
  StartTime_lower: "StartTime_lower",
  EndTime_lower: "EndTime_lower",
  LegTrend_lower: "LegTrend_lower",
  Thruster_lower: "Thruster_lower",
  Crane1_lower: "Crane1_lower",
  Crane2_lower: "Crane2_lower",
  TrimAndHeel_lower: "TrimAndHeel_lower",
  SepTrend_upper: "SepData_upper",
  DisconnectedNoEngineData_lower: "DisconnectedNoEngineData_lower",
  CPP_lower: "CPP_lower",
  Port_abbreviation: "Port_abbreviation",
  Starboard_abbreviation: "Starboard_abbreviation",
  Center_abbreviation: "Center_abbreviation",
  Number_abbreviation: "Number_abbreviation",
  Total_upper: "Total_upper",
  Power_upper: "Power_upper",
  Load_upper: "Load_upper",
  RunningTime_upper: "RunningTime_upper",
  RunningTime_Lower: "RunningTime_Lower",
  CPP_label: "CPP_label",
  Unit: "Unit",
  PortEngine: "PortEngine",
  StarboardEngine: "StarboardEngine",
  CenterEngine: "CenterEngine",
  DataComparison_upper: "DataComparison_upper",
  DataComparison_lower: "DataComparison_lower",
  Channel_upper: "Channel_upper",
  AllChannel_lower: "AllChannel_lower",
  ChannelFavorites_lower: "ChannelFavorites_lower",
  Graph_upper: "Graph_upper",
  vessel_lower: "vessel_lower",
  graph_lower: "graph_lower",
  SEP_upper: "SEP_upper",
  MainEngine_abbreviation: "MainEngine_abbreviation",
  Filters_lower: "Filters_lower",
  ResetSearchOptions_lower: "ResetSearchOptions_lower",
  SortByHighestValue: "SortByHighestValue",
  SortByLowestValue: "SortByLowestValue",
  SortByNewest: "SortByNewest",
  SortByOldest: "SortByOldest",
  SortAlarmItemAZ: "SortAlarmItemAZ",
  SortAlarmItemZA: "SortAlarmItemZA",
  SortAscendingNo: "SortAscendingNo",
  SortDescendingNo: "SortDescendingNo",
  SortByNewestOccurence: "SortByNewestOccurence",
  SortByOldestOccurence: "SortByOldestOccurence",
  ShowUnresolvedAlarms: "ShowUnresolvedAlarms",
  SortOperationItemAZ: "SortOperationItemAZ",
  SortOperationItemZA: "SortOperationItemZA",
  SortChannelNameAZ: "SortChannelNameAZ",
  SortChannelNameZA: "SortChannelNameZA",
  ResetSort: "ResetSort",
  Emissions_upper: "Emissions_upper",
  EmissionsOverview_lower: "EmissionsOverview_lower",
  OccurrenceTime: "OccurrenceTime",
  UpdateTime: "UpdateTime",
  ChannelName: "ChannelName",
  ChannelNumber: "ChannelNumber",
  Loading: "Loading",
  OperationalAnalysis: "OperationalAnalysis",
  EmissionDataSelect: "EmissionDataSelect",
  EmissionDataDuration: "EmissionDataDuration",
  EmissionDataDurationStart: "EmissionDataDurationStart",
  EmissionDataDurationEnd: "EmissionDataDurationEnd",
  EmissionDataDurationError: "EmissionDataDurationError",
  EmissionExportData: "EmissionExportData",
  EmissionExportDataButton: "EmissionExportDataButton",
  ShowGraphVariables: "ShowGraphVariables",
  HideGraphVariables: "hideGraphVariables",
  ChangeColor: "ChangeColor",

  // Custom Charts
  CustomSubtitle: "CustomSubtitle",
  CustomSearchChannels: "CustomSearchChannels",
  CustomSearchChannelsPlaceholder: "CustomSearchChannelsPlaceholder",
  CustomShowAll: "CustomShowAll",
  CustomChartCountNote: "CustomChartCountNote",
  CustomNoMatchFound: "CustomNoMatchFound",
  CustomGraphPreview: "CustomGraphPreview",
  CustomVariableNote: "CustomVariableNote",
  CustomSaveGraph: "CustomSaveGraph",
  CustomCancel: "CustomCancel",
  CustomDelete: "CustomDelete",
  CustomChannelNo: "CustomChannelNo",
  CustomChannelName: "CustomChannelName",
  CustomUnit: "CustomUnit",
  CustomSignalType: "CustomSignalType",
  CustomGraphPreviewFull: "CustomChannelsAdded",
  CustomVariableNoteFull: "CustomVariableNoteFull",
  CustomPromptCancelTitle: "CustomPromptCancelTitle",
  CustomPromptCancelSubtitle: "CustomPromptCancelSubtitle",
  CustomPromptCancelOkButton: "CustomPromptCancelOkButton",
  CustomPromptCancelCancelButton: "CustomPromptCancelCancelButton",
  CustomPromptSaveTitle: "CustomPromptSaveTitle",
  CustomPromptSaveSubtitle: "CustomPromptSaveSubtitle",
  CustomPromptSaveOkButton: "CustomPromptSaveOkButton",
  CustomPromptSaveCancelButton: "CustomPromptSaveCancelButton",
  CustomPromptDeleteTitle: "CustomPromptDeleteTitle",
  CustomPromptDeleteSubtitle: "CustomPromptDeleteSubtitle",
  CustomPromptDeleteOkButton: "CustomPromptDeleteOkButton",
  CustomPromptDeleteCancelButton: "CustomPromptDeleteCancelButton",
  CustomPromptLeaveTitle: "CustomPromptLeaveTitle",
  CustomPromptLeaveSubtitle: "CustomPromptLeaveSubtitle",
  CustomPromptLeaveOkButton: "CustomPromptLeaveOkButton",
  CustomPromptLeaveCancelButton: "CustomPromptLeaveCancelButton",
  CustomLastEdited: "CustomLastEdited",
  CustomMenuAdd: "CustomMenuAdd",

  // LCO2
  LCO2Page: "LCO2Page",
  Transport: "Transport",
  Handling: "Handling",
  CargoStatus: "CargoStatus",
  LocalTime: "LocalTime",
  FlowRate: "FlowRate",
  FlowSpeed: "FlowSpeed",
  Density: "Density",
  AMBTemperature: "AMBTemperature",
  Tank: "Tank",
  CargoTank: "CargoTank",
  ShieldInnerSurfaceTemp: "ShieldInnerSurfaceTemp",
  ShieldOuterSurfaceTemp: "ShieldOuterSurfaceTemp",
  TankCoverInnerSurfaceTemp: "TankCoverInnerSurfaceTemp",
  CargoTankPress: "CargoTankPress",
  CargoTankTemp: "CargoTankTemp",
  PressureLoss: "PressureLoss",
  XAngularVelocity: "XAngularVelocity",
  YAngularVelocity: "YAngularVelocity",
  PositionAndWeather: "PositionAndWeather",
  TrueWind: "TrueWind",
  RelativeWind: "RelativeWind",
  VesselSpeedOG: "VesselSpeedOG",
  LongAndLat: "LongAndLat",
  CargoMimic: "CargoMimic",
  TankLevel: "TankLevel",
  No1TankPress: "No1TankPress",
  No2TankPress: "No2TankPress",
  StatusGauge: "StatusGauge",
  TankPress: "TankPress",
  No2CargoPPDisch: "No2CargoPPDish",
  No2CargoPPSuction: "No2CargoPPSuction",
  PortManifoldLiquid: "PortManifoldLiquid",
  StarboardManifoldLiquid: "StarboardManifoldLiquid",
  CargoTemperature: "CargoTemperature",
  CargoTemperatureAndPressure: "CargoTemperatureAndPressure",
  CargoLoading: "CargoLoading",
  CargoUnloading: "CargoUnloading",
  CSVDownload: "CSVDownload",
  NoLatestData: "NoLatestData",
  NoLatestDataForChart: "N/A",
  BackToLatest: "BackToLatest",

  // Channel alert threshold settings
  ChannelOptions: "ChannelOptions",
  ConnectNotification: "ConnectNotification",
  ThresholdReached: "ThresholdReached",
  SystemExternalSettings: "SystemExternalSettings",
  SelectThresholdForNotification: "SelectThresholdForNotification",
  InputEmailAddresses: "InputEmailAddresses",
  AddEmail: "AddEmail",
  SetThreshold: "SetThreshold",
  AddThreshold: "AddThreshold",
  SaveChanges: "SaveChanges",
  NoSaveConfirmation1st: "NoSaveConfirmation1st",
  NoSaveConfirmation2nd: "NoSaveConfirmation2nd",
  ContinueEditing: "ContinueEditing",
  ExitWithoutSaving: "ExitWithoutSaving",
  DeleteEmailConfirmation1st: "DeleteEmailConfirmation1st",
  DeleteEmailConfirmation2nd: "DeleteEmailConfirmation2nd",
  RemoveEmail: "RemoveEmail",
  ReturnToChannelOptions: "ReturnToChannelOptions",
  NoMatchConfirmation1st: "NoMatchConfirmation1st",
  NoMatchConfirmation2nd: "NoMatchConfirmation2nd",
  ConfirmAndSave: "ConfirmAndSave",
  NotExceedMax: "NotExceedMax",
  NotExceedMin: "NotExceedMin",
  NotExceedLow: "NotExceedLow",
  NotExceedHigh: "NotExceedHigh",
  Max: "Max",
  Min: "Min",
  CreateHighThreshold: "CreateHighThreshold",
  CreateLowThreshold: "CreateLowThreshold",
  HighThreshold: "HighThreshold",
  LowThreshold: "LowThreshold",

  //Synthetica
  Rank: "Rank",
  Distance: "Distance",
  VesselDetails: "VesselDetails",
  VesselPerformance: "VesselPerformance",
  DistanceTraveled: "DistanceTraveled",
  CO2: "Co2",
  FuelConsumption: "FuelConsumption",
  AverageSpeed: "AverageSpeed",
  VoyageHistory: "VoyageHistory",
  DepartureTimeUTC: "DepartureTimeUTC",
  ArrivalTimeUTC: "ArrivalTimeUTC",
  Departed: "Departed",
  SpeedKn: "SpeedKn",
  Destination: "Destination",
  CII: "CII",
  CIIDiff: "CIIDiff",
  DailyCII: "DailyCII",
  CurrentCII: "CurrentCII",
  RequiredCII: "RequiredCII",
  PredictedEOYCII: "PredictedEOYCII",
  PredictedIntervalCII: "PredictedIntervalCII",
  CIIOverTime: "CIIOverTime",

  // Vessel Overview
  VesselDetailsOverview: "VesselDetailsOverview",
  Updated: "Updated",
  VesselClass: "VesselClass",
  CIIRanking: "CIIRanking",
  VesselSpeed: "VesselSpeed",
  VesselPosition: "VesselPosition",
  TrueWindSpeed: "TrueWindSpeed",
  TrueWindDirection: "TrueWindDirection",
  LocalWeather: "LocalWeather",
  SeaWaterTemp: "SeaWaterTemp",
  ReposedAlarms: "ReposedAlarms",
  GoToAlarmDetails: "GoToAlarmDetails",
  CurrentStatus: "CurrentStatus",
  MainEngineLoad: "MainEngineLoad",
  MainEnginePowerCurve: "MainEnginePowerCurve",
};

export default localizationKeys;
