import { axios } from "./axiosSettings";
import { getAuthHeader } from "../helpers/session";

//This is JRCS company ID in master DB'.
//Remove this once iPortal has been finalized and admin role is already established.
import { JRCS_ID } from "../constants/jrcsID"

export const fetchFleetLatestNavigationData = async (manager_ids, vessel_ids) => {
  //If user has no company, do not display anything in fleet
  if (manager_ids.length == 0 && vessel_ids.length == 0) {
    return;
  }

  //If user is from JRCS.. set owner id to null. In iAPI, null string for fleet means
  //user will be able to retrieve all ships regardless of company. So for JRCS users, we can see all ships of all owners
  if (manager_ids.includes(JRCS_ID)) {
    manager_ids = [];
  }

  try {
    const { data } = await axios.post(
      `/fleet/latestNavigationData`, 
      {
        manager_ids,
        vessel_ids,
      },
      {
        headers: getAuthHeader(),
      }
    );

    return { fleetLatestNavigationData: data };
  } catch (e) {
    throw new Error(e);
  }
};

export const fetchFleetLatestEngineData = async (manager_ids, vessel_ids) => {
  //If user has no company, do not display anything in fleet
  if (manager_ids.length == 0 && vessel_ids.length == 0) {
    return;
  }

  //If user is from JRCS.. set owner id to null. In iAPI, null string for fleet means
  //user will be able to retrieve all ships regardless of company. So for JRCS users, we can see all ships of all owners
  if (manager_ids.includes(JRCS_ID)) {
    manager_ids = [];
  }

  try {
    const { data } = await axios.post(
      `/fleet/latestEngineData`, 
      {
        manager_ids,
        vessel_ids,
      },
      {
        headers: getAuthHeader(),
      }
    );

    return { fleetLatestEngineData: data };
  } catch (e) {
    throw new Error(e);
  }
};

export const fetchFleetLatestEngineDataByVesselId = async (vessel_id) => {
  try {
    const { data } = await axios.get(`/fleet/latestEngineData`, {
      params: {
        vessel_id,
      },
      headers: getAuthHeader(),
    });

    return { fleetLatestEngineData: data };
  } catch (e) {
    throw new Error(e);
  }
};
