export const checkCondition = (acb) => {
  if (acb === "OPEN") {
    return true;
  } else if (acb === "CLOSE") {
    return false;
  }
};

export const GE_MAX_POWER = 1300;
export const SG_MAX_POWER = 1600;

export const inlinePaper = (theme) => ({
  backgroundColor: "rgba(44, 58, 72, 0.3)",
  padding: theme.spacing(2),
  minHeight: "300px",
});
