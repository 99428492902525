import dayjs from 'util/dayjs-init.js';

export const checkEndOfMonth = (date) => {
  const endOfMonth = dayjs(date).endOf("month").format("YYYY-MM-DD");
  return dayjs(date).format("YYYY-MM-DD") === endOfMonth;
};

export const getName = (date) => {
  const time = new Date(date);
  const month = new Intl.DateTimeFormat("en-US", {
    month: "short",
    timeZone: "UTC",
  }).format(time);
  const year = new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    timeZone: "UTC",
  }).format(time);
  return `${month}'${year}`;
};

export const getDateName = (date) => {
  const time = dayjs.utc(date);
  const month = new Intl.DateTimeFormat("en-US", {
    month: "short",
    timeZone: "UTC",
  }).format(time);
  const year = new Intl.DateTimeFormat("en-US", {
    year: "2-digit",
    timeZone: "UTC",
  }).format(time);
  return `${month} ${time.format("DD")} '${year}`;
};
