function concatArrayBuffers(chunks) {
  const result = new Uint8Array(chunks.reduce((a, c) => a + c.length, 0));
  let offset = 0;
  for (const chunk of chunks) {
    result.set(chunk, offset);
    offset += chunk.length;
  }
  return result;
}

export async function convertStreamToJson(stream) {
  const chunks = [];
  const reader = stream.getReader();
  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    } else {
      chunks.push(value);
    }
  }
  const data = concatArrayBuffers(chunks);
  const decodedData = new TextDecoder().decode(data);
  const ret = decodedData.replaceAll("][", ",").replaceAll(`'`, "");
  return ret ? JSON.parse(ret) : [];
}
