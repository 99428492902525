export const checkValueRangeForChart = (value, range) => {
  const min = -1 * range;
  const max = range;
  if (value < min) {
    return min;
  }
  if (min <= value && value <= max) {
    return value;
  }
  if (value > max) {
    return max;
  }
  return 0;
};

export const customIsNaN = (value) => isNaN(parseFloat(value));

export const convertNumber = (value) => {
  if (value === null) {
    return NaN;
  }
  return Number(value);
};
