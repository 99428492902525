import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import CompassBg from "../../../../assets/images/cargo/navigation/compassBg.svg";
import Degrees from "../../../../assets/images/cargo/navigation/degrees.svg";
import TwIcon from "../../../../assets/images/cargo/navigation/twIcon.svg";
import RwIcon from "../../../../assets/images/cargo/navigation/rwIcon.svg";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    height: "100%",
    transform: "scale(0.8, 0.8)",
  },
  compassBg: {
    position: "absolute",
    height: "279px",
    width: "279px",
  },
  degrees: {
    position: "absolute",
    height: "236px",
    width: "236px",
  },
  twIcon: {
    position: "absolute",
    width: "320px",
    height: "320px",
    display: "flex",
    justifyContent: "center",
  },
  rwIcon: {
    position: "absolute",
    width: "200px",
    height: "200px",
    display: "flex",
    justifyContent: "center",
  },
}));

export const Compass = memo((props) => {
  const { rotation, realWindDirection, trueWindDirection } = props;
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.container}>
      <div className={classes.compassBg}>
        <img src={CompassBg} width={279} height={279} />
      </div>
      <div
        className={classes.degrees}
        style={{
          transform: `rotate(-${rotation}deg)`,
        }}
      >
        <img src={Degrees} width={236} height={236} />
      </div>
      <div
        className={classes.twIcon}
        style={{
          transform: `rotate(${trueWindDirection}deg)`,
        }}
      >
        <img src={TwIcon} width={26} height={320} />
      </div>
      <div
        className={classes.rwIcon}
        style={{
          transform: `rotate(${realWindDirection}deg)`,
        }}
      >
        <img src={RwIcon} width={26} height={200} />
      </div>
    </Grid>
  );
});

Compass.propTypes = {
  rotation: PropTypes.number,
  realWindDirection: PropTypes.number,
  trueWindDirection: PropTypes.number,
};
