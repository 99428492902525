import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchChannelAllData,
  fetchChannelLatestData,
  fetchChannelModAllData,
} from "../../api/channel";

export const fetchChannelAsync = createAsyncThunk("channel/alldata", async ({ vesselId }) => {
  const { channelData } = await fetchChannelAllData(vesselId);
  return channelData;
});

export const fetchChannelLatestAsync = createAsyncThunk(
  "channel/latestdata",
  async ({ vesselId }) => {
    const data = await fetchChannelLatestData(vesselId);
    return data;
  }
);

export const fetchChannelModAsync = createAsyncThunk("channelmod/alldata", async ({ vesselId }) => {
  const { channelData } = await fetchChannelModAllData(vesselId);
  return channelData;
});

export const fulfilledFetchChannelAsync = (state, action) => {
  state.channelData = action.payload;
  state.isChannelFetching = false;
};

export const fulfilledFetchChannelLatestAsync = (state, action) => {
  const map = new Map();
  action.payload.channelConfigData.forEach((item) =>
    map.set(JSON.stringify({ chId: item.chId, fcuNo: item.fcuNo }), {
      chNo: item.chNo,
      unit: item.unit,
      status: item.status || "*",
    })
  );
  action.payload.channelLatestData.forEach((item) =>
    map.set(JSON.stringify({ chId: item.chId, fcuNo: item.fcuNo }), {
      ...map.get(JSON.stringify({ chId: item.chId, fcuNo: item.fcuNo })),
      name: item.chName,
      value: item.inputData || "*",
    })
  );

  state.channelLatestData = Array.from(map.values());
  state.isChannelLatestFetching = false;
};

export const fulfilledFetchChannelModAsync = (state, action) => {
  action.payload.map((item) => {
    item.chNo = item.chNo.toString().padStart(4, "0");
    return item;
  });
  state.channelModData = action.payload;
  state.isChannelModFetching = false;
};

export const rejectedFetchChannelAsync = (state, action) => {
  state.isChannelFetching = false;
};

export const rejectedFetchChannelLatestAsync = (state, action) => {
  state.isChannelLatestFetching = false;
};

export const rejectedFetchChannelModAsync = (state, action) => {
  state.isChannelModFetching = false;
};

export const pendingFetchChannelAsync = (state, action) => {
  state.isChannelFetching = true;
};

export const pendingFetchChannelLatestAsync = (state, action) => {
  state.isChannelLatestFetching = true;
};

export const pendingFetchChannelModAsync = (state, action) => {
  state.isChannelModFetching = true;
};
