import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { checkSelectedChart, clickPanel } from "../engine/engineSelector.js";
import { DEFAULT_CUSTOM_CHART_KEY } from "../../../constants/trendGraph/charts.js";

const buttonDesign = {
  alignItems: "left",
  justifyContent: "flex-start",
  display: "flex",
  width: "fitContent",
  "&:focus": {
    outline: "none",
  },
};

const buttonTextDesign = {
  color: "#ffffff",
  fontFamily: "Inter",
  fontSize: "11px",
  fontWeight: "300",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textTransform: "none",
  opacity: "100%",
  justifyContent: "flex-start",
};

const useStyles = makeStyles((theme) => ({
  dataList: {
    "& p": {
      MozUserSelect: "none",
      WebkitUserSelect: "none",
      msUserSelect: "none",
    },
  },
  trueButton: {
    ...buttonDesign,
    "& span": {
      ...buttonTextDesign,
      color: "#19b2ab",
      fontWeight: "600",
    },
    borderRadius: 0,
  },
  falseButton: {
    ...buttonDesign,
    "& span": {
      ...buttonTextDesign,
      color: "#ffffff",
      "&:hover": {
        opacity: "70%",
      },
    },
  },
  addButton: {
    width: "17px",
    height: "17px",
    border: "1px solid white",
    borderRadius: "5px",
    "-webkit-transform": "rotate(45deg)",
    "-moz-transform": "rotate(45deg)",
    "-o-transform": "rotate(45deg)",
    "-ms-transform": "rotate(45deg)",
    transform: "rotate(45deg)",
  },
}));

const CustomGraphSelector = React.memo((props) => {
  const {
    chartList,
    selectedChart,
    setSelectedChart,
    isCreateCustomCharts,
    setIsCreateCustomCharts,
    customChartTitle,
  } = props;
  const classes = useStyles();

  const displayChartList = () => {
    return (
      <>
        {chartList ? (
          <>
            {chartList.map((chart) => (
              <Button
                onClick={() => clickPanel(chart.id, null, setSelectedChart)}
                component="button"
                style={{ marginRight: "12px", minWidth: "0px" }}
                className={checkSelectedChart(chart.id, selectedChart, classes)}
                key={`displayChartList-${chart.id}`}
              >
                <span>{chart.name}</span>
              </Button>
            ))}
            {isCreateCustomCharts && selectedChart === DEFAULT_CUSTOM_CHART_KEY && (
              <Button
                onClick={() => clickPanel(DEFAULT_CUSTOM_CHART_KEY, null, setSelectedChart)}
                component="button"
                style={{ marginRight: "12px", minWidth: "0px" }}
                className={checkSelectedChart(DEFAULT_CUSTOM_CHART_KEY, selectedChart, classes)}
                key={`displayChartList`}
              >
                <span>{customChartTitle}</span>
              </Button>
            )}
          </>
        ) : (
          <Button
            component="button"
            style={{ marginRight: "12px", minWidth: "0px" }}
            className={classes.trueButton}
            key={`displayChartList`}
          >
            <span>{customChartTitle}</span>
          </Button>
        )}
        {(chartList?.length ?? 0) < 8 && (
          <Button
            component="button"
            style={{ marginRight: "12px", minWidth: "0px" }}
            onClick={() => {
              setIsCreateCustomCharts(true);
              clickPanel(DEFAULT_CUSTOM_CHART_KEY, null, setSelectedChart);
            }}
            className={classes.falseButton}
            key={`displayChartList-create`}
            disabled={isCreateCustomCharts && selectedChart === DEFAULT_CUSTOM_CHART_KEY}
          >
            <span className={classes.addButton}>×</span>
          </Button>
        )}
      </>
    );
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      direction="row"
      alignItems="center"
    >
      {displayChartList()}
    </Grid>
  );
});

CustomGraphSelector.propTypes = {
  t: PropTypes.func.isRequired,
  selectedChart: PropTypes.string,
  setSelectedChart: PropTypes.func,
  vesselId: PropTypes.string,
};

export default withTranslation()(CustomGraphSelector);
