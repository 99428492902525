//Map Icons
import AlarmEngineMap from "../../assets/images/alarmIcon/nav-alarm-engine.svg";
import AlarmElectricityMap from "../../assets/images/alarmIcon/nav-alarm-electricity.svg";
import AlarmFireMap from "../../assets/images/alarmIcon/nav-alarm-fire.svg";
import AlarmLowInsulationMap from "../../assets/images/alarmIcon/nav-alarm-low-insulation.svg";

//Display Icons
import AlarmEngine from "../../assets/images/alarmIcon/alarm-icon-engine.svg";
import AlarmGenerator from "../../assets/images/alarmIcon/alarm-icon-generator.svg";
import AlarmFire from "../../assets/images/alarmIcon/alarm-icon-fire.svg";
import AlarmInsulation from "../../assets/images/alarmIcon/alarm-icon-insulation.svg";

//Localization imports
import localizationKeys from "../i18n/localizationKeys.js";

//AlarmStatus constants
export const STATUS_ABNORMAL = "ABNOR";
export const STATUS_FAIL = "FAIL";
export const STATUS_REPOSE = "REPOSE";

//Category Constants
export const CATEGORY_ENGINE = "MainEngine";
export const CATEGORY_ELECTRIC = "PowerElectricSystem";
export const CATEGORY_FIRE = "FireFightingSystem";
export const CATEGORY_INSULATOR = "LowInsulation";

//Category Mapping to display UI wording
export const CATEGORY_WORDING_MAPPING = {
  [CATEGORY_ENGINE]: localizationKeys.MainEngine_upper,
  [CATEGORY_ELECTRIC]: localizationKeys.Generator_upper,
  [CATEGORY_FIRE]: localizationKeys.Fire_upper,
  [CATEGORY_INSULATOR]: localizationKeys.LowInsulation_upper,
};

//Category mapping to map icon
export const CATEGORY_MAP_ICON_MAPPING = {
  [CATEGORY_ENGINE]: AlarmEngineMap,
  [CATEGORY_ELECTRIC]: AlarmElectricityMap,
  [CATEGORY_FIRE]: AlarmFireMap,
  [CATEGORY_INSULATOR]: AlarmLowInsulationMap,
};

//Category mapping to display icon
export const CATEGORY_DISPLAY_ICON_MAPPING = {
  [CATEGORY_ENGINE]: AlarmEngine,
  [CATEGORY_ELECTRIC]: AlarmGenerator,
  [CATEGORY_FIRE]: AlarmFire,
  [CATEGORY_INSULATOR]: AlarmInsulation,
};

//Priority list of alarm results. Add as necessary
//First item has highest priority
//Last item has lowest priority
export const CATEGORY_PRIORITY_LIST = [
  CATEGORY_FIRE, //Highest prio
  CATEGORY_ENGINE,
  CATEGORY_ELECTRIC,
  CATEGORY_INSULATOR, //Lowest prio
];

//Alarm status priority list. Add as necessary
//First item has highest priority
//Last item has lowest priorith
export const STATUS_PRIORITY_LIST = [STATUS_ABNORMAL, STATUS_REPOSE, STATUS_FAIL];
