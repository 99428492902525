import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { svgContainer } from "../../../constants/trendGraph/buttonStyles";
const useStyles = makeStyles((theme) => ({
  svgContainer: svgContainer, // Use the imported styles
}));
const MaximizeIcon = ({ width = 25, height = 25, disabled = false }) => {
  const color = disabled ? "#FFFFFF" : "#D8D8D8";
  const opacity = disabled ? "0.5" : "1";
  const classes = useStyles();
  return (
    <div className={classes.svgContainer}>
      <svg
        width={width}
        height={height}
        viewBox="0 -3 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.75 8.15625C19.75 8.70854 20.1977 9.15625 20.75 9.15625C21.3023 9.15625 21.75 8.70854 21.75 8.15625V4.25C21.75 3.69772 21.3023 3.25 20.75 3.25H16.8438C16.2915 3.25 15.8438 3.69772 15.8438 4.25C15.8438 4.80228 16.2915 5.25 16.8438 5.25L18.3358 5.25L14.1366 9.44914C13.7461 9.83967 13.7461 10.4728 14.1366 10.8634C14.5272 11.2539 15.1603 11.2539 15.5509 10.8634L19.75 6.66421V8.15625ZM9.15625 20.75C9.15625 21.3023 8.70853 21.75 8.15625 21.75H4.25C3.69772 21.75 3.25 21.3023 3.25 20.75V16.8437C3.25 16.2915 3.69772 15.8437 4.25 15.8438C4.80229 15.8438 5.25 16.2915 5.25 16.8438L5.25 18.3358L9.44914 14.1366C9.83967 13.7461 10.4728 13.7461 10.8634 14.1366C11.2539 14.5272 11.2539 15.1603 10.8634 15.5509L6.66421 19.75H8.15625C8.70853 19.75 9.15625 20.1977 9.15625 20.75ZM21.75 20.75C21.75 21.3023 21.3023 21.75 20.75 21.75H16.8438C16.2915 21.75 15.8438 21.3023 15.8438 20.75C15.8438 20.1977 16.2915 19.75 16.8438 19.75H18.3358L14.1366 15.5509C13.7461 15.1603 13.7461 14.5272 14.1366 14.1366C14.5272 13.7461 15.1603 13.7461 15.5509 14.1366L19.75 18.3358V16.8438C19.75 16.2915 20.1977 15.8438 20.75 15.8438C21.3023 15.8438 21.75 16.2915 21.75 16.8438V20.75ZM4.25 9.15625C3.69772 9.15625 3.25 8.70853 3.25 8.15625V4.25C3.25 3.69772 3.69772 3.25 4.25 3.25H8.15625C8.70854 3.25 9.15625 3.69772 9.15625 4.25C9.15625 4.80229 8.70854 5.25 8.15625 5.25L6.66421 5.25L10.8634 9.44914C11.2539 9.83967 11.2539 10.4728 10.8634 10.8634C10.4728 11.2539 9.83967 11.2539 9.44914 10.8634L5.25 6.66421L5.25 8.15625C5.25 8.70853 4.80228 9.15625 4.25 9.15625Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    </div>
  );
};

MaximizeIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default MaximizeIcon;
