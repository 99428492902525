import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import { CargoStatusGauge } from "../../../cargo/statusGauge";

const CargoGauge = ({ taggedLatestData, page, classes }) => {
  return (
    <Grid container className={`${classes.components}`} style={{ position: "relative", width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
      <Grid item xs={12}>
        <CargoStatusGauge page={page} taggedLatestData={taggedLatestData} isVesselOverview={true} />
      </Grid>
    </Grid>
  );
}

CargoGauge.propTypes = {
  taggedLatestData: PropTypes.array,
  page: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export default CargoGauge;