const emissionTrendLines = [
  {
    key: "CO2",
    keyName: "CO2",
    name: "CO2",
    color: "#b1f7ff",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "SOx",
    keyName: "SOx",
    name: "SOx",
    color: "#64d9e7",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "NOx",
    keyName: "NOx",
    name: "NOx",
    color: "#28b6c7",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "Fuel",
    keyName: "Fuel",
    name: "Fuel",
    color: "#096976",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
];
const emissionTrendBars = [
  {
    key: "AtPort",
    keyName: "At Port",
    name: "At Port",
    color: "#64d9e7",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "Anchorage",
    keyName: "Anchorage",
    name: "Anchorage",
    color: "#096976",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "InTransit",
    keyName: "In Transit",
    name: "In Transit",
    color: "#096976",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "AtRig",
    keyName: "At Rig",
    name: "At Rig",
    color: "#096976",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "DP",
    keyName: "DP",
    name: "DP",
    color: "#64d9e7",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "RigTowing",
    keyName: "RigTowing",
    name: "Rig Towing",
    color: "#096976",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
];

export { emissionTrendLines, emissionTrendBars };
