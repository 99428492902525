import { ENGINE_CHART_TYPE, ENGINE_TYPE } from "../../../constants/trendGraph/enginesLine";

export const clickPanel = (type, engineType, setSelectedChart) => {
  switch (true) {
    case engineType === ENGINE_TYPE.STRAIGHT && type === ENGINE_CHART_TYPE.ENGINE_EXHAUST:
      setSelectedChart(ENGINE_CHART_TYPE.ENGINE_EXHAUST);
      break;
    case engineType === ENGINE_TYPE.STRAIGHT && type === ENGINE_CHART_TYPE.ENGINE_DEVIATION:
      setSelectedChart(ENGINE_CHART_TYPE.ENGINE_DEVIATION);
      break;
    default:
      setSelectedChart(type);
  }
};

export const checkSelectedChart = (type, selectedChart, classes) => {
  switch (true) {
    case selectedChart === type:
      return classes.trueButton;
    default:
      return classes.falseButton;
  }
};
