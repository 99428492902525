import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { ENGINE_CHART_LIST } from "./engineList";
import { ENGINE_CHART_TYPE, ENGINE_TYPE } from "../../../constants/trendGraph/enginesLine";
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Paper } from "@mui/material";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getDataDuration, getSepDataDuration } from "../../../model/slice/dataDurationSlice";
import { NAVIGATION_BAR_HEIGHT } from "../../../layout/panelLayout.jsx";
import Chart from "../../chart/chart.jsx";
import { useVesselSettings } from "../../../helpers/vesselSetting/useVesselSettings";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType";

const useStyles = makeStyles((theme) => ({
  paperHeight: {
    backgroundColor: "inherit",
    boxShadow: "none",
  },
  drawerButton: {
    position: "absolute",
    right: "1px",
    minWidth: "24px",
    width: "24px",
    color: "white",
  },
  baseGridContainer: {
    marginTop: "8px",
    width: "100% !important",
  },
  maximize: {
    position: "absolute",
    padding: "0px",
    [theme.breakpoints.down('lg')]: {},
  },
  titlePaperPosition: {
    position: "relative",
  },
  chart: {

  },
  controlContainer: {
    position: "absolute",
    right: "55px",
    [theme.breakpoints.down('lg')]: {
      right: "80px",
    },
  },
  titleNameHolder: {
    "user-select": "none",
    "-moz-user-select": "none",
    "-webkit-user-select": "none",
  },
}));

const EngineTrendBase = ({
  selectedEngine,
  values,
  layoutStyle,
  isNoData,
  vesselId,
  vessel,
  graphType,
  isLoading,
  selectedChart,
  graphData,
  setGraphData,
  customChartLines,
  customLineData,
  toggleVariables,
  isVesselOverview,
  setChartRef,
  overviewTrendIdx,
  isCustomCharts,
}) => {
  const styles = useStyles();
  const classes = { ...styles, ...layoutStyle };
  const dataDurationState = useSelector(
    graphType === GRAPH_TYPE.ENGINE ? getDataDuration : getSepDataDuration
  );
  const [isGraphDataLoading, setIsGraphDataLoading] = useState(false);
  const [isFilteredNoData, setIsFilteredNoData] = useState(false);

  const { vesselSettings } = useVesselSettings({ vesselId });
  const screenshotRef = useRef(null);

  const generateDisplay = async () => {
    switch (graphType) {
      case GRAPH_TYPE.ENGINE: {
        if (
          values.mainEngineData === undefined ||
          values.engineData === undefined ||
          !values.mainEngineData?.length ||
          !values.engineData?.length
        ) {
          if (isLoading === false && isNoData === true) {
            setGraphData([]);
          }
          return; //If either one of API values is missing, do nothing
        }

        const chartDetails = ENGINE_CHART_LIST.find(
          (e) => e.id === selectedChart && e.position === selectedEngine
        );
        let dataList = chartDetails?.dataList;
        if (vesselSettings.MEEngineType === ENGINE_TYPE.STRAIGHT) {
          dataList = dataList.filter((c) => !c.keyName.startsWith("meExhGasB"));
        }
        if (
          selectedChart === ENGINE_CHART_TYPE.ENGINE_DEVIATION ||
          selectedChart === ENGINE_CHART_TYPE.ENGINE_EXHAUST
        ) {
          dataList = dataList.filter(
            (b) =>
              !b.keyName.startsWith("meExhGas") ||
              b.keyName.match(/\d+/)?.[0] <= vesselSettings.cylinder
          );
        } else if (selectedChart === ENGINE_CHART_TYPE.ENGINE_TEMP) {
          dataList = dataList.filter(
            (b) =>
              b.keyName.match(/\d+/)?.[0] === undefined ||
              b.keyName.match(/\d+/)?.[0] <= vesselSettings.cylinder
          );
        }

        const displayData = await chartDetails?.reducer(
          values.mainEngineData,
          values.engineData,
          values.gpvtgData,
          dataDurationState.startDate,
          dataDurationState.endDate,
          dataList.map((d) => d.keyName)
        );
        setIsFilteredNoData(displayData?.length === 0)
        setGraphData(displayData);
        break;
      }
      case GRAPH_TYPE.SEP:
        setGraphData(values?.[selectedChart]);
        break;
      case GRAPH_TYPE.CUSTOM:
        setGraphData(values?.data);
        break;
    }
  };

  useEffect(() => {
    setIsGraphDataLoading(false);
  }, [graphData]);

  useEffect(() => {
    if (isNoData || isLoading || !vesselSettings || Object.keys(vesselSettings).length === 0) return;
    setIsGraphDataLoading(true);
    generateDisplay();
  }, [values, selectedChart, isLoading, isNoData, vesselSettings]);

  useEffect(() => {
    //If data duration is changed, reset display data to new duration range
    setGraphData([]);
  }, [dataDurationState, vesselId]);

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.baseGridContainer}>
        <Grid item xs={12} id="trendGraph" className={classes.chart} ref={screenshotRef}>
          <Paper className={classes.paperHeight}
            style={{
              height: `calc(100dvh - ${NAVIGATION_BAR_HEIGHT}px - 230px - ${isCustomCharts ? 50 : 0}px)`,
            }}>
            <Chart
              enginePosition={selectedEngine}
              chartType={selectedChart}
              graphData={graphData}
              customChartLines={customChartLines}
              isNoData={isNoData || isFilteredNoData}
              vesselId={vesselId}
              vessel={vessel}
              type={graphType}
              isInitialLoad={isLoading}
              isGraphDataLoading={isGraphDataLoading}
              customLineData={customLineData}
              toggleVariables={toggleVariables}
              isVesselOverview={isVesselOverview}
              setChartRef={setChartRef}
              overviewTrendIdx={overviewTrendIdx}
            />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

EngineTrendBase.propTypes = {
  t: PropTypes.func.isRequired,
  selectedEngine: PropTypes.string,
  layoutStyle: PropTypes.object,
  values: PropTypes.object,
  isDurationDisabled: PropTypes.bool.isRequired,
  isNoData: PropTypes.bool,
  isDisconnected: PropTypes.bool,
  vesselId: PropTypes.string,
  vessel: PropTypes.object,
  graphType: PropTypes.string,
  isLoading: PropTypes.bool,
  selectedChart: PropTypes.string,
  graphData: PropTypes.array,
  setGraphData: PropTypes.func,
  customChartLines: PropTypes.array,
  customLineData: PropTypes.object,
  isVesselOverview: PropTypes.bool,
  setChartRef: PropTypes.func,
  overviewTrendIdx: PropTypes.number,
  isCustomCharts: PropTypes.bool,
};

export default withTranslation()(EngineTrendBase);
