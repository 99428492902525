import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { channelModData, search } from "../../../model/slice/channelSlice.js";

export const useInputText = (field) => {
  const textRef = useRef("");
  const setCurrentText = (text) => {
    textRef.current = text;
    setSearchText();
  };
  const dispatch = useDispatch();
  const filter = useSelector(channelModData);

  const resetSearchText = () => (textRef.current = "");

  const filterWord = () => {
    return filter.word;
  };

  useEffect(() => {
    if (filterWord() === "") return;
    textRef.current = filterWord();
  }, []);

  const setSearchText = () => {
    dispatch(search({ text: textRef.current, filter: field }));
  };

  return {
    currentText: textRef.current,
    setCurrentText,
    setSearchText,
    resetSearchText,
  };
};
