import React from "react";
import PropTypes from "prop-types";
import { COLORS } from "../../constants/colors";

export const ResetIcon = ({ width = 15, height = 15, color = COLORS.white }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.663 3.486A3.854 3.854 0 0 0 4.11 5.815c-.109.254-.38.412-.647.339l-.082-.023c-.266-.073-.425-.35-.325-.607A4.938 4.938 0 0 1 7.663 2.4c2.72 0 4.937 2.181 4.937 4.886 0 2.704-2.217 4.885-4.937 4.885a4.943 4.943 0 0 1-4.43-2.725l-.267.763a.5.5 0 0 1-.684.288l-.047-.023a.5.5 0 0 1-.25-.645l.882-2.114a.5.5 0 0 1 .657-.268l1.95.833a.5.5 0 0 1 .257.671l-.045.097a.5.5 0 0 1-.629.256L4.22 8.99a3.857 3.857 0 0 0 3.444 2.096c2.134 0 3.852-1.708 3.852-3.8 0-2.093-1.718-3.8-3.852-3.8z"
      fill={color}
    />
  </svg>
);

ResetIcon.prototype = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
