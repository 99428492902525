import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { SHIP_GROUP_TYPE, SHIP_TYPE } from "../../../constants/vesselShipType";
import { logger } from "../../../api/logger";
import { isVesselOffline } from "../../../constants/util";
import VesselIcon from "../../vesselImages/vesselIcon";
import { fleetAlarmSelector } from "../../../model/slice/alarmSlice";
import { VESSEL_TYPE } from "../../../constants/constants";
import { getAlarmHighestPriority, getMarkerColor } from "../../../helpers/alarmTypeStyle";
import { STATUS_ABNORMAL } from "../../../constants/alarmType";
import { setSelectedVessel } from "../../../model/slice/fleetNavigationDataSlice";

const VESSEL_NAME = {
  color: "#19b2ab",
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "bold",
  fontStretch: "normal",
  lineHeight: "normal",
};

const useStyles = makeStyles((theme) => ({
  nameSpace: {
    marginLeft: "20px",
    overflowWrap: "normal",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
    fontSize: "14px",
    fontWeight: 300,
  },
  offlineVesselName: {
    ...VESSEL_NAME,
    color: "#525252",
  },
  onlineVesselName: {
    ...VESSEL_NAME,
  },
  baseBox: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "2em",
  },
}));

const VesselName = (props) => {
  const { vesselId, vesselList, vesselEngineList } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const vesselData = vesselList?.fleetNavigationData[0];
  const vesselEngineData = vesselEngineList?.[0] ?? [];
  logger.info(`VesselName vesselId: ${vesselId}, vesselList: ${vesselList}`);
  const isOffline = isVesselOffline(vesselData, vesselEngineData);
  const vesselIcon = VesselIcon(
    vesselData?.vesselType === VESSEL_TYPE.SEP
      ? 52
      : vesselData?.vesselLatestAisData[0]?.shipType ?? 0,
    0,
    1,
    isOffline,
    false
  );
  const fleetAlarmData = useSelector(fleetAlarmSelector);
  const alarmData = fleetAlarmData.filter((f) => f.vesselId === vesselData.vessel_id);

  useEffect(() => {
    dispatch(setSelectedVessel(vesselId));
  }, [vesselId]);

  const VesselImage = ({ isOffline, vesselIcon, vesselName, alarmData }) => {
    const alarmHighestPriority = getAlarmHighestPriority(alarmData);
    return (
      <div
        style={{
          minWidth: "30px",
          position: "relative",
        }}
      >
        <img
          src={vesselIcon}
          alt={`vessel icon ${vesselName}`}
          style={{ zIndex: 999, position: "relative" }}
        />
        {!isOffline && alarmHighestPriority?.alarmStatus === STATUS_ABNORMAL && (
          <span
            className="marker fl-marker"
            style={{
              filter: alarmHighestPriority != null ? getMarkerColor(alarmHighestPriority) : "",
              width: "24px",
              height: "24px",
              top: "-14px",
              left: "-12px",
              position: "absolute",
              borderRadius: "50%",
              zIndex: 600,
            }}
          />
        )}
      </div>
    );
  };
  return (
    <div className={classes.baseBox}>
      <VesselImage
        isOffline={isOffline}
        vesselName={vesselData?.vessel_name}
        vesselIcon={vesselIcon}
        alarmData={alarmData}
      />
      <span className={isOffline ? classes.offlineVesselName : classes.onlineVesselName}>
        {vesselData?.vessel_name}
      </span>
      {vesselData?.vesselLatestAisData[0]?.shipType
        ? ` - ${SHIP_GROUP_TYPE[SHIP_TYPE[vesselData?.vesselLatestAisData[0]?.shipType]]}`
        : null}
      {vesselData?.vessel_firstClass ? `[${vesselData?.vessel_firstClass}]` : null}
    </div>
  );
};

VesselName.propTypes = {
  vesselList: PropTypes.object,
};

export default VesselName;
