import { axios } from "./axiosSettings";
import { getAuthToken } from "../helpers/session";

export const fetchCraneLatestData = async (vessel_id, craneNumber = null) => {
  try {
    const { data } = await axios.get("latestData/craneData", {
      params: {
        vessel_id,
        craneNumber,
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return { latestCraneData: data };
  } catch (e) {
    return { fetchLatestCraneData: e };
  }
};

export const fetchTrimAndHeelLatestData = async (vessel_id) => {
  try {
    const { data } = await axios.get("latestData/vesselLatestNavigationData", {
      params: {
        vessel_id,
        navigationType: 'TRIM'
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return { latestTrimAndHeelData: data };
  } catch (e) {
    return { fetchLatestTrimAndHeelData: e };
  }
};

export const fetchLegLatestData = async (vessel_id) => {
  try {
    const { data } = await axios.get("latestData/legData", {
      params: {
        vessel_id,
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return { latestLegData: data };
  } catch (e) {
    return { fetchLatestLegData: e };
  }
};

export const fetchThrusterLatestData = async (vessel_id) => {
  try {
    const { data } = await axios.get("latestData/thrusterData", {
      params: {
        vessel_id,
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return { latestThrusterData: data };
  } catch (e) {
    return { fetchThrusterData: e };
  }
};
