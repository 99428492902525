import React from "react";
import PropTypes from "prop-types";

//Component imprts
import Pointer from "./pointer.jsx";
import WindIndicator from "./windIndicator.jsx";

import withStyles from '@mui/styles/withStyles';

//Image imports
import CompassRing from "../../../assets/images/compass/readings-compass-ring-inner.svg";

const speedLabelBase = {
  fontSize: "17px",
  fontWeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
};

const styles = () => ({
  compassHolder: {
    position: "absolute",
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  compassRing: {
    position: "absolute",
    left: 0,
    right: 0,
  },
  pointer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  speed: {
    position: "absolute",
    textAlign: "center",
    bottom: "30%",
    left: "0",
    right: "0",
  },
  speedValue: {
    fontSize: "17px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
  },
  speedLabel: {
    ...speedLabelBase,
    color: "#19b2ab",
  },
  disconnectedSpeedLabel: {
    ...speedLabelBase,
    color: "#909090",
  },
});

const Compass = (props) => {
  const defaultSpeed = 0;
  const defaultRotation = 360; //defaults to 360 degrees
  const defaultWindDirection = 360; //defaults to 360 degress
  const { isDisconnected } = props;

  const [rotation, setRotation] = React.useState(defaultRotation); //Default rotation
  const [speedKnot, setSpeedKnot] = React.useState(defaultSpeed); //Default speed
  const [windDirection, setWindDirection] = React.useState(defaultWindDirection); //Default wind direction

  React.useEffect(() => {
    setRotation(props.rotation);
    setSpeedKnot(props.speedKnot);
    setWindDirection(props.windDirection);
  }, [props.rotation, props.speedKnot, props.windDirection]);

  // React.useEffect(() => {
  // }, [windowSize.width]);
  return (
    <React.Fragment>
      <div
        className={props.classes.compassHolder}
        style={{
          height: `100%`,
          width: `100%`,
        }}
      >
        <div className={props.classes.compassRing}>
          <img
            src={CompassRing}
            style={{
              height: `100%`,
              width: `100%`,
              transform: `rotate(${360 - rotation}deg)`,
            }}
          />
        </div>
        <div className={props.classes.pointer}>
          <Pointer className={props.classes.pointer} isDisconnected={isDisconnected} />
        </div>
        {windDirection !== undefined && (
          <div className={props.classes.pointer}>
            <WindIndicator rotation={windDirection} />
          </div>
        )}
        <div className={props.classes.speed}>
          <div className={props.classes.speedValue}>
            {speedKnot} <br />
          </div>
          <div
            className={
              isDisconnected ? props.classes.disconnectedSpeedLabel : props.classes.speedLabel
            }
          >
            KN
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Compass.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  rotation: PropTypes.number,
  speedKnot: PropTypes.number,
  windDirection: PropTypes.any,
  isDisconnected: PropTypes.bool,
};

export default withStyles(styles)(Compass);
