import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Menu, MenuItem } from "@mui/material";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { VESSEL_TYPE } from "../../constants/constants";
import { SEP_CHART_LIST } from "../../constants/trendGraph/sepLines";
import { ENGINE_CHART_LIST } from "../trendPage/engine/engineList";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType";
import { useSelector } from "react-redux";
import { selectedChartType } from "../../model/slice/chartTypeSlice";
import localizationKeys from "../../i18n/localizationKeys";
import { chartsData } from "../../model/slice/chartsSlice";
import { DEFAULT_CUSTOM_CHART_KEY } from "../../constants/trendGraph/charts";

const useStyles = ({ isMainPage }) =>
  makeStyles(() => ({
    menu: {
      "& .MuiMenu-paper": {
        backgroundColor: isMainPage ? "rgba(2, 2, 2, 0.7) !important" : "#46576d",
        marginTop: "2px",
        marginLeft: "13px",
        color: "#fff",
        padding: "8px 0",
      },
      "& .MuiListItem-root.Mui-selected, & .MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "#252d39 !important",
      },
    },
    menuItem: {
      padding: "8px 24px",
      fontSize: "14px",
      width: "240px",
      "&:hover": {
        backgroundColor: "#252d39",
      },
    },
    item: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    divider: {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      margin: "12px 24px",
    },
  }));

const IconMenu = (props) => {
  const { vessel, anchorEl, setAnchorEl, location, i18n, t } = props;
  const styles = useStyles({
    isMainPage: location.pathname.includes("/main"),
  })();
  const open = useMemo(() => !!anchorEl, [anchorEl]);
  const [chartList, setChartList] = useState([]);
  const navigate = useNavigate();
  const selectedChart = useSelector(selectedChartType);
  const customCharts = useSelector(chartsData);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (graphId, chartId) => {
    if (graphId) {
      navigate(`/trend/${graphId}/${chartId}/${vessel.id}`);
    }
    handleClose();
  };

  const showAdd = useCallback(
    () => !customCharts?.charts || customCharts.charts.length < 8,
    [customCharts]
  );

  useEffect(() => {
    setChartList([]);
  }, []);

  useEffect(async () => {
    if (!vessel) return;
    let charts = [];
    if (vessel.vesselType === VESSEL_TYPE.SEP) {
      charts = SEP_CHART_LIST.map((chart) => ({
        id: chart.id,
        graphId: GRAPH_TYPE.SEP,
        name: t(chart.title),
      }));
    } else {
      if (vessel.NoME > 0) {
        charts = ENGINE_CHART_LIST.map((chart) => ({
          id: chart.id,
          graphId: GRAPH_TYPE.ENGINE,
          name: t(chart.title),
        }));
      }
    }

    if (vessel.NoDG > 0 || vessel.NoSG > 0) {
      charts.push({
        id: GRAPH_TYPE.ELECTRIC,
        graphId: GRAPH_TYPE.ELECTRIC,
        name: t(localizationKeys.ElectricData_lower),
      });
    }

    if (customCharts?.charts) {
      charts.push(
        ...customCharts.charts.map((chart) => ({
          id: chart.id,
          graphId: GRAPH_TYPE.CUSTOM,
          name: chart.name,
        }))
      );
    }

    setChartList(Array.from(new Map(charts.map((chart) => [chart.id, chart])).values()));
  }, [vessel, customCharts, i18n.language]);

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onMouseLeave={handleClose}
      className={styles.menu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        },
      }}
    >
      <div onMouseLeave={handleClose}>
        {" "}
        {/* Wraped the menu items in a div */}
        {chartList?.map((chart) => (
          <MenuItem
            className={styles.menuItem}
            onClick={() => handleClick(chart.graphId, chart.id)}
            key={chart.id}
            selected={chart.id === selectedChart}
          >
            <div className={styles.item}>{chart.name}</div>
          </MenuItem>
        ))}
        {showAdd() && chartList?.length > 0 && <Divider className={styles.divider} />}
        {showAdd() && (
          <MenuItem
            className={styles.menuItem}
            onClick={() => handleClick(GRAPH_TYPE.CUSTOM, DEFAULT_CUSTOM_CHART_KEY)}
            key={DEFAULT_CUSTOM_CHART_KEY}
            selected={false}
          >
            + {t(localizationKeys.CustomMenuAdd)}
          </MenuItem>
        )}
      </div>
    </Menu>
  );
};

export default withTranslation()(IconMenu);
