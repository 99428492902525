import { withStyles } from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import Electric from "../../../monitoringPage/electric/electric";

const styles = () => ({
  container: {
    display: "flex",
    paddingLeft: "50px !important",
  },
  paper: {
    marginTop: "16px",
  },
  toggleContainer: {
    top: "-24px",
    right: "0px",
    position: "absolute",
  },
  "[class*='chartTotalLabel']": {
    minWidth: "80px",
    minHeight: "30px",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Inter",
    color: "rgba(255, 255, 255, 0.7)",
  }
});

const ElectricMonitoring = ({ vessel, style, classes }) => {
  return (
    <Grid container className={ `${style.components} ${classes.container}` }>
      <Electric
        vesselId={vessel.id}
        vesselSettings={vessel}
        isInitialLoading={false}
        classes={{...style, ...classes}}
      />
    </Grid>
  );
}

ElectricMonitoring.propTypes = {
  vessel: PropTypes.object,
  style: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(ElectricMonitoring);