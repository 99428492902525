import { createSlice } from "@reduxjs/toolkit";
import Actions from "../action/emissionAction";
import {
  fetchEmissionDataAsync,
  fetchEmissionDataAsyncFulfilled,
  fetchEmissionDataForDataAreaAsync,
  fetchEmissionDataForDataAreaAsyncFulfilled,
} from "../async/emissionDataAsync";

export const LIST = [
  {
    name: "FLEET",
    dataTestId: "fleet_button",
  },
  {
    name: "VESSEL",
    dataTestId: "vessel_button",
  },
];

const emissionSlice = createSlice({
  name: "emissionController",
  initialState: {
    emissionDataType: LIST[0],
    emissionData: {},
    emissionDataQuerying: true,
    emissionTotalData: [],
    emissionTotalDataQuerying: true,
    emissionToken: "",
  },
  reducers: {
    ...Actions,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmissionDataAsync.pending, (state, action) => {
      state.emissionDataQuerying = true;
    });
    builder.addCase(fetchEmissionDataForDataAreaAsync.pending, (state, action) => {
      state.emissionTotalDataQuerying = true;
    });
    builder.addCase(fetchEmissionDataAsync.fulfilled, fetchEmissionDataAsyncFulfilled);
    builder.addCase(
      fetchEmissionDataForDataAreaAsync.fulfilled,
      fetchEmissionDataForDataAreaAsyncFulfilled
    );
  },
});

export const selectedEmissionDataType = (state) => state.emissionController.emissionDataType;
export const emissionData = (state) => state.emissionController.emissionData;
export const emissionDataQuerying = (state) => state.emissionController.emissionDataQuerying;
export const emissionTotalData = (state) => state.emissionController.emissionTotalData;
export const emissionTotalDataQuerying = (state) =>
  state.emissionController.emissionTotalDataQuerying;
export const { setSelectedEmissionDataType, setEmissionDataQuerying, setEmissionToken } = emissionSlice.actions;

export default emissionSlice.reducer;
