import { createSlice } from "@reduxjs/toolkit";
import Actions from "../action/sideBarSettingAction";

const sideBarSettingSlice = createSlice({
  name: "sideBarSetting",
  initialState: {
    displaySideBar: false,
  },
  reducers: {
    ...Actions,
  },
});

export const selectSideBarState = (state) => state.sideBarSetting.displaySideBar;

export const { setSideBarStatus } = sideBarSettingSlice.actions;

export default sideBarSettingSlice.reducer;
