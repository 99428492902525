import { createSlice } from "@reduxjs/toolkit";
import { engineTrendGraphLines } from "../../constants/trendGraph/enginesLine";
import { sepTrendGraphLines } from "../../constants/trendGraph/sepLines";
import { electricTrendLines } from "../../constants/trendGraph/electricLines";
import { emissionTrendLines, emissionTrendBars } from "../../constants/trendGraph/emissionLines";
import Actions from "../action/chartSettingAction";

const initialState = {
  engineChartList: engineTrendGraphLines,
  sepChartList: sepTrendGraphLines,
  electricChartList: electricTrendLines,
  emissionChartLineList: emissionTrendLines,
  emissionChartBarList: emissionTrendBars,
  hideLists: [],
};

const chartSettingsSlice = createSlice({
  name: "chartSettingsController",
  initialState: {
    ...initialState,
  },
  reducers: {
    ...Actions,
  },
});

export const selectHideList = (state) => state.chartSettingsController.hideLists;
export const engineChartList = (state) => state.chartSettingsController.engineChartList;
export const sepChartList = (state) => state.chartSettingsController.sepChartList;
export const electricChartList = (state) => state.chartSettingsController.electricChartList;
export const emissionChartLineList = (state) => state.chartSettingsController.emissionChartLineList;
export const emissionChartBarList = (state) => state.chartSettingsController.emissionChartBarList;
export const { setHideLine, removeHideLine, removeAllHideLine, setChartList, changeColor } =
  chartSettingsSlice.actions;
export default chartSettingsSlice.reducer;
