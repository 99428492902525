const setSelectedEmissionDataType = (state, action) => {
  state.emissionDataType = action.payload;
};
const setEmissionDataQuerying = (state, action) => {
  state.emissionDataQuerying = action.payload;
};
const setEmissionToken = (state, action) => {
  state.emissionToken = action.payload;
};

export default {
  setSelectedEmissionDataType,
  setEmissionDataQuerying,
  setEmissionToken,
};
