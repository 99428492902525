import dayjs from 'util/dayjs-init.js';

export const LATEST_UPDATE_FORMAT = "YYYY/MM/DD HH:mm";
export const MONTH_DATE_FORMAT = "YYYY/MM/DD";
export const DATE_TIME_FORMAT = "MM/DD HH:mm";

const BASE_TIME = 60 * 10;

export const isVesselOffline = (navigationData, engineData) => {
  return (
    isPassedBaseTime(navigationData?.vesselLatestGpggaData[0]?.dateTime) &&
    isPassedBaseTime(navigationData?.vesselLatestAisData[0]?.dateTime) &&
    (!engineData ||
      (engineData?.vesselLatestEngineData?.every((v) => isPassedBaseTime(v["dateTime"])) &&
        engineData?.vesselLatestMainEngineData?.every((v) => isPassedBaseTime(v["dateTime"])) &&
        engineData?.vesselLatestGeneratorEngineData?.every((v) =>
          isPassedBaseTime(v["dateTime"])
        ) &&
        engineData?.vesselLatestShaftGeneratorEngineData?.every((v) =>
          isPassedBaseTime(v["dateTime"])
        )))
  );
};

export const isPassedBaseTime = (dateTime) =>
  !dateTime || dayjs.utc(dateTime).add(BASE_TIME, "seconds").isBefore(dayjs.utc());
