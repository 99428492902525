import React from "react"
import { Box } from "@mui/material"
import { colorFromRank } from "./styles"

export const CiiClassKey = () => {

    const classItem = (rank) => {
        const color = colorFromRank(rank);
        return (
            <Box sx={{display: "flex", color: "white", gap: "5px"}}>
                <Box sx={{width: "22px", height: "22px", borderRadius: "12px", backgroundColor: color}}></Box>
                <Box sx={{lineHeight: "22px"}}>Class</Box>
                <Box sx={{lineHeight: "22px", color: color}}>{rank.toUpperCase()}</Box>
            </Box>
        )
    }
    return (
        <Box sx={{width: "100%", height: "100%", display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
            {classItem('a')}
            {classItem('b')}
            {classItem('c')}
            {classItem('d')}
            {classItem('e')}
        </Box>
    );
}