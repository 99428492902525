import dayjs from 'util/dayjs-init.js';
//import * as utc from 'dayjs/plugin/utc.js';
//dayjs.extend(utc);
import { DATE_FORMAT } from "./constants";

export const sepInitialDuration = 1;

export const portInitialDuration = 7;

export const starboardInitialDuration = 7;

export const centerInitialDuration = 7;

export const electricInitialDuration = 7;

export const comparisionInitialDuration = 7;

export const customInitialDuration = 7;

export const durationInitialState = {
  startDate: dayjs.utc().subtract(7, "d").format(DATE_FORMAT),
  endDate: dayjs.utc().format(DATE_FORMAT),
};

export const sepDurationInitialState = {
  startDate: dayjs.utc().subtract(1, "d").format(DATE_FORMAT),
  endDate: dayjs.utc().format(DATE_FORMAT),
};
export const cargoInitialDuration = 7;
