
import React from "react";
import * as PropTypes from "prop-types";

const SortDownIconSetColor = ({ color = "rgba(255, 255, 255, 0.3)", width = 14, height = 14 }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g fill={color} >
        <path
          d="M6.436 2.678a.235.235 0 0 0-.172.071.235.235 0 0 0-.07.173v6.912L3.05 6.692a.296.296 0 0 0-.182-.06.22.22 0 0 0-.163.06l-.405.406a.296.296 0 0 0-.06.182c0 .068.02.122.06.162l4.257 4.237c.04.054.095.081.162.081a.193.193 0 0 0 .162-.081l4.257-4.237a.22.22 0 0 0 .061-.162.297.297 0 0 0-.06-.182l-.406-.406a.22.22 0 0 0-.162-.06.297.297 0 0 0-.183.06L7.247 9.834V2.922a.235.235 0 0 0-.07-.173.235.235 0 0 0-.173-.07h-.568z"
        />
      </g>
    </svg>
  );
};

SortDownIconSetColor.propTypes = {
  disabled: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SortDownIconSetColor;
