import React from "react";
import { BrowserRouter, Route, Routes, Switch, Navigate, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import PropTypes from "prop-types";

//Context
import { useLocalStorage } from "./context/storage/useContextStorage.js";
import UserContext from "./context/UserContext.js";
import store from "./model/store/store.js";
import Favicon from "react-favicon";

//Layout imports
import FullScreenLayout from "./layout/fullScreenLayout.jsx";
import PanelLayout from "./layout/panelLayout.jsx";
import FavIconLogo from "../assets/images/defaults/connect.ico";

//Component imports
import Authorize from "./components/authorize/index.jsx";
import { LoginPage } from "./components/loginPage/loginPage.jsx";
import { MainPage } from "./components/mainPage/mainPage.jsx";
import { NavigationPage } from "./components/navigationPage/navigationPage.jsx";
import { AlarmPageBase } from "./components/alarmPage/alarmPage.jsx";
import { MonitoringPage } from "./components/monitoringPage/monitoringPage.jsx";
import Trend from "./components/trendPage/trendPage.jsx";
import Comparison from "./components/comparison/comparison.jsx";
import { ChannelPage } from "./components/channelPage/channelPage.jsx";
import { Cargo } from "./components/cargo/index.jsx";

//Emission imports
import { EmissionPage } from "./components/emissionPage/emissionPage.jsx";

//Azure Login imports
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./constants/authConfig.js";

//Localizatoin imports
import { withTranslation } from "react-i18next";
import localizationKeys from "./i18n/localizationKeys.js";

// Google analytics
import ReactGA from "react-ga4";
import RouteChangeTracker from "./components/common/RouteChangeTracker.jsx";
import PurchaseInQuiry from "./components/contactus/index.jsx";
import ServicePolicy from "./components/servicePolicy/servicePolicy.jsx";
import { getAuthToken } from "./helpers/session.js";

//Material
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { SyntheticaCII } from "./components/syntheticaPage/index.jsx";

const theme = createTheme();

const TRACKING_ID = "G-XZL5HFL7JG"; // Connect tracking ID G-D5D27D4JPR
ReactGA.initialize(TRACKING_ID);

const USER_CONTEXT_PERSISTENT = "USER_CONTEXT";

//Microsoft azure login instance
const msalInstance = new PublicClientApplication(msalConfig);

const App = (props) => {
  const [userContext, setUserContext] = useLocalStorage(USER_CONTEXT_PERSISTENT, null);
  const ChangeLanguage = (language) => {
    props.i18n.changeLanguage(language);
  };
  const PrivateRoutes = [
    {
      NAME: "Main",
      PATH: "/main",
      LAYOUT: FullScreenLayout,
      COMPONENT: MainPage,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
    },
    {
      NAME: localizationKeys.NavigationData_upper,
      PATH: "/navigation/:vesselId",
      LAYOUT: PanelLayout,
      COMPONENT: NavigationPage,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
    },
    {
      NAME: localizationKeys.Monitoring_upper,
      PATH: "/monitoring/:vesselId",
      LAYOUT: PanelLayout,
      COMPONENT: MonitoringPage,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
    },
    {
      NAME: localizationKeys.TrendData_upper,
      PATH: "/trend/:graph/:chart/:vesselId",
      LAYOUT: PanelLayout,
      COMPONENT: Trend,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
    },
    {
      NAME: localizationKeys.LCO2Page,
      PATH: "/cargo/:page/:vesselId",
      LAYOUT: PanelLayout,
      COMPONENT: Cargo,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext: setUserContext,
      },
    },
    {
      NAME: localizationKeys.Alarm_upper,
      PATH: "/alarm/:vesselId",
      LAYOUT: PanelLayout,
      COMPONENT: AlarmPageBase,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
    },
    {
      NAME: localizationKeys.ServicePolicyTitle_upper,
      PATH: "/servicepolicy",
      LAYOUT: PanelLayout,
      COMPONENT: ServicePolicy,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
    },
    {
      NAME: localizationKeys.DataComparison_upper,
      PATH: "/comparison/:vesselId",
      LAYOUT: PanelLayout,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
      COMPONENT: Comparison,
    },
    {
      NAME: localizationKeys.DataComparison_upper,
      PATH: "/comparison/",
      LAYOUT: PanelLayout,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
      COMPONENT: Comparison,
    },
    {
      NAME: localizationKeys.Channel_upper,
      PATH: "/channel/:vesselId",
      LAYOUT: PanelLayout,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
      COMPONENT: ChannelPage,
    },
    {
      NAME: localizationKeys.Channel_upper,
      PATH: "/channel/",
      LAYOUT: PanelLayout,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
      COMPONENT: ChannelPage,
    },
    {
      NAME: localizationKeys.Emissions_upper,
      PATH: "/emission/:vesselId",
      LAYOUT: PanelLayout,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
      COMPONENT: EmissionPage,
    },
    {
      NAME: localizationKeys.Emissions_upper,
      PATH: "/emission/",
      LAYOUT: PanelLayout,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
      COMPONENT: EmissionPage,
    },
    {
      NAME: "CII",
      PATH: "/cii/:vesselId",
      LAYOUT: PanelLayout,
      DEFAULT_PARAMS: {
        changeLanguage: ChangeLanguage,
        setUserContext,
      },
      COMPONENT: SyntheticaCII,
    },
  ];

  function RequireAuth({ children, redirectTo }) {
    let isAuthenticated = !!getAuthToken();
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
  }

  const GetPageRoutes = () => {
    return PrivateRoutes.map((item) => {
      return (
        <Route key={item.NAME} path={item.PATH} element={          
          <RequireAuth redirectTo="/">
            <item.LAYOUT name={item.NAME} component={item.COMPONENT} defaultParams={item.DEFAULT_PARAMS}/>
          </RequireAuth>
        }
        />
      )
    })
  }

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <UserContext.Provider value={userContext}>
              <RouteChangeTracker />
              <Favicon url={FavIconLogo} />
              <MsalProvider instance={msalInstance}>
                <Routes>
                  <Route path="/authorize" element={<Authorize setUserContext={setUserContext} />}/>
                  <Route path="/purchaseinq" element={<PurchaseInQuiry/>}/>
                  {GetPageRoutes()}
                  <Route path="/policyconfirm" element={
                     <ServicePolicy
                     setUserContext={setUserContext}
                     changeLanguage={ChangeLanguage}
                     i18n={props.i18n}
                     t={props.t}
                   />
                  }/>
                  <Route path="/" element={<LoginPage/>}/>
                  <Route path="*" element={<Navigate to="/"/>}/>
                </Routes>
              </MsalProvider>
            </UserContext.Provider>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
};

App.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(App);
