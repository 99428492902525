import localizationKeys from "../../i18n/localizationKeys";

const SEP_CHART_TYPE = {
  LEGS: "legs",
  THRUSTER: "thruster",
  CRANE1: "crane1",
  CRANE2: "crane2",
  TRIM_AND_HEEL: "trim_and_heel",
};

const SEP_CHART_LIST = [
  {
    id: SEP_CHART_TYPE.LEGS,
    title: localizationKeys.LegTrend_lower,
  },
  {
    id: SEP_CHART_TYPE.THRUSTER,
    title: localizationKeys.Thruster_lower,
  },
  {
    id: SEP_CHART_TYPE.CRANE1,
    title: localizationKeys.Crane1_lower,
  },
  {
    id: SEP_CHART_TYPE.CRANE2,
    title: localizationKeys.Crane2_lower,
  },
  {
    id: SEP_CHART_TYPE.TRIM_AND_HEEL,
    title: localizationKeys.TrimAndHeel_lower,
  },
];

export const crane1Graph = {
  id: SEP_CHART_TYPE.CRANE1,
  title: localizationKeys.Crane1_lower,
  dataList: [
    { key: "dateTime", color: "none" },
    {
      key: "mainHookLoad",
      name: "MH Load",
      keyName: "mainHookLoad",
      shortKeyName: "mhLoad",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
    {
      key: "mainHookSafetyWorkingLoad",
      name: "MH SWL",
      keyName: "mainHookSafetyWorkingLoad",
      shortKeyName: "mhSafetyWLoad",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
    {
      key: "mainHookRadius",
      name: "MH Radius",
      keyName: "mainHookRadius",
      shortKeyName: "mhRadius",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
    {
      key: "mainHookActualSpeed",
      name: "MH Actual Speed",
      keyName: "mainHookActualSpeed",
      shortKeyName: "mhActualSpeed",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
    {
      key: "mainHookWireLength",
      name: "MH Pay-out Wire Length",
      keyName: "mainHookWireLength",
      shortKeyName: "mhWireLength",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
    {
      key: "wipHoistLoad",
      name: "WH Load",
      keyName: "wipHoistLoad",
      shortKeyName: "whLoad",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
    {
      key: "wipHoistSafetyWorkingLoad",
      name: "WH SWL",
      keyName: "wipHoistSafetyWorkingLoad",
      shortKeyName: "whSafetyWLoad",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
    {
      key: "wipHoistRadius",
      name: "WH Radius",
      keyName: "wipHoistRadius",
      shortKeyName: "whRadius",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
    {
      key: "wipHoistActualSpeed",
      name: "WH Actual Speed",
      keyName: "wipHoistActualSpeed",
      shortKeyName: "whActualSpeed",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
    {
      key: "wipHoistWireLength",
      name: "WH Pay-out Wire Length",
      keyName: "wipHoistWireLength",
      shortKeyName: "whWireLength",
      enableVisibilityIcon: "hidden",
      enable: true,
    },
  ],
};

const sepTrendGraphLines = [
  //for legs
  {
    key: "tipBelowHull1",
    name: "Tip Below Hull 1",
    keyName: "tipBelowHull1",
    color: "#e74293",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.LEGS,
  },
  {
    key: "tipBelowHull2",
    name: "Tip Below Hull 2",
    keyName: "tipBelowHull2",
    color: "#fc78a8",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.LEGS,
  },
  {
    key: "tipBelowHull3",
    name: "Tip Below Hull 3",
    keyName: "tipBelowHull3",
    color: "#a29bfe",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.LEGS,
  },
  {
    key: "tipBelowHull4",
    name: "Tip Below Hull 4",
    keyName: "tipBelowHull4",
    color: "#6c5de7",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.LEGS,
  },
  {
    key: "legLoad1",
    name: "Leg Load 1",
    keyName: "legLoad1",
    color: "#4fa8de",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.LEGS,
  },
  {
    key: "legLoad2",
    name: "Leg Load 2",
    keyName: "legLoad2",
    color: "#57cee1",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.LEGS,
  },
  {
    key: "legLoad3",
    name: "Leg Load 3",
    keyName: "legLoad3",
    color: "#56ebc1",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.LEGS,
  },
  {
    key: "legLoad4",
    name: "Leg Load 4",
    keyName: "legLoad4",
    color: "#911eb4",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.LEGS,
  },

  //for thruster
  {
    key: "thrusterMotorPower1",
    name: "Thruster Motor Actual Power 1",
    keyName: "thrusterMotorPower1",
    shortKeyName: "thrusterPower1",
    color: "#e74293",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.THRUSTER,
  },
  {
    key: "thrusterMotorPower2",
    name: "Thruster Motor Actual Power 2",
    keyName: "thrusterMotorPower2",
    shortKeyName: "thrusterPower2",
    color: "#fc78a8",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.THRUSTER,
  },
  {
    key: "thrusterMotorPower3",
    name: "Thruster Motor Actual Power 3",
    keyName: "thrusterMotorPower3",
    shortKeyName: "thrusterPower3",
    color: "#a29bfe",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.THRUSTER,
  },
  {
    key: "thrusterMotorPower4",
    name: "Thruster Motor Actual Power 4",
    keyName: "thrusterMotorPower4",
    shortKeyName: "thrusterPower4",
    color: "#6c5de7",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.THRUSTER,
  },
  {
    key: "thrusterMotorSpeed1",
    name: "Thruster Motor Actual Speed 1",
    keyName: "thrusterMotorSpeed1",
    shortKeyName: "thrusterSpeed1",
    color: "#4fa8de",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.THRUSTER,
  },
  {
    key: "thrusterMotorSpeed2",
    name: "Thruster Motor Actual Speed 2",
    keyName: "thrusterMotorSpeed2",
    shortKeyName: "thrusterSpeed2",
    color: "#57cee1",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.THRUSTER,
  },
  {
    key: "thrusterMotorSpeed3",
    name: "Thruster Motor Actual Speed 3",
    keyName: "thrusterMotorSpeed3",
    shortKeyName: "thrusterSpeed3",
    color: "#56ebc1",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.THRUSTER,
  },
  {
    key: "thrusterMotorSpeed4",
    name: "Thruster Motor Actual Speed 4",
    keyName: "thrusterMotorSpeed4",
    shortKeyName: "thrusterSpeed4",
    color: "#911eb4",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.THRUSTER,
  },

  //for crane1
  {
    key: "mainHookLoad",
    name: "MH Load",
    keyName: "mainHookLoad",
    shortKeyName: "mhLoad",
    color: "#e74293",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },
  {
    key: "mainHookSafetyWorkingLoad",
    name: "MH SWL",
    keyName: "mainHookSafetyWorkingLoad",
    color: "#fc78a8",
    shortKeyName: "mhSafetyWLoad",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },
  {
    key: "mainHookRadius",
    name: "MH Radius",
    keyName: "mainHookRadius",
    shortKeyName: "mhRadius",
    color: "#a29bfe",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },
  {
    key: "mainHookActualSpeed",
    name: "MH Actual Speed",
    keyName: "mainHookActualSpeed",
    shortKeyName: "mhActualSpeed",
    color: "#4fa8de",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },
  {
    key: "mainHookWireLength",
    name: "MH Pay-out Wire Length",
    keyName: "mainHookWireLength",
    shortKeyName: "mhWireLength",
    color: "#57cee1",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },
  {
    key: "wipHoistLoad",
    name: "WH Load",
    keyName: "wipHoistLoad",
    shortKeyName: "whLoad",
    color: "#56ebc1",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },
  {
    key: "wipHoistSafetyWorkingLoad",
    name: "WH SWL",
    keyName: "wipHoistSafetyWorkingLoad",
    color: "#911eb4",
    shortKeyName: "whSafetyWLoad",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },
  {
    key: "wipHoistRadius",
    name: "WH Radius",
    keyName: "wipHoistRadius",
    shortKeyName: "whRadius",
    color: "#e6beff",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },
  {
    key: "wipHoistActualSpeed",
    name: "WH Actual Speed",
    keyName: "wipHoistActualSpeed",
    shortKeyName: "whActualSpeed",
    color: "#f553ec",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },
  {
    key: "wipHoistWireLength",
    name: "WH Pay-out Wire Length",
    keyName: "wipHoistWireLength",
    shortKeyName: "whWireLength",
    color: "#0a84e3",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE1,
  },

  //for crane2
  {
    key: "slewCapacity",
    name: "Slew Capacity",
    keyName: "slewCapacity",
    color: "#e74293",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE2,
  },
  {
    key: "slewAngle",
    name: "Slew Angle",
    keyName: "slewAngle",
    color: "#fc78a8",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE2,
  },
  {
    key: "slewSpeed",
    name: "Slew Speed",
    keyName: "slewSpeed",
    color: "#a29bfe",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE2,
  },
  {
    key: "craneCapacity",
    name: "Crane Capacity",
    keyName: "craneCapacity",
    color: "#6c5de7",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE2,
  },
  {
    key: "boomAngle",
    name: "Boom Angle",
    keyName: "boomAngle",
    color: "#4fa8de",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE2,
  },
  {
    key: "boomSpeed",
    name: "Boom Speed",
    keyName: "boomSpeed",
    color: "#57cee1",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE2,
  },
  {
    key: "craneSideLead",
    name: "Crane Side Lead",
    keyName: "craneSideLead",
    color: "#56ebc1",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE2,
  },
  {
    key: "loadTagger1Load",
    name: "LT1 Load",
    keyName: "loadTagger1Load",
    color: "#911eb4",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE2,
  },
  {
    key: "loadTagger2Load",
    name: "LT2 Load",
    keyName: "loadTagger2Load",
    color: "#e6beff",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.CRANE2,
  },

  //for trim & heel
  {
    key: "trimValue",
    name: "Trim",
    keyName: "trimValue",
    color: "#e74293",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.TRIM_AND_HEEL,
  },
  {
    key: "heelValue",
    name: "Heel",
    keyName: "heelValue",
    color: "#fc78a8",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.TRIM_AND_HEEL,
  },
  {
    key: "waterDepth",
    name: "Water Depth",
    keyName: "waterDepth",
    color: "#a29bfe",
    enableVisibilityIcon: "hidden",
    enable: true,
    chartType: SEP_CHART_TYPE.TRIM_AND_HEEL,
  },
  // {key: "foreDraftP", name: "fore draft P", keyName: "foreDraftP", color: "#6c5de7", enableVisibilityIcon: "hidden", enable: true, chartType: SEP_CHART_TYPE.CRANE2},
  // {key: "foreDraftS", name: "fore draft S", keyName: "foreDraftS", color: "#4fa8de", enableVisibilityIcon: "hidden", enable: true, chartType: SEP_CHART_TYPE.CRANE2},
  // {key: "afterDraftP", name: "after draft P", keyName: "afterDraftP", color: "#57cee1", enableVisibilityIcon: "hidden", enable: true, chartType: SEP_CHART_TYPE.CRANE2},
  // {key: "afterDraftS", name: "after draft S", keyName: "afterDraftS", color: "#56ebc1", enableVisibilityIcon: "hidden", enable: true, chartType: SEP_CHART_TYPE.CRANE2},
];

export { SEP_CHART_TYPE, SEP_CHART_LIST, sepTrendGraphLines };
