import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Marker from "../../../../../assets/images/cargo/load/marker.svg";
import ArrowIcon from "../../../../../assets/images/cargo/load/arrow.svg";
import { COLORS } from "../../../../constants/colors";
import { checkValueRangeForChart } from "../commonFunc";
import localizationKeys from "../../../../i18n/localizationKeys";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    color: COLORS.white,
  },
  chart: {
    position: "relative",
    display: "flex",
    gap: "2px",
    width: "100%",
    justifyContent: "center",
  },
  gauge: {
    position: "relative",
    display: "inline-block",
    width: "33.5px",
    height: "8px",
    borderRadius: "1px",
    backgroundColor: "rgb(40, 182, 199)",
    "&:first-child": {
      marginRight: "0.5px",
    },
    "&:last-child": {
      marginLeft: "1px",
    },
  },
  minMaxLabel: {
    fontSize: "10px",
    width: "26px",
    textAlign: "center",
  },
  value: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "8px",
  },
  arrow: {
    position: "absolute",
    right: "30px",
    width: "68px",
    height: "25px",
    display: "flex",
    zIndex: 100,
  },
}));

export const RateChart = memo((props) => {
  const { rate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const range = 20;
  const checkedValue = checkValueRangeForChart(rate, range);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div className={classes.chart}>
          <div
            className={classes.minMaxLabel}
            style={{ opacity: isNaN(rate) ? 0.5 : 1 }}
          >{`-${range}`}</div>
          <div style={{ width: "68px" }}>
            <div style={{ width: "68px", height: "5px", opacity: isNaN(rate) ? 0.5 : 1 }}>
              <img
                src={Marker}
                width={5}
                height={68}
                style={{
                  transform: "rotate(90deg) translate(-31.5px, -31.5px)",
                }}
              />
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                marginTop: "2px",
              }}
            >
              <div
                className={classes.gauge}
                style={{
                  opacity: isNaN(rate) ? 0.2 : rate < 0 ? 1 : 0.4,
                }}
              />
              <div
                className={classes.arrow}
                style={{
                  transform: `translateX(${51 + checkedValue * (33.5 / range)}px)`,
                }}
              >
                <img src={ArrowIcon} width={25} height={25} />
              </div>
              <div
                className={classes.gauge}
                style={{
                  opacity: isNaN(rate) ? 0.2 : rate > 0 ? 1 : 0.4,
                }}
              />
            </div>
          </div>
          <div
            className={classes.minMaxLabel}
            style={{ opacity: isNaN(rate) ? 0.5 : 1 }}
          >{`+${range}`}</div>
        </div>
        <div className={classes.value}>
          {isNaN(rate) ? (
            <div style={{ fontSize: "14px" }}>{t(localizationKeys.NoLatestDataForChart)}</div>
          ) : (
            <>
              <div style={{ fontSize: "16px" }}>{(rate > 0 ? "+" : "") + rate.toFixed(2)}</div>
              <div style={{ fontSize: "10px", marginLeft: "2px" }}>
                deg/s
              </div>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
});

RateChart.propTypes = {
  rate: PropTypes.number,
};
