import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@blueprintjs/core";
import { COLORS } from "../../constants/colors";
import dayjs from "util/dayjs-init.js";
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from "react-redux";
import { getShorthandSettings } from "../../model/slice/dateTimeShorthandSlice";
import { convertToTimezone, formatDate, getCurrentDateWithTimezone } from "../../util/timezone";

const useStyles = makeStyles(() => ({
  base: {
    color: COLORS.white,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  textContainer: {
    textAlign: "center",
    fontSize: "10px",
    margin: "0 4px",
    userSelect: "none",
  },
}));

const timeDirection = {
  ahead: "ahead",
  back: "back",
};

export const DateTimeDisplay = (props) => {
  const { date, submitDateTimeChange, latestDateTime, timezone } = props;
  const styles = useStyles();
  const shorthandValue = useSelector(getShorthandSettings);
  const [updateFlag, setUpdateFlag] = useState(false);

  const handleClick = (value, direction) => {
    if (handleDisabled(value, direction)) return;
    switch (direction) {
      case timeDirection.ahead:
        submitDateTimeChange(dayjs.utc(date).add(value, "m"));
        break;
      case timeDirection.back:
        submitDateTimeChange(dayjs.utc(date).subtract(value, "m"));
        break;
    }
  };

  const handleDisabled = (value, direction) => {
    switch (direction) {
      case timeDirection.ahead:
        if (convertToTimezone(date, timezone).add(value, "m").isAfter(convertToTimezone(latestDateTime, timezone))) return true;
        break;
      case timeDirection.back:
        if (convertToTimezone(date, timezone).subtract(value, "m").isBefore(getCurrentDateWithTimezone(timezone).subtract(30, "day")))
          return true;
        break;
    }
    return false;
  };

  //to update Icon state per 1min
  useEffect(() => {
    if (updateFlag === true) {
      setUpdateFlag(false);
      return;
    }
    setTimeout(() => {
      setUpdateFlag(true);
    }, 60 * 1000);
  }, [updateFlag]);

  return (
    <div className={styles.base}>
      <Icon
        icon="double-chevron-left"
        style={{
          opacity: handleDisabled(shorthandValue.long, timeDirection.back) ? 0.5 : 1,
        }}
        onClick={() => handleClick(shorthandValue.long, timeDirection.back)}
      />
      <Icon
        icon="chevron-left"
        style={{
          opacity: handleDisabled(shorthandValue.short, timeDirection.back) ? 0.5 : 1,
        }}
        onClick={() => handleClick(shorthandValue.short, timeDirection.back)}
      />
      <div className={styles.textContainer}>
        <span>{formatDate(date, timezone, "YYYY/MM/DD")}</span>
        <br />
        <span>{`${formatDate(date, timezone, "HH:mm")} (${timezone})`}</span>
      </div>
      <Icon
        icon="chevron-right"
        style={{
          opacity: handleDisabled(shorthandValue.short, timeDirection.ahead, updateFlag) ? 0.5 : 1,
        }}
        onClick={() => handleClick(shorthandValue.short, timeDirection.ahead)}
      />
      <Icon
        icon="double-chevron-right"
        style={{
          opacity: handleDisabled(shorthandValue.long, timeDirection.ahead, updateFlag) ? 0.5 : 1,
        }}
        onClick={() => handleClick(shorthandValue.long, timeDirection.ahead)}
      />
    </div>
  );
};

DateTimeDisplay.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  submitDateTimeChange: PropTypes.func,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  latestDateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  timezone: PropTypes.string,
};
