import { createSlice } from "@reduxjs/toolkit";

import {
  fetchChannelAsync,
  fetchChannelLatestAsync,
  fetchChannelModAsync,
  fulfilledFetchChannelAsync,
  fulfilledFetchChannelLatestAsync,
  fulfilledFetchChannelModAsync,
  pendingFetchChannelAsync,
  pendingFetchChannelLatestAsync,
  pendingFetchChannelModAsync,
} from "../async/channelAsync";
import Actions from "../action/channelAction.js";

export const FILTER_FIELDS = {
  CHANNEL_NO: "chNo",
  CHANNEL_NAME: "chName",
  VALUE: "inputData",
  STATUS: "chStatus",
};

export const INITIAL_FILTER = {
  chNo: undefined,
  name: undefined,
  value: undefined,
  status: undefined,
};

export const INITIAL_STATE = {
  channelData: [],
  channelLatestData: [],
  channelModData: [],
  isChannelFetching: false,
  isChannelLatestFetching: false,
  isChannelModFetching: false,
  isChannelTrendFetching: false,
  isSearching: false,
  tempFilteredChannelData: undefined,
  filteredChannelData: undefined,
  filters: INITIAL_FILTER,
  currentWindowPosition: 0,
  currentTablePosition: 0,
  currentChannelPage: 0,
};

const channelDataSlice = createSlice({
  name: "channelData",
  initialState: INITIAL_STATE,
  reducers: {
    ...Actions,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChannelAsync.fulfilled, fulfilledFetchChannelAsync);
    builder.addCase(fetchChannelLatestAsync.fulfilled, fulfilledFetchChannelLatestAsync);
    builder.addCase(fetchChannelModAsync.fulfilled, fulfilledFetchChannelModAsync);
    builder.addCase(fetchChannelAsync.pending, pendingFetchChannelAsync);
    builder.addCase(fetchChannelLatestAsync.pending, pendingFetchChannelLatestAsync);
    builder.addCase(fetchChannelModAsync.pending, pendingFetchChannelModAsync);
  },
});

//Selectors
export const channelData = (state) => state.channelController.channelData;
export const channelLatestData = (state) => state.channelController.channelLatestData;
export const channelModData = (state) => state.channelController.channelModData;
export const isChannelFetching = (state) => state.channelController.isChannelFetching;
export const isChannelLatestFetching = (state) => state.channelController.isChannelLatestFetching;
export const isChannelModFetching = (state) => state.channelController.isChannelModFetching;
export const isChannelTrendFetching = (state) => state.channelController.isChannelTrendFetching;
export const hasActiveFilter = (state) =>
  !!(
    state.channelController.filters.chNo ||
    state.channelController.filters.chName ||
    state.channelController.filters.inputData ||
    state.channelController.filters.chStatus
  );
export const isChannelSearching = (state) => state.channelController.isSearching;
export const tempFilteredChannelData = (state) => state.channelController.tempFilteredChannelData;
export const filteredChannelData = (state) => state.channelController.filteredChannelData;
export const currentWindowPosition = (state) => state.channelController.currentWindowPosition;
export const currentTablePosition = (state) => state.channelController.currentTablePosition;
export const currentChannelPage = (state) => state.channelController.currentChannelPage;

export const {
  search,
  resetFilters,
  reset,
  setCurrentWindowPosition,
  setCurrentTablePosition,
  setCurrentChannelPage,
  setIsChannelTrendFetching,
  searchButtonClicked,
} = channelDataSlice.actions;

export default channelDataSlice.reducer;
