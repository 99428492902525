import axiosBase from "axios";

export const BACKEND_URL = process.env.BACKEND_URL;
export const API_URL = process.env.API_URL;
export const VISWA_TOKEN_URL = process.env.VISWA_TOKEN_URL;
export const VISWA_API_URL = process.env.VISWA_API_URL;
export const WEATHER_URL = process.env.WEATHER_URL;
export const SYNTHETICA_API_URL = process.env.SYNTHETICA_API_URL;

export const axios = axiosBase.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  responseType: "json",
});

export const axios_iapi = axiosBase.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  responseType: "json",
});

export const axios_viswa_token_api = axiosBase.create({
  baseURL: VISWA_TOKEN_URL,
  responseType: "json",
});

export const axios_viswa_data_api = (token) => {
  return axiosBase.create({
    baseURL: VISWA_API_URL,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-Width": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
    responseType: "json",
  });
};

export const axios_weather = axiosBase.create({
  baseURL: WEATHER_URL,
  // headers: {
  //   // "Content-Type": "application/json",
  //   "X-Requested-With": "XMLHttpRequest",
  // },
  responseType: "json",
});

export const axios_synthetica_api_token = axiosBase.create({
  baseURL: SYNTHETICA_API_URL,
  responseType: "json",
});

export const axios_synthetica_api = (token) => {
  return axiosBase.create({
    baseURL: SYNTHETICA_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    responseType: "json",
  });
};
