import { withTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import localizationKeys from "../../i18n/localizationKeys";
import * as PropTypes from "prop-types";
import DisconnectedIcon from "../../../assets/images/navIcon/nav-alarm-offline-3.svg";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "35px",
    height: "35px",
    verticalAlign: "middle",
    display: "inline-block",
    marginRight: "15px",
    marginTop: "-3px",
  },
  rootGrid: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    display: "inline-block",
  },
  nodata: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#d8d8d8",
  },
}));

const Offline = ({ t = null, isEngine = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.rootGrid}>
      <p className={classes.line}>
        <img src={DisconnectedIcon} alt="disconnect Icon" className={classes.icon} />
        <span className={classes.nodata}>
          {isEngine
            ? t(localizationKeys.DisconnectedNoEngineData_lower)
            : t(localizationKeys.DisconnectedNodata_lower)}
        </span>
      </p>
    </div>
  );
};

Offline.PropType = {
  t: PropTypes.func,
};

export default withTranslation()(Offline);
