import React from "react";
import PropTypes from "prop-types";
import { TimePicker } from "@blueprintjs/datetime";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../constants/colors";
import dayjs from "util/dayjs-init.js";
import "./timePicker.css";
import { TIMEZONE } from "../../constants/timezone";

const useStyles = makeStyles({
  timePickerContainer: {
    display: "flex",
    background: COLORS.componentBackColor,
    justifyContent: "flex-end",
  },
  startTimePicker: {
    position: "relative",
    right: "5%",
  },
  endTimePicker: {
    position: "relative",
    right: "15%",
  },
});

export const Timepicker = (props) => {
  const { time, setTime, timezone } = props;
  const classes = useStyles();

  //Date object can't use UTC so use JST time -9 (note: display utc time but timezone is jst)
  const defaultValue = (timezone === TIMEZONE.UTC ? dayjs(time).subtract(9, "h") : dayjs(time)).toDate();

  const onChangeHandler = (newTime, updateTarget, updateFunction) => {
    //fix newTime to correct utc time.
    const newUTCTime = (timezone === TIMEZONE.UTC ? dayjs(newTime).add(9, 'h') : dayjs(newTime));
    const newHour = newUTCTime.get("hour");
    const newMinutes = newUTCTime.get("minute");

    let returnValue = (timezone === TIMEZONE.UTC ? dayjs(updateTarget).add(9, 'h') : dayjs(updateTarget));
    returnValue = returnValue.hour(newHour);
    returnValue = returnValue.minute(newMinutes);
    updateFunction(returnValue);
  };
  return (
    <div className={classes.timePickerContainer}>
      <TimePicker
        className={classes.startTimePicker}
        showArrowButtons
        value={defaultValue}
        onChange={(v) => onChangeHandler(v, time, setTime)}
      />
    </div>
  );
};

Timepicker.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
  setTime: PropTypes.func,
  timezone: PropTypes.string,
};
