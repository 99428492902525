import localizationKeys from "../../i18n/localizationKeys";

export const DEFAULT_CHART_TITLE = "Custom";
export const DEFAULT_CHART_TITLE_JP = "カスタム";
export const DEFAULT_CUSTOM_CHART_KEY = "custom";
export const MAX_CHANNEL_COUNT = 16;

export const CHART_PROMPTS = {
  SAVE: {
    title: localizationKeys.CustomPromptSaveTitle,
    message: localizationKeys.CustomPromptSaveSubtitle,
    okText: localizationKeys.CustomPromptSaveOkButton,
    cancelText: localizationKeys.CustomPromptSaveCancelButton,
  },
  CANCEL: {
    title: localizationKeys.CustomPromptCancelTitle,
    message: localizationKeys.CustomPromptCancelSubtitle,
    okText: localizationKeys.CustomPromptCancelOkButton,
    cancelText: localizationKeys.CustomPromptCancelCancelButton,
  },
  DELETE: {
    title: localizationKeys.CustomPromptDeleteTitle,
    message: localizationKeys.CustomPromptDeleteSubtitle,
    okText: localizationKeys.CustomPromptDeleteOkButton,
    cancelText: localizationKeys.CustomPromptDeleteCancelButton,
  },
  LEAVE: {
    title: localizationKeys.CustomPromptLeaveTitle,
    message: localizationKeys.CustomPromptLeaveSubtitle,
    okText: localizationKeys.CustomPromptLeaveOkButton,
    cancelText: localizationKeys.CustomPromptLeaveCancelButton,
  },
};
