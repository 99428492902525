import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React, { Children } from 'react';
import { Box, CircularProgress } from '@mui/material';

export const SyntheticaStyles = {
    paperHeader: {
        marginBottom: "12px",
        display: "flex",
        fontSize: "16px",
        color: "white",
    },
    spinner: {
        margin: "20px 20px",
        position: "relative",
        //left: "calc(50% - 20px)",
        top: "calc(50% - 20px)",
    },
    select: {
        color: "white",
        fontSize: "10px",
        fontWeight: "300",
        minWidth: "100px",
        '& .MuiSelect-icon': {
            fill: "white",
        },
        '& .MuiSelect-select:focus': {
            backgroundColor: 'rgba(0,0,0,0)'
        },
    },
    noDataWrapper: {
        width: "100%",
        height: "100%",
        display: "grid",
        textAlign: "center",
        alignItems: "center",
        fontSize: "30px",
    },
    deltaIndicatorBase: {
        fontSize: "10px",
        width: "78px",
        height: "24px",
        borderRadius: "2px",
        display: "flex",
        textAlign: "center",
        lineHeight: "24px",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
    },
    deltaIndicatorGood: {
        color: "#9dcd4e",
        background: "rgba(157,205,78,0.1)",
        border: "0px",
    },
    deltaIndicatorMed: {
        color: "#fea813",
        background: "rgba(255,247,0,0.1)",
        border: "0px",
    },
    deltaIndicatorBad: {
        color: "#ff5252",
        background: "rgba(255,82,82,0.1)",
        border: "0px",
    },
}

export const SynthConstants = {
    DELTA_GOOD: "GOOD",
    DELTA_MED: "MED",
    DELTA_BAD: "BAD",
}

export const SynthPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    background: "#272D38",
    textAlign: 'center',
    color: 'white',
    borderRadius: '12px',
}));

export const DataGuard = ({ data, children }) => {
    if (data != null && data.isLoading)
        return (<CircularProgress sx={SyntheticaStyles.spinner} />);
    if (data == null)
        return NoData();
    if (data.hasData == false)
        return NoData();
    return (
        <>
            {children}
        </>
    );
}
export const NoData = () => {
    return (<Box sx={SyntheticaStyles.noDataWrapper}>
        No Data
    </Box>)
}

export const colorFromRank = (rank, opacity = "ff") => {
    switch (rank) {
        case 'a': return "#00c45a" + opacity
        case 'b': return "#cef39f" + opacity
        case 'c': return "#f3e19f" + opacity
        case 'd': return "#f3ae9f" + opacity
        case 'e': return "#ff8282" + opacity
        default: return "#ffffff"
    }
}

/*
    "limits": {
        "boundary_4": 34.34,
        "boundary_3": 27.28,
        "boundary_2": 21.92,
        "boundary_1": 17.54
    }, 
*/
export const rankFromData = (ciiValue, limits) => {
    if (ciiValue < limits.boundary_1)
        return 'a';
    if (ciiValue < limits.boundary_2)
        return 'b';
    if (ciiValue < limits.boundary_3)
        return 'c';
    if (ciiValue < limits.boundary_4)
        return 'd';
    return 'e';
}
const RightUpArrow = (isGood) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.472 3.529c.26.26.26.682 0 .942l-8 8a.667.667 0 1 1-.943-.942l8-8c.26-.26.682-.26.943 0z" fill={isGood ? "#9DCD4E" : "#ff5252"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.833 4c0-.368.299-.667.667-.667H12c.368 0 .667.299.667.667v6.5a.667.667 0 1 1-1.333 0V4.667H5.5A.667.667 0 0 1 4.833 4z"  fill={isGood ? "#9DCD4E" : "#ff5252"} />
    </svg>
);
const RightArrow = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.833 8c0-.368.299-.667.667-.667h11a.667.667 0 0 1 0 1.334h-11A.667.667 0 0 1 1.833 8z" fill="#FEA813" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.529 3.029c.26-.26.682-.26.943 0l4.5 4.5c.26.26.26.682 0 .942l-4.5 4.5a.667.667 0 1 1-.943-.942L12.557 8 8.53 3.971a.667.667 0 0 1 0-.942z" fill="#FEA813" />
    </svg>
);
const RightDownArrow = (isGood) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.35355 9.35355C9.15829 9.54882 8.84171 9.54882 8.64645 9.35355L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645C2.84171 2.45118 3.15829 2.45118 3.35355 2.64645L9.35355 8.64645C9.54882 8.84171 9.54882 9.15829 9.35355 9.35355Z"  fill={isGood ? "#9DCD4E" : "#ff5252"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9 3.625C9.27614 3.625 9.5 3.84886 9.5 4.125V9C9.5 9.27614 9.27614 9.5 9 9.5H4.125C3.84886 9.5 3.625 9.27614 3.625 9C3.625 8.72386 3.84886 8.5 4.125 8.5H8.5V4.125C8.5 3.84886 8.72386 3.625 9 3.625Z" fill={isGood ? "#9DCD4E" : "#ff5252"}/>
    </svg>
);

const getArrowFromState = (delta, state) => {
    const isGood = state === SynthConstants.DELTA_GOOD;
    if(delta > 2)
        return RightUpArrow(isGood);
    else if(delta < -2)
        return RightDownArrow(isGood);
    return RightArrow();
}

// Show the delta badge
export const DeltaIndicator = ({ delta, deltaState }) => {
    if (delta == null || delta === "")
        return <></>;
    let displayStyle = { ...SyntheticaStyles.deltaIndicatorBase };
    if (deltaState === SynthConstants.DELTA_GOOD)
        displayStyle = { ...SyntheticaStyles.deltaIndicatorBase, ...SyntheticaStyles.deltaIndicatorGood };
    else if (deltaState === SynthConstants.DELTA_BAD)
        displayStyle = { ...SyntheticaStyles.deltaIndicatorBase, ...SyntheticaStyles.deltaIndicatorBad };
    else
        displayStyle = { ...SyntheticaStyles.deltaIndicatorBase, ...SyntheticaStyles.deltaIndicatorMed };

    return (
        <Box sx={displayStyle}>
            {getArrowFromState(delta, deltaState)}
            {delta.toFixed(2) + "%"}
        </Box>
    )
}