import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Wrapper } from "@googlemaps/react-wrapper";
import withStyles from '@mui/styles/withStyles';
import _ from "lodash";

import { API_KEY, REGION } from "../../constants/googlemap.js";
import Map from "./map.jsx";
import {
  setCurrentVessels,
  setMapZoom,
  mapData,
} from "../../model/slice/fleetNavigationDataSlice.js";

const styles = () => ({
  mapStyle: {
    height: "100%",
    width: "100%",
  },
});

//Main component
const MapWrapper = (props) => {
  const dispatch = useDispatch();
  const mapDetails = useSelector(mapData);

  useEffect(() => {
    if (props.vessels && !_.isEqual(props.vessels, mapDetails.currentVessels)) {
      dispatch(setCurrentVessels(props.vessels));
    }
  }, [props.vessels]);

  return (
    <Wrapper apiKey={API_KEY} region={REGION} libraries={["marker"]}>
      <Map vessels={mapDetails.currentVessels} isMainPage={props.isMainPage} />
    </Wrapper>
  );
};

MapWrapper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  vessels: PropTypes.array,
  isMainPage: PropTypes.bool,
};

export default withStyles(styles)(React.memo(MapWrapper));
