import { useState } from "react";

export const DEVICE_TYPE = {
  PC: 1,
  PHONE: 2,
  TABLET: 3,
};

export const detectDevice = (() => {
  if (
    navigator.userAgent.match(/(iPhone|Android.*Mobile|iPod|BlackBerry|IEMobile|Opera Mini|webOS)/i)
  ) {
    return DEVICE_TYPE.PHONE;
  } else if (
    navigator.userAgent.match(/(iPad|Android)/) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  ) {
    return DEVICE_TYPE.TABLET;
  } else {
    return DEVICE_TYPE.PC;
  }
})();

export const DEVICE_ANGLE = {
  VERTICAL: 1,
  HORIZONTAL: 2,
};

const detectScreenRotate = () => {
  if (detectDevice !== DEVICE_TYPE.PHONE) return DEVICE_ANGLE.VERTICAL;
  const angle = screen?.orientation?.angle ?? window?.orientation;
  return angle === 0 ? DEVICE_ANGLE.VERTICAL : DEVICE_ANGLE.HORIZONTAL;
};

export const useDetectScreenRotate = () => {
  const [angle, setAngle] = useState(DEVICE_ANGLE.VERTICAL);

  window.addEventListener(
    "orientationchange",
    () => {
      setAngle(detectScreenRotate());
    },
    false
  );

  return {
    angle,
  };
};
