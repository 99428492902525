import { useEffect, useRef } from "react";

const noop = () => {};

/**
 * set interval custom hook
 * @param callback
 * @param delay
 * @param immediate
 */
export const useInterval = (callback, delay, immediate = false) => {
  const savedCallback = useRef(noop);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (immediate === null || !immediate) return;
    if (delay === null || delay === false) return;
    savedCallback.current();
  }, [immediate]);

  useEffect(() => {
    if (delay === null || delay === false) return;
    const tick = () => savedCallback.current();
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
