import { createSlice } from "@reduxjs/toolkit";

//Action imports
import Actions from "../action/dataDurationAction";

// Constants
import {
  electricInitialDuration,
  portInitialDuration,
  starboardInitialDuration,
  centerInitialDuration,
  sepInitialDuration,
  comparisionInitialDuration,
  customInitialDuration,
  cargoInitialDuration,
} from "../../constants/duration";

export const initialOption = {
  electric: {
    value: electricInitialDuration,
    vesselId: null,
  },
  port: {
    value: portInitialDuration,
    vesselId: null,
  },
  starboard: {
    value: starboardInitialDuration,
    vesselId: null,
  },
  sep: {
    value: sepInitialDuration,
    vesselId: null,
  },
  center: {
    value: centerInitialDuration,
    vesselId: null,
  },
  comparison: {
    value: comparisionInitialDuration,
    vesselId: null,
  },
  custom: {
    value: customInitialDuration,
    vesselId: null,
  },
  cargo: {
    value: cargoInitialDuration,
    vesselId: null,
  },
};

const durationInitialState = {
  startDate: "",
  endDate: "",
};

const cargoDurationInitialState = {
  startDate: "",
  endDate: "",
  interval: 1,
}

const dataDurationSlice = createSlice({
  name: "dataDurationController",
  initialState: {
    duration: durationInitialState,
    electricDataDuration: durationInitialState,
    durationOption: {
      ...initialOption,
    },
    previousOption: {
      ...initialOption,
    },
    sepDataDuration: durationInitialState,
    comparisonDataDuration: durationInitialState,
    customDataDuration: durationInitialState,
    cargoDataDuration: cargoDurationInitialState,
  },
  reducers: {
    ...Actions,
  },
});

//Selectors
export const getDataDuration = (state) => state.dataDurationController.duration;
export const getElectricDataDuration = (state) => state.dataDurationController.electricDataDuration;
export const getDurationOption = (state) => state.dataDurationController.durationOption;
export const getPreviousOption = (state) => state.dataDurationController.previousOption;
export const getSepDataDuration = (state) => state.dataDurationController.sepDataDuration;
export const getComparisonDataDuration = (state) =>
  state.dataDurationController.comparisonDataDuration;
export const getCustomDataDuration = (state) => state.dataDurationController.customDataDuration;
export const getCargoDataDuration = (state) => state.dataDurationController.cargoDataDuration;
export const getSelectedDurationOption = (durationOption) => (state) =>
  state.durationOption?.[durationOption];

export const {
  setDataDuration,
  setStartDate,
  setEndDate,
  setElectricDataDuration,
  setElectricStartDate,
  setElectricEndDate,
  setDurationOption,
  resetDurationOption,
  cancelDurationOption,
  setDurationOptionLabel,
  setPreviousOptionLabel,
  setSepDataDuration,
  setSepStartDate,
  setSepEndDate,
  setComparisonDataDuration,
  setComparisonDataStartDate,
  setComparisonDataEndDate,
  setCustomDataDuration,
  setCustomDataStartDate,
  setCustomDataEndDate,
  setCargoDataStartDate,
  setCargoDataEndDate,
  setCargoDataDuration,
} = dataDurationSlice.actions;

export default dataDurationSlice.reducer;
