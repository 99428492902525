import React from "react";
import PropTypes from "prop-types";
import { COLORS } from "../../../constants/colors";

export const SearchIcon = ({ width = 24, height = 24, color = COLORS.white }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m19.032 18.809.774-.774a.66.66 0 0 0 .003-.93l-2.727-2.726a.656.656 0 0 0-.465-.191h-.445A5.687 5.687 0 0 0 11.688 5 5.687 5.687 0 0 0 6 10.688a5.687 5.687 0 0 0 9.188 4.484v.445c0 .176.068.342.19.465l2.727 2.727a.654.654 0 0 0 .927 0zm-7.345-4.622a3.498 3.498 0 0 1-3.5-3.5c0-1.933 1.565-3.5 3.5-3.5 1.934 0 3.5 1.565 3.5 3.5 0 1.934-1.564 3.5-3.5 3.5z"
      fill={color}
    />
  </svg>
);

SearchIcon.prototype = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
