import React, { useEffect, useState } from "react";

//Component imports
import MapWrapper from "../map/mapWrapper.jsx";
import FleetList from "./fleetList.jsx";
import NavBar from "../navBar/index.jsx";
import SideBar from "../sideBar/index.jsx";
import VesselOverview from "./overview/vesselOverview.jsx";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import { fleetNavigationData, setMapZoom, selectedVessel } from "../../model/slice/fleetNavigationDataSlice.js";

import makeStyles from '@mui/styles/makeStyles';

//Localization Imports
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys.js";
import {
  detectDevice,
  DEVICE_ANGLE,
  DEVICE_TYPE,
  useDetectScreenRotate,
} from "../../constants/detectDevice.js";
import { selectSideBarState } from "../../model/slice/sideBarSettingSlice.js";
// import AlarmNotification from "./alarmNotification.jsx";
import { setChartType } from "../../model/slice/chartTypeSlice.js";

import { NAVIGATION_BAR_HEIGHT } from "../../layout/panelLayout.jsx";

const normalVesselList = (isMobileVertical, isSideBarDisplayed) => {
  switch (true) {
    case isMobileVertical && isSideBarDisplayed:
      return {
        position: "fixed",
        bottom: "30px",
        right: "30px",
        left: `${30 + 50}px`,
        width: "auto",
      };
    case isMobileVertical:
      return {
        position: "fixed",
        bottom: "30px",
        right: "30px",
        left: "30px",
        width: "auto",
      };
    default:
      return {
        position: "fixed",
        bottom: "32px",
        left: "85px",
        width: "306px",
      };
  }
};

const useStyles = ({ isMobile, isTouchDevice, isVertical, isSideBarDisplayed }) =>
  makeStyles((theme) => ({
    containerWrapper: {
      position: "relative",
      display: "flex",
      "user-select": "none",
      "-moz-user-select": "none",
      "-webkit-user-select": "none",
    },
    mainPageContainer: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      zIndex: "20",
    },
    mapHolder: {
      width: "100%",
      height: `calc(100dvh - ${NAVIGATION_BAR_HEIGHT}px)`,
      "@supports (-webkit-touch-callout: none)": {
        height: `calc(100dvh - ${NAVIGATION_BAR_HEIGHT}px)`,
      },
      position: "absolute",
      top: NAVIGATION_BAR_HEIGHT + "px",
      left: "0",
      zIndex: "10",
      "& .gm-style": {
        "& .gm-style-iw-c": {
          background: "none",
          boxShadow: "none",
          paddingLeft: 0,
        },
        "& .gm-style-iw-d": {
          minHeight: "50px",
          overflow: "visible !important",
        },
        "& .gm-style-iw button": {
          display: "none !important",
        },
        "& .gm-style-iw-tc::after": {
          display: "none",
        },
      },
    },
    logo: {
      height: "41px",
      width: "41px",
    },
    alarmNotificationContainer: {
      position: "fixed",
      top: "66px",
      left: "96px",
      width: "auto",
    },
    fleetListComponent: {
      [theme.breakpoints.down('lg')]: {
        display: "none",
      },
      ...normalVesselList(isMobile && isVertical, isSideBarDisplayed),
    },
  }));

const MainPage = React.memo((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = detectDevice === DEVICE_TYPE.PHONE;
  const isTouchDevice = [DEVICE_TYPE.PHONE, DEVICE_TYPE.TABLET].includes(detectDevice);
  const { angle } = useDetectScreenRotate();
  const isSideBarDisplayed = useSelector(selectSideBarState);
  const fleetDataState = useSelector(fleetNavigationData);
  const currentVessel = useSelector(selectedVessel);
  const classes = useStyles({
    isMobile,
    isTouchDevice,
    isVertical: angle === DEVICE_ANGLE.VERTICAL,
    isSideBarDisplayed,
  })();
  const [fleetListHeight, setFleetListHeight] = useState(380);
  const [invisibleFleets, setInvisibleFleets] = useState([]);
  const [newFleet, setNewFleet] = useState([]);

  const handleInvisibleFleetsChange = (i) => {
    setInvisibleFleets(i);
    setNewFleet(fleetDataState?.fleetNavigationData.filter((v) => !i.includes(v?.vesselManagement?.company_name)));
  };

  useEffect(() => {
    setNewFleet(fleetDataState?.fleetNavigationData.filter((v) => !invisibleFleets.includes(v?.vesselManagement?.company_name)));
  }, [fleetDataState]);

  const handleResize = () => {
    setFleetListHeight(window.innerHeight - 30 - 80 - (isMobile ? 60 : 0));
  };

  useEffect(() => {
    dispatch(setChartType(null));
    dispatch(setMapZoom(3));

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [window.innerHeight]);

  return (
    <>
      <div className={classes.containerWrapper}>
        <div className={classes.mapHolder}>
          <MapWrapper vessels={newFleet} isMainPage={true} />
        </div>
        <div className={classes.mainPageContainer}>
          <NavBar
            title={t(localizationKeys.FleetOverview_upper)}
            changeLanguage={props.changeLanguage}
            setUserContext={props.setUserContext}
          />
          <SideBar />
          {/* <div className={classes.alarmNotificationContainer}>
            <AlarmNotification />
          </div> */}
          <div className={classes.fleetListComponent}>
            <FleetList height={fleetListHeight} onVisibilityChange={handleInvisibleFleetsChange} />
          </div>
          {
            currentVessel && 
              <VesselOverview vessel={currentVessel} t={t} />
          }
        </div>
      </div>
    </>
  );
});

export { MainPage };
