import { Grid } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import ComparisonEngineSelector from "./comparisonEngineSelector.jsx";
import { ChartSelector } from "./chartSelector.jsx";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType.js";
import { COLORS } from "../../constants/colors.js";

export const customGraphSelectorStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: "10px",
    opacity: state.isDisabled ? 0.5 : 1,
    height: "24px",
    minHeight: "24px",
    width: "100%",
    background: state.selectProps.isSm ? "#21262f" : "#2c3a48",
    borderRadius: "2px",
    borderColor: "#2c3b48",
    boxShadow: null,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      borderColor: "#2c3b48",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    display: "none",
    height: "24px",
    minHeight: "24px",
    fontFamily: "Inter",
    background: state.isSm ? "#21262f" : "#2c3a48",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItem: "center",
    fontSize: "12px",
    fontFamily: "Inter",
    background: state.isSelected ? "rgba(33, 38, 47, 0.7)" : "none",
    height: "45px",
    "&:hover": {
      background: "rgba(33, 38, 47, 0.7)",
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "null",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 10,
    borderRadius: 0,
    color: "#ffffff",
    marginTop: "8px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return {
      ...provided,
      opacity,
      color: "#ffffff",
    };
  },
  menuList: (provided) => ({
    ...provided,
    background: "#2c3a48",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    height: "100%",
    minHeight: "50px",
    maxHeight: "220px",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: "24px",
    minHeight: "24px",
    "& svg": {
      fill: COLORS.staticState,
    },
    "& svg:checked": {
      fill: COLORS.staticState,
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#fff",
    fontFamily: "Inter",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const GraphSelector = (props) => {
  const {
    vessel,
    selectedGraph,
    setSelectedGraph,
    selectedEngine,
    setSelectedEngine,
    chartType,
    position,
    customCharts,
    selectedGraphType,
    isFetchingChartInfo,
  } = props;

  const EnginePositionSelector = () => {
    if(selectedGraphType === GRAPH_TYPE.ENGINE && vessel?.NoME > 0) {
      return (
        <ComparisonEngineSelector
          vesselSettings={vessel}
          selectedEngine={selectedEngine}
          engineSelect={setSelectedEngine}
        />
      );
    };
    return null;
  };

  return (
    <Grid container>
      <EnginePositionSelector />
      <ChartSelector
        selectedVessel={vessel}
        chartType={chartType}
        selectedGraph={selectedGraph}
        setSelectedGraph={setSelectedGraph}
        position={position}
        customCharts={customCharts}
        selectedGraphType={selectedGraphType}
        isFetchingChartInfo={isFetchingChartInfo}
      />
    </Grid>
  );
};

GraphSelector.propTypes = {
  vessel: PropTypes.object,
  selectedGraph: PropTypes.string,
  setSelectedGraph: PropTypes.func,
  selectedEngine: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setSelectedEngine: PropTypes.func,
  chartType: PropTypes.object,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  customCharts: PropTypes.object,
  selectedGraphType: PropTypes.string,
  isFetchingChartInfo: PropTypes.bool,
};
