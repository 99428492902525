import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

import { alarmListByCategorySelector } from "../../../model/slice/alarmSlice.js";
import { isCurrentVesselSEP } from "../../../model/slice/vesselSlice.js";
import { fetchVesselAsync } from "../../../model/async/vesselAsync.js";
import {
  CATEGORY_DISPLAY_ICON_MAPPING,
  CATEGORY_ENGINE,
  CATEGORY_INSULATOR,
  CATEGORY_WORDING_MAPPING,
  STATUS_PRIORITY_LIST,
} from "../../../constants/alarmType.js";
import { getHighlightStyle } from "../../../helpers/alarmTypeStyle.js";

const styles = (theme) => ({
  circleStatus: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    marginLeft: "6px",
    marginRight: "6px",
    display: "inline-block",
  },
  categoryHolder: {
    minHeight: "100%",
    backgroundColor: "#2c3a48 !important",
    borderRadius: "8px !important",
    height: "100%",
  },
  categoryBlock: {
    textAlign: "center",
  },
  categoryTitle: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "center",
    flexDirection: "row",
    alignContent: "sretch",
    color: "white",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Inter",
    [theme.breakpoints.only("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
    },
  },
  categoryIcon: {
    height: "20px",
    [theme.breakpoints.only("sm")]: {
      height: "20px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "10px",
    },
  },
});

const Category = memo(({ vesselId, classes }) => {
  const dispatch = useDispatch();
  const isSEPVessel = useSelector(isCurrentVesselSEP);
  const alarmCategory = useSelector(alarmListByCategorySelector);

  useEffect(() => {
    if (!vesselId) return;
    dispatch(fetchVesselAsync({ vesselId }));
  }, [vesselId])

  const displayStatusList = (categoryKey) => {
    const statusRender = [];

    STATUS_PRIORITY_LIST.forEach((status) => {
      //Default color is black if there are no active alarms in a specific status
      let colorStyle = { backgroundColor: "#000000" };

      //Check if a certain status is existing in alarm list of a category
      if (categoryKey in alarmCategory) {
        const categoryStatus = alarmCategory[categoryKey].filter(
          (alarm) => alarm.alarmStatus === status
        );

        //If a certain status has an alarm, get the correct color
        if (categoryStatus.length > 0) {
          colorStyle = getHighlightStyle({ alarmStatus: status });
        }
      }

      statusRender.push(<span key={status} className={classes.circleStatus} style={colorStyle}></span>);
    });

    return statusRender;
  };

  const displayCategoryList = () => {
    const categoryRender = [];
    for (const key in CATEGORY_WORDING_MAPPING) {
      // When vessel type is SEP. hide category_enginer and category_insulator
      if ((isSEPVessel && key == CATEGORY_ENGINE) || (isSEPVessel && key == CATEGORY_INSULATOR))
        continue;

      categoryRender.push(
        <Grid key={key} item xs={6} sm={6} md={3}>
          <div className={classes.categoryBlock}>
            <div className={classes.categoryTitle}>
              <span className={classes.categoryIcon}>
                <img src={CATEGORY_DISPLAY_ICON_MAPPING[key]} className={classes.categoryIcon} />
              </span>

              {displayStatusList(key)}
            </div>
          </div>
        </Grid>
      );
    }
    return categoryRender;
  };

  return displayCategoryList();
});

Category.propTypes = {
  vesselId: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export default withStyles(styles)(Category);