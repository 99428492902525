import { useSelector } from "react-redux";
import { selectedFOCDuration } from "../../../model/slice/fleetNavigationDataSlice";
import { useEffect, useRef, useState } from "react";
import { baseNoon, baseTwoHours } from "../focCore";
import { fetchGeneratorEngineDataWithLimit } from "../../../api/baseEngineData";
import dayjs from 'util/dayjs-init.js';
import { fetchVesselLatestGeneratorEngineData } from "../../../api/latestData";
import useInterval from "../../../hooks/useInterval";
import { logger } from "../../../api/logger";

// check 2h FOC and TOTAL FOC data(only Gen)
export const useGenFocCalculation = ({ vesselId, flowmeterType }) => {
  const selectedFocDuration = useSelector(selectedFOCDuration);
  const unmount = useRef(false);
  const [currentFoc, setCurrentFoc] = useState(null);

  const getGeneratorEngineData = async (latestDateTime, generatorNumber) => {
    const { endDateTime, startDateTime } =
      selectedFocDuration?.duration === 24
        ? baseNoon(latestDateTime)
        : baseTwoHours(latestDateTime);

    try {
      const arg = {
        vesselId,
        startDate: dayjs(startDateTime).format(),
        endDate: dayjs(endDateTime).format(),
        interval: 0,
        generatorNumber,
        limit: 1,
      };
      const data = await fetchGeneratorEngineDataWithLimit(arg);
      if (data.loadingData) {
        return {
          // If there are "-1" data in fetched data, It will be removed.
          genData: data.loadingData,
          startDate: startDateTime,
          endDate: endDateTime,
        }; //If data is there return
      }
    } catch (e) {
      logger.error(`getGeneratorEngineData error: ${e}`);
    }
    return null;
  };

  const getLatestGeneratorEngineData = async () => {
    try {
      const data = await fetchVesselLatestGeneratorEngineData(vesselId);
      if (data.latestGeneratorEngineData) {
        return data.latestGeneratorEngineData; //If data is there return
      }
    } catch (e) {
      logger.error(`getLatestGeneratorEngineData error: ${e}`);
    }
    return null;
  };

  const getFoc = async () => {
    if(flowmeterType === undefined) // Due to rendering order this function gets called with undefined flowmeterType first, ignore that
      return;

    const latestGeneratorEngineData = await getLatestGeneratorEngineData();
    if (!latestGeneratorEngineData) return;

    //min date time in all generators
    const latestDataObject = latestGeneratorEngineData.reduce((a, c) => {
      return dayjs(c.dateTime).isAfter(dayjs(a.dateTime)) ? a : c;
    }, latestGeneratorEngineData[0]);
    const latestDate = latestDataObject?.dateTime;

    // calc generator foc
    let geFailure = [];
    let latestFOCvalue = 0;
    let prevFOCvalue = 0;

    for (let i = 0; i < latestGeneratorEngineData.length; i++) {
      const prevGEData = await getGeneratorEngineData(latestDate, latestGeneratorEngineData[i].generatorNumber);
      if (
        prevGEData === undefined ||
        prevGEData?.genData[0]?.gefoInFlowCount < 0 || 
        prevGEData?.genData[0]?.gefoOutFlowCount < 0 || 
        latestGeneratorEngineData[i]?.gefoInFlowCount < 0 ||
        latestGeneratorEngineData[i]?.gefoOutFlowCount < 0
      ) {
        geFailure.push(Number(latestGeneratorEngineData[i].generatorNumber));
        continue; // if the counter of a generator fails, ignore this generator
      };

      latestFOCvalue += 
        latestGeneratorEngineData[i]?.gefoInFlowCount - 
        latestGeneratorEngineData[i]?.gefoOutFlowCount;
      prevFOCvalue += 
        prevGEData?.genData[0]?.gefoInFlowCount - 
        prevGEData?.genData[0]?.gefoOutFlowCount;
    };
    geFailure.sort(function(a, b) {return a - b});

   
    const foc = {
      geTotal: latestFOCvalue - prevFOCvalue,
      latestDate,
      geFailure
    };

    // unmount.current || insertFoc(foc, engineData.startDate, engineData.endDate);
    try {
      unmount.current ||
        setCurrentFoc(
          Object.assign(
            {},
            ...Object.entries(foc).map(([k, v]) =>
              k === "geTotal" ? { [k]: v * flowmeterType} : { [k]: v }
            )
          )
        );
    } catch (e) {
      logger.error(`getFoc error ${e}`);
      throw e;
    }
  };

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  useEffect(() => {
    getFoc();
  }, [selectedFocDuration, vesselId, flowmeterType]);

  useInterval(
    () => {
      if (selectedFocDuration?.duration === 2) {
        getFoc();
      }
    },
    60 * 10 * 1000,
    false
  ); // automatic update for 10 interval for 2hours FOC

  return {
    currentFoc,
  };
};
