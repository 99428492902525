import { fetchVesselLatestMainEngineData } from "../../api/latestData";

export const useEngineDataOnce = async (vesselId) => {
  const fetchLatestData = async () => {
    const data = await fetchVesselLatestMainEngineData(vesselId).catch(() => {
      return { engine: [], engineDataState: [] };
    });

    return {
      engine: data?.latestMainEngineData,
      engineDataState: data?.latestMainEngineData?.length ? data.latestMainEngineData : [],
    };
  };

  const data = await fetchLatestData();
  return data;
};
