export default {
  INVALID_DATE: "--:--",
  ROWS_PER_PAGE: 20,
  ALARM_DATE_FORMAT: "YYYY/MM/DD HH:mm",
  ALARM_DATE_DURATION_FORMAT: "YYYY/MM/DD",
  AMS_CHANNEL_COLUMN: "channelName",
  AMS_OPERATION_TYPE_COLUMN: "operationType",
  PAGE_BUTTON: {
    FORWARD: 1,
    BACK: 2,
  },
  ALARM_HEADER_ID: {
    ALARM_NAME: "channelName",
    VALUE: "value",
    OCCURRENCE: "dateTime",
    UPDATE: "updateDateTime",
    RECOVERY: "recoveryDate",
    ID: "channelNo",
    OPERATION_TYPE: "operationType",
    STATUS: "targetStatus",
    ACTION: "action",
    DATETIME: "dateTime",
    CATEGORY: "category",
    UNRESOLVEDALARMS: "UnresolvedAlarms",
    ALARMSTATUS: "alarmStatus",
  },
  ORDER: {
    DESC: "desc",
    ASC: "asc",
  },
  DURATION_TYPE: {
    OCCURRENCE: 1,
    RECOVERY: 2,
    DATETIME: 3,
  },
  DURATION_INITIAL_STATE: {
    occurrenceStart: "",
    occurrenceEnd: "",
    recoveryTimeStart: "",
    recoveryTimeEnd: "",
  },
  DURATION_INITIAL_STATE_DATETIME: {
    start: "",
    end: "",
  },
};
