import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import PortGaugeIcon from "../../../../../assets/images/cargo/load/roll/portGauge.svg";
import StbdGaugeIcon from "../../../../../assets/images/cargo/load/roll/stbdGauge.svg";
import ArrowIcon from "../../../../../assets/images/cargo/load/arrow.svg";
import { COLORS } from "../../../../constants/colors";
import { checkValueRangeForChart } from "../commonFunc";
import localizationKeys from "../../../../i18n/localizationKeys";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    color: COLORS.white,
  },
  gauge: {
    position: "relative",
    textAlign: "right",
    width: "48px",
    marginRight: "1px",
  },
  minMaxLabel: {
    position: "absolute",
    fontSize: "10px",
    marginTop: "-15px",
    width: "110px",
  },
  value: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -18px)",
    textAlign: "center",
  },
  arrow: {
    position: "absolute",
    width: "97px",
    height: "97px",
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
    marginTop: "-57px",
  },
}));

export const Chart = memo((props) => {
  const { roll } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const range = 15;
  const checkedValue = checkValueRangeForChart(roll, range);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <div
            className={classes.arrow}
            style={{
              transform: `rotate(${180 - checkedValue * 5}deg)`,
            }}
          >
            <img src={ArrowIcon} width={25} height={25} />
          </div>
        </div>
        <div className={classes.value}>
          {isNaN(roll) ? (
            <div style={{ fontSize: "14px" }}>{t(localizationKeys.NoLatestDataForChart)}</div>
          ) : (
            <>
              <div
                style={{
                  fontSize: "16px",
                  color: roll < 0 ? "#FF5252" : "#64E771",
                }}
              >
                {(roll > 0 ? "+" : "") + roll.toFixed(2)}
              </div>
              <div style={{ fontSize: "10px" }}>DEG</div>
            </>
          )}
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            opacity: isNaN(roll) ? 0.5 : 1,
          }}
        >
          <div className={classes.minMaxLabel}>{`-${range}°`}</div>
          <div
            className={classes.gauge}
            style={{
              opacity: roll < 0 ? 1 : 0.3,
            }}
          >
            <img
              src={PortGaugeIcon}
              width={48}
              height={38}
              style={{
                transform: "rotate(1deg)",
              }}
            />
          </div>
          <div
            className={classes.gauge}
            style={{
              opacity: roll > 0 ? 1 : 0.3,
            }}
          >
            <img
              src={StbdGaugeIcon}
              width={48}
              height={38}
              style={{
                transform: "rotate(1deg)",
              }}
            />
          </div>
          <div className={classes.minMaxLabel} style={{ textAlign: "right" }}>
            {`+${range}°`}
          </div>
        </div>
      </Grid>
    </Grid>
  );
});

Chart.propTypes = {
  roll: PropTypes.number,
};
