import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Modal, Typography, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { EmailSetting } from "./emailSetting";
import { ThresholdsSetting } from "./thresholdsSetting";
import { COLORS } from "../../../constants/colors";
import { CustomSwitch } from "./customSwitch";
import { CustomBlueButton } from "./customButton";
import CloseIcon from "../../../../assets/images/alarmIcon/close-icon.svg";
import CheckIcon from "../../../../assets/images/cargo/chart/check.png";
import { NoSaveConfirmationModal } from "./modalNoSaveConfirmation";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys";
import { getChannelThresholdData, postChannelThresholdData } from "../../../api/channel";
import { THRESHOLD_API_KEYS } from "../../../constants/channel/channelAlert";
import { getAlertSettings, postAlertSettings } from "../../../api/alert";
import { getShortUuid } from "../../../api/vessel";
import { NoMatchConfirmationModal } from "./modalNoMatchConfirmationModal";

const useStyles = makeStyles({
  container: {
    background: COLORS.componentBackColor,
    color: COLORS.white,
    width: "800px",
    height: "calc(100dvh - 120px)",
    position: "absolute",
    right: "20px",
    bottom: "10px",
    padding: "15px",
    borderRadius: "10px",
    overflowY: "hidden",
  },
  contentContainer: {
    padding: "10px",
  },
  header: {
    height: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  footer: {
    height: "50px",
    margin: "0 15px",
    alignItems: "end",
  },
  sliderWithLabel: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 0 0 15px",
  },
  buttonHolder: {
    width: "300px",
    margin: "0 auto",
  },
});

export const ModalContent = React.forwardRef((props, ref) => {
  const { onRequestClose, vesselId, channelNo } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  // remove 0 head of channelNo.
  const chNo = String(Number(channelNo));
  const [isLoading, setIsLoading] = useState(true);
  const [initState, setInitState] = useState();
  const [lowThresholdList, setLowThresholdList] = useState([]);
  const [highThresholdList, setHighThresholdList] = useState([]);
  const [lowChecked, setLowChecked] = useState([]);
  const [highChecked, setHighChecked] = useState([]);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [isOpenNoMatchConfirmation, setIsOpenNoMatchConfirmation] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);
  const [isThresholdActive, setIsThresholdActive] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onClickSaveButton = async () => {
    if (
      lowThresholdList.length !== new Set(lowThresholdList).size ||
      highThresholdList.length !== new Set(highThresholdList).size
    ) {
      setIsOpenNoMatchConfirmation(true);
    } else {
      await saveProcess();
    }
  };

  const saveProcess = async () => {
    setIsSaving(true);
    const newThresholdSettings = [
      { key: THRESHOLD_API_KEYS.MIN, value: min },
      { key: THRESHOLD_API_KEYS.MAX, value: max },
      ...lowThresholdList.map((v, i) => ({ key: `L${i + 1}`, value: v })),
      ...highThresholdList.map((v, i) => ({ key: `H${i + 1}`, value: v })),
    ];
    const channelThresholdPostData = {
      chNo,
      thresholds: newThresholdSettings,
    };
    const newAlertThresholds = [];
    if (isThresholdActive) {
      lowChecked.forEach((v, i) => {
        if (v) {
          newAlertThresholds.push(`L${i + 1}`);
        }
      });
      highChecked.forEach((v, i) => {
        if (v) {
          newAlertThresholds.push(`H${i + 1}`);
        }
      });
    }
    // remove blank email
    const postEmailList = emailList.filter((email) => email !== "");
    const alertThresholdPostData = {
      channelNo: chNo,
      emails: postEmailList,
      notificationTypes: [0],
      alertThresholds: newAlertThresholds,
    };
    const shortUuid = (await getShortUuid(vesselId)).shortuuid;
    await postChannelThresholdData(shortUuid, channelThresholdPostData);
    await postAlertSettings(shortUuid, alertThresholdPostData);
    setIsSaving(false);
    onRequestClose(true);
  };

  const onClickCloseButton = () => {
    if (isLoading) {
      onRequestClose(true);
    }
    const compareObject = {
      lowThresholdList,
      highThresholdList,
      emailList,
      lowChecked: isThresholdActive ? lowChecked : Array(lowThresholdList.length).fill(false),
      highChecked: isThresholdActive ? highChecked : Array(highThresholdList.length).fill(false),
    };
    const isChange = JSON.stringify(compareObject) !== JSON.stringify(initState);
    if (isChange) {
      setIsOpenConfirmation(true);
    } else {
      setIsOpenConfirmation(false);
      onRequestClose(true);
    }
  };

  const mappingChannelAPIData = (channelData) => {
    const lowArray = [];
    const highArray = [];
    if (!channelData) return { lowArray, highArray };
    const keys = Object.keys(channelData);
    keys.forEach((key) => {
      if (key === THRESHOLD_API_KEYS.MIN) {
        setMin(channelData[key]);
      } else if (key === THRESHOLD_API_KEYS.MAX) {
        setMax(channelData[key]);
      } else if (key.includes(THRESHOLD_API_KEYS.L)) {
        lowArray.push(channelData[key]);
      } else if (key.includes(THRESHOLD_API_KEYS.H)) {
        highArray.push(channelData[key]);
      }
    });
    setLowThresholdList(lowArray);
    setHighThresholdList(highArray);

    return { lowArray, highArray };
  };

  const mappingAlertAPIData = (alertData, lowArray, highArray) => {
    if (!alertData || alertData?.length === 0) return;
    const lowCheckedList = Array(lowArray.length).fill(false);
    const highCheckedList = Array(highArray.length).fill(false);
    const lowAlert = alertData["alertThresholds"].filter((v) => v.includes(THRESHOLD_API_KEYS.L));
    const highAlert = alertData["alertThresholds"].filter((v) => v.includes(THRESHOLD_API_KEYS.H));
    lowAlert?.forEach((e) => {
      lowCheckedList[Number(e.slice(-1)[0]) - 1] = true;
    });
    highAlert?.forEach((e) => {
      highCheckedList[Number(e.slice(-1)[0]) - 1] = true;
    });
    setEmailList([...alertData["emails"]]);
    setLowChecked([...lowCheckedList]);
    setHighChecked([...highCheckedList]);
  };

  useEffect(() => {
    const getThresholdData = async () => {
      const { channelThresholdData } = await getChannelThresholdData(vesselId, chNo);
      const { alertSettings } = await getAlertSettings(vesselId, chNo);
      const { lowArray, highArray } = mappingChannelAPIData(channelThresholdData?.thresholds);
      mappingAlertAPIData(alertSettings[0], lowArray, highArray);
      setIsLoading(false);
    };
    getThresholdData();
  }, []);

  useEffect(() => {
    if (isLoading) return;
    setInitState({ lowThresholdList, highThresholdList, emailList, lowChecked, highChecked });
    const isDataExist = lowChecked.includes(true) || highChecked.includes(true);
    setIsThresholdActive(isDataExist);
  }, [isLoading]);

  return (
    <Grid container className={classes.container} ref={ref}>
      <Grid item xs={12} className={classes.header}>
        <Typography>{t(localizationKeys.ChannelOptions)}</Typography>
        <img
          src={CloseIcon}
          height={25}
          width={25}
          style={{ cursor: "pointer" }}
          onClick={() => onClickCloseButton()}
        />
      </Grid>
      {isLoading ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <>
          <Grid container style={{ height: "calc(100dvh - 225px)", overflowY: "auto", }}>
            <Grid item xs={6} className={classes.contentContainer}>
              <Grid item xs={12}>
                <div>{t(localizationKeys.ConnectNotification)}</div>
                <div className={classes.sliderWithLabel}>
                  <div>{t(localizationKeys.ThresholdReached)}</div>
                  <div>
                    <CustomSwitch
                      checked={isThresholdActive}
                      onChange={() => {
                        setIsThresholdActive(!isThresholdActive);
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <EmailSetting
                lowThresholdList={lowThresholdList}
                highThresholdList={highThresholdList}
                lowChecked={lowChecked}
                setLowChecked={setLowChecked}
                highChecked={highChecked}
                setHighChecked={setHighChecked}
                emailList={emailList}
                setEmailList={setEmailList}
                isThresholdActive={isThresholdActive}
              />
            </Grid>
            <Grid item xs={6} className={classes.contentContainer}>
              <ThresholdsSetting
                min={min}
                setMin={setMin}
                max={max}
                setMax={setMax}
                lowThresholdList={lowThresholdList}
                setLowThresholdList={setLowThresholdList}
                highThresholdList={highThresholdList}
                setHighThresholdList={setHighThresholdList}
                lowChecked={lowChecked}
                setLowChecked={setLowChecked}
                highChecked={highChecked}
                setHighChecked={setHighChecked}
                validationError={validationError}
                setValidationError={setValidationError}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.footer}>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <div className={classes.buttonHolder}>
                <CustomBlueButton
                  disabled={validationError || isSaving}
                  onClick={() => {
                    onClickSaveButton();
                  }}
                >
                  {
                    isSaving
                    ? <CircularProgress size={24}/>
                    : <>
                        <img src={CheckIcon} style={{ opacity: validationError ? 0.3 : 1 }} />
                        <span>{t(localizationKeys.SaveChanges)}</span>
                      </>
                  }
                </CustomBlueButton>
              </div>
            </Grid>
          </Grid>
          <Modal
            open={isOpenConfirmation}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <NoSaveConfirmationModal
              onRequestClose={onRequestClose}
              setIsOpenConfirmation={setIsOpenConfirmation}
            />
          </Modal>
          <Modal
            open={isOpenNoMatchConfirmation}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <NoMatchConfirmationModal
              onRequestClose={onRequestClose}
              setIsOpenNoMatchConfirmation={setIsOpenNoMatchConfirmation}
              saveProcess={saveProcess}
              isSaving={isSaving}
            />
          </Modal>
        </>
      )}
    </Grid>
  );
});

ModalContent.propTypes = {
  onRequestClose: PropTypes.func,
  vesselId: PropTypes.string,
  channelNo: PropTypes.string,
};
