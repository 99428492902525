//Setters
const setShownList = (state, action) => {
  state.shownList = action.payload;
};
const setDelayFlag = (state, action) => {
  state.delayFlag = action.payload;
};

export default {
  setShownList,
  setDelayFlag,
};
