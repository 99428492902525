import { axios } from "./axiosSettings";
import { getAuthHeader } from "../helpers/session";

export const fetchFleetAlarmData = async (vesselIds) => {
  try {
    const { data } = await axios.post(
      `/api/alarm/fleetdata`,
      {
        vesselIds,
      },
      {
        headers: getAuthHeader(),
      }
    );
    return { fleetAlarmData: data };
  } catch (e) {
    throw { fetchFleetAlarmData: e };
  }
};

export const fetchVesselAlarmData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/api/alarm/data`, {
      params: {
        vesselId,
      },
      headers: getAuthHeader(),
    });
    return { alarmData: data };
  } catch (e) {
    throw { fetchVesselAlarmData: e };
  }
};

export const fetchVesselAlarmHistoryData = async (vesselId) => {
  try {
    const { data } = await axios.get("/api/alarmhistory/data", {
      params: {
        vesselId,
        order: "dateTime:desc",
      },
      headers: getAuthHeader(),
    });
    return { alarmHistoryData: data };
  } catch (e) {
    throw { fetchVesselAlarmHistoryData: e };
  }
};

export const fetchVesselAlarmOperationData = async (vesselId) => {
  try {
    const { data } = await axios.get("/api/alarmoperation/data", {
      params: {
        vesselId,
        order: "dateTime:desc",
      },
      headers: getAuthHeader(),
    });
    return { alarmOperationData: data };
  } catch (e) {
    throw { fetchVesselAlarmOperationData: e };
  }
};
