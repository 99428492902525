import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

//Design imports
import withStyles from '@mui/styles/withStyles';
import { Grid } from "@mui/material";

//Component imports
import AlarmListTable from "./monitoringList.jsx";

//Localization Imports
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  currentAlarmSelector,
  filteredAlarmSelector,
  setCurrentAlarmLoading,
  getSearchButtonSelector,
} from "../../../model/slice/alarmSlice.js";
import { fetchCurrentAlarm } from "../../../model/async/alarmAsync.js";
import { getComparator } from "../utils/helper.js";
import constants from "../utils/constants.js";
import { FETCH_INTERVAL } from "../../../constants/constants.js";

const styles = (theme) => ({
  circleStatus: {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    marginLeft: "10px",
    marginRight: "10px",
    display: "inline-block",
    [theme.breakpoints.only("sm")]: {
      height: "35px",
      width: "35px",
      marginLeft: "8px",
      marginRight: "8px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "24px",
      width: "24px",
      marginLeft: "8px",
      marginRight: "8px",
    },
  },
  categoryHolder: {
    minHeight: "140px",
    backgroundColor: "#2c3a48 !important",
    borderRadius: "8px !important",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      minHeight: "88px",
    },
  },
  categoryBlock: {
    textAlign: "center",
  },
  categoryTitle: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "center",
    flexDirection: "row",
    alignContent: "sretch",
    color: "white",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Inter",
    [theme.breakpoints.only("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
    },
  },
  categoryIcon: {
    height: "40px",
    [theme.breakpoints.only("sm")]: {
      height: "35px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "18px",
    },
  },
  vesselTitleHolder: {
    display: "flex",
    alignItems: "center",
  },
  vesselStatusTitle: {
    color: "white",
    fontSize: "24px",
    fontWeight: "500",
    fontFamily: "Inter",
    marginRight: "8px",
    [theme.breakpoints.only("sm")]: {
      fontSize: "16px",
    },
  },
  shipNameTitle: {
    color: "#19b2ab !important",
  },
  alarmTableTitle: {
    marginTop: "38px",
  },
  vesselTitleAlarmStatus: {
    width: "70px",
    height: "70px",
    marginLeft: "20px",
  },
});

const AlarmPanel = React.memo((props) => {
  const { height } = props;
  const vesselId = props.vesselId;
  const filteredCurrentAlarmList = useSelector(filteredAlarmSelector);
  const currentAlarmList = useSelector(currentAlarmSelector);
  const searchButtonFlag = useSelector(getSearchButtonSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCurrentAlarm({ vesselId }))
    const interval = setInterval(() => dispatch(fetchCurrentAlarm({ vesselId })), FETCH_INTERVAL);
    dispatch(setCurrentAlarmLoading(true));

    return () => {
      clearInterval(interval);
    }
  }, []);

  const filterList = useMemo(() => {
    let arrayForSort;
    if (searchButtonFlag) {
      arrayForSort = [...filteredCurrentAlarmList];
    } else {
      arrayForSort = [...currentAlarmList];
    }
    return arrayForSort.sort(getComparator("asc", constants.ALARM_HEADER_ID.OCCURRENCE));
  }, [searchButtonFlag, currentAlarmList, filteredCurrentAlarmList]);

  return (
    <Grid
      container
      alignItems="stretch"
      style={{ display: "flex", overflowX: "hidden" }}
      justifyContent="center"
    >
      <Grid container>
        <Grid item xs={12}>
          <AlarmListTable alarmList={filterList} vesselId={vesselId} height={height} />
        </Grid>
      </Grid>
    </Grid>
  );
});

AlarmPanel.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  layoutStyle: PropTypes.object,
  vesselName: PropTypes.string,
  vesselId: PropTypes.string,
  height: PropTypes.number,
};

export default withStyles(styles)(withTranslation()(AlarmPanel));
