import { GetCIIOverTime, GetSyntheticaVessels, GetVesselPerformance, GetVoyages } from '../../api/synthetica';
import { useMemo, useState } from "react";
import { SEAJAPAN_TEST_VESSEL, TEST_VESSEL } from '../../constants/synthetica';

const emptyOutput = {
    isLoading: false,
    hasData: false,
    vesselStatus: [],
    ciiOverTime: [],
    vesselPerformance: [],
    voyageHistory: [],
}

export const useSyntheticaData = ({ vesselName }) => {
    const [synthData, setSynthData] = useState({...emptyOutput, isLoading: true});
    let isStgTestVessel = false;

    const fetchData = async () => {
        if(vesselName == null){
            return;
        }

        if (vesselName.toUpperCase() === SEAJAPAN_TEST_VESSEL) {
          vesselName = TEST_VESSEL;
          isStgTestVessel = true;
        }

        const vesselData = await GetSyntheticaVessels();
        const foundVessel = vesselData.find((v) => v.vessel_name?.toLowerCase() == vesselName.toLowerCase());
        if(foundVessel == null){
            setSynthData(emptyOutput);
            return;
        }

        const allData = await Promise.all([
            GetCIIOverTime(foundVessel.vessel_name),
            GetVesselPerformance(foundVessel.vessel_name),
            GetVoyages(foundVessel.vessel_name),
        ]).catch(() => {
            setSynthData(emptyOutput);
        });

        if(allData == undefined){
            return;
        }

        if (isStgTestVessel) {
          allData[1].vessel_name = SEAJAPAN_TEST_VESSEL;
        }

        const output = {
            isLoading: false,
            hasData: true,
            vesselStatus: foundVessel,
            ciiOverTime: allData[0],
            vesselPerformance: allData[1],
            voyageHistory: allData[2],
        };

        setSynthData(output);
    }
    useMemo(() => {
        fetchData();
    }, [vesselName]);

    return synthData;
}
