import { withStyles } from "@mui/styles";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { difference, isEqual } from "lodash";

import { ENGINE_CHART_TYPE } from "../../../../constants/trendGraph/enginesLine";
import localizationKeys from "../../../../i18n/localizationKeys";
import { setDataDuration, setElectricDataDuration } from "../../../../model/slice/dataDurationSlice";
import dayjs from "../../../../util/dayjs-init.js";
import { DATE_FORMAT } from "../../../../constants/constants";
import { engineSelect } from "../../../../util/monitoring/monitoring.js";

import EngineTrendGraph from "../../../trendPage/engine/index";
import { sepDurationInitialState } from "../../../../constants/duration.js";
import { ElectricPanel } from "../../../trendPage/electric/index.jsx";

const styles = (theme) => ({
  container: {
    display: "flex",
    maxHeight: "272px",
    "& .MuiGrid-item": {
      paddingTop: "0px !important",
      paddingLeft: "0px !important",
    },
    "& .MuiPaper-root": {
      height: "240px !important",
      minHeight: "50px !important",
    },
  },
  titleContainer: {
    marginBottom: "16px",
    fontSize: "11px",
  },
  legendContainer: {
    fontSize: "11px",
    marginTop: "16px",
    columnCount: "2",
    columnFill: "auto",
    height: "210px"
  },
  legendLineContainer: {
    display: "flex",
    gap: '8px',
    marginBottom: "8px"
  },
  rectContainer: {
    display: "flex",
    alignItems: "center",
  },
  rect: {
    width: "10px",
    height: "2px",
    flexGrow: 0,
    borderRadius: "1px",
  },
  legend: {
    minWidth: '110px',
    maxWidth: '110px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100px',
      maxWidth: '100px',
    }
  },
  value: {
    width: '50px',
    textAlign: 'right',
  },
});

const isArrayEqual = (arr1, arr2) => {
  return difference(arr1, arr2, isEqual)?.length === 0;
}

const DisplayLegends = forwardRef(function DisplayLegends({ isSepVessel, classes }, ref) {
  const [_data, _setData] = useState([]);

  useImperativeHandle(ref, () => ({
    setData (data) {
      if (!isArrayEqual(data, _data)) {
        _setData(data);
      }
    }
  }), []);

  return _data?.length > 0 && _data.map((t, i) => (
    <div key={i} className={classes.legendLineContainer}>
      <div className={classes.rectContainer}><div className={classes.rect} style={{ backgroundColor: t.color }} /></div>
      <div className={classes.legend}>{t.name}</div>
      <div className={classes.value}>{t.value !== null && t.value !== undefined ? t.value + (isSepVessel ? "kW" : "°C") : "--"}</div>
    </div>
  ));
});

const DisplayTooltipData = ({ dataFetchStatus, isSepVessel, chart, classes }) => {
  const [latestData, setLatestData] = useState([]);
  const cursor = chart.get("cursor");
  const legendRef = useRef(null);

  const setWithLatestData = () => {
    legendRef.current?.setData(latestData);
  }

  const pointerOutEvent = (e) => {
    const xAxis = e.target.get("xAxis");
    const positionX = e.target.getPrivate("positionX");
    const x = xAxis.toAxisPosition(positionX);

    const tmp = [];
    chart.series.each((series) => {
      const item = xAxis.getSeriesItem(series, x);
      const valueYField = series.get("valueYField");

      tmp.push({
        color: series.get("stroke").toString(),
        name: series.get("name").toString(),
        value: item.dataContext[valueYField]
      });
    });

    if (tmp.length > 0) {
      legendRef.current?.setData(tmp);
    }
  };

  if (!cursor.events.isDisposed()) {
    cursor.events.off("pointerover", pointerOutEvent);
    cursor.events.on("pointerover", pointerOutEvent);
  }

  useEffect(() => {
    setWithLatestData();
  }, [dataFetchStatus, latestData]);

  useEffect(() => {
    const tmp = [];
    chart.series.each((series) => {
      const latestData = series.data.values[series.data.length-1];
      const valueYField = series.get("valueYField");

      tmp.push({
        color: series.get("stroke").toString(),
        name: series.get("name").toString(),
        value: latestData[valueYField]
      });
    });

    setLatestData(tmp);
  }, [chart.series])

  return <DisplayLegends ref={legendRef} isSepVessel={isSepVessel} classes={classes} />;
}

const Display = ({ vessel, isSepVessel, classes, idx, engine, t }) => {
  const [graphData, setGraphData] = useState();
  const [dataFetchStatus, setDataFetchStatus] = useState({});
  const [chartRef, setChartRef] = useState(null);

  useEffect(() => {
    if (chartRef?.current) {
      chartRef.current.dispose();
    }
    setChartRef(null);
  }, [vessel.id]);

  return (
    <Grid container className={ `${classes.components} ${classes.container}` }>
      <Grid item xs={6}>
        <div className={classes.titleContainer}>
          {isSepVessel ? `${t(localizationKeys.ElectricData_lower)}` : `${t(localizationKeys.Engine_lower)} ${idx+1} - ${t(localizationKeys.CylinderDeviation_lower)}`} (24 hours)
        </div>
        {
          isSepVessel ?
          <ElectricPanel
            vesselId={vessel.id}
            currentVessel={vessel}
            graphData={graphData}
            setGraphData={setGraphData}
            setDataFetchStatus={setDataFetchStatus}
            toggleVariables={false}
            isVesselOverview={true}
            setChartRef={setChartRef}
          /> :
          <EngineTrendGraph
            vesselId={vessel.id}
            currentVessel={vessel}
            selectedChart={ENGINE_CHART_TYPE.ENGINE_DEVIATION}
            selectedEngine={engine.position}
            graphData={graphData}
            setGraphData={setGraphData}
            setDataFetchStatus={setDataFetchStatus}
            toggleVariables={false}
            isVesselOverview={true}
            setChartRef={setChartRef}
            overviewTrendIdx={idx}
          />
        }
      </Grid>
      <Grid item xs={6}>
        {t(localizationKeys.CurrentStatus)}:
        {chartRef &&
          <div className={classes.legendContainer}><DisplayTooltipData dataFetchStatus={dataFetchStatus} isSepVessel={isSepVessel} chart={chartRef} classes={classes} /></div>
        }
      </Grid>
    </Grid>
  )
}

const Trend = ({ vessel, isSepVessel, style, classes, t }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSepVessel) {
      dispatch(setElectricDataDuration(sepDurationInitialState));
    } else {
      dispatch(
        setDataDuration({
          startDate: dayjs.utc()
            .subtract(1, "d")
            .format(DATE_FORMAT),
          endDate: dayjs.utc()
            .format(DATE_FORMAT)
        })
      );
    }
  }, [vessel.id, isSepVessel]);

  const engines = engineSelect(t, vessel.NoME);

  return isSepVessel ?
    <Display vessel={vessel} classes={{...style, ...classes}} isSepVessel={isSepVessel} t={t} /> :
    engines.map((engine, i) => (
      <Display vessel={vessel} classes={{...style, ...classes}} idx={i} engine={engine} key={i} t={t} />
    ));
}

Trend.propTypes = {
  vessel: PropTypes.object,
  isSepVessel: PropTypes.bool,
  style: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(Trend);