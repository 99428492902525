import Sunny from "../../../assets/images/cargo/navigation/weather/sunny.svg";
import Cloudy from "../../../assets/images/cargo/navigation/weather/cloudy.svg";
import HeavyFog from "../../../assets/images/cargo/navigation/weather/heavyFog.svg";
import Snow from "../../../assets/images/cargo/navigation/weather/snow.svg";
import HeavyRain from "../../../assets/images/cargo/navigation/weather/heavyRain.svg";
import Thunderstorm from "../../../assets/images/cargo/navigation/weather/thunderstorm.svg";

const WeatherIcon = (weatherCode = 0) => {
  switch (weatherCode) {
    case 0:
      return Sunny;
    case 1:
    case 2:
    case 3:
      return Cloudy;
    case 45:
    case 48:
      return HeavyFog;
    case 51:
    case 53:
    case 55:
    case 56:
    case 57:
    case 61:
    case 63:
    case 65:
    case 66:
    case 67:
      return HeavyRain;
    case 71:
    case 73:
    case 75:
    case 77:
      return Snow;
    case 80:
    case 81:
    case 82:
      return HeavyRain;
    case 85:
    case 86:
      return Snow;
    case 95:
    case 96:
    case 99:
      return Thunderstorm;
    default:
      return Sunny;
  }
};

export default WeatherIcon;
