import { VESSEL_TYPE } from "../constants/constants";
import { isVesselOffline } from "../constants/util";
import { SHIP_GROUP_TYPE, SHIP_TYPE } from "../constants/vesselShipType";
import VesselIcon from "../components/vesselImages/vesselIcon";

export default class SortUtil {
  //sort the list
  sortFunc(pre, later) {
    const preName = pre.vessel_name.toUpperCase();
    const laterName = later.vessel_name.toUpperCase();
    if (preName < laterName) return -1;
    if (preName > laterName) return 1;
    return 0;
  }

  vesselListSort(navigationData, engineData) {
    const copyList = [...navigationData.fleetNavigationData];
    const connectedList = copyList
      .filter(
        (e) =>
          !isVesselOffline(
            e,
            engineData?.find((d) => d.vessel_id === e.vessel_id)
          )
      )
      .sort(this.sortFunc);
    const disconnectedList = copyList
      .filter((e) =>
        isVesselOffline(
          e,
          engineData?.find((d) => d.vessel_id === e.vessel_id)
        )
      )
      .sort(this.sortFunc);
    const sortedList = connectedList.concat(disconnectedList);
    return sortedList;
  }

  vesselOffline(vesselList, currentSelectedVessel, engineData) {
    const sortedList = this.vesselListSort(vesselList, engineData);
    return (
      sortedList?.map((d) => {
        const isOffline = isVesselOffline(
          d,
          engineData?.find((e) => d.vessel_id === e.vessel_id)
        );
        return {
          vessel_name: d.vessel_name,
          vessel_id: d.vessel_id,
          isOffline,
          vessel_firstClass: d.vessel_firstClass,
          vesselType: SHIP_GROUP_TYPE[SHIP_TYPE[d?.vesselLatestAisData[0]?.shipType]],
          vesselIcon: VesselIcon(
            d?.vesselType === VESSEL_TYPE.SEP ? 52 : d?.vesselLatestAisData[0]?.shipType ?? 0,
            0,
            1,
            isOffline
          ),
          alarmData: d.vesselAlarmData,
          isSelected: d.vessel_id === currentSelectedVessel,
        };
      }) ?? []
    );
  }
}
