import React from "react";
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { ENGINE_POSITION } from "../../constants/trendGraph/enginesLine";
import localizationKeys from "../../i18n/localizationKeys";
import { withTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  radio: {
    color: "#19b2ab !important",
  },
  radioLabel: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    "& .MuiTypography-body1": {
      fontSize: "8px !important",
    },
  },
}));

const engineList = [
  {
    position: ENGINE_POSITION.PORT,
    label: localizationKeys.PortEngine,
  },
  {
    position: ENGINE_POSITION.CENTER,
    label: localizationKeys.CenterEngine,
  },
  {
    position: ENGINE_POSITION.STARBOARD,
    label: localizationKeys.StarboardEngine,
  },
];

const getEngines = (noME) => {
  let index = [];
  switch (noME) {
    case 3:
      index = [0, 1, 2];
      break;
    case 2:
      index = [0, 2];
      break;
    default:
      index = [1];
  }
  return engineList.filter((e, i) => index.includes(i));
};

const ComparisonEngineSelector = (props) => {
  const { vesselSettings, selectedEngine, engineSelect, t } = props;
  const classes = useStyles();
  //when vessel have 1 engine, not show the engine select button
  if (vesselSettings?.NoME === 1) return <></>;
  return (
    <RadioGroup>
      {
        <RadioGroup
          name="engine-select-radio-group"
          defaultValue={vesselSettings?.NoME === 1 ? ENGINE_POSITION.CENTER : ENGINE_POSITION.PORT}
          value={selectedEngine}
          onChange={(e) => engineSelect(e.target.value)}
          row={true}
        >
          {getEngines(vesselSettings?.NoME).map((d, i) => (
            <FormControlLabel
              key={`engine-radio-${i}`}
              control={<Radio className={classes.radio} size="small" />}
              label={`${t(d.label)}`}
              value={d.position}
              className={classes.radioLabel}
            />
          ))}
        </RadioGroup>
      }
    </RadioGroup>
  );
};

export default withTranslation()(ComparisonEngineSelector);
