import React from "react";
import PropTypes from "prop-types";

export const MobileSortIcon = ({ width = 15, height = 17, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.494 10.44a.43.43 0 0 0 .312-.125.408.408 0 0 0 .128-.303V4.885l2.11 2.143c.086.084.19.128.312.134a.377.377 0 0 0 .312-.134l.404-.393a.408.408 0 0 0 .128-.303.408.408 0 0 0-.128-.304l-3.56-3.464A.43.43 0 0 0 7.2 2.44a.43.43 0 0 0-.312.125l-3.56 3.464a.408.408 0 0 0-.128.304c0 .119.043.22.129.303l.403.393c.086.096.19.14.312.134a.465.465 0 0 0 .312-.134l2.11-2.143v5.125c0 .12.043.22.129.304a.43.43 0 0 0 .312.125h.587zM12.506 9.64a.43.43 0 0 0-.312.125.408.408 0 0 0-.128.304v5.125l-2.11-2.143a.465.465 0 0 0-.312-.134.378.378 0 0 0-.312.134l-.404.393a.408.408 0 0 0-.128.303c0 .12.043.22.128.304l3.56 3.464a.43.43 0 0 0 .312.125.43.43 0 0 0 .312-.125l3.56-3.464a.408.408 0 0 0 .128-.304c0-.119-.043-.22-.129-.303l-.403-.393a.378.378 0 0 0-.312-.134.465.465 0 0 0-.312.134l-2.11 2.143v-5.125a.408.408 0 0 0-.129-.304.43.43 0 0 0-.312-.125h-.587z"
      fill={color}
    />
  </svg>
);

MobileSortIcon.prototype = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
