import React, { memo } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../../constants/colors";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys";
import { Title } from "../title";
import { useSelector } from "react-redux";
import { isChannelModFetching } from "../../../model/slice/channelSlice";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
  },
  time: {
    height: "40px",
  },
  left: {
    // margin: 'auto',
  },
  right: {
    // paddingLeft: "20px",
  },
  table: {
    "& thead tr td": {
      fontSize: "12px",
      color: COLORS.white,
      height: "18px",
    },
    "& tbody tr": {
      fontSize: "12px",
      height: "20px",
    },
    "& tbody tr td:first-child": {
      color: COLORS.white,
      width: "150px",
    },
    "& tbody tr td:not(:first-child)": {
      color: COLORS.greenishBlue,
      width: "80px",
    },
  },
}));

export const Details = memo((props) => {
  const { data, isVesselOverview } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const isLoading = useSelector(isChannelModFetching);
  const pipeArea = Math.PI * (199 / 2) ** 2;

  const flowRate = data.find((value) => value.tag === "O9");
  const flowSpeed = ((10000 * flowRate?.inputData) / (36 * pipeArea)).toFixed(1);
  const density = data.find((value) => value.tag === "O10");
  const outsideTemp = data.find((value) => value.tag === "T51");
  const tempOfInner = data.find((value) => value.tag === "T29");
  const tempOfOuter = data.find((value) => value.tag === "T26");
  const tankCoverInnerTemp = data.find((value) => value.tag === "T31");
  const cargoTankPress = data.find((value) => value.tag === "P47");
  const cargoTankTemp100 = data.find((value) => value.tag === "T32");
  const cargoTankTemp50 = data.find((value) => value.tag === "T33");
  const cargoTankTemp40 = data.find((value) => value.tag === "T34");
  const cargoTankTemp25 = data.find((value) => value.tag === "T35");
  const press1 = data.find((value) => value.tag === "P1");
  const press2 = data.find((value) => value.tag === "P2");
  const press5 = data.find((value) => value.tag === "P5");
  const press6 = data.find((value) => value.tag === "P6");
  const press8 = data.find((value) => value.tag === "P8");
  const pressureLossUnloading1 = (Number(press8?.inputData) - Number(press1?.inputData)).toFixed(2);
  const pressureLossUnloading2 = (Number(press8?.inputData) - Number(press2?.inputData)).toFixed(2);
  const pressureLossLoading1 = (Number(press1?.inputData) - Number(press5?.inputData)).toFixed(2);
  const pressureLossLoading2 = (Number(press2?.inputData) - Number(press5?.inputData)).toFixed(2);
  const pressureLossLoading3 = (Number(press2?.inputData) - Number(press6?.inputData)).toFixed(2);
  const pressureLossLoading4 = (Number(press2?.inputData) - Number(press6?.inputData)).toFixed(2);

  const checkValue = (value) => {
    if (isNaN(Number(value?.inputData))) {
      return t(localizationKeys.NoLatestData);
    }
    return `${value.inputData} ${value.unit}`;
  };

  return (
    <Grid container className={classes.container}>
      {!isVesselOverview && <Title title={t(localizationKeys.CargoStatus)} />}
      {isLoading ? (
        <CircularProgress style={{ margin: "0 auto" }} />
      ) : (
        <>
          <Grid item xs={5} className={classes.left}>
            <table className={classes.table} style={{ margin: isVesselOverview ? "auto" : "0 20px 0 auto" }}>
              <thead>
                <tr>
                  <td></td>
                  <td>{`No.2 ${t(localizationKeys.Tank)}`}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t(localizationKeys.ShieldInnerSurfaceTemp)}</td>
                  <td>{checkValue(tempOfInner)}</td>
                </tr>
                <tr>
                  <td>{t(localizationKeys.ShieldOuterSurfaceTemp)}</td>
                  <td>{checkValue(tempOfOuter)}</td>
                </tr>
                <tr>
                  <td>{t(localizationKeys.TankCoverInnerSurfaceTemp)}</td>
                  <td>{checkValue(tankCoverInnerTemp)}</td>
                </tr>
                <tr>
                  <td>{t(localizationKeys.CargoTankPress)}</td>
                  <td>{checkValue(cargoTankPress)}</td>
                </tr>
                <tr>
                  <td>{`${t(localizationKeys.CargoTankTemp)}(100%)`}</td>
                  <td>{checkValue(cargoTankTemp100)}</td>
                </tr>
                <tr>
                  <td>{`${t(localizationKeys.CargoTankTemp)}(50%)`}</td>
                  <td>{checkValue(cargoTankTemp50)}</td>
                </tr>
                <tr>
                  <td>{`${t(localizationKeys.CargoTankTemp)}(40%)`}</td>
                  <td>{checkValue(cargoTankTemp40)}</td>
                </tr>
                <tr>
                  <td>{`${t(localizationKeys.CargoTankTemp)}(25%)`}</td>
                  <td>{checkValue(cargoTankTemp25)}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={7} className={classes.right}>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td>{t(localizationKeys.FlowRate)}</td>
                  <td>{checkValue(flowRate)}</td>
                </tr>
                <tr>
                  <td>{t(localizationKeys.FlowSpeed)}</td>
                  <td>{checkValue({ inputData: flowSpeed, unit: "m/s" })}</td>
                </tr>
                <tr>
                  <td>{t(localizationKeys.Density)}</td>
                  <td>{checkValue(density)}</td>
                </tr>
                <tr>
                  <td>{t(localizationKeys.AMBTemperature)}</td>
                  <td>{checkValue(outsideTemp)}</td>
                </tr>
              </tbody>
            </table>
            <table className={classes.table}>
              <thead>
                <tr>
                  <td></td>
                  <td>{`ΔMPaG`}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>P8 - P1</td>
                  <td>P8 - P2</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {`${t(localizationKeys.PressureLoss)} (${t(localizationKeys.CargoUnloading)})`}
                  </td>
                  <td>{checkValue({ inputData: pressureLossUnloading1, unit: "MPa" })}</td>
                  <td>{checkValue({ inputData: pressureLossUnloading2, unit: "MPa" })}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <td></td>
                  <td>P1 - P5</td>
                  <td>P2 - P5</td>
                  <td>P1 - P6</td>
                  <td>P2 - P6</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {`${t(localizationKeys.PressureLoss)} (${t(localizationKeys.CargoLoading)})`}
                  </td>
                  <td>{checkValue({ inputData: pressureLossLoading1, unit: "MPa" })}</td>
                  <td>{checkValue({ inputData: pressureLossLoading2, unit: "MPa" })}</td>
                  <td>{checkValue({ inputData: pressureLossLoading3, unit: "MPa" })}</td>
                  <td>{checkValue({ inputData: pressureLossLoading4, unit: "MPa" })}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </>
      )}
    </Grid>
  );
});

Details.propTypes = {
  data: PropTypes.array,
  isVesselOverview: PropTypes.bool,
};
