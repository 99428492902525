export const DEFAULT_LINE_COLORS = [
  "#b4affb",
  "#8f81ff",
  "#66c0f6",
  "#84eeff",
  "#56ebc1",
  "#911eb4",
  "#e6beff",
  "#f553ec",
  "#0a84e3",
  "#e74293",
  "#fc78a8",
  "#a29bfe",
  "#6c5de7",
  "#ff0000",
  "#57cee1",
  "#56ebc1",
];
