import React from "react";
import PropTypes from "prop-types";

export const ArrowRightIcon = ({ width = 15, height = 15 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="#19b2ab"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.8 7.71c0 .049.018.091.052.126a.174.174 0 0 0 .128.052h10.397l-2.322 2.315a.218.218 0 0 0-.045.134c0 .05.015.09.045.12l.3.298a.22.22 0 0 0 .135.045c.05 0 .09-.015.12-.045l3.13-3.136c.04-.03.06-.07.06-.119 0-.05-.02-.09-.06-.12l-3.13-3.135a.162.162 0 0 0-.12-.045.22.22 0 0 0-.135.045l-.3.298a.161.161 0 0 0-.045.12c0 .05.015.094.045.134l2.322 2.315H1.98a.174.174 0 0 0-.128.052.173.173 0 0 0-.052.127v.418z"
      fill="#19B2AB"
    />
  </svg>
);

ArrowRightIcon.prototype = {
  width: PropTypes.number,
  height: PropTypes.number,
};
