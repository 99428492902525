import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCharts } from "../../api/charts";

export const fetchChartsAsync = createAsyncThunk("charts", async ({ vesselId }) => {
  const { charts } = await fetchCharts(vesselId);
  return charts;
});

export const fulfilledFetchChartsAsync = (state, action) => {
  state.charts = action.payload;
  state.isChartsFetching = false;
};

export const rejectedFetchChartsAsync = (state, action) => {
  state.isChartsFetching = false;
};

export const pendingFetchChartsAsync = (state, action) => {
  state.isChartsFetching = true;
};
