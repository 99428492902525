import Cookies from "universal-cookie";
import { COOKIE_AUTH_KEY } from "../constants/constants.js";

export const getAuthHeader = () => {
  const cookies = new Cookies();
  const authToken = cookies.get(COOKIE_AUTH_KEY);
  return { Authorization: `Bearer ${authToken}` };
};

export const getAuthToken = () => {
  const cookies = new Cookies();
  const authToken = cookies.get(COOKIE_AUTH_KEY);

  return authToken;
};
