import { useEffect, useRef, useState } from "react";
import { fetchVessel } from "../../api/vessel";

export const useVesselSettings = ({ vesselId }) => {
  const [vesselSettings, setVesselSettings] = useState({
    // for testing in Izumi case
    // METype: 4,
    // NoMe: 2,
    // NoDG: 3,
    // MEEngineType: "",
    // bore: 300,
    // stroke: 475,
    // cycle: 4,
    // cylinder: 12,
    // meMaxKw: 8700,
    // normalMeMaxKw: -1,
    // meMaxRpm: 600,
    // normalMeMaxRpm: -1,
    // meMinRpm: 100,
    // geMaxKw: 1000,
  });
  const unmount = useRef(false);

  const fetchVesselData = async () => {
    const { vessel } = await fetchVessel(vesselId);
    // console.log({vessel, error})
    // if (vessel === undefined || vessel === {} || error !== undefined) return;
    setVesselSettings(() => ({
      ...vessel,
    }));
  };

  useEffect(() => {
    unmount.current || fetchVesselData();
  }, [vesselId]);

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  return {
    vesselSettings,
  };
};
