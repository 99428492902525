export const VALIDATION_ERROR = {
  MIN: "min",
  MAX: "max",
  L: "L",
  H: "H",
};

export const THRESHOLD_API_KEYS = {
  MIN: "MIN",
  MAX: "MAX",
  L: "L",
  H: "H",
};
