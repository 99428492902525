import React, { useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import localizationKeys from "../../../../i18n/localizationKeys.js";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Popover, Typography } from "@mui/material";
import constants from "../../utils/constants.js";
import DurationCalendar from "./durationCalendar.jsx";

const OccurrenceFilter = ({ disabled = false, classes, t, minDate }) => {
  return (
    <div className={classes.itemForSearchDate}>
      <Typography
        variant="body2"
        className={clsx(
          disabled ? classes.disableColumnName : classes.columnName,
          classes.occurrenceColumn
        )}
      >
        {t(localizationKeys.Occurrence_lower)}
      </Typography>
      <DurationCalendar
        minDate={minDate}
        type={constants.DURATION_TYPE.OCCURRENCE}
        disabled={disabled}
      />
    </div>
  );
};

OccurrenceFilter.propTypes = {
  t: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  minDate: PropTypes.object,
};

export default withTranslation()(OccurrenceFilter);
