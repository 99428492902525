import React, { useEffect, useMemo } from "react";
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useEngineData } from "./useEngineDataList";
import EngineTrendBase from "./engineTrendBase.jsx";
import { useDetectVesselConnection } from "../../../hooks/useDetectVesselConnection";
import { useDurationHandle } from "../calendarDuration/useDurationHandle.js";
import { DURATION } from "../calendarDuration/durationOption";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType";
import { useNavigationData } from "./useNavigationData";

const EngineTrendGraph = ({
  t = null,
  vesselId = null,
  selectedChart,
  selectedEngine,
  currentVessel,
  setSelectedEngine,
  graphData,
  setGraphData,
  setDataFetchStatus,
  layoutStyle,
  trendCharts,
  toggleVariables,
  isVesselOverview,
  overviewTrendIdx,
  setChartRef,
}) => {
  const { connections } = useDetectVesselConnection();
  const isDisconnected = connections.includes(vesselId);
  const { engineValues, isLoading, isNoData } = useEngineData({
    vesselId,
    position: selectedEngine,
    isVesselOverview,
  });
  const { navigationValues } = useNavigationData({
    vesselId,
  });

  const passedValues = useMemo(() => {
    return { ...engineValues, ...navigationValues };
  }, [engineValues, navigationValues]);

  useEffect(() => {
    setDataFetchStatus({
      isLoading,
      isNoData,
    });
  }, [isLoading, isNoData]);

  // initial load trigger
  useDurationHandle(DURATION.ENGINE, selectedEngine);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EngineTrendBase
            selectedEngine={selectedEngine}
            setSelectedEngine={setSelectedEngine}
            layoutStyle={layoutStyle}
            values={passedValues}
            isDurationDisabled={isLoading}
            isNoData={isNoData}
            isDisconnected={isDisconnected}
            vesselId={vesselId}
            vessel={currentVessel}
            isLoading={isLoading}
            selectedChart={selectedChart}
            graphType={GRAPH_TYPE.ENGINE}
            graphData={graphData}
            setGraphData={setGraphData}
            customLineData={trendCharts?.[selectedChart]}
            toggleVariables={toggleVariables}
            isVesselOverview={isVesselOverview}
            setChartRef={setChartRef}
            overviewTrendIdx={overviewTrendIdx}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

EngineTrendGraph.propTypes = {
  t: PropTypes.func.isRequired,
  vesselId: PropTypes.string.isRequired,
  selectedChart: PropTypes.string,
  selectedEngine: PropTypes.string,
  isVesselOverview: PropTypes.bool,
  layoutStyle: PropTypes.object,
  setChartRef: PropTypes.func,
  overviewTrendIdx: PropTypes.number,
};

export default withTranslation()(EngineTrendGraph);
