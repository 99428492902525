import React from "react";
import PropTypes from "prop-types";

export const ArrowDownIcon = ({ width = 15, height = 15 }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#s4seu8awwa)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.888.96c-.026 0-.049.01-.067.028a.093.093 0 0 0-.028.068v5.545L2.558 5.363a.116.116 0 0 0-.071-.024.086.086 0 0 0-.064.024l-.16.16a.117.117 0 0 0-.023.072c0 .026.008.048.024.064l1.672 1.67A.076.076 0 0 0 4 7.36c.026 0 .048-.01.063-.032l1.673-1.67a.087.087 0 0 0 .024-.063.117.117 0 0 0-.024-.072l-.16-.16a.086.086 0 0 0-.063-.024.116.116 0 0 0-.072.024L4.207 6.6V1.056c0-.027-.01-.05-.028-.068A.092.092 0 0 0 4.111.96h-.223z"
        fill="#19B2AB"
      />
    </g>
    <defs>
      <clipPath id="s4seu8awwa">
        <path fill="#fff" transform="rotate(-180 4 4)" d="M0 0h8v8H0z" />
      </clipPath>
    </defs>
  </svg>
);

ArrowDownIcon.prototype = {
  width: PropTypes.number,
  height: PropTypes.number,
};
