import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import * as PropTypes from "prop-types";

const baseToggle = {
  height: "23px",
  width: "47px",
  border: "none",
  color: "#ffffff",
  "&:hover": {
    // backgroundColor: "rgba(25, 178, 171, 0.4)",
    // backgroundColor: "#19b2ab",
  },
  "&.Mui-selected": {
    backgroundColor: "rgba(25, 178, 171, 0.5)",
  },
  "&.MuiToggleButton-label": {
    color: "rgba(25, 178, 171, 0.7)",
  },
};

const useStyles = makeStyles((theme) => ({
  toggleGroup: {
    backgroundColor: "rgba(25, 178, 171, 0.4)",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
  selectedToggle: {
    ...baseToggle,
    backgroundColor: "#19b2ab !important",
  },
  toggle: {
    ...baseToggle,
  },
  iconContainer: {
    width: "15px",
    height: "15px",
    "& svg": {
      transform: "scale(0.8) translate(-6px, -6px)",
    },
  },
  characterDesign: {
    textTransform: "none",
    color: "#ffffff",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
}));

const CustomToggleButton = ({ options = [], selectedOption, handler, style = null }) => {
  baseToggle.width = style?.width ?? "47px";
  baseToggle.height = style?.height ?? "23px";
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={selectedOption}
      exclusive
      aria-label="selectData"
      onChange={handler}
      className={classes.toggleGroup}
    >
      {options.map((d) => (
        <ToggleButton
          key={d.name}
          value={d}
          data-testid={d.dataTestId}
          className={selectedOption.name === d.name ? classes.selectedToggle : classes.toggle}
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            borderBottomLeftRadius: "8px",
          }}
        >
          {d.icon ? (
            <span className={classes.iconContainer}>{d.icon}</span>
          ) : (
            <span className={classes.characterDesign} style={{ color: "#ffffff" }}>
              {d.name}
            </span>
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

CustomToggleButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  selectedOption: PropTypes.object,
};

export default CustomToggleButton;
