import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFleetLatestNavigationData } from "../../api/fleet";

export const fetchVesselInformationAsync = createAsyncThunk(
  "vesselInformation/asyncGet",
  async ({ companyIds, vesselIds }) => {
    return await fetchFleetLatestNavigationData(companyIds, vesselIds);
  }
);

export const fetchVesselInformationFulfilled = (state, action) => {
  if (action.payload?.fleetLatestNavigationData) {
    state.fleetNavigationData = action.payload.fleetLatestNavigationData;
  }
};
