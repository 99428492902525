import React from "react";
import PropTypes from "prop-types";

export const ResetCircleIcon = ({ width = 15, height = 17, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6 5.447a4.994 4.994 0 0 0-4.662 3.157c-.1.257-.373.415-.64.342l-.392-.108a.47.47 0 0 1-.33-.61A6.399 6.399 0 0 1 10.6 4.04c3.526 0 6.4 2.827 6.4 6.333 0 3.506-2.874 6.334-6.4 6.334a6.407 6.407 0 0 1-5.742-3.533l-.4 1.144a.5.5 0 0 1-.684.287l-.34-.158a.5.5 0 0 1-.25-.646l1.256-3.01a.5.5 0 0 1 .658-.267l2.806 1.198a.5.5 0 0 1 .256.672l-.181.388a.5.5 0 0 1-.628.256l-1.215-.455A5 5 0 0 0 10.6 15.3c2.766 0 4.993-2.213 4.993-4.926 0-2.712-2.227-4.926-4.993-4.926z"
      fill={color}
    />
  </svg>
);

ResetCircleIcon.prototype = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
