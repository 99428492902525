import { ENGINE_POSITION } from "./enginesLine";

export const engineInitialSelector = (NoME) => {
  switch (NoME) {
    case 1:
      return ENGINE_POSITION.CENTER;
    case 2:
    case 3:
      return ENGINE_POSITION.PORT;
  }
};
