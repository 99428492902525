import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

//localization imports
import { withTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys.js";

const useStyles = makeStyles((theme) => ({
  tableHolder: {
    width: "100%",
  },
  columnHolder: {
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  mmsiInfo: {
    textAlign: "right",
  },
}));

const VesselInformationPanel = (props) => {
  const { t, isDisconnected } = props;
  const styles = useStyles();
  const classes = { ...styles, ...props.classes };
  const navLabel = isDisconnected ? classes.disconnectedNavLabel : classes.navLabel;

  return (
    <React.Fragment>
      <table className={classes.tableHolder}>
        <tbody>
          <tr>
            <td className={classes.columnHolder}>
              <div className={navLabel}>{t(localizationKeys.CallSign_upper)}</div>
              <div className={classes.navValue}>
                {!props.aisData.callSign ? "-" : props.aisData.callSign}
              </div>
            </td>
            <td className={`${classes.columnHolder} ${classes.mmsiInfo}`}>
              <div className={navLabel}>{t(localizationKeys.Mmsi_upper)}</div>
              <div className={classes.navValue}>
                {!props.aisData.mmsi ? "-" : props.aisData.mmsi}
              </div>
            </td>
          </tr>
          <tr className={classes.rowHolder}>
            <td className={classes.columnHolder}>
              <div className={navLabel}>{t(localizationKeys.VesselClass_upper)}</div>
              <div className={classes.navValue}>
                {!props.vesselData.firstClass ? "-" : props.vesselData.firstClass}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

VesselInformationPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
  isDisconnected: PropTypes.bool,
};

export default withTranslation()(VesselInformationPanel);
