import React, { memo } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { VolumeMarker } from "./marker.jsx";

// import { VolumeIcon } from "../../../../../assets/images/cargo/load/volume.svg";
import { MAX_TANK_CAPACITY } from "../../../../constants/cargo/constants.js";
import { COLORS } from "../../../../constants/colors.js";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../../i18n/localizationKeys.js";
import { Title } from "../../title.jsx";
import { convertNumber } from "../../pitchAndRoll/commonFunc.js";
import { useSelector } from "react-redux";
import { isChannelModFetching } from "../../../../model/slice/channelSlice.js";

const useStyles = makeStyles(() => ({
  container: {
    color: COLORS.white,
    backgroundColor: COLORS.tabSelectorColor,
    height: "100%",
    width: "100%",
  },
  markerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  volumeIcon: {
    position: "absolute",
    zIndex: 50,
  },
  tankMarker: {
    width: "96px",
    zIndex: 100,
  },
  topLabel: {
    textAlign: "center",
    fontSize: "15px",
    marginTop: "50px",
    marginBottom: "2px",
  },
  bottomLabel: {
    textAlign: "center",
    fontSize: "12px",
    backgroundColor: "rgba(11, 13, 16, 0.5)",
    marginTop: "15px",
  },
}));

export const Volume = memo((props) => {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const isLoading = useSelector(isChannelModFetching);

  const tank1Value = isNaN(convertNumber(data[0]?.inputData))
    ? `${t(localizationKeys.NoLatestDataForChart)}`
    : convertNumber(data[0]?.inputData);
  const tank2Value = isNaN(convertNumber(data[1]?.inputData))
    ? `${t(localizationKeys.NoLatestDataForChart)}`
    : convertNumber(data[1]?.inputData);
  return (
    <Grid container className={classes.container}>
      <Title title={t(localizationKeys.TankLevel)} />
      {isLoading ? (
        <CircularProgress style={{ margin: "0 auto" }} />
      ) : (
        <>
          <Grid item xs={6} className={classes.markerContainer}>
            <div className={classes.tankMarker}>
              <VolumeMarker fill={tank2Value} max={MAX_TANK_CAPACITY} />
              <div className={classes.topLabel}>{`No.2 ${t(localizationKeys.Tank)}`}</div>
              <div className={classes.bottomLabel}>{`${tank2Value} ${
                isNaN(tank2Value) ? "" : "mm"
              }`}</div>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.markerContainer}>
            <div className={classes.tankMarker}>
              <VolumeMarker fill={tank1Value} max={MAX_TANK_CAPACITY} />
              <div className={classes.topLabel}>{`No.1 ${t(localizationKeys.Tank)}`}</div>
              <div className={classes.bottomLabel}>{`${tank1Value} ${
                isNaN(tank1Value) ? "" : "mm"
              }`}</div>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
});

Volume.propTypes = {
  data: PropTypes.array,
};
