import engine from "../../components/mainPage/overview/monitoring/engine";
import { bhpCalcDict } from "../../constants/monitoring/bhpCalc"

export const calcBHP = (vesselId, engineData, NoME, meMaxKw, meMaxRpm, enginePositions) => {
  if (
    !Object.keys(bhpCalcDict).includes(vesselId)
    && (!NoME || NoME <= 0 || !meMaxKw || meMaxKw <= 0 || !meMaxRpm || meMaxRpm <= 0)
  ){
    return null;
  }

  // if (channels.length) bhp += channels.reduce((d) => ((d.value)*bhpCalcDict[vesselId][d.chNo]));
  if (engineData != undefined && engineData.length) {
    const bhp = {}
    enginePositions.forEach((p) => {
      const meSpd = engineData.filter((d) => d.position == p)[0]?.meSpeed < 0 ? 0 : engineData.filter((d) => d.position == p)[0]?.meSpeed;
      bhp[p] = Object.keys(bhpCalcDict).includes(vesselId) 
        ? meSpd**3 * bhpCalcDict[vesselId]["rpm"]
        : meSpd**3 * (meMaxKw/meMaxRpm**3);
    });

    return bhp;
  }

  return {};
}