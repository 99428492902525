import React, { memo, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from "react-redux";
import { setChartList } from "../../model/slice/chartSettingsSlice.js";
import { useDetectVesselConnection } from "../../hooks/useDetectVesselConnection.js";
import { NoData } from "../offlineComponent/noData.jsx";
import Offline from "../offlineComponent/offline.jsx";
import * as PropTypes from "prop-types";
import { fetchVessel } from "../../api/vessel.js";

import AmCharts from "./amcharts.jsx";
import { electricTrendLines } from "../../constants/trendGraph/electricLines.js";
import { setChartsSettings } from "../../model/slice/chartsSlice.js";

const disableUserSelect = {
  userSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
};

const useStyles = makeStyles((theme) => ({
  brush: {
    fontSize: "0px",
    position: "absolute",
    background: "#21262f",
    height: "20px",
    padding: "4px",
    "& rect": {
      stroke: "none",
      height: "20px",
      fillOpacity: "unset",
      fill: "#1b212a",
      rx: "2px",
    },
  },
  root: {},
  circle: {
    margin: "20px 20px",
    position: "relative",
    left: "calc(50% - 20px)",
    top: "calc(50% - 20px)",
  },
  noData: {
    color: "#FFFFFF",
  },
  containerRoot: {
    width: "100%",
    height: "100%",
    border: "solid white",
  },
  labelText: {
    "& tspan": {
      ...disableUserSelect,
    },
  },
}));

const HEIGHT = 600;

const TrendGraph = memo((props) => {
  const { geNumber = 0, sgNumber = 0, vesselId } = props;
  const dispatch = useDispatch();

  // electric chart settings
  useEffect(() => {
    const nameLists = [...new Array(geNumber)]
      .map((_, i) => `dg${i + 1}`)
      .concat([...new Array(sgNumber)].map((_, i) => `sg${i + 1}`));
    if (!nameLists.length) {
      return;
    }

    dispatch(
      setChartList(
        electricTrendLines?.filter(
          (d) =>
            d.name === "dateTime" || d.name === "localDateTime" || nameLists.includes(d?.keyName)
        )
      )
    );
  }, [geNumber, sgNumber]);

  const newProps = { ...props, vesselData: props.vessel };

  return <AmCharts {...newProps} />;
});

const DisplaySituation = React.memo((props) => {
  const {
    isInitialLoad,
    graphData = [],
    vesselId,
    isGraphDataLoading,
    height,
    isMockPreview = false,
    isNoData,
  } = props;
  const { connections } = useDetectVesselConnection();
  const classes = useStyles();
  switch (true) {
    case isInitialLoad || isGraphDataLoading:
      return (
        <div style={{ height: `100%` }}>
          <CircularProgress className={classes.circle} />
        </div>
      );
    case isNoData:
      return (
        <div style={{ height: `${height}px` }}>
          <NoData />
        </div>
      );
    case graphData?.length > 0 || isMockPreview:
      return <TrendGraph {...props} />;
    case connections.includes(vesselId):
      return (
        <div style={{ height: `${height}px` }}>
          <Offline />
        </div>
      );
    default:
      return (
        <div style={{ height: `100%` }}>
          <CircularProgress className={classes.circle} />
        </div>
      );
  }
});

const Chart = React.memo((props) => {
  const dispatch = useDispatch();
  if (!props.isVesselOverview) {
    dispatch(setChartsSettings(props.customChartLines));
  }
  return <DisplaySituation {...props} />;
});

Chart.propTypes = {
  expand: PropTypes.string,
  type: PropTypes.string,
  isNoData: PropTypes.bool,
  graphData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  enginePosition: PropTypes.string,
  chartType: PropTypes.string,
  vesselId: PropTypes.string,
  geNumber: PropTypes.number,
  sgNumber: PropTypes.number,
  isInitialLoad: PropTypes.bool,
  isComparison: PropTypes.bool,
  comparisonGraphIndex: PropTypes.number,
  vesssel: PropTypes.object,
};

export default Chart;
