import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fleetNavigationData } from "../model/slice/fleetNavigationDataSlice";
import { isVesselOffline } from "../constants/util";
import { fleetEngineData } from "../model/slice/fleetEngineDataSlice";

export const useDetectVesselConnection = () => {
  const unmount = useRef(false);
  const [connections, setConnection] = useState([]);
  const [latestUpdates, setLatestUpdates] = useState([]);
  const vesselInformation = useSelector(fleetNavigationData);
  const fleetData = useSelector(fleetEngineData);

  const checkConnections = () => {
    const disconnectedVessel =
      vesselInformation?.fleetNavigationData?.filter(
        (v) =>
          v === undefined ||
          isVesselOffline(
            v,
            fleetData?.find((f) => f.vessel_id === v.vessel_id)
          )
      ) ?? [];
    setConnection(disconnectedVessel.map((d) => d?.vessel_id));
    setLatestUpdates(
      disconnectedVessel.map((d) => ({
        vesselId: d.vessel_id,
        lastDateTime: d?.vesselLatestGpggaData[0]?.dateTime,
      }))
    );
  };

  useEffect(() => {
    // if there are internet connection, but vessel data is old more 10 minutes.
    // Check data condition each vessel latest data updated
    navigator.onLine && checkConnections();
  }, [vesselInformation, fleetData]);

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  return {
    connections,
    latestUpdates,
  };
};
