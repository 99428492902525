import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

//localization imports
import { withTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys.js";

const useStyles = makeStyles((theme) => ({
  windTable: {
    width: "100%",
  },
  windSpeed: {
    width: "20%",
  },
  windDirection: {
    textAlign: "center",
  },
  vesselOgSpeed: {
    textAlign: "right",
  },
}));

const WindPanel = (props) => {
  const { t, isDisconnected } = props;
  const styles = useStyles();
  const classes = { ...styles, ...props.classes };
  const navLabel = isDisconnected ? classes.disconnectedNavLabel : classes.navLabel;

  return (
    <React.Fragment>
      <table className={classes.windTable}>
        <tbody>
          <tr>
            <td className={classes.windSpeed}>
              <div className={navLabel}>{t(localizationKeys.WindSpeed_upper)}</div>
              <div className={classes.navValue}>
                {props.windSpeed === undefined ? "-" : `${props.windSpeed} m/s`}
              </div>
            </td>
            <td className={classes.windDirection}>
              <div className={navLabel}>{t(localizationKeys.WindDirection_upper)}</div>
              <div className={classes.navValue}>
                {props.windDirection === undefined ? "-" : `${props.windDirection} °`}
              </div>
            </td>
            <td className={classes.vesselOgSpeed}>
              <div className={navLabel}>{t(localizationKeys.VesselOgSpeed_upper)}</div>
              <div className={classes.navValue}>
                {props.vesselOgSpeed === undefined ? "-" : `${props.vesselOgSpeed} knots`}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

WindPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  windSpeed: PropTypes.number,
  windDirection: PropTypes.number,
  vesselOgSpeed: PropTypes.number,
  t: PropTypes.func.isRequired,
  isDisconnected: PropTypes.bool,
};

export default withTranslation()(WindPanel);
