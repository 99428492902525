import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
  setSelectedVessel,
  selectedVesselId,
  fleetNavigationData,
} from "../../model/slice/fleetNavigationDataSlice";
import { reset, setCurrentChannelPage } from "../../model/slice/channelSlice";
import AlarmTopNavigation from "./navigation/top.jsx";
import { useDispatch, useSelector } from "react-redux";
import { ChannelList } from "./list.jsx";
import { TabSelector } from "../tabSelector/tabSelector.jsx";
import localizationKeys from "../../i18n/localizationKeys";
import { MobileListBottomNavigation } from "../common/listUtil/mobileListBottom.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#ffffff",
    paddingTop: "10px",
    [theme.breakpoints.down('md')]: {
      justifyContent: "center",
    },
  },
  tabHeaderSpace: {
    marginLeft: "0px",
    [theme.breakpoints.down('sm')]: {
      marginTop: "-90px",
    },
  },
  table: {
    padding: "0px 8px !important",
    marginRight: "24px",
    [theme.breakpoints.down('md')]: {
      overflow: "auto",
      marginTop: "180px",
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "10px",
      height: "calc(100dvh - 190px)",
    },
  },
}));

const commonTabs = (t) => [
  {
    name: t(localizationKeys.AllChannel_lower),
    id: "allChannels",
  },
];

const ChannelPage = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const vesselId = useSelector(selectedVesselId);
  const fleetNavData = useSelector(fleetNavigationData);
  const [filterWord, setFilterWord] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [containerHeight, setContainerHeight] = useState((window.innerHeight ?? 380) - 230);
  const [currentVesselId, setCurrentVesselId] = useState(null);

  const clearList = () => {
    setCurrentVesselId(null);
    dispatch(setSelectedVessel(null));
    dispatch(reset());
  };

  useEffect(() => {
    const vesselPath = location.pathname.split("/")?.[2] ?? null;
    if (vesselPath) {
      dispatch(setSelectedVessel(vesselPath));
    } else {
      clearList();
    }

    const handleResize = () => {
      setContainerHeight(window.innerHeight - 230);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentVesselId(vesselId);
    dispatch(setCurrentChannelPage(0));
  }, [vesselId]);

  useEffect(() => {
    if (fleetNavData?.fleetNavigationData?.length === 1) {
      setCurrentVesselId(fleetNavData.fleetNavigationData[0].vessel_id);
    }
  }, [fleetNavData]);

  return (
    <>
      {isSmUp && (
        <TabSelector tabs={commonTabs(t)} selectedOne={"allChannels"} clickHandler={() => {}} />
      )}
      <div className={styles.root}>
        {/* Favorites is not implemented yet */}
        <Grid container spacing={2} className={styles.tabHeaderSpace} alignItems="center">
          {isSmUp && (
            <AlarmTopNavigation
              filterWord={filterWord}
              setFilterWord={setFilterWord}
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              vesselId={currentVesselId}
            />
          )}
          <Grid item xs={12} className={styles.table}>
            <DisplayContent
              filterWord={filterWord}
              filterStatus={filterStatus}
              vesselId={currentVesselId}
              containerHeight={containerHeight}
            />
          </Grid>
          {!isSmUp && (
            <MobileListBottomNavigation
              filterWord={filterWord}
              setFilterWord={setFilterWord}
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              vesselId={currentVesselId}
            />
          )}
        </Grid>
      </div>
    </>
  );
};

const DisplayContent = (props) => {
  const { filterWord, filterStatus, vesselId, containerHeight } = props;
  return (
    <ChannelList
      filterValue={filterWord}
      filterStatus={filterStatus}
      vesselId={vesselId}
      height={containerHeight}
    />
  );
};

export { ChannelPage };
