import React, { useEffect, useState, useRef } from "react";
import { Grid, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import { useElectricData } from "./useElectricDataList";
import { useDurationHandle } from "../calendarDuration/useDurationHandle.js";
import { DURATION } from "../calendarDuration/durationOption";
import { NAVIGATION_BAR_HEIGHT } from "../../../layout/panelLayout.jsx";
import Chart from "../../chart/chart.jsx";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType";

const paper = (theme) => ({
  backgroundColor: "rgba(44, 58, 72, 0.3)",
});

const useStyles = makeStyles((theme) => ({
  paper: {
    ...paper(theme),
    backgroundColor: "inherit",
    boxShadow: "none",
    height: `calc(100dvh - ${NAVIGATION_BAR_HEIGHT}px - 200px)`,
  },
  container: {
    width: "100% !important",
  },
}));

export const ElectricPanel = (props) => {
  const classes = useStyles();
  const { vesselId = null, currentVessel, trendCharts, setGraphData, setDataFetchStatus, toggleVariables, 
    isVesselOverview,
    setChartRef, } = props;
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const setValueOrDefault = (val) => (val > 0 ? val : 0);

  const [ge, setGe] = useState(setValueOrDefault(currentVessel?.NoDG));
  const [sg, setSg] = useState(setValueOrDefault(currentVessel?.NoSG));
  const { graphData, isLoading, isNoData } = useElectricData({
    vesselId: currentVessel?.id,
    ge,
    sg,
  });
  const screenshotRef = useRef(null);

  useEffect(() => {
    setDataFetchStatus({
      isLoading,
      isNoData,
    });
  }, [isLoading, isNoData]);

  useEffect(() => {
    setIsInitialLoad(true);
  }, [vesselId]);
  useEffect(() => {
    setGe(setValueOrDefault(currentVessel?.NoDG));
    setSg(setValueOrDefault(currentVessel?.NoSG));
  }, [currentVessel]);

  useEffect(() => {
    const isFetchingData = graphData?.length === 0 && !isNoData;
    const hasGenerators = ge > 0 || sg > 0;
    setIsInitialLoad((isFetchingData && hasGenerators) || isLoading);
    setGraphData(graphData);
  }, [graphData, isNoData, isLoading, ge, sg]);

  // initial load trigger
  useDurationHandle(DURATION.ELECTRIC, null);

  return (
    <Grid spacing={2} container className={classes.container}>
      <Grid item xs={12} ref={screenshotRef}>
        <Paper className={classes.paper} id="trendGraph">
          <Chart
            isInitialLoad={isInitialLoad}
            isGraphDataLoading={isLoading}
            graphData={graphData}
            vesselId={vesselId}
            geNumber={ge}
            sgNumber={sg}
            isNoData={isNoData}
            type={GRAPH_TYPE.ELECTRIC}
            customLineData={trendCharts?.[GRAPH_TYPE.ELECTRIC]}
            toggleVariables={toggleVariables}
            isVesselOverview={isVesselOverview}
            setChartRef={setChartRef}
            vessel={currentVessel}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

ElectricPanel.propTypes = {
  vesselId: PropTypes.string,
  currentVessel: PropTypes.object,
  trendCharts: PropTypes.array,
  setGraphData: PropTypes.func,
  isVesselOverview: PropTypes.bool,
  setChartRef: PropTypes.func,
};
