import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import homeLogo from "../../../assets/images/defaults/connectlogo.png";
import background from "../../../assets/images/defaults/background.png";
import infoceanus from "../../../assets/images/defaults/infoceanus.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { LoginAzure } from "./loginAzure.jsx";
import { COLORS } from "../../constants/colors";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100dvh",
    backgroundSize: "cover",
    padding: "0 5rem",
  },
  header: {
    width: "100%",
    display: "flex",
    padding: "2.5rem 0",
    fontSize: "2rem",
    borderBottom: "1px solid lightGray",
  },
  homePage: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    textAlign: "center",
  },
  homeLogo: {
    textAlign: "center",
    position: "absolute",
    top: "44%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  connectLogo: {
    maxHeight: "50%",
    maxWidth: "50%",
  },
  loginWindow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    marginTop: "3rem",
    padding: "40px 20px",
  },
  windowFields: {
    marginBottom: "3rem",
    fontSize: "16px",
  },
  windowItems: {
    width: "580px",
    height: "56px",
  },
  loginButton: {
    width: "580px",
    height: "56px",
    background: COLORS.loginButton,
  },
  forgetPass: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  inqsq: {
    fontSize: "14px",
    width: "730px",
    height: "310px",
    margin: "20px 0 0",
    padding: "24px 25px 18px 44px",
    whiteSpace: "pre-line",
    borderRadius: "5px",
    backgroundColor: COLORS.navyBlue80,
    color: "white",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      height: "100%",
    },
  },
  inqsqbtn: {
    marginTop: "10px",
    whiteSpace: "pre-line",
    backgroundColor: COLORS.black80,
    color: "white",
    height: "auto",
    width: "200px",
    "&:hover": {
      background: COLORS.black80,
      filter: "brightness(1.2)",
    },
    textTransform: "none",
  },
  infoceanus: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    [theme.breakpoints.down('lg')]: {
      alignItems: "center",
    },
  },
  infoceanusimg: {
    width: "201px",
    height: "109px",
    marginRight: "-45px",
    [theme.breakpoints.down('lg')]: {
      paddingTop: "10px",
      marginRight: 0,
    },
  },
  infoceanusHomeLink: {
    width: "121px",
    height: "29px",
  },
  inqsqicon: {
    whiteSpace: "pre-line",
    color: "white",
    display: "inline-flex",
    verticalAlign: "middle",
  },
  backlink: {
    display: "block",
    textDecoration: "none",
    color: "#fff",
    padding: "0",
    "&:hover": {
      background: COLORS.black80,
      filter: "brightness(1.2)",
    },
  },
  welcome: {
    fontSize: "27px",
    whiteSpace: "pre-line",
    color: "white",
  },
  contractHolder: {
    fontSize: "14.5px",
    marginTop: "27px",
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const homeBg = {
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundImage: `url(${background})`,
  };

  return (
    <div className={classes.homePage} style={homeBg}>
      <div className={classes.homeLogo}>
        <img className={classes.connectLogo} src={homeLogo} />
        <div className={classes.inqsq}>
          <div className={classes.welcome}>{"Welcome to connect"}</div>
          <div className={classes.contractHolder}>
            ご契約済みの方はこちら / Contract holders click here:
          </div>
          <label className="photo-file">
            <LoginAzure />
          </label>
          {`
          
          
          `}
          {`サービスを利用するためには別途契約が必要です。お手数ですが下記宛先までお問い合わせください。
          A separate contract is required to use this service. Please contact the address below.
          `}
          <label className="photo-file">
            <Button className={classes.inqsqbtn} component="div">
              <Link className={classes.backlink} to="/purchaseinq">
                <div className={classes.inqsqicon}>
                  <MailOutlineIcon className={classes.collections} />
                </div>
                &nbsp;お問合せ / Contact us
              </Link>
            </Button>
          </label>
          <div className={classes.infoceanus}>
            <a
              target="_blank"
              className={classes.infoceanusimg}
              href="https://infoceanus.com"
              rel="noreferrer"
            >
              <img className={classes.infoceanusHomeLink} src={infoceanus} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoginPage };
