import NewNavIcon from "../../../assets/images/navIcon/nav-navigation-menu-button-on-vessel-35-x-35-3.svg";
import EngineTrendIcon from "../../../assets/images/navIcon/Nav_EngineTrendData35x35.svg";
import MonitoringIcon from "../../../assets/images/navIcon/Nav_MonitoringData35x35.svg";
import AlarmIcon from "../../../assets/images/navIcon/Nav_Alarm35x35.svg";

import { GRAPH_TYPE } from "../trendGraph/trendGraphType";
import { ENGINE_CHART_TYPE } from "../trendGraph/enginesLine";

export const NAV_LINK_ID = {
  navigation: "navigation",
  monitoring: "monitoring",
  trend: "trend",
  alarm: "alarm",
};

export const NAV_LINK_DATA = [
  {
    icon: NewNavIcon,
    alt: "navigation page link icon in navbar",
    path: "navigation",
    id: NAV_LINK_ID.navigation,
  },
  {
    icon: MonitoringIcon,
    alt: "monitoring page link icon in navbar",
    path: "monitoring",
    id: NAV_LINK_ID.monitoring,
  },
  {
    icon: EngineTrendIcon,
    alt: "engine page link icon in navbar",
    path: `trend/${GRAPH_TYPE.ENGINE}/${ENGINE_CHART_TYPE.ENGINE_LOAD}`,
    id: NAV_LINK_ID.trend,
  },
  {
    icon: AlarmIcon,
    alt: "alarm page link icon in navbar",
    path: "alarm",
    id: NAV_LINK_ID.alarm,
  },
];
