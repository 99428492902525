import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Input, Tooltip, Select, MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../../../constants/colors";
import constants from "../../utils/constants";
import { ThresholdSlider } from "./thresholdSlider";
import { sortFunc } from "../../utils/helper";
import { StartAdornmentContent } from "./startAdornmentContent";
import { EndAdornmentContent } from "./endAdornmentContent";
import { CustomBlueButton } from "../customButton";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../../i18n/localizationKeys";
import "../../../../../css/input.css";

const useStyles = makeStyles((theme) => ({
  container: {
    color: COLORS.white,
    paddingRight: "15px",
  },
  baseContainer: {},
  thresholdSliderHolder: {
    width: "300px",
    margin: "10px auto 50px auto",
  },
  inputHolder: {
    width: "300px",
    margin: "0 auto",
  },
  input: {
    color: COLORS.white,
    background: COLORS.baseColor,
    textAlign: "right",
    marginBottom: "5px",
    width: "100%",
  },
  inputStartAdornment: {
    height: "100%",
    width: "80px",
    padding: "5px",
    marginRight: "15px",
    borderRight: "solid 1px white",
    textAlign: "center",
  },
  inputEndAdornment: {
    width: "30px",
  },
  outline: {
    outline: "none",
  },
  sliderRoot: {
    color: "#059000",
  },
  sliderRail: {
    backgroundImage: `linear-gradient(to right, #6200ea, #6200ea 50%, #42a5f5 50%, #42a5f5)`,
    backgroundSize: "1px 2px",
    backgroundRepeat: "repeat",
  },
  sliderTrack: {
    backgroundImage: `linear-gradient(to right, #6200ea, #6200ea 50%, #42a5f5 50%, #42a5f5)`,
    backgroundSize: "1px 2px",
    backgroundRepeat: "repeat",
  },
  AddThresholdArea: {
    width: "300px",
    display: "flex",
    margin: "10px auto 0 auto",
  },
  buttonHolder: {
    width: "250px",
  },
  selectableThresholdAddButton: {
    width: "50px",
    height: '36.5px',
    background: COLORS.skyBlue,
    borderRadius: "2px",
    borderLeft: "solid 1px black",
  },
  option: {
    "&:hover": {
      background: COLORS.componentBackColor,
    },
  },
  icon: {
    color: COLORS.black,
    position: "absolute",
    right: "12px",
    transform: "scale(1.5) rotate(180deg)",
  },
  lowThresholdHolder: {
    display: "flex",
    flexFlow: "column-reverse",
  },
}));

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      background: COLORS.tabSelectorColor,
      color: COLORS.white,
      marginTop: "10px",
      padding: "0 3px",
      width: "200px",
      border: "solid 1px white",
    },
  },
};

export const ThresholdsSetting = (props) => {
  const {
    min,
    setMin,
    max,
    setMax,
    lowThresholdList,
    setLowThresholdList,
    highThresholdList,
    setHighThresholdList,
    lowChecked,
    setLowChecked,
    highChecked,
    setHighChecked,
    validationError,
    setValidationError,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [displayMin, setDisplayMin] = useState(min);
  const [displayMax, setDisplayMax] = useState(max);
  const [displayLowValues, setDisplayLowValues] = useState([...lowThresholdList]);
  const [displayHighValues, setDisplayHighValues] = useState([...highThresholdList]);
  const [minValidation, setMinValidation] = useState(false);
  const [maxValidation, setMaxValidation] = useState(false);
  const [borderValidation, setBorderValidation] = useState(false);
  const [lowErrorOpen, setLowErrorOpen] = useState(null);
  const [highErrorOpen, setHighErrorOpen] = useState(null);

  useEffect(() => {
    sortFunc(lowThresholdList, constants.ORDER.DESC);
    sortFunc(highThresholdList, constants.ORDER.ASC);
  }, []);

  const updateThresholdList = (order, target, updateFunction, value, index) => {
    const newArray = target.map((v, i) => (i === index ? Number(value) : v));
    if (order === "L") {
      if (
        validationError ||
        value < min ||
        (highThresholdList.length > 0 && highThresholdList.some((e) => e < value))
      ) {
        setLowErrorOpen(index);
        updateFunction(newArray);
        setTimeout(() => {
          setLowErrorOpen(null);
        }, 1000);
        return;
      }
      sortFunc(newArray, constants.ORDER.DESC);
    } else {
      if (
        validationError ||
        value > max ||
        (lowThresholdList.length > 0 && lowThresholdList.some((e) => e > value))
      ) {
        setHighErrorOpen(index);
        updateFunction(newArray);
        setTimeout(() => {
          setHighErrorOpen(null);
        }, 1000);
        return;
      }
      sortFunc(newArray, constants.ORDER.ASC);
    }
    updateFunction(newArray);
  };

  const onClickHandler = () => {
    if (lowThresholdList.length <= highThresholdList.length) {
      setLowThresholdList([...lowThresholdList, min]);
    } else {
      setHighThresholdList([...highThresholdList, max]);
    }
  };

  const onChangeHandler = (value) => {
    if (value === "L") {
      setLowThresholdList([...lowThresholdList, min]);
    } else {
      setHighThresholdList([...highThresholdList, max]);
    }
  };

  const validateLowInputValue = (value) => value < min || value >= highThresholdList[0];
  const validateHighInputValue = (value) => value > max || value <= lowThresholdList[0];

  useEffect(() => {
    setDisplayLowValues([...lowThresholdList]);
  }, [lowThresholdList]);

  useEffect(() => {
    setDisplayHighValues([...highThresholdList]);
  }, [highThresholdList]);

  useEffect(() => {
    const sortedLowThresholdList = sortFunc([...lowThresholdList], constants.ORDER.ASC);
    const sortedHighThresholdList = sortFunc([...highThresholdList], constants.ORDER.ASC);

    if (lowThresholdList.some((v) => v < min)) {
      setMinValidation(true);
    } else {
      setMinValidation(false);
    }
    if (highThresholdList.some((v) => v > max)) {
      setMaxValidation(true);
    } else {
      setMaxValidation(false);
    }
    if (sortedHighThresholdList[0] <= sortedLowThresholdList.slice(-1)[0]) {
      setBorderValidation(true);
    } else {
      setBorderValidation(false);
    }
  }, [lowThresholdList, highThresholdList, min, max]);

  useEffect(() => {
    setValidationError(maxValidation || minValidation || borderValidation);
  }, [maxValidation, minValidation, borderValidation]);

  return (
    <Grid className={classes.container}>
      {`${t(localizationKeys.SetThreshold)}:`}
      <div className={classes.baseContainer}>
        <div className={classes.thresholdSliderHolder}>
          <ThresholdSlider
            lowThresholdList={lowThresholdList}
            highThresholdList={highThresholdList}
            min={min}
            max={max}
          />
        </div>
        <div className={classes.inputHolder}>
          <Input
            className={classes.input}
            value={displayMin}
            onChange={(e) => setDisplayMin(e.target.value)}
            onBlur={(e) => setMin(Number(e.target.value))}
            startAdornment={
              <div className={classes.inputStartAdornment}>{t(localizationKeys.Min)}</div>
            }
            endAdornment={<div className={classes.inputEndAdornment} />}
          />
        </div>
        <div className={classes.lowThresholdHolder}>
          {lowThresholdList.map((v, index) => {
            return (
              <div className={classes.inputHolder} key={index}>
                <Tooltip
                  open={lowErrorOpen === index}
                  placement="left"
                  arrow
                  title={
                    v < min
                      ? t(localizationKeys.NotExceedMin)
                      : borderValidation
                      ? t(localizationKeys.NotExceedLow)
                      : ""
                  }
                >
                  <Input
                    className={`${classes.input} ${
                      validateLowInputValue(lowThresholdList[index]) ? "error" : null
                    }`}
                    value={displayLowValues[index]}
                    onMouseOver={() => {
                      if (validateLowInputValue(lowThresholdList[index])) setLowErrorOpen(index);
                    }}
                    onMouseLeave={() => {
                      if (lowErrorOpen === index) setLowErrorOpen(null);
                    }}
                    onChange={(e) =>
                      setDisplayLowValues(
                        displayLowValues.map((v, i) => (i === index ? e.target.value : v))
                      )
                    }
                    onBlur={(e) => {
                      updateThresholdList(
                        "L",
                        lowThresholdList,
                        setLowThresholdList,
                        e.target.value,
                        index
                      );
                    }}
                    startAdornment={
                      <StartAdornmentContent
                        order={"L"}
                        index={index}
                        value={v}
                        lowThresholdList={lowThresholdList}
                        setLowThresholdList={setLowThresholdList}
                        highThresholdList={highThresholdList}
                        setHighThresholdList={setHighThresholdList}
                      />
                    }
                    endAdornment={
                      <EndAdornmentContent
                        order={"L"}
                        index={index}
                        target={lowThresholdList}
                        secondTarget={lowChecked}
                        updateFunction={setLowThresholdList}
                        secondUpdateFunction={setLowChecked}
                      />
                    }
                  />
                </Tooltip>
              </div>
            );
          })}
        </div>
        {highThresholdList.map((v, index) => {
          return (
            <div className={classes.inputHolder} key={index}>
              <Tooltip
                open={highErrorOpen === index}
                placement="left"
                arrow
                title={
                  maxValidation
                    ? t(localizationKeys.NotExceedMax)
                    : borderValidation
                    ? t(localizationKeys.NotExceedHigh)
                    : ""
                }
              >
                <Input
                  className={`${classes.input} ${
                    validateHighInputValue(highThresholdList[index]) ? "error" : null
                  }`}
                  value={displayHighValues[index]}
                  onMouseOver={(e) => {
                    if (validateHighInputValue(highThresholdList[index])) setHighErrorOpen(index);
                  }}
                  onMouseLeave={() => {
                    if (highErrorOpen === index) setHighErrorOpen(null);
                  }}
                  onChange={(e) =>
                    setDisplayHighValues(
                      displayHighValues.map((v, i) => (i === index ? e.target.value : v))
                    )
                  }
                  onBlur={(e) => {
                    updateThresholdList(
                      "H",
                      highThresholdList,
                      setHighThresholdList,
                      e.target.value,
                      index
                    );
                  }}
                  startAdornment={
                    <StartAdornmentContent
                      order={"H"}
                      index={index}
                      value={v}
                      lowThresholdList={lowThresholdList}
                      setLowThresholdList={setLowThresholdList}
                      highThresholdList={highThresholdList}
                      setHighThresholdList={setHighThresholdList}
                    />
                  }
                  endAdornment={
                    <EndAdornmentContent
                      order={"H"}
                      index={index}
                      target={highThresholdList}
                      updateFunction={setHighThresholdList}
                      secondTarget={highChecked}
                      secondUpdateFunction={setHighChecked}
                    />
                  }
                />
              </Tooltip>
            </div>
          );
        })}
        <div className={classes.inputHolder}>
          <Input
            className={classes.input}
            value={displayMax}
            onChange={(e) => setDisplayMax(e.target.value)}
            onBlur={(e) => setMax(Number(e.target.value))}
            startAdornment={
              <div className={classes.inputStartAdornment}>{t(localizationKeys.Max)}</div>
            }
            endAdornment={<div className={classes.inputEndAdornment} />}
          />
        </div>
        <div className={classes.AddThresholdArea}>
          <div className={classes.buttonHolder}>
            <CustomBlueButton
              onClick={(e) => onClickHandler(e)}
              disabled={lowThresholdList.length >= 4 && highThresholdList.length >= 4}
            >
              {`+ ${t(localizationKeys.AddThreshold)}`}
            </CustomBlueButton>
          </div>
          <Select
            className={classes.selectableThresholdAddButton}
            classes={{ icon: classes.icon }}
            MenuProps={MenuProps}
            value={""}
            renderValue={() => <div />}
            onChange={(e) => {
              onChangeHandler(e.target.value);
            }}
          >
            <MenuItem
              className={classes.option}
              value={"H"}
              disabled={highThresholdList?.length === 4}
            >
              {t(localizationKeys.CreateHighThreshold)}
            </MenuItem>
            <MenuItem
              className={classes.option}
              value={"L"}
              disabled={lowThresholdList?.length === 4}
            >
              {t(localizationKeys.CreateLowThreshold)}
            </MenuItem>
          </Select>
        </div>
      </div>
    </Grid>
  );
};

ThresholdsSetting.propTypes = {
  min: PropTypes.number,
  setMin: PropTypes.func,
  max: PropTypes.number,
  setMax: PropTypes.func,
  lowThresholdList: PropTypes.array,
  setLowThresholdList: PropTypes.func,
  highThresholdList: PropTypes.array,
  setHighThresholdList: PropTypes.func,
  lowChecked: PropTypes.array,
  setLowChecked: PropTypes.func,
  highChecked: PropTypes.array,
  setHighChecked: PropTypes.func,
  validationError: PropTypes.bool,
  setValidationError: PropTypes.func,
};
