import { Grid, FormControl, CircularProgress, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys";
import { fleetNavigationData, setSelectedVessel } from "../../model/slice/fleetNavigationDataSlice";
import dayjs from 'util/dayjs-init.js';

import CustomToggleButton from "../common/toggleButton.jsx";
import VesselSelector from "../common/vesselSelector.jsx";
import {
  LIST,
  selectedEmissionDataType,
  setSelectedEmissionDataType,
  emissionData,
  emissionDataQuerying,
} from "../../model/slice/emissionSlice";
import { fetchEmissionDataAsync } from "../../model/async/emissionDataAsync";
import { TotalDataArea } from "./totalDataArea.jsx";
import { DurationPicker } from "../durationPicker/durationPicker.jsx";
import { EmissionLineChart } from "./emissionLineChart.jsx";
import { EmissionBarChart } from "./emissionBarChart.jsx";
import { NoData } from "../offlineComponent/noData.jsx";
import { TabSelector } from "../tabSelector/tabSelector.jsx";
import { getEmissionEnableVesselId } from "../../model/slice/addonSlice";

const useStyles = makeStyles({
  wrapper: {
    margin: "18px 0 24px -16px",
    color: "white",
    overflow: "hidden",
    width: "100%",
  },
  leftArea: {
    gap: "2vh",
  },
  lineChartArea: {
    height: "37vh",
    borderRadius: "10px",
    backgroundColor: "#242b36",
    padding: "12px",
  },
  barGraphArea: {
    height: "37vh",
    borderRadius: "10px",
    backgroundColor: "#242b36",
    padding: "12px",
  },
  chartLabel: {
    "& label": {
      marginLeft: "25px",
    },
  },
  rightArea: {
    gap: "2vh",
    minWidth: "230px"
  },
  dataSelectArea: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    backgroundColor: "#242b36",
    padding: "12px",
  },
  dataDurationArea: {
    height: "5vh",
    minHeight: "100px",
    borderRadius: "10px",
    backgroundColor: "#242b36",
    padding: "12px 25px",
  },
  exportDataArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "15vh",
    borderRadius: "10px",
    backgroundColor: "#242b36",
    padding: "12px",
  },
  rightLabel: {
    display: "flex",
    justifyContent: "center",
    color: "#19b2ab",
    marginBottom: "4px",
  },
  dataSelect: {},
  durationPicker: {
    display: "flex",
    justifyContent: "center",
  },
  graphLoadingCircle: {
    marginTop: "10px",
    position: "relative",
    top: "6px",
  },
  exportButton: {
    width: "100px",
    height: "34px",
    borderRadius: "5px",
    background: "#19b2ab",
    textTransform: "none",
    fontSize: "12px",
    color: "#21262f",
    marginTop: "20px",
    "& span": {
      marginLeft: "0px",
    },
    "&:hover": {
      background: "#19b2ab",
    },
  },
  noDataHolder: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& label": {
      padding: "20px 80px",
      backgroundColor: "#21262f",
      display: "inline-block",
      borderRadius: "5px",
    },
  },
});

const getInitialStartDate = () => {
  const d = new Date(dayjs.utc().startOf("year"));
  return d;
};
const getTodayDate = () => {
  const d = new Date(dayjs.utc());
  return d;
};

const getInitialSelectedVessel = (emissionEnabledVesselIds, vesselList) => {
  const emissionVesselIds = emissionEnabledVesselIds.filter(d => vesselList.fleetNavigationData.some(f => f.vessel_id === d))
  if (
    vesselList === undefined ||
    vesselList.fleetNavigationData.length === 0 ||
    emissionVesselIds === undefined ||
    emissionVesselIds.length === 0
  ) {
    return undefined;
  }

  if (vesselList.selectedVesselId != "") {
    if (emissionVesselIds.includes(vesselList.selectedVesselId)) {
      return vesselList.selectedVesselId;
    }
  }

  return emissionVesselIds[0];
};

const digits = 4; //number of digits for shown data
const modeName = ["At Port", "Anchorage", "In Transit", "At Rig", "DP", "Rig Towing"]; //viswa API
const commonTabs = (t) => [
  {
    name: t(localizationKeys.EmissionsOverview_lower),
    id: "emissionsOverview",
  },
];

const EmissionPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const emissionEnabledVesselIds = useSelector(getEmissionEnableVesselId);
  const viswaData = useSelector(emissionData);
  const queryLoading = useSelector(emissionDataQuerying);
  const [emissionDuration, setEmissionDuration] = useState("monthly");
  const [startDate, setStartDate] = useState(getInitialStartDate());
  const [endDate, setEndDate] = useState(getTodayDate());
  const selectedEmissionData = useSelector(selectedEmissionDataType);
  const vesselList = useSelector(fleetNavigationData);
  const [currentSelectedVessel, setCurrentSelectedVessel] = useState(() =>
    getInitialSelectedVessel(emissionEnabledVesselIds, vesselList)
  );
  const [passedImoList, setPassedImoList] = useState();

  const nowLoading = t(localizationKeys.Loading);

  useEffect(() => {
    //avoid to show the side bar additional options
    dispatch(setSelectedVessel(undefined));
  }, []);

  const handleDataChange = (e, value) => {
    value !== undefined &&
      value !== null &&
      (() => {
        dispatch(setSelectedEmissionDataType(value));
      })();
  };

  const getDataForSelections = () => {
    if (selectedEmissionData.name === LIST[0].name) {
      //fleet
      getFleetData();
    } else if (selectedEmissionData.name === LIST[1].name) {
      //vessel
      getVesselData(currentSelectedVessel);
    }
  };

  const getVesselData = (vessel) => {
    if (vessel == null) return;

    const vesselRef = vesselList.fleetNavigationData.find((v) => v.vessel_id === vessel);

    const imoList = vesselRef.vessel_imo;
    setPassedImoList(imoList);
    dispatch(
      fetchEmissionDataAsync({
        imoList,
        startDate,
        endDate,
      })
    );
  };

  const getEmissionVessels = () => {
    return vesselList.fleetNavigationData.filter((v) =>
      emissionEnabledVesselIds.includes(v.vessel_id)
    );
  };

  const getFleetData = () => {
    const emissionVessels = getEmissionVessels();
    const imoList = emissionVessels.map((v) => v.vessel_imo);
    //to avoid topArea rerender when change the duration
    if (JSON.stringify(imoList) !== JSON.stringify(passedImoList)) {
      setPassedImoList(imoList);
    }
    dispatch(
      fetchEmissionDataAsync({
        imoList,
        startDate,
        endDate,
      })
    );
  };

  const filterEmissionVessels = (vessel) => {
    return emissionEnabledVesselIds.includes(vessel.vessel_id);
  };

  const vesselIds = useMemo(
    () => JSON.stringify(emissionEnabledVesselIds),
    [emissionEnabledVesselIds]
  );
  useEffect(() => {
    if (!(!!startDate && !!endDate)) return;
    const startDateForCalculation = Date.parse(startDate);
    const endDateForCalculation = Date.parse(endDate);

    const difference = endDateForCalculation - startDateForCalculation;
    const dayDifference = Math.round(difference / (1000 * 60 * 60 * 24));

    if (dayDifference <= 30) {
      setEmissionDuration("daily");
    } else if (dayDifference <= 120) {
      setEmissionDuration("weekly");
    } else if (dayDifference <= 1095) {
      setEmissionDuration("monthly");
    } else {
      setEmissionDuration("yearly");
    }

    if (emissionEnabledVesselIds === undefined || emissionEnabledVesselIds.length == 0) {
      return;
    }

    if (
      vesselList.fleetNavigationData === undefined ||
      vesselList.fleetNavigationData.length == 0
    ) {
      return;
    }

    if (currentSelectedVessel === undefined) {
      setCurrentSelectedVessel(getInitialSelectedVessel(emissionEnabledVesselIds, vesselList));
      return;
    }

    getDataForSelections();
  }, [startDate, endDate, selectedEmissionData, currentSelectedVessel, vesselIds]);

  const exportData = () => {
    const out = viswaData?.GetCSV();

    if (!out) return;

    const blob = new Blob([out]);

    const downloadLink = document.createElement("a");
    downloadLink.download = "emissions" + ".csv";
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.dataset.downloadurl = ["text/csv", downloadLink.download, downloadLink.href].join(
      ":"
    );

    downloadLink.click();

    downloadLink.remove();
  };
  return (
    <>
      <TabSelector tabs={commonTabs(t)} selectedOne={"emissionsOverview"} clickHandler={() => {}} />
      <Grid className={classes.wrapper} container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={9}>
          <Grid className={classes.leftArea} container>
            <TotalDataArea digits={digits} t={t} imoList={passedImoList} />
            <Grid className={classes.lineChartArea} item xs={12}>
              <div className={classes.chartLabel}>
                <label>Total Emission per Vessel over Time ({emissionDuration})</label>
                <label>{!queryLoading ? viswaData?.vesselName : ""}</label>
              </div>
              {queryLoading ? (
                <>
                  <label
                    style={{
                      marginLeft: "25px",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {nowLoading}
                  </label>
                  <CircularProgress
                    className={classes.graphLoadingCircle}
                    color={"inherit"}
                    size={18}
                    style={{ marginTop: "10px" }}
                  />
                </>
              ) : Object.keys(viswaData.DailyData).length === 0 ? (
                <NoData t={t} />
              ) : (
                <>
                  <EmissionLineChart
                    vesselName={
                      selectedEmissionData.name === LIST[0].name ? "FLEET" : viswaData?.vesselName
                    }
                    data={viswaData}
                    digits={digits}
                    emissionDuration={emissionDuration}
                  />
                </>
              )}
            </Grid>
            <Grid className={classes.barGraphArea} item xs={12}>
              <div className={classes.chartLabel}>
                <label>{t(localizationKeys.OperationalAnalysis)}</label>
                <label>{!queryLoading ? viswaData?.vesselName : ""}</label>
              </div>
              {queryLoading ? (
                <>
                  <label
                    style={{
                      marginLeft: "25px",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {nowLoading}
                  </label>
                  <CircularProgress
                    className={classes.graphLoadingCircle}
                    color={"inherit"}
                    size={18}
                  />
                </>
              ) : Object.keys(viswaData.DailyData).length === 0 ? (
                <>
                  <NoData t={t} />
                </>
              ) : (
                <EmissionBarChart
                  vesselName={
                    selectedEmissionData.name === LIST[0].name ? "FLEET" : viswaData?.vesselName
                  }
                  data={viswaData}
                  modeName={modeName}
                  emissionDuration={emissionDuration}
                  digits={0}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid className={classes.rightArea} container>
            <Grid
              className={classes.dataSelectArea}
              item
              xs={12}
              style={{
                height: selectedEmissionData.name === LIST[0].name ? "100px" : "160px",
              }}
            >
              <FormControl className={classes.dataSelect}>
                <label className={classes.rightLabel}>
                  {t(localizationKeys.EmissionDataSelect)}
                </label>
                <div style={{ margin: "10px 0" }}>
                  <CustomToggleButton
                    options={LIST}
                    selectedOption={selectedEmissionData}
                    handler={handleDataChange}
                    style={{
                      height: "35px",
                      width: "100px",
                    }}
                  />
                </div>
                {selectedEmissionData.name === LIST[1].name && (
                  <div>
                    <label style={{ fontSize: "13px" }}>Select Vessel</label>
                    <VesselSelector
                      currentSelectedVessel={currentSelectedVessel}
                      setCurrentSelectedVessel={setCurrentSelectedVessel}
                      filterPredicate={filterEmissionVessels}
                    />
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid className={classes.dataDurationArea} item xs={12}>
              <FormControl className={classes.durationPicker}>
                <label className={classes.rightLabel}>
                  {t(localizationKeys.EmissionDataDuration)}
                </label>
                <DurationPicker
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  isEmission={true}
                />
              </FormControl>
            </Grid>
            <Grid className={classes.exportDataArea} item xs={12}>
              <div>
                <label className={classes.rightLabel}>
                  {t(localizationKeys.EmissionExportData)}
                </label>
              </div>
              <Button className={classes.exportButton} onClick={exportData}>
                {t(localizationKeys.EmissionExportDataButton)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { EmissionPage };
