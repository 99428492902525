import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Marker from "../../../../../assets/images/cargo/load/marker.svg";
import ArrowIcon from "../../../../../assets/images/cargo/load/arrow.svg";
import { COLORS } from "../../../../constants/colors";
import { checkValueRangeForChart } from "../commonFunc";
import localizationKeys from "../../../../i18n/localizationKeys";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    color: COLORS.white,
  },
  gauge: {
    position: "relative",
    textAlign: "right",
    width: "8px",
    height: "33.5px",
    borderRadius: "1px",
    backgroundColor: "rgb(40, 182, 199)",
    "&:first-child": {
      marginBottom: "0.5px",
    },
    "&:last-child": {
      marginTop: "1px",
    },
  },
  minMaxLabel: {
    fontSize: "10px",
    width: "22px",
    textAlign: "center",
  },
  value: {
    position: "absolute",
    top: "50%",
    transform: "translate(15px, -50%)",
    textAlign: "center",
    marginLeft: "10px",
  },
  arrow: {
    position: "absolute",
    width: "25px",
    height: "68px",
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
    marginLeft: "5px",
    marginTop: "1px",
    "& img": {
      transform: "rotate(-90deg)",
    },
  },
}));

export const RateChart = memo((props) => {
  const { rate } = props;
  const { t } = useTranslation();
  const range = 20;
  const checkedValue = -1*checkValueRangeForChart(rate, range);
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div
          className={classes.arrow}
          style={{
            transform: `translateY(${67 - (33.5 + checkedValue * (33.5 / range))}px)`,
          }}
        >
          <img src={ArrowIcon} width={25} height={25} />
        </div>
        <div className={classes.value}>
          {isNaN(rate) ? (
            <div style={{ fontSize: "14px" }}>{t(localizationKeys.NoLatestDataForChart)}</div>
          ) : (
            <>
              <div style={{ fontSize: "16px" }}>{(rate > 0 ? "+" : "") + rate.toFixed(2)}</div>
              <div style={{ fontSize: "10px" }}>
                deg/s
              </div>
            </>
          )}
        </div>
        <div style={{ position: "relative", opacity: isNaN(rate) ? 0.5 : 1.0 }}>
          <div className={classes.minMaxLabel}>{`-${range}`}</div>
          <div style={{ display: "flex", gap: "2px" }}>
            <div>
              <img src={Marker} width={5} height={68} />
            </div>
            <div>
              <div
                className={classes.gauge}
                style={{
                  opacity: rate < 0 ? 1 : 0.4,
                }}
              />
              <div
                className={classes.gauge}
                style={{
                  opacity: rate > 0 ? 1 : 0.4,
                }}
              />
            </div>
          </div>
          <div className={classes.minMaxLabel}>{`+${range}`}</div>
        </div>
      </Grid>
    </Grid>
  );
});

RateChart.propTypes = {
  rate: PropTypes.number,
};
