import { API_URL, axios } from "./axiosSettings";
import { getAuthToken } from "../helpers/session";
import { logger } from "./logger";
import { convertStreamToJson } from "../util/buffer";

export const fetchCraneData = async (
  vesselId,
  craneNo = null, //can be null
  startDate,
  endDate,
  minuteInterval = 0,
  cancelToken
) => {
  try {
    const fetchUrl = `${API_URL}/sepData/craneData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${minuteInterval}`
    + (craneNo === null ? "" : `&craneNumber=${craneNo}`);
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      cancelToken,
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return {
      craneData: data,
    };
  } catch (e) {
    logger.error(`fetchCraneData Error: ${e}`);
    throw { fetchCraneData };
  }
};

export const fetchDataRangeCraneData = async (vesselId, craneNumber, dataRange) => {
  try {
    const { data } = await axios.post(
      "/sepData/dataRange/craneData",
      {
        vessel_id: vesselId,
        dataRange,
        craneNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return {
      dataRangeCraneData: data,
    };
  } catch (e) {
    logger.error(`fetchDataRangeCraneData Error: ${e}`);
    throw { fetchDataRangeCraneData: e };
  }
};

export const fetchDataRangeLegData = async (vesselId, legNumber, dataRange) => {
  try {
    const { data } = await axios.post(
      "/sepData/dataRange/legData",
      {
        vessel_id: vesselId,
        dataRange,
        legNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return {
      dataRangeLegData: data,
    };
  } catch (e) {
    logger.error(`fetchDataRangeLegData Error: ${e}`);
    throw {
      fetchDataRangeLegData: e,
    };
  }
};

export const fetchLegData = async (
  vesselId,
  legNo = null, // can be null for all legs
  startDate,
  endDate,
  minuteInterval = 0,
  cancelToken
) => {
  try {
    const fetchUrl = `${API_URL}/sepData/legData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${minuteInterval}`
    + (legNo === null ? "" : `&legNumber=${legNo}`);
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      cancelToken,
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return { legData: data };
  } catch (e) {
    logger.error(`fetchLegData Error: ${e}`);
    throw { fetchLegData: e };
  }
};

export const fetchTrimAndHeelData = async (
  vesselId,
  startDate,
  endDate,
  minuteInterval = 0,
  cancelToken
) => {
  try {
    const response = await fetch(`${API_URL}/v1/navigationData/vesselTrimHeel?vessel_id=${vesselId}&startDate=${startDate}&endDate=${endDate}&minuteInterval=${minuteInterval}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      cancelToken,
      responseType: "stream",
    });
    const responseBody = await response.text();
    const separated = responseBody.split("\n");
    const data = [];
    separated.forEach((v) => {
      if (v.length > 0) data.push(JSON.parse(v));
    });

    return { trimAndHeelData: data?.flat() };
  } catch (e) {
    logger.error(`fetchTrimAndHeelData Error: ${e}`);
    return { fetchTrimAndHeelData: e };
  }
};

export const fetchDataRangeTrimAndHeelData = async (vesselId, dataRange) => {
  try {
    const { data } = await axios.post(
      "navigationData/dataRange/vesselTrimHeel",
      {
        vessel_id: vesselId,
        dataRange,
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return { dataRangeTrimAndHeelData: data };
  } catch (e) {
    logger.error(`fetchDataRangeTrimAndHeelData Error: ${e}`);
    return { fetchDataRangeTrimAndHeelData: e };
  }
};

export const fetchThrusterData = async (
  vessel_id,
  startDate,
  endDate,
  thrusterNumber,
  minuteInterval = 0,
  cancelToken
) => {
  try {
    const fetchUrl = `${API_URL}/thrusterData/thrusterData?vessel_id=${vessel_id}`
    + `&startDate=${startDate}&endDate=${endDate}&thrusterNumber=${thrusterNumber}&minuteInterval=${minuteInterval}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      cancelToken,
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return { thrusterData: data };
  } catch (e) {
    logger.error(`fetchThrusterData Error: ${e}`);
    return { fetchThrusterData: e };
  }
};

export const fetchDataRangeThrusterData = async (vessel_id, dataRange) => {
  try {
    const { data } = await axios.post(
      "thrusterData/dataRange/thrusterData",
      {
        vessel_id,
        dataRange,
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );

    return { dataRangeThrusterData: data };
  } catch (e) {
    logger.error(`fetchDataRangeThrusterData Error: ${e}`);
    return { fetchDataRangeThrusterData: e };
  }
};
