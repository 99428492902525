import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(() => ({
  menuBtn: {
    maxWidth: "400px",
    boxShadow: "none",
    padding: "16px 32px",
    margin: "5px 5px",
    backgroundColor: "#3EB3BC",
    textTransform: "none",
    flexGrow: "1",
    height: "200px",
    "&:hover": {
      backgroundColor: "#3EB3BC",
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: "#dddddd",
    },
  },
  buttonRoot: {
    height: "60px",
  },
  typography: {
    textAlign: "center",
    textTransform: "none",
    whiteSpace: "pre-line",
  },
}));

const NextButton = ({ title, path, disabled, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.buttonRoot,
      }}
      disabled={disabled}
      fullWidth
      component={path ? Link : "button"}
      to={path}
      onClick={onClick}
      className={classes.menuBtn}
      variant="contained"
      type="button"
      size="large"
      color="primary"
    >
      <Typography
        variant="h6"
        classes={{
          h6: classes.typography,
        }}
      >
        {title}
      </Typography>
    </Button>
  );
};

NextButton.defaultProps = {
  disabled: false,
  path: undefined,
  onClick: undefined,
};

NextButton.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NextButton;
