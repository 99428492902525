export const paginationStyle = {
  'paddingRight': "30px",
  ".MuiTablePagination-displayedRows": {
    "color": "#FFFFFF",
    "fontSize": "12px",
    "fontFamily": "Inter",
    "fontWeight": "300",
  },
  ".MuiTablePagination-actions": {
    "display": "none",
  },
};