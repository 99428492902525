import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { Chart } from "./chart.jsx";
import { RateChart } from "./rateChart.jsx";

import RollIcon from "../../../../../assets/images/cargo/load/roll.svg";
import { COLORS } from "../../../../constants/colors.js";
import localizationKeys from "../../../../i18n/localizationKeys.js";
import { useTranslation } from "react-i18next";
import { convertNumber } from "../commonFunc.js";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
  },
  icon: {
    textAlign: "center",
  },
  gauges: {
    display: "flex",
    height: "120px",
  },
  roll: {
    height: "55%",
    display: "flex",
    alignItems: "end",
  },
  rate: {
    height: "35%",
  },
  zeroMarker: {
    height: "10%",
    color: COLORS.white,
    textAlign: "center",
    fontSize: "8px",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    "& tr": {
      fontSize: "14px",
      height: "20px",
    },
    "& tr td:first-child": {
      color: COLORS.white,
      width: "50px",
    },
    "& tr td:last-child": {
      color: COLORS.greenishBlue,
    },
  },
}));

export const Roll = memo((props) => {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const roll = convertNumber(data[0]?.inputData);
  const rate = convertNumber(data[1]?.inputData);

  return (
    <Grid container className={classes.container} justifyContent="center" alignItems="center">
      <Grid item xs={4} className={classes.icon}>
        <img src={RollIcon} width={74} height={41} />
      </Grid>
      <Grid item xs={4}>
        <Grid container className={classes.gauges} justifyContent="center" alignItems="center">
          <Grid item xs={12} className={classes.roll}>
            <Chart roll={roll} />
          </Grid>
          <Grid item xs={12} className={classes.zeroMarker}>
            0
          </Grid>
          <Grid item xs={12} className={classes.rate}>
            <RateChart rate={rate} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} className={classes.details}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td>Roll</td>
              {isNaN(roll) ? (
                <td>{t(localizationKeys.NoLatestData)}</td>
              ) : (
                <td>{roll.toFixed(2)} deg</td>
              )}
            </tr>
            <tr>
              <td>{t(localizationKeys.XAngularVelocity)}</td>
              {isNaN(rate) ? (
                <td>{t(localizationKeys.NoLatestData)}</td>
              ) : (
                <td>
                  {rate.toFixed(2)} deg/s
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
});

Roll.propTypes = {
  data: PropTypes.array,
};
