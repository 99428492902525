export const SHIP_COUNTRY = {
  201: {
    Country: "Albania",
    ShortCode: "AL",
  },
  202: {
    Country: "Andorra",
    ShortCode: "AD",
  },
  203: {
    Country: "Austria",
    ShortCode: "AT",
  },
  204: {
    Country: "Portugal",
    ShortCode: "PT",
  },
  205: {
    Country: "Belgium",
    ShortCode: "BE",
  },
  206: {
    Country: "Belarus",
    ShortCode: "BY",
  },
  207: {
    Country: "Bulgaria",
    ShortCode: "BG",
  },
  208: {
    Country: "Vatican",
    ShortCode: "VA",
  },
  209: {
    Country: "Cyprus",
    ShortCode: "CY",
  },
  210: {
    Country: "Cyprus",
    ShortCode: "CY",
  },
  211: {
    Country: "Germany",
    ShortCode: "DE",
  },
  212: {
    Country: "Cyprus",
    ShortCode: "CY",
  },
  213: {
    Country: "Georgia",
    ShortCode: "GE",
  },
  214: {
    Country: "Moldova",
    ShortCode: "MD",
  },
  215: {
    Country: "Malta",
    ShortCode: "MT",
  },
  216: {
    Country: "Armenia",
    ShortCode: "AM",
  },
  218: {
    Country: "Germany",
    ShortCode: "DE",
  },
  219: {
    Country: "Denmark",
    ShortCode: "DK",
  },
  220: {
    Country: "Denmark",
    ShortCode: "DK",
  },
  224: {
    Country: "Spain",
    ShortCode: "ES",
  },
  225: {
    Country: "Spain",
    ShortCode: "ES",
  },
  226: {
    Country: "France",
    ShortCode: "FR",
  },
  227: {
    Country: "France",
    ShortCode: "FR",
  },
  228: {
    Country: "France",
    ShortCode: "FR",
  },
  229: {
    Country: "Malta",
    ShortCode: "MT",
  },
  230: {
    Country: "Finland",
    ShortCode: "FI",
  },
  231: {
    Country: "Faroe Is",
    ShortCode: "FO",
  },
  232: {
    Country: "United Kingdom",
    ShortCode: "GB",
  },
  233: {
    Country: "United Kingdom",
    ShortCode: "GB",
  },
  234: {
    Country: "United Kingdom",
    ShortCode: "GB",
  },
  235: {
    Country: "United Kingdom",
    ShortCode: "GB",
  },
  236: {
    Country: "Gibraltar",
    ShortCode: "GI",
  },
  237: {
    Country: "Greece",
    ShortCode: "GR",
  },
  238: {
    Country: "Croatia",
    ShortCode: "HR",
  },
  239: {
    Country: "Greece",
    ShortCode: "GR",
  },
  240: {
    Country: "Greece",
    ShortCode: "GR",
  },
  241: {
    Country: "Greece",
    ShortCode: "GR",
  },
  242: {
    Country: "Morocco",
    ShortCode: "MA",
  },
  243: {
    Country: "Hungary",
    ShortCode: "HU",
  },
  244: {
    Country: "Netherlands",
    ShortCode: "NL",
  },
  245: {
    Country: "Netherlands",
    ShortCode: "NL",
  },
  246: {
    Country: "Netherlands",
    ShortCode: "NL",
  },
  247: {
    Country: "Italy",
    ShortCode: "IT",
  },
  248: {
    Country: "Malta",
    ShortCode: "MT",
  },
  249: {
    Country: "Malta",
    ShortCode: "MT",
  },
  250: {
    Country: "Ireland",
    ShortCode: "IE",
  },
  251: {
    Country: "Iceland",
    ShortCode: "IS",
  },
  252: {
    Country: "Liechtenstein",
    ShortCode: "LI",
  },
  253: {
    Country: "Luxembourg",
    ShortCode: "LU",
  },
  254: {
    Country: "Monaco",
    ShortCode: "MC",
  },
  255: {
    Country: "Portugal",
    ShortCode: "PT",
  },
  256: {
    Country: "Malta",
    ShortCode: "MT",
  },
  257: {
    Country: "Norway",
    ShortCode: "NO",
  },
  258: {
    Country: "Norway",
    ShortCode: "NO",
  },
  259: {
    Country: "Norway",
    ShortCode: "NO",
  },
  261: {
    Country: "Poland",
    ShortCode: "PL",
  },
  262: {
    Country: "Montenegro",
    ShortCode: "ME",
  },
  263: {
    Country: "Portugal",
    ShortCode: "PT",
  },
  264: {
    Country: "Romania",
    ShortCode: "RO",
  },
  265: {
    Country: "Sweden",
    ShortCode: "SE",
  },
  266: {
    Country: "Sweden",
    ShortCode: "SE",
  },
  267: {
    Country: "Slovakia",
    ShortCode: "SK",
  },
  268: {
    Country: "San Marino",
    ShortCode: "SM",
  },
  269: {
    Country: "Switzerland",
    ShortCode: "CH",
  },
  270: {
    Country: "Czech Republic",
    ShortCode: "CZ",
  },
  271: {
    Country: "Turkey",
    ShortCode: "TR",
  },
  272: {
    Country: "Ukraine",
    ShortCode: "UA",
  },
  273: {
    Country: "Russia",
    ShortCode: "RU",
  },
  274: {
    Country: "FYR Macedonia",
    ShortCode: "MK",
  },
  275: {
    Country: "Latvia",
    ShortCode: "LV",
  },
  276: {
    Country: "Estonia",
    ShortCode: "EE",
  },
  277: {
    Country: "Lithuania",
    ShortCode: "LT",
  },
  278: {
    Country: "Slovenia",
    ShortCode: "SI",
  },
  279: {
    Country: "Serbia",
    ShortCode: "RS",
  },
  301: {
    Country: "Anguilla",
    ShortCode: "AI",
  },
  303: {
    Country: "USA",
    ShortCode: "US",
  },
  304: {
    Country: "Antigua Barbuda",
    ShortCode: "AG",
  },
  305: {
    Country: "Antigua Barbuda",
    ShortCode: "AG",
  },
  306: {
    Country: "Curacao",
    ShortCode: "CW",
  },
  307: {
    Country: "Aruba",
    ShortCode: "AW",
  },
  308: {
    Country: "Bahamas",
    ShortCode: "BS",
  },
  309: {
    Country: "Bahamas",
    ShortCode: "BS",
  },
  310: {
    Country: "Bermuda",
    ShortCode: "BM",
  },
  311: {
    Country: "Bahamas",
    ShortCode: "BS",
  },
  312: {
    Country: "Belize",
    ShortCode: "BZ",
  },
  314: {
    Country: "Barbados",
    ShortCode: "BB",
  },
  316: {
    Country: "Canada",
    ShortCode: "CA",
  },
  319: {
    Country: "Cayman Is",
    ShortCode: "KY",
  },
  321: {
    Country: "Costa Rica",
    ShortCode: "CR",
  },
  323: {
    Country: "Cuba",
    ShortCode: "CU",
  },
  325: {
    Country: "Dominica",
    ShortCode: "DM",
  },
  327: {
    Country: "Dominican Rep",
    ShortCode: "DO",
  },
  329: {
    Country: "Guadeloupe",
    ShortCode: "GP",
  },
  330: {
    Country: "Grenada",
    ShortCode: "GD",
  },
  331: {
    Country: "Greenland",
    ShortCode: "GL",
  },
  332: {
    Country: "Guatemala",
    ShortCode: "GT",
  },
  334: {
    Country: "Honduras",
    ShortCode: "HN",
  },
  336: {
    Country: "Haiti",
    ShortCode: "HT",
  },
  338: {
    Country: "USA",
    ShortCode: "US",
  },
  339: {
    Country: "Jamaica",
    ShortCode: "JM",
  },
  341: {
    Country: "St Kitts Nevis",
    ShortCode: "KN",
  },
  343: {
    Country: "St Lucia",
    ShortCode: "LC",
  },
  345: {
    Country: "Mexico",
    ShortCode: "MX",
  },
  347: {
    Country: "Martinique",
    ShortCode: "MQ",
  },
  348: {
    Country: "Montserrat",
    ShortCode: "MS",
  },
  350: {
    Country: "Nicaragua",
    ShortCode: "NI",
  },
  351: {
    Country: "Panama",
    ShortCode: "PA",
  },
  352: {
    Country: "Panama",
    ShortCode: "PA",
  },
  353: {
    Country: "Panama",
    ShortCode: "PA",
  },
  354: {
    Country: "Panama",
    ShortCode: "PA",
  },
  355: {
    Country: "Panama",
    ShortCode: "PA",
  },
  356: {
    Country: "Panama",
    ShortCode: "PA",
  },
  357: {
    Country: "Panama",
    ShortCode: "PA",
  },
  358: {
    Country: "Puerto Rico",
    ShortCode: "PR",
  },
  359: {
    Country: "El Salvador",
    ShortCode: "SV",
  },
  361: {
    Country: "St Pierre Miquelon",
    ShortCode: "PM",
  },
  362: {
    Country: "Trinidad Tobago",
    ShortCode: "TT",
  },
  364: {
    Country: "Turks Caicos Is",
    ShortCode: "TC",
  },
  366: {
    Country: "USA",
    ShortCode: "US",
  },
  367: {
    Country: "USA",
    ShortCode: "US",
  },
  368: {
    Country: "USA",
    ShortCode: "US",
  },
  369: {
    Country: "USA",
    ShortCode: "US",
  },
  370: {
    Country: "Panama",
    ShortCode: "PA",
  },
  371: {
    Country: "Panama",
    ShortCode: "PA",
  },
  372: {
    Country: "Panama",
    ShortCode: "PA",
  },
  373: {
    Country: "Panama",
    ShortCode: "PA",
  },
  374: {
    Country: "Panama",
    ShortCode: "PA",
  },
  375: {
    Country: "St Vincent Grenadines",
    ShortCode: "VC",
  },
  376: {
    Country: "St Vincent Grenadines",
    ShortCode: "VC",
  },
  377: {
    Country: "St Vincent Grenadines",
    ShortCode: "VC",
  },
  378: {
    Country: "British Virgin Is",
    ShortCode: "VG",
  },
  379: {
    Country: "US Virgin Is",
    ShortCode: "VI",
  },
  401: {
    Country: "Afghanistan",
    ShortCode: "AF",
  },
  403: {
    Country: "Saudi Arabia",
    ShortCode: "SA",
  },
  405: {
    Country: "Bangladesh",
    ShortCode: "BD",
  },
  408: {
    Country: "Bahrain",
    ShortCode: "BH",
  },
  410: {
    Country: "Bhutan",
    ShortCode: "BT",
  },
  412: {
    Country: "China",
    ShortCode: "CN",
  },
  413: {
    Country: "China",
    ShortCode: "CN",
  },
  414: {
    Country: "China",
    ShortCode: "CN",
  },
  416: {
    Country: "Taiwan",
    ShortCode: "TW",
  },
  417: {
    Country: "Sri Lanka",
    ShortCode: "LK",
  },
  419: {
    Country: "India",
    ShortCode: "IN",
  },
  422: {
    Country: "Iran",
    ShortCode: "IR",
  },
  423: {
    Country: "Azerbaijan",
    ShortCode: "AZ",
  },
  425: {
    Country: "Iraq",
    ShortCode: "IQ",
  },
  428: {
    Country: "Israel",
    ShortCode: "IL",
  },
  431: {
    Country: "Japan",
    ShortCode: "JP",
  },
  432: {
    Country: "Japan",
    ShortCode: "JP",
  },
  434: {
    Country: "Turkmenistan",
    ShortCode: "TM",
  },
  436: {
    Country: "Kazakhstan",
    ShortCode: "KZ",
  },
  437: {
    Country: "Uzbekistan",
    ShortCode: "UZ",
  },
  438: {
    Country: "Jordan",
    ShortCode: "JO",
  },
  440: {
    Country: "Korea",
    ShortCode: "KR",
  },
  441: {
    Country: "Korea",
    ShortCode: "KR",
  },
  443: {
    Country: "Palestine",
    ShortCode: "PS",
  },
  445: {
    Country: "DPR Korea",
    ShortCode: "KP",
  },
  447: {
    Country: "Kuwait",
    ShortCode: "KW",
  },
  450: {
    Country: "Lebanon",
    ShortCode: "LB",
  },
  451: {
    Country: "Kyrgyz Republic",
    ShortCode: "KG",
  },
  453: {
    Country: "Macao",
    ShortCode: "MO",
  },
  455: {
    Country: "Maldives",
    ShortCode: "MV",
  },
  457: {
    Country: "Mongolia",
    ShortCode: "MN",
  },
  459: {
    Country: "Nepal",
    ShortCode: "NP",
  },
  461: {
    Country: "Oman",
    ShortCode: "OM",
  },
  463: {
    Country: "Pakistan",
    ShortCode: "PK",
  },
  466: {
    Country: "Qatar",
    ShortCode: "QA",
  },
  468: {
    Country: "Syria",
    ShortCode: "SY",
  },
  470: {
    Country: "UAE",
    ShortCode: "AE",
  },
  471: {
    Country: "UAE",
    ShortCode: "AE",
  },
  472: {
    Country: "Tajikistan",
    ShortCode: "TJ",
  },
  473: {
    Country: "Yemen",
    ShortCode: "YE",
  },
  475: {
    Country: "Yemen",
    ShortCode: "YE",
  },
  477: {
    Country: "Hong Kong",
    ShortCode: "HK",
  },
  478: {
    Country: "Bosnia and Herzegovina",
    ShortCode: "BA",
  },
  501: {
    Country: "Antarctica",
    ShortCode: "AQ",
  },
  503: {
    Country: "Australia",
    ShortCode: "AU",
  },
  506: {
    Country: "Myanmar",
    ShortCode: "MM",
  },
  508: {
    Country: "Brunei",
    ShortCode: "BN",
  },
  510: {
    Country: "Micronesia",
    ShortCode: "FM",
  },
  511: {
    Country: "Palau",
    ShortCode: "PW",
  },
  512: {
    Country: "New Zealand",
    ShortCode: "NZ",
  },
  514: {
    Country: "Cambodia",
    ShortCode: "KH",
  },
  515: {
    Country: "Cambodia",
    ShortCode: "KH",
  },
  516: {
    Country: "Christmas Is",
    ShortCode: "CX",
  },
  518: {
    Country: "Cook Is",
    ShortCode: "CK",
  },
  520: {
    Country: "Fiji",
    ShortCode: "FJ",
  },
  523: {
    Country: "Cocos Is",
    ShortCode: "CC",
  },
  525: {
    Country: "Indonesia",
    ShortCode: "ID",
  },
  529: {
    Country: "Kiribati",
    ShortCode: "KI",
  },
  531: {
    Country: "Laos",
    ShortCode: "LA",
  },
  533: {
    Country: "Malaysia",
    ShortCode: "MY",
  },
  536: {
    Country: "N Mariana Is",
    ShortCode: "MP",
  },
  538: {
    Country: "Marshall Is",
    ShortCode: "MH",
  },
  540: {
    Country: "New Caledonia",
    ShortCode: "NC",
  },
  542: {
    Country: "Niue",
    ShortCode: "NU",
  },
  544: {
    Country: "Nauru",
    ShortCode: "NR",
  },
  546: {
    Country: "French Polynesia",
    ShortCode: "PF",
  },
  548: {
    Country: "Philippines",
    ShortCode: "PH",
  },
  553: {
    Country: "Papua New Guinea",
    ShortCode: "PG",
  },
  555: {
    Country: "Pitcairn Is",
    ShortCode: "PN",
  },
  557: {
    Country: "Solomon Is",
    ShortCode: "SB",
  },
  559: {
    Country: "American Samoa",
    ShortCode: "AS",
  },
  561: {
    Country: "Samoa",
    ShortCode: "WS",
  },
  563: {
    Country: "Singapore",
    ShortCode: "SG",
  },
  564: {
    Country: "Singapore",
    ShortCode: "SG",
  },
  565: {
    Country: "Singapore",
    ShortCode: "SG",
  },
  566: {
    Country: "Singapore",
    ShortCode: "SG",
  },
  567: {
    Country: "Thailand",
    ShortCode: "TH",
  },
  570: {
    Country: "Tonga",
    ShortCode: "TO",
  },
  572: {
    Country: "Tuvalu",
    ShortCode: "TV",
  },
  574: {
    Country: "Vietnam",
    ShortCode: "VN",
  },
  576: {
    Country: "Vanuatu",
    ShortCode: "VU",
  },
  577: {
    Country: "Vanuatu",
    ShortCode: "VU",
  },
  578: {
    Country: "Wallis Futuna Is",
    ShortCode: "WF",
  },
  601: {
    Country: "South Africa",
    ShortCode: "ZA",
  },
  603: {
    Country: "Angola",
    ShortCode: "AO",
  },
  605: {
    Country: "Algeria",
    ShortCode: "DZ",
  },
  607: {
    Country: "St Paul Amsterdam Is",
    ShortCode: "TF",
  },
  608: {
    Country: "Ascension Is",
    ShortCode: "IO",
  },
  609: {
    Country: "Burundi",
    ShortCode: "BI",
  },
  610: {
    Country: "Benin",
    ShortCode: "BJ",
  },
  611: {
    Country: "Botswana",
    ShortCode: "BW",
  },
  612: {
    Country: "Cen Afr Rep",
    ShortCode: "CF",
  },
  613: {
    Country: "Cameroon",
    ShortCode: "CM",
  },
  615: {
    Country: "Congo",
    ShortCode: "CG",
  },
  616: {
    Country: "Comoros",
    ShortCode: "KM",
  },
  617: {
    Country: "Cape Verde",
    ShortCode: "CV",
  },
  618: {
    Country: "Antarctica",
    ShortCode: "AQ",
  },
  619: {
    Country: "Ivory Coast",
    ShortCode: "CI",
  },
  620: {
    Country: "Comoros",
    ShortCode: "KM",
  },
  621: {
    Country: "Djibouti",
    ShortCode: "DJ",
  },
  622: {
    Country: "Egypt",
    ShortCode: "EG",
  },
  624: {
    Country: "Ethiopia",
    ShortCode: "ET",
  },
  625: {
    Country: "Eritrea",
    ShortCode: "ER",
  },
  626: {
    Country: "Gabon",
    ShortCode: "GA",
  },
  627: {
    Country: "Ghana",
    ShortCode: "GH",
  },
  629: {
    Country: "Gambia",
    ShortCode: "GM",
  },
  630: {
    Country: "Guinea-Bissau",
    ShortCode: "GW",
  },
  631: {
    Country: "Equ. Guinea",
    ShortCode: "GQ",
  },
  632: {
    Country: "Guinea",
    ShortCode: "GN",
  },
  633: {
    Country: "Burkina Faso",
    ShortCode: "BF",
  },
  634: {
    Country: "Kenya",
    ShortCode: "KE",
  },
  635: {
    Country: "Antarctica",
    ShortCode: "AQ",
  },
  636: {
    Country: "Liberia",
    ShortCode: "LR",
  },
  637: {
    Country: "Liberia",
    ShortCode: "LR",
  },
  642: {
    Country: "Libya",
    ShortCode: "LY",
  },
  644: {
    Country: "Lesotho",
    ShortCode: "LS",
  },
  645: {
    Country: "Mauritius",
    ShortCode: "MU",
  },
  647: {
    Country: "Madagascar",
    ShortCode: "MG",
  },
  649: {
    Country: "Mali",
    ShortCode: "ML",
  },
  650: {
    Country: "Mozambique",
    ShortCode: "MZ",
  },
  654: {
    Country: "Mauritania",
    ShortCode: "MR",
  },
  655: {
    Country: "Malawi",
    ShortCode: "MW",
  },
  656: {
    Country: "Niger",
    ShortCode: "NE",
  },
  657: {
    Country: "Nigeria",
    ShortCode: "NG",
  },
  659: {
    Country: "Namibia",
    ShortCode: "NA",
  },
  660: {
    Country: "Reunion",
    ShortCode: "RE",
  },
  661: {
    Country: "Rwanda",
    ShortCode: "RW",
  },
  662: {
    Country: "Sudan",
    ShortCode: "SD",
  },
  663: {
    Country: "Senegal",
    ShortCode: "SN",
  },
  664: {
    Country: "Seychelles",
    ShortCode: "SC",
  },
  665: {
    Country: "St Helena",
    ShortCode: "SH",
  },
  666: {
    Country: "Somalia",
    ShortCode: "SO",
  },
  667: {
    Country: "Sierra Leone",
    ShortCode: "SL",
  },
  668: {
    Country: "Sao Tome Principe",
    ShortCode: "ST",
  },
  669: {
    Country: "Swaziland",
    ShortCode: "SZ",
  },
  670: {
    Country: "Chad",
    ShortCode: "TD",
  },
  671: {
    Country: "Togo",
    ShortCode: "TG",
  },
  672: {
    Country: "Tunisia",
    ShortCode: "TN",
  },
  674: {
    Country: "Tanzania",
    ShortCode: "TZ",
  },
  675: {
    Country: "Uganda",
    ShortCode: "UG",
  },
  676: {
    Country: "DR Congo",
    ShortCode: "CD",
  },
  677: {
    Country: "Tanzania",
    ShortCode: "TZ",
  },
  678: {
    Country: "Zambia",
    ShortCode: "ZM",
  },
  679: {
    Country: "Zimbabwe",
    ShortCode: "ZW",
  },
  701: {
    Country: "Argentina",
    ShortCode: "AR",
  },
  710: {
    Country: "Brazil",
    ShortCode: "BR",
  },
  720: {
    Country: "Bolivia",
    ShortCode: "BO",
  },
  725: {
    Country: "Chile",
    ShortCode: "CL",
  },
  730: {
    Country: "Colombia",
    ShortCode: "CO",
  },
  735: {
    Country: "Ecuador",
    ShortCode: "EC",
  },
  740: {
    Country: "UK",
    ShortCode: "UK",
  },
  745: {
    Country: "Guiana",
    ShortCode: "GF",
  },
  750: {
    Country: "Guyana",
    ShortCode: "GY",
  },
  755: {
    Country: "Paraguay",
    ShortCode: "PY",
  },
  760: {
    Country: "Peru",
    ShortCode: "PE",
  },
  765: {
    Country: "Suriname",
    ShortCode: "SR",
  },
  770: {
    Country: "Uruguay",
    ShortCode: "UY",
  },
  775: {
    Country: "Venezuela",
    ShortCode: "VE",
  },
};
