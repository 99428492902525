//Setters
const setFleetData = (state, action) => {
  state.fleetNavigationData = action.payload;
};

const setSelectedVessel = (state, action) => {
  state.selectedVesselId = action.payload;
};

const setSelectedFOCDuration = (state, action) => {
  state.selectedFOCDuration = action.payload;
};

const setMapCenter = (state, action) => {
  state.mapData.center = action.payload;
};

const setMapZoom = (state, action) => {
  state.mapData.zoom = action.payload;
};

const setCurrentVessels = (state, action) => {
  state.mapData.currentVessels = action.payload;
};

const setFleetMarkers = (state, action) => {
  state.mapData.fleetMarkers = action.payload;
};

const setAlarmMarkers = (state, action) => {
  state.mapData.alarmMarkers = action.payload;
};

const setDisconnectMarkers = (state, action) => {
  state.mapData.disconnectMarkers = action.payload;
};

const resetMapMarkers = (state, action) => {
  state.mapData.isResetMarkers = true;
};

const markersResetDone = (state, action) => {
  state.mapData.fleetMarkers = [];
  state.mapData.alarmMarkers = [];
  state.mapData.disconnectMarkers = [];
  state.mapData.isResetMarkers = false;
};

const resetCurrentVessels = (state, action) => {
  state.mapData.currentVessels = [];
};

export default {
  setFleetData,
  setSelectedVessel,
  setSelectedFOCDuration,
  setMapCenter,
  setMapZoom,
  setCurrentVessels,
  setFleetMarkers,
  setAlarmMarkers,
  setDisconnectMarkers,
  resetMapMarkers,
  markersResetDone,
  resetCurrentVessels,
};
