import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "../../../../../assets/images/alarmIcon/close-icon.svg";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import { DeleteEmailConfirmationModal } from "../modalEmailDeleteConfirmation";

const useStyles = makeStyles({
  inputEndAdornment: {
    width: "30px",
  },
  closeIcon: {
    cursor: "pointer",
  },
});

export const EndAdornmentContent = React.forwardRef((props, ref) => {
  const { index, target, updateFunction } = props;
  const classes = useStyles();
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [execute, setExecute] = useState(false);
  useEffect(() => {
    if (execute && !isOpenConfirmation) {
      const returnValue = [...target].filter((v, i) => i !== index);
      updateFunction(returnValue);
    }
  }, [isOpenConfirmation]);
  return (
    <div ref={ref} className={classes.inputEndAdornment}>
      <img
        className={classes.closeIcon}
        src={CloseIcon}
        onClick={() => {
          if (target[index] === "") {
            const returnValue = [...target].filter((v, i) => i !== index);
            updateFunction(returnValue);
          } else {
            setIsOpenConfirmation(true);
          }
        }}
      />
      <Modal
        open={isOpenConfirmation}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <DeleteEmailConfirmationModal
          setExecute={setExecute}
          setIsOpenConfirmation={setIsOpenConfirmation}
        />
      </Modal>
    </div>
  );
});

EndAdornmentContent.propTypes = {
  index: PropTypes.number,
  target: PropTypes.array,
  updateFunction: PropTypes.func,
};
