import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import { CargoStatus } from "../../../cargo/status";

const OverviewCargoStatus = ({ taggedLatestData, classes }) => {
  return (
    <Grid container className={`${classes.components}`} style={{ position: "relative", width: "100%",display: "block" }}>
      <Grid item xs={12}>
        <CargoStatus taggedLatestData={taggedLatestData} isVesselOverview={true} />
      </Grid>
    </Grid>
  );
}

OverviewCargoStatus.propTypes = {
  taggedLatestData: PropTypes.array,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export default OverviewCargoStatus;