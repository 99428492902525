export const API_KEY = process.env.GOOGLE_MAP_API_KEY;
export const REGION = process.env.GOOGLE_MAP_REGION;

export const INITIAL_MAP_DATA = {
  center: {
    lat: 33.8834483333333,
    lng: 130.995,
  },
  zoom: 3,
  currentVessels: [],
  fleetMarkers: [],
  alarmMarkers: [],
  disconnectMarkers: [],
  isResetMarkers: false,
};
