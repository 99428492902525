import React, { useEffect, useMemo, useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'util/dayjs-init.js';
import { useDispatch, useSelector } from "react-redux";
import { fetchEmissionDataForDataAreaAsync } from "../../model/async/emissionDataAsync";
import { emissionTotalData, emissionTotalDataQuerying } from "../../model/slice/emissionSlice";
import ChangeIndicator from "./changeIndicator.jsx";

const useStyles = makeStyles({
  totalDataArea: {
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "#242b36",
    borderRadius: "10px",
    padding: "5px",
    "& label": {
      fontSize: "8px",
      color: "rgba(255,255,255,0.7)",
    },
  },
  valueArea: {
    display: "flex",
    "& > .changeIndicator": {
      position: "relative",
      top: "10px",
      height: "fit-content",
      marginLeft: "12px",
    },
  },
  loadingCircle: {
    display: "flex",
    justifyContent: "center",
  },
});

const TotalDataArea = (props) => {
  const { digits = 4, t, imoList } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryLoading = useSelector(emissionTotalDataQuerying);
  const data = useSelector(emissionTotalData);
  const [compareResult, setCompareResult] = useState();

  useEffect(() => {
    getData(dispatch, imoList);
  }, [imoList]);

  useEffect(() => {
    if (data?.length === 0 || data === undefined) return;
    setCompareResult(compareData(data.compareDataPrevious, data.compareDataCurrent));
  }, [data]);
  return (
    <Grid className={classes.totalDataArea} item xs={12}>
      <div>
        <label>Total CO2 Emissions (MT/month)</label>
        {!queryLoading ? (
          <div className={classes.valueArea}>
            <h2>{data?.totalData?.CO2.toFixed(digits) ?? 0}</h2>
            <ChangeIndicator value={compareResult?.CO2} t={t} />
          </div>
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress color={"inherit"} size={20} />
          </div>
        )}
      </div>
      <div>
        <label>Total SOx Emissions (MT/month)</label>
        {!queryLoading ? (
          <div className={classes.valueArea}>
            <h2>{data?.totalData?.SOX.toFixed(digits) ?? 0} </h2>
            <ChangeIndicator value={compareResult?.SOX} />
          </div>
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress color={"inherit"} size={20} />
          </div>
        )}
      </div>
      <div>
        <label>Total NOx Emissions (MT/month)</label>
        {!queryLoading ? (
          <div className={classes.valueArea}>
            <h2>{data?.totalData?.NOX.toFixed(digits) ?? 0} </h2>
            <ChangeIndicator value={compareResult?.NOX} />
          </div>
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress color={"inherit"} size={20} />
          </div>
        )}
      </div>
      <div>
        <label>Total Fuel Consumption (MT/month)</label>
        {!queryLoading ? (
          <div className={classes.valueArea}>
            <h2>{data?.totalData?.TFC.toFixed(digits) ?? 0} </h2>
            <ChangeIndicator value={compareResult?.TFC} />
          </div>
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress color={"inherit"} size={20} />
          </div>
        )}
      </div>
    </Grid>
  );
};

const getData = (dispatch, imoList) => {
  const today = dayjs.utc().subtract(1, "days");
  const startDate = dayjs.utc().startOf("year");
  if (checkDuration(today, startDate)) {
    dispatch(
      fetchEmissionDataForDataAreaAsync({
        imoList,
        startDate,
        endDate: today,
      })
    );
  } else {
    dispatch(
      fetchEmissionDataForDataAreaAsync({
        imoList,
        startDate: dayjs.utc().subtract(61, "days"),
        endDate: today,
      })
    );
  }
};

const checkDuration = (today, startDate) => {
  if (today.diff(startDate, "days") >= 61) {
    return true;
  } else {
    return false;
  }
};

const compareData = (preData, currentData) => {
  const digits = 2;
  const comparedData = {
    CO2: ((currentData.CO2 / preData.CO2 - 1) * 100).toFixed(digits),
    SOX: ((currentData.SOX / preData.SOX - 1) * 100).toFixed(digits),
    NOX: ((currentData.NOX / preData.NOX - 1) * 100).toFixed(digits),
    TFC: ((currentData.TFC / preData.TFC - 1) * 100).toFixed(digits),
  };
  return comparedData;
};

export { TotalDataArea };
