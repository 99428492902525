import {
  STATUS_ABNORMAL,
  STATUS_FAIL,
  STATUS_REPOSE,
  CATEGORY_PRIORITY_LIST,
  STATUS_PRIORITY_LIST,
} from "../constants/alarmType.js";

//Returns array of alarms with highest priority based from priorityListBasis provided
//Lower value from priorityList means higher priority
const getAlarmListByPriority = function (priorityListBasis, groupingList) {
  let minValue = undefined;
  let priorityList = [];

  for (const key in groupingList) {
    let priorityIndex = priorityListBasis.indexOf(key);

    if (priorityIndex < 0) {
      //unexpected value case
      priorityIndex = 999;
    }

    if (minValue === undefined) {
      minValue = priorityIndex;
      priorityList = groupingList[key];
      continue;
    }

    if (priorityIndex < minValue) {
      minValue = priorityIndex;
      priorityList = groupingList[key];
    }
  }

  return priorityList;
};

//Compare function to sort alarms by category
//Lower value from CATEGORY_PRIORITY_LIST means higher priority
const compareByCategory = function (a, b) {
  const categoryIndexA = CATEGORY_PRIORITY_LIST.indexOf(a.category);
  const categoryIndexB = CATEGORY_PRIORITY_LIST.indexOf(b.category);

  if (categoryIndexA < categoryIndexB) return -1;
  if (categoryIndexA > categoryIndexB) return 1;
  return 0;
};

//Groups alarm list by status
const groupAlarmByStatus = (alarmList) => {
  const statusGrouping = {};

  alarmList?.forEach((e) => {
    if (e.alarmStatus in statusGrouping) {
      statusGrouping[e.alarmStatus].push(e);
    } else {
      statusGrouping[e.alarmStatus] = [];
      statusGrouping[e.alarmStatus].push(e);
    }
  });

  return statusGrouping;
};

//Function that returns the alarm with the highest priority
export const getAlarmHighestPriority = (alarmList) => {
  //Group alarms by category:
  //Sample output => {"FAIL": [...], "ABNOR": [...]}
  const statusGrouping = groupAlarmByStatus(alarmList);
  //Returns the array of alarms with highest status priority
  const alarmListStatusPriority = getAlarmListByPriority(STATUS_PRIORITY_LIST, statusGrouping);
  //Sort alarm array of highet priority by category priority
  const alarmSortedByCategoryPriority = alarmListStatusPriority.sort(compareByCategory);

  //After sorting by status priority return the first entry. First entry has the highest priority
  //otherwise return null
  return alarmSortedByCategoryPriority.length > 0 ? alarmSortedByCategoryPriority[0] : null;
};

//Function that returns the color of arker to be used based from the status of alarm
export const getMarkerColor = (category, opacity = 100) => {
  if (category === null) {
    return null;
  }

  const filterRed = `brightness(0) saturate(100%) invert(23%) sepia(48%) saturate(1652%) hue-rotate(327deg) brightness(140%) contrast(98%) opacity(${opacity}%)`;
  const filterYellow = `brightness(0) saturate(100%) invert(89%) sepia(61%) saturate(634%) hue-rotate(345deg) brightness(85%) contrast(103%) opacity(${opacity}%)`;
  const filterPink = `brightness(0) saturate(100%) invert(27%) sepia(96%) saturate(3614%) hue-rotate(277deg) brightness(111%) contrast(106%) opacity(${opacity}%)`;

  const filterColorMapping = {
    [STATUS_ABNORMAL]: filterRed,
    [STATUS_FAIL]: filterPink,
    [STATUS_REPOSE]: filterYellow,
  };

  //Return default filter red if status is not valid
  return category.alarmStatus in filterColorMapping
    ? filterColorMapping[category.alarmStatus]
    : filterRed;
};

export const getHighlightStyle = (category, opacity = 1.0) => {
  if (category === null) {
    return null;
  }

  const alarmRed = { backgroundColor: `rgba(220, 64, 64, ${opacity})` };
  const alarmPink = { backgroundColor: `rgba(232, 44, 255, ${opacity})` };
  const alarmYellow = { backgroundColor: `rgba(255, 244, 135, ${opacity})` };

  const filterHighlightStyle = {
    [STATUS_ABNORMAL]: alarmRed,
    [STATUS_FAIL]: alarmPink,
    [STATUS_REPOSE]: alarmYellow,
  };

  //Return default highlight to red if status is not valid
  return category.alarmStatus in filterHighlightStyle
    ? filterHighlightStyle[category.alarmStatus]
    : alarmRed;
};

export const getAlarmColorClass = (category) => {
  const colorClass = {
    [STATUS_ABNORMAL]: "toast-red",
    [STATUS_FAIL]: "toast-pink",
    [STATUS_REPOSE]: "toast-yellow",
  };

  return colorClass[category] ?? "toast-red";
};

export const getAlarmFill = (category) => {
  if (category === null) {
    return null;
  }

  const alarmRed = "#ff5858";
  const alarmPink = "#e82cff";
  const alarmYellow = "#fcba10";

  const filterHighlightStyle = {
    [STATUS_ABNORMAL]: alarmRed,
    [STATUS_FAIL]: alarmPink,
    [STATUS_REPOSE]: alarmYellow,
  };

  //Return default highlight to red if status is not valid
  return category.alarmStatus in filterHighlightStyle
    ? filterHighlightStyle[category.alarmStatus]
    : alarmRed;
};
