import { axios } from "./axiosSettings";
import { getAuthToken } from "../helpers/session";

import { logger } from "./logger";

export const fetchThumbnail = async (vesselId) => {
  try {
    const { data } = await axios.get(`/vessel/${vesselId}/thumbnail`, {
      responseType: "blob",
      timeout: 5000,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    logger.info(`fetchThumbnail data: ${JSON.stringify(data)}`);
    return { thumbnail: data };
  } catch (e) {
    logger.error(`fetchThumbnail error: ${e}`);
    return { thumbnail: null, error: e };
  }
};
