// --------------

import { logger } from "../../../../api/logger";

const BASE_RPM_POINT = [17, 50, 75, 100, 110];

export const ShipData = ({ cycle, cylinder, stroke, bore, mwMaxRpm }) => {
  return BASE_RPM_POINT.map((x) => {
    const le = x * 0.01 * mwMaxRpm;
    const ne = mwMaxRpm * Math.pow(x * 0.01, 1 / 3);
    return {
      powerRate: x,
      ne,
      le,
      pme: (((120000000 * cycle) / (Math.PI * Math.pow(bore, 2) * stroke)) * le) / (cylinder * ne),
    };
  });
};

const calLimit = (meMaxRpm) => {
  const list = [];

  // bule line limit can control here
  for (let i = 20; i <= 100; i++) {
    const rpm = (meMaxRpm * i) / 100;
    list.push({
      id: i,
      rpm,
    });
  }
  return list;
};

const data85Power = (meMaxKw) => (meMaxKw * 85) / 100;

export const calOutput = ({ cycle, cylinder, stroke, bore, meMaxKw, meMaxRpm }) => {
  const m22 =
    (((120000000 * cycle) / (Math.PI * Math.pow(bore, 2) * stroke)) * meMaxKw) /
    (cylinder * meMaxRpm);
  const calculated85Power = data85Power(meMaxKw);
  return calLimit(meMaxRpm).map((x) => {
    switch (true) {
      case x.id === 85:
        return {
          ...x,
          power: calculated85Power,
          cal:
            (((120000000 * cycle) / (Math.PI * Math.pow(bore, 2) * stroke)) * calculated85Power) /
            (cylinder * x.rpm),
        };
      case x.id === 100:
        return {
          ...x,
          power: meMaxKw,
          cal: m22,
        };
      case x.id < 85:
        return {
          ...x,
          // power: Math.pow((x.rpm / calculated85Rev), 3) * calculated85Power,
          power: Math.pow((meMaxKw * x.id) / 100 / calculated85Power, 3) * calculated85Power,
        };
      case x.id > 85: {
        const power =
          (m22 * cylinder * x.rpm * Math.PI * Math.pow(bore, 2) * stroke) / (120000000 * cycle);
        logger.info(`calLimit cycle: ${cycle}`);
        return {
          ...x,
          power,
          cal:
            (((120000000 * cycle) / (Math.PI * Math.pow(bore, 2) * stroke)) * x.rpm) /
            (cylinder * power),
        };
      }
      default:
        // eslint-disable-next-line array-callback-return
        return;
    }
  });
};

export const smokeLine = ({ cycle, cylinder, bore, stroke, meMaxKw, meMaxRpm }) => {
  const baseO22 = meMaxKw * 0.2176;
  const r22 =
    (((120000000 * cycle) / (Math.PI * Math.pow(bore, 2) * stroke * cylinder)) * meMaxRpm) /
    baseO22;
  const data = [];
  for (let i = 0; i <= meMaxRpm; i += 10) {
    if (i !== meMaxRpm) {
      data.push({
        rpm: i,
        power: (r22 * i * cylinder * Math.PI * Math.pow(bore, 2) * stroke) / (120000000 * cycle),
      });
    } else {
      data.push({
        rpm: i,
        power: baseO22,
      });
    }
  }
  return data;
};

export const shipCurv = ({ meMinRpm, meMaxRpm, meMaxKw }) => {
  const data = [];
  for (let i = meMinRpm; i <= meMaxRpm; i += 10) {
    data.push({
      rpm: i,
      power: Math.pow(i / meMaxRpm, 3) * meMaxKw,
    });
  }
  if (!data.find((d) => d.rpm === meMaxRpm)) {
    data.push({
      rpm: meMaxRpm,
      power: meMaxKw,
    });
  }
  return data;
};
