import React, { useMemo, useRef, useState } from "react";
import Select from "react-select";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { Typography, useMediaQuery } from "@mui/material";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useSelector } from "react-redux";
import { fleetNavigationData } from "../../model/slice/fleetNavigationDataSlice";
import SortUtil from "../../helpers/sortUtil";
import { getAlarmHighestPriority, getMarkerColor } from "../../helpers/alarmTypeStyle";
import { STATUS_ABNORMAL } from "../../constants/alarmType";
import { fleetEngineData } from "../../model/slice/fleetEngineDataSlice";
import "../../../css/marker.css";
import { COLORS } from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    fontSize: "10px",
    fontWeight: "300",
    width: "100%",
    height: "30px",
    "& p": {
      color: "#ffffff",
      marginRight: "10px",
    },
  },
}));

export const customSelectorStyles = {
  control: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    height: "24px",
    minHeight: "24px",
    width: "100%",
    background: state.selectProps.isSm ? "#21262f" : "#2c3a48",
    borderRadius: "2px",
    borderColor: "#2c3b48",
    boxShadow: null,
    overflow: "hidden",
    "&:hover": {
      borderColor: "#2c3b48",
    },
  }),
  input: (provided, state) => ({
    ...provided,
    display: "none",
    height: "24px",
    minHeight: "24px",
    background: state.isSm ? "#21262f" : "#2c3a48",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "10px",
    padding: "0px",
    background: "none",
    height: "40px",
    "&:hover": {
      background: "#26303b",
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "null",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 10,
    borderRadius: 0,
    color: "#ffffff",
    marginTop: "8px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;

    return {
      ...provided,
      opacity,
      color: "#ffffff",
    };
  },
  menuList: (provided) => ({
    ...provided,
    background: "#2c3a48",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    height: "100%",
    minHeight: "50px",
    maxHeight: "330px",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: "24px",
    minHeight: "24px",
    "& svg": {
      fill: COLORS.staticState,
    },
    "& svg:checked": {
      fill: COLORS.staticState,
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

const VesselSelector = (props) => {
  const { currentSelectedVessel, setCurrentSelectedVessel, filterPredicate } = props;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const classes = useStyles();
  const menuRef = useRef(null);
  const sortUtil = new SortUtil();
  const vesselList = useSelector(fleetNavigationData);
  const vesselEngineList = useSelector(fleetEngineData);
  useOnClickOutside([menuRef], () => {
    setIsMenuOpen(false);
  });

  const sortedVesselList = useMemo(() => {
    let sortedVessels = sortUtil.vesselOffline(vesselList, currentSelectedVessel, vesselEngineList);
    if (filterPredicate !== undefined) {
      sortedVessels = sortedVessels.filter(filterPredicate);
    }
    return sortedVessels;
  }, [vesselList, vesselEngineList, currentSelectedVessel]);

  const VesselImage = ({ isOffline, vesselIcon, vesselName, alarmData }) => {
    const alarmHighestPriority = getAlarmHighestPriority(alarmData);
    return (
      <div
        style={{
          minWidth: "28px",
          position: "relative",
          top: "6px",
          left: "8px",
        }}
      >
        <img
          src={vesselIcon}
          alt={`vessel icon ${vesselName}`}
          style={{ zIndex: 999, position: "relative" }}
        />
        {!isOffline && alarmHighestPriority?.alarmStatus === STATUS_ABNORMAL && (
          <span
            className="marker fl-marker"
            style={{
              filter: alarmHighestPriority != null ? getMarkerColor(alarmHighestPriority) : "",
              width: "24px",
              height: "24px",
              top: "-14px",
              left: "-12px",
              position: "absolute",
              borderRadius: "50%",
              zIndex: 600,
            }}
          />
        )}
      </div>
    );
  };

  const selectComponent = (props) => {
    return (
      <div
        style={{
          display: "flex",
          height: "40px",
          background: props?.isSelected ? "rgba(33,38,47,0.8)" : "none",
        }}
      >
        <VesselImage
          isOffline={props?.isOffline}
          vesselName={props?.vesselName}
          vesselIcon={props?.vesselIcon}
          alarmData={props?.alarmData}
        ></VesselImage>
        <Typography variant="body2">
          <span
            style={{
              position: "relative",
              top: "8px",
              left: "12px",
              color: !props?.isOffline ? "#19b2ab" : "#ffffff",
            }}
          >
            {props?.vessel_name}
          </span>
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.gridContainer} ref={menuRef}>
      <Select
        styles={customSelectorStyles}
        options={sortedVesselList}
        menuIsOpen={isMenuOpen}
        value={
          sortedVesselList?.find((d) => d.vessel_id === currentSelectedVessel)?.vessel_id ?? ""
        }
        formatOptionLabel={selectComponent}
        isSm={isSm}
        onChange={(d) => {
          setCurrentSelectedVessel(d?.vessel_id ?? null);
        }}
        placeholder={
          sortedVesselList?.find((d) => d?.vessel_id === currentSelectedVessel)?.vessel_name ??
          "Vessel"
        }
        menuPortalTarget={document.body}
        menuPosition={"absolute"}
        onMenuClose={() => setIsMenuOpen(false)}
        onMenuOpen={() => setIsMenuOpen(true)}
      />
    </div>
  );
};

export default VesselSelector;
