import { withTranslation } from "react-i18next";
import React from "react";
import {
  Backdrop,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import { ENGINE_POSITION } from "../../constants/trendGraph/enginesLine.js";
import localizationKeys from "../../i18n/localizationKeys";

const label = {
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#19b2ab",
};

const useStyles = makeStyles((theme) => ({
  paperHeightEngine: {
    position: "relative",
    marginBottom: "16px",
  },
  engineSelectorContainer: {
    display: "flex",
    justifyContent: "center",
    "& p": {
      ...label,
    },
  },
  radio: {
    color: "#19b2ab !important",
  },
  typographyLabel: {
    marginLeft: "20px",
    color: "#FFFFFF",
    fontFamily: "Inter",
    "& .MuiTypography-body1": {
      fontSize: "12px !important",
    },
  },
  radioLabel: {
    color: "#FFFFFF",
    fontFamily: "Inter",
    "& .MuiTypography-body1": {
      fontSize: "10px !important",
    },
  },
}));

const EngineSelector = React.memo(
  ({
    t = null,
    selectedEngine = [],
    setSelectedEngine = null,
    layoutStyle,
    isDownloading,
    currentVessel,
  }) => {
    const styles = useStyles();
    const classes = { ...styles, ...layoutStyle };
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const engineSelect = (e, position) => {
      setSelectedEngine(position);
    };

    const engineList = [
      {
        position: ENGINE_POSITION.PORT,
        label: localizationKeys.PortEngine,
      },
      {
        position: ENGINE_POSITION.CENTER,
        label: localizationKeys.Engine_upper,
      },
      {
        position: ENGINE_POSITION.STARBOARD,
        label: localizationKeys.StarboardEngine,
      },
    ];

    const getEngines = (noME) => {
      let index = [];
      switch (noME) {
        case 3:
          index = [0, 1, 2];
          break;
        case 2:
          index = [0, 2];
          break;
        default:
          index = [1];
      }
      return engineList.filter((e, i) => index.includes(i));
    };

    const EngineRadio = () => {
      return (
        <>
          <Grid item sm={4} md={12} className={classes.engineSelectorContainer}>
            <Typography variant="body2">{t(localizationKeys.EngineSelection_lower)}:</Typography>
          </Grid>
          <Grid
            item
            sm={8}
            md={12}
            container
            direction="row"
            style={{
              justifyContent: isSmall ? "left" : "center",
              display: "flex",
            }}
          >
            <RadioGroup
              name="engine-select-radio-group"
              defaultValue={
                currentVessel?.NoME === 1 ? ENGINE_POSITION.CENTER : ENGINE_POSITION.PORT
              }
              value={selectedEngine}
              onChange={(e) => engineSelect(e, e.target.value)}
              row={true}
            >
              {(() => {
                return getEngines(currentVessel?.NoME).map((d, i) => {
                  if (currentVessel?.NoME <= 1) {
                    return (
                      <FormControlLabel
                        key={`engine-radio-${i}`}
                        control={<Typography className={classes.radio} />}
                        label={`${t(d.label)}`}
                        value={d.position}
                        className={classes.typographyLabel}
                      />
                    );
                  } else {
                    return (
                      <FormControlLabel
                        key={`engine-radio-${i}`}
                        control={<Radio className={classes.radio} />}
                        label={`${t(d.label)}`}
                        value={d.position}
                        className={classes.radioLabel}
                      />
                    );
                  }
                });
              })()}
            </RadioGroup>
          </Grid>
          </>
      );
    };

    return (
      <Paper
        className={classes.paper + " " + classes.paperHeightEngine}
        style={{ minHeight: isSmall ? null : "100px" }}
      >
        <Grid
          id="Controller"
          container
          spacing={2}
          direction={"row"}
          alignItems={isSmall ? "center" : null}
          justifyContent={isXs ? "center" : null}
        >
          <EngineRadio />
        </Grid>
      </Paper>
    );
  }
);

EngineSelector.propTypes = {
  t: PropTypes.func.isRequired,
  selectedEngine: PropTypes.string,
  setSelectedEngine: PropTypes.func,
  layoutStyle: PropTypes.object,
  isDurationDisabled: PropTypes.bool,
  isNoData: PropTypes.bool,
  isDownloading: PropTypes.bool,
  graphData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedChart: PropTypes.string,
  currentVessel: PropTypes.object,
};

export default withTranslation()(EngineSelector);
