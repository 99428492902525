import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { svgContainer } from "../../../constants/trendGraph/buttonStyles";
const useStyles = makeStyles((theme) => ({
  svgContainer: svgContainer,
}));
export const ENABLE_COLOR = "#19b2ab";
export const DISABLE_COLOR = "#8c8c8c";

const CameraIcon = ({ color = DISABLE_COLOR, opacity = "0.5" }) => {
  const classes = useStyles();
  return (
    <div className={classes.svgContainer}>
      <svg
        width="25"
        height="25"
        viewBox="0 -4 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 7a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v1h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2V7zm5.5 11a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    </div>
  );
};

CameraIcon.propTypes = {
  color: PropTypes.string,
};

export default CameraIcon;
