import { axios_weather } from "./axiosSettings";

export const fetchWeatherData = async (lat, long) => {
  const params = {
    latitude: lat,
    longitude: long,
    current_weather: true,
    hourly: 'soil_temperature_0cm',
    cell_selection: 'sea',
    forecast_days: 2,
  };

  if (["staging", "product", "production"].includes(process.env.NODE_ENV)) {
    params.apikey = process.env.WEATHER_API_KEY;
  }

  const data = await axios_weather
    .get(`/forecast`, {
      params,
      timeout: 5000,
    })
    .catch((e) => {
      console.log(`Error: ${e.message}`);
    });

  return data?.data;
};
