import { ENGINE_CHART_LIST } from "../trendPage/engine/engineList";
import { useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { SEP_CHART_LIST } from "../../constants/trendGraph/sepLines";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import Select from "react-select";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { customGraphSelectorStyles } from "./graphSelector.jsx";
import { useTranslation } from "react-i18next";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType.js";

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    width: "100%",
    marginTop: "30px",
    fontSize: "12px",
    fontWeight: "300",
    fontFamily: "Inter",
  },
}));

export const ChartSelector = (props) => {
  const { selectedVessel, chartType, selectedGraph, setSelectedGraph, position, customCharts, selectedGraphType, isFetchingChartInfo } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedBefore, setSelectedBefore] = useState(null);
  const [options, setOptions] = useState([])
  const isNoME = !selectedVessel?.NoME;
  const chartBase = useCallback(() => {
    if (selectedGraphType === GRAPH_TYPE.SEP) {
      return SEP_CHART_LIST;
    } else if(selectedGraphType === GRAPH_TYPE.ENGINE) {
      return ENGINE_CHART_LIST;
    } else{
      return [];
    }
  }, [selectedGraphType]);

  useEffect(() => {
    if(selectedGraphType === GRAPH_TYPE.CUSTOM) {
      if(isFetchingChartInfo) return[];
      const chartList = customCharts?.charts?.charts;
      setOptions(chartList?.map((v) => {
        return {label: v.name, value: v.id};
      }));
    }else{
      if (!chartType) return [];
      if(selectedGraphType === GRAPH_TYPE.ENGINE && isNoME) return [];
      setOptions(Object.values(chartType).map((d) => {
        const chartData = chartBase().find((dd) => dd.id === d);
        return {
          label: t(chartData?.title),
          value: d,
        };
      }));
    }
  }, [chartType, position, selectedGraphType, JSON.stringify(customCharts), isFetchingChartInfo]);

  useEffect(() => {
    if(!selectedBefore || selectedGraphType === GRAPH_TYPE.ELECTRIC) {
      setSelectedBefore(selectedGraphType);
      return;
    }
    setSelectedGraph(options[0]?.value);
    setSelectedBefore(selectedGraphType);
  }, [selectedGraphType, options, isFetchingChartInfo]);

  const menuRef = useRef(null);
  useOnClickOutside([menuRef], () => {
    setIsMenuOpen(false);
  });
  return (
    <div ref={menuRef} className={classes.selectContainer}>
      <Select
        options={options}
        value={options.find((d) => d.value === selectedGraph)}
        onChange={(d) => {
          setSelectedGraph(d?.value);
        }}
        placeholder={"GRAPH SELECT"}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        styles={customGraphSelectorStyles}
        isSm={isSm}
        onMenuClose={() => setIsMenuOpen(false)}
        onMenuOpen={() => setIsMenuOpen(true)}
        menuIsOpen={isMenuOpen}
      />
    </div>
  );
};
