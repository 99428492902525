const FISHING_DEFAULT_ONLINE = (rotate) =>
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.128 10.624a1 1 0 0 0-.128.49V20a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8.886a1 1 0 0 0-.128-.49l-4-7.114a1 1 0 0 0-1.744 0l-4 7.114z" fill="#FF9F6D" stroke="#913500" stroke-width="2" stroke-linejoin="round" transform="rotate(${rotate} 12 12)"/>
  </svg>`;

const FISHING_DEFAULT_OFFLINE = (rotate) =>
  `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="rotate(${rotate} 12 12)">  
      <path opacity=".4" d="M7.128 10.624a1 1 0 0 0-.128.49V20a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-8.886a1 1 0 0 0-.128-.49l-4-7.114a1 1 0 0 0-1.744 0l-4 7.114z" fill="#913500" stroke="#913500" stroke-width="2" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 18a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm-1.618-4.855.855.855-.855.855a.54.54 0 1 0 .763.763l.855-.855.855.855a.54.54 0 1 0 .763-.763L12.763 14l.855-.855a.54.54 0 1 0-.763-.763l-.855.855-.855-.855a.54.54 0 1 0-.763.763z" fill="#020202" fill-opacity=".3"/>
    </g>
  </svg>`;

const FISHING_SELECTED_ONLINE = (rotate) =>
  `<svg width="36" height="39" viewBox="0 0 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#bd28dwsdma) transform="rotate(${rotate} 18 20)"">
      <path d="M10.795 15.328a1 1 0 0 0-.128.49v11.849a1 1 0 0 0 1 1h10.666a1 1 0 0 0 1-1V15.818a1 1 0 0 0-.128-.49l-5.333-9.485a1 1 0 0 0-1.744 0l-5.333 9.485z" fill="#FF9F6D" stroke="#913500" stroke-width="2" stroke-linejoin="round"/>
    </g>
    <defs>
      <filter id="bd28dwsdma" x="0" y="0" width="36" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation=".5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3031_49134"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="4"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
        <feBlend in2="effect1_dropShadow_3031_49134" result="effect2_dropShadow_3031_49134"/>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_3031_49134" result="shape"/>
      </filter>
    </defs>
  </svg>`;

const FISHING_SELECTED_OFFLINE = (rotate) =>
  `<svg width="36" height="39" viewBox="0 0 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#3ltpggmgza)" transform="rotate(${rotate} 18 20)">
      <path opacity=".7" d="M10.795 15.328a1 1 0 0 0-.128.49v11.849a1 1 0 0 0 1 1h10.666a1 1 0 0 0 1-1V15.818a1 1 0 0 0-.128-.49l-5.333-9.485a1 1 0 0 0-1.744 0l-5.333 9.485z" fill="#913500" stroke="#913500" stroke-width="2" stroke-linejoin="round" transform="rotate(${rotate} 12 12)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17 25a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm-2.023-6.07 1.07 1.07-1.07 1.07a.674.674 0 1 0 .954.953L17 20.953l1.07 1.07a.674.674 0 1 0 .953-.954L17.953 20l1.07-1.07a.674.674 0 1 0-.954-.953L17 19.047l-1.07-1.07a.674.674 0 1 0-.953.954z" fill="#020202" fill-opacity=".5"/>
    </g>
    <defs>
      <filter id="3ltpggmgza" x="0" y="0" width="36" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation=".5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3031_49155"/>
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="4"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
        <feBlend in2="effect1_dropShadow_3031_49155" result="effect2_dropShadow_3031_49155"/>
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_3031_49155" result="shape"/>
      </filter>
    </defs>
  </svg>`;

  export {FISHING_DEFAULT_ONLINE, FISHING_DEFAULT_OFFLINE, FISHING_SELECTED_ONLINE, FISHING_SELECTED_OFFLINE};