import { createSlice } from "@reduxjs/toolkit";
import Actions from "../action/windowDirectionTypeAction";

export const RW_TW_LIST = [
  {
    name: "RW",
    duration: 24,
    unit: "hours",
    dateTestId: "rw_button",
  },
  {
    name: "TW",
    duration: 2,
    unit: "hours",
    dataTestId: "tw_button",
  },
];

const windowDirectionTypeSlice = createSlice({
  name: "windowDirectionTypeController",
  initialState: {
    windowDirectionType: RW_TW_LIST[0],
  },
  reducers: {
    ...Actions,
  },
});

export const selectedWindowDirectionType = (state) =>
  state.windowDirectionTypeController.windowDirectionType;
export const { setSelectedWindowDirectionType } = windowDirectionTypeSlice.actions;

export default windowDirectionTypeSlice.reducer;
