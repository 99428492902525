import React from "react";
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import localizationKeys from "../../../i18n/localizationKeys.js";
import { ALARM_PAGE } from "../../../model/slice/alarmSlice.js";

const tabBase = (tabCount) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: `calc(100% / ${tabCount})`,
});

const useStyles = ({ tabCount, isMonitoringTabSelected }) =>
  makeStyles((theme) => ({
    tabBase: {
      height: "50px",
      borderRadius: "8px",
      [theme.breakpoints.down('sm')]: {
        height: "40px",
        borderRadius: "0 5px 5px 0",
      },
      backgroundColor: "rgba(44,58,72, 0.5)",
      cursor: "pointer",
      display: "flex",
      "& p": {
        color: "#ffffff",
        fontSize: "12px",
        fontWeight: "500",
        fontFamily: "Inter",
        [theme.breakpoints.down('sm')]: {
          fontSize: "8px",
        },
      },
      width: "100%",
    },
    tabFree: {
      ...tabBase(tabCount),
      height: "100%",
      borderRadius: "8px",
      "& p": {
        opacity: 0.7,
      },
    },
    tabSelected: {
      ...tabBase(tabCount),
      height: "100%",
      borderRadius: "8px",
      "& p": {
        opacity: 1.0,
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: isMonitoringTabSelected ? "0 5px 5px 0" : "5px",
      },
      backgroundColor: "#2c3a48",
    },
  }));

const tabSettings = (t) => [
  {
    name: t(localizationKeys.AlarmMonitoring_upper),
    id: ALARM_PAGE.MONITORING,
  },
  {
    name: t(localizationKeys.AlarmHistory_upper),
    id: ALARM_PAGE.HISTORY,
  },
  {
    name: t(localizationKeys.AlarmOperations_upper),
    id: ALARM_PAGE.OPERATION,
  },
];

const AlarmPageTab = ({ t, selectedTab, setSelectedTab }) => {
  const classes = useStyles({
    tabCount: tabSettings(t).length,
    isMonitoringTabSelected: selectedTab === ALARM_PAGE.MONITORING,
  })();

  const clickTab = (id) => {
    setSelectedTab(id);
  };

  return (
    <div className={classes.tabBase}>
      {tabSettings(t).map((d, i) => (
        <div
          className={i + 1 === selectedTab ? classes.tabSelected : classes.tabFree}
          key={`tab-data-${i + 1}`}
          onClick={() => clickTab(d.id)}
        >
          <p>{d.name}</p>
        </div>
      ))}
    </div>
  );
};

AlarmPageTab.propTypes = {
  t: PropTypes.func,
  setSelectedTab: PropTypes.func,
  selectedTab: PropTypes.number,
};

export default withTranslation()(AlarmPageTab);
