import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DurationPicker } from "../durationPicker/durationPicker.jsx";
import { COLORS } from "../../constants/colors.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getCargoDataDuration,
  setCargoDataDuration,
} from "../../model/slice/dataDurationSlice.js";
import dayjs from 'util/dayjs-init.js';
import { CSVDownloadForCargoPage } from "../cargo/csvDownload/csvDownload.jsx";
import { calDurations } from "../../constants/calDuration.js";
import { useOnClickOutside } from "../../hooks/useOnClickOutside.js";
import { CARGO_PAGES } from "../../constants/cargo/pages.js";

const useStyles = makeStyles({
  AdditionalContainer: {
    position: "fixed",
    top: "120px",
    right: "20px",
    width: "300px",
    height: "200px",
    background: COLORS.tabSelectorColor,
    zIndex: 110,
    outline: `solid 1px ${COLORS.white}`,
    borderRadius: "5px",
    padding: "20px 20px",
  },
  durationPickerContainer: {
    margin: "10px 5px",
  },
  downloadContainer: {
    margin: "10px 5px",
  },
});

export const AdditionalContainer = (props) => {
  const { vesselId, setOpen, selectedTab, selectedChart } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const cargoDuration = useSelector(getCargoDataDuration);
  const containerRef = useRef(null);
  const [startDate, setStartDate] = useState(
    cargoDuration.startDate === ""
      ? dayjs.utc().subtract(1, "M").toDate()
      : cargoDuration.startDate
  );
  const [endDate, setEndDate] = useState(
    cargoDuration.endDate === "" ? dayjs.utc().toDate() : cargoDuration.endDate
  );
  // close this container when click outside and not open duration picker
  useOnClickOutside([containerRef], () => {
    const popover = document.getElementsByClassName('bp5-popover-content');
    if(!popover[0]) setOpen(false)
  });

  useEffect(() => {
    let updateStartDate, updateEndDate;
    if (startDate === "" && endDate === "") {
      return;
    } else if (endDate === "") {
      updateStartDate = dayjs.utc(startDate).format();
      updateEndDate = dayjs.utc(startDate).endOf("d").format();
    } else if (startDate === "") {
      updateStartDate = dayjs.utc(endDate).format();
      updateEndDate = dayjs.utc(endDate).endOf("d").format();
    } else {
      updateStartDate = dayjs.utc(startDate).format();
      updateEndDate = dayjs.utc(endDate).format();
    }
    const interval = calDurations({startDate, endDate});
    dispatch(setCargoDataDuration({startDate: updateStartDate, endDate: updateEndDate, interval}));
  }, [startDate, endDate]);

  return (
    <Grid className={classes.AdditionalContainer} ref={containerRef}>
      <div className={classes.durationPickerContainer}>
        <span style={{ color: "white" }}>Select a Duration</span>
        <DurationPicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          withTimePicker={true}
          singleLine={true}
        />
      </div>
      <CSVDownloadForCargoPage
        vesselId={vesselId}
        startDate={startDate}
        endDate={endDate}
        fileType={selectedTab === CARGO_PAGES.TRANSPORT 
          ? CARGO_PAGES.TRANSPORT 
          : selectedChart}
      />
    </Grid>
  );
};

AdditionalContainer.propTypes = {
  vesselId: PropTypes.string,
  setOpen: PropTypes.func,
  selectedTab: PropTypes.string,
  selectedChart: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(null)])
};
