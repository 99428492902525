import React, { memo } from "react";
import { Grid, Slider } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  MIMIC_DEFAULT_SCALE,
  MIMIC_MAX_SCALE,
  MIMIC_MIN_SCALE,
  MIMIC_SCALE_STEP,
} from "../../../constants/cargo/mimic";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    width: "28px",
    height: "25%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "28px",
    height: "28px",
    border: "solid 1px #b3ddf3",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#40a9e0",
    fontSize: "22px",
    fontWeight: 100,
    zIndex: 100,
    cursor: "pointer",
  },
  scrollbar: {
    height: "50%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  slider: {
    margin: "15px 0",
    "& .MuiSlider-rail": {
      opacity: 0.5,
      backgroundColor: "#8ccbec",
      width: "1px",
    },
    "& .MuiSlider-thumb": {
      width: "21px",
      height: "21px",
      backgroundColor: "initial",
      backgroundImage:
        "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjEwLjY1OSIgY3k9IjEwLjQxOSIgcj0iOS4yMTMiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzQwQTlFMCIgc3Ryb2tlLXdpZHRoPSIxLjE1MiIvPgogICAgPGNpcmNsZSBjeD0iMTAuNjYiIGN5PSIxMC40MjEiIHI9IjYuMzM0IiBmaWxsPSIjZmZmIiBzdHJva2U9IiM0MEE5RTAiIHN0cm9rZS13aWR0aD0iMS4xNTIiLz4KPC9zdmc+Cg==')",
      "&.Mui-focusVisible, &:hover": {
        boxShadow: "none",
      },
    },
  },
}));

const Controls = memo((props) => {
  const { zoomIn, zoomOut, state } = props;
  const styles = useStyles();

  const handleChange = (evt, value) => {
    const scale = state?.scale ?? MIMIC_DEFAULT_SCALE;
    const zoomValue = Math.abs(value - scale).toFixed(4);
    if (value > scale) {
      zoomIn(zoomValue);
    } else {
      zoomOut(zoomValue);
    }
  };

  return (
    <Grid container className={styles.container}>
      <Grid
        item
        xs={12}
        className={styles.buttonContainer}
        style={{
          alignItems: "end",
        }}
      >
        <div className={styles.button} onClick={() => zoomIn(MIMIC_SCALE_STEP)}>
          +
        </div>
      </Grid>
      <Grid item xs={12} className={styles.scrollbar}>
        <div className={styles.slider}>
          <Slider
            orientation="vertical"
            value={state?.scale ?? MIMIC_DEFAULT_SCALE}
            track={false}
            onChange={handleChange}
            step={(MIMIC_MAX_SCALE - MIMIC_MIN_SCALE) / 50}
            min={MIMIC_MIN_SCALE}
            max={MIMIC_MAX_SCALE}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        className={styles.buttonContainer}
        style={{
          alignItems: "start",
        }}
      >
        <div className={styles.button} onClick={() => zoomOut(MIMIC_SCALE_STEP)}>
          -
        </div>
      </Grid>
    </Grid>
  );
});

export { Controls };
