import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { withTranslation } from "react-i18next";
import { Checkbox, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import localizationKeys from "../../../i18n/localizationKeys";
import * as PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { currentVesselValue } from "../../../model/slice/vesselSlice.js";

import clsx from "clsx";
import { logger } from "../../../api/logger";

const label = {
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#19b2ab",
  textAlign: "start",
};

const value = {
  fontFamily: "Inter",
  fontSize: "14px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  color: "#ffffff",
  fontWeight: "300",
};

const useStyles = makeStyles((theme) => ({
  dataSource: {
    textAlign: "right",
  },

  dataValue: {
    ...value,
  },
  offlineDataValue: {
    ...value,
    color: "#c8c8c8",
  },
  label: {
    ...label,
  },
  offlineLabel: {
    ...label,
    color: "#c8c8c8",
  },
  checkBoxDefault: {
    "&.MuiCheckbox-root": {
      color: "#19B2AB",
    },
  },
  checkBoxPort: {
    "&.MuiCheckbox-root": {
      color: "#ebad4f",
    },
  },
  checkBoxCenter: {
    "&.MuiCheckbox-root": {
      color: "#4fd8eb",
    },
  },
  checkBoxStarboard: {
    "&.MuiCheckbox-root": {
      color: "#eb4fc9",
    },
  },
  lineHeight: {
    lineHeight: "20px",
  },
  spacer: {
    height: "85px",
    // border: "solid white",
  },
  headSpacer: {
    height: "47px",
  },
  nameLabel: {
    fontSize: "12px",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    fontHeight: "normal",
    letterSpacing: "normal",
    whiteSpace: "nowrap",
    color: "#19b2ab",
  },
  nameLabelOneEngine: {
    fontSize: "12px",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    fontHeight: "normal",
    letterSpacing: "normal",
    whiteSpace: "nowrap",
    color: "#19b2ab",
    marginLeft: "40px",
  },
  engineRow: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  engineCheckBoxTable: {
    width: "25px",
    heigh: "25px",
  },
  centerLabel: {
    textAlign: "start",
    marginBottom: "5px",
    marginTop: "5px",
  },
  checkBoxContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
  },
  labelUppercase: {
    textTransform: "uppercase",
  },
}));

const EngineSelectPanel = ({
  setSelectEngines,
  selectEngines = [],
  baseEngines = [],
  vesselId,
  bhp,
  t = null,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let powerBaseValue = useSelector(currentVesselValue("powerBase", ""));
  if (bhp) powerBaseValue = "BHP"
  const powerBase = !powerBaseValue ? t(localizationKeys.Shp_upper) : powerBaseValue;

  const checkBoxChanged = (e, number) => {
    setSelectEngines((d) => {
      logger.info(`setSelectEngines checked: ${e.target.checked}`);
      return d.filter((dd) => dd !== number).concat(e.target.checked ? [number] : []);
    });
  };

  const CheckBoxLabel = ({ baseEngines }) => (
    <div className={classes.checkBoxContainer}>
      {baseEngines.map((d, i) => {
        if (baseEngines?.length === 1) {
          return (
            <div className={classes.engineRow} key={`engine-selector-${i}`}>
              <p className={classes.nameLabelOneEngine}>{d?.name}</p>
            </div>
          );
        } else {
          return (
            <div className={classes.engineRow} key={`engine-selector-${i}`}>
              <Checkbox
                size="small"
                checked={selectEngines.includes(d?.number)}
                onChange={(e) => checkBoxChanged(e, d?.number)}
                className={classes[`checkBox${d.position}`]}
              />
              <span className={classes.nameLabel}>{d?.name}</span>
            </div>
          );
        }
      })}
    </div>
  );

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
    >
      {baseEngines.length > 0 ? ( 
        <>
          <Grid lg={12} md={6}>
            <div className={classes.centerLabel}>
              <p className={clsx(classes.label, classes.labelUppercase)}>{`${t(
                localizationKeys.EngineSelection_lower
              )}`}</p>
            </div>
            <CheckBoxLabel baseEngines={baseEngines} />
          </Grid>

          <Grid lg={12} md={6} style={{ marginTop: "4px" }}>
            <div className={classes.centerLabel}>
              <p className={clsx(classes.label, classes.labelUppercase)}>{`${t(
                localizationKeys.Unit
              )}`}</p>
            </div>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td className={classes.dataSource}>
                    <p className={classes.label}>{t(localizationKeys.PowerBase_upper)}:</p>
                  </td>
                  <td>
                    <p className={classes.dataValue}>&nbsp;{powerBase}</p>
                  </td>
                </tr>
                <tr>
                  <td className={classes.dataSource}>
                    <p className={classes.label}>{t(localizationKeys.Speed_lower)}:</p>
                  </td>
                  <td>
                    <p className={classes.dataValue}>&nbsp;{t(localizationKeys.Og_upper)}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </>
        ) : null}
    </Grid>
  );
};

EngineSelectPanel.propTypes = {
  setSelectEngines: PropTypes.func.isRequired,
  baseEngines: PropTypes.array,
  t: PropTypes.func.isRequired,
  selectEngines: PropTypes.array.isRequired,
  isNoData: PropTypes.bool,
  isNoEngine: PropTypes.bool,
  vesselId: PropTypes.string,
  cppAngles: PropTypes.array,
  i18n: PropTypes.object,
};

export default withTranslation()(EngineSelectPanel);
