import { useEffect, useRef, useState } from "react";
import {
  fetchVesselLatestGpvtgData,
  fetchVesselLatestMainEngineData,
} from "../../../api/latestData";
import useInterval from "../../../hooks/useInterval";
import { logger } from "../../../api/logger";

export const useEngineLatestData = ({ vesselId }) => {
  const unmount = useRef(false);
  const [latestEngineData, setLatestEngineData] = useState();
  const [latestNavigationData, setLatestNavigationData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isNoEngine, setIsNoEngine] = useState(false);

  useEffect(() => {
    setIsNoEngine(false);
  }, [vesselId]);

  const fetchData = async () => {
    setIsLoading(true);
    const data = await Promise.all([
      fetchVesselLatestMainEngineData(vesselId),
      fetchVesselLatestGpvtgData(vesselId),
    ])
      .then((d) => {
        logger.info(`fetchVesselLatestData data: ${JSON.stringify(d)}`);
        setIsLoading(false);
        if (!d[0]?.latestMainEngineData?.length) {
          setIsNoEngine(true);
        }
        return d;
      })
      .catch(() => {
        setIsLoading(false);
      });

    unmount.current || setLatestEngineData(data[0]?.latestMainEngineData);
    unmount.current || setLatestNavigationData(data[1]?.latestGpvtgData);
  };

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [vesselId]);

  useInterval(fetchData, 60000, false);

  return {
    latestNavigationData,
    latestEngineData,
    isLoading,
    isNoEngine,
  };
};
