import React, { useEffect, useState } from "react";
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import Grid from '@mui/material/Unstable_Grid2';
import { fleetNavigationData } from "model/slice/fleetNavigationDataSlice";

import { SynthPaper, DataGuard } from "./styles";
import { VesselPerformance } from "./vesselPerformance";
import { CIIChart } from "./ciiChart";
import { CIIStatus } from "./ciiStatus";
import { VesselCard } from "./vesselCard";
import { VoyageHistory } from "./voyageHistory";
import { CiiClassKey } from "./ciiClassKey";
import { useSyntheticaData } from "./useSyntheticaData";
import { SHIP_COUNTRY } from "../../constants/vesselCountry";
import { fetchVesselLatestAisData } from "../../api/latestData";

export const SyntheticaCII = (props) => {
    const { vesselId } = useParams();
    const vesselInformation = useSelector(fleetNavigationData);

    const name = vesselInformation.fleetNavigationData.find((v) => v.vessel_id === vesselId)?.vessel_name;
    const data = useSyntheticaData({ vesselName: name });
    const [navData, setNavData] = useState({speedOverGround: 0, mmsi: "0"});

    useEffect(() => {
        const fetchData = async (v_id) => {
            const fetchedNavData = await fetchVesselLatestAisData(v_id);
            setNavData(fetchedNavData.latestAisData[0].data[0]);
        }
        fetchData(vesselId);
    }, [vesselId]);

    const countryCode = SHIP_COUNTRY[navData.mmsi.toString().substring(0, 3)]?.ShortCode ?? "";

    const styles = {
        pageWrapper: {
            overflow: "hidden",
            height: "fit-content",
            color: "white",
            margin: "0 200px 36px 123px",
        }
    }
    const paperElevation = 3;

    return (
        <Box sx={styles.pageWrapper}>
            <Grid container spacing={4}>
                <Grid xs={12}>
                    <SynthPaper elevation={paperElevation} sx={{height: "56px"}}>
                        <CiiClassKey></CiiClassKey>
                    </SynthPaper>
                </Grid>
                <Grid xs={12} lg={6}>
                    <SynthPaper elevation={paperElevation} sx={{ height: "235px" }}>
                        <DataGuard data={data}>
                            <VesselCard data={data} vesselId={vesselId} speedOverGround={navData.speedOverGround} countryCode={countryCode}/>
                        </DataGuard>
                    </SynthPaper>
                </Grid>
                <Grid xs={12} lg={6}>
                    <SynthPaper elevation={paperElevation} sx={{ height: "235px" }}>
                        <DataGuard data={data}>
                            <CIIStatus data={data} />
                        </DataGuard>
                    </SynthPaper>
                </Grid>
                <Grid xs={12}>
                    <SynthPaper elevation={paperElevation}>
                        <DataGuard data={data}>
                            <VesselPerformance data={data} />
                        </DataGuard>
                    </SynthPaper>
                </Grid>
                <Grid xs={12}>
                    <SynthPaper elevation={paperElevation} sx={{ height: "370px" }}>
                        <DataGuard data={data}>
                            <CIIChart data={data} />
                        </DataGuard>
                    </SynthPaper>
                </Grid>
                <Grid xs={12}>
                    <SynthPaper elevation={paperElevation} sx={{ maxHeight: "500px" }}>
                        <DataGuard data={data}>
                            <VoyageHistory data={data} />
                        </DataGuard>
                    </SynthPaper>
                </Grid>
            </Grid>
        </Box>
    );
}