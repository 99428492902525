import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../constants/colors";

const inlineTabBase = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100px",
};

const useInlineStyles = makeStyles((theme) => ({
  tabBase: {
    height: "30px",
    backgroundColor: COLORS.tabSelectorColor,
    cursor: "pointer",
    display: "flex",
    "& p": {
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: "500",
      fontFamily: "Inter",
    },
    "user-select": "none",
    "-moz-user-select": "none",
    "-webkit-user-select": "none",
  },
  tabFree: {
    ...inlineTabBase,
    height: "100%",
    "& p": {
      opacity: 0.7,
    },
    borderBottom: "solid 6px rgba(1,1,1,0)",
  },
  tabSelected: {
    ...inlineTabBase,
    height: "100%",
    "& p": {
      opacity: 1.0,
      color: COLORS.white,
    },
    borderBottom: `solid 3px ${COLORS.greenishBlue}`,
  },
}));

// separate inline tabSelector because conflict about screenshot

export const InlineTabSelector = (props) => {
  const { tabs, selectedOne, clickHandler } = props;
  const classes = useInlineStyles();

  return (
    <div id="InlineTabContainer" className={classes.tabContainer}>
      <div className={classes.tabBase}>
        {tabs.map((d) => (
          <div
            className={d.id === selectedOne ? classes.tabSelected : classes.tabFree}
            key={`tab-data-${d.id}`}
            onClick={() => clickHandler(d.id)}
          >
            <p>{d.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

InlineTabSelector.propTypes = {
  tabs: PropTypes.array,
  selectedOne: PropTypes.string,
  clickHandler: PropTypes.func,
};
