import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../../constants/colors";
import { CustomBlackButton, CustomRedButton } from "./customButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys";

const useStyles = makeStyles({
  container: {
    background: COLORS.confirmationModalColor,
    color: COLORS.white,
    position: "absolute",
    top: "20%",
    left: "30%",
    width: "700px",
    height: "200px",
    fontSize: "20px",
    padding: "44px 0 0 0",
  },
  messageArea: {
    textAlign: "center",
    width: "100%",
  },
  buttonHolder: {
    width: "250px",
    margin: "0 auto",
  },
});

export const DeleteEmailConfirmationModal = React.forwardRef((props,ref) => {
  const { setExecute, setIsOpenConfirmation } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid ref={ref} container className={classes.container}>
      <div className={classes.messageArea}>
        <span>{t(localizationKeys.DeleteEmailConfirmation1st)}</span>
        <p style={{ height: "10px" }}></p>
        <span>{t(localizationKeys.DeleteEmailConfirmation2nd)}</span>
      </div>
      <Grid container justifyContent="center">
        <Grid
          className={classes.buttonHolder}
          onClick={() => {
            setIsOpenConfirmation(false);
            setExecute(false);
          }}
        >
          <CustomBlackButton>{t(localizationKeys.ReturnToChannelOptions)}</CustomBlackButton>
        </Grid>
        <Grid className={classes.buttonHolder}>
          <CustomRedButton
            onClick={() => {
              setIsOpenConfirmation(false);
              setExecute(true);
            }}
          >
            <DeleteIcon />
            {t(localizationKeys.RemoveEmail)}
          </CustomRedButton>
        </Grid>
      </Grid>
    </Grid>
  );
});

DeleteEmailConfirmationModal.propTypes = {
  setExecute: PropTypes.func,
  setIsOpenConfirmation: PropTypes.func,
};
