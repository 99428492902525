import { Buffer } from "buffer";

import { SHIP_TYPE, SHIP_GROUP_TYPE } from "../../constants/vesselShipType.js";

import {
  CARGO_DEFAULT_ONLINE,
  CARGO_DEFAULT_OFFLINE,
  CARGO_SELECTED_ONLINE,
  CARGO_SELECTED_OFFLINE,
} from "../../../assets/images/vessels/vessel-icons/icon-cargo.js";
import {
  FISHING_DEFAULT_ONLINE,
  FISHING_DEFAULT_OFFLINE,
  FISHING_SELECTED_ONLINE,
  FISHING_SELECTED_OFFLINE,
} from "../../../assets/images/vessels/vessel-icons/icon-fishing.js";
import {
  HIGH_SPEED_DEFAULT_ONLINE,
  HIGH_SPEED_DEFAULT_OFFLINE,
  HIGH_SPEED_SELECTED_ONLINE,
  HIGH_SPEED_SELECTED_OFFLINE,
} from "../../../assets/images/vessels/vessel-icons/icon-highSpeed.js";
import {
  PASSENGER_DEFAULT_ONLINE,
  PASSENGER_DEFAULT_OFFLINE,
  PASSENGER_SELECTED_ONLINE,
  PASSENGER_SELECTED_OFFLINE,
} from "../../../assets/images/vessels/vessel-icons/icon-passenger.js";
import {
  TANKER_DEFAULT_ONLINE,
  TANKER_DEFAULT_OFFLINE,
  TANKER_SELECTED_ONLINE,
  TANKER_SELECTED_OFFLINE,
} from "../../../assets/images/vessels/vessel-icons/icon-tanker.js";
import {
  TUG_PILOT_DEFAULT_ONLINE,
  TUG_PILOT_DEFAULT_OFFLINE,
  TUG_PILOT_SELECTED_ONLINE,
  TUG_PILOT_SELECTED_OFFLINE,
} from "../../../assets/images/vessels/vessel-icons/icon-tugPilot.js";
import {
  UNSPECIFIED_DEFAULT_ONLINE,
  UNSPECIFIED_DEFAULT_OFFLINE,
  UNSPECIFIED_SELECTED_ONLINE,
  UNSPECIFIED_SELECTED_OFFLINE,
} from "../../../assets/images/vessels/vessel-icons/icon-unspecified.js";
import {
  YACHT_DEFAULT_ONLINE,
  YACHT_DEFAULT_OFFLINE,
  YACHT_SELECTED_ONLINE,
  YACHT_SELECTED_OFFLINE,
} from "../../../assets/images/vessels/vessel-icons/icon-yacht.js";

const VesselIcon = (
  shipType = 0,
  rotate = 0,
  scale = 0.9,
  isVesselDisconnected = true,
  isSelected = false
) => {
  const type = SHIP_TYPE[shipType];
  const groupType = SHIP_GROUP_TYPE[type];

  let mapping = {};
  if (isSelected) {
    !isVesselDisconnected
      ? (mapping = {
          Passenger: PASSENGER_SELECTED_ONLINE(rotate),
          Yacht: YACHT_SELECTED_ONLINE(rotate),
          Cargo: CARGO_SELECTED_ONLINE(rotate),
          Fishing: FISHING_SELECTED_ONLINE(rotate),
          "High Speed": HIGH_SPEED_SELECTED_ONLINE(rotate),
          Tanker: TANKER_SELECTED_ONLINE(rotate),
          "Tug Pilot": TUG_PILOT_SELECTED_ONLINE(rotate),
          "": UNSPECIFIED_SELECTED_ONLINE(rotate),
        })
      : (mapping = {
          Passenger: PASSENGER_SELECTED_OFFLINE(rotate),
          Yacht: YACHT_SELECTED_OFFLINE(rotate),
          Cargo: CARGO_SELECTED_OFFLINE(rotate),
          Fishing: FISHING_SELECTED_OFFLINE(rotate),
          "High Speed": HIGH_SPEED_SELECTED_OFFLINE(rotate),
          Tanker: TANKER_SELECTED_OFFLINE(rotate),
          "Tug Pilot": TUG_PILOT_SELECTED_OFFLINE(rotate),
          "": UNSPECIFIED_SELECTED_OFFLINE(rotate),
        });
  } else {
    !isVesselDisconnected
      ? (mapping = {
          Passenger: PASSENGER_DEFAULT_ONLINE(rotate),
          Yacht: YACHT_DEFAULT_ONLINE(rotate),
          Cargo: CARGO_DEFAULT_ONLINE(rotate),
          Fishing: FISHING_DEFAULT_ONLINE(rotate),
          "High Speed": HIGH_SPEED_DEFAULT_ONLINE(rotate),
          Tanker: TANKER_DEFAULT_ONLINE(rotate),
          "Tug Pilot": TUG_PILOT_DEFAULT_ONLINE(rotate),
          "": UNSPECIFIED_DEFAULT_ONLINE(rotate),
        })
      : (mapping = {
          Passenger: PASSENGER_DEFAULT_OFFLINE(rotate),
          Yacht: YACHT_DEFAULT_OFFLINE(rotate),
          Cargo: CARGO_DEFAULT_OFFLINE(rotate),
          Fishing: FISHING_DEFAULT_OFFLINE(rotate),
          "High Speed": HIGH_SPEED_DEFAULT_OFFLINE(rotate),
          Tanker: TANKER_DEFAULT_OFFLINE(rotate),
          "Tug Pilot": TUG_PILOT_DEFAULT_OFFLINE(rotate),
          "": UNSPECIFIED_DEFAULT_OFFLINE(rotate),
        });
  }

  return (
    "data:image/svg+xml;base64," + Buffer.from(mapping[groupType], "binary").toString("base64")
  );
};

export default VesselIcon;
