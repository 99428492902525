import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

//Image imports
import VesselIcon from "../vesselImages/vesselIcon.js";
import {VesselFlagAisMid} from "../vesselImages/vesselFlag.js";

//Const imports
import { SHIP_COUNTRY } from "../../constants/vesselCountry.js";
import Thumbnail from "./thumbnail.jsx";
import { logger } from "../../api/logger.js";

const useStyles = makeStyles((theme) => ({
  vesselImageHolder: {
    width: "100%",
    height: "100%",
    position: "relative",
    background: "#21262f"
  },
  vesselImage: {
    width: "100%",
    height: "100%",
  },
  iconHolder: {
    position: "absolute",
    height: "40px",
    width: "40px",
    background: "#19b2ab",
    borderRadius: "50px",
    top: "11px",
    right: "18px",
    display: "block",
    textAlign: "center",
  },
  icon: {
    transform: "rotate(90deg)",
    marginTop: "13%",
    marginLeft: "4px",
    width: "30px",
    height: "30px",
  },
  iconNoRotate: {
    marginTop: "2%",
  },
  flag: {
    width: "100%",
    height: "100%",
  },
  flagHolder: {
    position: "absolute",
    height: "40px",
    width: "40px",
    borderRadius: "50px",
    top: "11px",
    left: "18px",
    display: "block",
    textAlign: "center",
  },
  countryNameHolder: {
    position: "absolute",
    height: "40px",
    width: "40px",
    borderRadius: "50px",
    top: "11px",
    left: "18px",
    display: "block",
    textAlign: "center",
    background: "#2834408c",
    color: "#ffffff",
    paddingTop: "13px",
    fontSize: "12px",
    fontFamily: "Inter",
  },
}));

const VesselImagePanel = React.memo((props) => {
  const { vesselId, aisData, isSEPvessel } = props;
  const DEFAULT_SHIP_TYPE = 0; //Defaults to unknown ship
  const styles = useStyles();
  const classes = { ...styles, ...props.classes };
  const [shipType, setShipType] = React.useState();
  const [countryCode, setCountryCode] = React.useState();

  React.useEffect(() => {
    if (aisData.mmsi === undefined || aisData.shipType === undefined) {
      setShipType(DEFAULT_SHIP_TYPE); //Set Ship type to unknown
      return;
    }

    logger.info(`VesselImagePanel mmsi: ${aisData.mmsi}`);

    setShipType(aisData.shipType);
    setCountryCode(aisData.mmsi.toString().substring(0, 3));
  }, [props.aisData]);

  return (
    <React.Fragment>
      {(() => {
        if (shipType !== undefined) {
          return (
            <div className={classes.vesselImageHolder}>
              <Thumbnail vesselId={vesselId} vesselType={aisData.shipType} />
              <div className={classes.iconHolder}>
                <img
                  src={VesselIcon(isSEPvessel ? 52 : shipType, 0, 0.7, 0, 0)}
                  className={classes.icon}
                />
              </div>
              <div className={classes.flagHolder}>
                {countryCode ? <img src={VesselFlagAisMid(countryCode)} className={classes.flag} /> : ""}
              </div>
              <div className={classes.countryNameHolder}>
                {countryCode ? SHIP_COUNTRY[countryCode]?.ShortCode : ""}
              </div>
            </div>
          );
        }
      })()}
    </React.Fragment>
  );
});

VesselImagePanel.propTypes = {
  vesselId: PropTypes.string,
  aisData: PropTypes.object,
};

export default VesselImagePanel;
