import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  resetDurationOption,
  setDataDuration,
  setElectricDataDuration,
} from "../../../model/slice/dataDurationSlice.js";
import { DURATION } from "./durationOption.js";

// For reset duration select redux state.
// This custom hooks puts on the each trend graph component.
// Especially mobile view, we will provide duration or some controller as drawer.
// If drawer is hidden, state will disposed. So, reset function was seperated.
// When user unmount trend graph component, this custom hook runs then deleted current duration status.
export const useDurationReset = (type) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      //CLEANUP to avoid double refresh
      //SAVE empty to redux state if component is unmounted.
      //This is important to avoid misleading values. If duration controller is not visible on the page,
      //Then it is just logical that there will be no startDate and endDate saved anywhere on the app.
      type === DURATION.ENGINE &&
        dispatch(
          setDataDuration({
            startDate: "",
            endDate: "",
          })
        );

      type === DURATION.ELECTRIC &&
        dispatch(
          setElectricDataDuration({
            startDate: "",
            endDate: "",
          })
        );

      dispatch(resetDurationOption());
    };
  }, []);
};
