import { LOGGER_LEVEL } from "../constants/logger";
import { axios_iapi } from "./axiosSettings";

const sendLogs = async (level, message) => {
  const params = {
    level,
    message,
  };
  try {
    const res = await axios_iapi.post("/logger", params);
    return { status: res.status };
  } catch (e) {
    return { logger: e };
  }
};

export const logger = {
  async info(message) {
    if (process.env.NODE_ENV === "product") return;
    await sendLogs(LOGGER_LEVEL.INFO, message);
  },

  async error(message) {
    await sendLogs(LOGGER_LEVEL.ERROR, message);
  },
};
