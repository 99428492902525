import { createSlice } from "@reduxjs/toolkit";
import Actions from "../action/chartTypeAction";

const chartTypeSlice = createSlice({
  name: "chartTypeController",
  initialState: {
    chartType: "",
  },
  reducers: {
    ...Actions,
  },
});

export const selectedChartType = (state) => state.chartTypeController.chartType;
export const { setChartType } = chartTypeSlice.actions;
export default chartTypeSlice.reducer;
