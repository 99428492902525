import React, { memo, useMemo, useEffect, useState, useRef } from "react";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useMediaQuery,
} from "@mui/material";
import localizationKeys from "../../i18n/localizationKeys.js";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {
  TableCellStyled,
  TableRowStyled,
  TableCellStyledForFunction,
} from "./utils/tableStyled.jsx";
import constants from "./utils/constants.js";
import { getComparator, stableSort, channelListHeader } from "./utils/helper.js";
import { useDispatch, useSelector } from "react-redux";
import { getSearchButtonSelector } from "../../model/slice/alarmSlice";
import {
  channelModData,
  isChannelModFetching,
  filteredChannelData,
  currentWindowPosition,
  currentTablePosition,
  setCurrentWindowPosition,
  setCurrentTablePosition,
  currentChannelPage,
  setCurrentChannelPage,
} from "../../model/slice/channelSlice";
import { fetchChannelModAsync } from "../../model/async/channelAsync";
import clsx from "clsx";
import PageButton from "../common/listUtil/pageButton.jsx";
import { useOnClickOutside } from "../../hooks/useOnClickOutside.js";

import SortIcon from "../../../assets/images/alarmIcon/sort-icon.svg";
import SortIconPrimary from "../../../assets/images/alarmIcon/sort-icon-primary.svg";
import ResetIcon from "../../../assets/images/alarmIcon/reset-icon.svg";
import ResetIconPrimary from "../../../assets/images/alarmIcon/reset-icon-primary.svg";
import CloseIcon from "../../../assets/images/alarmIcon/close-icon.svg";
import { MobileSortIcon } from "../common/listUtil/mobileSortIcon.jsx";
import EditIcon from "../../../assets/images/alarmIcon/edit-icon.svg";
import ChannelOptions from "./channelAlertSetting/channelOptions.jsx";
import SortDownIconSetColor from "../common/SortDownIconSetColor.jsx";
import SortUpIconSetColor from "../common/SortUpIconSetColor.jsx";
import { paginationStyle } from "../common/listUtil/paginationStyle.js"
import notificationIcon from "../../../assets/images/alarmIcon/notification-icon.svg"
import { getAlertSettings } from "../../api/alert.js";

const pageButtonBase = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "10px",
};

const settingHolder = {
  padding: "10px 0px",
  backgroundColor: "rgba(44, 58, 72, 1.0)",
  position: "absolute",
  color: "#ffffff",
  fontSize: "12px",
};

const useStyles = makeStyles((theme) => ({
  headerHolder: {
    height: "38px",
    backgroundColor: "#2c3a48",
    [theme.breakpoints.down('sm')]: {
      height: "24px",
    },
    "& .MuiTableCell-root": {
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
      color: "#ffffff",
      [theme.breakpoints.down('sm')]: {
        fontSize: "10px",
      },
    },
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
  sortableColumnHeader: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  columnHeader: {},
  columnHeaderSort: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  dataHolder: {
    "& .MuiTableCell-root": {
      fontSize: "12px",
      fontWeight: "300",
      fontFamily: "Inter",
      fontStretch: "normal",
      lineHeight: "normal",
      color: "#ffffff",
      [theme.breakpoints.down('sm')]: {
        fontSize: "8px",
      },
    },
  },
  noAlarmStyle: {
    paddingTop: "50px",
    fontSize: "16px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tablepagination: {
    paddingRight: "30px",
    "& .MuiTablePagination-caption": {
      color: "#FFFFFF",
      fontSize: "12px",
      fontFamily: "Inter",
      fontWeight: "300",
    },
    "& .MuiTablePagination-actions": {
      display: "none",
    },
  },
  pageButton: {
    ...pageButtonBase,
    "& .MuiSvgIcon-root": {
      color: "#FFFFFF",
    },
  },
  disabledPageButton: {
    ...pageButtonBase,
    "& .MuisvgIcon-root": {
      color: "rgba(255,255,255,0.3)",
    },
  },
  buttonGrid: {
    //alignItems: "flex-start",
    justifyContent: "center",
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
  backButtonAdjustment: {
    "& .MuisvgIcon-root": {
      paddingLeft: "13px",
    },
  },
  japaneseTableTitle: {
    whiteSpace: "nowrap",
  },
  controlRow: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "inline-block",
    [theme.breakpoints.down('sm')]: {
      height: "10px",
      width: "10px",
    },
  },
  userSettingHolder: {
    ...settingHolder,
    top: "44px",
    width: "250px",
    right: "40px",
    borderRadius: "2px",
    [theme.breakpoints.down('md')]: {
      top: "38px",
      right: "5%",
    },
    [theme.breakpoints.down('sm')]: {
      top: "auto",
      bottom: "8%",
      right: "8%",
      position: "fixed",
    },
  },
  settingsTable: {
    width: "100%",
    borderSpacing: "0",
    // borderRadius: "10px"
  },
  settingRow: {
    height: "35px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(38, 48, 59) !important",
    },
  },
  selectColor: {
    backgroundColor: "rgb(33, 38, 47) !important",
  },
  mobileSort: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "8px",
    position: "fixed",
    right: "30px",
    bottom: "60px",
    width: "30px",
    height: "30px",
    background: "#2c3a48",
    "&:hover": {
      background: "#2c3a48",
    },
  },
  mobileSortIcon: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    display: "inline-block",
  },
  mobileRestSort: {
    marginTop: "10px",
    width: "60%",
    height: "30px",
    borderRadius: "5px 5px 5px 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#21262f",
    "&:hover": {
      background: "#21262f",
    },
  },
  settingRowMobile: {
    height: "35px",
    cursor: "pointer",
  },
  mobileCloseIcon: {
    width: "20px",
    borderRadius: "5px 5px 5px 5px",
    display: "flex",
    justifyContent: "right",
  },
  arrowIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rowLeft: {
    width: "25px",
    "& > img": {
      position: "relative",
      left: "3px",
    },
    editIcon: {
      width: "20px",
      height: "20px",
      backgroundColor: "rgba(0, 0, 0, 0)",
      border: "1px solid #000",
      borderRadius: "50%",
    },
  },
  whiteText: {
    color: '#bec2c5',
  },
}));

// https://material-ui.com/components/tables/
// material ui table sample
export const ChannelList = memo((props) => {
  const { vesselId, i18n, height } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'));
  const isXsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const channelLatestList = useSelector(channelModData);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("chNo");
  const dispatch = useDispatch();
  const filteredList = useSelector(filteredChannelData);
  const isChannelLatestFetchLoading = useSelector(isChannelModFetching);
  const searchButtonFlag = useSelector(getSearchButtonSelector);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isDisplaySortDialog, setIsDisplaySortDialog] = useState(false);
  const menuRef = useRef(null);
  const currentWinPos = useSelector(currentWindowPosition);
  const currentTablePos = useSelector(currentTablePosition);
  const page = useSelector(currentChannelPage);
  const setPage = (d) => dispatch(setCurrentChannelPage(d));
  let updateFlag = true;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedLow] = useState();
  const [isExistingThresholdList, setIsExistingThresholdList] = useState([]);
  const intervalId = useRef(null);

  const openModal = async (row) => {
    setSelectedLow(row?.chNo);
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setSelectedLow(null);
    setIsModalOpen(false);
  };

  const fetchThresholdData = async () => {
    if(!vesselId) return;
    const { alertSettings } = await getAlertSettings(vesselId);
    const channelWithThresholdList = alertSettings?.filter((e) => (e?.alertThresholds && e.alertThresholds.length > 0)).map((e) => e.channelNo);
    setIsExistingThresholdList(channelWithThresholdList);
  }

  useEffect(() => {
    const windowScrollHandler = () => {
      const y = window.scrollY;
      if (y !== 0) dispatch(setCurrentWindowPosition(y));
    };
    window.addEventListener("scroll", windowScrollHandler);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }
      window.removeEventListener("scroll", windowScrollHandler);
    };
  }, []);

  // while user edit the channel option, data update is stop.
  useEffect(() => {
    if (!vesselId) return;
    if(intervalId.current){
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    dispatch(fetchChannelModAsync({ vesselId }));
    intervalId.current = setInterval(() => dispatch(fetchChannelModAsync({ vesselId })), 60 * 1000);
    fetchThresholdData(vesselId);

    return () => {
      clearInterval(intervalId.current);
      intervalId.current = null;
    };
  }, [vesselId]);

  useEffect(() => {
    if (isModalOpen) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    } else if(!intervalId.current) {
      // do the data fetch immediately at first time.
      dispatch(fetchChannelModAsync({ vesselId }));
      // set schedule.
      intervalId.current = setInterval(() => dispatch(fetchChannelModAsync({ vesselId })), 60 * 1000);
      fetchThresholdData(vesselId);
    }
  }, [isModalOpen]);

  useOnClickOutside([menuRef], () => {
    setIsDisplaySortDialog(false);
  });

  const handleRequestSort = (property, sortable) => (event) => {
    if (!sortable) return;
    const isAsc = orderBy === property && order === constants.ORDER.ASC;
    setOrder(isAsc ? constants.ORDER.DESC : constants.ORDER.ASC);
    setOrderBy(property);
  };

  const ResetSort = () => {
    setOrder("desc");
    setOrderBy("chNo");
  };

  const CloseDialog = () => {
    setIsDisplaySortDialog(false);
  };

  const handleRequestSortForMenu = (property, selectorder) => (event) => {
    setOrder(selectorder);
    setOrderBy(property);
  };

  const displaySortDialog = () => {
    if (isDisplaySortDialog) {
      return (
        <div className={classes.userSettingHolder} ref={menuRef}>
          <table className={classes.settingsTable}>
            <tbody>
              {isXsDown && (
                <tr className={`${classes.settingRowMobile}`}>
                  <td colSpan="2" align="right" valign="top">
                    <div className={classes.mobileCloseIcon} onClick={CloseDialog}>
                      <img
                        src={CloseIcon}
                        className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
                      />
                    </div>
                  </td>
                </tr>
              )}
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.HEADER_ID.CHANNEL_NUMBER && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.HEADER_ID.CHANNEL_NUMBER,
                  constants.ORDER.DESC
                )}
              >
                <td className={classes.rowLeft}>
                <SortDownIconSetColor color={orderBy === constants.HEADER_ID.CHANNEL_NUMBER && order === constants.ORDER.DESC? "white" : "#bec2c5"} /> 
                </td>
                <td className={`${orderBy !== constants.HEADER_ID.CHANNEL_NUMBER || order !== constants.ORDER.DESC? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortAscendingNo)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.HEADER_ID.CHANNEL_NUMBER && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.HEADER_ID.CHANNEL_NUMBER,
                  constants.ORDER.ASC
                )}
              >
                <td className={classes.rowLeft}>
                <SortUpIconSetColor color={orderBy === constants.HEADER_ID.CHANNEL_NUMBER && order === constants.ORDER.ASC? "white" : "#bec2c5"} /> 
                </td>
                <td className={`${orderBy !== constants.HEADER_ID.CHANNEL_NUMBER || order !== constants.ORDER.ASC? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortDescendingNo)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.HEADER_ID.CHANNEL_NAME && order === constants.ORDER.DESC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.HEADER_ID.CHANNEL_NAME,
                  constants.ORDER.DESC
                )}
              >
                <td className={`${classes.rowLeft} ${orderBy !== constants.HEADER_ID.CHANNEL_NAME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                  &ensp;A
                </td>
                <td className={`${orderBy !== constants.HEADER_ID.CHANNEL_NAME || order !== constants.ORDER.DESC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortAlarmItemAZ)}
                </td>
              </tr>
              <tr
                className={`${classes.settingRow} ${
                  orderBy === constants.HEADER_ID.CHANNEL_NAME && order === constants.ORDER.ASC
                    ? classes.selectColor
                    : ""
                }`}
                onClick={handleRequestSortForMenu(
                  constants.HEADER_ID.CHANNEL_NAME,
                  constants.ORDER.ASC
                )}
              >
                <td className={`${classes.rowLeft} ${orderBy !== constants.HEADER_ID.CHANNEL_NAME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  &ensp;Z
                </td>
                <td className={`${orderBy !== constants.HEADER_ID.CHANNEL_NAME || order !== constants.ORDER.ASC ? classes.whiteText : ""}`}>
                  {t(localizationKeys.SortAlarmItemZA)}
                </td>
              </tr>
              {isXsDown && (
                <tr className={`${classes.settingRowMobile}`}>
                  <td colSpan="2" align="center">
                    <div className={classes.mobileRestSort} onClick={ResetSort}>
                      <img
                        src={ResetIcon}
                        className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
                      />
                      {t(localizationKeys.ResetSort)}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  const EnhancedTableHeader = () => {
    return (
      <TableHead>
        <TableRow className={classes.headerHolder}>
          {channelListHeader(t, isSmDown)
            .filter((a) => isMdUp || !a.hideOnSmallDevice)
            .map((d, i) => (
              <TableCellStyled
                key={`alarm-operation-table-header-${i}`}
                align={d.align}
                colSpan={d.id === constants.HEADER_ID.VALUE ? 3 : 1}
                sortDirection={(d.sortable && orderBy) === d.id ? order : false}
                onClick={handleRequestSort(d.id, d.sortable)}
                className={d.sortable ? classes.sortableColumnHeader : classes.columnHeader}
                style={d.style}
              >
                <span className={clsx(i18n?.language !== "en-US" && classes.japaneseTableTitle)}>
                  {d.name}
                </span>
                {orderBy === d.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === constants.ORDER.DESC ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
                {d.sortable && (
                  <TableSortLabel
                    active={orderBy === d.id}
                    direction={orderBy === d.id ? order : "asc"}
                  />
                )}
              </TableCellStyled>
            ))}
          {!isXsDown && (
            <TableCellStyledForFunction
              style={{ maxWidth: "30px", width: "30px" }}
              onClick={() => {
                setIsDisplaySortDialog(!isDisplaySortDialog);
              }}
            >
              <img
                src={order === "desc" && orderBy === "chNo" ? SortIcon : SortIconPrimary}
                className={`${classes.controlRow} ${classes.columnHeaderSort}`}
              />
            </TableCellStyledForFunction>
          )}
          {!isXsDown && (
            <TableCellStyledForFunction
              style={{ maxWidth: "30px", width: "30px" }}
              onClick={ResetSort}
            >
              <img
                src={order === "desc" && orderBy === "chNo" ? ResetIcon : ResetIconPrimary}
                className={`${classes.controlRow} ${classes.columnHeaderSort}`}
              />
            </TableCellStyledForFunction>
          )}
        </TableRow>
      </TableHead>
    );
  };

  const displayData = useMemo(() => {
    if (searchButtonFlag) {
      return filteredList;
    } else if (channelLatestList?.length) {
      return channelLatestList;
    }
  }, [searchButtonFlag, channelLatestList, filteredList]);

  const tableBody = (selectedRow) =>
    (isSmDown
      ? stableSort(displayData, getComparator(order, orderBy))
      : stableSort(displayData, getComparator(order, orderBy)).slice(
          page * constants.ROWS_PER_PAGE,
          page * constants.ROWS_PER_PAGE + constants.ROWS_PER_PAGE
        )
    ).map((row, i) => (
      <TableRowStyled
        key={`alarm-operation-list-${i}`}
        className={classes.dataHolder}
        style={{
          background: selectedRow === row?.chNo ? "rgba(100, 217, 231, 0.2)" : "none",
        }}
      >
        <TableCellStyled align="left">{row.chNo}</TableCellStyled>
        <TableCellStyled align="left">{row.chName}</TableCellStyled>
        <TableCellStyled align="right" style={{ paddingRight: "0" }}>
          {row.inputData}
        </TableCellStyled>
        <TableCellStyled
          align="center"
          style={{ width: "60px", paddingLeft: "0", paddingRight: "0" }}
        ></TableCellStyled>
        <TableCellStyled align="left" style={{ paddingLeft: "0" }}>
          {row.unit}
        </TableCellStyled>
        <TableCellStyled align="center" style={{ paddingLeft: "0" }}>
          {row.chStatus}
        </TableCellStyled>
        <TableCellStyled align="center" style={{ paddingLeft: "0" }}>
          {isExistingThresholdList.includes(String(parseInt(row?.chNo))) && <img src={notificationIcon} />}
        </TableCellStyled>
        <TableCellStyled align="center" style={{ paddingLeft: "0" }}>
          <img
            src={EditIcon}
            style={{ cursor: "pointer" }}
            onClick={() => {
              openModal(row);
            }}
            className={`${classes.editIcon}`}
          />
        </TableCellStyled>
        <TableCellStyled align="center" style={{ paddingLeft: "0" }}></TableCellStyled>
      </TableRowStyled>
    ));

  useEffect(() => {
    const table = document.getElementById("dataGrid");
    const tableScrollHandler = () => {
      const y = table.scrollTop;
      if (y !== 0) {
        dispatch(setCurrentTablePosition(y));
      }
    };
    if (displayData?.length === 0) {
      setPage(0);
    } else if (
      searchButtonFlag &&
      Math.floor(filteredList?.length / constants.ROWS_PER_PAGE) < page
    ) {
      setPage(Math.floor(filteredList?.length / constants.ROWS_PER_PAGE));
    }
    if (updateFlag) {
      updateFlag = false;
      table.addEventListener("scroll", tableScrollHandler);
    }
    window.scrollTo(0, currentWinPos);
    table.scrollTo(0, currentTablePos);

    return () => {
      table.removeEventListener("scroll", tableScrollHandler);
    };
  }, [displayData]);

  useEffect(() => {
    setInitialLoad(true);
  }, [vesselId]);

  useEffect(() => {
    if (!isChannelLatestFetchLoading) {
      setInitialLoad(false);
    }
  }, [isChannelLatestFetchLoading]);

  const DisplayList = () => {
    if (initialLoad && isChannelLatestFetchLoading) {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={6} align="center">
            <CircularProgress />
          </TableCellStyled>
        </TableRowStyled>
      );
    } else if (displayData?.length) {
      return tableBody(selectedRow);
    } else if (isChannelLatestFetchLoading) {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={6} align="center">
            <CircularProgress />
          </TableCellStyled>
        </TableRowStyled>
      );
    } else {
      return (
        <TableRowStyled>
          <TableCellStyled colSpan={6} align="center" className={classes.noAlarmStyle}>
            {t(localizationKeys.NoChannel_upper)}
          </TableCellStyled>
        </TableRowStyled>
      );
    }
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid
      container
      alignItems="stretch"
      style={{ display: "flex", width: "100%" }}
      justifyContent="center"
    >
      <Grid item sm={12} style={{ width: "100%" }}>
        <Grid>
          <TableContainer
            id="dataGrid"
            style={{
              borderRadius: "8px",
              height: isMdUp ? "100%" : height - 50,
              minHeight: "400px",
              position: "relative",
            }}
          >
            <Table aria-label="customized table">
              <EnhancedTableHeader />
              <TableBody>
                <DisplayList />
              </TableBody>
            </Table>
            {isXsDown && (
              <div
                className={classes.mobileSort}
                onClick={() => {
                  setIsDisplaySortDialog(!isDisplaySortDialog);
                }}
              >
                <MobileSortIcon
                  color={orderBy == null ? "white" : "#19B2AB"}
                  className={`${classes.mobileSortIcon} ${classes.columnHeader}`}
                />
              </div>
            )}
            {displaySortDialog()}
          </TableContainer>
        </Grid>
        {isMdUp && (
          <div className={classes.arrowIcon}>
            <Grid className={classes.arrowIcon}>
              <PageButton
                type={constants.PAGE_BUTTON.BACK}
                page={page}
                setPage={setPage}
                displayData={displayData}
                classes={classes}
                isLoading={isChannelLatestFetchLoading}
              />
              <TablePagination
                component="div"
                count={displayData?.length ?? 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={constants.ROWS_PER_PAGE}
                rowsPerPageOptions={[]}
                sx={paginationStyle}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from} - ${to} ${t(localizationKeys.OutOf_lower)} ${count !== -1 ? count : 0}`
                }
              />
              <PageButton
                type={constants.PAGE_BUTTON.FORWARD}
                page={page}
                setPage={setPage}
                displayData={displayData}
                classes={classes}
                isLoading={isChannelLatestFetchLoading}
              />
            </Grid>
          </div>
        )}
      </Grid>
      <ChannelOptions
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        vesselId={vesselId}
        channelNo={selectedRow}
        setSelectedLow={setSelectedLow}
      />
    </Grid>
  );
});

ChannelList.propTypes = {
  vesselId: PropTypes.string,
  i18n: PropTypes.object,
  height: PropTypes.number,
};
