export const splitSearchWordBySpace = (inputText) => {
  const splittedWord = inputText.replaceAll("　", " ").trim().split(" ");
  return splittedWord.filter(Boolean);
};

export const isMatchWithSearchWords = (target, words) => {
  if (words.length === 0) return true;

  const targetText = target.toLowerCase();
  const searchWords = words.map((d) => d.toLowerCase());

  const isMatch = searchWords.every((sw) => targetText.match(sw));

  return isMatch;
};
