import React from "react";
import { Grid } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import constants from "../../alarmPage/utils/constants.js";

const PageButton = ({ page, setPage, type, displayData, classes, isLoading }) => {
  const pageStatus = () => {
    const length = displayData?.length ?? 0;
    if (isLoading || length === 0) return false;
    return Math.floor(length / constants.ROWS_PER_PAGE) > 0;
  };
  if (type === constants.PAGE_BUTTON.FORWARD) {
    const changeBackPage = (current) =>
      current === Math.ceil(displayData.length / constants.ROWS_PER_PAGE) - 1 ? 0 : current + 1;

    return (
      <Grid
        item
        xs
        onClick={(e) => {
          e.preventDefault();
          pageStatus() && setPage(changeBackPage(page));
        }}
        className={classes.buttonGrid}
      >
        <div className={pageStatus() ? classes.pageButton : classes.disabledPageButton}>
          <ArrowForwardIos />
        </div>
      </Grid>
    );
  } else {
    const changeForwardPage = (current) =>
      current === 0 ? Math.ceil(displayData.length / constants.ROWS_PER_PAGE) - 1 : current - 1;

    return (
      <Grid
        item
        xs
        onClick={(e) => {
          e.preventDefault();
          pageStatus() && setPage(changeForwardPage(page));
        }}
        className={classes.buttonGrid}
      >
        <div
          className={`${pageStatus() ? classes.pageButton : classes.disabledPageButton} ${
            classes.backButtonAdjustment
          }`}
        >
          <ArrowBackIos style={{ marginLeft: "10px" }} />
        </div>
      </Grid>
    );
  }
};

export default PageButton;
