import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { Compass } from "./compass.jsx";
import { Details } from "./details.jsx";
import { currentFleetNavigationData } from "../../../model/slice/fleetNavigationDataSlice.js";
import {
  fetchVesselLatestGpvtgData,
  fetchVesselLatestWimwvrData,
  fetchVesselLatestWimwvtData,
} from "../../../api/latestData.js";
import { COLORS } from "../../../constants/colors.js";
import { Title } from "../title.jsx";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    height: "100%",
    backgroundColor: COLORS.tabSelectorColor,
  },
  title: {
    fontSize: "24px",
    color: COLORS.white,
    margin: "10px",
  },
});

export const Navigation = memo((props) => {
  const { vesselId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const navigationData = useSelector(currentFleetNavigationData(vesselId));
  const [gpvtgData, setGpvtgData] = useState(null); 
  const [wimwvrData, setWimwvrData] = useState(null);
  const [wimwvtData, setWimwvtData] = useState(null);

  useEffect(() => {
    if (!vesselId) return;
    Promise.all([
      fetchVesselLatestWimwvrData(vesselId),
      fetchVesselLatestWimwvtData(vesselId),
      fetchVesselLatestGpvtgData(vesselId),
    ]).then((data) => {
      setWimwvrData(data[0].latestWimwvrData[0]);
      setWimwvtData(data[1].latestWimwvtData[0]);
      setGpvtgData(data[2].latestGpvtgData[0]);
    });
  }, [vesselId, navigationData]);

  return (
    <Grid container className={classes.container}>
      <Title title={t(localizationKeys.PositionAndWeather)} />
      <Grid item xs={6} style={{ paddingBottom: "70px" }}>
        <Compass
          rotation={navigationData?.vesselLatestHehdtData?.[0]?.head ?? 0}
          realWindDirection={wimwvrData?.direction ?? 0}
          trueWindDirection={wimwvtData?.direction ?? 0}
        />
      </Grid>
      <Grid item xs={6}>
        <Details
          gpggaData={navigationData?.vesselLatestGpggaData}
          gpvtgData={gpvtgData}
          wimwvrData={wimwvrData}
          wimwvtData={wimwvtData}
        />
      </Grid>
    </Grid>
  );
});

Navigation.propTypes = {
  vesselId: PropTypes.string,
};
