import React from "react";
import PropTypes from "prop-types";

export const RemoveIcon = ({ width = 16, height = 16 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.884 12.8c.07 0 .125-.02.165-.06l.69-.69c.04-.04.06-.095.06-.165s-.02-.125-.06-.165L9.02 8l3.09-3.09.63-.63c.04-.04.06-.095.06-.165s-.02-.125-.06-.165l-.69-.69a.223.223 0 0 0-.165-.06c-.07 0-.125.02-.165.06L8 6.98 4.28 3.26a.223.223 0 0 0-.165-.06c-.07 0-.125.02-.165.06l-.69.69a.223.223 0 0 0-.06.165c0 .07.02.125.06.165L6.98 8l-3.72 3.72a.223.223 0 0 0-.06.165c0 .07.02.125.06.165l.69.69c.04.04.095.06.165.06s.125-.02.165-.06L8 9.02l3.72 3.72c.04.04.095.06.165.06z"
      fill="#fff"
      fillOpacity=".7"
    />
  </svg>
);

RemoveIcon.prototype = {
  width: PropTypes.number,
  height: PropTypes.number,
};
