import {
  engineTrendGraphLines,
  ENGINE_CHART_TYPE,
  ENGINE_POSITION,
} from "../../../constants/trendGraph/enginesLine";
import localizationKeys from "../../../i18n/localizationKeys";
import {
  engineDeviationReducer,
  engineLoadReducer,
  engineWaterReducer,
  engineTempReducer,
  enginePressReducer,
} from "./chartReducer.js";

const engineDeviationGraph = {
  id: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  title: localizationKeys.CylinderDeviation_lower,
  dataList: engineTrendGraphLines.filter(
    (value) => value.chartType === ENGINE_CHART_TYPE.ENGINE_DEVIATION
  ),
};

const engineExhaustTemp = {
  id: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  title: localizationKeys.CylinderTemp_upper,
  dataList: engineTrendGraphLines.filter(
    (value) => value.chartType === ENGINE_CHART_TYPE.ENGINE_EXHAUST
  ),
};

const engineLoad = {
  id: ENGINE_CHART_TYPE.ENGINE_LOAD,
  title: localizationKeys.EngineLoad_lower,
  dataList: engineTrendGraphLines.filter(
    (value) => value.chartType === ENGINE_CHART_TYPE.ENGINE_LOAD
  ),
};

const engineWater = {
  id: ENGINE_CHART_TYPE.ENGINE_WATER,
  title: localizationKeys.CoolingFreshWaterTemp_lower,
  dataList: engineTrendGraphLines.filter(
    (value) => value.chartType === ENGINE_CHART_TYPE.ENGINE_WATER
  ),
};

const engineTemp = {
  id: ENGINE_CHART_TYPE.ENGINE_TEMP,
  title: localizationKeys.EngineTemp_lower,
  dataList: engineTrendGraphLines.filter(
    (value) => value.chartType === ENGINE_CHART_TYPE.ENGINE_TEMP
  ),
};

const enginePress = {
  id: ENGINE_CHART_TYPE.ENGINE_PRESS,
  title: localizationKeys.EnginePress_lower,
  dataList: engineTrendGraphLines.filter(
    (value) => value.chartType === ENGINE_CHART_TYPE.ENGINE_PRESS
  ),
};

export const ENGINE_CHART_LIST = [
  {
    ...engineLoad,
    chart: ENGINE_CHART_TYPE.ENGINE_LOAD,
    position: ENGINE_POSITION.PORT,
    reducer: engineLoadReducer,
  },
  {
    ...engineLoad,
    chart: ENGINE_CHART_TYPE.ENGINE_LOAD,
    position: ENGINE_POSITION.STARBOARD,
    reducer: engineLoadReducer,
  },
  {
    ...engineDeviationGraph,
    chart: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
    position: ENGINE_POSITION.PORT,
    reducer: engineDeviationReducer,
  },
  {
    ...engineDeviationGraph,
    chart: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
    position: ENGINE_POSITION.STARBOARD,
    reducer: engineDeviationReducer,
  },
  {
    ...engineWater,
    chart: ENGINE_CHART_TYPE.ENGINE_WATER,
    position: ENGINE_POSITION.PORT,
    reducer: engineWaterReducer,
  },
  {
    ...engineWater,
    chart: ENGINE_CHART_TYPE.ENGINE_WATER,
    position: ENGINE_POSITION.STARBOARD,
    reducer: engineWaterReducer,
  },
  {
    ...engineExhaustTemp,
    chart: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
    position: ENGINE_POSITION.PORT,
    reducer: engineDeviationReducer,
  },
  {
    ...engineExhaustTemp,
    chart: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
    position: ENGINE_POSITION.STARBOARD,
    reducer: engineDeviationReducer,
  },
  {
    ...engineLoad,
    chart: ENGINE_CHART_TYPE.ENGINE_LOAD,
    position: ENGINE_POSITION.CENTER,
    reducer: engineLoadReducer,
  },
  {
    ...engineDeviationGraph,
    chart: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
    position: ENGINE_POSITION.CENTER,
    reducer: engineDeviationReducer,
  },
  {
    ...engineWater,
    chart: ENGINE_CHART_TYPE.ENGINE_WATER,
    position: ENGINE_POSITION.CENTER,
    reducer: engineWaterReducer,
  },
  {
    ...engineExhaustTemp,
    chart: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
    position: ENGINE_POSITION.CENTER,
    reducer: engineDeviationReducer,
  },
  {
    ...engineTemp,
    chart: ENGINE_CHART_TYPE.ENGINE_TEMP,
    position: ENGINE_POSITION.CENTER,
    reducer: engineTempReducer,
  },
  {
    ...engineTemp,
    chart: ENGINE_CHART_TYPE.ENGINE_TEMP,
    position: ENGINE_POSITION.PORT,
    reducer: engineTempReducer,
  },
  {
    ...engineTemp,
    chart: ENGINE_CHART_TYPE.ENGINE_TEMP,
    position: ENGINE_POSITION.STARBOARD,
    reducer: engineTempReducer,
  },
  {
    ...enginePress,
    chart: ENGINE_CHART_TYPE.ENGINE_PRESS,
    position: ENGINE_POSITION.CENTER,
    reducer: enginePressReducer,
  },
  {
    ...enginePress,
    chart: ENGINE_CHART_TYPE.ENGINE_PRESS,
    position: ENGINE_POSITION.PORT,
    reducer: enginePressReducer,
  },
  {
    ...enginePress,
    chart: ENGINE_CHART_TYPE.ENGINE_PRESS,
    position: ENGINE_POSITION.STARBOARD,
    reducer: enginePressReducer,
  },
];
