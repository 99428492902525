import { axios_iapi } from "./axiosSettings";

export const sendPurchaseInQuiry = async (params) => {
  try {
    const data = await axios_iapi.post("/contact/purchaseinquiry", {
      params,
    });
    return { sendInQuiryRet: data.data };
  } catch (e) {
    return { sendInQuiryError: e };
  }
};
