import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { sortFunc } from "../../utils/helper";
import constants from "../../utils/constants";
import { COLORS } from "../../../../constants/colors";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../../i18n/localizationKeys";

const useStyles = makeStyles({
  inputStartAdornment: {
    height: "100%",
    width: "80px",
    padding: "5px",
    marginRight: "15px",
    borderRight: "solid 1px white",
    textAlign: "center",
  },
  icon: {
    color: "white",
  },
  menuItem: {
    padding: 0,
  },
});

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      background: COLORS.tabSelectorColor,
      color: COLORS.white,
      marginTop: "10px",
      padding: "0 3px",
      width: "120px",
      border: "solid 1px white",
    },
  },
};

export const StartAdornmentContent = (props) => {
  const {
    order,
    index,
    value,
    lowThresholdList,
    setLowThresholdList,
    highThresholdList,
    setHighThresholdList,
  } = props;
  const classes = useStyles();
  const  { t } = useTranslation();
  const onChangeHandler = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "L") {
      const returnLowValue = [...lowThresholdList, value];
      const returnHighValue = highThresholdList.filter((v, i) => i !== index);
      sortFunc(returnLowValue, constants.ORDER.DESC);
      sortFunc(returnHighValue, constants.ORDER.ASC);
      setLowThresholdList(returnLowValue);
      setHighThresholdList(returnHighValue);
    } else if (selectedValue === "H") {
      const returnLowValue = lowThresholdList.filter((v, i) => i !== index);
      const returnHighValue = [...highThresholdList, value];
      sortFunc(returnLowValue, constants.ORDER.DESC);
      sortFunc(returnHighValue, constants.ORDER.ASC);
      setLowThresholdList(returnLowValue);
      setHighThresholdList(returnHighValue);
    }
  };
  return (
    <div
      className={classes.inputStartAdornment}
      style={{ borderLeft: `solid 3px ${COLORS.THRESHOLD_COLOR_LIST[index]}` }}
    >
      <Select
        classes={{ icon: classes.icon }}
        variant="standard"
        value={order}
        style={{ color: "white", icon: "white" }}
        MenuProps={MenuProps}
        onChange={(e) => {
          onChangeHandler(e);
        }}
        renderValue={(value) => <div>{`${value}${index + 1}`}</div>}
      >
        <MenuItem
          className={classes.menuItem}
          value={"H"}
          disabled={highThresholdList?.length === 4}
        >
          {t(localizationKeys.HighThreshold)}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          value={"L"}
          disabled={lowThresholdList?.length === 4}
        >
          {t(localizationKeys.LowThreshold)}
        </MenuItem>
      </Select>
    </div>
  );
};

StartAdornmentContent.propTypes = {
  order: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.number,
  lowThresholdList: PropTypes.array,
  setLowThresholdList: PropTypes.func,
  highThresholdList: PropTypes.array,
  setHighThresholdList: PropTypes.func,
};
