import React from "react";
import PropTypes from "prop-types";

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  pointerHolder: {
    position: "relative",
    textAlign: "center",
  },
  visualCone: {
    "& img": {
      height: "40%",
      width: "40%",
    },
    position: "absolute",
    top: "-23%",
    left: "0",
    right: "0",
    margin: "0 auto",
    height: "100%",
    width: "100%",
  },
  boatPointer: {
    "& img": {
      height: "100%",
      width: "100%",
    },
    position: "absolute",
    top: "-4%",
    left: "10%",
    right: "10%",
    bottom: "10%",
    margin: "0 auto",
  },
});

const Pointer = (props) => {
  const { isDisconnected } = props;

  return (
    <React.Fragment>
      <div
        className={props.classes.pointerHolder}
        style={{
          height: `100%`,
          width: `100%`,
          padding: `10%`,
        }}
      >
        <div className={props.classes.visualCone}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40%" height="40%" viewBox="0 0 131 131">
            <defs>
              <linearGradient id="iv0c1uvefa" x1="50%" x2="50%" y1="9.562%" y2="72.914%">
                <stop
                  offset="0%"
                  stopColor={isDisconnected ? "#909090" : "#19B3AB"}
                  stopOpacity=".544"
                />
                <stop
                  offset="100%"
                  stopColor={isDisconnected ? "#909090" : "#19B3AB"}
                  stopOpacity="0"
                />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <g fill="url(#iv0c1uvefa)" transform="translate(-915 -240)">
                <path d="M980.5 191L1070.5 371 890.5 371z" transform="rotate(-180 980.5 281)" />
              </g>
            </g>
          </svg>
        </div>
        <div className={props.classes.boatPointer}>
          {/* Vessel Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="100%"
            viewBox="0 0 189 336"
          >
            <defs>
              <linearGradient id="00lq4ionpd" x1="50%" x2="50%" y1="73.925%" y2="92.017%">
                <stop offset="0%" stopColor="#1B202C" />
                <stop offset="100%" stopColor="#1B202C" stopOpacity="0" />
              </linearGradient>
              <linearGradient id="aqd39wkc2c" x1="51.038%" x2="50%" y1="51.89%" y2="85.719%">
                <stop offset="0%" stopColor={isDisconnected ? "#909090" : "#19B3AB"} />
                <stop offset="100%" stopColor="#1B202C" stopOpacity="0" />
              </linearGradient>
              <filter
                id="muzxmoh1pa"
                width="269%"
                height="170.9%"
                x="-84.5%"
                y="-51.7%"
                filterUnits="objectBoundingBox"
              >
                <feMorphology
                  in="SourceAlpha"
                  operator="dilate"
                  radius="6"
                  result="shadowSpreadOuter1"
                />
                <feOffset dy="-40" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
                <feGaussianBlur
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                  stdDeviation="19.5"
                />
                <feComposite
                  in="shadowBlurOuter1"
                  in2="SourceAlpha"
                  operator="out"
                  result="shadowBlurOuter1"
                />
                <feColorMatrix
                  in="shadowBlurOuter1"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                />
              </filter>
              <path
                id="helt83jxnb"
                d="M932.654 631c-2.872-17.092-2.872-52.167 0-105.223 2.843-52.527 18.503-100.35 46.98-143.472L980.5 381c.297.444.592.89.886 1.335 28.464 43.113 44.118 90.927 46.96 143.442l.169 3.161.236 4.57c2.46 48.747 2.325 81.244-.405 97.492-21.418-12.516-37.367-18.774-47.846-18.774-10.786 0-26.735 6.258-47.846 18.774z"
              />
            </defs>
            <g fill="none" fillRule="evenodd" strokeLinejoin="round">
              <g transform="translate(-886 -298)">
                <use fill="#000" filter="url(#muzxmoh1pa)" xlinkHref="#helt83jxnb" />
                <path
                  fill="url(#00lq4ionpd)"
                  stroke="url(#aqd39wkc2c)"
                  strokeWidth="6"
                  d="M980.493 375.588l2.502 3.746c.3.449.598.898.895 1.348 28.76 43.562 44.58 91.872 47.452 144.935h0l.169 3.167.236 4.573c2.477 49.074 2.306 81.785-.442 98.14-.223 1.324-.496 2.743-.823 4.256-1.189-.715-2.405-1.436-3.65-2.163-20.74-12.12-36.165-18.364-46.332-18.364-10.46 0-25.88 6.239-46.316 18.355-1.25.74-2.472 1.476-3.663 2.2-.327-1.525-.602-2.953-.826-4.284-2.89-17.199-2.927-52.49-.037-105.883 2.873-53.072 18.7-101.392 47.476-144.968h0l3.359-5.058z"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
};

Pointer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  isDisconnected: PropTypes.bool,
};

export default withStyles(styles)(Pointer);
