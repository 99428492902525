import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { LCO2_TEMP_THRESHOLD } from "../../constants/cargo/charts";
import { COLORS } from "../../constants/colors.js";
import { initScrollbarY, initTooltip } from "./amcharts";
import localizationKeys from "../../i18n/localizationKeys";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectedTimezone } from "../../model/slice/timezoneSlice.js";
import { TIMEZONE } from "../../constants/timezone.js";

export const BarChart = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const chartRef = useRef();
  const chartId = "barchart";
  const timezone = useSelector(selectedTimezone);

  const currentTooltipPos = 0;

  const createRange = (value, axis, label, color) => {
    const rangeDataItem = axis.makeDataItem({
      value
    });
    
    const range = axis.createAxisRange(rangeDataItem);
    
    range.get("label").setAll({
      forceHidden: false,
      text: label,
      fill: color,
      fontSize: "11px"
    });
  
    range.get("grid").setAll({
      forceHidden: false,
      strokeOpacity: 0.8,
      stroke: color,
      location: 1,
    });
  }

  const handleColor = (value) => {
    if(value > LCO2_TEMP_THRESHOLD.high[0]){
      return am5.color(COLORS.overHighThreshold)
    }else if(value < LCO2_TEMP_THRESHOLD.low[0]){
      return am5.color(COLORS.underLowThresholdBar)
    }else{
      return am5.color(COLORS.greenishBlue)
    }
  }

  useLayoutEffect(() => {
    am5.addLicense(process.env.AMCHARTS_LICENSE);
    if (!data?.length) return;

    const groot = am5.Root.new(chartId, {
      useSafeResolution: false,
    });
    groot.utc = timezone === TIMEZONE.UTC;
    groot.setThemes([am5themes_Animated.new(groot)]);
    const xyChart = groot.container.children.push(
      am5xy.XYChart.new(groot, {
        wheelY: "none",
        pinchZoomX: "none",
        pinchZoomY: "none",
        maxTooltipDistance: -1,
        layout: groot.verticalLayout,
        panX: false,
        panY: false,
      })
    );

    const tagAxis = xyChart.xAxes.push(
      am5xy.CategoryAxis.new(groot, {
        categoryField: "tag",
        renderer: am5xy.AxisRendererX.new(groot, {
          cellStartLocation: 0.15,
          cellEndLocation: 0.85,
          minGridDistance: 16,
        }),
        stroke: am5.color(COLORS.white),
        autoZoom: false,
      })
    );

    tagAxis.get("renderer").labels.template.setAll({
      fontSize: 10,
      fill: am5.color(COLORS.white),
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
    });

    tagAxis.data.setAll(data);

    const valAxis = xyChart.yAxes.push(
      am5xy.ValueAxis.new(groot, {
        renderer: am5xy.AxisRendererY.new(groot, {}),
        autoZoom: false,
        baseValue: -60,
        max: 0,
        min: -60,
        strictMinMax: true,
      })
    );

    valAxis.get("renderer").labels.template.setAll({
      fill: am5.color(COLORS.white),
      stroke: am5.color(COLORS.white),
    });

    valAxis.get("renderer").grid.template.setAll({
      stroke: am5.color(COLORS.white),
      strokeWidth: 2,
    });

    const thresholdAxis = xyChart.yAxes.push(
      am5xy.ValueAxis.new(groot, {
        renderer: am5xy.AxisRendererY.new(groot, {}),
        autoZoom: false,
        baseValue: -60,
        max: 0,
        min: -60,
        strictMinMax: true,
      })
    );

    const rendererThresholdAxis = thresholdAxis.get("renderer");
    rendererThresholdAxis.grid.template.set("forceHidden", true);
    rendererThresholdAxis.labels.template.set("forceHidden", true);
  
    LCO2_TEMP_THRESHOLD.high.forEach((value) => {
      createRange(value, thresholdAxis, String(value), COLORS.overHighThreshold);
    });
    LCO2_TEMP_THRESHOLD.low.forEach((value) => {
      createRange(value, thresholdAxis, String(value), COLORS.lowThresholdLine);
    });
    initScrollbarY(xyChart, groot, false);
    const tooltip = initTooltip(groot, xyChart);
    initTooltipLabel(xyChart, tooltip, currentTooltipPos);

    const cursor = initCursor(xyChart, groot, tagAxis, valAxis);
    cursor.events.on("cursormoved", function (ev) {
      const x = ev.target.getPrivate("positionX");
      const position = tagAxis.toAxisPosition(x);
      const index = tagAxis.axisPositionToIndex(position);

      initTooltipLabel(xyChart, tooltip, index);
    });

    const valueSeries = xyChart.series.push(
      am5xy.ColumnSeries.new(groot, {
        name: "series",
        categoryXField: "tag",
        valueYField: "value",
        xAxis: tagAxis,
        yAxis: valAxis,
        stroke: am5.color(COLORS.greenishBlue),
        fill: am5.color(COLORS.greenishBlue),
        maskBullets: false,
      })
    );
    valueSeries.columns.template.adapters.add("fill", (fill, target) => handleColor(target.dataItem.dataContext.value));
    valueSeries.columns.template.adapters.add("stroke", (stroke, target) =>  handleColor(target.dataItem.dataContext.value));
    valueSeries.data.setAll(data);

    xyChart.appear(1000, 100);
    chartRef.current = xyChart;

    return () => {
      groot.dispose();
    };
  }, [JSON.stringify(data)]);

  const initCursor = (chart, root, inxAxis, inyAxis) => {
    const cursorinstance = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: inxAxis,
        yAxis: inyAxis,
        behavior: "zoomY",
      })
    );
    cursorinstance.lineX.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
      strokeDasharray: [],
    });
    cursorinstance.lineY.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
      strokeDasharray: [],
    });
    cursorinstance.selection.setAll({
      fill: am5.color(0xffffff),
      fillOpacity: 0.2,
    });
  
    return cursorinstance;
  };

  const initTooltipLabel = (chart, tooltip, index) => {
    const width = 50;
    tooltip.label.adapters.add("text", (text) => {
      text = t(localizationKeys.NoData_lower);
      chart.series.each(function (series, i) {
        series._dataItems.forEach((dataItem, i) => {
          const tooltipDataItem = dataItem.dataContext;
          const labelColor = handleColor(dataItem.dataContext.value);
          if (tooltipDataItem) {
            if (i === index) {
              text = text.replace(t(localizationKeys.NoData_lower), "");
              const valueY = tooltipDataItem.value;
              text += `[${labelColor} width: ${width}px]${
                tooltipDataItem.tag
              }:[/] [${labelColor} width: ${width}px]${
                valueY !== null && valueY !== undefined ? valueY : "--"
              }[/]`;
            }
          }
        });
      });
      return text;
    });
  };

  return <div id={chartId} style={{ width: "100%", height: "100%" }}></div>;
};

BarChart.propTypes = {
  data: PropTypes.array,
};
