import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import AlarmPanel from "./monitoring/monitoring.jsx";
import AlarmTopNavigation from "./navigation/top.jsx";
import AlarmTopNavigationMobile from "./navigation/mobiletop.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  ALARM_PAGE,
  currentPageSelector,
  resetAlarm,
  resetCurrentAlarm,
  setCurrentHistoryPage,
  setCurrentOperationPage,
  setCurrentPage,
} from "../../model/slice/alarmSlice.js";
import constants from "./utils/constants.js";
import AlarmHistoryList from "./history/historyList.jsx";
import OperationList from "./operations/list.jsx";
import { TabSelector } from "../tabSelector/tabSelector.jsx";
import localizationKeys from "../../i18n/localizationKeys.js";
import { NAVIGATION_BAR_HEIGHT } from "../../layout/panelLayout.jsx";
import { MobileListBottomNavigation } from "../common/listUtil/mobileListBottom.jsx";

const defaultTopAreaWithNavBar = 260 + NAVIGATION_BAR_HEIGHT;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#ffffff",
    [theme.breakpoints.down('md')]: {
      justifyContent: "center",
    },
  },
  tabHeaderSpace: {
    marginLeft: "0px",
    marginTop: "20px",
  },
  table: {
    padding: "0px 8px !important",
    marginRight: "24px",
    [theme.breakpoints.down('md')]: {
      overflow: "auto",
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "10px",
      height: "calc(100vh - 190px)",
    },
  },
}));

const commonTabs = (t) => [
  {
    name: t(localizationKeys.AlarmMonitoring_lower),
    id: ALARM_PAGE.MONITORING,
  },
  {
    name: t(localizationKeys.AlarmHistory_lower),
    id: ALARM_PAGE.HISTORY,
  },
  {
    name: t(localizationKeys.AlarmOperations_lower),
    id: ALARM_PAGE.OPERATION,
  },
];

const AlarmPageBase = ({ vesselName, layoutStyle }) => {
  const {vesselId} = useParams();
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const currentPage = useSelector(currentPageSelector);
  const [durationFilter, setDurationFilter] = useState(constants.DURATION_INITIAL_STATE);
  const [filterWord, setFilterWord] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const containerRef = useRef(null);

  useEffect(() => {
    dispatch(resetCurrentAlarm());
    dispatch(setCurrentHistoryPage(0));
    dispatch(setCurrentOperationPage(0));
  }, [vesselId]);

  const clickTab = (id) => {
    setPage(id);
  };

  const setPage = (currentPage) => {
    dispatch(setCurrentPage(currentPage));
  };

  useEffect(() => {
    return () => {
      dispatch(resetAlarm());
    };
  }, []);

  const DisplayContent = () => {
    switch (currentPage) {
      case ALARM_PAGE.MONITORING:
        return (
          <AlarmPanel
            layoutStyle={layoutStyle}
            vesselName={vesselName}
            vesselId={vesselId}
            filterValue={filterWord}
            filterStatus={filterStatus}
            height={defaultTopAreaWithNavBar}
          />
        );
      case ALARM_PAGE.HISTORY:
        return (
          <AlarmHistoryList
            durationFilter={durationFilter}
            filterValue={filterWord}
            filterStatus={filterStatus}
            vesselId={vesselId}
            height={defaultTopAreaWithNavBar}
          />
        );
      case ALARM_PAGE.OPERATION:
        return (
          <OperationList
            durationFilter={durationFilter}
            filterValue={filterWord}
            filterStatus={filterStatus}
            vesselId={vesselId}
            height={defaultTopAreaWithNavBar}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.root}>
      {isSmUp && (
        <TabSelector tabs={commonTabs(t)} selectedOne={currentPage} clickHandler={clickTab} />
      )}
      <Grid container spacing={2} className={styles.tabHeaderSpace} alignItems="center">
        {isSmUp ? (
          <AlarmTopNavigation
            currentPage={currentPage}
            setPage={setPage}
            setDurationFilter={setDurationFilter}
            durationFilter={durationFilter}
            filterWord={filterWord}
            setFilterWord={setFilterWord}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            dataType={currentPage}
            vesselId={vesselId}
          />
        ) : (
          <AlarmTopNavigationMobile
            currentPage={currentPage}
            setPage={setPage}
            setDurationFilter={setDurationFilter}
            durationFilter={durationFilter}
            filterWord={filterWord}
            setFilterWord={setFilterWord}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            dataType={currentPage}
            vesselId={vesselId}
          />
        )}
        <Grid item xs={12} className={styles.table} ref={containerRef}>
          <DisplayContent />
        </Grid>
        {!isSmUp && (
          <MobileListBottomNavigation
            currentPage={currentPage}
            setPage={setPage}
            setDurationFilter={setDurationFilter}
            durationFilter={durationFilter}
            filterWord={filterWord}
            setFilterWord={setFilterWord}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            dataType={currentPage}
            vesselId={vesselId}
          />
        )}
      </Grid>
    </div>
  );
};

AlarmPageBase.propTypes = {
  vesselName: PropTypes.string,
  vesselId: PropTypes.string,
  layoutStyle: PropTypes.object,
};

export { AlarmPageBase };
