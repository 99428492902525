import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { svgContainer } from "../../../constants/trendGraph/buttonStyles";
const useStyles = makeStyles((theme) => ({
  svgContainer: svgContainer,
}));
const DownloadIcon = ({color = "#8c8c8c", opacity = "0.5"}) => {
  const classes = useStyles();
  return (
    <div className={classes.svgContainer}>
      <svg
        width="30"
        height="30"
        viewBox="-4 -3 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.334 9.118v2.445H3.666V9.118H2v3.164c0 .396.372.718.834.718h10.332c.461 0 .834-.321.834-.718V9.118h-1.666z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M7.85 9.017 5.483 6.365s-.36-.316.03-.316h1.333V2.188S6.794 2 7.1 2h1.876c.22 0 .215.158.215.158V5.97h1.23c.473 0 .117.33.117.33S8.525 8.78 8.245 9.04c-.202.188-.396-.023-.396-.023z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    </div>
  );
};

DownloadIcon.propTypes = {
  color: PropTypes.string,
};

export default DownloadIcon;
