import React, { createRef, useEffect, useRef, useState } from "react";
import { Grid, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from "react-i18next";
import { detectDevice, DEVICE_TYPE } from "../../constants/detectDevice";
import { NAVIGATION_BAR_HEIGHT } from "../../layout/panelLayout.jsx";
import * as PropTypes from "prop-types";
import { ComparisonGraph } from "./comparisonGraph.jsx";
import DownloadIcon from "../trendPage/commonComponent/downloadIcon.jsx";
import Screenshot from "../trendPage/commonComponent/screenshot.jsx";
import { TabSelector } from "../tabSelector/tabSelector.jsx";
import localizationKeys from "../../i18n/localizationKeys";
import { useParams } from "react-router-dom";
import { DATE_FORMAT } from "../../constants/constants.js";
import dayjs from "util/dayjs-init.js";


const VESSEL_SELECTOR_HEIGHT = `calc(100dvh - ${NAVIGATION_BAR_HEIGHT}px - 10px - 65px)`;

const useStyle = ({ isMobile }) =>
  makeStyles((theme) => ({
    paperHeight: {
      borderRadius: "12px",
    },
    titlePaperPosition: {
      position: "relative",
      borderRadius: "12px 12px 0 0",
    },
    durationControl: {
      margin: "16px 0",
    },
    notSelected: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(100% - 20px)",
      "& p": {
        color: "#ffffff",
        fontSize: "16px",
      },
    },
    burgerMenu: {
      position: "absolute",
      left: "10px",
      top: `12px`,
      padding: "0px",
    },
    burgerMenuIcon: {
      color: "#19b3ab",
    },
    maximize: {
      position: "absolute",
      right: "10px",
      top: `12px`,
      padding: "0px",
    },
    comparisonTopArea: {
      display: "flex",
      justifyContent: "end",
      paddingRight: "24px"
    },
    comparisonGraphContainer: {
      // background: "#1b202a",
    },
    utilityIconArea: {
      height: "30px",
      margin: "35px 0 0 0",
      color: "white",
    },
    downloadIcon: {
      width: "25px",
      height: "25px",
      padding: "0px 0px",
      minWidth: "0px",
      marginRight: "10px",
    },
  }));

const NUMBER_OF_COMPARISON_GRAPH = 2;
const commonTabs = (t) => [
  {
    name: t(localizationKeys.DataComparison_lower),
    id: "dataComparison",
  },
];

const Comparison = (props) => {
  const {vesselId} = useParams();
  const {layoutStyle, t } = props;
  const isMobile = detectDevice === DEVICE_TYPE.PHONE;
  const classes = { ...useStyle({ isMobile })(), ...layoutStyle };
  const [firstGraphStartDate, setFirstGraphStartDate] = useState(dayjs.utc().subtract(7, "d").format(DATE_FORMAT));
  const [firstGraphEndDate, setFirstGraphEndDate] = useState(dayjs.utc().format(DATE_FORMAT));
  const [secondGraphStartDate, setSecondGraphStartDate] = useState("");
  const [secondGraphEndDate, setSecondGraphEndDate] = useState("");
  const [isGraphDateTimeSync, setIsGraphDateTimeSync] = useState(true);
  const [changeBySync, setChangeBySync] = useState(true);
  const [vessels, setVessels] = useState([{}, {}]);
  const [typeForComparison, setTypeForComparison] = useState(Array(NUMBER_OF_COMPARISON_GRAPH));
  const [selectedGraphTypeForComparison, setSelectedGraphTypeForComparison] = useState(
    Array(NUMBER_OF_COMPARISON_GRAPH)
  );
  const [selectedGraphForComparison, setSelectedGraphForComparison] = useState(
    Array(NUMBER_OF_COMPARISON_GRAPH)
  );
  const [graphDataForComparison, setGraphDataForComparison] = useState(
    Array(NUMBER_OF_COMPARISON_GRAPH)
  );
  const [isLoadingForComparison, setIsLoadingForComparison] = useState(
    Array(NUMBER_OF_COMPARISON_GRAPH).fill(false)
  );
  const screenshotRef = useRef();
  const refs = useRef([]);
  for (let i = 0; i < NUMBER_OF_COMPARISON_GRAPH; i++) {
    refs.current[i] = createRef();
  }

  const wait = (msec) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, msec);
    });
  };

  const downloadTwoCsvOnClick = async () => {
    for (const value of refs.current) {
      if (!value.current) return;
      value.current.click();
      //avoid safari issue
      await wait(100);
    }
  };

  // sync first graph and second graph duration until second one is changed by user.
  useEffect(() => {
    if(isGraphDateTimeSync) {
      setSecondGraphStartDate(firstGraphStartDate);
      setChangeBySync(true);
    }
  }, [firstGraphStartDate]);
  
  useEffect(() => {
    if(secondGraphStartDate === "") return; // skip first init
    if(changeBySync) {
      setChangeBySync(false);
    }else{
      setIsGraphDateTimeSync(false);
    }
  }, [secondGraphStartDate])

  useEffect(() => {
    if(isGraphDateTimeSync) {
      setSecondGraphEndDate(firstGraphEndDate);
      setChangeBySync(true);
    }
  }, [firstGraphEndDate]);


  useEffect(() => {
    if(secondGraphStartDate === "") return; // skip first init
    if(changeBySync) {
      setChangeBySync(false);
    }else{
      setIsGraphDateTimeSync(false);
    }
  }, [secondGraphEndDate])

  return (
    <React.Fragment>
      <TabSelector tabs={commonTabs(t)} selectedOne={"dataComparison"} clickHandler={() => {}} />
      <Grid container sx={{marginTop: "20px"}}>
        <Grid item xs={9}></Grid>
        <Grid item xs={3}>
          <Grid item xs={9} sx={{display: "flex", justifyContent: "flex-end"}}>
            <Button className={classes.downloadIcon} onClick={() => downloadTwoCsvOnClick()}>
              <DownloadIcon color={"#FFFFFF"} />
            </Button>
            <Screenshot element={screenshotRef.current} isTwoGraph={true} />
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.comparisonGraphContainer} container spacing={2} ref={screenshotRef}>
        {[...Array(NUMBER_OF_COMPARISON_GRAPH)].map((_, i) => (
          <ComparisonGraph
            graphRef={refs.current[i]}
            number={i}
            vessels={vessels}
            setVessels={setVessels}
            startDate={i===0 ? firstGraphStartDate : secondGraphStartDate}
            setStartDate={i===0 ? setFirstGraphStartDate : setSecondGraphStartDate}
            endDate={i===0 ? firstGraphEndDate : secondGraphEndDate}
            setEndDate={i===0 ? setFirstGraphEndDate : setSecondGraphEndDate}
            styles={classes}
            initialVesselId={vesselId}
            height={VESSEL_SELECTOR_HEIGHT}
            key={`comparison-chart-key-${i}`}
            typeForComparison={typeForComparison}
            selectedGraphTypeForComparison={selectedGraphTypeForComparison}
            selectedGraphForComparison={selectedGraphForComparison}
            graphDataForComparison={graphDataForComparison}
            isLoadingForComparison={isLoadingForComparison}
            setTypeForComparison={setTypeForComparison}
            setSelectedGraphTypeForComparison={setSelectedGraphTypeForComparison}
            setSelectedGraphForComparison={setSelectedGraphForComparison}
            setGraphDataForComparison={setGraphDataForComparison}
            setIsLoadingForComparison={setIsLoadingForComparison}
          />
        ))}
      </Grid>
    </React.Fragment>
  );
};

Comparison.propTypes = {
  vesselId: PropTypes.string,
  t: PropTypes.func,
  layoutStyle: PropTypes.object,
};

export default withTranslation()(Comparison);
