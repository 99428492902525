import { createAsyncThunk } from "@reduxjs/toolkit";
import { getViswaBearerToken, getViswaData, ViswaDataStorage } from "../../api/viswa";
import { setEmissionToken } from "../slice/emissionSlice";

export const fetchEmissionDataAsync = createAsyncThunk(
  "emission/asyncGet",
  async ({ imoList, startDate, endDate }, {dispatch, getState}) => {
    const state = getState().emissionController;
    let token = state.emissionToken;
    if(token === ""){
      token = await getViswaBearerToken(true);
      dispatch(setEmissionToken(token));
    }
     
    if (typeof imoList === "string") {
      const imoListArray = [imoList];
      const requests = imoListArray.map((imo) => getViswaData(imo, startDate, endDate, token));
      return await Promise.all(requests);
    } else {
      const requests = imoList.map((imo) => getViswaData(imo, startDate, endDate, token));
      return await Promise.all(requests);
    }
  }
);

export const fetchEmissionDataAsyncFulfilled = (state, action) => {
  state.emissionData = new ViswaDataStorage();
  const data = action.payload;
  data.forEach((d) => {
    if (d !== undefined) {
      state.emissionData.AddToDailyData(d);
    }
  });
  state.emissionDataQuerying = false;
};

export const fetchEmissionDataForDataAreaAsync = createAsyncThunk(
  "emissionTotalArea/asyncGet",
  async ({ imoList, startDate, endDate }, {dispatch, getState}) => {
    const state = getState().emissionController;
    let token = state.emissionToken;
    if(token === ""){
      token = await getViswaBearerToken(true);
      dispatch(setEmissionToken(token));
    }

    if (typeof imoList === "string") {
      const imoListArray = [imoList];
      const requests = imoListArray.map((imo) => getViswaData(imo, startDate, endDate, token));
      return await Promise.all(requests);
    } else {
      const request = imoList.map((imo) => getViswaData(imo, startDate, endDate, token));
      return await Promise.all(request);
    }
  }
);

export const fetchEmissionDataForDataAreaAsyncFulfilled = (state, action) => {
  state.emissionTotalData = new ViswaDataStorage();
  const data = action.payload;
  data.forEach((d) => {
    if (d !== undefined) {
      state.emissionTotalData.AddToDailyDataForTotalArea(d);
    }
  });
  state.emissionTotalDataQuerying = false;
};
