import { API_URL, axios } from "./axiosSettings";
import { getAuthToken } from "../helpers/session";
import { logger } from "./logger";
import { convertStreamToJson } from "../util/buffer";

export const fetchElectricData = async (vesselID, startDate, endDate) => {
  try {
    const fetchUrl = `${API_URL}/engineData/vesselGeneratorEngineData?vessel_id=${vesselID}`
    + `&startDate=${startDate}&endDate=${endDate}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return data;
  } catch (e) {
    logger.error(`fetchElectricData error ${e}`);
    throw e;
  }
};

export const fetchShaftGeneratorData = async (vesselID, startDate, endDate) => {
  try {
    const { data } = await axios.get("/engineData/vesselShaftGeneratorEngineData", {
      params: {
        vessel_id: vesselID,
        startDate,
        endDate,
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return data;
  } catch (e) {
    logger.error(`fetchShaftGeneratorData error ${e}`);
    throw e;
  }
};

export const fetchLatestGeneratorData = async (vesselId) => {
  try {
    const { data } = await axios.get("/latestData/vesselGeneratorEngineData", {
      params: {
        vessel_id: vesselId,
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return data;
  } catch (e) {
    logger.error(`fetchLatestGeneratorData error ${e}`);
    throw e;
  }
};

export const fetchLatestShaftGeneratorData = async (vesselId) => {
  try {
    const { data } = await axios.get("/latestData/vesselShaftGeneratorEngineData", {
      params: {
        vessel_id: vesselId,
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return data;
  } catch (e) {
    logger.error(`fetchLatestShaftGeneratorData error ${e}`);
    throw e;
  }
};
