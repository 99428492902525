import {
  ALARM_PAGE,
  FILTER_PAGE,
  INITIAL_ALARM_CURRENT_FILTER,
  INITIAL_ALARM_HISTORY_FILTER,
  INITIAL_ALARM_OPERATION_FILTER,
  INITIAL_ALARM_STATE,
} from "../slice/alarmSlice.js";
import constants from "../../components/alarmPage/utils/constants.js";
import dayjs from 'util/dayjs-init.js';
import { splitSearchWordBySpace, isMatchWithSearchWords } from "../../helpers/searchCommon.js";

const setCurrentPage = (state, action) => {
  state.currentPage = action.payload;
  resetFilters(state);
};

const setCurrentAlarm = (state, action) => {
  state.currentAlarm = action.payload;
};

const resetFilters = (state, action) => {
  state.alarmFilter.history = INITIAL_ALARM_HISTORY_FILTER;
  state.alarmFilter.current = INITIAL_ALARM_CURRENT_FILTER;
  state.alarmFilter.operation = INITIAL_ALARM_OPERATION_FILTER;
  state.tempFilteredAlarmList = undefined;
  state.filteredAlarmList = [];
  state.filters.word = undefined;
  state.filters.status = undefined;
  state.wordColumn = undefined;
  state.valueColumn = undefined;
  state.filters.occurrenceStart = undefined;
  state.filters.occurrenceEnd = undefined;
  state.filters.recoveryTimeStart = undefined;
  state.filters.recoveryTimeEnd = undefined;
  state.filters.datetimeStart = undefined;
  state.filters.datetimeEnd = undefined;
};

const alarmWordCheck = (base, words, column) => {
  return base?.filter((d) => isMatchWithSearchWords(d[column], words)) ?? [];
};

const getOldestDate = (history) => {
  const currentDateTime = dayjs.utc();
  if (!history?.length) return currentDateTime;
  return history
    .map((d) => [
      dayjs.utc(d.dateTime),
      d?.recoveryDate ? dayjs.utc(d.recoveryDate) : currentDateTime,
    ])
    .flat()
    .sort((a, b) => {
      if (a.isAfter(b)) return 1;
      else if (a.isBefore(b)) return -1;
      else return 0;
    })[0];
};

const filterTime = (date, startDate, endDate, filterStartDate, filterEndDate) =>
  !(filterStartDate && filterEndDate) || dayjs.utc(date).isBetween(startDate, endDate, null, "[]");

const alarmFilter = (state, alarmList) => {
  let filteredList = alarmList;
  if (state.filters.word) {
    filteredList = alarmWordCheck(
      filteredList,
      splitSearchWordBySpace(state.filters.word),
      state.wordColumn
    );
  }
  if (state.filters.status) {
    filteredList = alarmWordCheck(
      filteredList,
      splitSearchWordBySpace(state.filters.status),
      state.valueColumn
    );
  }
  if (ALARM_PAGE.HISTORY === state.currentPage) {
    const oldestDate = getOldestDate(filteredList);
    const occStart = state.filters.occurrenceStart;
    const occEnd = state.filters.occurrenceEnd;
    const recStart = state.filters.recoveryTimeStart;
    const recEnd = state.filters.recoveryTimeEnd;

    const occurrenceStartDateTime = occStart === "" ? oldestDate : dayjs.utc(occStart);
    const occurrenceEndDateTime = occEnd === "" ? dayjs.utc() : dayjs.utc(occEnd);
    const recoveryTimeStartDateTime = recStart === "" ? oldestDate : dayjs.utc(recStart);
    const recoveryTimeEndDateTime = recEnd === "" ? dayjs.utc() : dayjs.utc(recEnd);

    filteredList = filteredList?.filter(
      (d) =>
        filterTime(d.dateTime, occurrenceStartDateTime, occurrenceEndDateTime, occStart, occEnd) &&
        filterTime(
          d?.recoveryDate,
          recoveryTimeStartDateTime,
          recoveryTimeEndDateTime,
          recStart,
          recEnd
        )
    );
  } else if (ALARM_PAGE.OPERATION === state.currentPage) {
    const oldestDate = getOldestDate(filteredList);
    const start = state.filters.datetimeStart;
    const end = state.filters.datetimeEnd;

    const fixedStart = start === "" ? oldestDate : dayjs.utc(start);
    const fixedEnd = end === "" ? dayjs.utc() : dayjs.utc(end);

    filteredList = filteredList?.filter((d) =>
      filterTime(d.dateTime, fixedStart, fixedEnd, start, end)
    );
  }
  state.tempFilteredAlarmList = filteredList;
};

const wordSearch = (state, action) => {
  state.isSearching = true;
  state.filters.word = action.payload.text;
  state.wordColumn = constants.AMS_CHANNEL_COLUMN;

  if (action.payload.filter === FILTER_PAGE.HISTORY) {
    alarmFilter(state, state.alarmHistory);
  } else if (action.payload.filter === FILTER_PAGE.MONITORING) {
    alarmFilter(state, state.currentAlarm);
  } else if (action.payload.filter === FILTER_PAGE.OPERATION) {
    alarmFilter(state, state.alarmOperations);
  }
};

const valueSearch = (state, action) => {
  if (action.payload.filter === FILTER_PAGE.HISTORY) {
    state.isSearching = true;
    state.filters.status = action.payload.text;
    state.valueColumn = "value";
    alarmFilter(state, state.alarmHistory);
  } else if (action.payload.filter === FILTER_PAGE.MONITORING) {
    state.isSearching = true;
    state.filters.status = action.payload.text;
    state.valueColumn = "value";
    alarmFilter(state, state.currentAlarm);
  } else if (action.payload.filter === FILTER_PAGE.OPERATION) {
    state.isSearching = true;
    state.filters.status = action.payload.text;
    state.valueColumn = "targetStatus";
    alarmFilter(state, state.alarmOperations);
  }
};

const setFilterStatus = (state, action) => {
  state.alarmFilter[action.payload.filter].status = action.payload.text;
};

const setHistoryRecoveryFilter = (state, action) => {
  state.isSearching = true;
  state.filters.recoveryTimeStart = action.payload.recoveryTimeStart;
  state.filters.recoveryTimeEnd = action.payload.recoveryTimeEnd;
  alarmFilter(state, state.alarmHistory);
};

const setHistoryOccurrenceFilter = (state, action) => {
  state.isSearching = true;
  state.filters.occurrenceStart = action.payload.occurrenceStart;
  state.filters.occurrenceEnd = action.payload.occurrenceEnd;
  alarmFilter(state, state.alarmHistory);
};

const setOperationDatetimeFilter = (state, action) => {
  state.isSearching = true;
  state.filters.datetimeStart = action.payload.start;
  state.filters.datetimeEnd = action.payload.end;
  alarmFilter(state, state.alarmOperations);
};

const setCurrentAlarmLoading = (state, action) => {
  state.isCurrentAlarmLoading = action.payload;
};

const setAlarmCategory = (state, action) => {
  state.alarmListByCategory = action.payload;
};

const resetAlarm = (state, action) => {
  return INITIAL_ALARM_STATE;
};

const resetCurrentAlarm = (state, action) => {
  state.alarmListByCategory = {};
  state.currentAlarm = [];
};

const setSearchButtonFlg = (state, action) => {
  state.isSearchButton = action.payload.flag;
};

const setCurrentWindowPosition = (state, action) => {
  state.currentWindowPosition = action.payload;
};

const setCurrentTablePosition = (state, action) => {
  state.currentTablePosition = action.payload;
};

const setCurrentHistoryPage = (state, action) => {
  state.currentHistoryPage = action.payload;
};

const setCurrentOperationPage = (state, action) => {
  state.currentOperationPage = action.payload;
};

const searchButtonClicked = (state, action) => {
  state.filteredAlarmList = state.tempFilteredAlarmList;
}

export default {
  setCurrentPage,
  setCurrentAlarm,
  resetFilters,
  setHistoryRecoveryFilter,
  setHistoryOccurrenceFilter,
  setOperationDatetimeFilter,
  wordSearch,
  valueSearch,
  setFilterStatus,
  setCurrentAlarmLoading,
  setAlarmCategory,
  resetAlarm,
  resetCurrentAlarm,
  setSearchButtonFlg,
  setCurrentWindowPosition,
  setCurrentTablePosition,
  setCurrentHistoryPage,
  setCurrentOperationPage,
  searchButtonClicked,
};
