import React from "react";
export const DownloadIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#p621ds4ita)" fill="#D8D8D8">
                <path d="M12.334 9.118v2.445H3.666V9.118H2v3.164c0 .396.372.718.834.718h10.332c.461 0 .834-.321.834-.718V9.118h-1.666z" />
                <path d="M7.85 9.017 5.483 6.365s-.36-.316.03-.316h1.333V2.188S6.794 2 7.1 2h1.876c.22 0 .215.158.215.158V5.97h1.23c.473 0 .117.33.117.33S8.525 8.78 8.245 9.04c-.202.188-.396-.023-.396-.023z" />
            </g>
            <defs>
                <clipPath id="p621ds4ita">
                    <path fill="#fff" transform="translate(2 2)" d="M0 0h12v11H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
