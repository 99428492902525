import { axios_viswa_data_api, axios_viswa_token_api } from "./axiosSettings";
import { client_id, client_secret } from "../constants/viswa";
import dayjs from "util/dayjs-init.js";
import { logger } from "./logger";


export const getViswaBearerToken = async () => {
  const params = new URLSearchParams();
  params.append("grant_type", "client_credentials");
  params.append("client_id", client_id);
  params.append("client_secret", client_secret);

  const token = await axios_viswa_token_api
    .post("/token", params)
    .catch((e) => console.log("error", e));

  return token.data.access_token;
};

export const getViswaData = async (imo, startDate, finishDate, access_token) => {
  const updatedFinishDate = new Date(finishDate);
  updatedFinishDate.setDate(updatedFinishDate.getDate());
  const params = {
    ImoNumbers: imo,
    FromDate: startDate,
    ToDate: updatedFinishDate,
  };
  
  const data = await axios_viswa_data_api(access_token)
    .post("", params)
    .catch((e) => {
      logger.error(`getViswaData error ${e}`);
    });
  return data?.data[0];
};

export class ViswaDataStorage {
  #dailyData = {};
  #weeklyData = undefined;
  #monthlyData = undefined;
  #yearlyData = undefined;

  totalData = { CO2: 0, SOX: 0, NOX: 0, TFC: 0 };
  compareDataPrevious = { CO2: 0, SOX: 0, NOX: 0, TFC: 0 };
  compareDataCurrent = { CO2: 0, SOX: 0, NOX: 0, TFC: 0 };
  vesselName = "";

  get DailyData() {
    return this.#dailyData;
  }

  get WeeklyData() {
    if (this.#weeklyData === undefined) {
      this.#weeklyData = ViswaDataStorage.#generateWeeklyData(this.#dailyData);
    }
    return this.#weeklyData;
  }

  get MonthlyData() {
    if (this.#monthlyData === undefined) {
      this.#monthlyData = ViswaDataStorage.#generateMonthlyData(this.#dailyData);
    }
    return this.#monthlyData;
  }

  get YearlyData() {
    if (this.#yearlyData === undefined) {
      this.#yearlyData = ViswaDataStorage.#generateYearlyData(this.#dailyData);
    }
    return this.#yearlyData;
  }

  //viswa data should be
  // {
  //  Name
  //  Id
  //  ImoNumber
  //  ModeData [
  //    {Mode, DayData}
  //  ]
  // }
  // Add "one vessel" of viswa data into our storage, transforming the data
  AddToDailyData(viswaData) {
    viswaData.ModeData.forEach((singleModeData) => {
      singleModeData.DayData.forEach((singleDayData) => {
        //console.log(singleModeData);
        const date = dayjs.utc(singleDayData.UtcDt, "YYYY-MM-DD");
        const dateMilli = date.valueOf(); //date.getTime();
        if (!this.#dailyData.hasOwnProperty(dateMilli)) {
          this.#dailyData[dateMilli] = ViswaDataStorage.#getDataPacket();
          this.#dailyData[dateMilli].startDate = new Date(dateMilli);
        }

        const dayData = this.#dailyData[dateMilli];
        dayData.dataByMode[singleModeData.Mode].CO2 += singleDayData.CO2;
        dayData.dataByMode[singleModeData.Mode].SOX += singleDayData.SOX;
        dayData.dataByMode[singleModeData.Mode].NOX += singleDayData.NOX;
        dayData.dataByMode[singleModeData.Mode].TFC += singleDayData.TFC;
        dayData.dataByMode[singleModeData.Mode].LD += singleDayData.LD;
      });
    });

    this.#updateDailyCombinedData();
    this.#updateTotalData();
  }

  AddToDailyDataForTotalArea(viswaData) {
    viswaData.ModeData.forEach((singleModeData) => {
      singleModeData.DayData.forEach((singleDayData) => {
        //console.log(singleModeData);
        const date = dayjs.utc(singleDayData.UtcDt, "YYYY-MM-DD");
        const dateMilli = date.valueOf(); //date.getTime();
        if (!this.#dailyData.hasOwnProperty(dateMilli)) {
          this.#dailyData[dateMilli] = ViswaDataStorage.#getDataPacket();
          this.#dailyData[dateMilli].startDate = new Date(dateMilli);
        }

        const dayData = this.#dailyData[dateMilli];
        dayData.dataByMode[singleModeData.Mode].CO2 += singleDayData.CO2;
        dayData.dataByMode[singleModeData.Mode].SOX += singleDayData.SOX;
        dayData.dataByMode[singleModeData.Mode].NOX += singleDayData.NOX;
        dayData.dataByMode[singleModeData.Mode].TFC += singleDayData.TFC;
        dayData.dataByMode[singleModeData.Mode].LD += singleDayData.LD;
      });
    });

    this.#updateDailyCombinedData();
    this.#updateTotalDataForTopArea();
  }

  GetCSV() {
    const modeNames = ["At_Port", "Anchorage", "In_Transit", "At_Rig", "DP", "Rig_Towing"];
    const headers = ["Date", "Total_Duration", "Total_CO2", "Total_SOX", "Total_NOX", "Total_TFC"];
    modeNames.forEach((name) => {
      headers.push(name + "_Duration");
      headers.push(name + "_CO2");
      headers.push(name + "_SOX");
      headers.push(name + "_NOX");
      headers.push(name + "_TFC");
    });

    const rows = Object.values(this.#dailyData).map((dayData) => {
      const out = [
        dayData.startDate.toString(),
        dayData.combinedData.LD.toString(),
        dayData.combinedData.CO2.toString(),
        dayData.combinedData.SOX.toString(),
        dayData.combinedData.NOX.toString(),
        dayData.combinedData.TFC.toString(),
      ];

      dayData.dataByMode.forEach((mode) => {
        out.push(mode.LD.toString());
        out.push(mode.CO2.toString());
        out.push(mode.SOX.toString());
        out.push(mode.NOX.toString());
        out.push(mode.TFC.toString());
      });

      return out.concat(",");
    });

    const joinedRows = rows.join("\n");
    return headers + "\n" + joinedRows;
  }

  #updateDailyCombinedData() {
    Object.keys(this.#dailyData).forEach((key) => {
      const day = this.#dailyData[key];
      ViswaDataStorage.#dataPacketUpdateCombined(day);
    });
  }

  #updateTotalData() {
    this.totalData = { CO2: 0, SOX: 0, NOX: 0, TFC: 0 };
    Object.keys(this.#dailyData).forEach((key) => {
      const day = this.#dailyData[key];
      this.totalData.CO2 += day.combinedData.CO2;
      this.totalData.SOX += day.combinedData.SOX;
      this.totalData.NOX += day.combinedData.NOX;
      this.totalData.TFC += day.combinedData.TFC;
    });
  }

  #updateTotalDataForTopArea() {
    this.totalData = { CO2: 0, SOX: 0, NOX: 0, TFC: 0 };
    this.compareDataPrevious = { CO2: 0, SOX: 0, NOX: 0, TFC: 0 };
    this.compareDataCurrent = { CO2: 0, SOX: 0, NOX: 0, TFC: 0 };
    const previousStartDay = dayjs.utc().subtract(61, "days");
    const afterStartDay = dayjs.utc().subtract(31, "days");
    Object.keys(this.#dailyData).forEach((key) => {
      const day = this.#dailyData[key];
      if (
        dayjs.utc(day.startDate).isAfter(previousStartDay) &&
        dayjs.utc(day.startDate).isSameOrBefore(afterStartDay)
      ) {
        this.compareDataPrevious.CO2 += day.combinedData.CO2;
        this.compareDataPrevious.SOX += day.combinedData.SOX;
        this.compareDataPrevious.NOX += day.combinedData.NOX;
        this.compareDataPrevious.TFC += day.combinedData.TFC;
      } else if (dayjs.utc(day.startDate).isSameOrAfter(afterStartDay)) {
        this.compareDataCurrent.CO2 += day.combinedData.CO2;
        this.compareDataCurrent.SOX += day.combinedData.SOX;
        this.compareDataCurrent.NOX += day.combinedData.NOX;
        this.compareDataCurrent.TFC += day.combinedData.TFC;
      }
      this.totalData.CO2 += day.combinedData.CO2;
      this.totalData.SOX += day.combinedData.SOX;
      this.totalData.NOX += day.combinedData.NOX;
      this.totalData.TFC += day.combinedData.TFC;
    });
  }

  static #generateWeeklyData(dayData) {
    const weekData = {};
    const dayKeys = Object.keys(dayData);
    for (let i = 0; i < dayKeys.length; i++) {
      const weekNum = Math.floor(i / 7);
      if (weekData[weekNum] === undefined) {
        weekData[weekNum] = this.#getDataPacket();
        weekData[weekNum].startDate = dayData[dayKeys[i]].startDate;
      }
      const week = weekData[weekNum];
      const day = dayData[dayKeys[i]];

      this.#addDataPacketBToA(week, day);
    }

    Object.values(weekData).forEach((wd) => this.#dataPacketUpdateCombined(wd));

    return weekData;
  }

  static #generateMonthlyData(dayData) {
    const monthData = {};
    Object.values(dayData).forEach((dayPacket) => {
      const month = dayPacket.startDate.getUTCFullYear() * 12 + dayPacket.startDate.getUTCMonth();
      if (monthData[month] === undefined) {
        monthData[month] = this.#getDataPacket();
        monthData[month].startDate = dayPacket.startDate;
      }
      const monthRef = monthData[month];
      this.#addDataPacketBToA(monthRef, dayPacket);
    });

    Object.values(monthData).forEach((md) => this.#dataPacketUpdateCombined(md));

    return monthData;
  }

  static #generateYearlyData(dayData) {
    const yearData = {};
    Object.values(dayData).forEach((dayPacket) => {
      const year = dayPacket.startDate.getUTCFullYear();
      if (yearData[year] === undefined) {
        yearData[year] = this.#getDataPacket();
        yearData[year].startDate = dayPacket.startDate;
      }
      const yearRef = yearData[year];
      this.#addDataPacketBToA(yearRef, dayPacket);
    });

    Object.values(yearData).forEach((yd) => this.#dataPacketUpdateCombined(yd));

    return yearData;
  }

  static #getModeArray() {
    return Array.from({ length: 6 }, () => ({
      LD: 0,
      CO2: 0,
      SOX: 0,
      NOX: 0,
      TFC: 0,
    })); //[[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]];
  }

  static #getDataPacket() {
    return {
      startDate: undefined,
      dataByMode: this.#getModeArray(),
      combinedData: { LD: 0, CO2: 0, SOX: 0, NOX: 0, TFC: 0 },
    };
  }

  static #addDataPacketBToA(a, b) {
    for (let i = 0; i < 6; i++) {
      //6 modes
      a.dataByMode[i].LD += b.dataByMode[i].LD;
      a.dataByMode[i].CO2 += b.dataByMode[i].CO2;
      a.dataByMode[i].SOX += b.dataByMode[i].SOX;
      a.dataByMode[i].NOX += b.dataByMode[i].NOX;
      a.dataByMode[i].TFC += b.dataByMode[i].TFC;
    }
  }

  static #dataPacketUpdateCombined(dataPacket) {
    dataPacket.combinedData = dataPacket.dataByMode.reduce((dbm, cur) => {
      return {
        CO2: dbm.CO2 + cur.CO2,
        SOX: dbm.SOX + cur.SOX,
        NOX: dbm.NOX + cur.NOX,
        TFC: dbm.TFC + cur.TFC,
        LD: dbm.LD + cur.LD,
      };
    });
  }
}
