import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../constants/colors";

const useStyle = makeStyles({
  title: {
    fontSize: "20px",
    color: COLORS.white,
    paddingTop: "10px",
    paddingLeft: "20px",
    height: "40px",
  },
});
export const Title = (props) => {
  const { title } = props;
  const classes = useStyle();
  return (
    <Grid item xs={12} className={classes.title}>
      {`${title}`}
    </Grid>
  );
};

Title.propTypes = {
  title: PropTypes.string,
};
