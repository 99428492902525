
import React from "react";
import * as PropTypes from "prop-types";

const SortUpIconSetColor = ({ color = "rgba(255, 255, 255, 0.3)", width = 14, height = 14 }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g fill={color} >
        <path
          d="M7.564 11.322a.235.235 0 0 0 .172-.071.235.235 0 0 0 .07-.173V4.166l3.143 3.142c.054.04.115.06.182.06a.22.22 0 0 0 .163-.06l.405-.406a.297.297 0 0 0 .06-.182.22.22 0 0 0-.06-.162L7.442 2.32a.193.193 0 0 0-.162-.081.193.193 0 0 0-.162.081L2.86 6.558a.22.22 0 0 0-.061.162c0 .068.02.128.06.182l.406.406c.04.04.095.06.162.06.068 0 .129-.02.183-.06l3.142-3.142v6.912c0 .068.023.125.07.173.048.047.105.07.173.07h.568z"
        />
      </g>
    </svg>
  );
};

SortUpIconSetColor.propTypes = {
  disabled: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SortUpIconSetColor;
