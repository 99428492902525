import React from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import {useParams} from "react-router-dom";

//component imports
import NavBar from "../components/navBar/index.jsx";
import SideBar from "../components/sideBar/index.jsx";

import withStyles from '@mui/styles/withStyles';
import { COLORS } from "../constants/colors.js";

//Localization imports
import { withTranslation } from "react-i18next";

//API imports
import { fetchVessel } from "../api/vessel.js";

// Logger
import { logger } from "../api/logger";

const paper = {
  backgroundColor: `${COLORS.baseColor} !important`,
};

export const NAVIGATION_BAR_HEIGHT = 50;
export const TAB_BAR_HEIGHT = 50;
//temporary, all pages without map will have tab bar
const pageWithTab = [
  "trend",
  "navigation",
  "monitoring",
  "alarm",
  "emission",
  "comparison",
  "cargo",
];

const paperTitle = (color) => ({
  "& p": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color,
    margin: "0",
  },
  width: "max",
  height: "47px",
  backgroundColor: "rgba(44, 58, 72, 0.4)",
  margin: "-16px -16px 0px -16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px 12px 0px 0px"
});

const styles = (theme) => ({
  paper: {
    ...paper,
    padding: theme.spacing(2),
    borderRadius: "12px",
  },
  controlPaper: {
    ...paper,
    padding: "16px 16px 0",
    borderRadius: "12px",
  },
  root: {
    flexGrow: 1,
    marginTop: "50px",
    [theme.breakpoints.up('lg')]: {
      marginRight: "100px",
      marginLeft: "100px",
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: "32px",
      marginLeft: "32px"
    }
  },
  paperTitle: {
    ...paperTitle("#19b3ab"),
  },
  disconnectedPaperTitle: {
    ...paperTitle("#c8c8c8"),
  },
  panelNavBar: {
    marginTop: "140px",
    height: "calc(100dvh - 140px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  base: {
    display: "flex",
  },
  sideBar: {
    top: NAVIGATION_BAR_HEIGHT + "px",
    marginTop: NAVIGATION_BAR_HEIGHT + "px",
  },
  container: {
    padding: "0 !important",
  },
  wideContainer: {
    padding: "0 !important",
    overflow: "hidden",
  },
});

const PanelLayout = (props) => {
  const { t } = props;
  const { vesselId } = useParams(); //Get vesselId from router;
  const [vesselName, setVesselName] = React.useState("");
  const [vesselType, setVesselType] = React.useState("");
  const unmount = React.useRef(false);
  const currentPath = location.pathname.split("/")[1];

  const fetchVesselInfo = async () => {
    try {
      if (!vesselId) {
        return;
      }

      const data = await fetchVessel(vesselId);

      if (data.vessel) {
        setVesselName(data.vessel.name);
        setVesselType(data.vessel.firstClass);
      }
    } catch (e) {
      logger.error(`fetchVesselInfo error: ${e}`);
    }
  };

  React.useEffect(() => {
    unmount.current || fetchVesselInfo();
    return () => {
      unmount.current = true;
    };
  }, []);

  return (
    <div className={props.classes.base}>
      <NavBar
        title={t(props.name)}
        vesselId={vesselId}
        miniIcons={true}
        changeLanguage={props.defaultParams.changeLanguage}
        setUserContext={props.defaultParams.setUserContext}
        vesselName={vesselName}
        vesselType={vesselType}
        includeVesselSelect={currentPath !== "servicepolicy"}
      />
      <SideBar className={props.classes.sideBar} />
      <Container
        maxWidth={false}
        className={
          ["trend", "cargo"].includes(currentPath)
            ? props.classes.wideContainer
            : props.classes.container
        }
      >
        <div className={props.classes.panelNavBar}>
          <props.component
            vesselName={vesselName}
            layoutStyle={props.classes}
            {...props.defaultParams}
          />
        </div>
      </Container>
    </div>
  );
};

PanelLayout.propTypes = {
  name: PropTypes.string,
};

export default withStyles(styles)(withTranslation()(PanelLayout));
