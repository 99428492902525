import { enableES5 } from "immer";

import { configureStore } from "@reduxjs/toolkit";
import fleetNavigationDataReducer from "../slice/fleetNavigationDataSlice.js";
import fleetEngineDataReducer from "../slice/fleetEngineDataSlice.js";
import chartSettingsReducer from "../slice/chartSettingsSlice.js";
import sideBarSettingReducer from "../slice/sideBarSettingSlice.js";
import dataDurationReducer from "../slice/dataDurationSlice.js";
import alarmReducer from "../slice/alarmSlice.js";
import vesselReducer from "../slice/vesselSlice";
import chartTypeReducer from "../slice/chartTypeSlice";
import chartDisplayTypeReducer from "../slice/chartDisplayTypeSlice";
import windowDirectionTypeReducer from "../slice/windowDirectionTypeSlice";
import comparisonTableReducer from "../slice/comparisonTableSlice";
import alarmNotificationReducer from "../slice/alarmNotificationSlice.js";
import channelReducer from "../slice/channelSlice";
import emissionReducer from "../slice/emissionSlice";
import chartsReducer from "../slice/chartsSlice.js";
import addonReducer from "../slice/addonSlice.js";
import dateTimeShorthandReducer from "../slice/dateTimeShorthandSlice.js";
import timeZoneReducer from "../slice/timezoneSlice.js";

// NOTE
// https://github.com/reduxjs/redux-toolkit/issues/476
// npm install immer@8.0.1
enableES5();

export default configureStore({
  reducer: {
    fleetNavigationData: fleetNavigationDataReducer,
    fleetEngineData: fleetEngineDataReducer,
    chartSettingsController: chartSettingsReducer,
    sideBarSetting: sideBarSettingReducer,
    dataDurationController: dataDurationReducer,
    alarmController: alarmReducer,
    vesselController: vesselReducer,
    chartTypeController: chartTypeReducer,
    chartDisplayTypeController: chartDisplayTypeReducer,
    windowDirectionTypeController: windowDirectionTypeReducer,
    comparisonTableController: comparisonTableReducer,
    alarmNotificationController: alarmNotificationReducer,
    channelController: channelReducer,
    emissionController: emissionReducer,
    chartsController: chartsReducer,
    addonController: addonReducer,
    dateTimeShorthandController: dateTimeShorthandReducer,
    timezoneController: timeZoneReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
