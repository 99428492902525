import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import constants from "../../utils/constants.js";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  alarmFilterSelector,
  historyFilterSelector,
  operationFilterSelector,
  setHistoryOccurrenceFilter,
  setHistoryRecoveryFilter,
  setOperationDatetimeFilter,
} from "../../../../model/slice/alarmSlice.js";
import { DurationPicker } from "../../../durationPicker/durationPicker.jsx";

const DurationCalendar = ({ type, minDate, i18n, disabled = false }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const historyFilter = useSelector(historyFilterSelector);
  const operationFilter = useSelector(operationFilterSelector);
  const alarmFilters = useSelector(alarmFilterSelector);

  useEffect(() => {
    setDuration(type);
  }, [startDate, endDate]);

  const setDurationStart = (type) => {
    switch (type) {
      case constants.DURATION_TYPE.DATETIME:
        return alarmFilters.datetimeStart;
      case constants.DURATION_TYPE.RECOVERY:
        return alarmFilters.recoveryTimeStart;
      case constants.DURATION_TYPE.OCCURRENCE:
        return alarmFilters.occurrenceStart;
    }
  };

  const setDurationEnd = (type) => {
    switch (type) {
      case constants.DURATION_TYPE.DATETIME:
        return alarmFilters.datetimeEnd;
      case constants.DURATION_TYPE.RECOVERY:
        return alarmFilters.recoveryTimeEnd;
      case constants.DURATION_TYPE.OCCURRENCE:
        return alarmFilters.occurrenceEnd;
    }
  };

  const setDuration = (type) => {
    type === constants.DURATION_TYPE.OCCURRENCE &&
      dispatch(
        setHistoryOccurrenceFilter({
          ...historyFilter,
          occurrenceStart: startDate,
          occurrenceEnd: endDate,
        })
      );

    type === constants.DURATION_TYPE.RECOVERY &&
      dispatch(
        setHistoryRecoveryFilter({
          ...historyFilter,
          recoveryTimeStart: startDate,
          recoveryTimeEnd: endDate,
        })
      );

    type === constants.DURATION_TYPE.DATETIME &&
      dispatch(
        setOperationDatetimeFilter({
          ...operationFilter,
          start: startDate,
          end: endDate,
        })
      );
  };

  return (
    <DurationPicker
      startDate={setDurationStart(type)}
      setStartDate={setStartDate}
      endDate={setDurationEnd(type)}
      setEndDate={setEndDate}
      isEmission={false}
      minDate={minDate}
      disabled={disabled}
    />
  );
};

DurationCalendar.propTypes = {
  // setDurationFilter: PropTypes.func,
  minDate: PropTypes.object,
  type: PropTypes.number,
  i18n: PropTypes.object,
};

export default withTranslation()(DurationCalendar);
