import { withStyles } from "@mui/styles";
import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { fleetAlarmSelector } from "../../../../model/slice/alarmSlice";
import { STATUS_REPOSE } from "../../../../constants/alarmType";
import Category from "../../../alarmPage/navigation/category";
import { COLORS } from "../../../../constants/colors";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { toProperCase } from "../../../../util/util";
import localizationKeys from "../../../../i18n/localizationKeys";
import { FETCH_INTERVAL } from "../../../../constants/constants.js";
import { fetchCurrentAlarm } from "../../../../model/async/alarmAsync.js";

const styles = (theme) => ({
  container: {
    display: "flex",
  },
  categoryContainer: {
    display: "flex",
    alignItems: "start",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
    },
    marginBottom: "16px",
  },
  alarmContainer: {
    borderRadius: "10px",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  currentAlarms: {
    border: "2px solid #ff5252",
  },
  reposedAlarms: {
    border: "2px solid #e3d864",
  },
  count: {
    fontSize: "32px",
    fontWeight: "bold",
  },
  currentAlarmCount: {
    color: "#ff5252",
  },
  reposedAlarmCount: {
    color: "#e3d864",
  },
  label: { },
  alarmLinkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alarmLink: {
    color: COLORS.greenishBlue,
    textDecoration: "none",
    "&:hover": {
      color: COLORS.greenishBlue,
      textDecoration: "none",
    },
  },
});

const Alarm = memo(({ vesselId, style, classes, t }) => {
  const dispatch = useDispatch();
  const fleetAlarmData = useSelector(fleetAlarmSelector);
  const [count, setCount] = useState({});

  useEffect(() => {
    const currentAlarmList = fleetAlarmData.filter(alarm => alarm.vesselId === vesselId);
    if (fleetAlarmData && currentAlarmList.length > 0) {
      setCount({
        active: currentAlarmList.filter(alarm => alarm.alarmStatus !== STATUS_REPOSE).length,
        repose: currentAlarmList.filter(alarm => alarm.alarmStatus === STATUS_REPOSE).length,
      });
    } else {
      setCount({
        active: 0,
        repose: 0,
      });
    }
  }, [fleetAlarmData]);

  useEffect(() => {
    const interval = setInterval(() => dispatch(fetchCurrentAlarm({ vesselId })), FETCH_INTERVAL);  
    dispatch(fetchCurrentAlarm({ vesselId }));

    return () => {
      clearInterval(interval);
    };
  }, [vesselId])

  return (
    <Grid container className={ `${style.components} ${classes.container}` }>
      <Grid item xs={12} className={classes.categoryContainer}>
        <Category vesselId={vesselId} />
      </Grid>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={5} className={`${classes.alarmContainer} ${classes.currentAlarms}`}>
            <div className={`${classes.count} ${count.active > 0 ? classes.currentAlarmCount : ''}`}>{count.active}</div>
            <div className={classes.label}>{toProperCase(t(localizationKeys.ActiveAlarms_upper))}</div>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} className={`${classes.alarmContainer} ${classes.reposedAlarms}`}>
            <div className={`${classes.count} ${count.repose > 0 ? classes.reposedAlarmCount : ''}`}>{count.repose}</div>
            <div className={classes.label}>{t(localizationKeys.ReposedAlarms)}</div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} className={classes.alarmLinkContainer}>
        <Link to={`/alarm/${vesselId}`} className={classes.alarmLink}>
          {t(localizationKeys.GoToAlarmDetails)} →
        </Link>
      </Grid>
    </Grid>
  );
});

Alarm.propTypes = {
  vesselId: PropTypes.string,
  style: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func,
};

export default withStyles(styles)(withTranslation()(Alarm));