import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchVessel } from "../../api/vessel";

export const fetchVesselAsync = createAsyncThunk("vessel/vessel", async ({ vesselId }) => {
  if (!vesselId) return;
  return await fetchVessel(vesselId);
});

export const fulfilledFetchVesselAsync = (state, action) => {
  state.vessel = action.payload;
};
