import axiosBase from "axios";
import { axios } from "./axiosSettings";
import { getAuthHeader } from "../helpers/session";

let cancelToken;

export const fetchCharts = async (vesselId) => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  cancelToken = axiosBase.CancelToken.source();

  try {
    const { data } = await axios.get(`/api/charts`, {
      params: {
        vesselId,
      },
      headers: getAuthHeader(),
      cancelToken: cancelToken.token,
    });
    return { charts: data };
  } catch (e) {
    throw { fetchCharts: e };
  }
};

export const updateCharts = async (chart) => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  cancelToken = axiosBase.CancelToken.source();

  try {
    const { data } = await axios.post(`/api/charts`, chart, {
      headers: getAuthHeader(),
      cancelToken: cancelToken.token,
    });
    return { charts: data };
  } catch (e) {
    throw { updateCharts: e };
  }
};

export const updateLines = async (vesselId, type, chartType, line, color, visible) => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  cancelToken = axiosBase.CancelToken.source();

  try {
    const { data } = await axios.post(
      `/api/charts/line`,
      {
        vesselId,
        type,
        chartType,
        line: line.replace(/"/g, ""),
        color,
        visible,
      },
      {
        headers: getAuthHeader(),
        cancelToken: cancelToken.token,
      }
    );
    return { trendCharts: data };
  } catch (e) {
    throw { updateLines: e };
  }
};

export const deleteChart = async (vesselId, chartId) => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }

  cancelToken = axiosBase.CancelToken.source();

  try {
    const { data } = await axios.delete(`/api/charts`, {
      params: {
        vesselId,
        chartId,
      },
      headers: getAuthHeader(),
      cancelToken: cancelToken.token,
    });
    return { charts: data };
  } catch (e) {
    throw { deleteChart: e };
  }
};
