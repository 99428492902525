import { createSlice } from "@reduxjs/toolkit";

//Action imports
import Actions from "../action/fleetNavigationDataAction.js";
import { INITIAL_MAP_DATA } from "../../constants/googlemap.js";
import {
  fetchVesselInformationAsync,
  fetchVesselInformationFulfilled,
} from "../async/fleetNavigationDataAsync";

export const FOC_DURATION_LIST = [
  {
    name: "day",
    duration: 24,
    unit: "hours",
    dateTestId: "day_button",
  },
  {
    name: "2h",
    duration: 2,
    unit: "hours",
    dataTestId: "hour_button",
  },
];

const fleetNavigationDataSlice = createSlice({
  name: "fleetNavigationData",
  initialState: {
    fleetNavigationData: [],
    selectedFOCDuration: FOC_DURATION_LIST[0],
    selectedVesselId: "",
    mapData: INITIAL_MAP_DATA,
  },
  reducers: {
    ...Actions,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVesselInformationAsync.fulfilled, fetchVesselInformationFulfilled);
  },
});

//Selectors
export const fleetNavigationData = (state) => state.fleetNavigationData;
export const selectedFOCDuration = (state) => state.fleetNavigationData.selectedFOCDuration;
export const currentFleetNavigationData = (vesselId) => (state) =>
  state?.fleetNavigationData?.fleetNavigationData?.find((d) => d.vessel_id === vesselId);
export const selectedVessel = (state) =>
  state.fleetNavigationData?.fleetNavigationData?.find(
    (d) => d?.vessel_id === state.fleetNavigationData.selectedVesselId
  );
export const mapData = (state) => state.fleetNavigationData.mapData;
export const selectedVesselId = (state) => state.fleetNavigationData.selectedVesselId;

export const {
  setFleetData,
  setSelectedVessel,
  setSelectedFOCDuration,
  setMapCenter,
  setMapZoom,
  setCurrentVessels,
  setFleetMarkers,
  setAlarmMarkers,
  setDisconnectMarkers,
  resetMapMarkers,
  markersResetDone,
  resetCurrentVessels,
} = fleetNavigationDataSlice.actions;

export default fleetNavigationDataSlice.reducer;
