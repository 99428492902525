// only for LCO2 vessel
export const CH_NO_LIST = [
  { chNo: "2000", tag: "T3" },
  { chNo: "2001", tag: "T4" },
  { chNo: "2002", tag: "T17" },
  { chNo: "2003", tag: "T5" },
  { chNo: "2004", tag: "T6" },
  { chNo: "2005", tag: "T8" },
  { chNo: "2006", tag: "T10" },
  { chNo: "2007", tag: "T13" },
  { chNo: "2008", tag: "T14" },
  { chNo: "2009", tag: "T18" },
  { chNo: "2010", tag: "T19" },
  { chNo: "2011", tag: "T20" },
  { chNo: "2012", tag: "T21" },
  { chNo: "2013", tag: "T36" },
  { chNo: "2014", tag: "T22" },
  { chNo: "2015", tag: "T23" },
  { chNo: "2016", tag: "T24" },
  { chNo: "2017", tag: "T37" },
  { chNo: "2018", tag: "T25" },
  { chNo: "2019", tag: "T26" },
  { chNo: "2020", tag: "T27" },
  { chNo: "2021", tag: "T28" },
  { chNo: "2022", tag: "T29" },
  { chNo: "2023", tag: "T30" },
  { chNo: "2024", tag: "T31" },
  { chNo: "2025", tag: "T7" },
  { chNo: "2026", tag: "T9" },
  { chNo: "2027", tag: "T32" },
  { chNo: "2028", tag: "T33" },
  { chNo: "2029", tag: "T34" },
  { chNo: "2030", tag: "T35" },
  { chNo: "2031", tag: "T1" },
  { chNo: "2032", tag: "T2" },
  { chNo: "2033", tag: "T11" },
  { chNo: "2034", tag: "T12" },
  { chNo: "2035", tag: "T41" },
  { chNo: "2036", tag: "T15" },
  { chNo: "2037", tag: "T16" },
  { chNo: "2038", tag: "T51" },
  { chNo: "2042", tag: "P3" },
  { chNo: "2043", tag: "P4" },
  { chNo: "2044", tag: "P43" },
  { chNo: "2045", tag: "P44" },
  { chNo: "2046", tag: "P5" },
  { chNo: "2047", tag: "P6" },
  { chNo: "2048", tag: "P7" },
  { chNo: "2049", tag: "P8" },
  { chNo: "2050", tag: "P9" },
  { chNo: "2051", tag: "P10" },
  { chNo: "2052", tag: "P13" },
  { chNo: "2053", tag: "P14" },
  { chNo: "2054", tag: "P42" },
  { chNo: "2055", tag: "P45" },
  { chNo: "2056", tag: "P1" },
  { chNo: "2057", tag: "P2" },
  { chNo: "2058", tag: "P11" },
  { chNo: "2059", tag: "P12" },
  { chNo: "2060", tag: "P41" },
  { chNo: "2061", tag: "P15" },
  { chNo: "2062", tag: "P16" },
  { chNo: "2063", tag: "P51" },
  { chNo: "2064", tag: "P46" },
  { chNo: "2065", tag: "P47" },
  { chNo: "2066", tag: "O1" },
  { chNo: "2067", tag: "O2" },
  { chNo: "2068", tag: "O3" },
  { chNo: "2069", tag: "O4" },
  { chNo: "2070", tag: "O5" },
  { chNo: "2071", tag: "O6" },
  { chNo: "2072", tag: "O7" },
  { chNo: "2073", tag: "O8" },
  { chNo: "2074", tag: "O9" },
  { chNo: "2075", tag: "O10" },
  { chNo: "2084", tag: "O11" },
  { chNo: "2086", tag: "O12" },
  { chNo: "2088", tag: "O13" },
  { chNo: "2100", tag: "O14" },
  { chNo: "2101", tag: "O15" },
  { chNo: "2102", tag: "O16" },
  { chNo: "2103", tag: "O17" },
  { chNo: "2104", tag: "O18" },
  { chNo: "2105", tag: "O19" },
];
