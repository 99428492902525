const electricTrendLines = [
  {
    key: "dg1",
    keyName: "dg1",
    name: "1 D/G",
    color: "#66c0f6",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "dg2",
    keyName: "dg2",
    name: "2 D/G",
    color: "#84eeff",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "dg3",
    keyName: "dg3",
    name: "3 D/G",
    color: "#56ebc1",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "dg4",
    keyName: "dg4",
    name: "4 D/G",
    color: "#56eb92",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "dg5",
    keyName: "dg5",
    name: "5 D/G",
    color: "#56eb6a",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "dg6",
    keyName: "dg6",
    name: "6 D/G",
    color: "#ffffff",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "sg1",
    keyName: "sg1",
    name: "1 S/G",
    color: "#b4affb",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "sg2",
    keyName: "sg2",
    name: "2 S/G",
    color: "#8f81ff",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "sg3",
    name: "3 S/G",
    keyName: "sg3",
    color: "#ffffff",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "sg4",
    name: "4 S/G",
    keyName: "sg4",
    color: "#ffffff",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "sg5",
    name: "5 S/G",
    keyName: "sg5",
    color: "#ffffff",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
  {
    key: "sg6",
    name: "6 S/G",
    keyName: "sg6",
    color: "#ffffff",
    enableVisibilityIcon: "hidden",
    enable: true,
  },
];

export { electricTrendLines };
