import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from "react-i18next";
import { ALARM_PAGE, alarmListByCategorySelector } from "../../../model/slice/alarmSlice.js";
import { isCurrentVesselSEP } from "../../../model/slice/vesselSlice.js";
import {
  CATEGORY_DISPLAY_ICON_MAPPING,
  CATEGORY_ENGINE,
  CATEGORY_INSULATOR,
  CATEGORY_WORDING_MAPPING,
  STATUS_PRIORITY_LIST,
} from "../../../constants/alarmType.js";
import { getHighlightStyle } from "../../../helpers/alarmTypeStyle.js";

const useStyles = makeStyles((theme) => ({
  base: {
    width: "100%",
    margin: "8px",
  },
  container: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "-14px",
      position: "fixed",
      width: "90%",
    },
  },
  container2: {
    display: "flex",
    width: "100%",
    alignItems: "start",
    flexBasis: "45%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
    },
    [theme.breakpoints.down('md')]: {
      marginTop: "0px",
    },
  },
  circleStatus: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    marginLeft: "6px",
    marginRight: "6px",
    display: "inline-block",
    [theme.breakpoints.only("sm")]: {
      height: "20px",
      width: "20px",
      marginLeft: "4px",
      marginRight: "4px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "10px",
      width: "10px",
      marginLeft: "2px",
      marginRight: "2px",
    },
  },
  categoryBlock: {
    textAlign: "center",
  },
  categoryTitle: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "center",
    flexDirection: "row",
    alignContent: "sretch",
    color: "white",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: "Inter",
    [theme.breakpoints.only("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px",
    },
  },
  categoryIcon: {
    height: "20px",
    [theme.breakpoints.only("sm")]: {
      height: "20px",
    },
    [theme.breakpoints.down('sm')]: {
      height: "14px",
    },
  },
}));

const AlarmTopNavigationMobile = ({
  t,
  currentPage,
  setPage,
  setDurationFilter,
  durationFilter,
  filterWord,
  setFilterWord,
  filterStatus,
  setFilterStatus,
  vesselId,
}) => {
  const styles = useStyles();
  const resetFilter = useRef();
  const isSEPVessel = useSelector(isCurrentVesselSEP);
  const alarmCategory = useSelector(alarmListByCategorySelector);
  const classes = { ...styles };

  useEffect(() => {
    if (resetFilter.current) resetFilter.current();
  }, [currentPage]);

  const displayStatusList = (categoryKey) => {
    const statusRender = [];

    STATUS_PRIORITY_LIST.map((e) => {
      //Default color is black if there are no active alarms in a specific status
      let colorStyle = { backgroundColor: "#000000" };

      //Check if a certain status is existing in alarm list of a category
      if (categoryKey in alarmCategory) {
        const categoryStatus = alarmCategory[categoryKey].filter(
          (alarm) => alarm.alarmStatus === e
        );

        //If a certain status has an alarm, get the correct color
        if (categoryStatus.length > 0) {
          colorStyle = getHighlightStyle({ alarmStatus: e });
        }
      }

      statusRender.push(<span key={e} className={classes.circleStatus} style={colorStyle}></span>);
    });

    return statusRender;
  };
  const displayCategoryList = () => {
    const categoryRender = [];
    for (const key in CATEGORY_WORDING_MAPPING) {
      // When vessel type is SEP. hide category_enginer and category_insulator
      if ((isSEPVessel && key == CATEGORY_ENGINE) || (isSEPVessel && key == CATEGORY_INSULATOR))
        continue;

      categoryRender.push(
        <Grid key={key} item xs={6} sm={6} md={3}>
          <div className={classes.categoryBlock}>
            <span className={classes.categoryIcon}>
              <img src={CATEGORY_DISPLAY_ICON_MAPPING[key]} className={classes.categoryIcon} />
            </span>
            <div className={classes.categoryTitle}>{displayStatusList(key)}</div>
          </div>
        </Grid>
      );
    }
    return categoryRender;
  };

  return (
    <div className={styles.base}>
      <div className={styles.container}>
        {currentPage == ALARM_PAGE.MONITORING && (
          <Grid item md={8} sm={12} className={styles.container2}>
            {displayCategoryList()}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(AlarmTopNavigationMobile);
