export const mockData = [
  {
    dateTime: "2023-07-21T00:00:14.000Z",
    1: 73,
    2: 48,
    3: 131,
    4: 348,
    5: 80,
    6: 82,
    7: 81,
    8: 81,
    9: 81,
    10: 81,
    11: 53,
    12: 53,
    13: 53,
    14: 53,
    15: 54,
    16: 53,
    localDateTime: "2023-07-21 09:00:14",
  },
  {
    dateTime: "2023-07-21T01:38:19.000Z",
    1: 74,
    2: 48,
    3: 131,
    4: 346,
    5: 80,
    6: 81,
    7: 80,
    8: 80,
    9: 80,
    10: 80,
    11: 52,
    12: 53,
    13: 53,
    14: 53,
    15: 53,
    16: 53,
    localDateTime: "2023-07-21 10:38:19",
  },
  {
    dateTime: "2023-07-21T03:16:14.000Z",
    1: 75,
    2: 48,
    3: 131,
    4: 347,
    5: 80,
    6: 81,
    7: 81,
    8: 80,
    9: 80,
    10: 80,
    11: 53,
    12: 53,
    13: 53,
    14: 53,
    15: 53,
    16: 53,
    localDateTime: "2023-07-21 12:16:14",
  },
  {
    dateTime: "2023-07-21T04:54:18.000Z",
    1: 76,
    2: 48,
    3: 131,
    4: 318,
    5: 80,
    6: 81,
    7: 81,
    8: 81,
    9: 81,
    10: 81,
    11: 51,
    12: 52,
    13: 52,
    14: 52,
    15: 52,
    16: 52,
    localDateTime: "2023-07-21 13:54:18",
  },
  {
    dateTime: "2023-07-21T06:32:11.000Z",
    1: 80,
    2: 48,
    3: 128,
    4: 178,
    5: 79,
    6: 79,
    7: 78,
    8: 79,
    9: 79,
    10: 79,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-21 15:32:11",
  },
  {
    dateTime: "2023-07-21T08:10:17.000Z",
    1: 76,
    2: 50,
    3: 129,
    4: 136,
    5: 76,
    6: 76,
    7: 75,
    8: 76,
    9: 76,
    10: 75,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 50,
    localDateTime: "2023-07-21 17:10:17",
  },
  {
    dateTime: "2023-07-21T09:48:12.000Z",
    1: 75,
    2: 51,
    3: 129,
    4: 107,
    5: 74,
    6: 74,
    7: 73,
    8: 74,
    9: 74,
    10: 74,
    11: 50,
    12: 51,
    13: 51,
    14: 51,
    15: 51,
    16: 51,
    localDateTime: "2023-07-21 18:48:12",
  },
  {
    dateTime: "2023-07-21T11:26:17.000Z",
    1: 73,
    2: 47,
    3: 130,
    4: 86,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 72,
    11: 46,
    12: 47,
    13: 47,
    14: 47,
    15: 47,
    16: 47,
    localDateTime: "2023-07-21 20:26:17",
  },
  {
    dateTime: "2023-07-21T13:04:12.000Z",
    1: 72,
    2: 48,
    3: 130,
    4: 72,
    5: 71,
    6: 71,
    7: 70,
    8: 71,
    9: 71,
    10: 71,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-21 22:04:12",
  },
  {
    dateTime: "2023-07-21T14:42:13.000Z",
    1: 71,
    2: 43,
    3: 129,
    4: 61,
    5: 70,
    6: 71,
    7: 70,
    8: 71,
    9: 70,
    10: 70,
    11: 42,
    12: 43,
    13: 43,
    14: 43,
    15: 43,
    16: 42,
    localDateTime: "2023-07-21 23:42:13",
  },
  {
    dateTime: "2023-07-21T16:20:23.000Z",
    1: 71,
    2: 48,
    3: 129,
    4: 54,
    5: 70,
    6: 70,
    7: 70,
    8: 70,
    9: 70,
    10: 70,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 49,
    16: 48,
    localDateTime: "2023-07-22 01:20:23",
  },
  {
    dateTime: "2023-07-21T17:58:16.000Z",
    1: 72,
    2: 50,
    3: 130,
    4: 51,
    5: 71,
    6: 71,
    7: 70,
    8: 71,
    9: 71,
    10: 71,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 49,
    localDateTime: "2023-07-22 02:58:16",
  },
  {
    dateTime: "2023-07-21T19:36:22.000Z",
    1: 73,
    2: 50,
    3: 131,
    4: 49,
    5: 72,
    6: 72,
    7: 72,
    8: 72,
    9: 72,
    10: 72,
    11: 50,
    12: 51,
    13: 51,
    14: 51,
    15: 51,
    16: 50,
    localDateTime: "2023-07-22 04:36:22",
  },
  {
    dateTime: "2023-07-21T21:14:14.000Z",
    1: 73,
    2: 48,
    3: 130,
    4: 48,
    5: 72,
    6: 73,
    7: 72,
    8: 73,
    9: 72,
    10: 72,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 49,
    16: 48,
    localDateTime: "2023-07-22 06:14:14",
  },
  {
    dateTime: "2023-07-21T22:52:21.000Z",
    1: 74,
    2: 50,
    3: 129,
    4: 47,
    5: 73,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 49,
    localDateTime: "2023-07-22 07:52:21",
  },
  {
    dateTime: "2023-07-22T00:30:13.000Z",
    1: 71,
    2: 45,
    3: 129,
    4: 46,
    5: 70,
    6: 71,
    7: 70,
    8: 71,
    9: 71,
    10: 70,
    11: 45,
    12: 46,
    13: 46,
    14: 46,
    15: 46,
    16: 45,
    localDateTime: "2023-07-22 09:30:13",
  },
  {
    dateTime: "2023-07-22T02:08:10.000Z",
    1: 71,
    2: 47,
    3: 129,
    4: 45,
    5: 70,
    6: 71,
    7: 70,
    8: 71,
    9: 71,
    10: 70,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-22 11:08:10",
  },
  {
    dateTime: "2023-07-22T03:46:17.000Z",
    1: 72,
    2: 48,
    3: 130,
    4: 45,
    5: 71,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 49,
    16: 48,
    localDateTime: "2023-07-22 12:46:17",
  },
  {
    dateTime: "2023-07-22T05:24:10.000Z",
    1: 74,
    2: 48,
    3: 130,
    4: 45,
    5: 73,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 49,
    16: 48,
    localDateTime: "2023-07-22 14:24:10",
  },
  {
    dateTime: "2023-07-22T07:02:18.000Z",
    1: 74,
    2: 49,
    3: 129,
    4: 46,
    5: 73,
    6: 73,
    7: 73,
    8: 73,
    9: 73,
    10: 73,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 49,
    localDateTime: "2023-07-22 16:02:18",
  },
  {
    dateTime: "2023-07-22T08:40:22.000Z",
    1: 74,
    2: 50,
    3: 128,
    4: 46,
    5: 73,
    6: 74,
    7: 73,
    8: 74,
    9: 74,
    10: 73,
    11: 50,
    12: 51,
    13: 51,
    14: 51,
    15: 51,
    16: 50,
    localDateTime: "2023-07-22 17:40:22",
  },
  {
    dateTime: "2023-07-22T10:18:15.000Z",
    1: 74,
    2: 47,
    3: 129,
    4: 46,
    5: 73,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 73,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-22 19:18:15",
  },
  {
    dateTime: "2023-07-22T11:56:22.000Z",
    1: 74,
    2: 49,
    3: 130,
    4: 46,
    5: 73,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 73,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 49,
    localDateTime: "2023-07-22 20:56:22",
  },
  {
    dateTime: "2023-07-22T13:34:14.000Z",
    1: 74,
    2: 48,
    3: 130,
    4: 46,
    5: 73,
    6: 73,
    7: 73,
    8: 73,
    9: 73,
    10: 73,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-22 22:34:14",
  },
  {
    dateTime: "2023-07-22T15:12:21.000Z",
    1: 74,
    2: 48,
    3: 129,
    4: 46,
    5: 73,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 73,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-23 00:12:21",
  },
  {
    dateTime: "2023-07-22T16:50:15.000Z",
    1: 74,
    2: 49,
    3: 129,
    4: 46,
    5: 73,
    6: 73,
    7: 73,
    8: 73,
    9: 73,
    10: 73,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 50,
    localDateTime: "2023-07-23 01:50:15",
  },
  {
    dateTime: "2023-07-22T18:28:21.000Z",
    1: 74,
    2: 50,
    3: 130,
    4: 45,
    5: 73,
    6: 73,
    7: 73,
    8: 73,
    9: 73,
    10: 73,
    11: 50,
    12: 51,
    13: 51,
    14: 51,
    15: 51,
    16: 50,
    localDateTime: "2023-07-23 03:28:21",
  },
  {
    dateTime: "2023-07-22T20:06:14.000Z",
    1: 73,
    2: 48,
    3: 131,
    4: 45,
    5: 72,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-23 05:06:14",
  },
  {
    dateTime: "2023-07-22T21:44:21.000Z",
    1: 73,
    2: 49,
    3: 130,
    4: 45,
    5: 72,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 49,
    localDateTime: "2023-07-23 06:44:21",
  },
  {
    dateTime: "2023-07-22T23:22:14.000Z",
    1: 72,
    2: 45,
    3: 132,
    4: 45,
    5: 71,
    6: 71,
    7: 71,
    8: 71,
    9: 71,
    10: 71,
    11: 45,
    12: 45,
    13: 45,
    14: 45,
    15: 46,
    16: 45,
    localDateTime: "2023-07-23 08:22:14",
  },
  {
    dateTime: "2023-07-23T01:00:21.000Z",
    1: 73,
    2: 48,
    3: 130,
    4: 45,
    5: 72,
    6: 73,
    7: 72,
    8: 72,
    9: 72,
    10: 72,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 49,
    16: 48,
    localDateTime: "2023-07-23 10:00:21",
  },
  {
    dateTime: "2023-07-23T02:38:14.000Z",
    1: 74,
    2: 49,
    3: 130,
    4: 45,
    5: 73,
    6: 74,
    7: 73,
    8: 73,
    9: 73,
    10: 73,
    11: 49,
    12: 49,
    13: 50,
    14: 50,
    15: 50,
    16: 49,
    localDateTime: "2023-07-23 11:38:14",
  },
  {
    dateTime: "2023-07-23T04:16:11.000Z",
    1: 74,
    2: 49,
    3: 128,
    4: 46,
    5: 73,
    6: 73,
    7: 73,
    8: 73,
    9: 73,
    10: 73,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 49,
    16: 49,
    localDateTime: "2023-07-23 13:16:11",
  },
  {
    dateTime: "2023-07-23T05:54:17.000Z",
    1: 74,
    2: 48,
    3: 131,
    4: 46,
    5: 73,
    6: 74,
    7: 73,
    8: 74,
    9: 73,
    10: 73,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 49,
    16: 48,
    localDateTime: "2023-07-23 14:54:17",
  },
  {
    dateTime: "2023-07-23T07:32:10.000Z",
    1: 85,
    2: 46,
    3: 131,
    4: 322,
    5: 88,
    6: 89,
    7: 88,
    8: 88,
    9: 88,
    10: 88,
    11: 50,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 50,
    localDateTime: "2023-07-23 16:32:10",
  },
  {
    dateTime: "2023-07-23T09:10:16.000Z",
    1: 78,
    2: 48,
    3: 132,
    4: 136,
    5: 77,
    6: 77,
    7: 76,
    8: 77,
    9: 77,
    10: 77,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-23 18:10:16",
  },
  {
    dateTime: "2023-07-23T10:48:11.000Z",
    1: 77,
    2: 42,
    3: 128,
    4: 113,
    5: 76,
    6: 77,
    7: 76,
    8: 77,
    9: 76,
    10: 76,
    11: 41,
    12: 42,
    13: 43,
    14: 43,
    15: 44,
    16: 44,
    localDateTime: "2023-07-23 19:48:11",
  },
  {
    dateTime: "2023-07-23T12:26:19.000Z",
    1: 77,
    2: 37,
    3: 128,
    4: 92,
    5: 76,
    6: 77,
    7: 76,
    8: 77,
    9: 77,
    10: 76,
    11: 38,
    12: 39,
    13: 40,
    14: 40,
    15: 41,
    16: 41,
    localDateTime: "2023-07-23 21:26:19",
  },
  {
    dateTime: "2023-07-23T14:04:24.000Z",
    1: 76,
    2: 35,
    3: 128,
    4: 77,
    5: 75,
    6: 76,
    7: 75,
    8: 76,
    9: 76,
    10: 75,
    11: 37,
    12: 37,
    13: 39,
    14: 39,
    15: 40,
    16: 40,
    localDateTime: "2023-07-23 23:04:24",
  },
  {
    dateTime: "2023-07-23T15:42:20.000Z",
    1: 75,
    2: 34,
    3: 129,
    4: 65,
    5: 74,
    6: 74,
    7: 73,
    8: 74,
    9: 74,
    10: 74,
    11: 35,
    12: 36,
    13: 37,
    14: 37,
    15: 39,
    16: 39,
    localDateTime: "2023-07-24 00:42:20",
  },
  {
    dateTime: "2023-07-23T17:20:14.000Z",
    1: 74,
    2: 33,
    3: 130,
    4: 58,
    5: 73,
    6: 73,
    7: 73,
    8: 73,
    9: 73,
    10: 73,
    11: 35,
    12: 35,
    13: 36,
    14: 36,
    15: 38,
    16: 38,
    localDateTime: "2023-07-24 02:20:14",
  },
  {
    dateTime: "2023-07-23T18:58:19.000Z",
    1: 73,
    2: 35,
    3: 129,
    4: 53,
    5: 72,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 34,
    12: 35,
    13: 36,
    14: 36,
    15: 37,
    16: 37,
    localDateTime: "2023-07-24 03:58:19",
  },
  {
    dateTime: "2023-07-23T20:36:12.000Z",
    1: 73,
    2: 33,
    3: 129,
    4: 51,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 34,
    12: 34,
    13: 35,
    14: 35,
    15: 36,
    16: 36,
    localDateTime: "2023-07-24 05:36:12",
  },
  {
    dateTime: "2023-07-23T22:14:17.000Z",
    1: 73,
    2: 32,
    3: 130,
    4: 44,
    5: 37,
    6: 38,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 33,
    12: 33,
    13: 35,
    14: 35,
    15: 36,
    16: 36,
    localDateTime: "2023-07-24 07:14:17",
  },
  {
    dateTime: "2023-07-23T23:52:19.000Z",
    1: 73,
    2: 32,
    3: 130,
    4: 40,
    5: 39,
    6: 38,
    7: 72,
    8: 73,
    9: 72,
    10: 72,
    11: 33,
    12: 33,
    13: 35,
    14: 35,
    15: 36,
    16: 35,
    localDateTime: "2023-07-24 08:52:19",
  },
  {
    dateTime: "2023-07-24T01:30:11.000Z",
    1: 72,
    2: 33,
    3: 128,
    4: 41,
    5: 40,
    6: 39,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 33,
    12: 33,
    13: 34,
    14: 34,
    15: 35,
    16: 35,
    localDateTime: "2023-07-24 10:30:11",
  },
  {
    dateTime: "2023-07-24T03:08:20.000Z",
    1: 71,
    2: 33,
    3: 128,
    4: 41,
    5: 39,
    6: 39,
    7: 69,
    8: 70,
    9: 70,
    10: 70,
    11: 33,
    12: 34,
    13: 35,
    14: 35,
    15: 35,
    16: 35,
    localDateTime: "2023-07-24 12:08:20",
  },
  {
    dateTime: "2023-07-24T04:46:11.000Z",
    1: 70,
    2: 34,
    3: 128,
    4: 41,
    5: 41,
    6: 40,
    7: 68,
    8: 69,
    9: 69,
    10: 69,
    11: 34,
    12: 34,
    13: 35,
    14: 35,
    15: 36,
    16: 35,
    localDateTime: "2023-07-24 13:46:11",
  },
  {
    dateTime: "2023-07-24T06:24:19.000Z",
    1: 69,
    2: 35,
    3: 129,
    4: 43,
    5: 68,
    6: 69,
    7: 68,
    8: 68,
    9: 68,
    10: 68,
    11: 34,
    12: 34,
    13: 35,
    14: 35,
    15: 36,
    16: 35,
    localDateTime: "2023-07-24 15:24:19",
  },
  {
    dateTime: "2023-07-24T08:02:12.000Z",
    1: 70,
    2: 46,
    3: 129,
    4: 44,
    5: 69,
    6: 70,
    7: 69,
    8: 70,
    9: 69,
    10: 69,
    11: 45,
    12: 46,
    13: 46,
    14: 46,
    15: 46,
    16: 46,
    localDateTime: "2023-07-24 17:02:12",
  },
  {
    dateTime: "2023-07-24T09:40:18.000Z",
    1: 71,
    2: 46,
    3: 128,
    4: 45,
    5: 70,
    6: 71,
    7: 70,
    8: 71,
    9: 70,
    10: 70,
    11: 46,
    12: 47,
    13: 47,
    14: 47,
    15: 47,
    16: 46,
    localDateTime: "2023-07-24 18:40:18",
  },
  {
    dateTime: "2023-07-24T11:18:22.000Z",
    1: 71,
    2: 48,
    3: 128,
    4: 46,
    5: 70,
    6: 70,
    7: 70,
    8: 70,
    9: 70,
    10: 70,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-24 20:18:22",
  },
  {
    dateTime: "2023-07-24T12:56:18.000Z",
    1: 72,
    2: 48,
    3: 128,
    4: 46,
    5: 71,
    6: 71,
    7: 71,
    8: 71,
    9: 71,
    10: 71,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-24 21:56:18",
  },
  {
    dateTime: "2023-07-24T14:34:22.000Z",
    1: 73,
    2: 49,
    3: 128,
    4: 46,
    5: 72,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 50,
    16: 49,
    localDateTime: "2023-07-24 23:34:22",
  },
  {
    dateTime: "2023-07-24T16:12:15.000Z",
    1: 74,
    2: 50,
    3: 128,
    4: 46,
    5: 73,
    6: 74,
    7: 73,
    8: 74,
    9: 74,
    10: 73,
    11: 50,
    12: 50,
    13: 50,
    14: 50,
    15: 51,
    16: 50,
    localDateTime: "2023-07-25 01:12:15",
  },
  {
    dateTime: "2023-07-24T17:50:12.000Z",
    1: 75,
    2: 51,
    3: 128,
    4: 47,
    5: 74,
    6: 75,
    7: 74,
    8: 75,
    9: 74,
    10: 74,
    11: 51,
    12: 51,
    13: 51,
    14: 51,
    15: 52,
    16: 51,
    localDateTime: "2023-07-25 02:50:12",
  },
  {
    dateTime: "2023-07-24T19:28:17.000Z",
    1: 74,
    2: 50,
    3: 129,
    4: 46,
    5: 73,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 50,
    localDateTime: "2023-07-25 04:28:17",
  },
  {
    dateTime: "2023-07-24T21:06:25.000Z",
    1: 73,
    2: 51,
    3: 130,
    4: 46,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 72,
    11: 50,
    12: 51,
    13: 51,
    14: 51,
    15: 51,
    16: 51,
    localDateTime: "2023-07-25 06:06:25",
  },
  {
    dateTime: "2023-07-24T22:44:16.000Z",
    1: 72,
    2: 49,
    3: 130,
    4: 45,
    5: 71,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 49,
    12: 49,
    13: 49,
    14: 49,
    15: 50,
    16: 49,
    localDateTime: "2023-07-25 07:44:16",
  },
  {
    dateTime: "2023-07-25T00:22:23.000Z",
    1: 73,
    2: 50,
    3: 130,
    4: 46,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 72,
    11: 50,
    12: 51,
    13: 51,
    14: 51,
    15: 51,
    16: 50,
    localDateTime: "2023-07-25 09:22:23",
  },
  {
    dateTime: "2023-07-25T02:00:16.000Z",
    1: 72,
    2: 39,
    3: 128,
    4: 46,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 39,
    12: 40,
    13: 40,
    14: 40,
    15: 40,
    16: 39,
    localDateTime: "2023-07-25 11:00:16",
  },
  {
    dateTime: "2023-07-25T03:38:23.000Z",
    1: 70,
    2: 47,
    3: 128,
    4: 45,
    5: 69,
    6: 69,
    7: 68,
    8: 69,
    9: 69,
    10: 69,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-25 12:38:23",
  },
  {
    dateTime: "2023-07-25T05:16:15.000Z",
    1: 72,
    2: 48,
    3: 128,
    4: 46,
    5: 71,
    6: 71,
    7: 70,
    8: 71,
    9: 71,
    10: 71,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-25 14:16:15",
  },
  {
    dateTime: "2023-07-25T06:54:22.000Z",
    1: 73,
    2: 49,
    3: 129,
    4: 46,
    5: 72,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 49,
    localDateTime: "2023-07-25 15:54:22",
  },
  {
    dateTime: "2023-07-25T08:32:13.000Z",
    1: 74,
    2: 51,
    3: 132,
    4: 47,
    5: 73,
    6: 74,
    7: 73,
    8: 74,
    9: 73,
    10: 73,
    11: 50,
    12: 51,
    13: 51,
    14: 51,
    15: 52,
    16: 51,
    localDateTime: "2023-07-25 17:32:13",
  },
  {
    dateTime: "2023-07-25T10:10:20.000Z",
    1: 73,
    2: 49,
    3: 129,
    4: 52,
    5: 72,
    6: 73,
    7: 72,
    8: 72,
    9: 72,
    10: 72,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 49,
    16: 48,
    localDateTime: "2023-07-25 19:10:20",
  },
  {
    dateTime: "2023-07-25T11:48:13.000Z",
    1: 73,
    2: 48,
    3: 130,
    4: 48,
    5: 72,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 49,
    16: 48,
    localDateTime: "2023-07-25 20:48:13",
  },
  {
    dateTime: "2023-07-25T13:26:21.000Z",
    1: 70,
    2: 47,
    3: 128,
    4: 47,
    5: 69,
    6: 70,
    7: 69,
    8: 69,
    9: 69,
    10: 69,
    11: 46,
    12: 47,
    13: 47,
    14: 47,
    15: 47,
    16: 47,
    localDateTime: "2023-07-25 22:26:21",
  },
  {
    dateTime: "2023-07-25T15:04:13.000Z",
    1: 69,
    2: 48,
    3: 128,
    4: 45,
    5: 68,
    6: 69,
    7: 68,
    8: 69,
    9: 68,
    10: 68,
    11: 48,
    12: 48,
    13: 48,
    14: 48,
    15: 49,
    16: 48,
    localDateTime: "2023-07-26 00:04:13",
  },
  {
    dateTime: "2023-07-25T16:42:17.000Z",
    1: 70,
    2: 48,
    3: 129,
    4: 44,
    5: 68,
    6: 69,
    7: 68,
    8: 69,
    9: 69,
    10: 68,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-26 01:42:17",
  },
  {
    dateTime: "2023-07-25T18:20:24.000Z",
    1: 69,
    2: 48,
    3: 130,
    4: 44,
    5: 68,
    6: 69,
    7: 68,
    8: 69,
    9: 69,
    10: 68,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-26 03:20:24",
  },
  {
    dateTime: "2023-07-25T19:58:28.000Z",
    1: 70,
    2: 48,
    3: 130,
    4: 44,
    5: 69,
    6: 69,
    7: 69,
    8: 69,
    9: 69,
    10: 69,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-26 04:58:28",
  },
  {
    dateTime: "2023-07-25T21:36:11.000Z",
    1: 69,
    2: 48,
    3: 129,
    4: 43,
    5: 68,
    6: 69,
    7: 68,
    8: 69,
    9: 69,
    10: 68,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-26 06:36:11",
  },
  {
    dateTime: "2023-07-25T23:14:16.000Z",
    1: 73,
    2: 48,
    3: 129,
    4: 44,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-26 08:14:16",
  },
  {
    dateTime: "2023-07-26T00:52:22.000Z",
    1: 64,
    2: 42,
    3: 129,
    4: 44,
    5: 64,
    6: 65,
    7: 64,
    8: 68,
    9: 68,
    10: 68,
    11: 43,
    12: 45,
    13: 43,
    14: 43,
    15: 44,
    16: 44,
    localDateTime: "2023-07-26 09:52:22",
  },
  {
    dateTime: "2023-07-26T02:30:15.000Z",
    1: 52,
    2: 36,
    3: 128,
    4: 41,
    5: 54,
    6: 55,
    7: 53,
    8: 57,
    9: 57,
    10: 58,
    11: 40,
    12: 42,
    13: 40,
    14: 40,
    15: 41,
    16: 41,
    localDateTime: "2023-07-26 11:30:15",
  },
  {
    dateTime: "2023-07-26T04:08:21.000Z",
    1: 47,
    2: 35,
    3: 129,
    4: 38,
    5: 48,
    6: 49,
    7: 50,
    8: 48,
    9: 51,
    10: 51,
    11: 38,
    12: 40,
    13: 39,
    14: 39,
    15: 40,
    16: 40,
    localDateTime: "2023-07-26 13:08:21",
  },
  {
    dateTime: "2023-07-26T05:46:14.000Z",
    1: 43,
    2: 35,
    3: 130,
    4: 36,
    5: 44,
    6: 45,
    7: 44,
    8: 47,
    9: 46,
    10: 47,
    11: 38,
    12: 39,
    13: 39,
    14: 39,
    15: 39,
    16: 39,
    localDateTime: "2023-07-26 14:46:14",
  },
  {
    dateTime: "2023-07-26T07:24:22.000Z",
    1: 56,
    2: 46,
    3: 128,
    4: 35,
    5: 55,
    6: 55,
    7: 54,
    8: 55,
    9: 55,
    10: 54,
    11: 46,
    12: 47,
    13: 47,
    14: 47,
    15: 47,
    16: 47,
    localDateTime: "2023-07-26 16:24:22",
  },
  {
    dateTime: "2023-07-26T09:02:15.000Z",
    1: 72,
    2: 47,
    3: 129,
    4: 40,
    5: 71,
    6: 71,
    7: 70,
    8: 71,
    9: 71,
    10: 71,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-26 18:02:15",
  },
  {
    dateTime: "2023-07-26T10:40:21.000Z",
    1: 71,
    2: 47,
    3: 129,
    4: 44,
    5: 70,
    6: 70,
    7: 70,
    8: 70,
    9: 70,
    10: 70,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-26 19:40:21",
  },
  {
    dateTime: "2023-07-26T12:18:11.000Z",
    1: 73,
    2: 48,
    3: 130,
    4: 45,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 48,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-26 21:18:11",
  },
  {
    dateTime: "2023-07-26T13:56:19.000Z",
    1: 74,
    2: 50,
    3: 130,
    4: 45,
    5: 73,
    6: 74,
    7: 73,
    8: 74,
    9: 74,
    10: 73,
    11: 49,
    12: 50,
    13: 50,
    14: 50,
    15: 50,
    16: 50,
    localDateTime: "2023-07-26 22:56:19",
  },
  {
    dateTime: "2023-07-26T15:34:11.000Z",
    1: 73,
    2: 47,
    3: 130,
    4: 46,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 46,
    12: 47,
    13: 47,
    14: 47,
    15: 47,
    16: 47,
    localDateTime: "2023-07-27 00:34:11",
  },
  {
    dateTime: "2023-07-26T17:12:16.000Z",
    1: 73,
    2: 48,
    3: 129,
    4: 45,
    5: 72,
    6: 72,
    7: 72,
    8: 72,
    9: 72,
    10: 72,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-27 02:12:16",
  },
  {
    dateTime: "2023-07-26T18:50:22.000Z",
    1: 74,
    2: 43,
    3: 129,
    4: 46,
    5: 73,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 72,
    11: 42,
    12: 43,
    13: 43,
    14: 43,
    15: 43,
    16: 42,
    localDateTime: "2023-07-27 03:50:22",
  },
  {
    dateTime: "2023-07-26T20:28:14.000Z",
    1: 71,
    2: 48,
    3: 128,
    4: 45,
    5: 70,
    6: 71,
    7: 70,
    8: 71,
    9: 70,
    10: 70,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-27 05:28:14",
  },
  {
    dateTime: "2023-07-26T22:06:20.000Z",
    1: 75,
    2: 47,
    3: 129,
    4: 45,
    5: 73,
    6: 74,
    7: 73,
    8: 74,
    9: 74,
    10: 73,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-27 07:06:20",
  },
  {
    dateTime: "2023-07-26T23:44:17.000Z",
    1: 72,
    2: 45,
    3: 129,
    4: 45,
    5: 71,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 44,
    12: 46,
    13: 45,
    14: 45,
    15: 46,
    16: 45,
    localDateTime: "2023-07-27 08:44:17",
  },
  {
    dateTime: "2023-07-27T01:22:11.000Z",
    1: 71,
    2: 37,
    3: 130,
    4: 45,
    5: 70,
    6: 70,
    7: 69,
    8: 70,
    9: 70,
    10: 70,
    11: 39,
    12: 42,
    13: 41,
    14: 41,
    15: 41,
    16: 41,
    localDateTime: "2023-07-27 10:22:11",
  },
  {
    dateTime: "2023-07-27T03:00:15.000Z",
    1: 71,
    2: 48,
    3: 130,
    4: 45,
    5: 70,
    6: 71,
    7: 70,
    8: 71,
    9: 71,
    10: 70,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-27 12:00:15",
  },
  {
    dateTime: "2023-07-27T04:38:20.000Z",
    1: 71,
    2: 48,
    3: 130,
    4: 45,
    5: 70,
    6: 71,
    7: 70,
    8: 70,
    9: 70,
    10: 70,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-27 13:38:20",
  },
  {
    dateTime: "2023-07-27T06:16:14.000Z",
    1: 73,
    2: 47,
    3: 129,
    4: 46,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 71,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-27 15:16:14",
  },
  {
    dateTime: "2023-07-27T07:54:23.000Z",
    1: 72,
    2: 47,
    3: 129,
    4: 47,
    5: 71,
    6: 72,
    7: 71,
    8: 72,
    9: 71,
    10: 71,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-27 16:54:23",
  },
  {
    dateTime: "2023-07-27T09:32:14.000Z",
    1: 72,
    2: 47,
    3: 130,
    4: 47,
    5: 71,
    6: 71,
    7: 70,
    8: 71,
    9: 71,
    10: 71,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-27 18:32:14",
  },
  {
    dateTime: "2023-07-27T11:10:22.000Z",
    1: 71,
    2: 48,
    3: 130,
    4: 46,
    5: 70,
    6: 71,
    7: 70,
    8: 71,
    9: 70,
    10: 70,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-27 20:10:22",
  },
  {
    dateTime: "2023-07-27T12:48:11.000Z",
    1: 71,
    2: 48,
    3: 130,
    4: 46,
    5: 70,
    6: 71,
    7: 70,
    8: 71,
    9: 70,
    10: 70,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-27 21:48:11",
  },
  {
    dateTime: "2023-07-27T14:26:19.000Z",
    1: 71,
    2: 48,
    3: 130,
    4: 46,
    5: 70,
    6: 71,
    7: 70,
    8: 71,
    9: 70,
    10: 70,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-27 23:26:19",
  },
  {
    dateTime: "2023-07-27T16:04:22.000Z",
    1: 70,
    2: 48,
    3: 130,
    4: 45,
    5: 69,
    6: 70,
    7: 69,
    8: 70,
    9: 70,
    10: 69,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-28 01:04:22",
  },
  {
    dateTime: "2023-07-27T17:42:16.000Z",
    1: 70,
    2: 47,
    3: 130,
    4: 45,
    5: 69,
    6: 70,
    7: 69,
    8: 70,
    9: 70,
    10: 69,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 47,
    localDateTime: "2023-07-28 02:42:16",
  },
  {
    dateTime: "2023-07-27T19:20:23.000Z",
    1: 72,
    2: 48,
    3: 129,
    4: 45,
    5: 71,
    6: 71,
    7: 71,
    8: 71,
    9: 71,
    10: 71,
    11: 47,
    12: 48,
    13: 48,
    14: 48,
    15: 48,
    16: 48,
    localDateTime: "2023-07-28 04:20:23",
  },
  {
    dateTime: "2023-07-27T20:58:18.000Z",
    1: 73,
    2: 49,
    3: 128,
    4: 45,
    5: 72,
    6: 72,
    7: 71,
    8: 72,
    9: 72,
    10: 72,
    11: 48,
    12: 49,
    13: 49,
    14: 49,
    15: 49,
    16: 49,
    localDateTime: "2023-07-28 05:58:18",
  },
  {
    dateTime: "2023-07-27T22:36:21.000Z",
    1: 74,
    2: 50,
    3: 129,
    4: 45,
    5: 73,
    6: 73,
    7: 72,
    8: 73,
    9: 73,
    10: 73,
    11: 50,
    12: 50,
    13: 50,
    14: 50,
    15: 51,
    16: 50,
    localDateTime: "2023-07-28 07:36:21",
  },
  {
    dateTime: "2023-07-28T00:14:13.000Z",
    1: 74,
    2: 51,
    3: 129,
    4: 46,
    5: 73,
    6: 74,
    7: 73,
    8: 74,
    9: 73,
    10: 73,
    11: 51,
    12: 51,
    13: 51,
    14: 51,
    15: 52,
    16: 51,
    localDateTime: "2023-07-28 09:14:13",
  },
  {
    dateTime: "2023-07-28T01:52:33.000Z",
    1: 75,
    2: 52,
    3: 129,
    4: 46,
    5: 74,
    6: 75,
    7: 74,
    8: 74,
    9: 74,
    10: 74,
    11: 51,
    12: 52,
    13: 52,
    14: 52,
    15: 53,
    16: 52,
    localDateTime: "2023-07-28 10:52:33",
  },
  {
    dateTime: "2023-07-28T03:30:20.000Z",
    1: 76,
    2: 50,
    3: 130,
    4: 47,
    5: 75,
    6: 75,
    7: 74,
    8: 75,
    9: 75,
    10: 75,
    11: 50,
    12: 50,
    13: 50,
    14: 50,
    15: 51,
    16: 50,
    localDateTime: "2023-07-28 12:30:20",
  },
];
