import { useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material";
import WorldIcon from "../../../assets/images/timezoneSelector/FontistoWorld.svg"
import React, { memo, useEffect, useState } from "react";
import Select from "react-select";

//Context imports
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import dayjs from 'util/dayjs-init.js';
import { setTimezone } from "../../model/slice/timezoneSlice";

const timeZoneSelectStyle = () => ({
  control: (base, state) => ({
    ...base,
    height: "100%",
    width: "155px",
    background: "#020202",
    boxShadow: null,
    border: "none",
    minHeight: "40px",
    marginRight: "20px",
    flexDirection: "row-reverse",
    "& p": {
      overflowWrap: "normal",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
    },
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    background: "rgba(2,2,2,0.7)",
  }),
  valueContainer: (base, state) => ({
    ...base,
    overflowWrap: "normal",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    marginLeft: "25px",
    "& svg": {
      fill: "#ffffff",
    },
    "$ svg:checked": {
      fill: "#ffffff",
    },
    zIndex: 999,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),
  menu: (base) => ({
    ...base,
    width: "96%",
    marginTop: "8px",
    zIndex: 9999,
    position: "absolute",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: "#28b6c7",
    margin: "auto",
    zIndex: 9999,
  }),
  option: (base, state) => ({
    ...base,
    marginTop: "2px",
    background: state.data.value === state.selectProps.value.value ? "#2c3a48" : "none",
    "&:hover": {
      background: "rgba(44, 58, 72, 0.6)",
    },
    color: state.data.value === state.selectProps.value.value ? "#28b6c7" : "rgba(255, 255, 255, 0.7)"
  }),
  menuList: (base) => ({
    background: "#161a1e",
    padding: "6px 0 8px 0",
    zIndex: 9999,
    maxHeight: `calc(${window.innerHeight}px - 66px)`,
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    overflowY: "auto",
    borderRadius: "2px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    overflowX: "hidden",
  }),
});

const Timezone = memo(() => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [timeSelectorList, setTimeSelectorList] = useState([
    { value: "UTC", label: "UTC", currTime: dayjs.utc(currentTime).format("HH:mm") },
    { value: "LT", label: "LT", currTime: dayjs(currentTime).format("HH:mm") }
  ]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(localStorage.getItem("timeZone") == "LT" ? timeSelectorList[1] : timeSelectorList[0]);

  const getTimeValue = (timezone) => timezone === 'UTC' ? dayjs.utc(currentTime).format("HH:mm") : dayjs(currentTime).format("HH:mm");

  useEffect(() => {
    dispatch(setTimezone(selectedTimeZone.value));
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);
    
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const timeValue = getTimeValue("UTC");
    if (timeValue === timeSelectorList[0].currTime) return;

    setTimeSelectorList(prevState => 
      prevState.map(el => 
        ({
          ...el,
          currTime: getTimeValue(el.value)
        })
      )
    );
  }, [currentTime]);

  useEffect(() => {
    setSelectedTimeZone(prevState => ({
      ...prevState,
      currTime: getTimeValue(prevState.value)
    }));
  }, [timeSelectorList]);

  const timeZoneLabels = ({ label, currTime }) => (
    <div style={{ display: "flex" }}>
      <div style={{ float: "left", width: "80%", }}>{label}</div>
      <div style={{ marginLeft: "8px", fontVariantNumeric: "tabular-nums" }}>{currTime}</div>
    </div>
  );

  const timeZoneChanged = (v) => {
    setSelectedTimeZone(v.value == "UTC" ? timeSelectorList[0] : timeSelectorList[1]);
    dispatch(setTimezone(v.value));
    localStorage.setItem("timeZone", v.value);
  };

  return (
    <Grid style={{ position: "absolute", display: "flex", right: isSm ? "95px" : "150px" }}>
      <img style={{ marginRight: "-25px", zIndex: "9999" }} src={WorldIcon} />
      <Select id="timeZoneSelector"
        options={timeSelectorList}
        value={selectedTimeZone}
        onChange={(v) => {timeZoneChanged(v)}}
        formatOptionLabel={timeZoneLabels}
        menuPosition={"fixed"}
        styles={timeZoneSelectStyle()}
        isSearchable={false}
      />
    </Grid>
  );
});

export default withTranslation()(Timezone);
