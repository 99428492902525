export const COLORS = {
  backgroundColor: "#1b202a",
  baseColor: "#21262f",
  componentBackColor: "#2c3a48",
  white: "#fff",
  black: "#000000",
  red: "#FF0000",
  blue: "#0000FF",
  lightBlue: "#19b2ab",
  darkBlue: "#909090",
  shipCurveColor: "#82ca9d",
  portYellow: "#fff9bd",
  starBoardPink: "#eb4fc9",
  centerBlue: "#a6f8ff",
  tabSelectorColor: "#252D39",
  greenishBlue: "#28b6c7",
  thresholdRed: "#ff3333",
  black80: "#000000cc",
  black70: "#000000B3",
  navyBlue80: "#00234dcc",
  skyBlue: "#64d9e7",
  skyBlue80: "#64d9e7cc",
  hoverSate: "#FFFFFF",
  activeState: "#FFFFFF",
  onClick: "#64D9E7",
  disableState: "#D8D8D8",
  staticState: "#D8D8D8",
  confirmationModalColor: "#161a1e",
  THRESHOLD_COLOR_LIST: ["#fff700", "#fea813", "#fe7613", "#ff2d00"],
  overHighThreshold: "#ff3333",
  underLowThresholdBar: "#c344ff",
  lowThresholdLine: "#8676ff",
  white70: "rgba(255,255,255,0.70)",
  monitoringGaugeBackground: "#304049",
};
