import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { withTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import constants from "../utils/constants.js";
import dayjs from 'util/dayjs-init.js';
import { useDispatch, useSelector } from "react-redux";
import {
  ALARM_PAGE,
  currentAlarmSelector,
  currentPageSelector,
  historyAlarmSelector,
  operationAlarmSelector,
  resetFilters,
  alarmFilterSelector,
} from "../../../model/slice/alarmSlice.js";
import { detectDevice, DEVICE_TYPE } from "../../../constants/detectDevice.js";
import { NAVIGATION_BAR_HEIGHT } from "../../../layout/panelLayout.jsx";
import SearchItem from "./searchItem.jsx";
import SearchValue from "./searchValue.jsx";
import { useInputText } from "./useInputText.js";
import { useInputValueText } from "./useInputValueText.js";

import OccurrenceFilter from "./dateFilter/occurrenceFilter.jsx";
import RecoveryFilter from "./dateFilter/recoveryFilter.jsx";
import DatetimeFilter from "./dateFilter/datetimeFilter.jsx";

const filterBackGroundColor = "#252d39";

const FILTER_HEIGHT = 110;

const column = (theme, isEnglish) => ({
  fontSize: "10px",
  fontFamily: "Inter",
  fontWeight: "500",
  color: "#ffffff",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  minWidth: "100px",
  [theme.breakpoints.only("sm")]: {
    width: "70px",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "10px !important",
    width: "100%",
    marginBottom: "12px",
  },
});

const durationFilter = (theme) => ({
  fontFamily: "Inter",
  fontSize: "10px",
  fontWeight: "300",
  marginLeft: "5px",
  minWidth: "150px",
  [theme.breakpoints.down('md')]: {
    minWidth: "180px",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: "10px",
  },
});

const useStyles = ({ isEnglish }) =>
  makeStyles((theme) => ({
    item: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down('sm')]: {
        display: "block",
        marginBottom: "6px",
        "text-align": "left",
      },
    },
    itemForSearchDate: {
      alignItems: "left",
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down('sm')]: {
        display: "block",
        marginBottom: "6px",
        "text-align": "left",
      },
    },
    disableColumnName: {
      ...column(theme, isEnglish),
      color: "rgba(255,255,255,0.3) !important",
    },
    columnName: {
      ...column(theme, isEnglish),
    },
    searchLabel: {
      fontSize: "14px",
      fontStyle: "italic",
    },
    baseContainer: {
      borderRadius: "8px",
      display: "flex",
      height: "100%",
      [theme.breakpoints.down('sm')]: {
        marginLeft: "0px !important",
        marginRight: "0px !important",
      },
    },
    durationFilter: {
      ...durationFilter(theme),
      color: "rgba(255,255,255,0.6)",
    },
    disabledDurationFilter: {
      ...durationFilter(theme),
      color: "rgba(255,255,255,0.3)",
    },
    durationSelectorBox: {
      backgroundColor: filterBackGroundColor,
      display: "flex",
      alignItems: "center",
      height: "25px",
      borderRadius: "2px",
      marginTop: "8px",
      "&:hover": {
        cursor: "pointer",
      },
      justifyContent: "flex-end",
      [theme.breakpoints.down('sm')]: {
        backgroundColor: "#252d39 !important",
        height: "24px",
        borderRadius: "2px",
        marginLeft: "0",
        paddingRight: "10px",
      },
    },
    placeholder: {
      fontStyle: "italic",
    },
    selectItem: {
      color: "#FFFFFF",
      marginLeft: "10px",
      flexGrow: 1,
      "& .MuiSvgIcon-root": {
        color: "#19b2ab",
      },
    },
    selectorBox: {
      flexGrow: 1,
      paddingRight: "8px",
      [theme.breakpoints.down('sm')]: {
        marginTop: "10px",
      },
    },
    calendarIcon: {
      zIndex: 100,
    },
    occurrenceColumn: {
      marginBottom: "8px",
      whiteSpace: "nowrap",
    },
    recoveryColumn: {
      marginBottom: "8px",
      whiteSpace: isEnglish ? "" : "nowrap",
    },
    durationDisplay: {
      marginLeft: "10px",
    },
    calendarDiv: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    popoverBase: {
      "& .MuiPaper-root": {
        backgroundColor: "none",
      },
    },
    calendarBase: {
      position: "fixed",
      top: "100px",
      right: "100px",
    },
    popover: {
      zIndex: 5,
    },
    centerItem: {
      display: "flex",
      justifyContent: "center",
    },
    omitOverflowCharacters: {
      display: "-webkit-box",
      overflow: "hidden",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
    filterGrid: {
      height: "100%",
      [theme.breakpoints.down('sm')]: {
        marginLeft: "0px !important",
        marginRight: "0px !important",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: "15px",
    },
    calenderContainer: {
      marginTop: "10px",
      height: `${FILTER_HEIGHT}px`,
    },
    durationFilterAdjust: {
      display: "flex",
      alignItems: "center",
    },
    paperContainer: {
      backgroundColor: filterBackGroundColor,
      width: "100%",
    },
    backGroundLine: {
      content: "",
      flexGrow: 1,
      height: "1px",
      display: "block",
      width: "100%",
      maxWidth: `${15 * 7}px`,
      backgroundSize: "15px 1px",
      backgroundRepeat: "space", // "repeat-x",
      backgroundImage:
        "linear-gradient(to right, rgba(255,255,255, 0.6), rgba(255,255,255, 0.6) 7px, transparent 7px, transparent 15px)",
      marginLeft: "10px",
      marginRight: "10px",
    },
    backgroundLineContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      width: "100%",
      height: "100%",
    },
  }));

const AlarmSearch = React.memo(({ t, i18n, resetFilter }) => {
  const isMobile = detectDevice === DEVICE_TYPE.PHONE;
  const classes = useStyles({ isEnglish: i18n.language === "en-US" })();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const alarmHistoryList = useSelector(historyAlarmSelector);
  const alarmCurrentList = useSelector(currentAlarmSelector);
  const alarmOperationList = useSelector(operationAlarmSelector);
  const alarmFilters = useSelector(alarmFilterSelector);
  const currentPage = useSelector(currentPageSelector);
  const dispatch = useDispatch();
  const { currentText, setCurrentText, resetSearchText } = useInputText();
  const { currentValueText, setCurrentValueText, resetSearchValueText } =
    useInputValueText();
  const minDate = useMemo(() => {
    const currentDateTime = dayjs.utc();
    if (!alarmHistoryList?.length) return new Date();
    return alarmHistoryList
      .map((d) => [
        dayjs.utc(d.dateTime),
        d?.recoveryDate ? dayjs.utc(d.recoveryDate) : currentDateTime,
      ])
      .flat()
      .sort((a, b) => {
        if (a.isAfter(b)) return 1;
        else if (a.isBefore(b)) return -1;
        else return 0;
      })[0]
      .toDate();
  }, [alarmHistoryList]);
  const datetimeMinDate = useMemo(() => {
    const currentDateTime = dayjs.utc();
    if (!alarmOperationList?.length) return new Date();
    return alarmOperationList
      .map((d) => [dayjs.utc(d.dateTime), d?.dateTime ? dayjs.utc(d.dateTime) : currentDateTime])
      .flat()
      .sort((a, b) => {
        if (a.isAfter(b)) return 1;
        else if (a.isBefore(b)) return -1;
        else return 0;
      })[0]
      .toDate();
  }, [alarmOperationList]);
  const options = () => {
    if (currentPage === ALARM_PAGE.MONITORING) return alarmCurrentList;
    else if (currentPage === ALARM_PAGE.HISTORY) return alarmHistoryList;
  };
  const itemFieldRef = useRef();
  const statusFieldRef = useRef();

  const resetFilterFunction = () => {
    resetSearchText();
    resetSearchValueText();
    itemFieldRef.current();
    statusFieldRef.current();
    dispatch(resetFilters());
  };

  resetFilter.current = resetFilterFunction;

  useEffect(() => {
    resetFilterFunction();
  }, [currentPage]);

  const removeDuplicate = (array) => {
    return Array.from(new Set(array));
  };

  const autocompleteOptions = useCallback(() => {
    const allChannelName =
      options()
        ?.map((d) => d[constants.AMS_CHANNEL_COLUMN])
        ?.filter((d) => ![null, undefined, ""].includes(d)) ?? [];
    return removeDuplicate(allChannelName);
  }, [alarmHistoryList, alarmCurrentList, currentPage]);

  const autocompleteOptionsForValue = useCallback(() => {
    const allValue =
      options()
        ?.map((d) => d.value)
        ?.filter((d) => ![null, undefined, "", "-"].includes(d)) ?? [];
    return removeDuplicate(allValue);
  }, [alarmHistoryList, alarmCurrentList, currentPage]);

  const disableDateFilter = () => currentPage !== ALARM_PAGE.HISTORY;

  return (
    <Grid container alignItems="center">
      <Grid
        container
        item
        xs={12}
        className={classes.baseContainer}
        alignItems="center"
        spacing={2}
      >
        <Grid container item xs={12} sm={6} className={classes.filterGrid} spacing={2}>
          <Grid item xs={12}>
            <SearchItem
              isOperationPage={ALARM_PAGE.OPERATION === currentPage}
              text={currentText}
              setText={setCurrentText}
              autocompleteOptions={autocompleteOptions}
              resetFunc={itemFieldRef}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchValue
              isOperationPage={ALARM_PAGE.OPERATION === currentPage}
              text={currentValueText}
              setText={setCurrentValueText}
              autocompleteOptions={autocompleteOptionsForValue}
              resetFunc={statusFieldRef}
            />
          </Grid>
        </Grid>
        {currentPage === ALARM_PAGE.OPERATION ? (
          <Grid container item className={classes.filterGrid} xs={12} sm={6} spacing={2}>
            {!isXs && <Grid item xs={12} style={{ height: "61px" }} />}
            <Grid item xs={12}>
              <DatetimeFilter
                classes={classes}
                alarmFilters={alarmFilters}
                isXs={isXs}
                isMobile={isMobile}
                anchorTop={NAVIGATION_BAR_HEIGHT}
                minDate={datetimeMinDate}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container item className={classes.filterGrid} xs={12} sm={6} spacing={2}>
            <Grid item xs={12}>
              <OccurrenceFilter
                disabled={disableDateFilter()}
                classes={classes}
                alarmFilters={alarmFilters}
                isXs={isXs}
                isMobile={isMobile}
                anchorTop={NAVIGATION_BAR_HEIGHT}
                minDate={minDate}
              />
            </Grid>
            <Grid item xs={12}>
              <RecoveryFilter
                disabled={disableDateFilter()}
                classes={classes}
                alarmFilters={alarmFilters}
                isXs={isXs}
                isMobile={isMobile}
                anchorTop={NAVIGATION_BAR_HEIGHT}
                minDate={minDate}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

AlarmSearch.propTypes = {
  t: PropTypes.func,
  alarmHistoryList: PropTypes.array,
  i18n: PropTypes.object,
};

export default withTranslation()(AlarmSearch);
