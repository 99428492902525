import React, { useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { STATUS_ABNORMAL } from "../../constants/alarmType.js";

import withStyles from '@mui/styles/withStyles';

//UI icon imports
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "../../../assets/images/fleetListSearch/Icon_Search.svg"

//Vessel Icon imports
import VesselIcon from "../vesselImages/vesselIcon.js";
import { getAlarmHighestPriority, getMarkerColor } from "../../helpers/alarmTypeStyle.js";

//Redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  fleetNavigationData,
  setSelectedVessel,
  selectedVessel,
} from "../../model/slice/fleetNavigationDataSlice.js";
import { fleetAlarmSelector } from "../../model/slice/alarmSlice.js";

//Vessel type const
import { SHIP_GROUP_TYPE, SHIP_TYPE } from "../../constants/vesselShipType.js";
import { useDetectVesselConnection } from "../../hooks/useDetectVesselConnection.js";
import { VESSEL_TYPE } from "../../model/slice/vesselSlice.js";

import SortUtil from "../../helpers/sortUtil.js";
import { fleetEngineData } from "../../model/slice/fleetEngineDataSlice.js";
import "../../../css/marker.css";

const styles = (theme) => ({
  fleetList: {
    borderRadius: "5px",
    backgroundColor: "rgba(2, 2, 2, 0.7)",
    fontSize: "12px",
    color: "#ffffff",
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    overflow: "hidden"
  },
  fleetListExpanded: {
    display: "flex",
    flexDirection: "column",
  },
  fleetListCollapsed: {
    height: "44px",
  },
  searchBarExpanded: {
    padding: "5px 0 30px 0",
  },
  searchBarCollapsed: {
    display: "none",
  },
  searchBarDiv: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    marginLeft: "12.5%", 
    width: "75%",
    height: "28px",
    borderRadius: "2px",
  },
  searchBar: { 
    width: "90%",
    padding: "0 0 0 6px",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0)",
    border: "none",
    float: "left"
  },
  expandIcon: {
    display: "block",
    margin: "auto",
    cursor: "pointer",
    fill: "#19b2ab",
  },
  dataEntry: {
    cursor: "pointer",
    height: "44px",
    paddingTop: "8px",
    width: "100%",
    display: "table",
    tableLayout: "fixed",
    "&:hover": {
      backgroundColor: "rgba(2,2,2,0.3)",
    },
  },
  shipSelected: {
    backgroundColor: "rgba(2, 2, 2, 0.5) !important",
    cursor: "pointer",
    height: "44px",
    paddingTop: "8px",
    width: "100%",
    display: "table",
    tableLayout: "fixed",
  },
  fleetTable: {
    borderCollapse: "collapse !important",
    width: "100%",
    flex: 1,
    display: "block",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "overflow-y": "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  fleetNameHolder: {
    display: "inline-table",
  },
  connectedFleetName: {
    display: "table-cell",
    verticalAlign: "middle",
    color: "#19b2ab",
  },
  disconnectedFleetName: {
    display: "table-cell",
    verticalAlign: "middle",
    color: "#ffffff",
  },
  disConnectedVessel: {
    zIndex: "100",
  },
  vesselIcon: {
    // transform: "scale(0.6)",
    position: "relative",
    margin: "auto",
    width: "32px",
    height: "24px",
    // zIndex: 99,
    zIndex: 999,
  },
  alarmMarker: {
    position: "relative",
    width: "34px",
    height: "34px",
    "& .fl-marker, & .fl-marker:before, & .fl-marker:after": {
      top: "-6px",
      left: "2px",
      position: "absolute",
      borderRadius: "50%",
      zIndex: 600,
    },
  },
  topArea: {
    paddingTop: "8px",
    borderRadius: "5px",
    backgroundColor: "rgba(2, 2, 2, 0.7)",
    width: "100%"
  },
});

const FleetList = (props) => {
  const fleetNavigationDispatch = useDispatch();
  const fleetNavigationDataState = useSelector(fleetNavigationData);
  const fleetEngineDataState = useSelector(fleetEngineData);
  const currentVessel = useSelector(selectedVessel);
  const fleetAlarmData = useSelector(fleetAlarmSelector);
  const fleetNavigationDataStateRef = React.useRef();
  const input = React.useRef("");
  const [filteredDict, setFilteredDict] = React.useState();
  const [invisibleCompanies, setInvisibleCompanies] = React.useState([]);
  fleetNavigationDataStateRef.current = fleetNavigationDataState;
  const sortUtil = new SortUtil();
  const [isExpanded, setIsExpanded] = React.useState(true); //set default exapnd to true
  const { connections } = useDetectVesselConnection();

  //sort the list
  const sortedList = sortUtil.vesselListSort(fleetNavigationDataState, fleetEngineDataState);
  const sortedIdList = sortedList.map(v => v.vessel_id);

  React.useEffect(() => {
    if (filteredDict) {
      const currFilteredDict = filteredDict;
      for (const key in currFilteredDict) {
        currFilteredDict[key].sort((a, b) => sortedIdList.indexOf(a.vessel_id) - sortedIdList.indexOf(b.vessel_id));
      }
      setFilteredDict(currFilteredDict);
    }
  }, [sortedIdList]);

  const companyFleetDictOriginal = sortedList.reduce(function (dict, v) {
    let vessel = {};
    Object.assign(vessel, v);
    vessel.iconVisible = true;
    vessel.listVisible = true;
    vessel.searchResult = true;
    dict[vessel.vesselManagement.company_name] = dict[vessel.vesselManagement.company_name] || [];
    dict[vessel.vesselManagement.company_name].push(vessel);
    return dict;
  }, Object.create(null));

  const getDataList = useMemo(() => {
    if (!isExpanded) {
      return;
    }
    const currList = filteredDict || companyFleetDictOriginal;

    const companyFleetDict = {};

    for (const key in currList) {
      companyFleetDict[key] = currList[key].map(function (object, i) {
        const shipType =
          object.vesselLatestAisData.length > 0 ? object.vesselLatestAisData[0].shipType : 0;
        const shipTypeText = SHIP_GROUP_TYPE[SHIP_TYPE[shipType]];
        const shipClassText = object.vessel_firstClass !== "" ? `[${object.vessel_firstClass}]` : "";
        const isSEPvessel = object?.vesselType === VESSEL_TYPE.SEP;
        const alarmHighestPriority = getAlarmHighestPriority(
          fleetAlarmData.filter((f) => f.vesselId === object.vessel_id)
        );
        const isVesselDisconnected = connections.includes(object.vessel_id);
        const isSelected = object.vessel_id === currentVessel?.vessel_id;
  
        const handleItemClick = (event) => {
          event.stopPropagation(); // Prevent the click from propagating
          if (object.iconVisible && object?.vesselLatestGpggaData?.length > 0)
            setSelected(object.vessel_id);
        };

        return (
          <tr
            key={object.vessel_id}
            className={isSelected ? props.classes.shipSelected : props.classes.dataEntry}
            onClick={handleItemClick}
            style={{
              width: "100%",
              display: object.listVisible && object.searchResult ? "" : "none",
              cursor: object.iconVisible ? "pointer" : "auto",
            }}
          >
            <td className={props.classes.fleetNameHolder}>
              <>
                <span className={clsx(props.classes.fleetName, props.classes.alarmMarker)}>
                  {!isVesselDisconnected && alarmHighestPriority?.alarmStatus === STATUS_ABNORMAL && (
                    <span
                      className="marker fl-marker"
                      style={{
                        filter:
                          alarmHighestPriority != null ? getMarkerColor(alarmHighestPriority) : "",
                      }}
                    />
                  )}
                  <img
                    className={clsx(
                      props.classes.vesselIcon,
                    isVesselDisconnected ? props.classes.disConnectedVessel : null
                  )}
                  src={VesselIcon(isSEPvessel ? 52 : shipType, 0, 1, isVesselDisconnected, false)}
                  alt="vessel icon"
                />
              </span>
              {!isVesselDisconnected && (
                <span className={props.classes.connectedFleetName}>
                  {object.vessel_name}{" "}
                  <span style={{ color: "#ffffff" }}>
                    {shipTypeText !== "" ? "- " + shipTypeText : ""} {shipClassText}
                  </span>
                </span>
              )}
              {isVesselDisconnected && (
                <span className={props.classes.disconnectedFleetName}>
                  {object.vessel_name}{" "}
                  <span style={{ color: "#9c9c9c" }}>
                    {shipTypeText !== "" ? "- " + shipTypeText : ""} {shipClassText}
                  </span>
                </span>
              )}
            </>
          </td>
        </tr>
      )})
    }

    return companyFleetDict;
  }, [companyFleetDictOriginal, fleetAlarmData, connections]);

  const companyRowClicked = (event) => {
    event.stopPropagation()
  }

  const companyNameClicked = (event, key) => {
    event.stopPropagation();
    let currDict = {};
    currDict = Object.assign(currDict, filteredDict || companyFleetDictOriginal);
    currDict[key].forEach((v) => v.listVisible = !v.listVisible);
    setFilteredDict(currDict);
  };

  const companyCheckboxClicked = (event, key) => {
    event.stopPropagation();
    const idx = invisibleCompanies.indexOf(key);
    if (idx == -1) invisibleCompanies.push(key);
    else invisibleCompanies.splice(idx, 1);
    setInvisibleCompanies(invisibleCompanies);
    props.onVisibilityChange(invisibleCompanies);
    
    let currDict = {};
    currDict = Object.assign(currDict, filteredDict || companyFleetDictOriginal);
    currDict[key].forEach((v) => {
      v.iconVisible = !v.iconVisible
      if (v.vessel_id == currentVessel?.vessel_id) fleetNavigationDispatch(setSelectedVessel(null));
    });
    setFilteredDict(currDict);
  }

  const getCompanyList = useMemo(() => {
    if (!isExpanded) {
      return;
    }
    const currDict = filteredDict || companyFleetDictOriginal;
    const companyCount = Object.keys(currDict)?.length;

    return Object.keys(currDict).sort().map(function(key, i) {
      if (getDataList[key].length == 0) return;
      return (
        <tbody key={key} style={{ width: "100%", display: "table" }} onClick={e => e.stopPropagation()}>
          <tr
            onClick={e => companyRowClicked(e)}
            style={{
              width: "100%",
              height: "44px",
              cursor: "auto",
              backgroundColor: "rgba(2, 2, 2, 0.7)",
              display: companyCount == 1 ? "none" : ""
            }}
          >
            <td>
              <div style={{ display: "flex", height: "44px" }}>
                <input 
                  type="checkbox"
                  onClick={e => companyCheckboxClicked(e, key)}
                  defaultChecked
                  style={{ accentColor: "#28b6c7", marginLeft: "20px", top: "50%" }}
                />
                <div
                  onClick={e => companyNameClicked(e, key)}
                  style={{ marginLeft: "12px", display: "inline-block", width: "72%", height: "100%", cursor: "pointer" }}
                >
                  <div 
                    style={{ fontFamily: "Inter", fontWeight: "500", position: "relative", top: "50%", transform: "translateY(-50%)" }}
                  >
                    {(key || "Unknown") + " Fleet"}
                  </div>
                </div>
                <div
                  style={{ width: "13%", cursor: "pointer" }}
                  onClick={e => companyNameClicked(e, key)}
                >
                  {currDict[key][0]?.listVisible ? 
                    <ExpandLessIcon style={{ position: "relative", top: "50%", transform: "translateY(-50%)", width: "20px" }} /> : 
                    <ExpandMoreIcon style={{ position: "relative", top: "50%", transform: "translateY(-50%)", width: "20px" }} />
                  }
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <table
                id={ "Fleetlist-" + key }
                style={{ borderCollapse: "collapse !important", borderSpacing: "0" }}
              >
                <tbody>{getDataList[key]}</tbody>
              </table>
            </td>
          </tr>
        </tbody>
      );
    })
    
  }, [companyFleetDictOriginal, filteredDict]);

  const getExpandIcon = () => {
    if (isExpanded) {
      return (
        <ExpandMoreIcon
          className={props.classes.expandIcon}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        />
      );
    } else {
      return (
        <ExpandLessIcon
          className={props.classes.expandIcon}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        />
      );
    }
  };

  const setSelected = (vessel_id) => {
    fleetNavigationDispatch(setSelectedVessel(vessel_id));
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const searchBarClicked = (event) => {
    event.stopPropagation();
  };
  const searchVessels = () => {
    let currDict = {};
    currDict = Object.assign(currDict, filteredDict || companyFleetDictOriginal);

    for (const key in currDict) {
      currDict[key].forEach((v) => {
        const shipType =
          v.vesselLatestAisData.length > 0 ? v.vesselLatestAisData[0].shipType : "";
        const shipTypeText = SHIP_GROUP_TYPE[SHIP_TYPE[shipType]];
        const vesselText = [v?.vessel_name, v?.vessel_firstClass, shipTypeText].join(" ").toLowerCase();
        v.searchResult = vesselText.includes(input.current.value.toLowerCase());
      });
    }
    setFilteredDict(currDict);
  };
  
  return (
    <div
      className={props.classes.fleetList}
      onClick={toggleExpand}
      style={{ cursor: "pointer" }} // make the cursor to a pointer
    >
      <div
        className={isExpanded ? props.classes.fleetListExpanded : props.classes.fleetListCollapsed}
        style={{
          maxHeight: props.height,
        }}
      >
        <div className={props.classes.topArea} style={{ height: isExpanded ? "" : "44px" }}>
          <div>{getExpandIcon()}</div>
          <div
            style={{ width: "100%", cursor: "auto", margin: "auto"}}
            className={isExpanded ? props.classes.searchBarExpanded : props.classes.searchBarCollapsed}
            onClick={searchBarClicked}
          >
            <div className={ props.classes.searchBarDiv }>
              <input
                id="vesselSearch"
                className={props.classes.searchBar}
                type="text"
                onKeyUp={searchVessels}
                placeholder="Search for vessel here"
                ref={input}
              >
              </input>
              <img src={SearchIcon} style={{ marginTop: "4px" }}/>
            </div>
          </div>
        </div>
        <table className={props.classes.fleetTable}>
          {getCompanyList}
        </table>
      </div>
    </div>
  );
};

FleetList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  height: PropTypes.number,
};

export default withStyles(styles)(FleetList);
