import { FILTER_FIELDS, INITIAL_STATE, INITIAL_FILTER } from "../slice/channelSlice.js";
import { splitSearchWordBySpace, isMatchWithSearchWords } from "../../helpers/searchCommon.js";

const resetFilters = (state, action) => {
  state.tempFilteredChannelData = undefined;
  state.filteredChannelData = undefined;
  state.filters = INITIAL_FILTER;
};

const reset = (state, action) => {
  return INITIAL_STATE;
};

const alarmWordCheck = (base, words, column) => {
  return base?.filter((d) => isMatchWithSearchWords(d[column], words)) ?? [];
};

const alarmFilter = (state) => {
  let filteredList = state.channelModData;
  if (state.filters.chNo) {
    filteredList = alarmWordCheck(
      filteredList,
      splitSearchWordBySpace(state.filters.chNo),
      FILTER_FIELDS.CHANNEL_NO
    );
  }
  if (state.filters.chName) {
    filteredList = alarmWordCheck(
      filteredList,
      splitSearchWordBySpace(state.filters.chName),
      FILTER_FIELDS.CHANNEL_NAME
    );
  }
  if (state.filters.chStatus) {
    filteredList = alarmWordCheck(
      filteredList,
      splitSearchWordBySpace(state.filters.chStatus),
      FILTER_FIELDS.STATUS
    );
  }
  if (state.filters.inputData) {
    filteredList = alarmWordCheck(
      filteredList,
      splitSearchWordBySpace(state.filters.inputData),
      FILTER_FIELDS.VALUE
    );
  }
  state.tempFilteredChannelData = filteredList;
};

const search = (state, action) => {
  state.isSearching = true;
  state.filters[action.payload.filter] = action.payload.text;
  alarmFilter(state);
};

const setFilterStatus = (state, action) => {
  state.alarmFilter[action.payload.filter].status = action.payload.text;
};

const setSearchButtonFlg = (state, action) => {
  state.isSearchButton = action.payload.flag;
};

const setCurrentWindowPosition = (state, action) => {
  state.currentWindowPosition = action.payload;
};

const setCurrentTablePosition = (state, action) => {
  state.currentTablePosition = action.payload;
};

const setCurrentChannelPage = (state, action) => {
  state.currentChannelPage = action.payload;
};

const setIsChannelTrendFetching = (state, action) => {
  state.isChannelTrendFetching = action.payload;
};

const searchButtonClicked = (state, action) => {
  state.filteredChannelData = state.tempFilteredChannelData;
}

export default {
  resetFilters,
  reset,
  search,
  setFilterStatus,
  setSearchButtonFlg,
  setCurrentWindowPosition,
  setCurrentTablePosition,
  setCurrentChannelPage,
  setIsChannelTrendFetching,
  searchButtonClicked,
};
