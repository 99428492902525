import { API_URL, axios } from "./axiosSettings";
import { getAuthToken } from "../helpers/session";
import { logger } from "./logger";
import { convertStreamToJson } from "../util/buffer";

export const fetchMainEngineData = async (
  position,
  vessel_id,
  startDate,
  endDate,
  interval,
  cancelToken
) => {
  try {
    const fetchUrl = `${API_URL}/engineData/vesselMainEngineData?vessel_id=${vessel_id}`
    + `&startDate=${startDate}&endDate=${endDate}&position=${position}&minuteInterval=${interval}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      cancelToken: cancelToken.token,
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return {
      vesselMainEngineData: data,
    };
  } catch (e) {
    throw { fetchMainEngineData: e };
  }
};

export const fetchDateRangeMainEngineData = async (position, vessel_id, dateRange) => {
  try {
    const { data } = await axios.post(
      `/engineData/dateRange/vesselMainEngineData`,
      {
        vessel_id,
        position,
        dateRange,
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );

    return { dateRangeMainEngineData: data };
  } catch (e) {
    throw { fetchDateRangeMainEngineData: e };
  }
};

export const fetchFOCEngineData = async (vessel_id, startDate, endDate, interval) => {
  try {
    const { data } = await axios.get(`/engineData/vesselFOCEngineData`, {
      params: {
        vessel_id,
        startDate,
        endDate,
        minuteInterval: interval,
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    logger.info({ vessel_id, startDate, endDate, interval });

    return { vesselEngineData: data };
  } catch (e) {
    throw { fetchEngineData: e };
  }
};

export const fetchEngineData = async (vessel_id, startDate, endDate, interval, cancelToken) => {
  try {
    const fetchUrl = `${API_URL}/engineData/vesselEngineData?vessel_id=${vessel_id}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      cancelToken: cancelToken.token,
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return {
      vesselEngineData: data,
    };
  } catch (e) {
    throw { fetchEngineData: e };
  }
};

export const fetchDateRangeGeneratorData = async (vessel_id, dateRange) => {
  try {
    const { data } = await axios.post(
      "/engineData/dateRange/vesselGeneratorEngineData",
      {
        vessel_id,
        dateRange,
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return { dateRangeGeneratorData: data };
  } catch (e) {
    throw { fetchDataRangeGeneratorData: e };
  }
};

export const fetchDateRangeShaftGeneratorData = async (vessel_id, dateRange) => {
  try {
    const { data } = await axios.post(
      "/engineData/dateRange/vesselShaftGeneratorEngineData",
      {
        vessel_id,
        dateRange,
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );
    return { dateRangeShaftGeneratorData: data };
  } catch (e) {
    throw { fetchDataRangeShaftGeneratorData: e };
  }
};

export const fetchDateRangeEngineData = async (vessel_id, dateRange) => {
  try {
    const { data } = await axios.post(
      `/engineData/dateRange/vesselEngineData`,
      {
        vessel_id,
        dateRange,
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      }
    );

    return { dateRangeEngineData: data };
  } catch (e) {
    throw { fetchDateRangeEngineData: e };
  }
};

export const fetchGeneratorEngineData = async ({
  vesselId,
  startDate,
  endDate,
  interval,
  generatorNumber = null, // can be null
  cancelToken,
}) => {
  try {
    logger.info({ vesselId, startDate, endDate });
    const fetchUrl = `${API_URL}/engineData/vesselGeneratorEngineData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}`
    + (generatorNumber===null ? "" : `&generatorNumber=${generatorNumber}`);
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      cancelToken: cancelToken.token,
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return {
      loadingData: data,
    };
  } catch (e) {
    logger.error(`generated error ${JSON.stringify(e)}`);
    throw { fetchGeneratorEngine: e };
  }
};

export const fetchGeneratorEngineDataWithLimit = async ({
  vesselId,
  startDate,
  endDate,
  interval,
  generatorNumber = null, //can be null
  limit,
}) => {
  try {
    const fetchUrl = `${API_URL}/engineData/vesselGeneratorEngineData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}&limit=${limit}`
    + (generatorNumber===null ? "" : `&generatorNumber=${generatorNumber}`);
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return { loadingData: data };
  } catch (e) {
    logger.error(`generated error ${JSON.stringify(e)}`);
    throw { fetchGeneratorEngine: e };
  }
};

export const fetchShaftGeneratorEngineData = async ({
  vesselId,
  startDate,
  endDate,
  interval,
  generatorNumber = null,
  cancelToken,
}) => {
  try {
    const fetchUrl = `${API_URL}/engineData/vesselShaftGeneratorEngineData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&generatorNumber=${generatorNumber}&minuteInterval=${interval}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return { loadingData: data };
  } catch (e) {
    logger.error(`generated error ${JSON.stringify(e)}`);
    throw { fetchShaftGenerator: e };
  }
};
