import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../constants/colors";
import { useLocation } from "react-router-dom";
import DownloadIcon from "../trendPage/commonComponent/downloadIcon.jsx";
import { AdditionalContainer } from "./additionalContainer.jsx";
import Screenshot from "../trendPage/commonComponent/screenshot.jsx";
import { DEVICE_TYPE, detectDevice } from "../../constants/detectDevice.js";

const tabBase = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "150px",
};

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    display: "flex",
    position: "fixed",
    top: "50px",
    width: "calc(100vw - 77px)",
    zIndex: "999",
    backgroundColor: COLORS.tabSelectorColor,
  },
  tabBase: {
    position: "relative",
    zIndex: 50,
    // top: "50px",
    height: "48px",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      height: "40px",
      borderRadius: "0 5px 5px 0",
    },
    backgroundColor: COLORS.tabSelectorColor,
    cursor: "pointer",
    display: "flex",
    "& p": {
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: "500",
      fontFamily: "Inter",
      [theme.breakpoints.down('sm')]: {
        fontSize: "8px",
      },
    },
    "user-select": "none",
    "-moz-user-select": "none",
    "-webkit-user-select": "none",
  },
  tabFree: {
    ...tabBase,
    height: "100%",
    "& p": {
      opacity: 0.7,
    },
    borderBottom: "solid 6px rgba(1,1,1,0)",
  },
  tabSelected: {
    ...tabBase,
    height: "100%",
    "& p": {
      opacity: 1.0,
      color: "#19b2ab",
    },
    borderBottom: "solid 6px #19b2ab",
  },
  utilityContainer: {
    cursor: "pointer",
    position: "relative",
    height: "48px",
    // top: "50px",
    right: "36px",
    display: "flex",
    zIndex: 60,
    alignItems: "center",
    "& div": {
      marginLeft: "12px",
    },
  },
}));

export const TabSelector = (props) => {
  const { tabs, selectedOne, clickHandler, interval, vesselId, pageRef = null, selectedChart = null } = props;
  const isTouchDevice = [DEVICE_TYPE.PHONE, DEVICE_TYPE.TABLET].includes(detectDevice);
  const classes = useStyles();
  const location = useLocation();
  const isCargo = location.pathname.includes("cargo");
  const [open, setOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    const container = document.getElementById("TabContainer");

    const onScroll = () => setIsTop(container.offsetTop <= 52);

    window.addEventListener(
      "scroll",
      onScroll,
      true
    );
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div
      id="TabContainer"
      className={classes.tabContainer}
      style={{ boxShadow: isTop ? "none" : "0px 4px 20px 0px rgba(0, 0, 0, 0.3)" }}
    >
      <div className={classes.tabBase}>
        {tabs.map((d) => (
          <div
            className={d.id === selectedOne ? classes.tabSelected : classes.tabFree}
            key={`tab-data-${d.id}`}
            onClick={() => clickHandler(d.id)}
          >
            <p>{d.name}</p>
          </div>
        ))}
      </div>
      {isCargo && (
        <>
          <div className={classes.utilityContainer}>
            {!isTouchDevice && <Screenshot element={pageRef.current} isCargo={true} />}
            <div onClick={() => setOpen(!open)}>
              <DownloadIcon color="#ffffff" opacity="1" />
            </div>
          </div>
          {open && <AdditionalContainer interval={interval} vesselId={vesselId} setOpen={setOpen} selectedTab={selectedOne} selectedChart={selectedChart}/>}
        </>
      )}
    </div>
  );
};

TabSelector.propTypes = {
  tabs: PropTypes.array,
  selectedOne: PropTypes.string || PropTypes.number,
  clickHandler: PropTypes.func,
  inline: PropTypes.bool,
  pageRef: PropTypes.any,
  interval: PropTypes.number,
  vesselId: PropTypes.string,
  selectedChart: PropTypes.string,
};
