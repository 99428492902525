export const MAX_TANK_CAPACITY = 7740;

export const CARGO_CHARTS = {
  TEMPERATURE: "temperature",
  PRESSURE: "pressure",
};

export const PRESS_TABS = {
  LOADING: "loading",
  UNLOADING: "unloading",
};
