import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Comlink from "comlink";

import { getCustomDataDuration } from "../../../model/slice/dataDurationSlice";
import { calDurations } from "../../../constants/calDuration";
import { logger } from "../../../api/logger";
import { fetchChannelDataByChartId } from "../../../api/channel";
import CustomWorker from "./custom.worker";

export const dataProcess = async (rawData, unmount, setCustomValues) => {
  const worker = CustomWorker();
  const wrap = Comlink.wrap(worker);
  const data = await wrap?.generateData({ rawData });
  unmount.current || setCustomValues({ data });
  worker.terminate();
};

export const useCustomData = ({
  vesselId = null,
  chartId = null,
  isCustomChartsCreated = false,
}) => {
  const [customValues, setCustomValues] = useState({});
  const { startDate, endDate } = useSelector(getCustomDataDuration);
  const unmount = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  //handleFetchSuccess
  useEffect(() => {
    if (isFetching) return;
    unmount.current || setIsLoading(false);
    unmount.current || setIsNoData(!customValues?.data?.length);
  }, [customValues]);

  const handleFetchError = (err) => {
    if (err.fetchChannelTrend.__CANCEL__) return;

    setIsFetching(false)

    unmount.current || setIsLoading(false);
    unmount.current || setIsNoData(true);
    logger.error(`Fetch Error: ${err}`);
  };

  const getValues = async () => {
    if (!startDate || !endDate) return;

    const interval = calDurations({ startDate, endDate });

    fetchChannelDataByChartId(vesselId, chartId, startDate, endDate, interval)
      .then((data) => {
        dataProcess(data.customData, unmount, setCustomValues);
        setIsFetching(false)
      })
      .catch(handleFetchError);
  };

  const dataLoad = async () => {
    if (!chartId || startDate === "" || vesselId === null) return;
    setIsLoading(true);
    setIsNoData(false);
    await getValues()
      .then(() => {
        if (unmount.current === true) {
          return;
        }
      })
      .catch((error) => {
        logger.error(`getValues error: ${error}`);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    setCustomValues({});
  }, [vesselId, chartId]);

  useEffect(() => {
    dataLoad();
  }, [vesselId, chartId, startDate, endDate]);

  useEffect(() => {
    if (!isCustomChartsCreated) return;
    setCustomValues({});
    dataLoad();
  }, [isCustomChartsCreated]);

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  return {
    customValues,
    isLoading,
    isNoData,
  };
};
