import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  upperTriangle: {
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: "0 8px 13.9px 8px",
    borderColor: "transparent transparent #ff2d00 transparent",
  },
  lowerTriangle: {
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: "13.9px 8px 0 8px",
    borderColor: "#9dcd4e transparent transparent transparent",
  },
});

const ChangeTriangle = (props) => {
  const { value } = props;
  const classes = useStyles();
  if (value > 0) {
    return <div className={classes.upperTriangle} />;
  } else if (value < 0) {
    return <div className={classes.lowerTriangle} />;
  } else {
    return <div className={classes.noTriangle} />;
  }
};

export default ChangeTriangle;
