import React from "react";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  base: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    padding: "32px"
  },
});

export const ComparisonRightPanel = (props) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.base}>
      {props.children}
    </Grid>
  );
};
