import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Button, IconButton, CircularProgress } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'util/dayjs-init.js';

import { setChartType } from "../../model/slice/chartTypeSlice";
import localizationKeys from "../../i18n/localizationKeys.js";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType";
import { ENGINE_CHART_TYPE } from "../../constants/trendGraph/enginesLine";
import { SEP_CHART_LIST, SEP_CHART_TYPE } from "../../constants/trendGraph/sepLines";
import { ENGINE_CHART_LIST } from "./engine/engineList";
import { fetchVessel } from "../../api/vessel";
import { engineInitialSelector } from "../../constants/trendGraph/engine";
import GraphSelector from "./graphSelector.jsx";
import CustomGraphSelector from "./customGraph/graphSelector.jsx";
import EngineTrendGraph from "./engine/index.jsx";
import Sep from "./sep/index.jsx";
import { ElectricPanel } from "./electric/index.jsx";
import Custom from "./customGraph/displayGraph.jsx";
import EngineSelector from "./engineSelector.jsx";
import Screenshot from "./commonComponent/screenshot.jsx";
import MaximizeIcon from "./icon/maximizeIcon.jsx";
import MinimiseIcon from "./icon/minimiseIcon.jsx";
import EditIcon from "./icon/editIcon.jsx";
import { CsvDownload } from "./commonComponent/csvDownload.jsx";
import DurationSelector from "./durationSelector.jsx";
import CustomGraph from "./customGraph/index.jsx";
import {
  DEFAULT_CHART_TITLE,
  DEFAULT_CHART_TITLE_JP,
  DEFAULT_CUSTOM_CHART_KEY,
} from "../../constants/trendGraph/charts";
import { fetchChannelNames } from "../../api/channel";
import { LATEST_UPDATE_FORMAT } from "../../constants/util";
import { VESSEL_TYPE } from "../../constants/constants";
import { chartsData, isChartsFetching } from "../../model/slice/chartsSlice";
import { fetchChartsAsync } from "../../model/async/chartsAsync";
import { TabSelector } from "../tabSelector/tabSelector.jsx";
import i18n from "../../i18n";
import {
  setCustomDataDuration,
  setElectricDataDuration,
  setSepDataDuration,
} from "../../model/slice/dataDurationSlice";
import { durationInitialState, sepDurationInitialState } from "../../constants/duration";
import { getComparator, stableSort } from "../channelPage/utils/helper.js";
import { COLORS } from "../../constants/colors.js";

const tabBase = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "150px",
};

const useStyles = makeStyles((theme) => ({
  tabBase: {
    height: "50px",
    [theme.breakpoints.down('sm')]: {
      height: "40px",
      borderRadius: "0 5px 5px 0",
    },
    backgroundColor: "rgba(44,58,72, 0.5)",
    cursor: "pointer",
    display: "flex",
    "& p": {
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: "500",
      fontFamily: "Inter",
      [theme.breakpoints.down('sm')]: {
        fontSize: "8px",
      },
    },
    width: "100%",
    "user-select": "none",
    "-moz-user-select": "none",
    "-webkit-user-select": "none",
  },
  tabFree: {
    ...tabBase,
    height: "100%",
    "& p": {
      opacity: 0.7,
    },
    borderBottom: "solid 6px rgba(1,1,1,0)",
  },
  tabSelected: {
    ...tabBase,
    height: "100%",
    "& p": {
      opacity: 1.0,
      color: "#19b2ab",
    },
    borderBottom: "solid 6px #19b2ab",
  },
  chartContainer: {
    color: "#fff",
    maxWidth: "1600px",
    margin: "auto",
    padding: "36px 36px 0 36px",
    "user-select": "none",
    "-moz-user-select": "none",
    "-webkit-user-select": "none",
    maxHeight: "calc(100dvh - 140px)",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  titleContainer: {
    display: "flex",
    gap: "40px",
    marginBottom: "12px",
    alignItems: "center",
    padding: "10px 0px 10px 0px",
  },
  chartTitle: {
    fontSize: "18px",
  },
  lastUpdate: {
    color: "rgba(255, 255, 255, 0.5)",
  },
  chartSubtitleLeft: {
    color: "rgba(255, 255, 255, 0.5)",
  },
  chartSubtitleRight: {
    textAlign: "right",
  },
  maximize: {
    padding: "0 12px !important",
  },
  chartController: {
    padding: "105px 0 0 20px",
  },
  circle: {
    margin: "20px 20px",
    position: "relative",
    left: "calc(50% - 20px)",
    top: "calc(50% - 20px)",
  },
}));

const commonTabs = (t) => [
  {
    name: t(localizationKeys.EngineData_lower),
    id: GRAPH_TYPE.ENGINE,
  },
  {
    name: t(localizationKeys.SepData_lower),
    id: GRAPH_TYPE.SEP,
  },
  {
    name: t(localizationKeys.ElectricData_lower),
    id: GRAPH_TYPE.ELECTRIC,
  },
  {
    name: t(localizationKeys.Custom_lower),
    id: GRAPH_TYPE.CUSTOM,
  },
];

const chartSettingList = (selectedGraph) => {
  switch (selectedGraph) {
    case GRAPH_TYPE.ENGINE:
      return ENGINE_CHART_LIST;
    case GRAPH_TYPE.SEP:
      return SEP_CHART_LIST;
  }
};

const filterTabs = (isSepVessel, NoME, NoDG, NoSG, t) =>
  commonTabs(t)
    .filter((d) =>
      isSepVessel ? d.id !== GRAPH_TYPE.ENGINE : d.id !== GRAPH_TYPE.SEP
    ).filter(t => 
      (NoME > 0 && t.id === GRAPH_TYPE.ENGINE) || 
      ((NoDG > 0 || NoSG > 0) && t.id === GRAPH_TYPE.ELECTRIC) || 
      ([GRAPH_TYPE.SEP, GRAPH_TYPE.CUSTOM].includes(t.id))
    );

const DisplayTabSelector = (props) => {
  const { vesselId, currentVessel, selectedGraph, isSepVessel, defaultChart, t } = props;
  const { NoME, NoDG, NoSG } = currentVessel || {};
  const navigate = useNavigate();

  const clickTab = (id) => {
    let chart = id === GRAPH_TYPE.ELECTRIC ? id : defaultChart(id);
    if (id==GRAPH_TYPE.CUSTOM && !chart) {
      chart = DEFAULT_CUSTOM_CHART_KEY;
    }
    navigate(`/trend/${id}/${chart}/${vesselId}`);
  };

  const filteredTab = filterTabs(isSepVessel, NoME, NoDG, NoSG, t);

  return (
    <TabSelector tabs={filteredTab} selectedOne={selectedGraph} clickHandler={clickTab} t={t} />
  );
};

const DisplayChartsList = (props) => {
  const { selectedGraph, vesselId, selectedChart, customCharts } = props;
  const navigate = useNavigate();
  const updateSelectedChart = (chart) => {
    navigate(`/trend/${selectedGraph}/${chart}/${vesselId}`);
  };

  switch (selectedGraph) {
    case GRAPH_TYPE.ELECTRIC:
      return <Grid container spacing={2} style={{ height: "50px", marginTop: "2px" }}></Grid>;
    case GRAPH_TYPE.CUSTOM:
      return (
        <CustomGraphSelector
          {...props}
          setSelectedChart={updateSelectedChart}
          chartList={customCharts}
        />
      );
    default:
      return (
        <GraphSelector
          selectedChart={selectedChart}
          chartType={selectedGraph === GRAPH_TYPE.SEP ? SEP_CHART_TYPE : ENGINE_CHART_TYPE}
          chartSettingList={chartSettingList(selectedGraph)}
          setSelectedChart={updateSelectedChart}
          vesselId={vesselId}
        />
      );
  }
};

const DisplayChart = (props) => {
  const { selectedGraph, isSepVessel } = props;

  switch (selectedGraph) {
    case GRAPH_TYPE.ELECTRIC:
      return <ElectricPanel {...props} />;
    case GRAPH_TYPE.CUSTOM:
      return <Custom {...props} />;
    default:
      return isSepVessel ? <Sep {...props} /> : <EngineTrendGraph {...props} />;
  }
};

const DisplayController = (props) => {
  const { selectedGraph, classes } = props;
  return (
    <Grid container spacing={1}>
      <Grid item md={12} sm={12}>
        <DurationSelector {...props} />
      </Grid>
      {selectedGraph === GRAPH_TYPE.ENGINE && (
        <Grid item md={12} sm={12}>
          <EngineSelector {...props} />
        </Grid>
      )}
    </Grid>
  );
};

const DisplayGraphHeader = (props) => {
  const {
    vesselId,
    selectedGraph,
    selectedChart,
    selectedChartData,
    selectedEngine,
    graphData,
    dataFetchStatus,
    currentVessel,
    isMaximize,
    setIsMaximize,
    setIsCreateCustomCharts,
    screenshotRef,
    classes,
    t,
  } = props;
  const { isLoading, isNoData } = dataFetchStatus;

  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <div style={{ boxShadow: " 4px 4px 4px 2px rgba(0, 0, 0, 0.1)", padding: "10px", marginRight: "30px" }}>
      <Grid container>
        <Grid item xs={7} className={classes.chartTitle}>
          {t(
            selectedGraph === GRAPH_TYPE.ELECTRIC
              ? localizationKeys.ElectricData_lower
              : chartSettingList(selectedGraph)?.find((e) => e.id === selectedChart)?.title
          )}
        </Grid>
        {selectedGraph === GRAPH_TYPE.CUSTOM && (
          <Grid item xs={7} className={classes.chartSubtitleLeft}>
            {selectedChartData?.otherName}
          </Grid>
        )}
        {selectedChart !== DEFAULT_CUSTOM_CHART_KEY && (
          <Grid item xs={5} className={classes.chartSubtitleRight}>
            {selectedGraph === GRAPH_TYPE.CUSTOM && (
              <IconButton
                onClick={() => setIsCreateCustomCharts(true)}
                className={classes.maximize}
                size="large">
                <EditIcon />
              </IconButton>
            )}
            <Screenshot
              isDurationDisabled={isDownloading}
              isNoData={isNoData}
              element={screenshotRef.current}
              vesselName={currentVessel?.name}
              graphType={selectedGraph}
              selectedGraph={selectedGraph === GRAPH_TYPE.CUSTOM ? selectedChartData?.name : selectedChart}
              selectedEngine={selectedEngine}
              isLoading={isLoading}
            />
            <CsvDownload
              vesselId={vesselId}
              graphData={graphData}
              graphType={selectedGraph}
              selectedChart={selectedChart}
              selectedEngines={selectedEngine}
              isDownloading={isDownloading}
              setIsDownloading={setIsDownloading}
              isDurationDisabled={isLoading}
              isNoData={isNoData}
              is1SecFetchEnabled={true}
            />
            <IconButton
              onClick={() => setIsMaximize(!isMaximize)}
              className={classes.maximize}
              size="large"
              disabled={!isMaximize && (isNoData || isLoading)}>
              {isMaximize ? (
                <MinimiseIcon
                  disabled={isNoData || isLoading}
                />
              ) : (
                <MaximizeIcon
                  disabled={isNoData || isLoading}
                />
              )}
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const DisplayVariableToggleButton = ({ toggleVariables, setToggleVariables, t }) => {
  const toggleHandler = () => setToggleVariables(!toggleVariables)
  return (
    <Button
      onClick={toggleHandler}
      sx={{
        position: "relative",
        color: "white",
        fontSize: "10px",
        alignItems: "center",
        left: "35px",
        bottom: "10px",
      }}
    >
      {toggleVariables
        ? <Grid container sx={{alignItems: "center"}}>{t(localizationKeys.HideGraphVariables)} <ExpandLessIcon /> </Grid>
        : <Grid container sx={{alignItems: "center"}}>{t(localizationKeys.ShowGraphVariables)} <ExpandMoreIcon /> </Grid>
      }
    </Button>)
};

const DisplayGraph = (props) => {
  const { selectedGraph, selectedChart, currentVessel, isMaximize, toggleVariables, setToggleVariables, classes, t } = props;

  const [selectedEngine, setSelectedEngine] = useState(() =>
    engineInitialSelector(currentVessel?.NoME)
  );
  const [graphData, setGraphData] = useState([]);
  const [dataFetchStatus, setDataFetchStatus] = useState({});
  const dispatch = useDispatch();
  const screenshotRef = useRef();

  useEffect(() => {
    setSelectedEngine(engineInitialSelector(currentVessel?.NoME));
  }, [currentVessel]);

  useEffect(() => {
    switch (selectedGraph) {
      case GRAPH_TYPE.ELECTRIC:
        dispatch(setElectricDataDuration(durationInitialState)); break;
      case GRAPH_TYPE.SEP:
        dispatch(setSepDataDuration(sepDurationInitialState)); break;
    }
  }, [selectedGraph]);

  return (
    <Grid container className={classes.chartContainer} spacing={1}>
      <Grid item sm={12} md={isMaximize ? 12 : 10} order={{sm: 2, md: 0}} ref={screenshotRef}>
        <DisplayGraphHeader
          {...props}
          screenshotRef={screenshotRef}
          graphData={graphData}
          dataFetchStatus={dataFetchStatus}
        />
        <div style={{ maxHeight: "calc(100% - 30px)" }}>
          <DisplayChart
            {...props}
            selectedEngine={selectedEngine}
            graphData={graphData}
            setGraphData={setGraphData}
            setDataFetchStatus={setDataFetchStatus}
            toggleVariables={toggleVariables}
          />
        </div>
        {dataFetchStatus && !dataFetchStatus.isNoData && !dataFetchStatus.isLoading &&
          <DisplayVariableToggleButton
            toggleVariables={toggleVariables}
            setToggleVariables={setToggleVariables}
            t={t}
          />
        }
      </Grid>
      {!isMaximize && (
        <Grid item sm={12} md={2}>
          <DisplayController
            {...props}
            isDownloading={dataFetchStatus.isLoading}
            selectedEngine={selectedEngine}
            setSelectedEngine={setSelectedEngine}
            dataFetchStatus={dataFetchStatus}
            graphData={graphData}
          />
        </Grid>
      )}
    </Grid>
  );
};

const DisplayCustomGraph = (props) => {
  const {
    vesselId,
    customCharts,
    isCreateCustomCharts,
    setIsCreateCustomCharts,
    selectedChart,
    isMaximize,
    selectedGraph,
    toggleVariables,
    setToggleVariables,
    classes,
    t,
  } = props;
  const dispatch = useDispatch();
  const [channels, setChannels] = useState([]);
  const [selectedChartData, setSelectedChartData] = useState({});

  const [graphData, setGraphData] = useState([]);
  const [dataFetchStatus, setDataFetchStatus] = useState({});
  const screenshotRef = useRef();
  const navigate = useNavigate();

  const fetchData = async () => {
    const data = (await fetchChannelNames(vesselId))?.names;
    const sortedChannels = stableSort(data, getComparator("desc", "chNo"));
    setChannels(sortedChannels ?? []);
  };

  useEffect(() => {
    if (selectedGraph === GRAPH_TYPE.CUSTOM) {
      dispatch(setCustomDataDuration(durationInitialState));
      fetchData();
    }
  }, [selectedGraph, vesselId]);

  useEffect(() => {
    setSelectedChartData(customCharts?.find((e) => e.id === selectedChart));
    if (isCreateCustomCharts && selectedChart !== DEFAULT_CUSTOM_CHART_KEY) {
      navigate(`/trend/${selectedGraph}/${selectedChart}/${vesselId}`);
    }
    if (selectedChart !== DEFAULT_CUSTOM_CHART_KEY) {
      setIsCreateCustomCharts(false);
    }
  }, [selectedChart, customCharts]);

  return (
    <Grid container className={classes.chartContainer} style={ isCreateCustomCharts ? { overflow: "auto", } : { overflow: "hidden", }}>
      {isCreateCustomCharts ? (
        <CustomGraph {...props} selectedChartData={selectedChartData} setSelectedChartData={setSelectedChartData} channels={channels} />
      ) : (
        <>
          <Grid item xs={isMaximize ? 12 : 10} ref={screenshotRef}>
            <Grid item xs={12} className={classes.titleContainer}>
              <div className={classes.chartTitle}>{selectedChartData?.name}</div>
              {selectedChartData?.updatedAt && (
                <div className={classes.lastUpdate}>
                  {t(localizationKeys.CustomLastEdited)}{" "}
                  {dayjs.utc(selectedChartData?.updatedAt).format(LATEST_UPDATE_FORMAT)} (UTC)
                </div>
              )}
            </Grid>
            <DisplayGraphHeader
              {...props}
              screenshotRef={screenshotRef}
              selectedChartData={selectedChartData}
              graphData={graphData}
              dataFetchStatus={dataFetchStatus}
              setIsCreateCustomCharts={setIsCreateCustomCharts}
            />
            <DisplayChart
              {...props}
              graphData={graphData}
              setGraphData={setGraphData}
              dataFetchStatus={dataFetchStatus}
              setDataFetchStatus={setDataFetchStatus}
              channels={channels}
            />
            {dataFetchStatus && !dataFetchStatus.isNoData && !dataFetchStatus.isLoading &&
              <DisplayVariableToggleButton
                toggleVariables={toggleVariables}
                setToggleVariables={setToggleVariables}
                t={t}
              />
            }
          </Grid>
          {!isMaximize && (
            <Grid item xs={2}>
              <DisplayController
                {...props}
                dataFetchStatus={dataFetchStatus}
                graphData={graphData}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const Trend = (props) => {
  const { vesselId, graph, chart } = useParams();
  props = {vesselId, graph, chart, ...props}; //cursed, but have to due to all children accessing vessel id this way
  const { t } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();;
  const charts = useSelector(chartsData);
  const isChartsFetch = useSelector(isChartsFetching);

  const [currentVessel, setCurrentVessel] = useState(null);
  const [selectedGraph, setSelectedGraph] = useState(graph ?? GRAPH_TYPE.ENGINE);
  const [customCharts, setCustomCharts] = useState(null);
  const [trendCharts, setTrendCharts] = useState(null);
  const [customChartTitle, setCustomChartTitle] = useState(DEFAULT_CHART_TITLE);
  const [isCustomChartsLoading, setIsCustomChartsLoading] = useState(false);
  const [isCreateCustomCharts, setIsCreateCustomCharts] = useState(false);
  const [isCustomChartsCreated, setIsCustomChartsCreated] = useState(false);
  const [isChartDeleted, setIsChartDeleted] = useState(false);
  const [isMaximize, setIsMaximize] = useState(false);
  const [isSepVessel, setIsSepVessel] = useState(graph === GRAPH_TYPE.SEP);
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [toggleVariables, setToggleVariables] = useState(false);

  const defaultChart = (value) => {
    const val = value ?? selectedGraph;
    switch (val) {
      case GRAPH_TYPE.CUSTOM:
        return customCharts?.[0]?.id;
      case GRAPH_TYPE.SEP:
        return SEP_CHART_TYPE.LEGS;
      case GRAPH_TYPE.ENGINE:
        return ENGINE_CHART_TYPE.ENGINE_LOAD;
    }
  };

  const confirmDefaultGraph = (isSEP) => {
    const filteredTab = filterTabs(isSEP, currentVessel?.NoME, currentVessel?.NoDG, currentVessel?.NoSG, t);
    const tmpGraph = filteredTab.map(f => f.id).includes(selectedGraph) ? selectedGraph : filteredTab?.[0]?.id;

    if (selectedGraph !== tmpGraph) {
      if (tmpGraph==GRAPH_TYPE.CUSTOM && !defaultChart(tmpGraph)) {
        navigate(`/trend/custom/custom/${vesselId}`, {replace: true});
      }
      else {
        navigate(`/trend/${tmpGraph}/${defaultChart(tmpGraph)}/${vesselId}`, {replace: true});
      }
    }
  };

  const [selectedChart, setSelectedChart] = useState(chart ?? defaultChart(graph));

  const updateSelectedChart = (selectedChart) => {
    setSelectedChart(selectedChart);
    dispatch(setChartType(selectedChart));
  };

  const fetchCustomCharts = async () => {
    setIsCustomChartsLoading(true);
    dispatch(fetchChartsAsync({ vesselId }));
  };

  const fetchTrendCharts = async () => {
    dispatch(fetchChartsAsync({ vesselId }));
  };

  const fetchCharts = () => {
    if (selectedGraph === GRAPH_TYPE.CUSTOM) {
      fetchCustomCharts();
    } else {
      fetchTrendCharts();
    }
  };

  useEffect(() => {
    if (!isChartsFetch) {
      if (selectedGraph === GRAPH_TYPE.CUSTOM) {
        const data = charts?.charts;
        setCustomCharts(data);
        setIsCustomChartsLoading(false);
        if (!data?.length) {
          setIsCreateCustomCharts(true);
          navigate(`/trend/${selectedGraph}/${DEFAULT_CUSTOM_CHART_KEY}/${vesselId}`);
        }
      } else {
        const data = charts?.trendCharts;
        setTrendCharts(data?.[selectedGraph]);
      }
    }
  }, [charts, isChartsFetch]);

  useEffect(() => {
    const fetchData = async () => {
      const data = (await fetchVessel(vesselId))?.vessel;
      setCurrentVessel(data);
    };
    fetchData();
  }, [vesselId]);

  useEffect(() => {
    if (!currentVessel) return;
    const isSEP = currentVessel.vesselType === VESSEL_TYPE.SEP
    setIsSepVessel(isSEP);
    confirmDefaultGraph(isSEP);
    localStorage.setItem('isSepVessel', JSON.stringify(isSEP));
  }, [currentVessel]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem('isManualRefresh', 'true');
      const isSEP = currentVessel && currentVessel.vesselType === VESSEL_TYPE.SEP;
      updateVesselType(isSEP);
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); 
  
  useEffect(() => {
    const isManualRefresh = sessionStorage.getItem('isManualRefresh') === 'true';
    sessionStorage.setItem('isManualRefresh', 'false');
  
    if (isManualRefresh) {
      const storedVesselType = localStorage.getItem('isSepVessel');
      const isSEP = JSON.parse(storedVesselType);
      const graphType = isSEP ? GRAPH_TYPE.SEP : GRAPH_TYPE.ENGINE;
  
      navigate(`/trend/${graphType}/${defaultChart(graphType)}/${vesselId}`);
    }
  }, [isSepVessel, currentVessel]);
  
  useEffect(() => {
    setSelectedGraph(
      [GRAPH_TYPE.ENGINE, GRAPH_TYPE.SEP].includes(graph)
        ? isSepVessel
          ? GRAPH_TYPE.SEP
          : GRAPH_TYPE.ENGINE
        : graph
    );
  }, [currentVessel, isSepVessel]);
  
  useEffect(() => {
    if (isCustomChartsCreated) {
      fetchCustomCharts();
    }
  }, [isCustomChartsCreated]);

  useEffect(() => {
    if (isChartDeleted) {
      fetchCustomCharts();
    }
  }, [isChartDeleted]);

  useEffect(() => {
    if (selectedGraph !== GRAPH_TYPE.CUSTOM) return;
    if (!isCustomChartsLoading) {
      if (isCustomChartsCreated) {
        setIsCustomChartsCreated(false);
        if (selectedChart === DEFAULT_CUSTOM_CHART_KEY) {
          updateSelectedChart(customCharts[customCharts.length - 1].id);
        }
        setCustomChartTitle(DEFAULT_CHART_TITLE);
      } else {
        if (customCharts?.length > 0 && !isCreateCustomCharts) {
          if (isInitialLoad) {
            setIsInitialLoad(false);
          } else {
            // selectedChart doesn't update when change the selectedVessel by vesselSelector.
            const checkValue = customCharts.some((v) => v?.id === selectedChart)
              ? selectedChart
              : "undefined";
            const newSelectedChart =
              checkValue === "undefined" || checkValue === DEFAULT_CUSTOM_CHART_KEY
                ? defaultChart()
                : checkValue;
            if (
              selectedGraph === GRAPH_TYPE.CUSTOM &&
              (checkValue === "undefined" || checkValue === DEFAULT_CUSTOM_CHART_KEY)
            ) {
              // to prevent back to /undefined/~
              navigate(`/trend/${selectedGraph}/${newSelectedChart}/${vesselId}`, {replace: true});
            }
            updateSelectedChart(newSelectedChart);
          }
        } else if (customCharts?.length > 0 && isChartDeleted) {
          updateSelectedChart(defaultChart());
          setIsChartDeleted(false);
        } else {
          if (!isCreateCustomCharts) return;
          updateSelectedChart(DEFAULT_CUSTOM_CHART_KEY);
          navigate(`/trend/${selectedGraph}/${DEFAULT_CUSTOM_CHART_KEY}/${vesselId}`);
          setIsCreateCustomCharts(true);
        }
      }
    }
  }, [customCharts, isInitialLoad]);

  useEffect(() => {
    setSelectedGraph(graph);
  }, [graph]);

  useEffect(() => {
    dispatch(setChartType(selectedChart));
    fetchCharts();
  }, [selectedChart]);

  useEffect(() => {
    setIsCreateCustomCharts(false);
    fetchCharts();
    if (selectedGraph === GRAPH_TYPE.CUSTOM) {
      setSelectedChart(defaultChart());
    }
  }, [vesselId, selectedGraph]);

  useEffect(() => {
    if (chart === "undefined" && selectedGraph === GRAPH_TYPE.CUSTOM) {
      navigate(`/trend/${selectedGraph}/${DEFAULT_CUSTOM_CHART_KEY}/${vesselId}`);
    } else {
      setSelectedChart(chart);
      if (selectedGraph === GRAPH_TYPE.CUSTOM && chart === DEFAULT_CUSTOM_CHART_KEY) {
        setIsCreateCustomCharts(true);
      }
    }
  }, [chart]);

  useEffect(() => {
    setIsMaximize(false);
  }, [vesselId, selectedGraph, selectedChart]);

  useEffect(() => {
    switch (i18n.language) {
      case "en-US":
        if (customChartTitle === DEFAULT_CHART_TITLE_JP) {
          setCustomChartTitle(DEFAULT_CHART_TITLE);
        }
        break;
      case "ja":
        if (customChartTitle === DEFAULT_CHART_TITLE) {
          setCustomChartTitle(DEFAULT_CHART_TITLE_JP);
        }
        break;
    }
  }, [i18n.language]);

  useEffect(() => {
    setIsInitialLoad(true);

    return () => {
      dispatch(setChartType(null));
    };
  }, []);

  if(currentVessel == null){
    return (
      <div style={{display: 'flex', width: '100%', height: '80vh', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress/>
      </div>
    );
  }

  return (
    <div>
      <DisplayTabSelector
        {...props}
        currentVessel={currentVessel}
        defaultChart={defaultChart}
        selectedGraph={selectedGraph}
        setSelectedGraph={setSelectedGraph}
        isSepVessel={isSepVessel}
        classes={classes}
      />
      <div style={{
        height: "50px",
        width: "100%",
        marginLeft: "33px",
        position: "fixed",
        top: "98px",
        display: "flex",
        backgroundColor: COLORS.backgroundColor,
        zIndex: 999,
      }}>
      <DisplayChartsList
        {...props}
        isSepVessel={isSepVessel}
        customCharts={customCharts}
        currentVessel={currentVessel}
        selectedGraph={selectedGraph}
        selectedChart={selectedChart}
        setSelectedChart={updateSelectedChart}
        isCreateCustomCharts={isCreateCustomCharts}
        setIsCreateCustomCharts={setIsCreateCustomCharts}
        customChartTitle={customChartTitle}
      />
      </div>
      {selectedGraph === GRAPH_TYPE.CUSTOM ? (
        <DisplayCustomGraph
          {...props}
          selectedGraph={selectedGraph}
          classes={classes}
          currentVessel={currentVessel}
          customChartTitle={customChartTitle}
          setCustomChartTitle={setCustomChartTitle}
          customCharts={customCharts}
          selectedChart={selectedChart}
          isCustomChartsCreated={isCustomChartsCreated}
          setIsCustomChartsCreated={setIsCustomChartsCreated}
          setIsChartDeleted={setIsChartDeleted}
          isCreateCustomCharts={isCreateCustomCharts}
          setIsCreateCustomCharts={setIsCreateCustomCharts}
          isMaximize={isMaximize}
          setIsMaximize={setIsMaximize}
          toggleVariables={toggleVariables}
          setToggleVariables={setToggleVariables}
        />
      ) : (
        <DisplayGraph
          {...props}
          selectedGraph={selectedGraph}
          selectedChart={selectedChart}
          isSepVessel={isSepVessel}
          currentVessel={currentVessel}
          classes={classes}
          isMaximize={isMaximize}
          setIsMaximize={setIsMaximize}
          trendCharts={trendCharts}
          toggleVariables={toggleVariables}
          setToggleVariables={setToggleVariables}
        />
      )}
    </div>
  );
};

Trend.propTypes = {
  t: PropTypes.func,
  graph: PropTypes.string,
  chart: PropTypes.string,
  vesselId: PropTypes.string,
  layoutStyle: PropTypes.object,
};

export default withTranslation()(Trend);
