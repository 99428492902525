import dayjs from 'util/dayjs-init.js';

const FETCH_DURATION_BASE = 720;

export const calDurations = ({ startDate, endDate }) => {
  const duration = Math.abs(dayjs.utc(startDate).diff(dayjs.utc(endDate), "minutes"));
  if(duration <= 60) {
    return 0;
  } else if(duration <= 1440) {
    return 1;
  } else {
    return Math.ceil(duration / FETCH_DURATION_BASE);
  } 
};
