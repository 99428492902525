import { createSlice } from "@reduxjs/toolkit";

import {
  fetchChartsAsync,
  fulfilledFetchChartsAsync,
  pendingFetchChartsAsync,
  // rejectedFetchChartsAsync,
} from "../async/chartsAsync";
import Actions from "../action/chartsAction.js";

const chartsSlice = createSlice({
  name: "chartsController",
  initialState: {
    charts: null,
    chartsSettings: null,
    isChartsFetching: true,
  },
  reducers: {
    ...Actions,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChartsAsync.fulfilled, fulfilledFetchChartsAsync);
    builder.addCase(fetchChartsAsync.pending, pendingFetchChartsAsync);
    // builder.addCase(fetchChartsAsync.rejected, rejectedFetchChartsAsync);
  },
});

//Selectors
export const chartsData = (state) => state.chartsController.charts;
export const chartsSettings = (state) => state.chartsController.chartsSettings;
export const isChartsFetching = (state) => state.chartsController.isChartsFetching;

export const { setChartsSettings } = chartsSlice.actions;

export default chartsSlice.reducer;
