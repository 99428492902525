import { COLORS } from "../colors";

export const CARGO_STYLES = {
  paper: {
    backgroundColor: "rgba(36, 44, 55) !important",
    borderRadius: "5px",
    filter: "drop-shadow(0 10px 20px rgba(0,0,0,0.3))",
    width: "100%",
    height: "100%",
    color: COLORS.white,
  },
  paperTitle: {},
};
