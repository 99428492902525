import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../../../constants/colors";

const useStyles = makeStyles({
  base: {
    height: "10px",
    backgroundColor: "#059000",
    borderRadius: "10px",
    position: "relative",
  },
  edgeCircle: {
    width: "15px",
    height: "15px",
    position: "absolute",
    top: "-3px",
    borderRadius: "50%",
  },
  thumb: {
    position: "absolute",
    height: "10px",
    borderRadius: "20px",
  },
  label: {
    width: "25px",
    height: "25px",
    position: "absolute",
    top: "18px",
    textAlign: "center",
  },
});

export const ThresholdSlider = (props) => {
  const { highThresholdList, lowThresholdList, min, max } = props;
  const classes = useStyles();

  const fitMinMax = (value) => {
    if (value < min) {
      return min;
    }
    if (value > max) {
      return max;
    }
    return value;
  };

  return (
    <div className={classes.base}>
      {highThresholdList.map((value, index) => {
        const useValue = fitMinMax(value);
        const width = ((Math.abs(max) - useValue) / (Math.abs(max) + Math.abs(min))) * 100;
        return (
          <div key={`low-thresholdSlider-${index}`}>
            <div
              className={classes.edgeCircle}
              style={{
                right: `calc(${width}% - 7.5px)`,
                background: COLORS.THRESHOLD_COLOR_LIST[index],
              }}
            />
            <div
              className={classes.thumb}
              style={{
                right: "0px",
                width: `${width}%`,
                background: COLORS.THRESHOLD_COLOR_LIST[index],
              }}
            />
            <div
              className={classes.label}
              style={{
                right: `calc(${width}% - 10px)`,
                border: `solid 2px ${COLORS.THRESHOLD_COLOR_LIST[index]}`,
              }}
            >
              {`H${index + 1}`}
            </div>
          </div>
        );
      })}
      {lowThresholdList.map((value, index) => {
        const useValue = fitMinMax(value);
        const width = ((useValue - min) / (Math.abs(max) + Math.abs(min))) * 100;
        return (
          <div key={`high-thresholdSlider-${index}`}>
            <div
              className={classes.edgeCircle}
              style={{
                left: `calc(${width}% - 7.5px)`,
                background: COLORS.THRESHOLD_COLOR_LIST[index],
              }}
            />
            <div
              className={classes.thumb}
              key={index}
              style={{
                left: "0px",
                width: `${width}%`,
                background: COLORS.THRESHOLD_COLOR_LIST[index],
              }}
            />
            <div
              className={classes.label}
              style={{
                left: `calc(${width}% - 10px)`,
                border: `solid 2px ${COLORS.THRESHOLD_COLOR_LIST[index]}`,
              }}
            >
              {`L${index + 1}`}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ThresholdSlider.propTypes = {
  highThresholdList: PropTypes.array,
  lowThresholdList: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
};
