import React, { useCallback, useRef } from "react";
import { withTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys.js";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from "react-redux";
import { useInputText } from "./useInputText.js";

import SearchField from "./searchField.jsx";
import {
  tempFilteredChannelData,
  FILTER_FIELDS,
  resetFilters,
  channelModData,
} from "../../../model/slice/channelSlice.js";

const useStyles = makeStyles((theme) => ({
  baseContainer: {
    borderRadius: "8px",
    display: "flex",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px !important",
      marginRight: "0px !important",
    },
  },
  filterGrid: {
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px !important",
      marginRight: "0px !important",
    },
  },
}));

const AlarmSearch = React.memo(({ t, i18n, resetFilter }) => {
  const classes = useStyles();
  const channelData = useSelector(tempFilteredChannelData);
  const channelLatestData = useSelector(channelModData);
  const dispatch = useDispatch();
  const chNoField = useInputText(FILTER_FIELDS.CHANNEL_NO);
  const chNameField = useInputText(FILTER_FIELDS.CHANNEL_NAME);
  const valueField = useInputText(FILTER_FIELDS.VALUE);
  const statusField = useInputText(FILTER_FIELDS.STATUS);
  const chNameFieldRef = useRef();
  const chNoFieldRef = useRef();
  const valueFieldRef = useRef();
  const statusFieldRef = useRef();

  const resetFilterFunction = () => {
    chNoField.resetSearchText();
    chNameField.resetSearchText();
    valueField.resetSearchText();
    statusField.resetSearchText();

    chNameFieldRef.current();
    chNoFieldRef.current();
    valueFieldRef.current();
    statusFieldRef.current();

    dispatch(resetFilters());
  };

  resetFilter.current = resetFilterFunction;

  const autocompleteOptions = useCallback(
    (col) => {
      if (!channelData) {
        const allValue =
          channelLatestData
            ?.map((d) => d[col])
            ?.filter((d) => ![null, undefined, "", "-"].includes(d)) ?? [];
        return Array.from(new Set(allValue));
      } else {
        const allValue =
          channelData?.map((d) => d[col])?.filter((d) => ![null, undefined, "", "-"].includes(d)) ??
          [];
        return Array.from(new Set(allValue));
      }
    },
    [channelData, channelLatestData]
  );

  return (
    <Grid container alignItems="center">
      <Grid
        container
        item
        xs={12}
        className={classes.baseContainer}
        alignItems="center"
        spacing={2}
      >
        <Grid container item xs={12} sm={6} className={classes.filterGrid} spacing={2}>
          <Grid item xs={12}>
            <SearchField
              label={t(localizationKeys.ChannelName)}
              field={"chName"}
              text={chNameField.currentText}
              setText={chNameField.setCurrentText}
              autocompleteOptions={autocompleteOptions}
              resetFunc={chNameFieldRef}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchField
              label={t(localizationKeys.Value_lower)}
              field={"inputData"}
              text={valueField.currentText}
              setText={valueField.setCurrentText}
              autocompleteOptions={autocompleteOptions}
              resetFunc={valueFieldRef}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} className={classes.filterGrid} spacing={2}>
          <Grid item xs={12}>
            <SearchField
              label={t(localizationKeys.ChannelNumber)}
              field={"chNo"}
              text={chNoField.currentText}
              setText={chNoField.setCurrentText}
              autocompleteOptions={autocompleteOptions}
              resetFunc={chNoFieldRef}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchField
              label={t(localizationKeys.Status_lower)}
              field={"chStatus"}
              text={statusField.currentText}
              setText={statusField.setCurrentText}
              autocompleteOptions={autocompleteOptions}
              resetFunc={statusFieldRef}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

AlarmSearch.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  resetFilter: PropTypes.object,
};

export default withTranslation()(AlarmSearch);
