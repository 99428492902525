import { useEffect, useRef, useState } from "react";
import { fetchLatestGeneratorData, fetchLatestShaftGeneratorData } from "../../../api/electricData";
import useInterval from "../../../hooks/useInterval";
import { cleanGeneratorData } from "../../../constants/trendGraph/electric";
import { logger } from "../../../api/logger";
import { isPassedBaseTime } from "../../../constants/util";

export const useElectricLatestData = (vesselId, noDG, noSG) => {
  const [ge, setGe] = useState([]);
  const [sg, setSg] = useState([]);
  const [total, setTotal] = useState(0);
  const unmount = useRef(false);
  const [isNoData, setIsNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoElectric, setIsNoElectric] = useState(false);

  useEffect(() => {
    setIsNoElectric(false);
  }, [vesselId]);

  const fetchLatestData = async () => {
    setIsLoading(true);
    const data = await Promise.all([
      fetchLatestGeneratorData(vesselId),
      fetchLatestShaftGeneratorData(vesselId),
    ])
      .then((d) => {
        setIsLoading(false);
        if (!d[0]?.length) {
          setIsNoElectric(true);
        } else {
          d[0] = cleanGeneratorData(vesselId, d[0], noDG);
          d[1] = cleanGeneratorData(vesselId, d[1], noSG);

          const hasLatestData = [...d[0], ...d[1]].some(d => !isPassedBaseTime(d.dateTime));
          setIsNoData(!hasLatestData);
        }
        return d;
      })
      .catch((e) => {
        logger.error(`fetchLatestData error: ${e}`);
        unmount.current || setIsLoading(false);
        unmount.current || setIsNoData(true);
      });

    logger.info(`fetchLatestData data: ${JSON.stringify(data)}`);
    if (!(unmount.current || !data?.length)) {
      setGe(data[0]);
      setSg(data[1]);
    }
  };

  useEffect(() => {
    setTotal(() => {
      return [...ge, ...sg].reduce((acc, cur) => {
        cur?.gePower && (() => (acc += cur?.gePower))();
        cur?.sgPower && (() => (acc += cur?.sgPower))();
        return acc;
      }, 0);
    });
  }, [ge, sg]);

  useEffect(() => {
    fetchLatestData();
  }, [vesselId, noDG, noSG]);

  useInterval(fetchLatestData, 60000, false);

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);

  return {
    ge,
    sg,
    total,
    isNoData,
    isLoading,
    isNoElectric,
  };
};
