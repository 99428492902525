import { API_URL } from "./axiosSettings";
import { getAuthHeader } from "../helpers/session";
import { convertStreamToJson } from "../util/buffer";

export const fetchVesselGpggaData = async (vesselId, startDate, endDate, interval) => {
  try {
    const fetchUrl = `${API_URL}/v1/navigationData/vesselGpggaData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
      responseType: "stream",
    });
    const responseBody = await response.text();
    const separated = responseBody.split("\n");
    let data = [];
    separated.forEach((v) => {
      if (v.length > 0) data.push(JSON.parse(v));
    });
    return { gpggaData: data };
  } catch (e) {
    throw { apiGetVesselGpggaData: e };
  }
};

export const fetchVesselGpvtgData = async (vesselId, startDate, endDate, interval) => {
  try {
    const fetchUrl = `${API_URL}/v1/navigationData/vesselGpvtgData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
      responseType: "stream",
    });
    const responseBody = await response.text();
    const separated = responseBody.split("\n");
    let data = [];
    separated.forEach((v) => {
      if (v.length > 0) data.push.apply(data, JSON.parse(v));
    });
    return { gpvtgData: data };
  } catch (e) {
    throw { apiGetVesselGpvtgData: e };
  }
};

export const fetchVesselWimwvrData = async (vesselId, startDate, endDate, interval) => {
  try {
    const fetchUrl = `${API_URL}/v1/navigationData/vesselWimwvrData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}`;   
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
      responseType: "stream",
    });
    const responseBody = await response.text();
    const separated = responseBody.split("\n");
    let data = [];
    separated.forEach((v) => {
      if (v.length > 0) data.push.apply(data, JSON.parse(v));
    });
    return {wimwvrData: data};
  } catch (e) {
    throw { apiGetVesselWimwvrData: e};
  }
};

export const fetchVesselWimwvtData = async (vesselId, startDate, endDate, interval) => {
  try {
    const fetchUrl = `${API_URL}/v1/navigationData/vesselWimwvtData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
      responseType: "stream",
    });
    const responseBody = await response.text();
    const separated = responseBody.split("\n");
    let data = [];
    separated.forEach((v) => {
      if (v.length > 0) data.push.apply(data, JSON.parse(v));
    });
    return {wimwvtData: data};
  } catch (e) {
    throw { apiGetVesselWimwvtData: e};
  }
};

export const fetchVesselNavDataForCargo = async (vesselId, startDate, endDate, interval) => {
  try {
    const fetchUrl = `${API_URL}/v1/navigationData/vesselNavigationData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}&navTypes=GPGGA,WIMWVR,WIMWVT,GPVTG`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
      responseType: "stream",
    });
    const responseBody = await response.text();
    const separated = responseBody.split("\n");
    let data = [];
    separated.forEach((v) => {
      if (v.length > 0) data.push.apply(data, JSON.parse(v));
    });
    return {data: data};
  } catch (e) {
    throw { apiGetVesselAllNav: e};
  }
};