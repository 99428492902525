import { Box, Button, MenuItem, Select } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys";
import { DownloadIcon } from "./icons/downloadIcon";
import { SyntheticaStyles } from "./styles";
import dayjs from "util/dayjs-init.js";

const styles = {
    tableHeader: {
        color: "#e8e8e8",
        fontSize: "16px",
        borderTop: "1px solid #808080",
        borderBottom: "1px solid #808080",
    },
    tableCell: {
        color: "#e8e8e8",
        fontSize: "16px",
        border: "0",
    }
}

const Constants = {
    SortingRecent: "RECENT",
    SortingOldest: "OLDEST",
}
// Need to add pagination
export const VoyageHistory = ({ data }) => {
    const { t } = useTranslation();
    const [sorting, setSorting] = useState(Constants.SortingRecent);

    const handleDropdownChange = (event) => {
        setSorting(event.target.value);
    }

    const filterNullVoyages = (voyage) => voyage.source_port != null && voyage.destination_port != null;
    const getSortedVoyages = () => {
        if (sorting === Constants.SortingOldest)
            return data.voyageHistory.voyages.sort((a, b) => {
                return new Date(a.destination_datetime) - new Date(b.destination_datetime);
            }).filter(filterNullVoyages);
        else
            return data.voyageHistory.voyages.sort((a, b) => {
                return new Date(b.destination_datetime) - new Date(a.destination_datetime);
            }).filter(filterNullVoyages);
    };

    const handleDownload = () => {
        const headers = ['Source Time', 'Source', 'Destination Time', 'Destination', 'Current CII', 'CII Diff', 'Speed'].join(',');
        const rows = getSortedVoyages().map((v) => [v.source_datetime, v.source_port, v.destination_datetime, v.destination_port, v.current_cii, v.cii_diff, v.speed].join(','));
        const joinedRows = rows.join('\n');
        const out = headers + '\n' + joinedRows;

        const blob = new Blob([out]);

        const downloadLink = document.createElement("a");
        downloadLink.download = "CII" + ".csv";
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.dataset.downloadurl = ["text/csv", downloadLink.download, downloadLink.href].join(
            ":"
        );

        downloadLink.click();

        downloadLink.remove();
    };

    return (
        <>
            <Box sx={SyntheticaStyles.paperHeader}>
                <Box>{t(localizationKeys.VoyageHistory)}</Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Select
                    value={sorting}
                    onChange={handleDropdownChange}
                    sx={{ ...SyntheticaStyles.select, marginRight: '8px' }}
                    disableUnderline
                    variant="standard"
                >
                    <MenuItem value={Constants.SortingRecent}>RECENT</MenuItem>
                    <MenuItem value={Constants.SortingOldest}>OLDEST</MenuItem>
                </Select>
                <Box>
                    <Button sx={{ width: "32px", minWidth: "32px" }} onClick={handleDownload}>
                        <DownloadIcon></DownloadIcon>
                    </Button>
                </Box>
            </Box>
            <Box sx={{ overflowY: "scroll", width: "100%", maxHeight: 440 }}>
                <TableContainer component="div" sx={{ maxHeight: 440 }} >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ borderTop: "1px" }}>
                                <TableCell sx={styles.tableHeader}>{t(localizationKeys.Departed)}</TableCell>
                                <TableCell sx={styles.tableHeader} align="right">{t(localizationKeys.DepartureTimeUTC)}</TableCell>
                                <TableCell sx={styles.tableHeader} align="right">{t(localizationKeys.ArrivalTimeUTC)}</TableCell>
                                <TableCell sx={styles.tableHeader} align="right">{t(localizationKeys.Destination)}</TableCell>
                                <TableCell sx={styles.tableHeader} align="right">{t(localizationKeys.SpeedKn)}</TableCell>
                                <TableCell sx={styles.tableHeader} align="right">{t(localizationKeys.CII)}</TableCell>
                                <TableCell sx={styles.tableHeader} align="right">{t(localizationKeys.CIIDiff)}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getSortedVoyages().map((row) => (
                                <TableRow
                                    key={row.source_datetime}
                                    sx={{ height: 32 }}
                                >
                                    <TableCell sx={styles.tableCell} component="th" scope="row">{row.source_port}</TableCell>
                                    <TableCell sx={styles.tableCell} align="right">{dayjs.utc(row.source_datetime).format("YYYY/MM/DD - hh:mm")}</TableCell>
                                    <TableCell sx={styles.tableCell} align="right">{dayjs.utc(row.destination_datetime).format("YYYY/MM/DD - hh:mm")}</TableCell>
                                    <TableCell sx={styles.tableCell} align="right">{row.destination_port}</TableCell>
                                    <TableCell sx={styles.tableCell} align="right">{Number(row.speed).toFixed(2)}</TableCell>
                                    <TableCell sx={styles.tableCell} align="right">{Number(row.current_cii).toFixed(2)}</TableCell>
                                    <TableCell sx={styles.tableCell} align="right">{Number(row.cii_diff).toFixed(2) + "%"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
}
