import { axios } from "./axiosSettings";
import { getAuthToken } from "../helpers/session.js";
import { logger } from "./logger";

export const loginOid = async (oid) => {
  try {
    const token = await axios.post(
      "/api/auth/login/oid",
      {
        oid,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return { isLoginSuccess: true, token: token.data };
  } catch (e) {
    logger.error(e);
    return { isLoginSuccess: false };
  }
};

export const loginValidateToken = async () => {
  const cookieToken = getAuthToken();

  try {
    const { data } = await axios.post(
      "/api/auth/login/validateToken",
      {
        token: cookieToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return { isValid: true, userData: data };
  } catch (e) {
    return { isValid: false };
  }
};
