import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useDetectVesselConnection } from "../../../hooks/useDetectVesselConnection";
import { Grid } from "@mui/material";
import { useSepTrendData } from "../../../hooks/useSepTrendData";
import { useDurationHandle } from "../calendarDuration/useDurationHandle";
import { DURATION } from "../calendarDuration/durationOption";
import EngineTrendBase from "../engine/engineTrendBase.jsx";
import { SEP_CHART_TYPE } from "../../../constants/trendGraph/sepLines";
import * as PropTypes from "prop-types";
import { GRAPH_TYPE } from "../../../constants/trendGraph/trendGraphType";

const Sep = (props) => {
  const {
    vesselId,
    currentVessel,
    selectedChart,
    graphData,
    setGraphData,
    setDataFetchStatus,
    trendCharts,
    layoutStyle,
    toggleVariables,
    isVesselOverview,
    setChartRef,
  } = props;

  const { connections } = useDetectVesselConnection();
  const isDisconnected = connections.includes(vesselId);

  const { sepValues, isLoading, isNoData } = useSepTrendData(vesselId);

  useEffect(() => {
    setDataFetchStatus({
      isLoading,
      isNoData,
    });
  }, [isLoading, isNoData]);

  useDurationHandle(DURATION.SEP, null);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EngineTrendBase
            layoutStyle={layoutStyle}
            values={sepValues}
            isDurationDisabled={isLoading}
            isNoData={isNoData}
            isLoading={isLoading}
            isDisconnected={isDisconnected}
            vesselId={vesselId}
            vessel={currentVessel}
            chartList={SEP_CHART_TYPE}
            graphType={GRAPH_TYPE.SEP}
            selectedChart={selectedChart}
            graphData={graphData}
            setGraphData={setGraphData}
            customLineData={trendCharts?.[selectedChart]}
            toggleVariables={toggleVariables}
            isVesselOverview={isVesselOverview}
            setChartRef={setChartRef}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Sep.propTypes = {
  vesselId: PropTypes.string,
  selectedChart: PropTypes.string,
  isVesselOverview: PropTypes.bool,
  setChartRef: PropTypes.func,
};

export default withTranslation()(Sep);
