import React, { Fragment, memo, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { Controls } from "./control.jsx";

import LCO2SensorMap from "../../../../assets/images/cargo/mimic/LCO2SensorMap.svg";
import { CARGO_STYLES } from "../../../constants/cargo/styles";
import {
  MIMIC_DEFAULT_SCALE,
  MIMIC_MAX_SCALE,
  MIMIC_MIN_SCALE,
} from "../../../constants/cargo/mimic.js";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    ...CARGO_STYLES.paper,
    paddingBottom: "20px",
  },
  titleContainer: {
    padding: "10px 24px",
    height: "43px",
    borderBottom: "solid 1.3px #222831",
    display: "flex",
    alignItems: "center",
  },
  contentContainer: {
    height: "calc(100% - 24px)"
  },
  image: {
    height: "100%",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  zoom: {
    border: "solid 1px green",
  },
}));

export const Mimic = memo((props) => {
  const { vesselId } = props;
  const { t } = useTranslation();
  const styles = useStyles();
  const [zoomScale, setZoomScale] = useState({});

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={styles.titleContainer}>
        <div>{t(localizationKeys.CargoMimic)}</div>
      </Grid>
      <Grid container className={styles.contentContainer}>
        <TransformWrapper
          initialScale={MIMIC_DEFAULT_SCALE}
          minScale={MIMIC_MIN_SCALE}
          maxScale={MIMIC_MAX_SCALE}
          onTransformed={(ref, scale) => setZoomScale(scale)}
        >
          {({ zoomIn, zoomOut }) => (
            <Fragment>
              <Grid item xs={11} className={styles.image}>
                <TransformComponent contentStyle={{width: "100%", height: "100%"}} wrapperStyle={{width: "100%", height: "100%"}}>
                  {vesselId !== '8fbc25fd-663d-4aed-9109-0a35e02733a9' &&
                  <img src={LCO2SensorMap} alt="Sensor Map" />
                  }
                </TransformComponent>
              </Grid>
              <Grid item xs={1}>
                <Controls zoomIn={zoomIn} zoomOut={zoomOut} state={zoomScale} />
              </Grid>
            </Fragment>
          )}
        </TransformWrapper>
      </Grid>
    </Grid>
  );
});

Mimic.propTypes = {
  vesselId: PropTypes.string,
};
