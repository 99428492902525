export const client_id = process.env.VISWA_CLIENT_ID;
export const client_secret = process.env.VISWA_CLIENT_SECRET;

export const mockData = [
  {
    ModeData: [
      {
        Mode: 0,
        DayData: [
          {
            UtcDt: "2022-11-05 00:00:00",
            LD: 582.0,
            CO2: 5.4469,
            NOX: 78.7989,
            SOX: 4.4853,
            TFC: 1.699,
          },
          {
            UtcDt: "2022-11-06 00:00:00",
            LD: 480.0,
            CO2: 4.4948,
            NOX: 65.0241,
            SOX: 3.7012,
            TFC: 1.402,
          },
          {
            UtcDt: "2022-11-07 00:00:00",
            LD: 546.0,
            CO2: 4.5717,
            NOX: 66.1373,
            SOX: 3.7646,
            TFC: 1.426,
          },
          {
            UtcDt: "2022-11-08 00:00:00",
            LD: 516.0,
            CO2: 4.607,
            NOX: 66.6474,
            SOX: 3.7936,
            TFC: 1.437,
          },
          {
            UtcDt: "2022-11-09 00:00:00",
            LD: 630.0,
            CO2: 5.992,
            NOX: 86.6834,
            SOX: 4.9341,
            TFC: 1.869,
          },
          {
            UtcDt: "2022-11-10 00:00:00",
            LD: 546.0,
            CO2: 5.0173,
            NOX: 72.584,
            SOX: 4.1316,
            TFC: 1.565,
          },
          {
            UtcDt: "2022-11-11 00:00:00",
            LD: 540.0,
            CO2: 4.5877,
            NOX: 66.3692,
            SOX: 3.7778,
            TFC: 1.431,
          },
          {
            UtcDt: "2022-11-12 00:00:00",
            LD: 486.0,
            CO2: 4.4146,
            NOX: 63.8647,
            SOX: 3.6352,
            TFC: 1.377,
          },
          {
            UtcDt: "2022-11-13 00:00:00",
            LD: 582.0,
            CO2: 5.354,
            NOX: 77.4539,
            SOX: 4.4088,
            TFC: 1.67,
          },
          {
            UtcDt: "2022-11-14 00:00:00",
            LD: 510.0,
            CO2: 4.966,
            NOX: 71.842,
            SOX: 4.0893,
            TFC: 1.549,
          },
          {
            UtcDt: "2022-11-15 00:00:00",
            LD: 618.0,
            CO2: 5.697,
            NOX: 82.4165,
            SOX: 4.6912,
            TFC: 1.777,
          },
          {
            UtcDt: "2022-11-16 00:00:00",
            LD: 534.0,
            CO2: 4.5653,
            NOX: 66.0445,
            SOX: 3.7593,
            TFC: 1.424,
          },
          {
            UtcDt: "2022-11-17 00:00:00",
            LD: 588.0,
            CO2: 5.1616,
            NOX: 74.6711,
            SOX: 4.2504,
            TFC: 1.61,
          },
          {
            UtcDt: "2022-11-18 00:00:00",
            LD: 528.0,
            CO2: 4.7929,
            NOX: 69.3375,
            SOX: 3.9468,
            TFC: 1.495,
          },
          {
            UtcDt: "2022-11-19 00:00:00",
            LD: 510.0,
            CO2: 4.8282,
            NOX: 69.8476,
            SOX: 3.9758,
            TFC: 1.506,
          },
          {
            UtcDt: "2022-11-20 00:00:00",
            LD: 534.0,
            CO2: 4.5364,
            NOX: 65.6271,
            SOX: 3.7356,
            TFC: 1.415,
          },
          {
            UtcDt: "2022-11-21 00:00:00",
            LD: 534.0,
            CO2: 4.5108,
            NOX: 65.256,
            SOX: 3.7144,
            TFC: 1.407,
          },
          {
            UtcDt: "2022-11-22 00:00:00",
            LD: 504.0,
            CO2: 4.6551,
            NOX: 67.3431,
            SOX: 3.8332,
            TFC: 1.452,
          },
          {
            UtcDt: "2022-11-23 00:00:00",
            LD: 456.0,
            CO2: 4.6935,
            NOX: 67.8997,
            SOX: 3.8649,
            TFC: 1.464,
          },
          {
            UtcDt: "2022-11-24 00:00:00",
            LD: 492.0,
            CO2: 4.6615,
            NOX: 67.4359,
            SOX: 3.8385,
            TFC: 1.454,
          },
          {
            UtcDt: "2022-11-25 00:00:00",
            LD: 552.0,
            CO2: 4.8346,
            NOX: 69.9404,
            SOX: 3.9811,
            TFC: 1.508,
          },
          {
            UtcDt: "2022-11-26 00:00:00",
            LD: 474.0,
            CO2: 4.7673,
            NOX: 68.9664,
            SOX: 3.9256,
            TFC: 1.487,
          },
          {
            UtcDt: "2022-11-27 00:00:00",
            LD: 594.0,
            CO2: 5.4021,
            NOX: 78.1496,
            SOX: 4.4484,
            TFC: 1.685,
          },
          {
            UtcDt: "2022-11-28 00:00:00",
            LD: 546.0,
            CO2: 4.9308,
            NOX: 71.3318,
            SOX: 4.0603,
            TFC: 1.538,
          },
          {
            UtcDt: "2022-11-29 00:00:00",
            LD: 600.0,
            CO2: 5.0173,
            NOX: 72.584,
            SOX: 4.1316,
            TFC: 1.565,
          },
          {
            UtcDt: "2022-11-30 00:00:00",
            LD: 636.0,
            CO2: 5.4822,
            NOX: 79.3091,
            SOX: 4.5144,
            TFC: 1.71,
          },
        ],
      },
      {
        Mode: 2,
        DayData: [
          {
            UtcDt: "2022-11-05 00:00:00",
            LD: 720.0,
            CO2: 94.1762,
            NOX: 1362.4007,
            SOX: 77.55,
            TFC: 29.375,
          },
          {
            UtcDt: "2022-11-06 00:00:00",
            LD: 738.0,
            CO2: 78.8996,
            NOX: 1141.4019,
            SOX: 64.9704,
            TFC: 24.61,
          },
          {
            UtcDt: "2022-11-07 00:00:00",
            LD: 732.0,
            CO2: 87.1358,
            NOX: 1260.5511,
            SOX: 71.7525,
            TFC: 27.179,
          },
          {
            UtcDt: "2022-11-08 00:00:00",
            LD: 738.0,
            CO2: 82.0094,
            NOX: 1186.3901,
            SOX: 67.5312,
            TFC: 25.58,
          },
          {
            UtcDt: "2022-11-09 00:00:00",
            LD: 720.0,
            CO2: 81.4516,
            NOX: 1178.3201,
            SOX: 67.0718,
            TFC: 25.406,
          },
          {
            UtcDt: "2022-11-10 00:00:00",
            LD: 726.0,
            CO2: 87.3859,
            NOX: 1264.1687,
            SOX: 71.9584,
            TFC: 27.257,
          },
          {
            UtcDt: "2022-11-11 00:00:00",
            LD: 738.0,
            CO2: 82.7276,
            NOX: 1196.7791,
            SOX: 68.1225,
            TFC: 25.804,
          },
          {
            UtcDt: "2022-11-12 00:00:00",
            LD: 732.0,
            CO2: 83.5675,
            NOX: 1208.9306,
            SOX: 68.8142,
            TFC: 26.066,
          },
          {
            UtcDt: "2022-11-13 00:00:00",
            LD: 726.0,
            CO2: 81.7786,
            NOX: 1183.0508,
            SOX: 67.3411,
            TFC: 25.508,
          },
          {
            UtcDt: "2022-11-14 00:00:00",
            LD: 732.0,
            CO2: 90.8548,
            NOX: 1314.3514,
            SOX: 74.8149,
            TFC: 28.339,
          },
          {
            UtcDt: "2022-11-15 00:00:00",
            LD: 720.0,
            CO2: 82.5673,
            NOX: 1194.4602,
            SOX: 67.9905,
            TFC: 25.754,
          },
          {
            UtcDt: "2022-11-16 00:00:00",
            LD: 738.0,
            CO2: 92.5604,
            NOX: 1339.0254,
            SOX: 76.2194,
            TFC: 28.871,
          },
          {
            UtcDt: "2022-11-17 00:00:00",
            LD: 726.0,
            CO2: 93.9838,
            NOX: 1359.6179,
            SOX: 77.3916,
            TFC: 29.315,
          },
          {
            UtcDt: "2022-11-18 00:00:00",
            LD: 738.0,
            CO2: 87.4404,
            NOX: 1264.9572,
            SOX: 72.0033,
            TFC: 27.274,
          },
          {
            UtcDt: "2022-11-19 00:00:00",
            LD: 732.0,
            CO2: 100.7806,
            NOX: 1457.9427,
            SOX: 82.9884,
            TFC: 31.435,
          },
          {
            UtcDt: "2022-11-20 00:00:00",
            LD: 732.0,
            CO2: 80.089,
            NOX: 1158.6087,
            SOX: 65.9498,
            TFC: 24.981,
          },
          {
            UtcDt: "2022-11-21 00:00:00",
            LD: 738.0,
            CO2: 89.7519,
            NOX: 1298.3969,
            SOX: 73.9068,
            TFC: 27.995,
          },
          {
            UtcDt: "2022-11-22 00:00:00",
            LD: 732.0,
            CO2: 79.4863,
            NOX: 1149.8894,
            SOX: 65.4535,
            TFC: 24.793,
          },
          {
            UtcDt: "2022-11-23 00:00:00",
            LD: 720.0,
            CO2: 84.8435,
            NOX: 1227.3897,
            SOX: 69.8649,
            TFC: 26.464,
          },
          {
            UtcDt: "2022-11-24 00:00:00",
            LD: 732.0,
            CO2: 78.6784,
            NOX: 1138.2017,
            SOX: 64.7882,
            TFC: 24.541,
          },
          {
            UtcDt: "2022-11-25 00:00:00",
            LD: 732.0,
            CO2: 85.7829,
            NOX: 1240.9789,
            SOX: 70.6384,
            TFC: 26.757,
          },
          {
            UtcDt: "2022-11-26 00:00:00",
            LD: 732.0,
            CO2: 89.675,
            NOX: 1297.2837,
            SOX: 73.8434,
            TFC: 27.971,
          },
          {
            UtcDt: "2022-11-27 00:00:00",
            LD: 726.0,
            CO2: 80.9386,
            NOX: 1170.8993,
            SOX: 66.6494,
            TFC: 25.246,
          },
          {
            UtcDt: "2022-11-28 00:00:00",
            LD: 738.0,
            CO2: 92.2943,
            NOX: 1335.1759,
            SOX: 76.0003,
            TFC: 28.788,
          },
          {
            UtcDt: "2022-11-29 00:00:00",
            LD: 738.0,
            CO2: 89.3351,
            NOX: 1292.3675,
            SOX: 73.5636,
            TFC: 27.865,
          },
          {
            UtcDt: "2022-11-30 00:00:00",
            LD: 732.0,
            CO2: 94.1153,
            NOX: 1361.5195,
            SOX: 77.4998,
            TFC: 29.356,
          },
        ],
      },
    ],
    Id: 169,
    ImoNumber: "9726906",
    Name: "IZUMI",
  },
];
