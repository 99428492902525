import React from "react";
import * as PropTypes from "prop-types";

const DeleteIcon = ({ width = 25, height = 25, color = "#FF5252" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.464 7.143c.296 0 .536-.24.536-.536V5.536A.536.536 0 0 0 19.464 5h-4.018l-.314-.626c-.107-.215-.367-.395-.615-.436l-.105-.01h-3.83c-.274 0-.593.2-.714.446L9.554 5H5.536A.536.536 0 0 0 5 5.536v1.071c0 .296.24.536.536.536h13.928zm-2.143 13.929c.888 0 1.608-.72 1.608-1.608V8.214H6.07v11.25c0 .888.72 1.608 1.608 1.608h9.642zm-1.607-2.143a.536.536 0 0 1-.535-.536v-7.5a.536.536 0 0 1 1.071 0v7.5c0 .296-.24.536-.536.536zm-3.214 0a.536.536 0 0 1-.536-.536v-7.5a.536.536 0 0 1 1.072 0v7.5c0 .296-.24.536-.536.536zm-3.214 0a.536.536 0 0 1-.536-.536v-7.5a.536.536 0 0 1 1.071 0v7.5c0 .296-.24.536-.535.536z"
        fill={color}
      />
    </svg>
  );
};

DeleteIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default DeleteIcon;
