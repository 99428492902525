import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { COLORS } from "../../../../constants/colors";

const BarChartContainer = ({
  isNoElectric,
  isNoData,
  isLoading,
  limit,
  sizeRatio = 1,
  isInitialLoading,
  electricData,
  title,
}) => {
  const filteredData = electricData?.filter((e) => e.name === "load");
  const chartid = `barChartdiv-${title}`;
  const ElectricBarChart = ({ displayData, sizeRatio }) => {
    am5.addLicense(process.env.AMCHARTS_LICENSE);
    useEffect(() => {
      const root = am5.Root.new(chartid, {
        useSafeResolution: false,
      });
      const barChart = root.container.children.push(am5xy.XYChart.new(root, {}));
      barChart.plotContainer.get("background").setAll({
        stroke: am5.color(COLORS.white),
      });
      const xAxis = barChart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          categoryField: "name",
          forceHidden: true,
        })
      );
      xAxis.data.setAll(displayData);
      const yAxis = barChart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          max: limit,
          strictMinMax: true,
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );
      const renderY = yAxis.get("renderer");
      renderY.grid.template.set("forceHidden", true);
      renderY.labels.template.set("forceHidden", true);
      createRange(0, yAxis, "0");
      createRange(limit / 2, yAxis, `${limit / 2}`);
      createRange(limit, yAxis, `${limit}`);
      const series = barChart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Series",
          xAxis,
          yAxis,
          valueYField: "value",
          categoryXField: "name",
          fill: COLORS.lightBlue,
        })
      );
      series.data.setAll(displayData);
      barChart.zoomOutButton.set("forceHidden", true);

      return () => {
        root.dispose();
      };
    }, [filteredData]);

    const createRange = (value, axis, label) => {
      const rangeDataItem = axis.makeDataItem({
        value,
      });

      const range = axis.createAxisRange(rangeDataItem);

      range.get("label").setAll({
        forceHidden: false,
        fill: am5.color(COLORS.white),
        text: label,
        fontSize: 10,
        fontFamily: "sans-serif",
      });
    };

    return (
      <div
        id={chartid}
        style={{
          width: `${120 * sizeRatio * (1 + (1 - sizeRatio))}px`,
          height: `${233 * sizeRatio}px`,
        }}
      />
    );
  };

  const Display = () => {
    switch (true) {
      case isInitialLoading:
        return <CircularProgress />;
      case isNoElectric:
        return (
          <ElectricBarChart displayData={[{ name: "load", value: 0 }]} sizeRatio={sizeRatio} />
        );
      case isNoData:
        return (
          <ElectricBarChart displayData={[{ name: "load", value: 0 }]} sizeRatio={sizeRatio} />
        );
      case filteredData?.length > 0:
        return <ElectricBarChart displayData={filteredData} sizeRatio={sizeRatio} />;
      case isLoading:
        return <CircularProgress />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Display />
    </React.Fragment>
  );
};

export default BarChartContainer;
