import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../constants/colors.js";
import localizationKeys from "../../i18n/localizationKeys.js";
import { colorFromRank, SyntheticaStyles, DeltaIndicator, SynthConstants, rankFromData } from "./styles.jsx";

export const CIIStatus = ({ data }) => {
    const { t } = useTranslation();

    const currentRank = data.vesselStatus.rating_value.toUpperCase();
    const projectedRank = rankFromData(data.vesselStatus.projected_cii, data.ciiOverTime.limits);

    const currentRatingColor = colorFromRank(data.vesselStatus.rating_value);
    const projectedRatingColor = colorFromRank(projectedRank);

    const ciiDiffPercent = (data.vesselStatus.cii_diff / data.vesselStatus.current_cii)*100;
    let ciiDeltaState = SynthConstants.DELTA_MED;
    if(ciiDiffPercent < -2)
        ciiDeltaState = SynthConstants.DELTA_GOOD;
    else if (ciiDiffPercent > 2)
        ciiDeltaState = SynthConstants.DELTA_BAD;

    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ ...SyntheticaStyles.paperHeader, textAlign: "left" }}>
                {t(localizationKeys.DailyCII)}
            </Box>
            <Grid container xs={12} padding={2} sx={{ flexGrow: "1", textAlign: "left" }}>
                <Grid xs={6}>
                    <div style={{display: 'flex'}}>
                        <div style={{ fontSize: "16px", fontWeight: "500", color: COLORS.white70, marginBottom: "4px", marginRight: "10px"}}>{t(localizationKeys.CurrentCII)}</div>
                        <DeltaIndicator delta={ciiDiffPercent} deltaState={ciiDeltaState} ></DeltaIndicator>
                    </div>
                    <div style={{ fontSize: "24px", color: currentRatingColor }}>{data.vesselStatus.current_cii}<span style={{fontSize: "16px", marginLeft: "12px"}}>{`(${currentRank})`}</span></div>
                </Grid>
                <Grid xs={6}>
                    <div style={{ fontSize: "12px", color: COLORS.white70, marginBottom: "4px", marginTop: "4px" }}>{t(localizationKeys.RequiredCII)}</div>
                    <div style={{ fontSize: "14px", color: COLORS.white }}>{data.vesselStatus.required_cii}</div>
                </Grid>
                <Grid xs={6}>
                    <div style={{ fontSize: "16px", fontWeight: "500", color: COLORS.white70, marginBottom: "4px" }}>{t(localizationKeys.PredictedEOYCII)}</div>
                    <div style={{ fontSize: "24px", color: colorFromRank(projectedRank) }}>{data.vesselStatus.projected_cii}<span style={{fontSize: "16px", marginLeft: "12px"}}>{`(${projectedRank.toUpperCase()})`}</span></div>
                </Grid>
                <Grid xs={6}>
                    <div style={{ fontSize: "12px", color: COLORS.white70, marginBottom: "4px", marginTop: "4px" }}>{t(localizationKeys.PredictedIntervalCII)}</div>
                    <div style={{ fontSize: "14px", color: COLORS.white }}>{data.vesselStatus.ci_lower}-{data.vesselStatus.ci_upper}</div>
                </Grid>
            </Grid>
        </Box>
    );
}
