const setSelectedComparisonTableChart = (state, action) => {
  state.selectedChart = state.selectedChart
    .filter((d) => d?.number !== action.payload.number)
    .push(action.payload);
};

const hideLines = (state, action) => {
  state.graph[action.payload.index].hideLists = [
    ...new Set(state.graph[action.payload.index].hideLists.concat([action.payload.list])),
  ];
};

const showLines = (state, action) => {
  state.graph[action.payload.index].hideLists = state.graph[action.payload.index].hideLists.filter(
    (d) => d !== action.payload.list
  );
};

const showAllLines = (state, action) => {
  state.graph[action.payload.index].hideLists = [];
};

const setLineList = (state, action) => {
  state.graph[action.payload.index].chartList = action.payload.list;
};

const setEnableVisibilityIcon = (state, action) => {
  state.graph[action.payload.index].chartList.filter(
    (value) => value.keyName === action.payload.keyName
  )[0].enableVisibilityIcon = action.payload.value;
};

const setEnable = (state, action) => {
  state.graph[action.payload.index].chartList.filter(
    (value) => value.keyName === action.payload.keyName
  )[0].enable = action.payload.value;
};

export default {
  hideLines,
  showLines,
  showAllLines,
  setLineList,
  setSelectedComparisonTableChart,
  setEnableVisibilityIcon,
  setEnable,
};
