import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchFleetAlarmData,
  fetchVesselAlarmData,
  fetchVesselAlarmHistoryData,
  fetchVesselAlarmOperationData,
} from "../../api/alarm.js";

export const fetchAlarmDataAsync = createAsyncThunk(
  "api/alarm/fleetdata",
  async ({ vesselIds }) => {
    const { fleetAlarmData } = await fetchFleetAlarmData(vesselIds);
    return fleetAlarmData;
  }
);

export const fetchCurrentAlarm = createAsyncThunk("api/alarm/data", async ({ vesselId }) => {
  const { alarmData } = await fetchVesselAlarmData(vesselId);
  return alarmData;
});

export const fetchAlarmHistory = createAsyncThunk("api/alarmhistory/data", async ({ vesselId }) => {
  const { alarmHistoryData } = await fetchVesselAlarmHistoryData(vesselId);
  return alarmHistoryData;
});

export const fetchAlarmOperations = createAsyncThunk(
  "api/alarmoperation/data",
  async ({ vesselId }) => {
    const { alarmOperationData } = await fetchVesselAlarmOperationData(vesselId);
    return alarmOperationData;
  }
);

export const fulfilledFetchAlarmData = (state, action) => {
  state.fleetAlarmData = action.payload;
  state.isFleetAlarmLoading = false;
};

export const fulfilledFetchCurrentAlarm = (state, action) => {
  const alarm = action.payload?.map((p) => ({
    ...p,
    channelNo: p.channelNo.padStart(4, "0"),
  }));
  state.currentAlarm = alarm;
  state.isCurrentAlarmLoading = false;

  const groupAlarmListByCategory = (alarmList) => {
    const categoryGroup = {};
    alarmList.forEach((e) => {
      if (e.category in categoryGroup) {
        categoryGroup[e.category].push(e);
      } else {
        categoryGroup[e.category] = [];
        categoryGroup[e.category].push(e);
      }
    });
    return categoryGroup;
  };
  // set alarm category when current alarm have been loaded
  state.alarmListByCategory = groupAlarmListByCategory(alarm);
};

export const rejectedFetchCurrentAlarm = (state, action) => {
  state.isCurrentAlarmLoading = false;
};

export const pendingFetchCurrentAlarm = (state, action) => {
  state.isCurrentAlarmLoading = true;
};

export const fulfilledFetchHistoryAlarm = (state, action) => {
  state.alarmHistory = action.payload?.map((p) => ({
    ...p,
    channelNo: p.channelNo.padStart(4, "0"),
  }));
  state.isHistoryAlarmLoading = false;
};

export const rejectedFetchHistoryALarm = (state, action) => {
  state.isHistoryAlarmLoading = false;
};

export const pendingFetchAlarmHistory = (state, action) => {
  state.isHistoryAlarmLoading = true;
};

export const fulfilledFetchAlarmOperations = (state, action) => {
  state.alarmOperations = action.payload?.map((p) => ({
    ...p,
    channelNo: p.channelNo.padStart(4, "0"),
  }));
  state.isAlarmOperationLoading = false;
};

export const rejectedFetchAlarmOperations = (state, action) => {
  state.isAlarmOperationLoading = false;
};

export const pendingFetchAlarmOperations = (state, action) => {
  state.isAlarmOperationLoading = true;
};
