export const DETAILS_TAGS = [
  { tag: "O9" },
  { tag: "O10" },
  { tag: "T29" },
  { tag: "T26" },
  { tag: "T31" },
  { tag: "P47" },
  { tag: "T32" },
  { tag: "T33" },
  { tag: "T34" },
  { tag: "T35" },
  { tag: "T51" },
  //for calculation
  { tag: "P1" },
  { tag: "P2" },
  { tag: "P5" },
  { tag: "P6" },
  { tag: "P8" },
];
