import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import GaugeIcon from "../../../../../assets/images/cargo/load/gauge.svg";
import ArrowIcon from "../../../../../assets/images/cargo/load/arrow.svg";
import { COLORS } from "../../../../constants/colors";
import { checkValueRangeForChart } from "../commonFunc";
import localizationKeys from "../../../../i18n/localizationKeys";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    color: COLORS.white,
  },
  gauge: {
    position: "relative",
    textAlign: "right",
    height: "48px",
    marginBottom: "1px",
  },
  minMaxLabel: {
    position: "absolute",
    right: "50px",
    fontSize: "10px",
  },
  value: {
    position: "absolute",
    right: "50px",
    top: "50%",
    transform: "translate(15px, -50%)",
    textAlign: "center",
  },
  arrow: {
    position: "absolute",
    right: "0px",
    width: "97px",
    height: "97px",
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
  },
}));

export const Chart = memo((props) => {
  const { pitch } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const range = 5;
  const checkedValue = checkValueRangeForChart(-pitch, range);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <div
          className={classes.arrow}
          style={{
            transform: `rotate(${89 - 15 * checkedValue}deg)`,
          }}
        >
          <img src={ArrowIcon} width={25} height={25} />
        </div>
        <div className={classes.value}>
          {isNaN(pitch) ? (
            <div style={{ fontSize: "14px" }}>{t(localizationKeys.NoLatestDataForChart)}</div>
          ) : (
            <>
              <div style={{ fontSize: "16px" }}>{(pitch > 0 ? "+" : "") + pitch.toFixed(2)}</div>
              <div style={{ fontSize: "10px" }}>DEG</div>
            </>
          )}
        </div>
        <div style={{ position: "relative", opacity: isNaN(pitch) ? 0.5 : 1 }}>
          <div className={classes.minMaxLabel}>{`-${range}°`}</div>
          <div
            className={classes.gauge}
            style={{
              opacity: pitch < 0 ? 1 : 0.4,
            }}
          >
            <img src={GaugeIcon} width={38} height={48} />
          </div>
          <div
            className={classes.gauge}
            style={{
              opacity: pitch > 0 ? 1 : 0.4,
            }}
          >
            <img
              src={GaugeIcon}
              width={38}
              height={48}
              style={{
                transform: "scaleY(-1)",
              }}
            />
          </div>
          <div className={classes.minMaxLabel} style={{ marginTop: "-15px" }}>
            {`+${range}°`}
          </div>
        </div>
      </Grid>
    </Grid>
  );
});

Chart.propTypes = {
  pitch: PropTypes.number,
};
