import { API_URL } from "./axiosSettings";
import { getAuthHeader } from "../helpers/session";

export const fetchVesselVoyageStatus = async (vessel_id, duration = 3) => {
  try {
    const fetchUrl = `${API_URL}/v1/navigationData/vesselVoyageStatus?vessel_id=${vessel_id}&park_threshold=${duration}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
    });
    
    return { voyageStatus: await response.json() };
  } catch (e) {
    throw { apiGetVesselVoyageStatus: e };
  }
};