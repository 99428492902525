import { API_URL } from "./axiosSettings";
import { getAuthHeader } from "../helpers/session.js";
import { convertStreamToJson } from "../util/buffer";

export const fetchVesselHistoricalEngineData = async (vesselId, startDate, endDate) => {
  try {
    const fetchUrl = `${API_URL}/engineData/vesselEngineData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=30`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return { historicalEngineData: data };
  } catch (e) {
    throw { fetchVesselHistoricalEngineData: e };
  }
};

export const fetchVesselHistoricalMainEngineData = async (vesselId, startDate, endDate) => {
  try {
    const fetchUrl = `${API_URL}/engineData/vesselMainEngineData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=30`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);
    return { historicalMainEngineData: data };
  } catch (e) {
    throw { fetchVesselHistoricalMainEngineData: e };
  }
};

export const fetchVesselHistoricalGpvtgData = async (vesselId, startDate, endDate, interval=30) => {
  try {
    const fetchUrl = `${API_URL}/v1/navigationData/vesselGpvtgData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
      responseType: "stream",
    });
    const data = await convertStreamToJson(response.body);

    return { historicalGpvtgData: data };
  } catch (e) {
    throw { fetchVesselHistoricalGpvtgData: e };
  }
};

export const fetchVesselHistoricalGpggaData = async (vesselId, startDate, endDate, geomType = 'Point', interval = 10) => {
  try {
    const fetchUrl = `${API_URL}/v1/navigationData/vesselGpggaData?vessel_id=${vesselId}`
    + `&startDate=${startDate}&endDate=${endDate}&minuteInterval=${interval}&geomType=${geomType}`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: getAuthHeader(),
      responseType: "stream",
    });
    const responseBody = await response.text();
    const separated = responseBody.split("\n");
    let data = [];
    separated.forEach((v) => {
      if (v.length > 0) data.push(JSON.parse(v));
    });
    return { historicalGpggaData: data };
  } catch (e) {
    throw { fetchVesselHistoricalGpggaData: e };
  }
};
