import { fetchLatestGeneratorData, fetchLatestShaftGeneratorData } from "../../api/electricData";
import { LATEST_DATA_LOAD_STATUS } from "../../constants/trendGraph/dataLoad";

export const useElectricLatestDataOnce = async (vesselId = null) => {
  const fetchLatestData = async () => {
    const data = await Promise.all([
      fetchLatestGeneratorData(vesselId),
      fetchLatestShaftGeneratorData(vesselId),
    ]);
    return data;
  };

  const data = await fetchLatestData();

  return {
    ge: data[0],
    sg: data[1],
    geDataStatus:
      data[0]?.length || data[1]?.length
        ? LATEST_DATA_LOAD_STATUS.SUCCESS
        : LATEST_DATA_LOAD_STATUS.NODATA,
  };
};
