import { createSlice } from "@reduxjs/toolkit";

//Action imports
import {
  fetchFleetLatestEngineAsync,
  fetchFleetLatestEngineFulfilled,
  fetchFleetLatestEngineAsyncByVesselId,
  fetchFleetLatestEngineByVesselIdFulfilled,
} from "../async/fleetEngineDataAsync";

const fleetEngineDataSlice = createSlice({
  name: "fleetEngineData",
  initialState: {
    fleetLatestEngineData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFleetLatestEngineAsync.fulfilled, fetchFleetLatestEngineFulfilled);
    builder.addCase(
      fetchFleetLatestEngineAsyncByVesselId.fulfilled,
      fetchFleetLatestEngineByVesselIdFulfilled
    );
  },
});

//Selectors
export const fleetEngineData = (state) =>
  state.fleetEngineData?.fleetLatestEngineData?.fleetLatestEngineData;
export const latestEngineData = (vesselId) => (state) =>
  state.fleetEngineData?.fleetLatestEngineData?.fleetLatestEngineData?.find(
    (vessel) => vessel.vessel_id === vesselId
  );

export default fleetEngineDataSlice.reducer;
