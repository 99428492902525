import React from "react";
import localizationKeys from "../../i18n/localizationKeys";
import makeStyles from '@mui/styles/makeStyles';
import * as PropTypes from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    display: "flex",
    width: "400px",
    [theme.breakpoints.down('sm')]: {
      width: "280px",
    },
    height: "72px",
    // background: "#21262f",
    justifyContent: "center",
    alignItems: "center",
  },
  nodata: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fff",
  },
}));

export const NoData = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.rootGrid}>
      <p className={classes.line}>
        <span className={classes.nodata}>{t(localizationKeys.NoData_lower)}</span>
      </p>
    </div>
  );
};

NoData.propTypes = {
  t: PropTypes.func,
};
