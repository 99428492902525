import { createSlice } from "@reduxjs/toolkit";
import Actions from "../action/dateTimeShorthandAction";

const dateTimeShorthandSlice = createSlice({
  name: "dateTimeShorthandController",
  initialState: {
    settings: {
      short: 1,
      long: 10,
    },
  },
  reducers: {
    ...Actions,
  },
});

export const getShorthandSettings = (state) => state.dateTimeShorthandController.settings;

export const { setNewShorthand } = dateTimeShorthandSlice.actions;

export default dateTimeShorthandSlice.reducer;
