import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { ModalContent } from "./modalContent.jsx";

const ChannelOptions = (props) => {
  const { isOpen, onRequestClose, vesselId, channelNo } = props;

  return (
    <Modal
      open={isOpen}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <ModalContent onRequestClose={onRequestClose} vesselId={vesselId} channelNo={channelNo} />
    </Modal>
  );
};

ChannelOptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  vesselId: PropTypes.string,
  channelNo: PropTypes.string,
};

export default ChannelOptions;
