import React from "react";
import * as PropTypes from "prop-types";

const CalenderIconSetColor = ({ color = "rgba(255, 255, 255, 0.3)", width = 14, height = 14 }) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g fill={color} fillRule="evenodd">
        <path
          d="M12.195 1.094h-1.258V.437a.438.438 0 0 0-.874 0v.657H3.936V.437a.438.438 0 0 0-.874 0v.657H1.804C.81 1.094 0 1.904 0 2.898v9.297C0 13.19.81 14 1.805 14h10.39C13.19 14 14 13.19 14 12.195V2.898c0-.995-.81-1.804-1.805-1.804zm.93 11.101a.93.93 0 0 1-.93.93H1.805a.93.93 0 0 1-.93-.93V4.95c0-.075.061-.136.137-.136h11.976c.076 0 .137.06.137.136v7.246z"
          fillRule="nonzero"
        />
        <path d="M7 8h4v4H7z" />
      </g>
    </svg>
  );
};

CalenderIconSetColor.propTypes = {
  disabled: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CalenderIconSetColor;
