import { createSlice } from "@reduxjs/toolkit";
import Actions from "../action/addonAction";

const addonSlice = createSlice({
  name: "addonSlice",
  initialState: {
    showEmission: { showFlag: false, checkFlag: false },
    emissionAddonData: { emissionAddonId: "", emissionEnabledVesselIds: [] },
    showSynthetica: {showFlag: false, checkFlag: false},
    syntheticaAddonData: { syntheticaAddonId: "", syntheticaEnabledVesselIds: []},
  },
  reducers: {
    ...Actions,
  },
});

export const getEmissionShownStatus = (state) => state.addonController?.showEmission;
export const getEmissionEnableVesselId = (state) => state.addonController.emissionAddonData.emissionEnabledVesselIds;
export const getSyntheticaShownStatus = (state) => state.addonController?.showSynthetica;
export const getSyntheticaEnabledVesselIds = (state) => state.addonController?.syntheticaAddonData.syntheticaEnabledVesselIds;

export const { setShowEmission, setEmissionAddonData, setShowSynthetica, setSyntheticaAddonData } = addonSlice.actions;

export default addonSlice.reducer;
