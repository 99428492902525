//Setters
import { initialOption } from "../slice/dataDurationSlice.js";

const setDataDuration = (state, action) => {
  state.duration = action.payload;
};

const setStartDate = (state, action) => {
  state.duration.startDate = action.payload;
};

const setEndDate = (state, action) => {
  state.duration.endDate = action.payload;
};

const setElectricDataDuration = (state, action) => {
  state.electricDataDuration = action.payload;
};

const setElectricStartDate = (state, action) => {
  state.electricDataDuration.startDate = action.payload;
};

const setElectricEndDate = (state, action) => {
  state.electricDataDuration.endDate = action.payload;
};

const setDurationOption = (state, action) => {
  if (action.payload.value === undefined) return state;
  state.previousOption[action.payload.option] = state.durationOption[action.payload.option];
  state.durationOption[action.payload.option] = {
    value: action.payload.value,
    vesselId: action.payload.vesselId,
  };
};

const resetDurationOption = (state, action) => {
  state.previousOption = initialOption;
  state.durationOption = initialOption;
};

const cancelDurationOption = (state, action) => {
  state.durationOption[action.payload.option] = state.previousOption[action.payload.option];
};

const setPreviousOptionLabel = (state, action) => {
  state.previousOption[action.payload.option].label = action.payload.label;
};

const setDurationOptionLabel = (state, action) => {
  state.previousOption[action.payload.option].label = action.payload.label;
};

const setSepDataDuration = (state, action) => {
  state.sepDataDuration = action.payload;
};

const setSepStartDate = (state, action) => {
  state.sepDataDuration.startDate = action.payload;
};

const setSepEndDate = (state, action) => {
  state.sepDataDuration.endDate = action.payload;
};

const setComparisonDataDuration = (state, action) => {
  state.comparisonDataDuration = action.payload;
};

const setComparisonDataStartDate = (state, action) => {
  state.comparisonDataDuration.startDate = action.payload;
};

const setComparisonDataEndDate = (state, action) => {
  state.comparisonDataDuration.endDate = action.payload;
};

const setCustomDataDuration = (state, action) => {
  state.customDataDuration = action.payload;
};

const setCustomDataStartDate = (state, action) => {
  state.customDataDuration.startDate = action.payload;
};

const setCustomDataEndDate = (state, action) => {
  state.customDataDuration.endDate = action.payload;
};

const setCargoDataStartDate = (state, action) => {
  state.cargoDataDuration.startDate = action.payload;
};

const setCargoDataEndDate = (state, action) => {
  state.cargoDataDuration.endDate = action.payload;
};

const setCargoDataDuration = (state, action) => {
  state.cargoDataDuration = {startDate: action.payload.startDate, endDate: action.payload.endDate, interval: action.payload.interval}
}

export default {
  setDataDuration,
  setStartDate,
  setEndDate,
  setElectricDataDuration,
  setElectricStartDate,
  setElectricEndDate,
  setDurationOption,
  resetDurationOption,
  cancelDurationOption,
  setDurationOptionLabel,
  setPreviousOptionLabel,
  setSepDataDuration,
  setSepStartDate,
  setSepEndDate,
  setComparisonDataDuration,
  setComparisonDataStartDate,
  setComparisonDataEndDate,
  setCustomDataDuration,
  setCustomDataStartDate,
  setCustomDataEndDate,
  setCargoDataStartDate,
  setCargoDataEndDate,
  setCargoDataDuration,
};
