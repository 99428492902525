import { axios } from "./axiosSettings";
import { getAuthHeader, getAuthToken } from "../helpers/session.js";

export const fetchVesselLatestEngineData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/latestData/vesselEngineData`, {
      params: {
        vessel_id: vesselId,
      },
      headers: getAuthHeader(),
    });

    return { latestEngineData: data };
  } catch (e) {
    throw { fetchVesselLatestEngineData: e };
  }
};

export const fetchVesselLatestMainEngineData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/latestData/vesselMainEngineData`, {
      params: {
        vessel_id: vesselId,
      },
      headers: getAuthHeader(),
    });

    return { latestMainEngineData: data };
  } catch (e) {
    throw { fetchVesselLatestMainEngineData: e };
  }
};

export const fetchVesselLatestGeneratorEngineData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/latestData/vesselGeneratorEngineData`, {
      params: {
        vessel_id: vesselId,
      },
      headers: getAuthHeader(),
    });

    return { latestGeneratorEngineData: data };
  } catch (e) {
    throw { fetchVesselLatestGeneratorEngineData: e };
  }
};

export const fetchVesselLatestShaftGeneratorEngineData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/latestData/vesselShaftGeneratorEngineData`, {
      params: {
        vessel_id: vesselId,
      },
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return { latestShaftGeneratorEngineData: data };
  } catch (e) {
    throw { fetchVesselLatestShaftGeneratorEngineData: e };
  }
};

export const fetchVesselLatestGpggaData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/latestData/vesselLatestNavigationData`, {
      params: {
        vessel_id: vesselId,
        navigationType: 'GPGGA'
      },
      headers: getAuthHeader(),
    });

    return { latestGpggaData: data };
  } catch (e) {
    throw { fetchVesselLatestGpggaData: e };
  }
};

export const fetchVesselLatestGpvtgData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/latestData/vesselLatestNavigationData`, {
      params: {
        vessel_id: vesselId,
        navigationType: 'GPVTG'
      },
      headers: getAuthHeader(),
    });

    return { latestGpvtgData: data };
  } catch (e) {
    throw { fetchVesselLatestGpvtgData: e };
  }
};

export const fetchVesselLatestHehdtData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/latestData/vesselLatestNavigationData`, {
      params: {
        vessel_id: vesselId,
        navigationType: 'HEHDT'
      },
      headers: getAuthHeader(),
    });

    return { latestHehdtData: data };
  } catch (e) {
    throw { fetchVesselLatestHehdtData: e };
  }
};

export const fetchVesselLatestAisData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/latestData/vesselLatestNavigationData`, {
      params: {
        vessel_id: vesselId,
        navigationType: 'AIS'
      },
      headers: getAuthHeader(),
    });

    return { latestAisData: data };
  } catch (e) {
    throw { fetchVesselLatestAisData: e };
  }
};

export const fetchVesselLatestWimwvrData = async (vesselId) => {
  try {
    const { data } = await axios.get(`/latestData/vesselLatestNavigationData`, {
      params: {
        vessel_id: vesselId,
        navigationType: 'WIMWVR'
      },
      headers: getAuthHeader(),
    });

    return { latestWimwvrData: data };
  } catch (e) {
    throw { fetchVesselLatestWimwvrData: e };
  }
};

export const fetchVesselLatestWimwvtData = async (vesselId) => {
  try {
    const { data } = await axios.get(`latestData/vesselLatestNavigationData`, {
      params: {
        vessel_id: vesselId,
        navigationType: 'WIMWVT'
      },
      headers: getAuthHeader(),
    });

    return { latestWimwvtData: data };
  } catch (e) {
    throw { fetchVesselLatestWimwvtData: e };
  }
};

export const fetchVesselLatestNavigationData = async (vesselId) => {
  try {
    const { data } = await axios.get(`latestData/vesselLatestNavigationData`, {
      params: {
        vessel_id: vesselId,
      },
      headers: getAuthHeader(),
    });

    return { latestNavigationData: data };
  } catch (e) {
    throw { fetchVesselLatestNavigationData: e };
  }
};
