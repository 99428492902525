import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Typography,
  Input,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { COLORS } from "../../../../constants/colors";
import { EndAdornmentContent } from "./endAdornmentContent";
import { CustomBlueButton } from "../customButton";
import localizationKeys from "../../../../i18n/localizationKeys";
import { useTranslation } from "react-i18next";
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles({
  label: {
    width: "25px",
    height: "25px",
  },
  selectArea: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    textAlign: "center",
  },
  inputHolder: {
    width: "300px",
    margin: "0 auto",
  },
  input: {
    color: COLORS.white,
    background: COLORS.baseColor,
    textAlign: "right",
    marginBottom: "5px",
    width: "100%",
    paddingLeft: "5px",
  },
  expandIcon: {
    color: COLORS.white,
  },
  checkbox: {
    color: COLORS.skyBlue,
    "&$Mui-checked": {
      color: COLORS.skyBlue,
    },
    "&.Mui-disabled": {
      color: COLORS.skyBlue,
      "&$Mui-checked": {
        color: COLORS.skyBlue,
      },
    },
  },
  buttonHolder: {
    width: "300px",
    margin: "10px auto 0 auto",
  },
});

const CustomAccordionSummary = withStyles({
  root: {
    minHeight: 48,
    "&$Mui-expanded": {
      minHeight: 48,
    },
  },
  content: {
    "&$Mui-expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(AccordionSummary);

export const EmailSetting = (props) => {
  const {
    lowThresholdList,
    highThresholdList,
    lowChecked,
    setLowChecked,
    highChecked,
    setHighChecked,
    emailList,
    setEmailList,
    isThresholdActive,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [displayValues, setDisplayValues] = useState([...emailList]);
  const [expanded, setExpanded] = useState(true);
  const maxEmail = 10;

  const handleChange = (e, index, target, updateFunction, baseArray) => {
    const newTarget = target.length ? target : (new Array(baseArray.length)).fill(false);
    const newChecked = newTarget.map((v, i) => (i === index ? e.target.checked : v));
    updateFunction(newChecked);
  };

  const onClickHandler = () => {
    const newEmailList = [...emailList, ""];
    setEmailList(newEmailList);
  };

  useEffect(() => {
    setDisplayValues([...emailList]);
  }, [emailList]);

  return (
    <Accordion
      style={{ color: "white", background: "none", marginTop: 0, boxShadow: "none" }}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <CustomAccordionSummary
        style={{ padding: 0, position: "relative" }}
        expandIcon={<ExpandMoreIcon sx={{color: "white"}}/>}
      >
        <Typography>{t(localizationKeys.SystemExternalSettings)}</Typography>
      </CustomAccordionSummary>
      <AccordionDetails>
        <Typography>{t(localizationKeys.SelectThresholdForNotification)}:</Typography>
      </AccordionDetails>
      <AccordionDetails style={{ opacity: isThresholdActive ? 1 : 0.5, display: "flex" }}>
        <Grid style={{ display: "flex", flexDirection: "row-reverse" }}>
          {lowThresholdList.map((v, i) => {
            return (
              <div key={`low-email-${i}`} className={classes.selectArea}>
                <div
                  className={classes.label}
                  style={{ border: `solid 2px ${COLORS.THRESHOLD_COLOR_LIST[i]}` }}
                >
                  {`L${i + 1}`}
                </div>
                <Checkbox
                  checked={lowChecked[i]}
                  onChange={(e) => handleChange(e, i, lowChecked, setLowChecked, lowThresholdList)}
                  classes={{ root: classes.checkbox }}
                  color="default"
                  disabled={!isThresholdActive}
                />
              </div>
            );
          })}
        </Grid>
        <Grid style={{ display: "flex" }}>
          {highThresholdList.map((v, i) => {
            return (
              <div key={`high-email-${i}`} className={classes.selectArea}>
                <div
                  className={classes.label}
                  style={{ border: `solid 2px ${COLORS.THRESHOLD_COLOR_LIST[i]}` }}
                >
                  {`H${i + 1}`}
                </div>
                <Checkbox
                  checked={highChecked[i]}
                  onChange={(e) => handleChange(e, i, highChecked, setHighChecked, highThresholdList)}
                  classes={{ root: classes.checkbox }}
                  color="default"
                  disabled={!isThresholdActive}
                />
              </div>
            );
          })}
        </Grid>
      </AccordionDetails>
      <AccordionDetails>
        <Typography>{t(localizationKeys.InputEmailAddresses)}</Typography>
      </AccordionDetails>
      <AccordionDetails style={{ display: "flex", flexFlow: "column", padding: "10px" }}>
        {emailList.map((v, index) => {
          return (
            <div className={classes.inputHolder} key={index}>
              <Input
                className={classes.input}
                value={displayValues[index]}
                onChange={(e) =>
                  setDisplayValues(displayValues.map((v, i) => (i === index ? e.target.value : v)))
                }
                onBlur={(e) => {
                  setEmailList(emailList.map((v, i) => (i === index ? e?.target.value : v)));
                }}
                endAdornment={
                  <EndAdornmentContent
                    index={index}
                    target={emailList}
                    updateFunction={setEmailList}
                  />
                }
              />
            </div>
          );
        })}
        <div className={classes.buttonHolder}>
          <CustomBlueButton
            onClick={() => {
              onClickHandler();
            }}
            disabled={emailList.length >= maxEmail}
          >
            {`+ ${t(localizationKeys.AddEmail)}`}
          </CustomBlueButton>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

EmailSetting.propTypes = {
  lowThresholdList: PropTypes.array,
  highThresholdList: PropTypes.array,
  lowChecked: PropTypes.array,
  setLowChecked: PropTypes.func,
  highChecked: PropTypes.array,
  setHighChecked: PropTypes.func,
  emailList: PropTypes.array,
  setEmailList: PropTypes.func,
  isThresholdActive: PropTypes.bool,
};
