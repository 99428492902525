import React from "react";
import PropTypes from "prop-types";
import { Grid, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { COLORS } from "../../../constants/colors";
import { CustomBlackButton, CustomRedButton } from "./customButton";
import { useTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys";

const useStyles = makeStyles({
  container: {
    background: COLORS.confirmationModalColor,
    color: COLORS.white,
    position: "absolute",
    top: "20%",
    left: "30%",
    width: "500px",
    height: "200px",
    fontSize: "20px",
    padding: "44px 0 0 0",
  },
  messageArea: {
    textAlign: "center",
    width: "100%",
  },
  buttonHolder: {
    width: "200px",
    margin: "0 auto",
  },
});

export const NoMatchConfirmationModal = React.forwardRef((props, ref) => {
  const { onRequestClose, setIsOpenNoMatchConfirmation, saveProcess, isSaving } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid ref={ref} container className={classes.container}>
      <div className={classes.messageArea}>
        <span>{t(localizationKeys.NoMatchConfirmation1st)}</span>
        <p style={{ height: "10px" }}></p>
        <span>{t(localizationKeys.NoMatchConfirmation2nd)}</span>
      </div>
      <Grid container justifyContent="center">
        <Grid className={classes.buttonHolder}>
          <CustomBlackButton
            onClick={() => {
              setIsOpenNoMatchConfirmation(false);
            }}
          >
            {t(localizationKeys.ContinueEditing)}
          </CustomBlackButton>
        </Grid>
        <Grid className={classes.buttonHolder}>
          <CustomRedButton
            onClick={async () => {
              await saveProcess();
              setIsOpenNoMatchConfirmation(false);
              onRequestClose(false);
            }}
            disabled={isSaving}
          >
            {isSaving
              ? <CircularProgress size={24}/>
              : t(localizationKeys.ConfirmAndSave)
            }
          </CustomRedButton>
        </Grid>
      </Grid>
    </Grid>
  );
});

NoMatchConfirmationModal.propTypes = {
  onRequestClose: PropTypes.func,
  setIsOpenNoMatchConfirmation: PropTypes.func,
  saveProcess: PropTypes.func,
  isSaving: PropTypes.bool,
};
