import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType";

const setHideLine = (state, action) => {
  state.hideLists = [...new Set(state.hideLists.concat([action.payload]))];
};

const removeHideLine = (state, action) => {
  state.hideLists = state.hideLists.filter((d) => d !== action.payload);
};

const removeAllHideLine = (state, _) => {
  state.hideLists = [];
};

const setChartList = (state, action) => {
  state.chartList = action.payload;
};

const changeColor = (state, action) => {
  switch (action.payload.graphType) {
    case GRAPH_TYPE.ENGINE:
      state.engineChartList.find(
        (d) => d.keyName === action.payload.dataKey && d.chartType === action.payload.chartType
      ).color = action.payload.color;
      break;
    case GRAPH_TYPE.SEP:
      state.sepChartList.find((d) => d.keyName === action.payload.dataKey).color =
        action.payload.color;
      break;
    case GRAPH_TYPE.ELECTRIC:
      state.electricChartList.find((d) => d.keyName === action.payload.dataKey).color =
        action.payload.color;
      break;
    case GRAPH_TYPE.EMISSION_LINES:
      state.emissionChartLineList.find((d) => d.keyName === action.payload.dataKey).color =
        action.payload.color;
      break;
    case GRAPH_TYPE.EMISSION_BARS:
      state.emissionChartBarList.find((d) => d.keyName === action.payload.dataKey).color =
        action.payload.color;
      break;
  }
};

export default {
  setHideLine,
  removeHideLine,
  removeAllHideLine,
  setChartList,
  changeColor,
};
