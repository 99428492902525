import { useState, useEffect } from "react";
import { DETAILS_TAGS } from "../../constants/cargo/details";
import { CH_NO_LIST } from "../../constants/cargo/chNoList";
import { GAUGES_TAGS } from "../../constants/cargo/gauges";
import { PITCH_TAGS, ROLL_TAGS, SENSORS_TAGS, VOLUME_TAGS } from "../../constants/cargo/sensors";

export const useTaggedCargoLatestData = (latestData) => {
  const [taggedLatestData, setTaggedLatestData] = useState([]);

  useEffect(() => {
    if (!latestData) return;
    const taggedData = latestData.map((value) => ({
      ...value,
      tag: CH_NO_LIST?.find((e) => e.chNo === value.chNo)?.tag,
    }));
    setTaggedLatestData(taggedData);
  }, [latestData]);

  return { taggedLatestData };
};

export const useCargoDetailsLatestData = (taggedLatestData) => {
  const [detailsData, setDetailsData] = useState([]);

  useEffect(() => {
    if (!taggedLatestData) return;
    const latestDetailsData = taggedLatestData.filter((value) =>
      DETAILS_TAGS.some((elem) => elem.tag === value.tag)
    );
    setDetailsData(latestDetailsData);
  }, [taggedLatestData]);

  return { detailsData };
};

export const useCargoGaugesLatestData = (taggedLatestData) => {
  const [gaugesData, setGaugesData] = useState([]);

  useEffect(() => {
    if (!taggedLatestData) return;
    const latestGaugesData = taggedLatestData.filter((value) =>
      GAUGES_TAGS.some((elem) => elem.tag === value.tag)
    );
    setGaugesData(latestGaugesData);
  }, [taggedLatestData]);

  return { gaugesData };
};

export const useCargoSensorsLatestData = (taggedLatestData) => {
  const [pitchData, setPitchData] = useState([]);
  const [rollData, setRollData] = useState([]);
  const [volumeData, setVolumeData] = useState([]);

  useEffect(() => {
    if (!taggedLatestData) return;
    const latestSensorData = taggedLatestData.filter((value) =>
      SENSORS_TAGS.some((elem) => elem.tag === value.tag)
    );
    const latestPitchData = latestSensorData.filter((value) =>
      PITCH_TAGS.some((elem) => elem.tag === value.tag)
    );
    const latestRollData = latestSensorData.filter((value) =>
      ROLL_TAGS.some((elem) => elem.tag === value.tag)
    );
    const latestVolumeData = latestSensorData.filter((value) =>
      VOLUME_TAGS.some((elem) => elem.tag === value.tag)
    );

    setPitchData(latestPitchData);
    setRollData(latestRollData);
    setVolumeData(latestVolumeData);
  }, [taggedLatestData]);

  return { pitchData, rollData, volumeData };
};
