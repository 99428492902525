import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";

const styles = () => ({
  dialogContainer: {
    "& > div:nth-child(3) > div": {
      borderRadius: "10px",
      // height: "70vh",
      background: "#2c3a47",
    },
  },

  checkBoxLabel: {
    ".MuiCheckbox-root": {
      color: "blue",
    },
  },
  label: {
    color: "#FFFFFF",
  },
  dialogHeader: {
    height: "50px",
    background: "#2c3a47",
  },
  closeIconButton: {
    position: "absolute",
    top: "0",
    right: "0",
    color: "#FFFFFF",

    "&:hover": {
      // background: "white",
      filter: "brightness(1.2)",
    },
  },
  title: {
    textAlign: "center",
    fontSize: "18px",
    color: "#FFFFFF",
  },
  alertTitle: {
    textAlign: "center",
    fontSize: "18px",
    color: "#ff5f7b",
  },
  alertIcon: {
    position: "absolute",
    left: "5px",
    top: "45px",
    color: "#ff5f7b",
    //bottom: "0",
  },
  buttonContainer: {
    margin: "10px",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "10px",
  },
  button: {
    width: "200px",
    height: "40px",
    textAlign: "center",
    margin: "5px",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "none",
    textColor: "#FFFFFF",
  },

  saveButton: {
    background: "#1ab3aa",
    "&:hover": {
      background: "#1ab3aa",
      filter: "brightness(1.2)",
    },
  },
});

const MessageModal = (props) => {
  const { classes, open, handleClose, go_back, message, type } = props;

  const saveButtonClicked = () => {
    handleClose();
    go_back();
  };

  return (
    <React.Fragment>
      <Dialog fullWidth onClose={handleClose} open={open} className={classes.dialogContainer}>
        <div className={classes.dialogHeader} />
        <IconButton className={classes.closeIconButton} onClick={handleClose} size="large">
          <Close />
        </IconButton>
        <Divider />
        {type == "alertMessage" && <WarningIcon className={classes.alertIcon} fontSize="large" />}
        <div className={type == "alertMessage" ? classes.alertTitle : classes.title}>{message}</div>

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={`${classes.button} ${classes.saveButton}`}
            onClick={saveButtonClicked}
          >
            <div>OK</div>
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

MessageModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(MessageModal);
