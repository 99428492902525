import localizationKeys from "../localizationKeys.js";
import { servicePolicyJn } from "../servicePolicy/servicePolicyJn.js";

const customChartCountNote = `
8つのトレンドグラフを作成できます。
それぞれのトレンドグラフには最大16チャンネルを割り付けることができます。
チャンネル名称を検索して、グラフに追加できます。
`;

const translations = {
  translations: {
    [localizationKeys.FleetOverview_upper]: "フリートオーバービュー",
    [localizationKeys.NavigationData_upper]: "航海データ",
    [localizationKeys.NavigationData_lower]: "航海データ",
    [localizationKeys.WindSpeed_upper]: "風速",
    [localizationKeys.WindDirection_upper]: "風向",
    [localizationKeys.VesselOgSpeed_upper]: "対地船速",
    [localizationKeys.VesselInformation_upper]: "船情報",
    [localizationKeys.CallSign_upper]: "コールサイン",
    [localizationKeys.VesselClass_upper]: "船級",
    [localizationKeys.Mmsi_upper]: "MMSI",
    [localizationKeys.Itinerary_upper]: "航路",
    [localizationKeys.Eta_upper]: "到着予定時刻",
    [localizationKeys.Departure_upper]: "出発地",
    [localizationKeys.Destination_upper]: "目的地",
    [localizationKeys.Engine_upper]: "主機",
    [localizationKeys.Engine_lower]: "主機",
    [localizationKeys.BasicEngineData_upper]: "主機基本データ",
    [localizationKeys.BasicSepData_upper]: "SEPデータ",
    [localizationKeys.SepData_lower]: "SEPデータ",
    [localizationKeys.Trim_upper]: "トリム",
    [localizationKeys.Heel_upper]: "ヒール",
    [localizationKeys.EngineLoad_upper]: "主機負荷",
    [localizationKeys.EngineLoad_lower]: "主機負荷",
    [localizationKeys.EngineRevolution_upper]: "主機回転数",
    [localizationKeys.EngineRevolution_lower]: "主機回転数",
    [localizationKeys.TotalEngineFoc_upper]: "主機燃料消費量",
    [localizationKeys.TotalEngineFoc_lower]: "主機燃料消費量",
    [localizationKeys.TotalDieselEngineFoc_upper]: "補機燃料消費量",
    [localizationKeys.TotalDieselEngineFoc_lower]: "補機燃料消費量",
    [localizationKeys.CurrentPosition_upper]: "現在位置",
    [localizationKeys.EngineData_upper]: "機関データ",
    [localizationKeys.EngineData_lower]: "機関データ",
    [localizationKeys.LoadDiagram_upper]: "ロードダイアグラム",
    [localizationKeys.PowerCurve_upper]: "パワーカーブ",
    [localizationKeys.PowerBase_upper]: "パワーベース",
    [localizationKeys.Foc_upper]: "FOC",
    [localizationKeys.Shp_upper]: "SHP",
    [localizationKeys.Speed_lower]: "船速",
    [localizationKeys.Og_upper]: "OG",
    [localizationKeys.Log_upper]: "LOG",
    [localizationKeys.EngineTrendGraph_upper]: "主機トレンドグラフ",
    [localizationKeys.CylinderDeviation_lower]: "シリンダー偏差-排ガス温度",
    [localizationKeys.CoolingFreshWaterTemp_lower]: "冷却清水温度",
    [localizationKeys.Logout_lower]: "ログアウト/ユーザーの切り替え",
    [localizationKeys.Settings_lower]: "設定",
    [localizationKeys.ProductInformation_lower]: "製品情報",
    [localizationKeys.Generator_upper]: "発電機",
    [localizationKeys.Electric_upper]: "電力",
    [localizationKeys.Electric_lower]: "電力",
    [localizationKeys.TotalPower_upper]: "総発電量",
    [localizationKeys.ElectricTrendGraph_upper]: "電力トレンドグラフ",
    [localizationKeys.DataDuration_lower]: "期間",
    [localizationKeys.Hours_lower]: "時間",
    [localizationKeys.Day_lower]: "日",
    [localizationKeys.Week_lower]: "週間",
    [localizationKeys.Month_lower]: "ケ月",
    [localizationKeys.Custom_lower]: "カスタム",
    [localizationKeys.Custom_upper]: "カスタム",
    [localizationKeys.Last30Mins_lower]: "30分間",
    [localizationKeys.LastHour_lower]: "1時間",
    [localizationKeys.LastDay_lower]: "1日",
    [localizationKeys.LastWeek_lower]: "1週間",
    [localizationKeys.LastMonth_lower]: "1ヶ月間",
    [localizationKeys.Last3Month_lower]: "3ヶ月間",
    [localizationKeys.Last6Month_lower]: "6ヶ月間",
    [localizationKeys.LastYear_lower]: "1年間",
    [localizationKeys.CylinderTemp_upper]: "シリンダー温度-排ガス温度",
    [localizationKeys.TrendData_upper]: "トレンドデータ",
    [localizationKeys.Monitoring_upper]: "モニタリング",
    [localizationKeys.Monitoring_lower]: "モニタリング",
    [localizationKeys.Disconnected_upper]: "VESSEL OFFLINE",
    [localizationKeys.DisconnectedNodata_lower]: "vessel offline - no data",
    [localizationKeys.LastUpdate_upper]: "最終更新",
    [localizationKeys.NoData_lower]: "NO DATA AVAILABLE",
    [localizationKeys.GraphNotSelected_lower]: "No graph selected",
    [localizationKeys.GraphNotSelectedMsg_lower]: "Please select a vessel and graph options on the right.",
    [localizationKeys.AlarmMonitoring_upper]: "監視",
    [localizationKeys.AlarmMonitoring_lower]: "監視",
    [localizationKeys.Status_upper]: "ステータス",
    [localizationKeys.MainEngine_upper]: "主機",
    [localizationKeys.Fire_upper]: "火災",
    [localizationKeys.LowInsulation_upper]: "低絶縁",
    [localizationKeys.ActiveAlarms_upper]: "警報中",
    [localizationKeys.AlarmName_lower]: "アラーム",
    [localizationKeys.Category_lower]: "カテゴリー",
    [localizationKeys.DateTime_lower]: "日付/時刻",
    [localizationKeys.Status_lower]: "状態",
    [localizationKeys.Data_lower]: "データ",
    [localizationKeys.NoActiveAlarms_lower]: "警報なし",
    [localizationKeys.NoActiveAlarms_upper]: "警報なし",
    [localizationKeys.NoOperations_upper]: "操作なし",
    [localizationKeys.NoChannel_upper]: "チャンネルデータなし",
    [localizationKeys.SelectDate_lower]: "日付選択",
    [localizationKeys.Submit_lower]: "送信",
    [localizationKeys.SelectedLanguage_lower]: "言語選択",
    [localizationKeys.BackMenu_lower]: "戻る",
    [localizationKeys.TermsofService_lower]: "利用規約",
    [localizationKeys.ServiceAgreementContentsTitle_lower]: "サービス契約書目次",
    [localizationKeys.ServicePolicyTitle_lower]:
      "ＪＲＣＳ株式会社  ｉｎｆｏｃｅａｎｕｓ  ｃｏｎｎｅｃｔ  利用規約",
    [localizationKeys.ServicePolicyText_lower]: servicePolicyJn,
    [localizationKeys.ServicePolicyTitle_upper]: "サービス契約書",
    [localizationKeys.ConfirmServicePolicy_lower]: "利用規約に同意します。",
    [localizationKeys.NoConfirmServicePolicy_lower]: "利用規約に同意しません。",
    [localizationKeys.ServicePolicyLink_lower]: "サービス契約書",
    [localizationKeys.Occurrence_lower]: "発生時刻",
    [localizationKeys.RecoveryTime_lower]: "復旧時刻",
    [localizationKeys.Search_lower]: "検索",
    [localizationKeys.AlarmHistory_upper]: "履歴",
    [localizationKeys.AlarmHistory_lower]: "履歴",
    [localizationKeys.AlarmOperations_upper]: "操作",
    [localizationKeys.AlarmOperations_lower]: "操作",
    [localizationKeys.AlarmOperationItem_lower]: "操作項目",
    [localizationKeys.AlarmOperationItemAbbr_lower]: "項目 ",
    [localizationKeys.AlarmOperationType_lower]: "操作",
    [localizationKeys.AlarmOperationTypeAbbr_lower]: "種類 ",
    [localizationKeys.Action_lower]: "変更内容",
    [localizationKeys.Alarm_upper]: "警報",
    [localizationKeys.OutOf_lower]: "out of",
    [localizationKeys.Value_lower]: "値",
    [localizationKeys.ResetSearch_lower]: "リセット",
    [localizationKeys.ElectricData_upper]: "電力データ",
    [localizationKeys.ElectricData_lower]: "電力データ",
    [localizationKeys.Minutes_lower]: "分間",
    [localizationKeys.EngineSelection_lower]: "主機選択",
    [localizationKeys.Last_lower]: "",
    [localizationKeys.StartTime_lower]: "開始時間",
    [localizationKeys.EndTime_lower]: "終了時間",
    [localizationKeys.LegTrend_lower]: "レグ",
    [localizationKeys.Thruster_lower]: "スラスター",
    [localizationKeys.Crane1_lower]: "クレーン 1",
    [localizationKeys.Crane2_lower]: "クレーン 2",
    [localizationKeys.TrimAndHeel_lower]: "トリム & ヒール",
    [localizationKeys.SepTrend_upper]: "SEPトレンド",
    [localizationKeys.CPP_lower]: "CPP翼角",
    [localizationKeys.Port_abbreviation]: "左舷",
    [localizationKeys.Starboard_abbreviation]: "右舷",
    [localizationKeys.Center_abbreviation]: "中央",
    [localizationKeys.DisconnectedNoEngineData_lower]: "engine offline - no data",
    [localizationKeys.Number_abbreviation]: "No.",
    [localizationKeys.Total_upper]: "合計",
    [localizationKeys.Power_upper]: "電力",
    [localizationKeys.Load_upper]: "負荷 %",
    [localizationKeys.RunningTime_upper]: "運転時間",
    [localizationKeys.RunningTime_Lower]: "運転時間",
    [localizationKeys.CPP_label]: "CPP翼角データ",
    [localizationKeys.Unit]: "単位",
    [localizationKeys.PortEngine]: "左舷主機",
    [localizationKeys.StarboardEngine]: "右舷主機",
    [localizationKeys.CenterEngine]: "中央主機",
    [localizationKeys.DataComparison_upper]: "トレンド比較", // NOTE: Temporary
    [localizationKeys.DataComparison_lower]: "トレンド比較",
    [localizationKeys.Channel_upper]: "チャンネル",
    [localizationKeys.AllChannel_lower]: "チャンネル",
    [localizationKeys.ChannelFavorites_lower]: "お気に入り",
    [localizationKeys.Graph_upper]: "グラフ",
    [localizationKeys.vessel_lower]: "船名",
    [localizationKeys.graph_lower]: "グラフ",
    [localizationKeys.SEP_upper]: "SEP",
    [localizationKeys.MainEngine_abbreviation]: "主機",
    [localizationKeys.Filters_lower]: "フィルタ",
    [localizationKeys.ResetSearchOptions_lower]: "リセット",
    [localizationKeys.SortByHighestValue]: "値の降順",
    [localizationKeys.SortByLowestValue]: "値の昇順",
    [localizationKeys.SortByNewest]: "日付の降順",
    [localizationKeys.SortByOldest]: "日付の昇順",
    [localizationKeys.SortAlarmItemAZ]: "名称のA to Z",
    [localizationKeys.SortAlarmItemZA]: "名称のZ to A",
    [localizationKeys.SortAscendingNo]: "No.の昇順",
    [localizationKeys.SortDescendingNo]: "No.の降順",
    [localizationKeys.SortByNewestOccurence]: "発生時刻の降順",
    [localizationKeys.SortByOldestOccurence]: "発生時刻の昇順",
    [localizationKeys.ShowUnresolvedAlarms]: "未復旧アラームの表示",
    [localizationKeys.SortOperationItemAZ]: "操作項目のA to Z",
    [localizationKeys.SortOperationItemZA]: "操作項目のZ to A",
    [localizationKeys.SortChannelNameAZ]: "チャネル名称のA to Z",
    [localizationKeys.SortChannelNameZA]: "チャネル名称のZ to A",
    [localizationKeys.ResetSort]: "並び替えリセット",
    [localizationKeys.Emissions_upper]: "EMISSION",
    [localizationKeys.EmissionsOverview_lower]: "Emissions OverView",
    [localizationKeys.OccurrenceTime]: "発生日時", //temporary
    [localizationKeys.UpdateTime]: "更新日時", //temporary,
    [localizationKeys.ChannelName]: "チャンネル名称",
    [localizationKeys.ChannelNumber]: "チャンネル番号",
    [localizationKeys.Loading]: "ロード中",
    [localizationKeys.OperationalAnalysis]: "運用分析",
    [localizationKeys.EmissionDataSelect]: "データ選択:",
    [localizationKeys.EmissionDataDuration]: "期間:",
    [localizationKeys.EmissionDataDurationStart]: "開始",
    [localizationKeys.EmissionDataDurationEnd]: "終了",
    [localizationKeys.EmissionDataDurationError]: "無効な期間です",
    [localizationKeys.EmissionExportData]: "エクスポート:",
    [localizationKeys.EmissionExportDataButton]: "エクスポート",
    [localizationKeys.ShowGraphVariables]: "凡例を表示",
    [localizationKeys.HideGraphVariables]: "凡例を非表示",
    [localizationKeys.ChangeColor]: "チャンネル色変更",
    [localizationKeys.EngineTemp_lower]: "温度",
    [localizationKeys.EnginePress_lower]: "圧力",

    // Custom Charts
    [localizationKeys.CustomSubtitle]: "その他の言語でグラフのタイトルを追加（オプション）",
    [localizationKeys.CustomSearchChannels]: "名称検索",
    [localizationKeys.CustomSearchChannelsPlaceholder]: "チャンネル名称、チャンネル番号",
    [localizationKeys.CustomShowAll]: "すべて表示",
    [localizationKeys.CustomChartCountNote]: customChartCountNote,
    [localizationKeys.CustomNoMatchFound]: "結果なし",
    [localizationKeys.CustomGraphPreview]: "プレビュー",
    [localizationKeys.CustomVariableNote]: "最大16チャンネル",
    [localizationKeys.CustomSaveGraph]: "保存",
    [localizationKeys.CustomCancel]: "キャンセル",
    [localizationKeys.CustomDelete]: "削除",
    [localizationKeys.CustomChannelNo]: "チャンネル番号",
    [localizationKeys.CustomChannelName]: "チャンネル名称",
    [localizationKeys.CustomUnit]: "単位",
    [localizationKeys.CustomSignalType]: "信号タイプ",
    [localizationKeys.CustomGraphPreviewFull]: "16チャンネルに達しました。",
    [localizationKeys.CustomVariableNoteFull]:
      "これ以上追加できません。追加する場合は、いずれかのチャンネルを削除してください。",
    [localizationKeys.CustomPromptCancelTitle]: "編集内容を破棄しますか？",
    [localizationKeys.CustomPromptCancelSubtitle]: "編集内容は保存されません。",
    [localizationKeys.CustomPromptCancelOkButton]: "破棄する",
    [localizationKeys.CustomPromptCancelCancelButton]: "編集をつづける",
    [localizationKeys.CustomPromptSaveTitle]: "このグラフを保存しますか？",
    [localizationKeys.CustomPromptSaveSubtitle]: "保存したグラフはあとで変更できます。",
    [localizationKeys.CustomPromptSaveOkButton]: "保存する",
    [localizationKeys.CustomPromptSaveCancelButton]: "編集をつづける",
    [localizationKeys.CustomPromptDeleteTitle]: "このグラフを削除しますか？",
    [localizationKeys.CustomPromptDeleteSubtitle]: "削除したグラフは元に戻せません。",
    [localizationKeys.CustomPromptDeleteOkButton]: "削除する",
    [localizationKeys.CustomPromptDeleteCancelButton]: "キャンセル",
    [localizationKeys.CustomPromptLeaveTitle]: "編集内容は保存されていません。",
    [localizationKeys.CustomPromptLeaveSubtitle]: "保存せずに編集を終了しますか？",
    [localizationKeys.CustomPromptLeaveOkButton]: "保存せずに終了する",
    [localizationKeys.CustomPromptLeaveCancelButton]: "編集をつづける",
    [localizationKeys.CustomLastEdited]: "最終更新",
    [localizationKeys.CustomMenuAdd]: "新しいグラフを作成して追加",

    // LCO2
    [localizationKeys.LCO2Page]: "貨物データ",
    [localizationKeys.Transport]: "輸送",
    [localizationKeys.Handling]: "荷役",
    [localizationKeys.CargoStatus]: "貨物ステータス",
    [localizationKeys.LocalTime]: "現地時刻",
    [localizationKeys.FlowRate]: "流量",
    [localizationKeys.FlowSpeed]: "流速",
    [localizationKeys.Density]: "密度",
    [localizationKeys.AMBTemperature]: "外気温度",
    [localizationKeys.Tank]: "タンク",
    [localizationKeys.CargoTank]: "貨物タンク",
    [localizationKeys.ShieldInnerSurfaceTemp]: "タンク防壁内側中部",
    [localizationKeys.ShieldOuterSurfaceTemp]: "タンク防壁外側中部",
    [localizationKeys.TankCoverInnerSurfaceTemp]: "タンクカバー内温度",
    [localizationKeys.CargoTankPress]: "タンク本体圧力",
    [localizationKeys.CargoTankTemp]: "貨物タンク",
    [localizationKeys.PressureLoss]: "圧力損失",
    [localizationKeys.XAngularVelocity]: "横傾斜角速度",
    [localizationKeys.YAngularVelocity]: "船首上下角速度",
    [localizationKeys.PositionAndWeather]: "航海情報",
    [localizationKeys.TrueWind]: "真風向",
    [localizationKeys.RelativeWind]: "相対風向",
    [localizationKeys.VesselSpeedOG]: "対地船速",
    [localizationKeys.LongAndLat]: "緯度・経度",
    [localizationKeys.CargoMimic]: "センサー位置図",
    [localizationKeys.TankLevel]: "タンク液位",
    [localizationKeys.StatusGauge]: "貨物タンク内部圧力",
    [localizationKeys.No1TankPress]: "No.1 タンク本体圧力",
    [localizationKeys.No2TankPress]: "No.2 タンク本体圧力",
    [localizationKeys.No2CargoPPDisch]: "No.2 貨物ポンプ吐出圧力",
    [localizationKeys.No2CargoPPSuction]: "No.2 貨物ポンプ吸込圧力",
    [localizationKeys.PortManifoldLiquid]: "左舷船陸接続部液圧力",
    [localizationKeys.StarboardManifoldLiquid]: "右舷船陸接続部液圧力",
    [localizationKeys.CargoTemperature]: "貨物温度グラフ(瞬時)",
    [localizationKeys.CargoTemperatureAndPressure]: "貨物温度・圧力グラフ(経時)",
    [localizationKeys.CargoLoading]: "積荷役",
    [localizationKeys.CargoUnloading]: "揚荷役",
    [localizationKeys.CSVDownload]: "CSVダウンロード",
    [localizationKeys.NoLatestData]: "--",
    [localizationKeys.NoLatestDataForChart]: "N/A",
    [localizationKeys.BackToLatest]: "最新データを表示",

    // Channel alert threshold settings
    [localizationKeys.ChannelOptions]: "チャンネル設定",
    [localizationKeys.ConnectNotification]: "通知設定",
    [localizationKeys.ThresholdReached]: "閾値超過",
    [localizationKeys.SystemExternalSettings]: "詳細設定",
    [localizationKeys.SelectThresholdForNotification]: "通知する閾値を選択",
    [localizationKeys.InputEmailAddresses]: "メールアドレス入力",
    [localizationKeys.AddEmail]: "メールアドレス追加",
    [localizationKeys.SetThreshold]: "閾値設定",
    [localizationKeys.AddThreshold]: "閾値追加",
    [localizationKeys.SaveChanges]: "保存",
    [localizationKeys.NoSaveConfirmation1st]: "変更が保存されませんが",
    [localizationKeys.NoSaveConfirmation2nd]: "このまま終了しますか？",
    [localizationKeys.ContinueEditing]: "編集を続ける",
    [localizationKeys.ExitWithoutSaving]: "保存せず終了",
    [localizationKeys.DeleteEmailConfirmation1st]: "このメールアドレスを削除してよろしいですか?",
    [localizationKeys.DeleteEmailConfirmation2nd]:
      "このチャンネルに関する通知を受け取れなくなります",
    [localizationKeys.RemoveEmail]: "メールアドレスを削除",
    [localizationKeys.ReturnToChannelOptions]: "編集に戻る",
    [localizationKeys.NoMatchConfirmation1st]: "閾値に重複した値があります。",
    [localizationKeys.NoMatchConfirmation2nd]: "重複したアラートメールを受け取る恐れがあります。",
    [localizationKeys.ConfirmAndSave]: "そのまま保存する",
    [localizationKeys.NotExceedMax]: "閾値は最大値を上回らないでください",
    [localizationKeys.NotExceedMax]: "閾値は最小値を下回らないでください",
    [localizationKeys.NotExceedLow]: "低側閾値は高側を上回らないでください",
    [localizationKeys.NotExceedHigh]: "高側閾値は低側を下回らないでください",
    [localizationKeys.Min]: "最小",
    [localizationKeys.Max]: "最大",
    [localizationKeys.CreateHighThreshold]: "閾値(高)",
    [localizationKeys.CreateLowThreshold]: "閾値(低)",
    [localizationKeys.HighThreshold]: "高",
    [localizationKeys.LowThreshold]: "低",

    //Synthetica
    [localizationKeys.Rank]: "ランク",
    [localizationKeys.Distance]: "距離",
    [localizationKeys.VesselDetails]: "本船情報",
    [localizationKeys.VesselPerformance]: "パフォーマンス",
    [localizationKeys.DistanceTraveled]: "航行距離",
    [localizationKeys.CO2]: "CO2",
    [localizationKeys.FuelConsumption]: "燃料消費量",
    [localizationKeys.AverageSpeed]: "平均船速",
    [localizationKeys.VoyageHistory]: "航海履歴",
    [localizationKeys.Departed]: "出航地",
    [localizationKeys.DepartureTimeUTC]: "出発時刻(UTC)",
    [localizationKeys.ArrivalTimeUTC]: "到着時刻(UTC)",
    [localizationKeys.SpeedKn]: "船速(kn)",
    [localizationKeys.Destination]: "目的地",
    [localizationKeys.CII]: "CII",
    [localizationKeys.CIIDiff]: "CII偏差(%)",
    [localizationKeys.DailyCII]: "CIIパフォーマンス（本日）",
    [localizationKeys.CurrentCII]: "現在値",
    [localizationKeys.RequiredCII]: "要求値",
    [localizationKeys.PredictedEOYCII]: "年末予測値",
    [localizationKeys.PredictedIntervalCII]: "予測値範囲",
    [localizationKeys.CIIOverTime]: "CII推移",

    // Vessel Overview
    [localizationKeys.VesselDetailsOverview]: "本船情報",
    [localizationKeys.Updated]: "更新",
    [localizationKeys.VesselClass]: "船級",
    [localizationKeys.CIIRanking]: "CIIランク",
    [localizationKeys.VesselSpeed]: "船速",
    [localizationKeys.VesselPosition]: "本船位置",
    [localizationKeys.TrueWindSpeed]: "真風速",
    [localizationKeys.TrueWindDirection]: "真風向",
    [localizationKeys.LocalWeather]: "地域天気",
    [localizationKeys.SeaWaterTemp]: "海水温",
    [localizationKeys.ReposedAlarms]: "警報休止",
    [localizationKeys.GoToAlarmDetails]: "詳細警報へ",
    [localizationKeys.CurrentStatus]: "現在値",
    [localizationKeys.MainEngineLoad]: "ロードダイアグラム",
    [localizationKeys.MainEnginePowerCurve]: "パワーカーブ",

  },
};

export default translations;
