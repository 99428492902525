import withStyles from '@mui/styles/withStyles';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const TableCellStyled = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c3a48",
    color: theme.palette.common.white,
    padding: "10px 20px !important",
    fontSize: "16px",
    fontWeight: "500 !important",
    fontFamily: "Inter",
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px !important",
    },
  },
  body: {
    fontSize: 12,
    fontFamily: "Inter",
    [theme.breakpoints.down('sm')]: {
      fontSize: "8px !important",
    },
  },
  root: {
    maxHeight: "35px",
    borderBottom: "none",
    padding: "10px 12px 10px 20px",
    [theme.breakpoints.down('md')]: {
      padding: "6px !important",
    },
    [theme.breakpoints.down('sm')]: {
      padding: "6px 2px !important",
    },
    fontWeight: "200",
    color: theme.palette.common.white,
    "&:hover": {
      borderBottom: "none",
    },
  },
}))(TableCell);

export const TableCellStyledForFunction = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c3a48",
    color: theme.palette.common.white,
    padding: "5px 0px !important",
    fontSize: "16px",
    fontWeight: "500 !important",
    fontFamily: "Inter",
    [theme.breakpoints.down('sm')]: {
      fontSize: "10px !important",
    },
  },
  body: {
    fontSize: 12,
    fontFamily: "Inter",
    [theme.breakpoints.down('sm')]: {
      fontSize: "8px !important",
    },
  },
  root: {
    maxHeight: "35px",
    borderBottom: "none",
    padding: "10px 12px 10px 20px",
    [theme.breakpoints.down('md')]: {
      padding: "6px !important",
    },
    [theme.breakpoints.down('sm')]: {
      padding: "6px 2px !important",
    },
    fontWeight: "200",
    color: theme.palette.common.white,
    "&:hover": {
      borderBottom: "none",
    },
  },
}))(TableCell);

export const TableRowStyled = withStyles((theme) => ({
  root: {
    overflow: "hidden",
    "&:not(:last-child)": {
      borderBottom: "solid 1px rgba(255,255,255,0.1) !important",
    },
  },
}))(TableRow);
