import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { useTaggedCargoLatestData } from "./useLatestCargoData";
import { CargoStatus } from "./status";
import { PitchAndRoll } from "./pitchAndRoll";
import { Navigation } from "./navigation";
import { Mimic } from "./mimic";
import { TankFillStatus } from "./tankFillStatus";
import { CargoStatusGauge } from "./statusGauge";
import { Temperature } from "./temperature";
import { Pressure } from "./pressure";
import { CARGO_PAGES } from "../../constants/cargo/pages";
import { CARGO_CHARTS } from "../../constants/cargo/constants";

export const DisplayPage = (props) => {
  const { vesselId, page, classes, latestData, interval, selectedChart, setSelectedChart } = props;
  const [isMaximized, setMaximize] = useState(false);
  const updateMaximized = (val) => {
    setMaximize(isMaximized ? false : val);
  };
  const { taggedLatestData } = useTaggedCargoLatestData(latestData);

  return (
    <Grid container className={classes.container}>
      {!isMaximized && (
        <Grid item xs={12} xl={7} className={ `${classes.topContainer} ${classes.leftContainer}` }>
          <CargoStatus taggedLatestData={taggedLatestData} />
        </Grid>
      )}
      {!isMaximized && (
        <Grid item xs={12} xl={5} className={ `${classes.topContainer} ${classes.rightContainer}` }>
          <PitchAndRoll taggedLatestData={taggedLatestData} />
        </Grid>
      )}
      {!isMaximized && (
        <Grid item xs={12} xl={6} className={ `${classes.middleContainer} ${classes.leftContainer}` }>
          {page === CARGO_PAGES.TRANSPORT ? <Navigation vesselId={vesselId} /> : <Mimic vesselId={vesselId} />}
        </Grid>
      )}
      {!isMaximized && (
        <Grid item xs={4} xl={2} className={ `${classes.middleContainer} ${classes.leftContainer} ${classes.rightContainer} ${classes.tankContainer}` }>
          <TankFillStatus taggedLatestData={taggedLatestData} />
        </Grid>
      )}
      {!isMaximized && (
        <Grid item xs={8} xl={4} className={ `${classes.middleContainer} ${classes.rightContainer}` }>
          <CargoStatusGauge page={page} taggedLatestData={taggedLatestData} />
        </Grid>
      )}
      {(!isMaximized || isMaximized === CARGO_CHARTS.TEMPERATURE) && (
        <Grid
          item
          xs={12}
          className={
            isMaximized === CARGO_CHARTS.TEMPERATURE ? classes.maximized : classes.bottomContainer
          }
        >
          <Temperature
            vesselId={vesselId}
            isMaximized={isMaximized}
            setMaximize={updateMaximized}
            latestData={latestData}
          />
        </Grid>
      )}
      {(!isMaximized || isMaximized === CARGO_CHARTS.PRESSURE) && (
        <Grid
          item
          xs={12}
          className={
            isMaximized === CARGO_CHARTS.PRESSURE ? classes.maximized : classes.bottomContainer
          }
        >
          <Pressure
            vesselId={vesselId}
            isMaximized={isMaximized}
            setMaximize={updateMaximized}
            interval={interval}
            selectedChart={selectedChart}
            setSelectedChart={setSelectedChart}
          />
        </Grid>
      )}
    </Grid>
  );
};

DisplayPage.propTypes = {
  vesselId: PropTypes.string,
  page: PropTypes.string,
  classes: PropTypes.object,
  latestData: PropTypes.array,
  interval: PropTypes.number,
  selectedChart: PropTypes.string,
  setSelectedChart: PropTypes.func,
};
