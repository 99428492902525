import React from "react";
import PropTypes from "prop-types";
import { useCargoGaugesLatestData } from "../useLatestCargoData";
import { Gauge } from "./gauge";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  gauge: {
    height: "100%",
    width: "100%",
  },
}));

export const CargoStatusGauge = (props) => {
  const { page, taggedLatestData, isVesselOverview } = props;
  const classes = useStyles();
  const { gaugesData } = useCargoGaugesLatestData(taggedLatestData);

  return (
    <Grid item className={classes.gauge}>
      <Gauge page={page} data={gaugesData} isVesselOverview={isVesselOverview} />
    </Grid>
  );
};

CargoStatusGauge.propTypes = {
  page: PropTypes.string,
  taggedLatestData: PropTypes.array,
  isVesselOverview: PropTypes.bool,
};
