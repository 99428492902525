import React from "react";

export const MIN_ENGINE_PAPER_HEIGHT = 355;

export const MIN_ELECTRIC_PAPER_HEIGHT = 480;

export const TITLE_PAPER_HEIGHT = 47;

export const GENERATOR_TYPE = {
  SG: "shaft_generator",
  GE: "auxiliary_generator",
  TOTAL: "generator_total",
};

export const TOGGLE_OPTION_LIST = [
  {
    name: "bar",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 20.5a.5.5 0 0 1 .5-.5H10v1H7.5a.5.5 0 0 1-.5-.5zM7 12.5a.5.5 0 0 1 .5-.5H10v1H7.5a.5.5 0 0 1-.5-.5zM7 4.5a.5.5 0 0 1 .5-.5H10v1H7.5a.5.5 0 0 1-.5-.5z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.531 4.365H10.47v16.27h5.062V4.365zM10 4v17h5.531c.26 0 .469-.163.469-.365V4.365c0-.202-.21-.365-.469-.365H10z"
          fill="#fff"
        />
        <rect x="11" y="8" width="4" height="12" rx="1" fill="#fff" />
      </svg>
    ),
    duration: null,
    unit: null,
    dataTestId: null,
  },
  {
    name: "pie",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.09 17.753c.393.67 1.266.901 1.85.39a7.5 7.5 0 1 0-9.86.018c.586.51 1.459.274 1.85-.397s.143-1.522-.383-2.094a4.686 4.686 0 1 1 6.917-.013c-.523.575-.769 1.426-.375 2.096z"
          fill="#fff"
        />
      </svg>
    ),
    duration: null,
    unit: null,
    dataTestId: null,
  },
];
