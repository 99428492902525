import React from "react";
import { Link } from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import MessageModal from "../messagemodal/index.jsx";
import { sendPurchaseInQuiry } from "../../api/contact";

const styles = () => ({
  inputTextArea: {
    width: "100%",
    height: 170,
    resize: "none",
    padding: 15,
    fontFamily: "inherit",
    border: "1px solid rgba(199,199,199,0.5)",
    borderRadius: "5px",
    "&::placeholder": {
      color: "rgba(0,0,0,0.12)",
    },
  },
  buttonContainer: {
    textAlign: "center",
  },
  button: {
    width: "200px",
    height: "60px",
    textAlign: "center",
    margin: "5px",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "none",
    textColor: "#FFFFFF",
    border: "none",
    fontSize: "24px",
  },
  backlink: {
    display: "block",
    textDecoration: "none",
    color: "#000",
    padding: "0",
  },
});

//FIELD name
const FIELDS = {
  userName: "userName",
  userEmail: "userEmail",
  userCompany: "userCompany",
  userSubject: "userSubject",
  userMessage: "userMessage",
};

class PurchaseInQuiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitEnabled: false,
      stuserName: "",
      isMessageDialogOpen: false,
    };

    this.elements = null;
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormBlur = this.handleFormBlur.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.closedeleteConfirmModal = this.closedeleteConfirmModal.bind(this);
    this.sendSubmit = this.sendSubmit.bind(this);
  }

  setSubmitEnabled() {
    if (!this.elements) {
      return;
    }
    const isNoEmptyFields = Object.values(FIELDS).every((field) => this.elements[field].value);
    this.setState({
      isSubmitEnabled: isNoEmptyFields,
    });
  }

  handleFormChange() {
    this.setSubmitEnabled();
  }

  handleFormBlur(event) {
    const field = event.target.name;
    this.setSubmitEnabled();
  }

  async handleFormSubmit(event) {
    event.preventDefault();

    // get form value
    const params = {
      userName: this.elements[FIELDS.userName].value,
      userEmail: this.elements[FIELDS.userEmail].value,
      userCompany: this.elements[FIELDS.userCompany].value,
      userSubject: this.elements[FIELDS.userSubject].value,
      userMessage: this.elements[FIELDS.userMessage].value,
    };

    const { sendInQuiryRet, sendInQuiryError } = await sendPurchaseInQuiry(params);

    this.setState({
      isMessageDialogOpen: true,
    });
  }

  async sendSubmit() {
    const { history } = this.props;

    //setModalOpen(true);
    this.elements[FIELDS.userName].value = "";
    this.elements[FIELDS.userEmail].value = "";
    this.elements[FIELDS.userCompany].value = "";
    this.elements[FIELDS.userSubject].value = "";
    this.elements[FIELDS.userMessage].value = "";
    this.setState({
      isSubmitEnabled: false,
    });
    history.push("/purchaseinq");
  }

  closedeleteConfirmModal() {
    this.setState({
      isMessageDialogOpen: false,
    });
  }

  render() {
    const { classes } = this.props;

    const { isMessageDialogOpen } = this.state;

    return (
      <React.Fragment>
        <Container className="ts_container">
          <div className="ts_content">
            <div className="title_green   ">
              <React.Fragment>
                お問合せ / Contact us
                {/* <div className="label_required">
                    (*) 必須 / This mark is required
                  </div> */}
              </React.Fragment>
            </div>
            <div className="input_container inquiryForm">
              <form
                onChange={this.handleFormChange}
                onBlur={this.handleFormBlur}
                onSubmit={this.handleFormSubmit}
                ref={(el) => (this.elements = el && el.elements)}
              >
                <div className="field_container">
                  <div className="label required">氏名 / Name</div>
                  <input type="text" name={FIELDS.userName} className="field" maxLength={100} />
                </div>
                <div className="field_container">
                  <div className="label required">メールアドレス / Email address</div>
                  <input type="email" name={FIELDS.userEmail} className="field" maxLength={254} />
                </div>
                <div className="field_container">
                  <div className="label required">会社名 / Company</div>
                  <input type="text" name={FIELDS.userCompany} className="field" maxLength={100} />
                </div>
                <div className="field_container">
                  <div className="label required">件名 / Subject</div>
                  <input type="text" name={FIELDS.userSubject} className="field" maxLength={100} />
                </div>
                <div className="field_container">
                  <div className="label required">メッセージ / Message</div>
                  <textarea
                    type="text"
                    name={FIELDS.userMessage}
                    rows={7}
                    className={classes.inputTextArea}
                    maxLength={512}
                  />
                </div>
                <div className="button-container">
                  <button
                    type="submit"
                    variant="contained"
                    className={this.state.isSubmitEnabled ? `button-approve` : "button-disabled"}
                    disabled={!this.state.isSubmitEnabled}
                    color="primary"
                  >
                    送信 / Send
                  </button>
                  <button
                    type="button"
                    variant="contained"
                    className="button-delete"
                    color="primary"
                  >
                    <CloseIcon />
                    <Link className={classes.backlink} to={`/`}>
                      戻る / Back
                    </Link>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <MessageModal
            handleClose={this.closedeleteConfirmModal}
            open={isMessageDialogOpen}
            message={"送信完了 / Email sent"}
            buttonText={"Ok"}
            type="check"
            go_back={this.sendSubmit}
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PurchaseInQuiry);
