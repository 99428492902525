export const GRAPH_TYPE = {
  ELECTRIC: "electric",
  ENGINE: "engine",
  SEP: "sep",
  EMISSION_LINES: "emission_lines",
  EMISSION_BARS: "emission_bars",
  CUSTOM: "custom",
  PRESSURE: "pressure",
  CARGO: "cargo",
};
