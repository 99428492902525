import React, { memo } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import { Volume } from "./volume/index.jsx";
import { useCargoSensorsLatestData } from "../useLatestCargoData.js";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
  },
  volume: {
    height: "100%",
  },
}));

export const TankFillStatus = memo((props) => {
  const { taggedLatestData } = props;
  const classes = useStyles();
  const { volumeData } = useCargoSensorsLatestData(taggedLatestData);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.volume}>
        <Volume data={volumeData} />
      </Grid>
    </Grid>
  );
});

TankFillStatus.propTypes = {
  taggedLatestData: PropTypes.array,
};
