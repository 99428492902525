import constants from "./constants.js";
import localizationKeys from "../../../i18n/localizationKeys.js";
import dayjs from 'util/dayjs-init.js';
import { DATE_FORMAT } from "../../../constants/constants.js";

export const getStatusList = (alarmList) => {
  const retList = alarmList?.length
    ? [
        "ALL",
        ...new Set(
          alarmList
            .map((d) => [d?.value])
            .flat()
            .filter((d) => ![null, undefined, "", "-"].includes(d))
        ),
      ]
    : [];

  return retList;
};

export const setWordTextData = (() => {
  let innerAction = null;
  let innerText = null;

  return {
    setAction: (action) => (innerAction = action),
    setText: (text) => (innerText = text),
    action: () => innerAction(innerText),
    getText: () => innerText,
  };
})();

export const isInitialState = (historyFilter) => {
  return (
    [null, "", undefined].includes(historyFilter.word) &&
    [null, "", undefined, "ALL"].includes(historyFilter.status) &&
    JSON.stringify({
      occurrenceStart: historyFilter.occurrenceStart,
      occurrenceEnd: historyFilter.occurrenceEnd,
      recoveryTimeStart: historyFilter.recoveryTimeStart,
      recoveryTimeEnd: historyFilter.recoveryTimeEnd,
    }) === JSON.stringify(constants.DURATION_INITIAL_STATE)
  );
};

const descendingComparator = (a, b, orderBy) => {
  const latest = dayjs.utc().format(DATE_FORMAT);
  const aVal = a[orderBy] ?? latest;
  const bVal = b[orderBy] ?? latest;

  if (orderBy === "alarmStatus") {
    if (bVal > aVal) return 1;
    else if (bVal < aVal) return -1;
    else return 0;
  } else {
    if (bVal < aVal) return 1;
    else if (bVal > aVal) return -1;
    else return 0;
  }
};

export const getComparator = (order, orderBy) =>
  order === constants.ORDER.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = (array, comparator, orderBy) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const alarmHistoryListHeader = (t) => [
  {
    id: constants.ALARM_HEADER_ID.ID,
    name: "Ch. No.",
    align: "left",
    sortable: true,
    hideOnSmallDevice: true,
    style: {},
  },
  {
    id: constants.ALARM_HEADER_ID.ALARM_NAME,
    name: t(localizationKeys.AlarmName_lower),
    align: "left",
    sortable: true,
    style: {},
  },
  {
    id: constants.ALARM_HEADER_ID.VALUE,
    name: t(localizationKeys.Value_lower),
    align: "center",
    sortable: true,
    style: { minWidth: "56px" },
  },
  {
    id: constants.ALARM_HEADER_ID.OCCURRENCE,
    name: t(localizationKeys.Occurrence_lower),
    align: "left",
    sortable: true,
    style: { minWidth: "82px" },
  },
  {
    id: constants.ALARM_HEADER_ID.RECOVERY,
    name: t(localizationKeys.RecoveryTime_lower),
    align: "left",
    sortable: true,
    style: { minWidth: "93px" },
  },
];

export const alarmOperationsListHeader = (t, isXsDown) => [
  {
    id: constants.ALARM_HEADER_ID.ID,
    name: "Ch. No.",
    align: "left",
    sortable: true,
    hideOnSmallDevice: true,
  },
  {
    id: constants.ALARM_HEADER_ID.ALARM_NAME,
    name: t(
      isXsDown
        ? localizationKeys.AlarmOperationItemAbbr_lower
        : localizationKeys.AlarmOperationItem_lower
    ),
    align: "left",
    sortable: true,
    style: { minWidth: "80px" },
  },
  {
    id: constants.ALARM_HEADER_ID.OPERATION_TYPE,
    name: t(
      isXsDown
        ? localizationKeys.AlarmOperationTypeAbbr_lower
        : localizationKeys.AlarmOperationType_lower
    ),
    align: "left",
    sortable: true,
    style: { minWidth: "75px" },
  },
  {
    id: constants.ALARM_HEADER_ID.STATUS,
    name: t(localizationKeys.Status_lower),
    align: "center",
    sortable: true,
    style: { minWidth: "75px" },
  },
  {
    id: constants.ALARM_HEADER_ID.ACTION,
    name: t(localizationKeys.Action_lower),
    align: "center",
    sortable: false,
  },
  {
    id: constants.ALARM_HEADER_ID.DATETIME,
    name: t(localizationKeys.DateTime_lower),
    align: "left",
    sortable: true,
  },
];

export const formatDurationData = (date) =>
  dayjs.utc(date).local().format(constants.ALARM_DATE_DURATION_FORMAT);
