//Image imports
import flagAL from "../../../assets/images/vessels/country-flags/AL.png";
import flagAD from "../../../assets/images/vessels/country-flags/AD.png";
import flagAT from "../../../assets/images/vessels/country-flags/AT.png";
import flagPT from "../../../assets/images/vessels/country-flags/PT.png";
import flagBE from "../../../assets/images/vessels/country-flags/BE.png";
import flagBY from "../../../assets/images/vessels/country-flags/BY.png";
import flagBG from "../../../assets/images/vessels/country-flags/BG.png";
import flagVA from "../../../assets/images/vessels/country-flags/VA.png";
import flagCY from "../../../assets/images/vessels/country-flags/CY.png";
import flagDE from "../../../assets/images/vessels/country-flags/DE.png";
import flagGE from "../../../assets/images/vessels/country-flags/GE.png";
import flagMD from "../../../assets/images/vessels/country-flags/MD.png";
import flagMT from "../../../assets/images/vessels/country-flags/MT.png";
import flagAM from "../../../assets/images/vessels/country-flags/AM.png";
import flagDK from "../../../assets/images/vessels/country-flags/DK.png";
import flagES from "../../../assets/images/vessels/country-flags/ES.png";
import flagFR from "../../../assets/images/vessels/country-flags/FR.png";
import flagFI from "../../../assets/images/vessels/country-flags/FI.png";
import flagFO from "../../../assets/images/vessels/country-flags/FO.png";
import flagGB from "../../../assets/images/vessels/country-flags/GB.png";
import flagGI from "../../../assets/images/vessels/country-flags/GI.png";
import flagGR from "../../../assets/images/vessels/country-flags/GR.png";
import flagHR from "../../../assets/images/vessels/country-flags/HR.png";
import flagMA from "../../../assets/images/vessels/country-flags/MA.png";
import flagHU from "../../../assets/images/vessels/country-flags/HU.png";
import flagNL from "../../../assets/images/vessels/country-flags/NL.png";
import flagIT from "../../../assets/images/vessels/country-flags/IT.png";
import flagIE from "../../../assets/images/vessels/country-flags/IE.png";
import flagIS from "../../../assets/images/vessels/country-flags/IS.png";
import flagLI from "../../../assets/images/vessels/country-flags/LI.png";
import flagLU from "../../../assets/images/vessels/country-flags/LU.png";
import flagMC from "../../../assets/images/vessels/country-flags/MC.png";
import flagNO from "../../../assets/images/vessels/country-flags/NO.png";
import flagPL from "../../../assets/images/vessels/country-flags/PL.png";
import flagME from "../../../assets/images/vessels/country-flags/ME.png";
import flagRO from "../../../assets/images/vessels/country-flags/RO.png";
import flagSE from "../../../assets/images/vessels/country-flags/SE.png";
import flagSK from "../../../assets/images/vessels/country-flags/SK.png";
import flagSM from "../../../assets/images/vessels/country-flags/SM.png";
import flagCH from "../../../assets/images/vessels/country-flags/CH.png";
import flagCZ from "../../../assets/images/vessels/country-flags/CZ.png";
import flagTR from "../../../assets/images/vessels/country-flags/TR.png";
import flagUA from "../../../assets/images/vessels/country-flags/UA.png";
import flagRU from "../../../assets/images/vessels/country-flags/RU.png";
import flagMK from "../../../assets/images/vessels/country-flags/MK.png";
import flagLV from "../../../assets/images/vessels/country-flags/LV.png";
import flagEE from "../../../assets/images/vessels/country-flags/EE.png";
import flagLT from "../../../assets/images/vessels/country-flags/LT.png";
import flagSI from "../../../assets/images/vessels/country-flags/SI.png";
import flagRS from "../../../assets/images/vessels/country-flags/RS.png";
import flagAI from "../../../assets/images/vessels/country-flags/AI.png";
import flagUS from "../../../assets/images/vessels/country-flags/US.png";
import flagAG from "../../../assets/images/vessels/country-flags/AG.png";
import flagCW from "../../../assets/images/vessels/country-flags/CW.png";
import flagAW from "../../../assets/images/vessels/country-flags/AW.png";
import flagBS from "../../../assets/images/vessels/country-flags/BS.png";
import flagBM from "../../../assets/images/vessels/country-flags/BM.png";
import flagBZ from "../../../assets/images/vessels/country-flags/BZ.png";
import flagBB from "../../../assets/images/vessels/country-flags/BB.png";
import flagCA from "../../../assets/images/vessels/country-flags/CA.png";
import flagKY from "../../../assets/images/vessels/country-flags/KY.png";
import flagCR from "../../../assets/images/vessels/country-flags/CR.png";
import flagCU from "../../../assets/images/vessels/country-flags/CU.png";
import flagDM from "../../../assets/images/vessels/country-flags/DM.png";
import flagDO from "../../../assets/images/vessels/country-flags/DO.png";
//import flagGP from '../../../assets/images/vessels/country-flags/GP.png';
import flagGD from "../../../assets/images/vessels/country-flags/GD.png";
import flagGL from "../../../assets/images/vessels/country-flags/GL.png";
import flagGT from "../../../assets/images/vessels/country-flags/GT.png";
import flagHN from "../../../assets/images/vessels/country-flags/HN.png";
import flagHT from "../../../assets/images/vessels/country-flags/HT.png";
import flagJM from "../../../assets/images/vessels/country-flags/JM.png";
import flagKN from "../../../assets/images/vessels/country-flags/KN.png";
import flagLC from "../../../assets/images/vessels/country-flags/LC.png";
import flagMX from "../../../assets/images/vessels/country-flags/MX.png";
import flagMQ from "../../../assets/images/vessels/country-flags/MQ.png";
import flagMS from "../../../assets/images/vessels/country-flags/MS.png";
import flagNI from "../../../assets/images/vessels/country-flags/NI.png";
import flagPA from "../../../assets/images/vessels/country-flags/PA.png";
import flagPR from "../../../assets/images/vessels/country-flags/PR.png";
import flagSV from "../../../assets/images/vessels/country-flags/SV.png";
//import flagPM from '../../../assets/images/vessels/country-flags/PM.png';
import flagTT from "../../../assets/images/vessels/country-flags/TT.png";
import flagTC from "../../../assets/images/vessels/country-flags/TC.png";
import flagVC from "../../../assets/images/vessels/country-flags/VC.png";
import flagVG from "../../../assets/images/vessels/country-flags/VG.png";
import flagVI from "../../../assets/images/vessels/country-flags/VI.png";
import flagAF from "../../../assets/images/vessels/country-flags/AF.png";
import flagSA from "../../../assets/images/vessels/country-flags/SA.png";
import flagBD from "../../../assets/images/vessels/country-flags/BD.png";
import flagBH from "../../../assets/images/vessels/country-flags/BH.png";
import flagBT from "../../../assets/images/vessels/country-flags/BT.png";
import flagCN from "../../../assets/images/vessels/country-flags/CN.png";
import flagTW from "../../../assets/images/vessels/country-flags/TW.png";
import flagLK from "../../../assets/images/vessels/country-flags/LK.png";
import flagIN from "../../../assets/images/vessels/country-flags/IN.png";
//import flagIR from '../../../assets/images/vessels/country-flags/IR.png';
import flagAZ from "../../../assets/images/vessels/country-flags/AZ.png";
import flagIQ from "../../../assets/images/vessels/country-flags/IQ.png";
import flagIL from "../../../assets/images/vessels/country-flags/IL.png";
import flagJP from "../../../assets/images/vessels/country-flags/JP.png";
import flagTM from "../../../assets/images/vessels/country-flags/TM.png";
import flagKZ from "../../../assets/images/vessels/country-flags/KZ.png";
import flagUZ from "../../../assets/images/vessels/country-flags/UZ.png";
import flagJO from "../../../assets/images/vessels/country-flags/JO.png";
import flagKR from "../../../assets/images/vessels/country-flags/KR.png";
import flagPS from "../../../assets/images/vessels/country-flags/PS.png";
import flagKP from "../../../assets/images/vessels/country-flags/KP.png";
import flagKW from "../../../assets/images/vessels/country-flags/KW.png";
//import flagLB from '../../../assets/images/vessels/country-flags/LB.png';
import flagKG from "../../../assets/images/vessels/country-flags/KG.png";
import flagMO from "../../../assets/images/vessels/country-flags/MO.png";
import flagMV from "../../../assets/images/vessels/country-flags/MV.png";
import flagMN from "../../../assets/images/vessels/country-flags/MN.png";
import flagNP from "../../../assets/images/vessels/country-flags/NP.png";
import flagOM from "../../../assets/images/vessels/country-flags/OM.png";
import flagPK from "../../../assets/images/vessels/country-flags/PK.png";
import flagQA from "../../../assets/images/vessels/country-flags/QA.png";
import flagSY from "../../../assets/images/vessels/country-flags/SY.png";
import flagAE from "../../../assets/images/vessels/country-flags/AE.png";
import flagTJ from "../../../assets/images/vessels/country-flags/TJ.png";
import flagYE from "../../../assets/images/vessels/country-flags/YE.png";
import flagHK from "../../../assets/images/vessels/country-flags/HK.png";
import flagBA from "../../../assets/images/vessels/country-flags/BA.png";
//import flagAQ from '../../../assets/images/vessels/country-flags/AQ.png';
import flagAU from "../../../assets/images/vessels/country-flags/AU.png";
import flagMM from "../../../assets/images/vessels/country-flags/MM.png";
import flagBN from "../../../assets/images/vessels/country-flags/BN.png";
import flagFM from "../../../assets/images/vessels/country-flags/FM.png";
import flagPW from "../../../assets/images/vessels/country-flags/PW.png";
import flagNZ from "../../../assets/images/vessels/country-flags/NZ.png";
import flagKH from "../../../assets/images/vessels/country-flags/KH.png";
import flagCX from "../../../assets/images/vessels/country-flags/CX.png";
import flagCK from "../../../assets/images/vessels/country-flags/CK.png";
import flagFJ from "../../../assets/images/vessels/country-flags/FJ.png";
import flagCC from "../../../assets/images/vessels/country-flags/CC.png";
import flagID from "../../../assets/images/vessels/country-flags/ID.png";
import flagKI from "../../../assets/images/vessels/country-flags/KI.png";
import flagLA from "../../../assets/images/vessels/country-flags/LA.png";
import flagMY from "../../../assets/images/vessels/country-flags/MY.png";
//import flagMP from '../../../assets/images/vessels/country-flags/MP.png';
import flagMH from "../../../assets/images/vessels/country-flags/MH.png";
//import flagNC from '../../../assets/images/vessels/country-flags/NC.png';
import flagNU from "../../../assets/images/vessels/country-flags/NU.png";
import flagNR from "../../../assets/images/vessels/country-flags/NR.png";
import flagPF from "../../../assets/images/vessels/country-flags/PF.png";
import flagPH from "../../../assets/images/vessels/country-flags/PH.png";
import flagPG from "../../../assets/images/vessels/country-flags/PG.png";
import flagPN from "../../../assets/images/vessels/country-flags/PN.png";
import flagSB from "../../../assets/images/vessels/country-flags/SB.png";
import flagAS from "../../../assets/images/vessels/country-flags/AS.png";
import flagWS from "../../../assets/images/vessels/country-flags/WS.png";
import flagSG from "../../../assets/images/vessels/country-flags/SG.png";
import flagTH from "../../../assets/images/vessels/country-flags/TH.png";
//import flagTO from '../../../assets/images/vessels/country-flags/TO.png';
//import flagTV from '../../../assets/images/vessels/country-flags/TV.png';
import flagVN from "../../../assets/images/vessels/country-flags/VN.png";
import flagVU from "../../../assets/images/vessels/country-flags/VU.png";
//import flagWF from '../../../assets/images/vessels/country-flags/WF.png';
import flagZA from "../../../assets/images/vessels/country-flags/ZA.png";
import flagAO from "../../../assets/images/vessels/country-flags/AO.png";
import flagDZ from "../../../assets/images/vessels/country-flags/DZ.png";
//import flagTF from '../../../assets/images/vessels/country-flags/TF.png';
//import flagIO from '../../../assets/images/vessels/country-flags/IO.png';
import flagBI from "../../../assets/images/vessels/country-flags/BI.png";
import flagBJ from "../../../assets/images/vessels/country-flags/BJ.png";
import flagBW from "../../../assets/images/vessels/country-flags/BW.png";
import flagCF from "../../../assets/images/vessels/country-flags/CF.png";
import flagCM from "../../../assets/images/vessels/country-flags/CM.png";
import flagCG from "../../../assets/images/vessels/country-flags/CG.png";
import flagKM from "../../../assets/images/vessels/country-flags/KM.png";
import flagCV from "../../../assets/images/vessels/country-flags/CV.png";
import flagCI from "../../../assets/images/vessels/country-flags/CI.png";
import flagDJ from "../../../assets/images/vessels/country-flags/DJ.png";
import flagEG from "../../../assets/images/vessels/country-flags/EG.png";
import flagET from "../../../assets/images/vessels/country-flags/ET.png";
import flagER from "../../../assets/images/vessels/country-flags/ER.png";
import flagGA from "../../../assets/images/vessels/country-flags/GA.png";
import flagGH from "../../../assets/images/vessels/country-flags/GH.png";
import flagGM from "../../../assets/images/vessels/country-flags/GM.png";
import flagGW from "../../../assets/images/vessels/country-flags/GW.png";
import flagGQ from "../../../assets/images/vessels/country-flags/GQ.png";
import flagGN from "../../../assets/images/vessels/country-flags/GN.png";
import flagBF from "../../../assets/images/vessels/country-flags/BF.png";
import flagKE from "../../../assets/images/vessels/country-flags/KE.png";
import flagLR from "../../../assets/images/vessels/country-flags/LR.png";
import flagLY from "../../../assets/images/vessels/country-flags/LY.png";
import flagLS from "../../../assets/images/vessels/country-flags/LS.png";
import flagMU from "../../../assets/images/vessels/country-flags/MU.png";
import flagMG from "../../../assets/images/vessels/country-flags/MG.png";
import flagML from "../../../assets/images/vessels/country-flags/ML.png";
import flagMZ from "../../../assets/images/vessels/country-flags/MZ.png";
import flagMR from "../../../assets/images/vessels/country-flags/MR.png";
import flagMW from "../../../assets/images/vessels/country-flags/MW.png";
import flagNE from "../../../assets/images/vessels/country-flags/NE.png";
import flagNG from "../../../assets/images/vessels/country-flags/NG.png";
import flagNA from "../../../assets/images/vessels/country-flags/NA.png";
//import flagRE from '../../../assets/images/vessels/country-flags/RE.png';
import flagRW from "../../../assets/images/vessels/country-flags/RW.png";
import flagSD from "../../../assets/images/vessels/country-flags/SD.png";
import flagSN from "../../../assets/images/vessels/country-flags/SN.png";
import flagSC from "../../../assets/images/vessels/country-flags/SC.png";
//import flagSH from '../../../assets/images/vessels/country-flags/SH.png';
import flagSO from "../../../assets/images/vessels/country-flags/SO.png";
import flagSL from "../../../assets/images/vessels/country-flags/SL.png";
import flagST from "../../../assets/images/vessels/country-flags/ST.png";
import flagSZ from "../../../assets/images/vessels/country-flags/SZ.png";
import flagTD from "../../../assets/images/vessels/country-flags/TD.png";
import flagTG from "../../../assets/images/vessels/country-flags/TG.png";
import flagTN from "../../../assets/images/vessels/country-flags/TN.png";
import flagTZ from "../../../assets/images/vessels/country-flags/TZ.png";
import flagUG from "../../../assets/images/vessels/country-flags/UG.png";
import flagCD from "../../../assets/images/vessels/country-flags/CD.png";
import flagZM from "../../../assets/images/vessels/country-flags/ZM.png";
import flagZW from "../../../assets/images/vessels/country-flags/ZW.png";
import flagAR from "../../../assets/images/vessels/country-flags/AR.png";
import flagBR from "../../../assets/images/vessels/country-flags/BR.png";
import flagBO from "../../../assets/images/vessels/country-flags/BO.png";
import flagCL from "../../../assets/images/vessels/country-flags/CL.png";
import flagCO from "../../../assets/images/vessels/country-flags/CO.png";
import flagEC from "../../../assets/images/vessels/country-flags/EC.png";
//import flagUK from '../../../assets/images/vessels/country-flags/UK.png';
//import flagGF from '../../../assets/images/vessels/country-flags/GF.png';
import flagGY from "../../../assets/images/vessels/country-flags/GY.png";
import flagPY from "../../../assets/images/vessels/country-flags/PY.png";
import flagPE from "../../../assets/images/vessels/country-flags/PE.png";
import flagSR from "../../../assets/images/vessels/country-flags/SR.png";
import flagUY from "../../../assets/images/vessels/country-flags/UY.png";
import flagVE from "../../../assets/images/vessels/country-flags/VE.png";

//Constant import
import { SHIP_COUNTRY } from "../../constants/vesselCountry.js";

export const VesselFlagAisMid = (aisMid) => {
  if (!aisMid) {
    return;
  }

  const shortCode = SHIP_COUNTRY[aisMid]?.ShortCode;
  return VesselFlagShortCode(shortCode)
};

export const VesselFlagShortCode = (shortCode) => {
  const mapping = {
    AL: flagAL,
    AD: flagAD,
    AT: flagAT,
    PT: flagPT,
    BE: flagBE,
    BY: flagBY,
    BG: flagBG,
    VA: flagVA,
    CY: flagCY,
    DE: flagDE,
    GE: flagGE,
    MD: flagMD,
    MT: flagMT,
    AM: flagAM,
    DK: flagDK,
    ES: flagES,
    FR: flagFR,
    FI: flagFI,
    FO: flagFO,
    GB: flagGB,
    GI: flagGI,
    GR: flagGR,
    HR: flagHR,
    MA: flagMA,
    HU: flagHU,
    NL: flagNL,
    IT: flagIT,
    IE: flagIE,
    IS: flagIS,
    LI: flagLI,
    LU: flagLU,
    MC: flagMC,
    NO: flagNO,
    PL: flagPL,
    ME: flagME,
    RO: flagRO,
    SE: flagSE,
    SK: flagSK,
    SM: flagSM,
    CH: flagCH,
    CZ: flagCZ,
    TR: flagTR,
    UA: flagUA,
    RU: flagRU,
    MK: flagMK,
    LV: flagLV,
    EE: flagEE,
    LT: flagLT,
    SI: flagSI,
    RS: flagRS,
    AI: flagAI,
    US: flagUS,
    AG: flagAG,
    CW: flagCW,
    AW: flagAW,
    BS: flagBS,
    BM: flagBM,
    BZ: flagBZ,
    BB: flagBB,
    CA: flagCA,
    KY: flagKY,
    CR: flagCR,
    CU: flagCU,
    DM: flagDM,
    DO: flagDO,
    GD: flagGD,
    GL: flagGL,
    GT: flagGT,
    HN: flagHN,
    HT: flagHT,
    JM: flagJM,
    KN: flagKN,
    LC: flagLC,
    MX: flagMX,
    MQ: flagMQ,
    MS: flagMS,
    NI: flagNI,
    PA: flagPA,
    PR: flagPR,
    SV: flagSV,
    TT: flagTT,
    TC: flagTC,
    VC: flagVC,
    VG: flagVG,
    VI: flagVI,
    AF: flagAF,
    SA: flagSA,
    BD: flagBD,
    BH: flagBH,
    BT: flagBT,
    CN: flagCN,
    TW: flagTW,
    LK: flagLK,
    IN: flagIN,
    AZ: flagAZ,
    IQ: flagIQ,
    IL: flagIL,
    JP: flagJP,
    TM: flagTM,
    KZ: flagKZ,
    UZ: flagUZ,
    JO: flagJO,
    KR: flagKR,
    PS: flagPS,
    KP: flagKP,
    KW: flagKW,
    KG: flagKG,
    MO: flagMO,
    MV: flagMV,
    MN: flagMN,
    NP: flagNP,
    OM: flagOM,
    PK: flagPK,
    QA: flagQA,
    SY: flagSY,
    AE: flagAE,
    TJ: flagTJ,
    YE: flagYE,
    HK: flagHK,
    BA: flagBA,
    AU: flagAU,
    MM: flagMM,
    BN: flagBN,
    FM: flagFM,
    PW: flagPW,
    NZ: flagNZ,
    KH: flagKH,
    CX: flagCX,
    CK: flagCK,
    FJ: flagFJ,
    CC: flagCC,
    ID: flagID,
    KI: flagKI,
    LA: flagLA,
    MY: flagMY,
    MH: flagMH,
    NU: flagNU,
    NR: flagNR,
    PF: flagPF,
    PH: flagPH,
    PG: flagPG,
    PN: flagPN,
    SB: flagSB,
    AS: flagAS,
    WS: flagWS,
    SG: flagSG,
    TH: flagTH,
    VN: flagVN,
    VU: flagVU,
    ZA: flagZA,
    AO: flagAO,
    DZ: flagDZ,
    BI: flagBI,
    BJ: flagBJ,
    BW: flagBW,
    CF: flagCF,
    CM: flagCM,
    CG: flagCG,
    KM: flagKM,
    CV: flagCV,
    CI: flagCI,
    DJ: flagDJ,
    EG: flagEG,
    ET: flagET,
    ER: flagER,
    GA: flagGA,
    GH: flagGH,
    GM: flagGM,
    GW: flagGW,
    GQ: flagGQ,
    GN: flagGN,
    BF: flagBF,
    KE: flagKE,
    LR: flagLR,
    LY: flagLY,
    LS: flagLS,
    MU: flagMU,
    MG: flagMG,
    ML: flagML,
    MZ: flagMZ,
    MR: flagMR,
    MW: flagMW,
    NE: flagNE,
    NG: flagNG,
    NA: flagNA,
    RW: flagRW,
    SD: flagSD,
    SN: flagSN,
    SC: flagSC,
    SO: flagSO,
    SL: flagSL,
    ST: flagST,
    SZ: flagSZ,
    TD: flagTD,
    TG: flagTG,
    TN: flagTN,
    TZ: flagTZ,
    UG: flagUG,
    CD: flagCD,
    ZM: flagZM,
    ZW: flagZW,
    AR: flagAR,
    BR: flagBR,
    BO: flagBO,
    CL: flagCL,
    CO: flagCO,
    EC: flagEC,
    GY: flagGY,
    PY: flagPY,
    PE: flagPE,
    SR: flagSR,
    UY: flagUY,
    VE: flagVE,
  };

  return mapping[shortCode];
};

