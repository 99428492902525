import React, { useEffect } from "react";
import * as PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import { COLORS } from "../../../../constants/colors";

const PieChartContainer = ({
  isNoElectric,
  isNoData,
  isLoading,
  limit,
  sizeRatio = 1,
  isInitialLoading,
  electricData,
  title,
}) => {
  const chartId = `pieChartdiv-${title}`;
  const ElectricPieChart = ({ displayData, sizeRatio }) => {
    am5.addLicense(process.env.AMCHARTS_LICENSE);
    useEffect(() => {
      const root = am5.Root.new(chartId, {
        useSafeResolution: false,
      });
      const pieChart = root.container.children.push(
        am5percent.PieChart.new(root, {
          startAngle: 160,
          endAngle: 380,
        })
      );
      const series = pieChart.series.push(
        am5percent.PieSeries.new(root, {
          categoryField: "name",
          valueField: "value",
          startAngle: 140,
          endAngle: 400,
          innerRadius: am5.percent(70),
          fill: am5.color(COLORS.lightBlue),
        })
      );
      series.slices.template.set("tooltipText", ""); //disable tooltip
      series.slices.template.set("toggleKey", "none"); //disable hover
      series.get("colors").set("colors", [am5.color(COLORS.lightBlue), am5.color(COLORS.monitoringGaugeBackground)]);

      //set labels
      pieChart.seriesContainer.children.push(
        am5.Label.new(root, {
          textAlign: "center",
          centerX: am5.p50,
          centerY: am5.p50,
          text: `[bold ${am5.color(COLORS.white)}] ${displayData[0].value}kw[/]`,
        })
      );
      pieChart.seriesContainer.children.push(
        am5.Label.new(root, {
          textAlign: "center",
          centerX: am5.percent(200 * sizeRatio),
          centerY: am5.percent(-120 * sizeRatio),
          text: `[bold ${am5.color(COLORS.lightBlue)}] 0[/]`,
        })
      );
      pieChart.seriesContainer.children.push(
        am5.Label.new(root, {
          textAlign: "center",
          centerX: am5.p0,
          centerY: am5.percent(-120 * sizeRatio),
          text: `[bold ${am5.color(COLORS.lightBlue)}] ${limit}[/]`,
        })
      );
      series.data.setAll([displayData[0], { name: "load", value: limit - displayData[0].value}]);

      return () => {
        root.dispose();
      };
    }, []);

    return (
      <div
        id={chartId}
        style={{
          height: `${210 * sizeRatio}px`,
          width: `${220 * sizeRatio}px`,
        }}
      />
    );
  };

  const Display = () => {
    switch (true) {
      case isInitialLoading:
        return <CircularProgress />;
      case isNoElectric:
        return (
          <ElectricPieChart
            displayData={[
              { name: "load", value: 0 },
              { name: "unload", value: 100 },
            ]}
            sizeRatio={sizeRatio}
          />
        );
      case isNoData:
        return (
          <ElectricPieChart
            displayData={[
              { name: "load", value: 0 },
              { name: "unload", value: limit },
            ]}
            sizeRatio={sizeRatio}
          />
        );
      case electricData?.length > 0:
        return <ElectricPieChart displayData={electricData} sizeRatio={sizeRatio} />;
      case isLoading:
        return <CircularProgress />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Display />
    </React.Fragment>
  );
};

PieChartContainer.propTypes = {
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  limit: PropTypes.number.isRequired,
  condition: PropTypes.bool,
  type: PropTypes.string,
  sizeRatio: PropTypes.number,
  isNoData: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNoElectric: PropTypes.bool,
  isInitialLoading: PropTypes.bool,
};

export default PieChartContainer;
