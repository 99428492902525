import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "../../../../../assets/images/alarmIcon/close-icon.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  inputEndAdornment: {
    width: "30px",
  },
  closeIcon: {
    cursor: "pointer",
  },
});

export const EndAdornmentContent = React.forwardRef((props,ref) => {
  const { index, target, updateFunction, secondTarget = [], secondUpdateFunction } = props;
  const classes = useStyles();
  const onClickHandler = () => {
    const returnValue = [...target].filter((v, i) => i !== index);
    updateFunction(returnValue);
    if (secondTarget.length > 0) {
      const returnValue = [...secondTarget]
        .map((v, i) => (i === index ? false : v))
        .filter((v, i) => i !== index);
      secondUpdateFunction(returnValue);
    }
  };
  return (
    <div ref={ref} className={classes.inputEndAdornment}>
      <img
        className={classes.closeIcon}
        src={CloseIcon}
        onClick={() => {
          onClickHandler();
        }}
      />
    </div>
  );
});

EndAdornmentContent.propTypes = {
  index: PropTypes.number,
  target: PropTypes.array,
  updateFunction: PropTypes.func,
  secondTarget: PropTypes.array,
  secondUpdateFunction: PropTypes.func,
};
