const ENGINE_CHART_TYPE = {
  ENGINE_LOAD: "engine_load",
  ENGINE_DEVIATION: "engine_dev_exhaust_temp_trend",
  ENGINE_WATER: "engine_water_trend",
  ENGINE_EXHAUST: "engine_exhaust_temp_trend",
  ENGINE_TEMP: "engine_temp_trend",
  ENGINE_PRESS: "engine_press_trend",
};

const ENGINE_POSITION = {
  PORT: "Port",
  STARBOARD: "Starboard",
  CENTER: "Center",
};

const ENGINE_TYPE = {
  STRAIGHT: "S",
  V: "V",
};

const engineTrendGraphLines = [
  //for engine load
  {
    name: "dateTime",
    keyName: "dateTime",
    color: "none",
    chartType: ENGINE_CHART_TYPE.ENGINE_LOAD,
  },
  {
    name: "ME Speed",
    color: "#ff6388",
    enable: true,
    keyName: "meSpeed",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_LOAD,
  },
  {
    name: "ME Output",
    color: "#fff08b",
    enable: true,
    keyName: "meOutput",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_LOAD,
  },
  {
    name: "Shaft Torque",
    color: "#92f19e",
    enable: true,
    keyName: "shaftTorque",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_LOAD,
  },
  {
    name: "ME FO Flow",
    color: "#87a6c1",
    enable: true,
    keyName: "mefoFlow",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_LOAD,
  },
  {
    name: "ME Cpp Blade Angle",
    color: "#6c5de7",
    enable: true,
    keyName: "meCppBladeAngle",
    shortKeyName: "meCppAngle",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_LOAD,
  },
  {
    name: "Ship Speed",
    color: "#4fa8de",
    enable: true,
    keyName: "shipSpeed",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_LOAD,
  },
  {
    name: "Shaft Revolution",
    color: "#57cee1",
    enable: true,
    keyName: "shaftRevolution",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_LOAD,
  },

  //for cylinder deviation
  {
    name: "dateTime",
    keyName: "dateTime",
    color: "none",
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  // {
  //   name: "ME Ave Exh Gas Temp",
  //   color: "#e74293",
  //   enable: true,
  //   keyName: "meAveExhGasTemp",
  //   shortKeyName: "meAveExhGas",
  //   enableColorPicker: "none",
  //   enableVisibilityIcon: "hidden",
  //   selected: false,
  //   chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  // },
  {
    name: "ME Exh Gas A1 Dev Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasA1DevTemp",
    shortKeyName: "meExhGasA1",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A2 Dev Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasA2DevTemp",
    shortKeyName: "meExhGasA2",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A3 DevTemp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasA3DevTemp",
    shortKeyName: "meExhGasA3",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A4 Dev Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasA4DevTemp",
    shortKeyName: "meExhGasA4",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A5 Dev Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasA5DevTemp",
    shortKeyName: "meExhGasA5",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A6 Dev Temp",
    color: "#56ebc1",
    enable: true,
    keyName: "meExhGasA6DevTemp",
    shortKeyName: "meExhGasA6",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A7 Dev Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasA7DevTemp",
    shortKeyName: "meExhGasA7",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A8 Dev Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasA8DevTemp",
    shortKeyName: "meExhGasA8",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A9 Dev Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasA9DevTemp",
    shortKeyName: "meExhGasA9",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A10 Dev Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasA10DevTemp",
    shortKeyName: "meExhGasA10",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A11 Dev Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasA11DevTemp",
    shortKeyName: "meExhGasA11",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A12 Dev Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasA12DevTemp",
    shortKeyName: "meExhGasA12",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A13 Dev Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasA13DevTemp",
    shortKeyName: "meExhGasA13",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A14 Dev Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasA14DevTemp",
    shortKeyName: "meExhGasA14",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A15 Dev Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasA15DevTemp",
    shortKeyName: "meExhGasA15",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas A16 Dev Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasA16DevTemp",
    shortKeyName: "meExhGasA16",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B1 Dev Temp",
    color: "#911eb4",
    enable: true,
    keyName: "meExhGasB1DevTemp",
    shortKeyName: "meExhGasB1",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B2 Dev Temp",
    color: "#e6beff",
    enable: true,
    keyName: "meExhGasB2DevTemp",
    shortKeyName: "meExhGasB2",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B3 Dev Temp",
    color: "#f553ec",
    enable: true,
    keyName: "meExhGasB3DevTemp",
    shortKeyName: "meExhGasB3",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B4 Dev Temp",
    color: "#0a84e3",
    enable: true,
    keyName: "meExhGasB4DevTemp",
    shortKeyName: "meExhGasB4",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B5 Dev Temp",
    color: "#75b9ff",
    enable: true,
    keyName: "meExhGasB5DevTemp",
    shortKeyName: "meExhGasB5",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B6 Dev Temp",
    color: "#83f8ff",
    enable: true,
    keyName: "meExhGasB6DevTemp",
    shortKeyName: "meExhGasB6",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B7 Dev Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasB7DevTemp",
    shortKeyName: "meExhGasB7",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B8 Dev Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasB8DevTemp",
    shortKeyName: "meExhGasB8",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B9 Dev Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasB9DevTemp",
    shortKeyName: "meExhGasB9",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B10 Dev Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasB10DevTemp",
    shortKeyName: "meExhGasB10",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B11 Dev Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasB11DevTemp",
    shortKeyName: "meExhGasB11",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B12 Dev Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasB12DevTemp",
    shortKeyName: "meExhGasB12",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B13 Dev Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasB13DevTemp",
    shortKeyName: "meExhGasB13",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B14 Dev Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasB14DevTemp",
    shortKeyName: "meExhGasB14",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B15 Dev Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasB15DevTemp",
    shortKeyName: "meExhGasB15",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  {
    name: "ME Exh Gas B16 Dev Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasB16DevTemp",
    shortKeyName: "meExhGasB16",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  },
  // {
  //   name: "ME FO In Press",
  //   color: "#f48e74",
  //   enable: true,
  //   keyName: "mefoInPress",
  //   shortKeyName: "mefoInPress",
  //   enableColorPicker: "none",
  //   enableVisibilityIcon: "hidden",
  //   selected: false,
  //   chartType: ENGINE_CHART_TYPE.ENGINE_DEVIATION,
  // },

  //for engine water
  {
    name: "dateTime",
    keyName: "dateTime",
    color: "none",
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },
  {
    name: "ME HT CFW In Temp",
    color: "#b4affb",
    enable: true,
    keyName: "mehtcfwInTemp",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },
  {
    name: "ME HT CFW Out Temp",
    color: "#8f81ff",
    enable: true,
    keyName: "mehtcfwOutTemp",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },
  {
    name: "Sea Water Temp",
    color: "#66c0f6",
    enable: true,
    keyName: "seaWaterTemp",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },
  {
    name: "Shaft Revolution",
    color: "#84eeff",
    enable: true,
    keyName: "shaftRevolution",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },
  {
    name: "ME LT CFW In Press",
    color: "#56ebc1",
    enable: true,
    keyName: "meltcfwInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },
  {
    name: "ME LT CFW In Temp",
    color: "#911eb4",
    enable: true,
    keyName: "meltcfwInTemp",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },
  {
    name: "ME LO In Press",
    color: "#e6beff",
    enable: true,
    keyName: "meloInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },
  {
    name: "ME LO In Temp",
    color: "#f553ec",
    enable: true,
    keyName: "meloInTemp",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },
  {
    name: "ME HT CFW In Press",
    color: "#0a84e3",
    enable: true,
    keyName: "mehtcfwInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_WATER,
  },

  //for cylinder (average)
  {
    name: "dateTime",
    keyName: "dateTime",
    color: "none",
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Ave Exh Gas Temp",
    color: "#e74293",
    enable: true,
    keyName: "meAveExhGasTemp",
    shortKeyName: "meAveExhGas",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A1 Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasA1Temp",
    shortKeyName: "meExhGasA1",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A2 Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasA2Temp",
    shortKeyName: "meExhGasA2",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A3 Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasA3Temp",
    shortKeyName: "meExhGasA3",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A4 Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasA4Temp",
    shortKeyName: "meExhGasA4",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A5 Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasA5Temp",
    shortKeyName: "meExhGasA5",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A6 Temp",
    color: "#56ebc1",
    enable: true,
    keyName: "meExhGasA6Temp",
    shortKeyName: "meExhGasA6",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A7 Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasA7Temp",
    shortKeyName: "meExhGasA7",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A8 Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasA8Temp",
    shortKeyName: "meExhGasA8",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A9 Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasA9Temp",
    shortKeyName: "meExhGasA9",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A10 Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasA10Temp",
    shortKeyName: "meExhGasA10",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A11 Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasA11Temp",
    shortKeyName: "meExhGasA11",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A12 Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasA12Temp",
    shortKeyName: "meExhGasA12",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A13 Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasA13Temp",
    shortKeyName: "meExhGasA13",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A14 Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasA14Temp",
    shortKeyName: "meExhGasA14",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A15 Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasA15Temp",
    shortKeyName: "meExhGasA15",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas A16 Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasA16Temp",
    shortKeyName: "meExhGasA16",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B1 Temp",
    color: "#911eb4",
    enable: true,
    keyName: "meExhGasB1Temp",
    shortKeyName: "meExhGasB1",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B2 Temp",
    color: "#e6beff",
    enable: true,
    keyName: "meExhGasB2Temp",
    shortKeyName: "meExhGasB2",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B3 Temp",
    color: "#f553ec",
    enable: true,
    keyName: "meExhGasB3Temp",
    shortKeyName: "meExhGasB3",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B4 Temp",
    color: "#0a84e3",
    enable: true,
    keyName: "meExhGasB4Temp",
    shortKeyName: "meExhGasB4",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B5 Temp",
    color: "#75b9ff",
    enable: true,
    keyName: "meExhGasB5Temp",
    shortKeyName: "meExhGasB5",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B6 Temp",
    color: "#83f8ff",
    enable: true,
    keyName: "meExhGasB6Temp",
    shortKeyName: "meExhGasB6",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B7 Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasB7Temp",
    shortKeyName: "meExhGasB7",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B8 Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasB8Temp",
    shortKeyName: "meExhGasB8",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B9 Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasB9Temp",
    shortKeyName: "meExhGasB9",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B10 Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasB10Temp",
    shortKeyName: "meExhGasB10",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B11 Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasB11Temp",
    shortKeyName: "meExhGasB11",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B12 Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meExhGasB12Temp",
    shortKeyName: "meExhGasB12",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B13 Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meExhGasB13Temp",
    shortKeyName: "meExhGasB13",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B14 Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meExhGasB14Temp",
    shortKeyName: "meExhGasB14",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B15 Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meExhGasB15Temp",
    shortKeyName: "meExhGasB15",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME Exh Gas B16 Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meExhGasB16Temp",
    shortKeyName: "meExhGasB16",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },
  {
    name: "ME FO In Press",
    color: "#f48e74",
    enable: true,
    keyName: "mefoInPress",
    shortKeyName: "mefoInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_EXHAUST,
  },

  //for Trend Temperature
  {
    name: "dateTime",
    keyName: "dateTime",
    color: "none",
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME FO In Temp",
    color: "#e74293",
    enable: true,
    keyName: "mefoInTemp",
    shortKeyName: "mefoInTemp",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME LO In Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meloInTemp",
    shortKeyName: "meloInTemp",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME Exh Gas Mean Temp",
    color: "#F7AD2E",
    enable: true,
    keyName: "meAveExhGasTemp",
    shortKeyName: "meExhGasMean",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME Jacket C FW In Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "mehtcfwInTemp",
    shortKeyName: "mehtcfwInTemp",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #1 Temp",
    color: "#4fa8de",
    enable: true,
    keyName: "meJacketCfwA1OutTemp",
    shortKeyName: "meJKTCfwA1Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #2 Temp",
    color: "#56ebc1",
    enable: true,
    keyName: "meJacketCfwA2OutTemp",
    shortKeyName: "meJKTCfwA2Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #3 Temp",
    color: "#66ff99",
    enable: true,
    keyName: "meJacketCfwA3OutTemp",
    shortKeyName: "meJKTCfwA3Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #4 Temp",
    color: "#e6beff",
    enable: true,
    keyName: "meJacketCfwA4OutTemp",
    shortKeyName: "meJKTCfwA4Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #5 Temp",
    color: "#f553ec",
    enable: true,
    keyName: "meJacketCfwA5OutTemp",
    shortKeyName: "meJKTCfwA5Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #6 Temp",
    color: "#0a84e3",
    enable: true,
    keyName: "meJacketCfwA6OutTemp",
    shortKeyName: "meJKTCfwA6Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #7 Temp",
    color: "#008cb4",
    enable: true,
    keyName: "meJacketCfwA7OutTemp",
    shortKeyName: "meJKTCfwA7Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #8 Temp",
    color: "#0061fd",
    enable: true,
    keyName: "meJacketCfwA8OutTemp",
    shortKeyName: "meJKTCfwA8Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #9 Temp",
    color: "#4d22b2",
    enable: true,
    keyName: "meJacketCfwA9OutTemp",
    shortKeyName: "meJKTCfwA9Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #10 Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meJacketCfwA10OutTemp",
    shortKeyName: "meJKTCfwA10Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #11 Temp",
    color: "#7a219e",
    enable: true,
    keyName: "meJacketCfwA11OutTemp",
    shortKeyName: "meJKTCfwA11Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #12 Temp",
    color: "#ff00f2",
    enable: true,
    keyName: "meJacketCfwA12OutTemp",
    shortKeyName: "meJKTCfwA12Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #13 Temp",
    color: "#e74293",
    enable: true,
    keyName: "meJacketCfwA13OutTemp",
    shortKeyName: "meJKTCfwA13Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #14 Temp",
    color: "#e6927a",
    enable: true,
    keyName: "meJacketCfwA14OutTemp",
    shortKeyName: "meJKTCfwA14Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #15 Temp",
    color: "#00a1d8",
    enable: true,
    keyName: "meJacketCfwA15OutTemp",
    shortKeyName: "meJKTCfwA15Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME C FW #16 Temp",
    color: "#0a84e3",
    enable: true,
    keyName: "meJacketCfwA16OutTemp",
    shortKeyName: "meJKTCfwA16Out",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #1 Piston C Oil Out Temp",
    color: "#5e30eb",
    enable: true,
    keyName: "meA1PistonCoolingOilOutTemp",
    shortKeyName: "meA1PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #2 Piston C Oil Out Temp",
    color: "#a29bfe",
    enable: true,
    keyName: "meA2PistonCoolingOilOutTemp",
    shortKeyName: "meA2PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #3 Piston C Oil Out Temp",
    color: "#83f8ff",
    enable: true,
    keyName: "meA3PistonCoolingOilOutTemp",
    shortKeyName: "meA3PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #4 Piston C Oil Out Temp",
    color: "#f553ec",
    enable: true,
    keyName: "meA4PistonCoolingOilOutTemp",
    shortKeyName: "meA4PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #5 Piston C Oil Out Temp",
    color: "#ee719e",
    enable: true,
    keyName: "meA5PistonCoolingOilOutTemp",
    shortKeyName: "meA5PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #6 Piston C Oil Out Temp",
    color: "#f5cc7c",
    enable: true,
    keyName: "meA6PistonCoolingOilOutTemp",
    shortKeyName: "meA6PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #7 Piston C Oil Out Temp",
    color: "#83f8ff",
    enable: true,
    keyName: "meA7PistonCoolingOilOutTemp",
    shortKeyName: "meA7PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #8 Piston C Oil Out Temp",
    color: "#01c7fc",
    enable: true,
    keyName: "meA8PistonCoolingOilOutTemp",
    shortKeyName: "meA8PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #9 Piston C Oil Out Temp",
    color: "#3a87fd",
    enable: true,
    keyName: "meA9PistonCoolingOilOutTemp",
    shortKeyName: "meA9PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #10 Piston C Oil Out Temp",
    color: "#6c5de7",
    enable: true,
    keyName: "meA10PistonCoolingOilOutTemp",
    shortKeyName: "meA10PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #11 Piston C Oil Out Temp",
    color: "#be38f3",
    enable: true,
    keyName: "meA11PistonCoolingOilOutTemp",
    shortKeyName: "meA11PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #12 Piston C Oil Out Temp",
    color: "#fb84f4",
    enable: true,
    keyName: "meA12PistonCoolingOilOutTemp",
    shortKeyName: "meA12PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #13 Piston C Oil Out Temp",
    color: "#fc78a8",
    enable: true,
    keyName: "meA13PistonCoolingOilOutTemp",
    shortKeyName: "meA13PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #14 Piston C Oil Out Temp",
    color: "#f9d9b6",
    enable: true,
    keyName: "meA14PistonCoolingOilOutTemp",
    shortKeyName: "meA14PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #15 Piston C Oil Out Temp",
    color: "#b5e17e",
    enable: true,
    keyName: "meA15PistonCoolingOilOutTemp",
    shortKeyName: "meA15PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },
  {
    name: "ME #16 Piston C Oil Out Temp",
    color: "#57cee1",
    enable: true,
    keyName: "meA16PistonCoolingOilOutTemp",
    shortKeyName: "meA16PISTCoOut",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_TEMP,
  },

  //for Trend Pressure
  {
    name: "dateTime",
    keyName: "dateTime",
    color: "none",
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "ME TC LO In Press",
    color: "#0ba67a",
    enable: true,
    keyName: "meTcLoInPress",
    shortKeyName: "meTcLoInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "ME Start Air Press",
    color: "#fc78a8",
    enable: true,
    keyName: "meStartAirPress",
    shortKeyName: "meStartAirPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "ME Control Air Press",
    color: "#F7AD2E",
    enable: true,
    keyName: "meContAirPress",
    shortKeyName: "meContAirPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "ME Exh V SPR Air Press",
    color: "#66ff99",
    enable: true,
    keyName: "meExhValveSpringAirPress",
    shortKeyName: "meExhVSAirPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "Air Cooler SW In Press",
    color: "#6752a3",
    enable: true,
    keyName: "airCswInPress",
    shortKeyName: "airCswInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "Piston C Oil In Press",
    color: "#d223c9",
    enable: true,
    keyName: "mePistonCoolingOilInPress",
    shortKeyName: "mePistonCOInP",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "ME SCAV Air Press",
    color: "#b92d5d",
    enable: true,
    keyName: "meScavAirPress",
    shortKeyName: "meScavAirInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "ME FO In Press",
    color: "#b2634d",
    enable: true,
    keyName: "mefoInPress",
    shortKeyName: "mefoInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "ME LO In Press",
    color: "#18dca5",
    enable: true,
    keyName: "meloInPress",
    shortKeyName: "meloInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
  {
    name: "ME Jacket C FW In Press",
    color: "#008cb4",
    enable: true,
    keyName: "mehtcfwInPress",
    shortKeyName: "mehtcfwInPress",
    enableColorPicker: "none",
    enableVisibilityIcon: "hidden",
    selected: false,
    chartType: ENGINE_CHART_TYPE.ENGINE_PRESS,
  },
];

export { engineTrendGraphLines, ENGINE_CHART_TYPE, ENGINE_POSITION, ENGINE_TYPE };
