import React from "react";
import { useMsal } from "@azure/msal-react";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

//Style imports
import Button from "@mui/material/Button";
import { logger } from "../../api/logger";

const useStyles = makeStyles(() => ({
  inqsqbtn: {
    marginTop: "10px",
    whiteSpace: "pre-line",
    backgroundColor: "#9b0bab",
    color: "white",
    width: "200px",
    height: "37px",
    fontSize: "14.5px",
    fontFamily: "HiraginoSans-W3",
    "&:hover": {
      background: "rgba( 0, 0, 0, 0.8 )",
      filter: "brightness(1.2)",
    },
    textTransform: "none",
    borderRadius: "5px",
  },
}));

export const LoginAzure = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  const loginRequest = {
    scopes: ["profile"],
  };

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      logger.error(`handleLogin error: ${e}`);
    });
  };
  return (
    <Button className={classes.inqsqbtn} component="div" onClick={handleLogin}>
      &nbsp;サインイン / Sign in
    </Button>
  );
};
