import { useEffect } from "react";

/**
 * This function detects click without ref or ref contains object.
 * @param {Array<ref>} ref
 * @param {Function} handler
 */
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (e) => {
      if (
        ref
          .filter((d) => d?.current !== null)
          .some((r) => !r.current || r.current.contains(e.target))
      )
        return;
      handler(e);
    };

    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};
