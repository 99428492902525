import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import { useCargoSensorsLatestData } from "../../../cargo/useLatestCargoData";

import { Pitch } from "../../../cargo/pitchAndRoll/pitch";
import { Roll } from "../../../cargo/pitchAndRoll/roll";

const Cargo = ({ taggedLatestData, classes }) => {
  const { pitchData, rollData } = useCargoSensorsLatestData(taggedLatestData);

  return (
    <Grid container className={`${classes.components}`} style={{ position: "relative", width: "100%", display: "block" }}>
      <Grid item xs={12}>
        <Pitch data={pitchData} />
      </Grid>
      <Grid item xs={12}>
        <Roll data={rollData} />
      </Grid>
    </Grid>
  );
}

Cargo.propTypes = {
  taggedLatestData: PropTypes.array,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export default Cargo;