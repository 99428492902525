import React, { useCallback, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import localizationKeys from "../../../i18n/localizationKeys.js";
import { Grid, Typography, useMediaQuery, useTheme  } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from "react-redux";
import {
  getDataDuration,
  getDurationOption,
  getElectricDataDuration,
  getSepDataDuration,
  setDurationOption,
  setElectricStartDate,
  setElectricEndDate,
  setEndDate,
  setSepStartDate,
  setSepEndDate,
  setStartDate,
  getCustomDataDuration,
  setCustomDataStartDate,
  setCustomDataEndDate,
  resetDurationOption,
} from "../../../model/slice/dataDurationSlice.js";
import { DURATION, DURATION_OPTION_TYPE } from "../calendarDuration/durationOption.js";
import { ENGINE_POSITION } from "../../../constants/trendGraph/enginesLine.js";
import { DurationPicker } from "../../durationPicker/durationPicker.jsx";
import { selectedChartType } from "../../../model/slice/chartTypeSlice.js";

const baseDateTimeLabel = {
  fontSize: "10px",
  fontFamily: "Inter",
  fontWeight: 300,
};

const useStyles = ({ isDisabled }) =>
  makeStyles((theme) => ({
    durationDateLabel: {
      color: isDisabled ? "#525252" : "#19b2ab",
      fontFamily: "Inter",
      fontSize: "14px",
      "user-select": "none",
      "-moz-user-select": "none",
      "-webkit-user-select": "none",
    },
    radioLabel: {
      color: isDisabled ? "#525252" : "#ffffff",
      fontFamily: "Inter",
      "user-select": "none",
      "-moz-user-select": "none",
      "-webkit-user-select": "none",
      "& .MuiTypography-body1": {
        fontSize: "10px !important",
      },
    },
    radio: {
      color: isDisabled ? "#525252 !important" : "#19b2ab !important",
    },
    formControl: {
      color: isDisabled ? "#525252" : "#ffffff",
      fontSize: "10px",
      fontWeight: 300,
      fontStretch: "normal",
      fontStyle: "normal",
    },
    durationDateLabelPosition: {
      justifyContent: "center",
      display: "flex",
    },
    dateTimePickerContainer: {
      display: "flex",
      justifyContent: "center",
      flexFlow: "column",
      alignItems: "center",
    },
    disabledDateTimeLabel: {
      ...baseDateTimeLabel,
      color: "#525252",
    },
    baseDateTimeLabel: {
      ...baseDateTimeLabel,
      color: "#ffffff",
    },
    label: {
      color: isDisabled ? "#525252" : "#ffffff",
      fontFamily: "Inter",
      fontSize: "10px",
    },
    tbodyStyle: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    radioGroupContainer: {
      display: "flex",
      justifyContent: "center",
    },
  }));

const DataDuration = React.memo(({ type, isDisabled, t, position, vesselId, currentVessel }) => {
  const classes = useStyles({ isDisabled })();
  const dispatch = useDispatch();
  const dataDurationState = useSelector(getDataDuration);
  const electricDurationState = useSelector(getElectricDataDuration);
  const sepDurationState = useSelector(getSepDataDuration);
  const customDurationState = useSelector(getCustomDataDuration);
  const durationOption = useSelector(getDurationOption);
  const selectedChart = useSelector(selectedChartType);
  const [firstRender, setFirstRender] = useState(true);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const setDurationData = (duration) => {
    switch (true) {
      case type === DURATION.ENGINE && position === ENGINE_POSITION.PORT:
        dispatch(
          setDurationOption({
            option: DURATION_OPTION_TYPE.PORT,
            value: duration,
            vesselId,
          })
        );
        break;
      case type === DURATION.ENGINE && position === ENGINE_POSITION.STARBOARD:
        dispatch(
          setDurationOption({
            option: DURATION_OPTION_TYPE.STARBOARD,
            value: duration,
            vesselId,
          })
        );
        break;
      case type === DURATION.ENGINE && position === ENGINE_POSITION.CENTER:
        dispatch(
          setDurationOption({
            option: DURATION_OPTION_TYPE.CENTER,
            value: duration,
            vesselId,
          })
        );
        break;
      case type === DURATION.SEP:
        dispatch(
          setDurationOption({
            option: DURATION_OPTION_TYPE.SEP,
            value: duration,
            vesselId,
          })
        );
        break;
      case type === DURATION.ELECTRIC:
        dispatch(
          setDurationOption({
            option: DURATION_OPTION_TYPE.ELECTRIC,
            value: duration,
            vesselId,
          })
        );
        break;
      case type === DURATION.CUSTOM:
        dispatch(
          setDurationOption({
            option: DURATION_OPTION_TYPE.CUSTOM,
            value: duration,
            vesselId,
          })
        );
        break;
    }
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    dispatch(resetDurationOption());
  }, [vesselId, type, selectedChart]);

  const currentDurationOption = useCallback(() => {
    switch (true) {
      case type === DURATION.ENGINE && position === ENGINE_POSITION.PORT:
        return durationOption.port;
      case type === DURATION.ENGINE && position === ENGINE_POSITION.STARBOARD:
        return durationOption.starboard;
      case type === DURATION.ENGINE && position === ENGINE_POSITION.CENTER:
        return durationOption.center;
      case type === DURATION.ELECTRIC:
        return durationOption.electric;
      case type === DURATION.SEP:
        return durationOption.sep;
      case type === DURATION.CUSTOM:
        return durationOption.custom;
    }
  }, [type, position, vesselId, durationOption]);

  const setDurationStartDate = (d) => {
    switch (type) {
      case DURATION.ENGINE:
        dispatch(setStartDate(d));
        break;
      case DURATION.SEP:
        dispatch(setSepStartDate(d));
        break;
      case DURATION.ELECTRIC:
        dispatch(setElectricStartDate(d));
        break;
      case DURATION.CUSTOM:
        dispatch(setCustomDataStartDate(d));
        break;
    }
  };

  const setDurationEndDate = (d) => {
    switch (type) {
      case DURATION.ENGINE:
        dispatch(setEndDate(d));
        break;
      case DURATION.SEP:
        dispatch(setSepEndDate(d));
        break;
      case DURATION.ELECTRIC:
        dispatch(setElectricEndDate(d));
        break;
      case DURATION.CUSTOM:
        dispatch(setCustomDataEndDate(d));
        break;
    }
  };

  useEffect(() => {
    const currentDuration = currentDurationOption();
    if (!currentDuration?.vesselId || currentDuration?.vesselId !== vesselId) {
      setDurationData(currentDuration?.value);
    }
  }, [vesselId]);

  const selectedDate = () => {
    switch (true) {
      case type === DURATION.ENGINE:
        return dataDurationState;
      case type === DURATION.ELECTRIC:
        return electricDurationState;
      case type === DURATION.SEP:
        return sepDurationState;
      case type === DURATION.CUSTOM:
        return customDurationState;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Grid item sm={4} md={12}>
        <div className={classes.durationDateLabelPosition}>
          <Typography variant="body1" className={classes.durationDateLabel}>
            {t(localizationKeys.DataDuration_lower)}
          </Typography>
        </div>
      </Grid>
      <Grid item sm={8} md={12} className={classes.dateTimePickerContainer}>
        <DurationPicker
          startDate={selectedDate()?.startDate}
          setStartDate={(d) => setDurationStartDate(d)}
          endDate={selectedDate()?.endDate}
          setEndDate={(d) => setDurationEndDate(d)}
          isEmission={false}
          isNoData={isDisabled}
          withTimePicker={true}
          singleLine={isSmall}
          maxWidth={isSmall}
          currentVessel={currentVessel}
        />
      </Grid>
    </React.Fragment>
  );
});

DataDuration.propTypes = {
  type: PropTypes.number,
  isDisabled: PropTypes.bool,
  t: PropTypes.func,
  minDate: PropTypes.string,
  vesselId: PropTypes.string,
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  currentVessel: PropTypes.object,
};

export default withTranslation()(DataDuration);
