import React from "react";
import * as PropTypes from "prop-types";

const AlarmIconSetColor = ({ color = "rgba(255, 255, 255, 0.3)", width = 35, height = 35 }) => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 35 35">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g transform="translate(-8.000000, -335.000000) translate(0.000000, 50.000000)">
                <g transform="translate(8.000000, 285.000000)">
                    <g fill-rule="nonzero">
                        <path stroke={color} stroke-width="1.5" d="M20.672 13.72v-1.137c0-1.81-1.246-3.329-2.929-3.748-.243-.081-.556-.122-.939-.122-.383 0-.696.04-.939.122-1.682.42-2.93 1.938-2.93 3.748v1.138c0 1.216-.483 2.383-1.344 3.243-.38.378-.492.946-.287 1.44.204.494.687.816 1.223.816h2.334c0 1.072.87 1.94 1.943 1.94s1.943-.868 1.943-1.94h2.335c.535 0 1.018-.322 1.223-.816.205-.494.091-1.062-.287-1.44-.862-.86-1.346-2.027-1.346-3.243z" transform="translate(0.755253, 3.851050)"/>
                        <g fill={color}>
                            <g>
                                <path d="M2.403 3.023c-.437-.149-.911.085-1.06.521-.149.437.085.912.521 1.06 2.708.923 4.16 3.875 3.238 6.582-.149.437.084.912.521 1.06.09.03.18.045.27.045.347 0 .672-.219.79-.566 1.22-3.58-.7-7.483-4.28-8.702z" transform="translate(0.755253, 3.851050) translate(0.000000, 4.978990) translate(20.963176, 0.000000) translate(4.173976, 7.634670) rotate(18.000000) translate(-4.173976, -7.634670)"/>
                                <path d="M9.578 5.929C8.412 3.557 6.392 1.782 3.89.93c-.437-.149-.911.085-1.06.521-.149.437.085.912.521 1.06 2.08.709 3.759 2.184 4.728 4.155.97 1.971 1.113 4.202.405 6.281-.149.437.085.911.522 1.06.089.03.18.045.269.045.348 0 .672-.219.79-.566.853-2.502.68-5.186-.487-7.557z" transform="translate(0.755253, 3.851050) translate(0.000000, 4.978990) translate(20.963176, 0.000000) translate(6.692092, 7.468484) rotate(18.000000) translate(-6.692092, -7.468484)"/>
                            </g>
                            <g>
                                <path d="M2.403 3.023c-.437-.149-.911.085-1.06.521-.149.437.085.912.521 1.06 2.708.923 4.16 3.875 3.238 6.582-.149.437.084.912.521 1.06.09.03.18.045.27.045.347 0 .672-.219.79-.566 1.22-3.58-.7-7.483-4.28-8.702z" transform="translate(0.755253, 3.851050) translate(0.000000, 4.978990) translate(6.221094, 7.468484) scale(-1, 1) translate(-6.221094, -7.468484) translate(4.173976, 7.634670) rotate(18.000000) translate(-4.173976, -7.634670)"/>
                                <path d="M9.578 5.929C8.412 3.557 6.392 1.782 3.89.93c-.437-.149-.911.085-1.06.521-.149.437.085.912.521 1.06 2.08.709 3.759 2.184 4.728 4.155.97 1.971 1.113 4.202.405 6.281-.149.437.085.911.522 1.06.089.03.18.045.269.045.348 0 .672-.219.79-.566.853-2.502.68-5.186-.487-7.557z" transform="translate(0.755253, 3.851050) translate(0.000000, 4.978990) translate(6.221094, 7.468484) scale(-1, 1) translate(-6.221094, -7.468484) translate(6.692092, 7.468484) rotate(18.000000) translate(-6.692092, -7.468484)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>


  );
};

AlarmIconSetColor.propTypes = {
  disabled: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,

};

export default AlarmIconSetColor;
