import { createSlice } from "@reduxjs/toolkit";

//Action imports
import Actions from "../action/alarmNotificationAction.js";

const alarmNotificationSlice = createSlice({
  name: "alarmNotificationController",
  initialState: {
    shownList: [],
    delayFlag: true,
  },
  reducers: {
    ...Actions,
  },
});

//Selectors
export const getShownList = (state) => state.alarmNotificationController.shownList;
export const getDelayFlag = (state) => state.alarmNotificationController.delayFlag;

export const { setShownList, setDelayFlag } = alarmNotificationSlice.actions;

export default alarmNotificationSlice.reducer;
