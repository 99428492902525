import { COLORS } from "../colors";

export const svgContainer = {
  cursor: "pointer",
  "&:hover path": {
    stroke: COLORS.hoverSate,
    opacity: 1,
    fill: COLORS.hoverSate,
  },
  "&:active path": {
    stroke: COLORS.activeState,
    opacity: 1,
    fill: COLORS.activeState,
  },
  "&:focus path": {
    stroke: "#64D9E7",
    opacity: 1,
  },
};
