import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Pitch } from "./pitch/index";
import { Roll } from "./roll/index";
import { useCargoSensorsLatestData } from "../useLatestCargoData";
import { COLORS } from "../../../constants/colors";
import { Title } from "../title";
import { useSelector } from "react-redux";
import { isChannelModFetching } from "../../../model/slice/channelSlice";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: COLORS.tabSelectorColor,
    paddingBottom: "15px",
  },
}));

export const PitchAndRoll = (props) => {
  const { taggedLatestData } = props;
  const classes = useStyles();
  const { pitchData, rollData } = useCargoSensorsLatestData(taggedLatestData);
  const isLoading = useSelector(isChannelModFetching);

  return (
    <Grid container className={classes.container}>
      <Title title={"Pitch and Roll"} />
      {isLoading ? (
        <CircularProgress style={{ margin: "0 auto" }} />
      ) : (
        <>
          <Grid item xs={12}>
            <Pitch data={pitchData} />
          </Grid>
          <Grid item xs={12}>
            <Roll data={rollData} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

PitchAndRoll.propTypes = {
  taggedLatestData: PropTypes.array,
};
