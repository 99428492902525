import React, { useEffect, useRef, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useSelector } from "react-redux";
import { emissionChartLineList } from "../../model/slice/chartSettingsSlice";
import { ColorPicker } from "../chart/colorPicker.jsx";
import {
  handleTooltipLock,
  handleTooltipUnlocking,
  initCursor,
  initLegend,
  initScrollbarX,
  initScrollbarY,
  initTooltip,
  initYAxis,
} from "../chart/amcharts.jsx";
import { getDateName } from "./commonFunc";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType";
import { COLORS } from "../../constants/colors";
import { DEVICE_TYPE, detectDevice } from "../../constants/detectDevice.js";
let isLocked = false;

const EmissionLineChart = React.memo((props) => {
  const { vesselName, data, digits, emissionDuration, t } = props;
  const chartid = "emissionLineChart";
  const shownData = getShownData(data, digits, emissionDuration);
  //const dataKey = Object.keys(shownData[0])[1]
  // const hideLineList = useSelector(selectHideList);
  const paramList = useSelector(emissionChartLineList);

  //for Color select trick
  const [displayColorPickerFlag, setDisplayColorPickerFlag] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState({
    flag: "",
    position: 0,
  });
  const [dataKeyName, setDataKeyName] = useState("");
  const [colorPickerPos, setColorPickerPos] = useState({ x: 0, y: 0 });

  const refEmissionLineChart = useRef(null);
  const legendRef = useRef(null);

  const [hideLineList, updateHideLineList] = useState([]);
  const [initialCheck, setInitialCheck] = useState(false);
  const [operationCheck, setOperationCheck] = useState(false);

  const isMobile = [DEVICE_TYPE.PHONE, DEVICE_TYPE.TABLET].includes(detectDevice);

  //amCharts area

  useEffect(() => {
    const currentHideLineList = sessionStorage.getItem(`emission-line-${vesselName}`);
    if (!!currentHideLineList && currentHideLineList.length > 0) {
      updateHideLineList((prev) => prev.concat(JSON.parse(currentHideLineList)));
    }
    setInitialCheck(true);
  }, []);

  useEffect(() => {
    if (hideLineList.length > 0 || operationCheck) {
      sessionStorage.setItem(`emission-line-${vesselName}`, JSON.stringify(hideLineList));
    }
  }, [hideLineList]);

  useEffect(() => {
    am5.addLicense(process.env.AMCHARTS_LICENSE);
    const root = am5.Root.new(chartid, {
      useSafeResolution: false,
    });
    root.utc = true;
    root.setThemes([am5themes_Animated.new(root)]);
    const touchOptions = isMobile ? {
      pinchZoomX: true,
      pinchZoomY: true,
      panX: true,
      panY: true,
    } : {
      panX: false,
      panY: false,
    };

    const emissionLineChart = root.container.children.push(
      am5xy.XYChart.new(root, {
        wheelY: "zoomX",
        maxTooltipDistance: -1,
        layout: root.verticalLayout,
        ...touchOptions,
      })
    );
    const dateAxis = initXAxisForEmission(root, emissionLineChart, emissionDuration);
    const valueAxis = initYAxis(root, emissionLineChart);
    initLineSeriesForEmission(root, emissionLineChart, paramList, shownData, dateAxis, valueAxis);
    const tooltip = initTooltip(root, emissionLineChart);
    initTooltipLabelForEmission(emissionLineChart, tooltip);
    initCursor(emissionLineChart, root, dateAxis, valueAxis, null, isMobile);
    initScrollbarX(emissionLineChart, root);
    initScrollbarY(emissionLineChart, root);
    const legend = initLegend(
      emissionLineChart,
      root,
      hideLineList,
      setDataKeyName,
      setColorPickerPos,
      setDisplayColorPicker,
      setDisplayColorPickerFlag,
      null,
      isMobile,
      t
    );
    legend.itemContainers._values.forEach((v) => {
      v.events.on("click", function (ev) {
        const tooltip = initTooltip(root, emissionLineChart);
        initTooltipLabelForEmission(emissionLineChart, tooltip);
      });
      legendRef.current = legend;
    });

    if (!isMobile) {
      emissionLineChart.plotContainer.events.on("click", () => {
        if (!isLocked) {
          isLocked = true;
          handleTooltipLock(emissionLineChart);
        } else {
          isLocked = false;
          handleTooltipUnlocking(emissionLineChart);
        }
      });

      emissionLineChart.zoomOutButton.events.on("click", () => {
        if (isLocked) {
          handleTooltipUnlocking(emissionLineChart);
        }
      });
    }

    refEmissionLineChart.current = emissionLineChart;

    return () => {
      root.dispose();
    };
  }, [data, emissionDuration, initialCheck]);

  useEffect(() => {
    if (refEmissionLineChart.current && displayColorPicker) {
      const tooltip = initTooltip(refEmissionLineChart.current.root, refEmissionLineChart.current);
      initTooltipLabelForEmission(refEmissionLineChart.current, tooltip);
    }
  }, [displayColorPicker]);

  useEffect(() => {
    //for User change line color trick
    if (displayColorPicker.flag == "" || displayColorPicker.position == 0) {
      // Do nothing
    } else {
      refEmissionLineChart.current.series.each(function (item, index) {
        if (displayColorPicker.flag == item._settings.name) {
          //color change trick
          item.set("fill", am5.color(displayColorPicker.position));
          item.set("stroke", am5.color(displayColorPicker.position));
          //change legend marker color
          const legendDataItem = legendRef.current._dataItems[index];
          const legendMarker = legendDataItem.get("markerRectangle");
          legendMarker.states.lookup("default").set("fill", am5.color(displayColorPicker.position));
          legendMarker.states
            .lookup("default")
            .set("stroke", am5.color(displayColorPicker.position));
        }
      });
    }
  }, [displayColorPicker]);

  const initLineSeriesForEmission = (root, chart, paramList, shownData, dateAxis, valueAxis) => {
    paramList.forEach((value) => {
      const notVisibleFlag = hideLineList.includes(value.key);
      const valueSeries = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: value.key,
          valueXField: "dateTime",
          valueYField: value.key,
          xAxis: dateAxis,
          yAxis: valueAxis,
          visible: !notVisibleFlag,
          fill: am5.color(
            sessionStorage.getItem(`emission-${vesselName}-${value.key}`)
              ? sessionStorage.getItem(`emission-${vesselName}-${value.key}`)
              : value.color
          ),
          stroke: am5.color(
            sessionStorage.getItem(`emission-${vesselName}-${value.key}`)
              ? sessionStorage.getItem(`emission-${vesselName}-${value.key}`)
              : value.color
          ),
          legendLabelText: value.keyName,
        })
      );
      if (notVisibleFlag) {
        valueSeries.hide();
      }
      valueSeries.on("visible", (visible, target) => {
        const targetName = target.get("name");
        if (!operationCheck) {
          setOperationCheck(true);
        }
        if (visible) {
          updateHideLineList((prev) => prev.filter((d) => d !== targetName));
        } else {
          updateHideLineList((prev) => prev.concat([targetName]));
        }
      });
      valueSeries.data.setAll(shownData);
    });
  };

  const initTooltipLabelForEmission = (chart, tooltip) => {
    tooltip.label.adapters.add("text", (text) => {
      text = "";
      chart.series.each(function (series, i) {
        const tooltipDataItem = series.get("tooltipDataItem");
        if (tooltipDataItem) {
          if (i === 0) {
            const date = new Date(tooltipDataItem.get("valueX"));
            const formatDate = `${date.getUTCFullYear()}/${("00" + (date.getUTCMonth() + 1)).slice(
              -2
            )}/${("00" + date.getUTCDate()).slice(-2)} (UTC)\n`;
            text += "[bold]" + formatDate + "[/]";
          }
          if (!series.isHidden() && tooltipDataItem.get("valueY") !== null) {
            text += "\n";
            text +=
              "[" +
              series.get("stroke").toString() +
              " " +
              " width: 130px]" +
              series.get("name") +
              ":[/] " +
              tooltipDataItem.get("valueY");
          }
        }
      });
      return text;
    });
  };

  return (
    <>
      <div id={chartid} data-testid="amcharts" style={{ width: "100%", height: `100%` }} />
      <div
        className="colorPickerContainer"
        style={{
          width: "fit-content",
          visibility: displayColorPickerFlag ? "visible" : "hidden",
          position: "relative",
          left: colorPickerPos.x,
          bottom: colorPickerPos.y,
        }}
      >
        <div style={{ left: `10px` }}>
          <ColorPicker
            setDisplayColorPicker={setDisplayColorPicker}
            dataKey={dataKeyName}
            graphType={GRAPH_TYPE.EMISSION_LINES}
            setOnClose={setDisplayColorPickerFlag}
            isEmission={true}
            vesselName={vesselName}
          />
        </div>
      </div>
    </>
  );
});

const getShownData = (data, digits, duration) => {
  const shownData = [];
  if (data === undefined) return shownData;

  let targetArray = [];
  if (duration === "daily") {
    targetArray = Object.values(data.DailyData);
  } else if (duration === "weekly") {
    targetArray = Object.values(data.WeeklyData);
  } else if (duration === "monthly") {
    targetArray = Object.values(data.MonthlyData);
  } else {
    targetArray = Object.values(data.YearlyData);
  }

  targetArray.forEach((dataPacket) => {
    shownData.push({
      dateTime: Date.parse(dataPacket.startDate),
      name: getDateName(dataPacket.startDate),
      CO2: Number(dataPacket.combinedData.CO2.toFixed(digits)),
      SOx: Number(dataPacket.combinedData.SOX.toFixed(digits)),
      NOx: Number(dataPacket.combinedData.NOX.toFixed(digits)),
      Fuel: Number(dataPacket.combinedData.TFC.toFixed(digits)),
    });
  });

  return shownData;
};

const initXAxisForEmission = (root, chart, duration) => {
  const timeUnit = getUnit(duration);
  const dateAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit, count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {}),
    })
  );
  const xRenderer = dateAxis.get("renderer");
  xRenderer.labels.template.setAll({
    fill: am5.color(COLORS.white),
    opacity: 0.2,
    fontSize: "14px",
    paddingTop: 14,
  });
  xRenderer.grid.template.setAll({
    stroke: am5.color(COLORS.white),
    strokeWidth: 2,
    strokeDasharray: [4, 2],
  });
  return dateAxis;
};

const getUnit = (duration) => {
  if (duration === "daily") {
    return "day";
  } else if (duration === "weekly") {
    return "week";
  } else if (duration === "monthly") {
    return "month";
  } else {
    return "year";
  }
};

export { EmissionLineChart };
