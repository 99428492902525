export const TEMP_TAGS = [
  { tag: "T18" },
  { tag: "T17" },
  { tag: "T31" },
  { tag: "T19" },
  { tag: "T20" },
  { tag: "T21" },
  { tag: "T36" },
  { tag: "T22" },
  { tag: "T23" },
  { tag: "T24" },
  { tag: "T37" },
  { tag: "T25" },
  { tag: "T26" },
  { tag: "T27" },
  { tag: "T28" },
  { tag: "T29" },
  { tag: "T30" },
  { tag: "T32" },
  { tag: "T33" },
  { tag: "T34" },
  { tag: "T35" },
  { tag: "T9" },
  { tag: "T8" },
  { tag: "T5" },
  { tag: "T2" },
  { tag: "T3" },
  { tag: "T1" },
  { tag: "T6" },
  { tag: "T4" },
  { tag: "T7" },
];

// only for LCO2 vessel
export const TEMP_CH_NO = [
  {
    chNo: "2009",
    tag: "T18",
  },
  {
    chNo: "2002",
    tag: "T17",
  },
  {
    chNo: "2024",
    tag: "T31",
  },
  {
    chNo: "2010",
    tag: "T19",
  },
  {
    chNo: "2011",
    tag: "T20",
  },
  {
    chNo: "2012",
    tag: "T21",
  },
  {
    chNo: "2013",
    tag: "T36",
  },
  {
    chNo: "2014",
    tag: "T22",
  },
  {
    chNo: "2015",
    tag: "T23",
  },
  {
    chNo: "2016",
    tag: "T24",
  },
  {
    chNo: "2017",
    tag: "T37",
  },
  {
    chNo: "2018",
    tag: "T25",
  },
  {
    chNo: "2019",
    tag: "T26",
  },
  {
    chNo: "2020",
    tag: "T27",
  },
  {
    chNo: "2021",
    tag: "T28",
  },
  {
    chNo: "2022",
    tag: "T29",
  },
  {
    chNo: "2023",
    tag: "T30",
  },
  {
    chNo: "2027",
    tag: "T32",
  },
  {
    chNo: "2028",
    tag: "T33",
  },
  {
    chNo: "2029",
    tag: "T34",
  },
  {
    chNo: "2030",
    tag: "T35",
  },
  {
    chNo: "2026",
    tag: "T9",
  },
  {
    chNo: "2005",
    tag: "T8",
  },
  {
    chNo: "2003",
    tag: "T5",
  },
  {
    chNo: "2032",
    tag: "T2",
  },
  {
    chNo: "2000",
    tag: "T3",
  },
  {
    chNo: "2031",
    tag: "T1",
  },
  {
    chNo: "2004",
    tag: "T6",
  },
  {
    chNo: "2001",
    tag: "T4",
  },
  {
    chNo: "2025",
    tag: "T7",
  },
];

export const TRANSPORT_PRESS_TAGS = [
  { tag: "T17" },
  { tag: "T18" },
  { tag: "T25" },
  { tag: "T26" },
  { tag: "T27" },
  { tag: "T28" },
  { tag: "T29" },
  { tag: "T30" },
  { tag: "T31" },
  { tag: "T51" },
  { tag: "T46" },
  { tag: "T47" },
];

export const HANDLING_PRESS_LOAD_TAGS = [
  { tag: "T4" },
  { tag: "T6" },
  { tag: "T7" },
  { tag: "T32" },
  { tag: "T33" },
  { tag: "T34" },
  { tag: "T35" },
  { tag: "T1" },
  { tag: "T2" },
  // { tag: "P4" },
  { tag: "P5" },
  { tag: "P7" },
  { tag: "P1" },
  { tag: "P2" },
  { tag: "P46" },
  { tag: "P47" },
];

export const HANDLING_PRESS_UNLOAD_TAGS = [
  { tag: "T8" },
  { tag: "T9" },
  { tag: "T32" },
  { tag: "T33" },
  { tag: "T34" },
  { tag: "T35" },
  { tag: "T1" },
  { tag: "T2" },
  { tag: "P43" },
  { tag: "P44" },
  { tag: "P8" },
  { tag: "P9" },
  { tag: "P1" },
  { tag: "P2" },
  { tag: "P46" },
  { tag: "P47" },
];

export const LINE_CHART_TAGS = [...HANDLING_PRESS_LOAD_TAGS, ...HANDLING_PRESS_UNLOAD_TAGS].filter(
  (element, index, self) =>
    self.findIndex((dataElement) => dataElement.tag === element.tag) === index
);

export const CSV_EXPORT_TAGS = [
  { tag: "T3" },
  { tag: "T4" },
  { tag: "T17" },
  { tag: "T5" },
  { tag: "T6" },
  { tag: "T8" },
  { tag: "T18" },
  { tag: "T19" },
  { tag: "T20" },
  { tag: "T21" },
  { tag: "T36" },
  { tag: "T22" },
  { tag: "T23" },
  { tag: "T24" },
  { tag: "T37" },
  { tag: "T25" },
  { tag: "T26" },
  { tag: "T27" },
  { tag: "T28" },
  { tag: "T29" },
  { tag: "T30" },
  { tag: "T31" },
  { tag: "T7" },
  { tag: "T9" },
  { tag: "T32" },
  { tag: "T33" },
  { tag: "T34" },
  { tag: "T35" },
  { tag: "T1" },
  { tag: "T2" },
  { tag: "T51" },
  { tag: "P43" },
  { tag: "P44" },
  { tag: "P4" },
  { tag: "P5" },
  { tag: "P6" },
  { tag: "P7" },
  { tag: "P8" },
  { tag: "P9" },
  { tag: "P1" },
  { tag: "P2" },
  { tag: "P46" },
  { tag: "P47" },
  { tag: "O3" },
  { tag: "O4" },
  { tag: "O7" },
  { tag: "O8" },
  { tag: "O9" },
  { tag: "O10" },
  { tag: "O14" },
  { tag: "O15" },
];

export const TRANSPORT_CSV_TAGS = [
  { tag: "T3" },
  { tag: "T4" },
  { tag: "T17" },
  { tag: "T5" },
  { tag: "T6" },
  { tag: "T8" },
  { tag: "T18" },
  { tag: "T19" },
  { tag: "T20" },
  { tag: "T21" },
  { tag: "T36" },
  { tag: "T22" },
  { tag: "T23" },
  { tag: "T24" },
  { tag: "T37" },
  { tag: "T25" },
  { tag: "T26" },
  { tag: "T27" },
  { tag: "T28" },
  { tag: "T29" },
  { tag: "T30" },
  { tag: "T31" },
  { tag: "T7" },
  { tag: "T9" },
  { tag: "T32" },
  { tag: "T33" },
  { tag: "T34" },
  { tag: "T35" },
  { tag: "T1" },
  { tag: "T2" },
  { tag: "T51" },
  { tag: "P7" },
  { tag: "P46" },
  { tag: "P47" },
  { tag: "O3" },
  { tag: "O4" },
  { tag: "O7" },
  { tag: "O8" },
  { tag: "O9" },
  { tag: "O10" },
  { tag: "O14" },
  { tag: "O15" },
]

export const LOADING_CSV_TAGS = [
  { tag: "T3" },
  { tag: "T4" },
  { tag: "T17" },
  { tag: "T5" },
  { tag: "T6" },
  { tag: "T8" },
  { tag: "T18" },
  { tag: "T19" },
  { tag: "T20" },
  { tag: "T21" },
  { tag: "T36" },
  { tag: "T22" },
  { tag: "T23" },
  { tag: "T24" },
  { tag: "T37" },
  { tag: "T25" },
  { tag: "T26" },
  { tag: "T27" },
  { tag: "T28" },
  { tag: "T29" },
  { tag: "T30" },
  { tag: "T31" },
  { tag: "T7" },
  { tag: "T9" },
  { tag: "T32" },
  { tag: "T33" },
  { tag: "T34" },
  { tag: "T35" },
  { tag: "T1" },
  { tag: "T2" },
  { tag: "T51" },
  { tag: "P4" },
  { tag: "P5" },
  { tag: "P7" },
  { tag: "P8" },
  { tag: "P9" },
  { tag: "P1" },
  { tag: "P2" },
  { tag: "P46" },
  { tag: "P47" },
  { tag: "O3" },
  { tag: "O4" },
  { tag: "O7" },
  { tag: "O8" },
  { tag: "O9" },
  { tag: "O10" },
  { tag: "O14" },
  { tag: "O15" },
];

export const UNLOADING_CSV_TAGS = [
  { tag: "T3" },
  { tag: "T4" },
  { tag: "T17" },
  { tag: "T5" },
  { tag: "T6" },
  { tag: "T8" },
  { tag: "T18" },
  { tag: "T19" },
  { tag: "T20" },
  { tag: "T21" },
  { tag: "T36" },
  { tag: "T22" },
  { tag: "T23" },
  { tag: "T24" },
  { tag: "T37" },
  { tag: "T25" },
  { tag: "T26" },
  { tag: "T27" },
  { tag: "T28" },
  { tag: "T29" },
  { tag: "T30" },
  { tag: "T31" },
  { tag: "T7" },
  { tag: "T9" },
  { tag: "T32" },
  { tag: "T33" },
  { tag: "T34" },
  { tag: "T35" },
  { tag: "T1" },
  { tag: "T2" },
  { tag: "T51" },
  { tag: "P43" },
  { tag: "P44" },
  { tag: "P7" },
  { tag: "P8" },
  { tag: "P9" },
  { tag: "P1" },
  { tag: "P2" },
  { tag: "P46" },
  { tag: "P47" },
  { tag: "O3" },
  { tag: "O4" },
  { tag: "O7" },
  { tag: "O8" },
  { tag: "O9" },
  { tag: "O10" },
  { tag: "O14" },
  { tag: "O15" },
]

export const LCO2_TEMP_THRESHOLD = {
  high: [-24, -22, -20],
  low: [-47, -50, -56.6],
};