import { withStyles } from "@mui/styles";
import React, { useEffect, useState, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import Thumbnail from "../../../navigationPage/thumbnail";
import { DEFAULT_DASH_VALUE, DEFAULT_VALUE, NAVIGATION_TYPES } from "../../../../constants/constants";
import { SHIP_COUNTRY } from "../../../../constants/vesselCountry";
import { VesselFlagAisMid } from "../../../vesselImages/vesselFlag";
import { SHIP_GROUP_TYPE, SHIP_TYPE } from "../../../../constants/vesselShipType";
import { getSyntheticaEnabledVesselIds } from "../../../../model/slice/addonSlice";
import { COLORS } from "../../../../constants/colors";
import { useSyntheticaData } from "../../../syntheticaPage/useSyntheticaData";
import { colorFromRank } from "../../../syntheticaPage/styles";
import { withTranslation } from "react-i18next";
import localizationKeys from "../../../../i18n/localizationKeys";

const styles = () => ({
  container: {
    display: "flex",
  },
  thumbnailContainer: {
    display: "flex",
    alignItems: "center",
  },
  thumbnail: {
    minWidth: '125px',
    maxWidth: '125px',
    minHeight: '90px',
    maxHeight: '90px',
    position: 'relative'
  },
  flagHolder: {
    position: "absolute",
    height: "35px",
    width: "35px",
    borderRadius: "50px",
    top: "-10px",
    right: "-20px",
    display: "block",
    textAlign: "center",
  },
  flag: {
    width: "100%",
    height: "100%",
  },
  countryNameHolder: {
    position: "absolute",
    height: "35px",
    width: "35px",
    borderRadius: "50px",
    top: "-10px",
    right: "-20px",
    display: "block",
    background: "#2834408c",
    color: "#ffffff",
    paddingTop: "10px",
    fontSize: "12px",
    fontFamily: "Inter",
  },
  title: {
    fontSize: "32px",
  },
  text: {
    width: "100%",
    fontSize: "12px",
  },
  labelColor: {
    color: COLORS.greenishBlue,
  },
  valueColor: {
    color: COLORS.white,
  },
  valueColor70: {
    color: COLORS.white70,
  },
  center: {
    textAlign: "center",
  },
});

const vesselDataInitialValue = {
  vesselClass: DEFAULT_DASH_VALUE,
};

const aisDataInitialValue = {
  countryCode: DEFAULT_VALUE,
  shipType: DEFAULT_VALUE,
  callSign: DEFAULT_DASH_VALUE,
  mmsi: DEFAULT_DASH_VALUE,
  destination: DEFAULT_DASH_VALUE,
};

const VesselDetails = ({ aisData, vesselData, isCiiEnabled, classes, t }) => {
  const size = isCiiEnabled ? 4 : 12;

  return (
    <Grid container rowSpacing={2} style={{ textAlign: "center" }}>
      <Grid item xs={size}>
        <div className={`${classes.labelColor} ${classes.text}`}>
          {t(localizationKeys.CallSign_upper)}
        </div>
        <div className={`${classes.valueColor} ${classes.text}`}>
          {aisData.callSign || "-"}
        </div>
      </Grid>
      <Grid item xs={size}>
        <div className={`${classes.labelColor} ${classes.text}`}>
          {t(localizationKeys.Mmsi_upper)}
        </div>
        <div className={`${classes.valueColor} ${classes.text}`}>
          {aisData.mmsi || "-"}
        </div>
      </Grid>
      <Grid item xs={size}>
        <div className={`${classes.labelColor} ${classes.text}`}>
          {t(localizationKeys.VesselClass_upper)}
        </div>
        <div className={`${classes.valueColor} ${classes.text}`}>
          {vesselData.vesselClass || "-"}
        </div>
      </Grid>
    </Grid>
  );
}

const VesselOverviewDetails = memo(({ vessel, style, navigationData, classes, t }) => {
  const ciiEnabledVesselIds = useSelector(getSyntheticaEnabledVesselIds);
  const [vesselData, setVesselData] = useState(vesselDataInitialValue);
  const [aisData, setAisData] = useState(aisDataInitialValue);

  const ais = navigationData.find(d => d.navigationType === NAVIGATION_TYPES.AIS);

  const isCiiEnabled = useMemo(() => ciiEnabledVesselIds.includes(vessel.vessel_id), [vessel.vessel_id]);
  const ciiData = useSyntheticaData({ vesselName: vessel.vessel_name });
  const currentRatingColor = colorFromRank(ciiData.vesselStatus.rating_value);

  useEffect(() => {
    if (!ais) return;

    const details = ais.data[0];
    const shipType = details.shipType;

    setAisData({
      countryCode: details.mmsi.toString().substring(0, 3),
      shipType: SHIP_GROUP_TYPE[SHIP_TYPE[shipType]],
      callSign: details.callSign ?? DEFAULT_DASH_VALUE,
      mmsi: details.mmsi ?? DEFAULT_DASH_VALUE,
      destination: details.destination,
    });
  }, [ais]);

  useEffect(() => {
    setVesselData({
      vesselClass: vessel.vessel_firstClass ?? DEFAULT_DASH_VALUE,
    });

    return () => {
      setAisData(aisDataInitialValue);
      setVesselData(vesselDataInitialValue);
    };
  }, [vessel.vessel_id])

  return (
    <Grid container className={ `${style.components} ${classes.container}` }>
      <Grid item xs={3} className={classes.thumbnailContainer}>
        <div className={classes.thumbnail}>
          <Thumbnail vesselId={vessel.vessel_id} vesselType={aisData.shipType} />
          <div className={classes.flagHolder}>
            {aisData.countryCode ? <img src={VesselFlagAisMid(aisData.countryCode)} className={classes.flag} /> : ""}
          </div>
          <div className={`${classes.countryNameHolder} ${classes.center}`}>
            {aisData.countryCode ? SHIP_COUNTRY[aisData.countryCode]?.ShortCode : ""}
          </div>
        </div>
      </Grid>
      <Grid item xs={7} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Grid container rowSpacing={2}>
          {isCiiEnabled && <Grid item xs={12}>
            <VesselDetails vesselData={vesselData} aisData={aisData} isCiiEnabled={isCiiEnabled} classes={classes} t={t} />
          </Grid>}
          <Grid item xs={12}>
            <div className={`${classes.title} ${classes.center}`}>
              {vessel.vessel_name}
            </div>
            {vessel.vessel_imo && (<div className={`${classes.text} ${classes.center}`}>
              <span className={classes.labelColor}>IMO:</span>  <span className={classes.valueColor}>{vessel.vessel_imo}</span>
            </div>)}
            <div className={`${classes.valueColor70} ${classes.text} ${classes.center}`}>
              {aisData.shipType}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", textAlign: "center" }}>
        {isCiiEnabled ?
          <>
            <div className={`${classes.labelColor} ${classes.text}`}>
              {t(localizationKeys.CIIRanking)}
            </div>
            <div className={`${classes.title} ${classes.center}`} style={{ color: currentRatingColor, fontSize: "40px", fontWeight: "bold" }}>
              {ciiData.vesselStatus.rating_value ?? "-"}
            </div>
            <div className={`${classes.valueColor70} ${classes.text} ${classes.center}`}>
              {t(localizationKeys.Rank).toUpperCase()}
            </div>
          </> :
          <VesselDetails vesselData={vesselData} aisData={aisData} isCiiEnabled={isCiiEnabled} classes={classes} t={t} />
        }
      </Grid>
    </Grid>
  );
});

VesselOverviewDetails.propTypes = {
  vessel: PropTypes.object,
  navigationData: PropTypes.array,
  style: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func
};

export default withStyles(styles)(withTranslation()(VesselOverviewDetails));