import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { getDataDuration } from "../../../model/slice/dataDurationSlice";

//IndexDB imports

//Reducer imports

//API imports
import { fetchEngineData, fetchMainEngineData } from "../../../api/baseEngineData.js";
import { calDurations } from "../../../constants/calDuration";
import { logger } from "../../../api/logger";

const INITIAL_VALUE = {
  mainEngineData: [],
  engineData: [],
};

let cancelToken;

export const useEngineData = ({ vesselId = null, position = null, isVesselOverview = false }) => {
  const [engineValues, setEngineValues] = useState(INITIAL_VALUE);
  //Coming from duration selection
  const { startDate, endDate } = useSelector(getDataDuration);
  //Sets to true if component is unmounted
  const unmount = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(false);

  const ENGINE_CLASS = {
    engineData: {
      position: "",
      dateRangeKey: "dateRangeEngineData",
      fetchKey: "vesselEngineData",
      fetch: async (position, vesselId, startDate, endDate, interval, token) => {
        return await fetchEngineData(vesselId, startDate, endDate, interval, token);
      },
    },
    mainEngineData: {
      position: position,
      dateRangeKey: "dateRangeMainEngineData",
      fetchKey: "vesselMainEngineData",
      fetch: async (position, vesselId, startDate, endDate, interval, token) => {
        return await fetchMainEngineData(position, vesselId, startDate, endDate, interval, token);
      },
    },
  };

  //Core logic for fetching engine data values
  const getEngineValues = async (engineClass, token) => {
    let data = [];

    // interval calculation
    const interval = isVesselOverview ? 10 : calDurations({ startDate, endDate });

    // //Get indexDb data
    // data = await rawGetData(engineClass.position, vesselId);
    // console.log("fetched from indexedDB")
    //
    // //If indexdb data has contents, generate missing dates
    // if(data.length > 0) {
    //     const missingDateList = await missingDateReducer(data, startDate, endDate);
    //     console.log("missing data list");
    //     console.log(missingDateList);
    //
    //     //Fetch date range from iAPI
    //     const dateRange = await engineClass.getDateRangeData(position, vesselId, missingDateList);
    //
    //     //Insert date range to the correct spot in the array
    //     const mergedList = await insertDateReducer(data, dateRange[engineClass.dateRangeKey]);
    //     data = mergedList;
    // }else { //If indexb data has no contents, fetch directly from API
    //     console.log("fetch from iAPI")
    //     let fetchData = await engineClass.fetch(position, vesselId, startDate, endDate);
    //     data = fetchData[engineClass.fetchKey]
    // }

    //Insert to IndexDB. Regardless if data comes from old indexDB or API, always insert
    //If data comes from indexDB, then the updated one will be inserted.
    // rawInsert(data, engineClass.position, vesselId);

    logger.info("Fetch from iAPI");
    const fetchData = await engineClass.fetch(
      position,
      vesselId,
      startDate,
      endDate,
      interval,
      token.token
    );
    data = fetchData[engineClass.fetchKey];
    setIsNoData(!data?.length);
    return data;
  };

  //Loads data
  const dataLoad = async () => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    await Promise.all([
      getEngineValues(ENGINE_CLASS.mainEngineData, cancelToken),
      getEngineValues(ENGINE_CLASS.engineData, cancelToken),
    ])
      .then((values) => {
        if (unmount.current === true) {
          return;
        }
        setEngineValues({
          mainEngineData: values[0],
          engineData: values[1],
        });
      })
      .catch((error) => {
        logger.error(`getEngineValues error: ${error}`);
      });
    setIsLoading(false);
  };

  // if vesselId is changed, engine data will be reset
  useEffect(() => {
    setEngineValues(() => INITIAL_VALUE);
  }, [vesselId]);

  // Fires when either of the following is changed:
  // vesselId => if user changes selected vessel
  // position => engine position is changed: Starboard | Port
  // startDate/endDate => if date duration is changed
  useEffect(
    () => {
      if (position === null || position === undefined || startDate === "" || vesselId === null) {
        return;
      }
      setIsLoading(true);
      setIsNoData(false);
      dataLoad();
    },
    //IMPORTANT!!! Group position, startDate and endDate together.. The main cause of double refresh
    //is when position and duration changed at the same time
    [vesselId, position, startDate, endDate]
  );

  useEffect(() => {
    return () => {
      unmount.current = true;
    };
  }, []);
  return {
    engineValues,
    isLoading,
    isNoData,
  };
};
