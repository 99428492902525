import { axios } from "./axiosSettings";
import { getAuthToken } from "../helpers/session.js";

export const fetchVessel = async (vesselId) => {
  try {
    const { data } = await axios.get(`/vessel/${vesselId}`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return { vessel: data };
  } catch (e) {
    return { error: e };
  }
};

export const fetchVessels = async () => {
  try {
    const { data } = await axios.get("/vessel", {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    return { vessel: data };
  } catch (e) {
    return { fetchVessels: e };
  }
};

export const getShortUuid = async (vesselId) => {
  try {
    const { data } = await axios.get(`/vessel/uuidlongtoshort/${vesselId}`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return data;
  } catch (e) {
    return { getShortUuid: e };
  }
};
