import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAlarmDataAsync,
  fetchAlarmHistory,
  fetchCurrentAlarm,
  fetchAlarmOperations,
  fulfilledFetchCurrentAlarm,
  fulfilledFetchHistoryAlarm,
  fulfilledFetchAlarmOperations,
  pendingFetchAlarmHistory,
  pendingFetchCurrentAlarm,
  pendingFetchAlarmOperations,
  rejectedFetchCurrentAlarm,
  rejectedFetchHistoryALarm,
  rejectedFetchAlarmOperations,
  fulfilledFetchAlarmData,
} from "../async/alarmAsync.js";
import Actions from "../action/alarmAction.js";
import constants from "../../components/alarmPage/utils/constants.js";

export const ALARM_PAGE = {
  MONITORING: 1,
  HISTORY: 2,
  OPERATION: 3,
};

export const FILTER_PAGE = {
  MONITORING: "current",
  HISTORY: "history",
  OPERATION: "operation",
};

export const INITIAL_ALARM_CURRENT_FILTER = {
  word: "",
  status: "",
};

export const INITIAL_ALARM_HISTORY_FILTER = {
  ...INITIAL_ALARM_CURRENT_FILTER,
  ...constants.DURATION_INITIAL_STATE,
};

export const INITIAL_ALARM_OPERATION_FILTER = {
  word: "",
  status: "",
  ...constants.DURATION_INITIAL_STATE_DATETIME,
};

export const INITIAL_ALARM_STATE = {
  isFleetAlarmLoading: false,
  isCurrentAlarmLoading: false,
  isHistoryAlarmLoading: false,
  isAlarmOperationLoading: false,
  alarmListByCategory: {},
  fleetAlarmData: [],
  currentAlarm: [],
  alarmHistory: [],
  alarmOperations: [],
  currentPage: ALARM_PAGE.MONITORING,
  alarmFilter: {
    current: INITIAL_ALARM_CURRENT_FILTER,
    history: INITIAL_ALARM_HISTORY_FILTER,
    operation: INITIAL_ALARM_OPERATION_FILTER,
  },
  tempFilteredAlarmList: undefined,
  filteredAlarmList: [],
  isSearching: false,
  isSearchButton: false,
  filters: {
    word: "",
    status: "",
    occurrenceStart: "",
    occurrenceEnd: "",
    recoveryTimeStart: "",
    recoveryTimeEnd: "",
    datetimeStart: "",
    datetimeEnd: "",
  },
  currentWindowPosition: 0,
  currentTablePosition: 0,
  currentHistoryPage: 0,
  currentOperationPage: 0,
};

const alarmSlice = createSlice({
  name: "alarmSlice",
  initialState: INITIAL_ALARM_STATE,
  reducers: {
    ...Actions,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlarmHistory.fulfilled, fulfilledFetchHistoryAlarm);
    builder.addCase(fetchAlarmHistory.rejected, rejectedFetchHistoryALarm);
    builder.addCase(fetchAlarmHistory.pending, pendingFetchAlarmHistory);
    builder.addCase(fetchCurrentAlarm.fulfilled, fulfilledFetchCurrentAlarm);
    builder.addCase(fetchCurrentAlarm.rejected, rejectedFetchCurrentAlarm);
    builder.addCase(fetchCurrentAlarm.pending, pendingFetchCurrentAlarm);
    builder.addCase(fetchAlarmOperations.fulfilled, fulfilledFetchAlarmOperations);
    builder.addCase(fetchAlarmOperations.rejected, rejectedFetchAlarmOperations);
    builder.addCase(fetchAlarmOperations.pending, pendingFetchAlarmOperations);
    builder.addCase(fetchAlarmDataAsync.fulfilled, fulfilledFetchAlarmData);
  },
});

export const fleetAlarmSelector = (state) => state.alarmController.fleetAlarmData;
export const currentAlarmSelector = (state) => state.alarmController.currentAlarm;
export const historyAlarmSelector = (state) => state.alarmController.alarmHistory;
export const operationAlarmSelector = (state) => state.alarmController.alarmOperations;
export const currentPageSelector = (state) => state.alarmController.currentPage;
export const currentFilterSelector = (state) => state.alarmController.alarmFilter.current;
export const historyFilterSelector = (state) => state.alarmController.alarmFilter.history;
export const operationFilterSelector = (state) => state.alarmController.alarmFilter.operation;
export const filteredAlarmSelector = (state) => state.alarmController.filteredAlarmList;
export const isFleetAlarmLoading = (state) => state.alarmController.isFleetAlarmLoading;
export const isCurrentAlarmLoading = (state) => state.alarmController.isCurrentAlarmLoading;
export const isHistoryAlarmLoading = (state) => state.alarmController.isHistoryAlarmLoading;
export const isAlarmOperationLoading = (state) => state.alarmController.isAlarmOperationLoading;
export const alarmListByCategorySelector = (state) => state.alarmController.alarmListByCategory;
export const alarmSearchingSelector = (state) => state.alarmController.isSearching;
export const getSearchButtonSelector = (state) => state.alarmController.isSearchButton;
export const alarmFilterSelector = (state) => state.alarmController.filters;
export const hasFilterActive = (state) =>
  !!(
    state.alarmController.filters.word ||
    state.alarmController.filters.status ||
    state.alarmController.filters.occurrenceStart ||
    state.alarmController.filters.occurrenceEnd ||
    state.alarmController.filters.recoveryTimeStart ||
    state.alarmController.filters.recoveryTimeEnd ||
    state.alarmController.filters.datetimeStart ||
    state.alarmController.filters.datetimeEnd
  );
export const currentWindowPosition = (state) => state.alarmController.currentWindowPosition;
export const currentTablePosition = (state) => state.alarmController.currentTablePosition;
export const currentHistoryPage = (state) => state.alarmController.currentHistoryPage;
export const currentOperationPage = (state) => state.alarmController.currentOperationPage;

export const {
  setCurrentPage,
  setCurrentAlarm,
  resetFilters,
  setHistoryRecoveryFilter,
  setHistoryOccurrenceFilter,
  setOperationDatetimeFilter,
  wordSearch,
  valueSearch,
  setFilterStatus,
  setCurrentAlarmLoading,
  setAlarmCategory,
  resetAlarm,
  resetCurrentAlarm,
  setSearchButtonFlg,
  setCurrentWindowPosition,
  setCurrentTablePosition,
  setCurrentHistoryPage,
  setCurrentOperationPage,
  searchButtonClicked,
} = alarmSlice.actions;

export default alarmSlice.reducer;
