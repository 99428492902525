import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import languageEN from "./languages/en.js";
import languageJa from "./languages/ja.js";

const options = {
  // order and from where user language should be detected
  order: [
    "navigator",
    "cookie",
    "localStorage",
    "sessionStorage",
    "querystring",
    "htmlTag",
    "path",
    "subdomain",
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  cookieMinutes: 0,
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    resources: {
      "en-US": languageEN,
      ja: languageJa,
    },
    detection: options,
    /* default language when load the website in browser */
    //lng: "en",
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: "en-US",
    /* debugger For Development environment */
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    // keySeparator: ".",
    // interpolation: {
    //     escapeValue: false,
    //     formatSeparator: ","
    // },
    // react: {
    //     wait: true,
    //     bindI18n: 'languageChanged loaded',
    //     bindStore: 'added removed',
    //     nsMode: 'default'
    // }
  });

export default i18n;
