import { FLOWMETER_TYPE } from "../../constants/navigation/navigation";

const focUnit = ({ engineFoUnit, engineFoUnitFactor }) => {
  switch (true) {
    case engineFoUnit === "l" && engineFoUnitFactor === 1:
      return FLOWMETER_TYPE.LITERS;
    case engineFoUnit === "l" && engineFoUnitFactor === 10:
      return FLOWMETER_TYPE.LITER_10_TIMES;
    case engineFoUnit === "g":
      return FLOWMETER_TYPE.GARRONS;
  }
};

const getEta = (month, day, hour, minute) => {
  if (month === undefined || day === undefined || hour === undefined || minute === undefined) {
    return;
  }

  const dateNow = new Date();
  const etaYear = dateNow.getFullYear().toString();
  const etaMonth = month.toString().length < 2 ? 0 + month.toString() : month.toString();
  const etaDay = day.toString().length < 2 ? 0 + day.toString() : day.toString();
  const etaHour = hour.toString().length < 2 ? 0 + hour.toString() : hour.toString();
  const etaMinute = minute.toString().length < 2 ? 0 + minute.toString() : minute.toString();

  return `${etaYear}/${etaMonth}/${etaDay} - ${etaHour}:${etaMinute}`;
};

export { focUnit, getEta };