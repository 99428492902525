import { withStyles } from "@mui/styles";
import { Switch } from "@mui/material";
import { COLORS } from "../../../constants/colors";
import { switchClasses} from "@mui/material/Switch";

export const CustomSwitch = withStyles({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: COLORS.white,
    "&$checked": {
      transform: "translateX(12px)",
      color: COLORS.white,
    "& + $track": {
        opacity: 1,
        backgroundColor: COLORS.skyBlue,
        borderColor: COLORS.skyBlue,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
    borderRadius: 0,
  },
  track: {
    border: `1px solid none`,
    borderRadius: 0,
    opacity: 1,
    backgroundColor: "#9c9c9c",
  },
  checked: {},
})(Switch);
