export const servicePolicyEn = `
JRCS CO. LTD. (“JRCS”) stipulates the following terms of use (these “Terms of Use”) concerning the use of the services, which are provided via the “JRCS CO. LTD. infoceanus connect” operated by JRCS (the “Services”), by the potential subscribers and users thereof (collectively, “Users”).
Users shall subscribe for the Services after having agreed to the provisions of these Terms of Use and the privacy policy separately stipulated by JRCS 
(https://www.jrcs.co.jp/en/info/privacypolicy.html; the “Privacy Policy”).

Article 1.\tScope of Terms of Use
The scope of application of these Terms of Use includes any use of the online services on the webpage for the Services (the “Webpage”), as well as any communications made via email, or other methods, concerning the Services between JRCS and Users.

Article 2.\tMembership Registration
1.\tUsers shall subscribe for the membership registration for the Services through the procedures stipulated by JRCS.  Upon registering as a member, Users shall confirm and agree to the following matters.  JRCS will not be responsible for any damage caused as a result of any User’s breach of the following matters:
(1)\tUsers shall prepare a terminal with the OS recommended by JRCS;
(2)\tUsers shall install a web browser of the type and version recommended by JRCS;
(3)\tUsers shall prepare a communication environment recommended by JRCS;
(4)\tWith regard to the paid services among the Services provided by JRCS, Users shall pay the usage fees by the payment method set forth in Article 5 of these Terms of Use;
(5)\tFor the quality control of and the avoidance of trouble concerning the Services, JRCS may confirm/record the necessary information, such as Users’ login history, the records of the terminals, etc. which the Users used to login and the contents of the provided Services;
(6)\tJRCS shall deliver emails and give email notifications to Users.  Users shall prepare an email address through which such email delivery/notification may be received from JRCS;
(7)\tIn order to improve the quality of the customer support services, JRCS may record the data and audio of any inquiries, etc. made by Users, and store such records; and
(8)\tJRCS may provide or conduct notifications, advertisements, questionnaires, etc. concerning the Services via email or any Webpage app, etc.

2.\tMembership registration shall be completed when the User has made an application request to JRCS in the method designated by JRCS and has set a login ID and password by accessing the URL provided in the email sent by JRCS.

3.\tIf a User falls under any of the following, JRCS may reject such User’s membership registration, and even if registration has already been completed by such User, JRCS may cancel such membership registration:
(1)\tSuch User has made a misrepresentation, incorrect entry or omission with regard to the information provided by such User (the “Account Information”) at the time of registration;
(2)\tSuch User has breached these Terms of Use before;
(3)\tSuch User has failed to pay the usage fees and/or the optional fees;
(4)\tSuch User has not satisfied JRCS’s recommendations, such as for the terminal used, browser environment and line speed;
(5)\tIt is apparent that more than one User is using the Services via the same account, or it is apparent that one User is using more than one account; or
(6)\tJRCS otherwise decides that such User is unsuitable for using the Services.

4.\tAfter membership registration, Users shall be able to commence the use of the Services in accordance with the contents of Article 6.

5.\tThe information required for logging-into or for using the Services, such as email addresses, login IDs and passwords used by Users upon registration for the Services (collectively, the “Password Information”), must be strictly controlled at the Users’ own responsibility.
%JRCS may deem any use of the Services to have been conducted by the User him/herself, if the Password Information entered at the time of login matches the registered Password Information.

6.\tUsers shall not cause any third party to use their Password Information, and any and all assignment, lending, etc. of the same to any third party is forbidden.

7.\tIf a User loses or forgets his/her Password Information, or if there is a suspicion of the same being fraudulently used by a third party, such User must promptly notify JRCS to that effect, and must follow JRCS’s instructions.  JRCS will not be obliged to provide compensation for any damage, etc. arising due to such User’s delay in such notification.

8.\tFrom the aspect of security, Users shall set and use original Password Information for the Services, which is different from those used for other websites and services.

9.\tUsers shall be responsible for any damage arising from Users’ poor management of the Password Information or the use thereof by a third party.

Article 3.\tChange in Registered Information
If it becomes necessary to change the Account Information, Users shall conduct the procedures for change of registered information by the method stipulated by JRCS and without delay.  Even if a User has incurred any damage by delaying or failing to conduct such procedures for change, JRCS shall not be responsible for any and all such damage.

Article 4.\tUsage Period
Users shall be able to use the Services during the effective term of their membership registration.

Article 5.\tUsage Fees
Users shall complete the payment procedures for the usage fees of the paid services among the Services by the method separately designated by JRCS before commencing use.

Article 6.\tServices
1.\tUsers shall be able to use the free services among the Services immediately after membership registration.

2.\tUsers shall be able to use the paid services among the Services after membership registration and after having completed the payment procedures for the usage fees in accordance with Article 5.

3.\tUsers shall make sure to confirm, by the method designated by JRCS, each time before using the Services, that there are no problems in the terminal, OS, communication environment, etc. that they are using.

4.\tIf there are any documents, etc. which may be downloaded among the Services, Users will download the same, so that they will be available for review when using the Services.

Article 7.\tSystem Use and Recommended Environment for Use
1.\tThe Services will be provided using technology which conforms to HTML5.  Upon such use, Users must agree with the following:
(1)\tUsers shall prepare JRCS’s recommended environment;

(2)\tUsers shall conduct any download, installation, setting, use, etc. of the recommended browser at their own responsibility;
(3)\tJRCS shall not be responsible for any malfunction or problem attributable to the recommended browser, HTML or other apps, etc. which arises after commencement of the Services; and
(4)\tJRCS cannot respond to any consultation, inquiry, etc. concerning the services/functions defined by the recommended browser or HTML.

2.\tUpon using the Services, Users shall comply with the recommended environment designated by JRCS.  If a User uses the Services out of the recommended environment designated by JRCS, such User shall not be indemnified in any case.  The recommended environment designated by JRCS shall be as follows:

%Recommended Environment for Use
%---------------------------------------------------------------------------
%[OS]
%The environment recommended for the following browsers
%
%[Browser]
%The latest version Google Chrome, Microsoft Edge, Safari or Mozilla Firefox
%
%*\tIf a terminal does not have the recommended browser, the User will have to newly install the same.
%
%[Internet Communication]
%Broadband (optical fiber), CATV, mobile network, etc.
%*\tWhen using pay-as-you-go internet communication services such as by using mobile networks via tablets, please make sure to pay attention to the communication fees.
%\tWhen using the Services via tablets, we recommend that you use the same by accessing broadband networks via Wi-Fi.
%
%---------------------------------------------------------------------------
3.\tIf any obstacle arises in relation to the use of the system for the Services due to a problem attributable to other apps, plugins, etc. installed in the terminal used by a User, JRCS cannot respond to any inquiries or indemnifications, etc.

Article 8.\tProhibited Acts
1.\tUpon using the Services, Users shall not engage in any of the following acts, unless JRCS otherwise explicitly approves in writing (including electromagnetic means) of such Users’ engaging in such acts:
(1)\tActs of assigning, lending, buying, purchasing, changing the name of, creating a pledge for, and offering as collateral, the rights to use the Services to a third party;
(2)\tActs of assigning, lending, etc. the Password Information to a third party, or acts of causing a third party to use the same;
(3)\tActs which infringe upon JRCS’s rights to honor/credit, copyrights, patent rights, utility model rights, design rights, trademark rights or any other rights, or which infringe upon any third party’s privacy rights, publicity rights or any other rights;
(4)\tIllegal acts or acts which violate the public order and morals;
(5)\tActs which obstruct the use or operation of the Services, such as unauthorized/unpermitted access to or fraudulent manipulation of JRCS’s server;
(6)\tActs of using the Services for doing business, gaining profit or making preparations for either of the foregoing;
(7)\tActs of inducing or encouraging other Users of the Services to conduct an illegal act;
(8)\tActs which cause other Users of the Services to incur economic or mental damage, or other disadvantages;
(9)\tOther acts of using the Services for any purpose other than its intended purpose; 
(10)\tCriminal acts or acts which lead to criminal acts;
(11)\tActs of prying into JRCS’s confidential information;
(12)\tActs of using violent language or threatening behavior against the staff in JRCS’s support center, or acts of obstructing the progression of support services;
(13)\tActs of multiple Users using a single account;
(14)\tActs of one User registering more than one account; or
(15)\tOther acts which JRCS decides are inappropriate.

2.\tIf a User causes any damage to JRCS or a third party due to any acts of breach set forth in the preceding paragraphs, such User shall bear any and all legal responsibilities, even after withdrawal from the Services.

Article 9.\tResponses to Prohibited Acts
1.\tIf User falls under any of the following, JRCS may, without the need to provide such User with any prior notice, take measures that JRCS deems appropriate, such as discontinuing the provision of the Services or deleting such User’s membership registration (withdrawal of membership):
(1)\tEngaging in any of the prohibited acts as stipulated in Article 8;
(2)\tViolating any of the provisions of these Terms of Use;
(3)\tDelaying or failing to make payment of the usage fees or optional fees;
(4)\tNot following JRCS’s instructions in using the Services; or
(5)\tAny other case where JRCS deems User’s use of the Services to be inappropriate.

2.\tIf User is subject to JRCS’s disposition under the preceding paragraph, JRCS shall not reimburse such User for any usage fees already paid.

Article 10.\tChange in Details of Services
JRCS may change the details of the Services upon providing Users with notice.

Article 11.\tWithdrawal
1.\tUsers shall perform the withdrawal procedure in the method designated by JRCS.  In such case, JRCS may delete the User’s Account Information in accordance with the User’s request.

2.\tUpon completion of the withdrawal procedure, the User shall no longer be able to login or browse his/her usage history.

Article 12.\tNotice by Email
1.\tWhen JRCS transmits information regarding the Services, it shall be sufficient if such information is notified to the email address registered by each User (the “Designated Email Address”).  The handling regarding changes of these Terms of Use shall be as set forth in Article 19 hereof.

2.\tJRCS’s notice to Users shall be considered to have been completed upon sending the same to the Designated Email Address.

3.\tIf necessary, Users may need to change various settings regarding the Designated Email Address and permit emails to be received from JRCS (domain name: jrcs.co.jp).

4.\tJRCS shall not assume any responsibility even in the case where JRCS’s email does not arrive at User due to any deficiency or error in the Designated Email Address or User’s failure to change the email receipt settings.

5.\tJRCS may provide Users with advertisements and surveys via email, and may also otherwise encourage Users to use the Services.

Article 13.\tHandling of Registered Information
JRCS may use the registered information of a User’s account, such User’s usage history and such User’s browsing history of the Webpage based on the provisions of these Terms of Use and the privacy policy separately stipulated by JRCS  
(https://www.jrcs.co.jp/en/info/privacypolicy.html; the “Privacy Policy”).

Article 14.\tDiscontinuation and Termination of Services
JRCS may discontinue or terminate the Services upon posting an announcement on the Webpage or providing Users with notice in advance.  In addition, if it becomes difficult to provide the Services due to failure of HTML and apps, domestic or overseas political situation, natural disasters, including typhoons, earthquakes, heavy rain and lightning, human-generated disasters, including fire, riots and disputes, power cuts, or failure of servers providing the Services, training for staff, or any other reason not attributable to JRCS, JRCS may discontinue the Services without providing any prior notice.

Article 15.\tLiability for Damage
1.\tIf User violates these Terms of Use, JRCS may claim compensation against the User for any and all damage incurred due to such act of violation.

2.\tThe scope of damage which may be claimed by Users toward JRCS based on a flaw or default in the Services shall be limited to within the scope of the direct and actual damage incurred by the User, unless there is any willful intention or gross negligence on the part of JRCS, and the maximum amount shall be the usage fees for the Services that the User paid to JRCS in the month in which such damage occurred.

Article 16.\tIntellectual Property, Etc.
1.\tAny intellectual property rights, including copyrights (including the rights set forth in Article 27 and 28 of the Copyright Act; the same shall apply hereafter in this Article), and other rights in and to the Services and any contents provided under the Services (including, without limitation, ships databases, etc.; collectively, the “Contents”) shall belong to JRCS or those persons who have granted JRCS a license thereto.  In addition, any and all intellectual property rights, including copyrights and trademark rights in and to trademarks, logomarks, statements, etc., as well as other rights regarding the Services, shall belong to JRCS.

2.\tUsers may not use, duplicate or otherwise utilize the Services or Contents for any purpose other than the User’s utilization thereof, and shall only use the same in the way designated by JRCS.

3.\tUsers may not sell, provide as a gift, or lend (whether with or without consideration) any reproduction of the Services or Contents or the right to use the Services, etc. to any third party, regardless of the method of and reason therefor.

4.\tIf User violates any of the paragraphs of this Article, JRCS may take legal measures (including, without limitation, warnings, criminal complaints, claims for damage, injunctions against use, and measures to restore honor).  In such case, the User shall provide compensation for any and all damage incurred by JRCS and take measures to repair JRCS’s damage in accordance with JRCS’s instructions.

Article 17.\tDisclaimer
1.\tUsers agree in advance that JRCS will not provide any compensation for any of the following occurrences:
(1)\tWhen User is not able to use the Service to such User’s satisfaction (including, without limitation, the following cases):
(a)\tWhen the occurrence of the case set forth in Article 17.1(1) is caused by a shortage of the number of services provided, due to a rapid increase in the number of the users of the Services, or any of the events stipulated in Article 14.2 of these Terms of Use;
(b)\tWhen User is unable to reserve the Services during a specific time period desired by such User;
(c)\tWhen the occurrence of the case set forth in Article 17.1(1) is due to any of the events stipulated in Article 14 of these Terms of Use; or
(d)\tWhen the occurrence of the case set forth in Article 17.1(1) is caused by unauthorized access to or alteration of User’s messages and data, or any other act engaged in by a third party.
(e)\tWhen the occurrence of the case set forth in Article 17.1(1) is caused by the equipment mounted on the ship or the communication environment.
(2)\tWhen JRCS performs maintenance of systems on a regular basis or an urgent manner; 
(3)\tWhen virus infection or other damage occurs due to files received or opened by User at such User’s own risk;
(4)\tWhen User is unable to use the Services due to loss or inoperability of Passwords, Information caused by User’s negligence;
(5)\tIncompleteness, inaccuracy, oldness, unsafety, etc. of all information and links, etc. provided on the Webpage;
(6)\tWhen a User is unable to use part of the Services due to such User’s use of a website which is operated by a third party other than JRCS and which has a link to or from the Webpage, or when a service that was considered to be provided on such website in the course of the Services is not actually provided;
(7)\tWhen a trouble or failure occurs in association with the payment method; or
(8)\tWhen it becomes difficult to provide the Services due to any reason not attributable to JRCS.

2.\tJRCS makes no representation or warranty to Users as to the (i) fitness for a particular purpose of the corporation or person using the Services, (ii) accuracy, or (iii) function or detail, of the Services.

Article 18.\tIndication of Dates and Times in Relation to Services 
In making an indication [/With regard to indication] in relation to the Services and these Terms of Use, the dates and times such as membership registration date, the use start date, the month in which the use starts, the due date of payments of various usage fees, the due date of various applications shall all be based on Japan Standard time (UTC+9).

Article 19.\tChange in Terms of Use
1.\tJRCS may change these Terms of Use and, in such event, JRCS shall notify Users of the details of the change on the Webpage or via email.  In the case where a User continues to use the Services without performing the withdrawal procedure as stipulated in Article 11 of these Terms of Use after the notice of the details of such change, such User shall be considered to have agreed to these Terms of Use after such change.

2.\tIf an amendment, etc. to a compulsory statute causes part of these Terms of Use to conflict with such statute, such part shall be considered to have been changed to bring it into compliance with the provisions of the said statute.

Article 20.\tConfidentiality
Users shall not, without JRCS’s prior approval, disclose, divulge or use for any purpose other than using the Services, any of JRCS’s information which comes to be learned through the use of the Services and can be recognized as being confidential in nature (“Confidential Information”).

Article 21.\tSeverability
Even if part of these Terms of Use is found to be invalid, the remaining parts shall continue in full force and effect.

Article 22.\tGoverning Law and Agreed-Upon Court of Exclusive Jurisdiction 
1.\tThese Terms of Use shall be governed by the laws of Japan.  Any matter not set forth in these Terms of Use or any doubt arising in relation to the Services shall be discussed between the parties in good faith.

2.\tIn the event that a dispute is unable to be resolved despite the discussion pursuant to the preceding paragraph, the Tokyo District Court or Tokyo Summary Court shall be the agreed-upon court of exclusive jurisdiction in the first instance.
`;
