import React from "react";
import { withTranslation } from "react-i18next";
import localizationKeys from "../../../../i18n/localizationKeys.js";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Popover, Typography } from "@mui/material";
import constants from "../../utils/constants.js";
import DurationCalendar from "./durationCalendar.jsx";

const DatetimeFilter = ({ classes, t, minDate }) => {
  return (
    <div className={classes.itemForSearchDate}>
      <Typography variant="body2" className={clsx(classes.columnName, classes.occurrenceColumn)}>
        {t(localizationKeys.DateTime_lower)}
      </Typography>
      <DurationCalendar minDate={minDate} type={constants.DURATION_TYPE.DATETIME} />
    </div>
  );
};

DatetimeFilter.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object,
  minDate: PropTypes.object,
};

export default withTranslation()(DatetimeFilter);
