import React, { useEffect, useState, useRef, Fragment } from "react";
import { engineInitialSelector } from "../../constants/trendGraph/engine";
import { VESSEL_TYPE } from "../../model/slice/vesselSlice";
import { SEP_CHART_TYPE } from "../../constants/trendGraph/sepLines";
import { ENGINE_CHART_TYPE } from "../../constants/trendGraph/enginesLine.js";
import { useComparisonDataFetch } from "./hooks/useComparisonDataFetch";
import { Button, Grid, IconButton, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import localizationKeys from "../../i18n/localizationKeys";
import MinimiseIcon from "../trendPage/icon/minimiseIcon.jsx";
import MaximizeIcon from "../trendPage/icon/maximizeIcon.jsx";
import {ComparisonGraphSelector} from "./comparisonGraphSelector.jsx";
import Chart from "../chart/chart.jsx";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setSelectedVessel } from "../../model/slice/fleetNavigationDataSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { NoData } from "../offlineComponent/noData.jsx";
import { GraphNotSelected } from "../comparisonGraphNotSelectedComponent/comparisonGraphNotSelected.jsx";
import { fetchVessel } from "../../api/vessel.js";
import { ComparisonRightPanel } from "./comparisonRightPanel.jsx";
import Screenshot from "../trendPage/commonComponent/screenshot.jsx";
import { CsvDownload } from "../trendPage/commonComponent/csvDownload.jsx";
import { NAVIGATION_BAR_HEIGHT, TAB_BAR_HEIGHT } from "../../layout/panelLayout.jsx";
import { GRAPH_TYPE } from "../../constants/trendGraph/trendGraphType";
import { fetchCharts } from "../../api/charts.js";
import { fetchChannelNames } from "../../api/channel.js";
import { DEFAULT_LINE_COLORS } from "../../constants/trendGraph/linesDefaultColors.js";
import dayjs from "util/dayjs-init";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const PAPER_HEIGHT = `calc(50vh - ${NAVIGATION_BAR_HEIGHT}px - ${TAB_BAR_HEIGHT}px - 20px)`;

const useStyles = makeStyles((theme) => ({
  iconButtonContainer: {
    "& p": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "500",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#19b2ab",
      margin: "0",
    },
    width: "max",
    height: `50px`,
    backgroundColor: "rgba(44, 58, 72, 0.4)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
  },
  utilityIconArea: {
    height: "30px",
    display: "flex",
    justifyContent: "end",
    "& span": {
      width: "25px",
    },
  },
}));

export const ComparisonGraph = (props) => {
  const {
    number,
    styles,
    initialVesselId,
    vessels,
    setVessels,
    graphRef,
    typeForComparison,
    setTypeForComparison,
    setSelectedGraphTypeForComparison,
    selectedGraphForComparison,
    setSelectedGraphForComparison,
    graphDataForComparison,
    setGraphDataForComparison,
    isLoadingForComparison,
    setIsLoadingForComparison,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = props;

  const { t } = useTranslation();
  const classes = { ...styles };
  const utilityClasses = useStyles();
  const dispatch = useDispatch();
  const [currentSelectedVessel, setCurrentSelectedVessel] = useState(() => {
    if (number === 0) {
      return initialVesselId;
    } else {
      return null;
    }
  });
  const vessel = vessels[number];
  const navigate = useNavigate();
  const location = useLocation();
  const screenshotRef = useRef();

  const getGraphTypeByVesselId = (vesselId) => {
    if (vessel?.length == 0) return null;
    if(vessel?.vesselType === VESSEL_TYPE.SEP) return GRAPH_TYPE.SEP;
    if(vessel?.NoME > 0) return GRAPH_TYPE.ENGINE;
    return;
  };

  const initialSelectedGraph = () => {
    if (!currentSelectedVessel) return null;
    if (vessel?.vesselType === VESSEL_TYPE.SEP) return SEP_CHART_TYPE.LEGS;
    if (vessel?.NoME > 0) return ENGINE_CHART_TYPE.ENGINE_LOAD;
    return;
  };

  const initialGraphTypeSelect = () => {
    if (!currentSelectedVessel) return null;
    return getGraphTypeByVesselId(currentSelectedVessel);
  };

  const updateVesselData = async (vessel_id, number) => {
    if (!vessel_id) return;
    const newVesselData = await fetchVessel(vessel_id);
    setVessels(
      vessels.map((element, index) => {
        if (index === number) return newVesselData.vessel;
        return element;
      })
    );
  };

  const [enginePositions, setEnginePositions] = useState(() => engineInitialSelector(vessel?.NoME));
  const [selectedGraph, setSelectedGraph] = useState(() => initialSelectedGraph());
  const [selectedGraphType, setSelectedGraphType] = useState(() => initialGraphTypeSelect());
  const [isMaximize, setIsMaximize] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [comparisonLegendHeight, setComparisonLegendHeight] = useState(0);
  const [comparisonLegendLeft, setComparisonLegendLeft] = useState(0);
  const [showGraphUtilityIcon, setShowGraphUtilityIcon] = useState(false);
  const [toggleVariables, setToggleVariables] = useState(false);
  const [customCharts, setCustomCharts] = useState(null);
  const [customLines, setCustomLines] = useState(null);
  const [channelNames, setChannelNames] = useState(null);
  const [isFetchingChartInfo, setIsFetchingChartInfo] = useState(false);

  const { isLoading, isOnline, graphData } = useComparisonDataFetch({
    vessel,
    vesselId: currentSelectedVessel,
    selectedGraph: selectedGraphType === GRAPH_TYPE.ELECTRIC ? selectedGraphType : selectedGraph,
    engineType: vessel?.MEEngineType,
    position: enginePositions,
    startDate,
    endDate,
    number,
    selectedGraphType
  });
  const handleGraphData = (graphData) => {
    switch(selectedGraphType){
      case GRAPH_TYPE.ENGINE:
      case GRAPH_TYPE.SEP: return graphData?.[selectedGraph];
      case GRAPH_TYPE.ELECTRIC: return graphData?.electricData;
      case GRAPH_TYPE.CUSTOM: return graphData;
      default: return [];
    }
  }

  const displayGraphName = () => {
    if(selectedGraphType === GRAPH_TYPE.ELECTRIC) {
      return GRAPH_TYPE.ELECTRIC
    }else if(selectedGraphType === GRAPH_TYPE.CUSTOM) {
      return customCharts?.charts?.charts?.find((v) => v.id === selectedGraph)?.name
    }else{
      return selectedGraph
    }
  }

  useEffect(() => {
    dispatch(setSelectedVessel(initialVesselId));
  }, []);

  useEffect(() => {
    if (
      number === 0 &&
      (currentSelectedVessel === undefined || currentSelectedVessel !== initialVesselId)
    ) {
      setCurrentSelectedVessel(initialVesselId);
      setSelectedGraph(initialSelectedGraph());
    }
  }, [initialVesselId]);

  // to sync No.1 trend graph selected vessel and vessel Selector
  useEffect(() => {
    if (number === 0 && currentSelectedVessel !== initialVesselId) {
      dispatch(setSelectedVessel(currentSelectedVessel));
      const currentLocation = location.pathname.split("/")[1];
      navigate(`/${currentLocation}/${currentSelectedVessel}`);
    }
    updateVesselData(currentSelectedVessel, number);
  }, [currentSelectedVessel]);

  useEffect(() => {
    if (!selectedGraphType) {
      setSelectedGraphType(initialGraphTypeSelect());
    }
    if (!selectedGraph) {
      setSelectedGraph(initialSelectedGraph());
    }

    if (vessel) {
      let nameLists = [];
      if (vessel?.NoDG > 0) {
        nameLists = nameLists.concat([...new Array(vessel?.NoDG)].map((_, i) => `dg${i + 1}`));
      }
      if (vessel?.NoSG > 0) {
        nameLists = nameLists.concat([...new Array(vessel?.NoSG)].map((_, i) => `sg${i + 1}`));
      }
      if (!nameLists.length) {
        return;
      }
    }
  }, [currentSelectedVessel, vessel]);

  useEffect(() => {
    setSelectedGraph((value) => {
      if (vessel?.vesselType === VESSEL_TYPE.SEP) return SEP_CHART_TYPE.LEGS;
      else if (vessel?.vesselType === VESSEL_TYPE.vessel) return ENGINE_CHART_TYPE.ENGINE_LOAD;
      else return null;
    });
    setSelectedGraphType(() => getGraphTypeByVesselId(currentSelectedVessel));
    setEnginePositions(engineInitialSelector(vessel?.NoME) ?? []);
  }, [vessel]);

  useEffect(() => {
    setTypeForComparison([...typeForComparison.map((e, i) => (i === number ? selectedGraphType : e))]);
    setSelectedGraphTypeForComparison([
      ...selectedGraphForComparison.map((e, i) => (i === number ? selectedGraphType : e)),
    ]);
    setSelectedGraphForComparison([
      ...selectedGraphForComparison.map((e, i) => (i === number ? selectedGraph : e)),
    ]);
    setGraphDataForComparison([
      ...graphDataForComparison.map((e, i) => (i === number ? graphData : e)),
    ]);
    setIsLoadingForComparison([
      ...isLoadingForComparison.map((e, i) => (i === number ? isLoading : e)),
    ]);
    setToggleVariables(false);
  }, [isLoading]);

  useEffect(() => {
    const fetchCustomCharts = async () => {
      if(!vessel?.id) return;
      setIsFetchingChartInfo(true);
      const customChartsList = await fetchCharts(vessel?.id);
      const customChartsName = await fetchChannelNames(vessel?.id);
      setCustomCharts(customChartsList)
      setChannelNames(customChartsName);
      setIsFetchingChartInfo(false);
    }
    fetchCustomCharts();
  }, [vessel?.id])

  useEffect(() => {
    if (selectedGraphType !== GRAPH_TYPE.CUSTOM || !customCharts?.charts?.charts?.length) return;
    const lines = customCharts?.charts?.charts?.find((v) => v.id === selectedGraph)?.lines;
    const returnValue =  lines
      ?.map((line, idx) => {
        const channel = channelNames?.names.find((ch) => ch?.chNo === line?.chNo);
        return {
          key: `${line?.chNo}`,
          keyName: `${line?.chNo}`,
          name: channel?.chName,
          color: line?.color === '' || !line?.color ? DEFAULT_LINE_COLORS[idx] : line?.color,
          enableVisibilityIcon: "hidden",
          enable: true,
        };
      });
    setCustomLines(returnValue);
  }, [selectedGraph, customCharts]);
  const buttonClickHandler = (event) => {
    setToggleVariables(!toggleVariables);
  };

  const isNoData = () => {
    if(isLoading){return false;}
    if(Object.keys(graphData).length === 0){return true;}
    if(handleGraphData(graphData) == null || handleGraphData(graphData).length == 0) {return true;}
    return false;
  };

  const canShowUtilityIcons = selectedGraph && !isLoading && showGraphUtilityIcon && !isNoData();
 
  return (
    <Fragment>
      <Grid item xs={isMaximize ? 12 : 9}>
        <Grid
          className={classes.paperHeight}
          onMouseEnter={() => setShowGraphUtilityIcon(true)}
          onMouseLeave={() => setShowGraphUtilityIcon(false)}
          style={{
            height: toggleVariables
              ? `calc(${PAPER_HEIGHT} + ${comparisonLegendHeight}px + ${"30"}px)`
              : `calc(${PAPER_HEIGHT} + ${"30"}px)`,
          }}
        >
          <Grid
            container
            className={classes.comparisonGraphHeight}
            style={{
              height: toggleVariables
                ? `calc(${PAPER_HEIGHT} + ${comparisonLegendHeight}px + ${"30"}px)`
                : PAPER_HEIGHT,
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                height: toggleVariables
                  ? `calc(${PAPER_HEIGHT} + ${comparisonLegendHeight}px)`
                  : PAPER_HEIGHT,
                overflow: "hidden",
              }}
              ref={screenshotRef}
            >
              {currentSelectedVessel &&
              ((selectedGraph !== null && !!graphData && !isNoData())) ? 
              (
                <Fragment>
                  <Grid container justifyContent="space-between">
                    <Grid item sx={{color: "white", marginTop : "8px", marginLeft: "30px"}}>
                      {`${vessel?.name} - ${displayGraphName()}  ${dayjs.utc(startDate).format('YYYY/MM/DD HH:mm')} - ${dayjs.utc(endDate).format('YYYY/MM/DD HH:mm')}`}
                    </Grid>
                    <Grid
                      item
                      className={utilityClasses.utilityIconArea}
                      sx={{
                        visibility: canShowUtilityIcons ? "visible" : "hidden",
                      }}
                    >
                      <Screenshot
                        element={screenshotRef.current}
                        vesselName={vessel?.name}
                        graphType={selectedGraphType}
                        selectedGraph={selectedGraph}
                        selectedEngines={enginePositions}
                      />
                      <CsvDownload
                        graphRef={graphRef}
                        vesselId={vessel?.id}
                        graphData={graphData}
                        graphType={selectedGraphType}
                        selectedChart={selectedGraph}
                        selectedEngines={enginePositions}
                        isDownloading={isDownloading}
                        setIsDownloading={setIsDownloading}
                        isNoData={false}
                        isDurationDisable={false}
                        is1SecFetchEnabled={true}
                        isComparison={true}
                        isLoading={isLoading}
                        vesselName={vessel?.name}
                        chartsNameSettings={customLines}
                        customChartsList={customCharts}
                      />
                      <IconButton
                        style={{ width: "25px", height: "25px", top: "1px" }}
                        onClick={() => {
                          setIsMaximize((val) => !val);
                        }}
                        size="large">
                        {isMaximize ? (
                          <MinimiseIcon disabled={!graphData} />
                        ) : (
                          <MaximizeIcon disabled={!graphData} />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Chart
                    type={selectedGraphType}
                    height={320}
                    graphData={handleGraphData(graphData)}
                    isNoData={isNoData()}
                    vesselId={currentSelectedVessel}
                    vesselType={vessel?.MEEngineType}
                    isGraphDataLoading={isLoading}
                    chartType={
                      selectedGraphType === GRAPH_TYPE.ELECTRIC ? selectedGraphType : selectedGraph
                    }
                    style={{ border: "solid white" }}
                    isComparison={true}
                    comparisonGraphIndex={number}
                    toggleVariables={toggleVariables}
                    setComparisonLegendHeight={setComparisonLegendHeight}
                    setComparisonLegendLeft={setComparisonLegendLeft}
                    customChartLines={customLines}
                    vessel={vessel}
                  />
                  <Button
                    onClick={buttonClickHandler}
                    sx={{
                      position: "relative",
                      color: "white",
                      fontSize: "10px",
                      alignItems: "center",
                      bottom: toggleVariables ? `170px` : "180px",
                      left: `${comparisonLegendLeft}px`,
                      visibility: showGraphUtilityIcon && !isLoading ? "visible" : "hidden",
                    }}
                  >
                    {canShowUtilityIcons && 
                      (toggleVariables
                        ? <Grid container sx={{alignItems: "center"}}>{t(localizationKeys.HideGraphVariables)} <ExpandLessIcon /> </Grid>
                        : <Grid container sx={{alignItems: "center"}}>{t(localizationKeys.ShowGraphVariables)} <ExpandMoreIcon /> </Grid>
                      )
                    }
                  </Button>
                </Fragment>
              ) : (
                !currentSelectedVessel || !selectedGraph
                ? <GraphNotSelected/>
                : (
                  isNoData()
                  ? <NoData/>
                  : <CircularProgress size={80} sx={{position: "relative", left: "50%", top: "50%"}}/>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!isMaximize && (
        <Grid item xs={3}>
          <Grid
            className={classes.paperHeight}
            style={{
              height: toggleVariables
                ? `calc(${PAPER_HEIGHT} + ${comparisonLegendHeight}px + ${"30"}px)`
                : `calc(${PAPER_HEIGHT} + ${"30"}px)`,
            }}
          >
            <ComparisonRightPanel>
              <ComparisonGraphSelector
                currentVesselInfo={vessel}
                graphType={selectedGraphType}
                currentSelectedVessel={currentSelectedVessel}
                setCurrentSelectedVessel={setCurrentSelectedVessel}
                selectedGraphType={selectedGraphType}
                setSelectedGraphType={setSelectedGraphType}
                selectedGraph={selectedGraph}
                setSelectedGraph={setSelectedGraph}
                enginePositions={enginePositions}
                setEnginePositions={setEnginePositions}
                customCharts={customCharts}
                isFetchingChartInfo={isFetchingChartInfo}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </ComparisonRightPanel>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

ComparisonGraph.propTypes = {
  number: PropTypes.number,
  styles: PropTypes.object,
  initialVesselId: PropTypes.string,
  vessels: PropTypes.arrayOf(PropTypes.object),
  setVessels: PropTypes.func,
  graphRef: PropTypes.any,
  typeForComparison: PropTypes.array,
  setTypeForComparison: PropTypes.func,
  setSelectedGraphTypeForComparison: PropTypes.func,
  selectedGraphForComparison: PropTypes.array,
  setSelectedGraphForComparison: PropTypes.func,
  graphDataForComparison: PropTypes.array,
  setGraphDataForComparison: PropTypes.func,
  isLoadingForComparison: PropTypes.arrayOf(PropTypes.bool),
  setIsLoadingForComparison: PropTypes.func,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
};
