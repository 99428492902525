import dayjs from 'util/dayjs-init.js';
import { logger } from "../../api/logger";

export const baseNoon = (dateTime) => {
  const baseTime = dayjs.utc(dateTime);
  const noon = dayjs.utc(dateTime).startOf("days").hour(12).minute(0).second(0).millisecond(0);
  if (baseTime.isSameOrAfter(noon)) {
    const yesNoon = dayjs.utc(noon.format()).subtract(1, "days");
    return {
      endDateTime: noon,
      startDateTime: yesNoon,
    };
  } else {
    return {
      endDateTime: dayjs.utc(noon.format()).subtract(1, "days"),
      startDateTime: dayjs.utc(noon.format()).subtract(2, "days"),
    };
  }
};

export const baseTwoHours = (dateTime) => {
  return {
    endDateTime: dayjs.utc(dateTime),
    startDateTime: dayjs.utc(dateTime).subtract(2, "hours"),
  };
};

export const getFocData = (engineData) => {
  try {
    if (!engineData?.length) return;
    return engineData.reduce(
      (a, c) => {
        const meTotal = c?.mefoFlowTotal - a?.mefoFlowTotal;
        const geTotal = c?.gefoFlowCoil - a?.gefoFlowCoil;
        return {
          mefoFlowTotal: c?.mefoFlowTotal,
          gefoFlowCoil: c?.gefoFlowCoil,
          meTotal: meTotal > 0 ? a.meTotal + meTotal : a.meTotal,
          geTotal: geTotal > 0 ? a.geTotal + geTotal : a.geTotal,
          latestDate: c.dateTime,
        };
      },
      {
        mefoFlowTotal: engineData[0]?.mefoFlowTotal,
        gefoFlowCoil: engineData[0]?.gefoFlowCoil,
        meTotal: 0,
        geTotal: 0,
        latestDate: 0,
      }
    );
  } catch (e) {
    logger.error(`getFocData error ${e}`);
    throw e;
  }
};
