import React from "react";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

//localization imports
import { withTranslation } from "react-i18next";
import localizationKeys from "../../i18n/localizationKeys.js";
import { getEta } from "../../util/navigation/navigation.js";

const useStyles = makeStyles((theme) => ({
  tableHolder: {
    width: "100%",
  },
  columnHolder: {
    paddingTop: "20px",
    paddingLeft: "10px",
  },
  rowHolder: {
    textAlign: "center",
  },
}));

const IteneraryPanel = (props) => {
  const { t, isDisconnected, isVesselSEP = false } = props;
  const styles = useStyles();
  const classes = { ...styles, ...props.classes };
  const navLabel = isDisconnected ? classes.disconnectedNavLabel : classes.navLabel;

  return (
    <React.Fragment>
      {getEta()}
      <table className={classes.tableHolder}>
        <tbody>
          {!isVesselSEP && (
            <tr className={classes.rowHolder}>
              <td className={classes.columnHolder} colSpan="2">
                <div className={navLabel}>{t(localizationKeys.Eta_upper)}</div>
                <div className={classes.navValue}>
                  {props.aisData.etaMonth === undefined
                    ? "-"
                    : getEta(
                        props.aisData.etaMonth,
                        props.aisData.etaDay,
                        props.aisData.etaHour,
                        props.aisData.etaMinute
                      )}
                </div>
              </td>
            </tr>
          )}
          <tr className={classes.rowHolder}>
            {isVesselSEP && (
              <td className={classes.columnHolder}>
                <div className={navLabel}>{t(localizationKeys.Eta_upper)}</div>
                <div className={classes.navValue}>
                  {props.aisData.etaMonth === undefined
                    ? "-"
                    : getEta(
                        props.aisData.etaMonth,
                        props.aisData.etaDay,
                        props.aisData.etaHour,
                        props.aisData.etaMinute
                      )}
                </div>
              </td>
            )}
            <td className={`${classes.columnHolder}`}>
              <div className={navLabel}>{t(localizationKeys.Destination_upper)}</div>
              <div className={classes.navValue}>
                {props.aisData.destination === undefined ? "-" : props.aisData.destination}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

IteneraryPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  t: PropTypes.func.isRequired,
  aisData: PropTypes.object,
  isDisconnected: PropTypes.bool,
};

export default withTranslation()(IteneraryPanel);
