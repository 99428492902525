import { createSlice } from "@reduxjs/toolkit";
import Actions from "../action/comparisonTableAction";
import { electricTrendLines } from "../../constants/trendGraph/electricLines";

const initialState = {
  graph: [
    {
      chartList: electricTrendLines,
      hideLists: [],
    },
    {
      chartList: electricTrendLines,
      hideLists: [],
    },
  ],
};

const comparisonTableSlice = createSlice({
  name: "comparisonTableController",
  initialState: {
    selectedChart: [],
    ...initialState,
  },
  reducers: {
    ...Actions,
  },
});

export const getSelectedChartLists = (state) => state.comparisonTableController.selectedChart;
export const getSelectedChart = (graphNumber) => (state) =>
  state.comparisonTableController.selectedChart?.find((d) => d.number === graphNumber);
export const graphList = (index, listType) => (state) =>
  state.comparisonTableController.graph[index][listType];
export const {
  hideLines,
  showLines,
  setLineList,
  setSelectedComparisonTableChart,
  setEnableVisibilityIcon,
  setEnable,
} = comparisonTableSlice.actions;

export default comparisonTableSlice.reducer;
