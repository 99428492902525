import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import { Autocomplete, Paper, TextField, Typography } from "@mui/material";
import dayjs from 'util/dayjs-init.js';
import localizationKeys from "../../../i18n/localizationKeys.js";
import * as PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import { SearchIcon } from "../../common/listUtil/searchIcon.jsx";

const column = {
  fontSize: "10px",
  fontFamily: "Inter",
  fontWeight: "500",
  color: "#ffffff",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
};

const placeHolder = (theme) => ({
  color: "#FFFFFF",
  fontStyle: "italic",
  opacity: 0.65,
  fontSize: "10px",
  fontWeight: 300,
  fontStretch: "normal",
  [theme.breakpoints.down('sm')]: {
    fontSize: "10px !important",
  },
});

const useStyles = ({ isEnglish, isOperationPage }) =>
  makeStyles((theme) => ({
    alarmSearch: {
      backgroundColor: "#252d39 !important",
      fontSize: "10px",
      fontWeight: "500",
      width: "100%",
      borderRadius: "2px",
      "& .MuiInputBase-input": {
        color: "#FFFFFF",
        marginLeft: "10px",
        fontSize: "10px",
        [theme.breakpoints.down('sm')]: {
          fontSize: "10px !important",
        },
      },
      "& .MuiInputBase-input::placeholder": {
        ...placeHolder(theme),
      },
      "& .MuiInputBase-input::-ms-input-placeholder": {
        ...placeHolder(theme),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "10px !important",
        borderRadius: "2px",
      },
    },
    searchBase: {
      flexGrow: 1,
      // marginLeft: "10px",
      "& .first-child": {
        marginRight: "auto",
      },
      "& .MuiAutocomplete-popper": {
        backgroundColor: "none !important",
      },
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        marginLeft: "0",
      },
    },
    searchIconDiv: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    searchIconAdjustment: {
      marginRight: "5px",
    },
    item: {
      //display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down('sm')]: {
        display: "block",
        marginBottom: "12px",
      },
    },
    columnName: {
      ...column,
      [theme.breakpoints.up("sm")]: {
        //marginLeft: "auto",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "10px !important",
      },
    },
    titleContainer: {
      width: isOperationPage ? "110px" : "80px",
      display: "flex",
      marginBottom: "8px",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        marginBottom: "12px",
      },
    },
  }));

const SearchItem = React.memo(
  ({ isOperationPage, text, setText, autocompleteOptions, i18n, t, resetFunc }) => {
    const classes = useStyles({
      isEnglish: i18n.language === "en-US",
      isOperationPage,
    })();
    const theme = useTheme();
    const [key, setKey] = useState("");
    const { register, getValues, setValue, watch, reset } = useForm({
      defaultValues: {
        item: "",
      },
    });

    const CustomPaper = ({ children }) => (
      <Paper 
        sx={{
          "& .MuiAutocomplete-listbox": {
            backgroundColor: "#2c3a48",
            color: "white",
            "& :hover": {
              backgroundColor: "rgba(33, 38, 47, 1.0)",
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: "10px !important",
            },
          },
        }}
      >
        {children}
      </Paper>
    );

    const [open, setOpen] = useState(false);
    const AddSearchIcon = () => {
      const SearchIconButtonClick = () => {
        setOpen(true);
      };
      return (
        <div className={classes.searchIconDiv} onClick={() => SearchIconButtonClick()}>
          <SearchIcon color={"#19B2AB"} />
        </div>
      );
    };

    resetFunc.current = () => {
      reset();
      setKey(dayjs.utc().toString());
    };

    useEffect(() => {
      setValue("item", text);
    }, []);

    useEffect(() => {
      setText(getValues().item);
    }, [watch("item")]);

    return (
      <div className={classes.item} key="input-base">
        <div className={classes.titleContainer}>
          <Typography className={classes.columnName} variant="body2">
            {t(
              isOperationPage
                ? localizationKeys.AlarmOperationItem_lower
                : localizationKeys.AlarmName_lower
            )}
          </Typography>
        </div>
        <Autocomplete
          name="item"
          key={key}
          freeSolo
          open={open}
          onClose={() => setOpen(false)}
          options={autocompleteOptions()}
          {...register("item")}
          onInputChange={(e, v, r) => {
            setValue("item", v);
          }}
          className={classes.searchBase}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              key="input-text"
              className={classes.alarmSearch}
              onClick={() => setOpen(true)}
              placeholder={t(localizationKeys.Search_lower)}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                type: "search",
                endAdornment: <AddSearchIcon />,
                variant: "standard",
              }}
            />
          )}
          PaperComponent={CustomPaper}
        />
      </div>
    );
  }
);

SearchItem.propTypes = {
  isOperationPage: PropTypes.bool,
  text: PropTypes.string,
  setText: PropTypes.func,
  t: PropTypes.func,
  autocompleteOptions: PropTypes.func,
  i18n: PropTypes.object,
};

export default withTranslation()(SearchItem);
