import {
  fetchCraneLatestData,
  fetchLegLatestData,
  fetchTrimAndHeelLatestData,
  fetchThrusterLatestData,
} from "../../api/latestSepData";

export const useSepLatestDataOnce = async (vesselId) => {
  const fetchLatestData = async () => {
    const data = await Promise.all([
      fetchCraneLatestData(vesselId),
      fetchThrusterLatestData(vesselId),
      fetchTrimAndHeelLatestData(vesselId),
      fetchLegLatestData(vesselId),
    ]).catch(() => {
      return [];
    });

    return {
      sepDataState: data?.length > 0 ? data : [],
    };
  };

  const data = await fetchLatestData();

  return data;
};
